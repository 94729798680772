import { Box, Flex, Text } from '@chakra-ui/react';
import { FC, useMemo } from 'react';
import { useDefaultStyles } from '../../../hooks/useDefaultStyles';
import { useDevice } from '../../../hooks/useDevice';
import { Divider } from '../../../lib/components/Divider';

interface aux {
  hit: number;
  total: number;
}

export interface ResultsProps {
  result: {
    easy: aux;
    medium: aux;
    hard: aux;
    unknown: aux;
  };
  hide?: boolean;
  device?: 'web' | 'mobile' | 'both';
}

const Results: FC<ResultsProps> = ({
  result, hide = false, device = 'both',
}) => {
  const currDevice = useDevice();
  const { colors } = useDefaultStyles();
  const totalHits = useMemo(() => result.unknown.hit + result.easy.hit + result.medium.hit + result.hard.hit, [
    result.easy.hit, result.hard.hit, result.medium.hit, result.unknown.hit]);
  const totalQuestions = useMemo(() => result.unknown.total
    + result.easy.total
    + result.medium.total
    + result.hard.total, [
    result.easy.total, result.hard.total, result.medium.total, result.unknown.total,
  ]);
  if (hide || (currDevice !== device && device !== 'both')) {
    return <></>;
  }
  return (
    <Box
      borderRadius={12}
      bgColor={colors.background}
      boxShadow="1px 1px 1px 3px rgba(0, 0, 0, 0.25)"
      py={2}
      px={3}
      my={3}
      mt={4}
    >
      <Text fontSize="lg" color={colors.secondary.keep} textAlign="center" mb={2}>Resultado</Text>
      <Flex align="center">
        <Text fontSize="sm">Fáceis</Text>
        <Divider mx={2} />
        <Text fontSize="sm">
          <strong>{`${result.easy.hit}`}</strong>
          {`/${result.easy.total}`}
        </Text>
        <Text fontSize="xs" color={colors.secondary.keep} ml={2}>
          {`(${(Math.round((result.easy.hit * 100) / result.easy.total) || '00').toString().padStart(2)}%)`}
        </Text>
      </Flex>
      <Flex align="center">
        <Text fontSize="sm">Médias</Text>
        <Divider mx={2} />
        <Text fontSize="sm">
          <strong>{`${result.medium.hit}`}</strong>
          {`/${result.medium.total}`}
        </Text>
        <Text fontSize="xs" color={colors.secondary.keep} ml={2}>
          {`(${(Math.round((result.medium.hit * 100) / result.medium.total) || '00').toString().padStart(2)}%)`}
        </Text>
      </Flex>
      <Flex align="center">
        <Text fontSize="sm">Difíceis</Text>
        <Divider mx={2} />
        <Text fontSize="sm">
          <strong>{`${result.hard.hit}`}</strong>
          {`/${result.hard.total}`}
        </Text>
        <Text fontSize="xs" color={colors.secondary.keep} ml={2}>
          {`(${(Math.round((result.hard.hit * 100) / result.hard.total) || '00').toString().padStart(2)}%)`}
        </Text>
      </Flex>
      <Flex align="center">
        <Text whiteSpace={{ base: 'nowrap', md: 'normal', lg: 'nowrap' }} fontSize="sm">Sem categoria</Text>
        <Divider mx={2} />
        <Text fontSize="sm">
          <strong>{`${result.unknown.hit}`}</strong>
          {`/${result.unknown.total}`}
        </Text>
        <Text fontSize="xs" color={colors.secondary.keep} ml={2}>
          {`(${(Math.round((result.unknown.hit * 100) / result.unknown.total) || '00').toString().padStart(2)}%)`}
        </Text>
      </Flex>
      <Flex align="center" mt={2}>
        <Text fontSize="sm" fontWeight="bold" color={colors.secondary.keep}>Total</Text>
        <Divider mx={2} borderColor={colors.secondary.keep} />
        <Text fontSize="sm" color={colors.secondary.keep}>
          <strong>
            {`${totalHits}`}
          </strong>
          {`/${totalQuestions}`}
        </Text>
        <Text fontSize="xs" color={colors.secondary.keep} ml={2}>
          {`(${(Math.round((totalHits * 100) / totalQuestions) || '00').toString().padStart(2)}%)`}
        </Text>
      </Flex>
    </Box>
  );
};

export default Results;
