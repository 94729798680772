import React, {
  FC, useCallback, useState, useContext, useMemo, useEffect,
} from 'react';
import { toast } from 'react-toastify';
import { faPlusCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, HStack, Text, useDisclosure } from '@chakra-ui/react';
import {
  InstitutionTarget, useProfile,
} from '../../../api/profile';
import Flex, { FlexItem } from '../../../lib/components/Flex';
import SizedBox from '../../../lib/components/SizedBox';
import { cleanTypename } from '../../../utils/cleanTypename';
import { PrivateContext } from '../../../Private.context';
import { SecondaryButton } from '../../../lib/components/SecondaryButton';
import { PrimaryButton } from '../../../lib/components/PrimaryButton';
import { AristoDrawer } from '../../../lib/components/AristoDrawer';
import { InstitutionIncluder } from './InstitutionIncluder';
import { useDevice } from '../../../hooks/useDevice';
import { Loading } from '../../../lib/components/Loading';
import { useDefaultStyles } from '../../../hooks/useDefaultStyles';

interface MyGoalsProps {
  disableUndo?: boolean;
  onSave?: () => void;
  neverDisableSave?: boolean;
  primaryButtonText?: string;
  primaryButtonStyle?: React.CSSProperties;
  justifyButtons?: 'end' | 'center';
  hideSuccessToast?: boolean;
}

const MyGoals: FC<MyGoalsProps> = ({
  disableUndo, onSave, neverDisableSave, primaryButtonText = 'Salvar alterações',
  justifyButtons = 'end', primaryButtonStyle, hideSuccessToast,
}: MyGoalsProps) => {
  const { profile: { institutionTarget }, profileLoading } = useContext(PrivateContext);
  const { colors } = useDefaultStyles();
  const treatedIt: InstitutionTarget[] = useMemo(() => {
    if (institutionTarget) {
      return institutionTarget.map(item => cleanTypename(item));
    }
    return [];
  }, [institutionTarget]);

  // console.log(profileLoading);
  const { onUpdateProfile } = useProfile();
  const [loading, setLoading] = useState(false);
  const { onOpen, onClose, isOpen } = useDisclosure();
  const [dirtyInputs, setDirtyInputs] = useState(false);

  const [it, setIt] = useState(treatedIt);

  useEffect(() => {
    setIt(treatedIt);
  }, [treatedIt]);

  const handleRemove = useCallback((paramUf: string, paramInstitution: string) => () => {
    const fIndex = it.findIndex(c => c.uf === paramUf && c.institution === paramInstitution);
    if (fIndex !== -1) {
      const newArray = Object.assign([], it);
      newArray.splice(fIndex, 1);
      setIt(newArray);
      setDirtyInputs(true);
    } else {
      toast.warning('Instituição não encontrada!');
    }
  }, [it]);

  const handleUndo = useCallback(() => {
    setIt(treatedIt);
    setDirtyInputs(false);
  }, [treatedIt]);

  const handleUpdate = useCallback(async () => {
    setLoading(true);
    try {
      const data = await onUpdateProfile({
        institutionTarget: it,
      });
      if (data) {
        if (!hideSuccessToast) {
          toast.success('Atualizado com sucesso!');
        }
        setDirtyInputs(false);
      } else {
        toast.error('Erro ao atualizar!');
      }
    } catch (error) {
      toast.error(error);
    } finally {
      if (onSave) {
        onSave();
      }
      setLoading(false);
    }
  }, [hideSuccessToast, it, onSave, onUpdateProfile]);

  return (
    <Box>

      <AristoDrawer
        isOpen={isOpen}
        onClose={onClose}
        title="Adicionar instituição"
      >
        <InstitutionIncluder
          setDirtyInputs={setDirtyInputs}
          it={it}
          setIt={setIt}
          onClose={onClose}
        />
      </AristoDrawer>
      <Text tag="h5" className="mb-4">Em quais instituições deseja passar?</Text>
      <Box>
        {
          profileLoading ? (<Loading />) : (
            it.map((item, index) => {
              return (
                <TargetLine
                  key={`${item.uf}-${item.institution}`}
                  uf={item.uf}
                  index={index}
                  institution={item.institution}
                  handleRemove={handleRemove}
                />
              );
            })
          )
        }
      </Box>
      <HStack justify="flex-end" marginBottom="2.5rem">
        <Button
          variant="ghost"
          onClick={onOpen}
          color={colors.secondary.goALittleDarker}
          rightIcon={<FontAwesomeIcon icon={faPlusCircle} size="2x" />}
        >
          Adicionar instituição
        </Button>
      </HStack>
      <Flex className="mb-2">
        <HStack justify={justifyButtons} w="100%">
          {!disableUndo && (
            <SecondaryButton
              disabled={!dirtyInputs}
              onClick={handleUndo}
              color={colors.secondary.goLighter}
            >
              <Text fontSize={{ base: 'xs', md: 'md' }}>
                Desfazer alterações
              </Text>
            </SecondaryButton>
          )}
          <PrimaryButton
            onClick={handleUpdate}
            style={primaryButtonStyle}
            disabled={!neverDisableSave && !dirtyInputs}
            isLoading={loading}
          >
            <Text fontSize={!primaryButtonStyle ? { base: 'xs', md: 'md' } : ''}>
              {primaryButtonText}
            </Text>
          </PrimaryButton>
        </HStack>
      </Flex>
    </Box>
  );
};

interface TargetLineProps {
  uf: string;
  institution: string;
  handleRemove: (paramUf: string, paramInstitution: string) => () => void;
  index?: number;
}

const TargetLine: FC<TargetLineProps> = ({ uf, institution, handleRemove, index }) => {
  const device = useDevice();
  return (
    <SizedBox
      flex
      align="center"
      height={40}
      className={`pl-2 my-2 ${device === 'mobile' ? 'py-5' : 'py-4'}`}
      border={{
        color: 'gray',
        width: 1,
        style: 'solid',
      }}
      borderRadius={8}
    >
      <FlexItem>
        <Text>{`${index !== undefined ? `${index + 1}° - ` : ''}${uf} - ${institution}`}</Text>
      </FlexItem>
      <button type="button" onClick={handleRemove(uf, institution)} title="Remover instituição">
        <SizedBox width={50} flex justify="center" align="center">
          <FontAwesomeIcon icon={faTimes} size="lg" className="color-gray" />
        </SizedBox>
      </button>
    </SizedBox>
  );
};

export default MyGoals;
