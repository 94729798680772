import { Box } from '@chakra-ui/react';
import { FC, ReactNode, useEffect, useState } from 'react';

type FlipEffectType = {
  front: ReactNode;
  back: ReactNode;
  flip?: boolean;
  flipMode: 'hover' | 'click' | 'setter';
  onClick?: () => void;
  dimensions?: { w: number | string, h: number | string, m?: number | string };
}

const faceProps = {
  sx: {
    backfaceVisibility: 'hidden',
    WebkitBackfaceVisibility: 'hidden',
  },
  w: '100%',
  h: '100%',
};

export const FlipEffect: FC<FlipEffectType> = ({
  front, back, dimensions, flipMode = 'hover', flip, onClick,
}: FlipEffectType) => {
  const [showBack, setShowBack] = useState(false);

  useEffect(() => {
    if (flipMode === 'setter' && (flip !== undefined)) {
      setShowBack(flip);
    }
  }, [flip, flipMode]);

  return (
    <Box
      sx={{ perspective: '1000px' }}
      as={onClick ? 'button' : undefined}
      onClick={() => {
        if (flipMode === 'click') {
          setShowBack(prev => !prev);
        }
        if (onClick) {
          onClick();
        }
      }}
      onMouseOver={() => {
        if (flipMode === 'hover') {
          setShowBack(true);
        }
      }}
      onMouseLeave={() => {
        if (flipMode === 'hover') {
          setShowBack(false);
        }
      }}
      w={dimensions?.w}
      h={dimensions?.h}
      m={dimensions?.m}
    >
      <Box
        transition="transform 0.56s"
        sx={{ transformStyle: 'preserve-3d' }}
        transform={showBack ? 'rotateY(180deg)' : ''}
        position="relative"
        w="100%"
        h="100%"
      >
        <Box
          position="absolute"
          {...faceProps}
        >
          {front}
        </Box>
        <Box
          position="absolute"
          transform="rotateY(180deg)"
          {...faceProps}
        >
          {back}
        </Box>
      </Box>
    </Box>
  );
};
