export const getId = (): string => {
  const { search } = window.location;
  const params = new URLSearchParams(search);
  const id = params.get('id') || '';
  return id;
};

export const setId = (value: string): { search: string; } => {
  const currentUrlParams = new URLSearchParams(window.location.search);
  currentUrlParams.set('id', value);
  return ({ search: currentUrlParams.toString() });
};
