import { DocTypes } from '../api/agenda/agenda';

export const getActivityType = (): DocTypes | 'exams' | 'mocks'
| 'smart_exams' | 'extra-smart-review-question' | 'extra-smart-review-flashcard' => {
  const { pathname } = window.location;
  if (pathname.includes('/video')) {
    return 'video';
  }
  if (pathname.includes('/simulado-inteligente')) {
    return 'smart_exams';
  }
  if (pathname.includes('/simulado')) {
    return 'mocks';
  }
  if (pathname.includes('/prova')) {
    return 'exams';
  }
  if (pathname.includes('/flashcard')) {
    if (pathname.includes('/atividades-extras/revisao-inteligente')) {
      return 'extra-smart-review-flashcard';
    }
    return 'flashcard';
  }
  if (pathname.includes('/questao')) {
    if (pathname.includes('/atividades-extras/revisao-inteligente')) {
      return 'extra-smart-review-question';
    }
    return 'question';
  }
  if (pathname.includes('/mapa-mental')) {
    return 'mindmap';
  }
  if (pathname.includes('/apostila')) {
    return 'text';
  }
  return 'question';
};
