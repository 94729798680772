import { useCallback } from 'react';
import { optimizerApi } from '../services/api';

const _tempNps = '/temporary-nps';
const _nps = '/nps';

export const useOptimizerApi = () => {
  const shouldExecute = useCallback(async () => {
    try {
      const res = await optimizerApi.get(`${_nps}/should-execute`);
      return String(res.data) === 'true';
    } catch (_) {
      return false;
    }
  }, []);

  const completeSurvey = useCallback(async ({
    score, comment, reasons, isUserParticipatingInTest,
  } : {
    score: number,
    comment: string,
    reasons: string[],
    isUserParticipatingInTest: boolean,
  }) => {
    try {
      await optimizerApi.post(`${_nps}/complete-survey`, {
        score,
        source: 'React',
        comment,
        reasons,
        isUserParticipatingInTest,
      });
    } catch (_) {
      console.log('Error complete survey');
    }
  }, []);

  const refuseSurvey = useCallback(async () => {
    try {
      await optimizerApi.post(`${_nps}/refuse-survey`);
    } catch (_) {
      console.log('Error refuse survey');
    }
  }, []);
  const replyLaterSurvey = useCallback(async () => {
    try {
      await optimizerApi.post(`${_nps}/reply-later`);
    } catch (_) {
      console.log('Error reply later survey');
    }
  }, []);

  const shouldExecuteTemp = useCallback(async () => {
    try {
      const res = await optimizerApi.get(`${_tempNps}/can-execute`);
      return String(res.data) === 'true';
    } catch (_) {
      return false;
    }
  }, []);

  const completeSurveyTemp = useCallback(async () => {
    try {
      await optimizerApi.post(`${_tempNps}/mark-survey-as-completed`);
    } catch (_) {
      console.log('Error complete survey');
    }
  }, []);

  const skipSurveyTemp = useCallback(async () => {
    try {
      await optimizerApi.post(`${_tempNps}/mark-today-as-skiped`);
    } catch (_) {
      console.log('Error skip survey');
    }
  }, []);

  return {
    shouldExecute,
    completeSurvey,
    refuseSurvey,
    replyLaterSurvey,
    shouldExecuteTemp,
    completeSurveyTemp,
    skipSurveyTemp,
  };
};
