import {
  Box, Button, Center, Flex, Image, Text, useBreakpointValue,
} from '@chakra-ui/react';
import { FC, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { PrimaryButton } from '../../lib/components/PrimaryButton';
import LandingFooter from './components/LandingFooter';
import { MyGrid } from './components/MyGrid';

const howItWorksData = [
  {
    image: '/images/landing/appeals-cards.png',
    title: 'Escolha a instituição',
    body: 'Veja a lista de instituições que solicitaram recursos.',
  },
  {
    image: '/images/landing/appeals-summary-card.png',
    title: 'Visualize as Questões',
    body: 'Confira as questões disponíveis para revisão.',
  },
  {
    image: '/images/landing/comment.png',
    title: 'Confira a justificativa',
    body: 'Ao clicar na questão, veja a justificativa explicando por que cabe ou não recurso.',
  },
];

const itemData = [
  {
    image: '/images/landing/school.png',
    title: 'Recursos das principais instituições',
    body: 'Acesse as questões em análise de diversas instituições de ensino.',
  },
  {
    image: '/images/landing/clock.png',
    title: 'Acompanhamento em tempo real',
    body: 'Veja as atualizações de revisões e recursos.',
  },
  {
    image: '/images/landing/bolt.png',
    title: 'Acesso rápido e gratuito',
    body: 'Explore os recursos de forma prática e sem custos, com apenas alguns cliques.',
  },
];

const LandingPage: FC = () => {
  const history = useHistory();

  const onClick = useCallback(() => {
    history.push('/landing/login');
  }, [history]);

  const btnWidth = useBreakpointValue({
    base: '100%',
    sm: '100%',
    md: '234px',
    lg: '234px',
  });

  const items = useCallback((index: number) => {
    const data = itemData[index];
    return (
      <Box>
        <Image src={data.image} alt="summary" w="34px" mb={2} />
        <Text fontWeight={500} mb={1} fontSize={14} lineHeight={1.4}>{data.title}</Text>
        <Text fontSize={12} color="#75777E">{data.body}</Text>
      </Box>
    );
  }, []);

  const alignGrid: 'start' | 'center' | undefined = useBreakpointValue({
    base: 'start',
    sm: 'center',
    md: 'start',
    lg: 'start',
  });

  const howItWorksItem = useCallback((index: number) => {
    const data = howItWorksData[index];
    return (
      <MyGrid
        cols={[1, 2, 1, 1]}
        gap={3}
        alignItems={alignGrid}
        id="header"
      >
        <Image src={data.image} alt="summary" w="100%" />
        <Box>
          <Text as="strong" fontSize={22}>{data.title}</Text>
          <Text color="#75777E">{data.body}</Text>
        </Box>
      </MyGrid>
    );
  }, [alignGrid]);

  return (
    <div style={{ background: '#fff' }}>
      <Box maxW={1220} mx="auto" my="0" color="#191C20">
        <Box px={8} py={8}>
          <Flex justifyContent="space-between" mb={12}>
            <Image src="/images/logo-name.png" alt="logo" w="135px" h="25.2px" />
            <Button borderRadius={100} border="1px solid #75777E" color="blue.500" onClick={onClick}>
              Quero me matricular
            </Button>
          </Flex>
          <MyGrid
            cols={[1, 1, 2, 2]}
            gap={8}
            mb={10}
            id="main"
          >
            <Box>
              <Text fontSize={36} as="h1" fontWeight="bold" mb={4}>
                Tenha Acesso Gratuito aos Recursos das Questões da Sua Instituição
              </Text>
              <Text mb={4}>
                Acompanhe questões com possíveis erros nas provas da sua instituição,
                veja quais estão em análise e mantenha-se informado sobre revisões e recursos.
                Tudo de forma prática e gratuita, diretamente pela nossa plataforma.
              </Text>
              <PrimaryButton size="lg" borderRadius={8} mb={8} onClick={onClick}>
                Acessar Recursos Gratuitamente
              </PrimaryButton>
              <MyGrid
                cols={[3, 3, 3, 3]}
                gap={4}
                id="items"
              >
                {items(0)}
                {items(1)}
                {items(2)}
              </MyGrid>
            </Box>
            <Image src="/images/landing/appeals-summary.png" alt="summary" w="100%" />
          </MyGrid>
          <Text color="#296FE0" fontSize={22}>COMO FUNCIONA?</Text>
          <Text fontWeight="bold" mb={4} fontSize={28}>Entenda como acompanhar os recursos</Text>
          <MyGrid
            cols={[1, 1, 3, 3]}
            gap={6}
            mb={10}
            id="howItWorks"
          >
            {howItWorksItem(0)}
            {howItWorksItem(1)}
            {howItWorksItem(2)}
          </MyGrid>
          <Center mb={12}>
            <PrimaryButton w={btnWidth} size="lg" borderRadius={8} onClick={onClick}>
              Acessar Recursos Agora
            </PrimaryButton>
          </Center>
        </Box>
      </Box>
      <LandingFooter />
    </div>
  );
};

export default LandingPage;
