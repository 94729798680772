import { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Box, Button, Center, Flex, HStack, Input, ScaleFade, Text, Tooltip } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { PrivateContext } from '../../../Private.context';
import { COMMENT_PAGE_STEP, useForumAPI } from '../../../hooks/useForumAPI';
import { ForumCommentWithAnswers } from './components/ForumCommentWithAnswers.component';
import { useDefaultStyles } from '../../../hooks/useDefaultStyles';
import { toast } from '../../../utils/toast';
import { Loading } from '../../../lib/components/Loading';
import { useDevice } from '../../../hooks/useDevice';
import { ForumContext } from '../../../contexts/Forum.context';
import { generateCommentData, getFirstLevelCommentId, getUserPicture } from '../../../utils/forumUtils';
import { ForumProps, ForumUserDataType, ForumUserIdentificationType } from './types/forum.types';
import { EditCommentDrawer } from './components/EditCommentDrawer.component';
import useFirebaseStorageImg from '../../../hooks/useFirebaseStorageImg';
import { EmptyPage } from './components/EmptyPage';
import { WholeAppContext } from '../../agenda/WholeApp.context';
import { PrimaryButton } from '../../../lib/components/PrimaryButton';
import { useCoursesManyInfo } from '../../../api/courses';

export const SPLIT_MARKER = '{|||||}';
export const IS_DEV_ENV = process.env.REACT_APP_ENV === 'DEV';

export const Forum: FC<ForumProps> = ({ id, tag, type, show = true }) => null;

export const ForumOff: FC<ForumProps> = ({ id, tag, type, show = true }) => {
  const {
    createFirstLevelComment,
    createSecondLevelComment,
    createThirdLevelComment,
    createOrGetForumByUID,
    updateForumUserIdMap,
  } = useForumAPI();

  const {
    comments,
    updateComments,
    setComments,
    forumDisclosure,
    commentBoxRef,
    answerTargetComment,
    setAnswerTargetComment,
    forumRef,
    removedComments,
    setAnswersCursorShouldResetFlag,
  } = useContext(ForumContext);
  const { forumCredentials, profile } = useContext(PrivateContext);
  const { hideElementsThatTriggerModals, setAreShortcutsEnabled, areShortcutsEnabled } = useContext(WholeAppContext);
  const [hasAutoDisabledShortcut, setHasAutoDisabledShortcut] = useState(false);
  const coursesInfoQuery = useCoursesManyInfo(profile.courses.map(c => c.course));

  const [currForumId, setCurrForumId] = useState<string>();
  const [currForumNav, setCurrForumNav] = useState(1);
  const [currForumCommentsAmount, setCurrForumCommentsAmount] = useState(0);
  const [commentersList, setCommentersList] = useState<ForumUserDataType[]>();
  const [loadingSendComment, setLoadingSendComment] = useState(false);
  const [refetchLoading, setRefetchLoading] = useState(false);
  const [loadingMoreFirstLevelComments, setLoadingMoreFirstLevelComments] = useState(false);
  const [forumUserIdMap, setForumUserIdMap] = useState<Record<string, ForumUserIdentificationType>>({});
  const [commentBeingCreated, setCommentBeingCreated] = useState<string>('');
  const [forumLoading, setForumLoading] = useState(false);
  const { colors } = useDefaultStyles();
  const device = useDevice();
  const { onOpen } = forumDisclosure;
  const { getURL } = useFirebaseStorageImg();

  const courseTitle = useMemo(() => coursesInfoQuery.data?.coursesManyInfo[0].title ?? '', [
    coursesInfoQuery.data?.coursesManyInfo,
  ]);

  useEffect(() => {
    setForumLoading(true);
    setCurrForumNav(1);
  }, [id, setCurrForumNav]);

  useEffect(() => {
    if (forumCredentials && profile._id) {
      const request = async () => {
        setForumUserIdMap(prev => {
          const name = profile.name.trim() === '' ? 'Aristoter' : profile.name;
          return {
            ...prev,
            [forumCredentials.userId]: {
              name,
              specialist: forumCredentials.isSpecialist,
              aristoClassId: profile._id,
              profileImage: getUserPicture(profile._id),
            },
          };
        });
      };
      request();
    }
  }, [forumCredentials, getURL, profile._id, profile.name]);

  const uid = useMemo(() => {
    return tag ? [tag ?? '', id].join('|') : id;
  }, [id, tag]);

  const redirectUrl = useMemo(() => {
    return `${window.location.origin}/forum-viewer/${uid}`;
  }, [uid]);

  useEffect(() => {
    if (forumCredentials && uid) {
      const request = async () => {
        const data = await createOrGetForumByUID({ uid });
        if (data._id) {
          setCurrForumId(data._id);
        }
        if (data.comment_list) {
          setComments(data.comment_list);
        }
        if (data.commenters_list) {
          setCommentersList(data.commenters_list);
        }
        if (data.comment_count !== undefined) {
          setCurrForumCommentsAmount(data.comment_count);
        }
      };
      request()
        .then(() => setForumLoading(false))
        .catch(console.error);
    } else {
      setForumLoading(false);
    }
  }, [createOrGetForumByUID, forumCredentials, setComments, uid]);

  useEffect(() => {
    updateForumUserIdMap({ setForumUserIdMap, forumUserIdMap, forumUserObjs: commentersList });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commentersList, updateForumUserIdMap]);

  const loadMoreFirstLevelComments = useCallback(async () => {
    setLoadingMoreFirstLevelComments(true);
    try {
      if (currForumId) {
        const data = await createOrGetForumByUID({ uid, nav: currForumNav });
        const _comments = data.comment_list;
        const _commenters = data.commenters_list;
        if (_comments && _commenters) {
          updateComments(_comments);
          setCurrForumNav(prev => prev + 1);
          setCommentersList(_commenters);
        }
      }
    } catch (e) {
      console.error(e);
      toast.error({
        title: 'Não foi possível carregar mais comentários...',
        description: 'Confira sua conexão',
      });
    } finally {
      setLoadingMoreFirstLevelComments(false);
    }
  }, [createOrGetForumByUID, currForumId, currForumNav, uid, updateComments]);

  const refetch = useCallback(async () => {
    setCurrForumNav(1);
    try {
      setRefetchLoading(true);
      if (currForumId) {
        const data = await createOrGetForumByUID({ uid });
        const _comments = data.comment_list;
        if (_comments && data.comment_count) {
          setComments(_comments);
          setCurrForumCommentsAmount(data.comment_count);
        }
      }
      setAnswersCursorShouldResetFlag(prev => (-1 * prev) as 1 | -1);
      return 200;
    } catch (e) {
      console.error(e);
      return 400;
    } finally {
      setRefetchLoading(false);
    }
  }, [createOrGetForumByUID, currForumId, setAnswersCursorShouldResetFlag, setComments, uid]);

  const handleSendCommentClick = useCallback(async () => {
    if (forumCredentials && currForumId && commentBeingCreated.trim().length > 0) {
      setLoadingSendComment(true);
      try {
        if (!answerTargetComment) {
          await createFirstLevelComment({
            author: forumCredentials.userId,
            forumId: currForumId,
            text: commentBeingCreated,
            userCourse: courseTitle,
          });
        } else if (answerTargetComment.isFirstLevel) {
          await createSecondLevelComment({
            content: `${answerTargetComment.userId}${SPLIT_MARKER}${commentBeingCreated}`,
            targetURL: redirectUrl || window.location.origin,
            forumId: currForumId,
            parentCommentId: answerTargetComment.commentId,
            userCourse: courseTitle,
          });
        } else {
          const firstLevelCommentId = getFirstLevelCommentId({
            allComments: comments || [],
            currCommentId: answerTargetComment.commentId,
          });
          if (firstLevelCommentId) {
            await createThirdLevelComment({
              content: `${answerTargetComment.userId}${SPLIT_MARKER}${commentBeingCreated}`,
              targetURL: redirectUrl || window.location.origin,
              forumId: currForumId,
              firstLevelCommentId,
              secondLevelCommentId: answerTargetComment.commentId,
              userCourse: courseTitle,
            });
          }
        }
      } catch (e) {
        console.error(e);
        toast.error({
          title: 'Não foi possível criar o comentário',
          description: 'Verifique sua conexão.',
        });
      } finally {
        if (hasAutoDisabledShortcut) {
          setAreShortcutsEnabled(true);
          setHasAutoDisabledShortcut(false);
        }
        setCommentBeingCreated('');
        await refetch();
        setLoadingSendComment(false);
        commentBoxRef.current?.focus();
      }
    }
  }, [
    answerTargetComment,
    commentBeingCreated,
    commentBoxRef,
    comments,
    courseTitle,
    createFirstLevelComment,
    createSecondLevelComment,
    createThirdLevelComment,
    currForumId,
    forumCredentials,
    hasAutoDisabledShortcut,
    redirectUrl,
    refetch,
    setAreShortcutsEnabled,
  ]);

  const handleEnterPress = useCallback(
    e => {
      if (e.key === 'Enter') {
        handleSendCommentClick();
      }
    },
    [handleSendCommentClick],
  );

  const commentsData = useMemo(() => {
    if (comments && currForumId) {
      return comments
        .filter(x => !x['Resposta?'])
        .map(comment => generateCommentData({
          comment,
          forumUserIdMap,
          forumId: currForumId,
          userId: forumCredentials?.userId || '',
        }));
    }
    return undefined;
  }, [comments, currForumId, forumCredentials?.userId, forumUserIdMap]);

  useEffect(() => {
    if (removedComments.filter(x => x.isFirstLevel).length > 0) {
      refetch();
    }
  }, [refetch, removedComments, removedComments.length]);

  useEffect(() => {
    setComments([]);
  }, [id, setComments]);

  const remainingCommentsCount = useMemo(() => {
    const remains = currForumCommentsAmount - currForumNav * COMMENT_PAGE_STEP;
    return remains;
  }, [currForumCommentsAmount, currForumNav]);

  if (!show) {
    return <></>;
  }

  if (forumLoading) {
    return (
      <HStack justify="center" color={colors.secondary.keep}>
        <Loading color={colors.secondary.keep} />
        <Text>Carregando fórum...</Text>
      </HStack>
    );
  }

  if (type === 'questao-comentada') {
    return (
      <ScaleFade in={!forumLoading} initialScale={0.5}>
        <Flex flexFlow="column" align="center" my={5} transition="all 0.4s" minH={300}>
          <Flex maxW={{ md: 700 }} flexFlow="column" justify="center" w="100%" mt={2} ref={forumRef}>
            <Box w="100%">
              {commentsData
                && commentsData.map(comment => {
                  return (
                    <ForumCommentWithAnswers
                      forumId={comment.forumId}
                      key={comment.commentId}
                      commentData={comment}
                      forumUserIdMap={forumUserIdMap}
                      redirectUrl={redirectUrl}
                      commentOfQuestion
                    />
                  );
                })}
            </Box>
            <HStack w="100%" justify="end">
              {commentsData && commentsData.length > 0 && remainingCommentsCount > 0 && (
                <Center w="100%">
                  {loadingMoreFirstLevelComments ? (
                    <Loading />
                  ) : (
                    <Text my={4} as="button" color={colors.secondary.keep} onClick={loadMoreFirstLevelComments}>
                      Ver mais comentários...
                    </Text>
                  )}
                </Center>
              )}
            </HStack>
            {(!commentsData || (commentsData && commentsData.length === 0)) && (
              <EmptyPage
                label="Por enquanto, essa prova não está completamente comentada.
                  Para solicitar um recurso exclusivo pra sua prova, clique no botão abaixo.
                  A resposta da sua solicitação irá chegar no seu e-mail!"
                variant="normal"
              />
            )}
            <PrimaryButton
              m="0 auto"
              mt={4}
              onClick={() => {
                window.open('https://airtable.com/shrj0KVv8QzkJdjqG', '_blank');
              }}
            >
              Solicitar recurso
            </PrimaryButton>
          </Flex>
        </Flex>
      </ScaleFade>
    );
  }
  return (
    <ScaleFade in={!forumLoading} initialScale={0.5}>
      <Flex flexFlow="column" align="center" my={5} w="100%" transition="all 0.4s" minH={300}>
        <Flex maxW={{ md: 700 }} flexFlow="column" justify="center" w="100%" mt={2} ref={forumRef}>
          <EditCommentDrawer
            commentBeingCreated={commentBeingCreated}
            handleSendCommentClick={handleSendCommentClick}
            loadingSendComment={loadingSendComment}
            setCommentBeingCreated={setCommentBeingCreated}
            answerTargetComment={answerTargetComment}
          />
          <HStack
            border="1px solid"
            borderColor="gray.300"
            bgColor={loadingSendComment || refetchLoading ? colors.lightGray.goAlmostFullDarker : undefined}
            borderRadius={4}
            minH={10}
            px={2}
            mb={4}
            w="100%"
          >
            {answerTargetComment && (
              <>
                <Tooltip label={`Respondendo ${answerTargetComment.userName}`}>
                  <Text noOfLines={1} color={colors.secondary.keep}>
                    {answerTargetComment ? `${answerTargetComment.userName}` : ''}
                  </Text>
                </Tooltip>
                <Text p={0} as="button" onClick={() => setAnswerTargetComment(undefined)}>
                  <FontAwesomeIcon icon={faTimes} />
                </Text>
              </>
            )}
            <Input
              resize="none"
              ref={commentBoxRef}
              _placeholder={{ fontSize: answerTargetComment && device === 'mobile' ? 'xs' : undefined }}
              value={commentBeingCreated}
              rows={1}
              onChange={c => {
                setCommentBeingCreated(c.target.value.replaceAll(SPLIT_MARKER, ''));
                if (areShortcutsEnabled) {
                  setAreShortcutsEnabled(false);
                  setHasAutoDisabledShortcut(true);
                }
                if (hasAutoDisabledShortcut && c.target.value === '') {
                  setAreShortcutsEnabled(true);
                  setHasAutoDisabledShortcut(false);
                }
              }}
              onKeyDown={handleEnterPress}
              disabled={loadingSendComment}
              color={loadingSendComment ? 'gray' : undefined}
              variant="unstyled"
              placeholder="Escreva um comentário..."
            />
            {!loadingSendComment
            && (forumCredentials?.isSpecialist || IS_DEV_ENV)
            && !hideElementsThatTriggerModals && (
              <Button variant="unstyled" color={colors.secondary.keep} onClick={onOpen}>
                <FontAwesomeIcon icon="edit" />
              </Button>
            )}
            {loadingSendComment || refetchLoading ? (
              <Loading />
            ) : (
              <Text as="button" color={colors.secondary.keep} onClick={handleSendCommentClick}>
                Publicar
              </Text>
            )}
          </HStack>
          <Box w="100%">
            {commentsData
              && commentsData.map(comment => {
                return (
                  <ForumCommentWithAnswers
                    forumId={comment.forumId}
                    key={comment.commentId}
                    commentData={comment}
                    forumUserIdMap={forumUserIdMap}
                    redirectUrl={redirectUrl}
                  />
                );
              })}
          </Box>
          <HStack w="100%" justify="end">
            {commentsData && remainingCommentsCount > 0 && (
              <Center w="100%">
                {loadingMoreFirstLevelComments ? (
                  <Loading />
                ) : (
                  <Text my={4} as="button" color={colors.secondary.keep} onClick={loadMoreFirstLevelComments}>
                    Ver mais comentários...
                  </Text>
                )}
              </Center>
            )}
          </HStack>
          {commentsData && commentsData.length === 0 && <EmptyPage label="Sem comentários por aqui..." />}
        </Flex>
      </Flex>
    </ScaleFade>
  );
};
