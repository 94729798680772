import {
  Button,
  Center,
  HStack,
  Modal, ModalContent, ModalOverlay,
} from '@chakra-ui/react';
import { faCaretLeft, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC, useContext } from 'react';
import { FlashcardType } from '../../../../api/flashcards';
import { useDefaultStyles } from '../../../../hooks/useDefaultStyles';
import { FlashcardsCreationContext } from '../../contexts/FlashcardsCreation.context';
import { FlipEffect } from '../../transitions/FlipEffect';
import { FlashcardViewerFace } from './FlashcardViewerFace';

type FlashcardViewerModalType = {
  isOpen: boolean,
  onClose: () => void,
  data?: FlashcardType,
  navigator?: {
    prev: {
      onClick: () => void,
      disabled: boolean,
    },
    next: {
      onClick: () => void,
      disabled: boolean,
    }
  },
}

export const FlashcardViewerModal: FC<FlashcardViewerModalType> = ({
  isOpen, onClose, data, navigator,
}: FlashcardViewerModalType) => {
  const {
    flashcardViewerModalFlip,
    setFlashcardViewerModalFlip,
  } = useContext(FlashcardsCreationContext);
  const { colors } = useDefaultStyles();
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size="sm">
      <ModalOverlay />
      <ModalContent mx={2} borderRadius={20} bgColor="transparent" boxShadow="none">
        <FlipEffect
          front={(
            <FlashcardViewerFace
              onClose={onClose}
              setFlip={setFlashcardViewerModalFlip}
              face="front"
              data={data}
            />
          )}
          back={<FlashcardViewerFace setFlip={setFlashcardViewerModalFlip} face="back" data={data} />}
          dimensions={{ w: '100%', h: 480 }}
          flipMode="setter"
          flip={flashcardViewerModalFlip}
        />
        {navigator && (
          <Center mt={3}>
            <HStack>
              <Button
                onClick={navigator.prev.onClick}
                disabled={navigator.prev.disabled}
                color={colors.secondary.keep}
              >
                <FontAwesomeIcon icon={faCaretLeft} />
              </Button>
              <Button
                onClick={navigator.next.onClick}
                disabled={navigator.next.disabled}
                color={colors.secondary.keep}
              >
                <FontAwesomeIcon icon={faCaretRight} />
              </Button>
            </HStack>
          </Center>
        )}
      </ModalContent>
    </Modal>

  );
};
