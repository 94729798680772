import { useMemo, useState } from 'react';
import { ref, getDownloadURL, uploadBytes } from 'firebase/storage';

import { storage } from '../client/firebase';

const cache: { [k: string]: string } = {};

async function addFileURL(path: string): Promise<typeof cache> {
  if (!path) {
    throw new Error('invalid path.');
  }
  if (cache[path]) {
    return cache;
  }
  try {
    const storageUrl = await getDownloadURL(ref(storage, path));
    cache[path] = storageUrl;
  } catch (error) {
    cache[path] = '/pdf/pdf-broken.pdf';
    console.error(error);
  }
  return { ...cache };
}

async function uploadFromBase64(path: string, b64: string): Promise<string> {
  const contentType = /data:([^;]*);base64,/.exec(b64)?.[1];
  if (!contentType) {
    throw new Error('Missing content type');
  }
  const data = b64.replace(/data:([^;]*);base64,/, '');
  // eslint-disable-next-line no-useless-escape
  // const ext = contentType.replace(/[^\/]*\//, '').split('+')[0];
  const chars = atob(data);
  const bytes = new Uint8Array(Array.from({ length: chars.length }, (_, i) => chars.charCodeAt(i)));
  const snap = await uploadBytes(ref(storage, path), bytes, { contentType });
  return snap.ref.fullPath;
}

export interface FirebaseStorageHook {
  getURL: (path: string) => string;
  uploadFromBase64: (path: string, b64: string) => Promise<string>;
}

export default function useFirebaseStorage(): FirebaseStorageHook {
  const [hookCache, setHookCache] = useState(cache);
  return useMemo(() => {
    const getURL = (path: string): string => {
      if (hookCache[path]) {
        return hookCache[path];
      }
      addFileURL(path)
        .then(setHookCache)
        .catch(e => console.error(e));

      return '';
    };

    return { getURL, uploadFromBase64 };
  }, [hookCache]);
}
