import { TheoreticalTypes } from '../api/agenda/agenda';

export const getTheoreticalType = (): TheoreticalTypes => {
  const { pathname } = window.location;
  if (pathname.includes('agenda/estudo-teorico')) {
    return 'theoreticalStudy';
  }
  if (pathname.includes('agenda/revisao-inteligente')) {
    return 'smartReview';
  }
  if (pathname.includes('agenda/revisao-teorica')) {
    return 'theoreticalReview';
  }
  if (pathname.includes('agenda/simulado-inteligente')) {
    return 'smartExams';
  }
  if (pathname.includes('agenda/simulado')) {
    return 'mocks';
  }
  if (pathname.includes('agenda/prova')) {
    return 'exams';
  }
  if (pathname.includes('agenda/favoritos')) {
    return 'favorites';
  }
  if (pathname.includes('/atividades-extras')) {
    if (pathname.includes('estudo-teorico')) {
      return 'extras-theoreticalStudy';
    }
    if (pathname.includes('revisao-teorica')) {
      return 'extras-theoreticalReview';
    }
    if (pathname.includes('revisao-inteligente')) {
      return 'extras-smartReview';
    }
    if (pathname.includes('simulado-inteligente')) {
      return 'extras-smartExam';
    }
    if (pathname.includes('prova')) {
      return 'extras-exam';
    }
  }
  return 'extras';
};
