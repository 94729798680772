import {
  gql, QueryResult, useMutation, useQuery,
} from '@apollo/client';
import { useCallback } from 'react';
import { toast } from 'react-toastify';
import { PreChoiceInterface } from '../agenda/mock-exam';
import { Question } from '../agenda/questions';
import { Activity } from './extra-activities';

interface ExtraExam {
  _id: string;
  profile: string;
  finished: boolean;
  preChoice: PreChoiceInterface[];
  createdAt: Date;
  updatedAt: Date;
  institution: string;
  uf: string;
  year: number;
  questions: Question[];
}

interface GetExtraExamsInput {
  id: string;
}

interface GetExtraExamsOutput {
  getExamExtraActivityDetails: ExtraExam;
}

const GET_EXTRA_EXAMS = gql`
  query GetExamExtraActivityDetails ($id: ObjectId!) {
    getExamExtraActivityDetails (_id: $id) {
      _id
      profile
      finished
      preChoice {
        questionId
        selected
        essay
      }
      createdAt
      updatedAt
      institution
      uf
      year
      questions {
        _id
        body
        choices
        answersProportion
        correctChoice
        institution
        uf
        year
        examOrder
        examType
        complement
        classification
        difficulty
        createdAt
        updatedAt
        comment {
          author
          profile
          title
          body
          createdAt
          updatedAt
        }
        answer
        isEssay
        essay
      }
    }
  }
`;

export function useGetExtraExam({
  id,
}: GetExtraExamsInput): QueryResult<GetExtraExamsOutput, GetExtraExamsInput> {
  return useQuery<GetExtraExamsOutput, GetExtraExamsInput>(GET_EXTRA_EXAMS, {
    variables: {
      id,
    },
    fetchPolicy: 'network-only',
    skip: id === '',
  });
}

interface ActivityExam extends Activity {
  institution: string;
  state: string;
  year: number;
  questions: Question[];
}

interface CreateExamExtraActivityInput {
  template: string;
}

interface CreateExamExtraActivityOutput {
  createExamExtraActivity: ActivityExam;
}

const CREATE_EXAM_EXTRA_ACTIVITY = gql`
  mutation CreateExamExtraActivity ($template: ObjectId!) {
    createExamExtraActivity (template: $template) {
      _id
    }
  }
`;

interface UseExtraExamsInterface {
  onCreateExamExtraActivity: (props: CreateExamExtraActivityInput) => Promise<string | undefined>;
}

export function useExtraExams(): UseExtraExamsInterface {
  const [createActivity] = useMutation<
    CreateExamExtraActivityOutput, CreateExamExtraActivityInput
  >(CREATE_EXAM_EXTRA_ACTIVITY, {
    onCompleted: data => {
      if (data !== null) {
        toast.success('Atividade de prova na íntegra criada com sucesso!');
      }
    },
  });

  const handleCreate = useCallback(async ({ template }: CreateExamExtraActivityInput) => {
    const res = await createActivity({
      variables: {
        template,
      },
    });

    return res.data?.createExamExtraActivity._id;
  }, [createActivity]);

  return {
    onCreateExamExtraActivity: handleCreate,
  };
}
