import { Box, BoxProps } from '@chakra-ui/react';
import { useDefaultStyles, useRGBColor } from '../../../hooks/useDefaultStyles';

export function QuestionWrapper({
  ...rest
}: BoxProps) {
  const { colors } = useDefaultStyles();
  const { toRGB } = useRGBColor();
  return (
    <Box
      // backgroundColor="white"
      borderRadius={12}
      p={4}
      boxShadow={`1px 1px 1px 3px ${toRGB(colors.darkGray.goPallete700)}`}
      border="solid 2px"
      borderColor={colors.secondary.goDarker}
      {...rest}
    />
  );
}
