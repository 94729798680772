import {
  FC, useContext, useMemo,
} from 'react';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useParams } from 'react-router-dom';
import { useMindmaps } from '../../../api/agenda/mindmaps';
import Flex from '../../../lib/components/Flex';
import AgendaMindMaps from './AgendaMindMaps';
import MindMapEmpty from './MindMapEmpty';
import { PrivateContext } from '../../../Private.context';
import { getTheoreticalType } from '../../../hooks/getTheoreticalType';

const AgendaMindMapsData: FC = () => {
  const params = useParams<{ lessonId: string }>();

  const { agenda } = useContext(PrivateContext);

  const theoreticalType = getTheoreticalType();

  const agendaIds = useMemo(() => {
    if (theoreticalType === 'theoreticalStudy') {
      const lessonStudy = agenda?.theoreticalStudy.find(item => item.lessonId === params.lessonId);
      return lessonStudy;
    }
    if (theoreticalType === 'theoreticalReview') {
      const lessonReview = agenda?.theoreticalReview.find(item => item.lessonId === params.lessonId);
      return lessonReview;
    }

    return undefined;
  }, [agenda?.theoreticalReview, agenda?.theoreticalStudy, params.lessonId, theoreticalType]);

  const mindmapsObj = agendaIds?.mindmaps;

  const query = useMindmaps({
    ids: mindmapsObj || [],
  });

  const obj = useMemo(() => {
    if (query.data) {
      return ({
        loading: query.loading,
        data: query.data.mindmapMany,
        length: query.data.mindmapMany.length,
      });
    }
    return ({
      loading: query.loading,
      data: [],
      length: 0,
    });
  }, [query]);

  if (obj.loading) {
    return (
      <Flex align="center" justify="center" style={{ minHeight: 400 }}>
        <FontAwesomeIcon icon={faSpinner} spin size="5x" className="color-dark-gray" />
      </Flex>
    );
  }

  if (!obj.loading && obj.length > 0 && obj.data) {
    return (
      <AgendaMindMaps
        data={obj.data}
      />
    );
  }

  return <MindMapEmpty />;
};

export default AgendaMindMapsData;
