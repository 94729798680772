import { FC, useCallback, useEffect, useState } from 'react';
import { PrimumNpsModalShow } from './primum-nps-modal/PrimumNpsModalShow';
import { NpsModalShow } from './NpsModalShow';
import { useOptimizerApi } from '../../../hooks/useOptimizerApi';

export const NpsModalService: FC = () => {
  const [openNpsPrimumModal, setOpenPrimumNpsModal] = useState(false);
  const [openNpsModal, setOpenNpsModal] = useState(false);

  const {
    shouldExecuteTemp,
    shouldExecute,
  } = useOptimizerApi();

  const handlePrimumNpsModal = useCallback(() => {
    setOpenPrimumNpsModal(!openNpsPrimumModal);
  }, [openNpsPrimumModal]);

  const handleNpsModal = useCallback(() => {
    setOpenNpsModal(!openNpsModal);
  }, [openNpsModal]);

  const execute = useCallback(async () => {
    try {
      const temp = await shouldExecuteTemp();
      if (temp) {
        setOpenNpsModal(true);
        return;
      }

      const nps = await shouldExecute();
      setOpenPrimumNpsModal(nps);
    } catch (error) {
      console.error('Error when executing nps survey');
    }
  }, [shouldExecute, shouldExecuteTemp]);

  useEffect(() => {
    execute();
  }, [execute]);

  return (
    <>
      <PrimumNpsModalShow open={openNpsPrimumModal} setOpen={handlePrimumNpsModal} />
      <NpsModalShow open={openNpsModal} setOpen={handleNpsModal} />
    </>
  );
};
