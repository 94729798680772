import { useMutation } from '@apollo/client';
import moment from 'moment';
import { faCheckCircle, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Box, Button, Text } from '@chakra-ui/react';
import { faCircle } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, useCallback, useState } from 'react';
import { FinishTheoreticalStudyInput, FINISH_THEORETICAL_STUDY } from '../../../api/theme-list';
import ConfirmModal from '../../../lib/components/ConfirmModal';
import errorHandler from '../../../api/error/errorHandler';
import { useDefaultStyles } from '../../../hooks/useDefaultStyles';

interface FinishThemeBtnProps {
  theoreticalFinishedAt: string;
  lock: boolean;
  lessonId: string;
  lessonTitle: string;
  asIcon?: boolean;
  onFinish?: () => Promise<void>;
}

const FinishThemeBtn: FC<FinishThemeBtnProps> = ({
  theoreticalFinishedAt,
  lock,
  lessonId,
  lessonTitle,
  asIcon = false,
  onFinish,
}) => {
  const [finishTheoreticalStudy] = useMutation<boolean, FinishTheoreticalStudyInput>(FINISH_THEORETICAL_STUDY, {
    onError: errorHandler,
  });
  const [loading, setLoading] = useState(false);
  const [finished, setFinished] = useState(Boolean(theoreticalFinishedAt));
  const [modal, setModal] = useState(false);
  const { colors } = useDefaultStyles();

  const handleFinishTheoreticalStudy = useCallback(async () => {
    setLoading(true);
    try {
      await finishTheoreticalStudy({
        variables: {
          lesson: lessonId,
        },
      });
      if (onFinish) {
        await onFinish();
      }
      setFinished(true);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
      setModal(false);
    }
  }, [finishTheoreticalStudy, lessonId, onFinish]);

  return (
    <>
      {finished && !asIcon ? (
        <Text tag="small" tooltip={moment(theoreticalFinishedAt).format('[Concluído em] DD[/]MM[/]YYYY')}>
          Concluído
        </Text>
      ) : ''}

      {finished && asIcon ? (
        <Box
          color={colors.green.goDarker}
          // padding={2}
          tooltip={moment(theoreticalFinishedAt).format('[Concluído em] DD[/]MM[/]YYYY')}
          fontSize="1.3rem"

        >
          <FontAwesomeIcon icon={faCheckCircle} />
        </Box>
      ) : ''}

      {!finished ? (
        <Box title="Não concluído" color={lock ? colors.secondary.goLighter : colors.darkGray.keep}>
          {!loading ? (
            <Button
              marginX={-5}
              variant="unstyled"
              fontSize="1.3rem"
              onClick={() => setModal(true)}
              disabled={!lock}
            >
              <FontAwesomeIcon icon={faCircle} />
            </Button>
          ) : (
            <FontAwesomeIcon icon={faSpinner} spin />
          )}
        </Box>
      ) : ''}

      <ConfirmModal
        isOpen={modal}
        onConfirm={handleFinishTheoreticalStudy}
        onDecline={() => setModal(false)}
        title="Conclusão de tema"
        subtitle={`Você gostaria de concluir o tema "${lessonTitle}"? Esse processo é irreversível.`}
        confirmText="Concluir"
        declineText="Cancelar"
        loadingText="Concluindo..."
        icon={<FontAwesomeIcon icon={faCheckCircle} />}
        loading={loading}
      />
    </>
  );
};

export default FinishThemeBtn;
