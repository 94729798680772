import { Button, Flex, HStack, Text, useDisclosure } from '@chakra-ui/react';
import { faChartLine, faLaptop, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC, useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useExamStatistics } from '../../../api/aristo-compass/exam-statistics';
import { useDiagnosticExam } from '../../../api/diagnosis';
import { InstitutionTarget } from '../../../api/profile';
import { useDefaultStyles } from '../../../hooks/useDefaultStyles';
import { toast } from '../../../utils/toast';
import { AristoCompassTemplate } from '../template';
import { CompassActivityCard } from '../_components/CompassActivityCard';
import { ExamsDifficultiesList } from '../_components/ExamsDifficultiesList';
import { ExamsThemesList } from '../_components/ExamsThemesList';
import { SideCardsContainer } from '../_components/SideCardsContainer';
import { CompassStatisticsTutorial } from '../_components/CompassStatisticsTutorial';
import { useLocalStorage } from '../../../hooks/useLocalStorage';
import { testThePlatformFormLink } from '../_components/Header';

export const StatisticsPage: FC = () => {
  // TODO: Activate partial diagnostic route
  const { colors } = useDefaultStyles();
  const themesBoardWidth = 60;
  const history = useHistory();
  const { search } = useLocation();
  const { onOpen, isOpen, onClose } = useDisclosure();
  const setCompass = useLocalStorage('@Aristoclass:isCompass')[1];
  const [loadingCreateDiagnosis, setLoadingCreateDiagnosis] = useState(false);
  // const [loadingCreatePartialDiagnosis, setLoadingCreatePartialDiagnosis] = useState(false);
  const [selectedInstitutions, setSelectedInstitutions] = useState<InstitutionTarget[]>([]);

  useEffect(() => {
    if (localStorage.getItem('@plataformaJJMentoria:institutions') !== null) {
      const inst = localStorage.getItem('@plataformaJJMentoria:institutions') as string;
      setSelectedInstitutions(JSON.parse(inst));
    }
  }, []);

  const { data, loading } = useExamStatistics(selectedInstitutions);

  const institutionData = data?.getExamStatistics;

  // useEffect(() => {
  //   if (localStorage.getItem('@plataformaJJMentoria:id')
  //     && !JSON.parse(localStorage.getItem('@plataformaJJMentoria:finished') ?? 'false')) {
  //     history.push('/diagnostico/prova');
  //   }
  // }, [history]);

  const { handleCreateDiagnosticExam } = useDiagnosticExam({
    _id: '',
    templateId: new URLSearchParams(search).get('id') || undefined,
  });

  const onDoDiagnosisClick = useCallback(async () => {
    setCompass(true);
    setLoadingCreateDiagnosis(true);
    try {
      const result = await handleCreateDiagnosticExam(selectedInstitutions);
      if (result) {
        localStorage.setItem('@plataformaJJMentoria:id', result);
        history.push('/diagnostico');
      } else {
        throw new Error();
      }
    } catch (error) {
      toast.error('Erro ao criar o simulado!');
      console.error(error);
    } finally {
      setLoadingCreateDiagnosis(false);
    }
  }, [handleCreateDiagnosticExam, history, selectedInstitutions, setCompass]);

  // const onDoPartialDiagnosisClick = useCallback(async () => {
  //   setLoadingCreatePartialDiagnosis(true);
  //   try {
  //     const result = await handleCreateDiagnosticExam(selectedInstitutions);
  //     if (result) {
  //       localStorage.setItem('@plataformaJJMentoria:partialDiagnosticId', result);
  //       history.push('/aristo-compass/diagnostico-parcial/questoes?compass=true');
  //     } else {
  //       throw new Error();
  //     }
  //   } catch (error) {
  //     toast.error('Erro ao criar o simulado!');
  //     console.error(error);
  //   } finally {
  //     setLoadingCreatePartialDiagnosis(false);
  //   }
  // }, [handleCreateDiagnosticExam, history, selectedInstitutions]);

  return (
    <AristoCompassTemplate>
      <CompassStatisticsTutorial isOpen={isOpen} onClose={onClose} />
      <Flex
        w="100%"
        flexFlow="column"
        justify="flex-start"
        px={{ base: 5, md: '5%' }}
        py={10}
        mb={5}
      >
        <HStack align="center" mb="1rem">
          <Text
            fontWeight="bold"
            fontSize="2rem"
            color={colors.primary.keep}
          >
            Suas provas
          </Text>
          <Button
            variant="solid"
            borderColor={colors.secondary.keep}
            color={colors.secondary.keep}
            onClick={onOpen}
            leftIcon={<FontAwesomeIcon icon={faQuestionCircle} />}
          >
            Tutorial
          </Button>
        </HStack>
        <Flex
          w="100%"
          alignItems="flex-start"
          justifyContent="space-between"
          flexFlow={{ base: 'column', lg: 'row' }}
          style={{ gap: 12 }}
        >
          <Flex
            w={{ base: '100%', lg: `${themesBoardWidth}%` }}
            flexFlow="column"
            style={{ gap: 20 }}
            justifyContent="space-between"
          >
            <ExamsDifficultiesList
              showDropdown={institutionData && institutionData.length > 1}
              institutionData={institutionData}
              loading={loading}
            />
            <ExamsThemesList
              institutionData={institutionData}
              loading={loading}
            />
          </Flex>
          <SideCardsContainer
            description={[
              `Nós disponibilizamos um método inovador para você avaliar seu desempenho.
              Faça um simulado e lhe daremos uma lista com seus principais pontos fracos!`,
              `Aproveite e teste nosso curso gratuitamente! Clique abaixo para conhecer
              a Revalidação e a Residência Médica!`,
            ]}
            title="Gostaria de praticar seus temas?"
            width={{ base: '100%', lg: `${98 - themesBoardWidth}%` }}
          >
            {/* <CompassActivityCard
              title="Diagnóstico parcial"
              description="Fazer algumas questões e flashcards dos temas."
              estimatedTime="15 - 20 min"
              onClick={onDoPartialDiagnosisClick}
              icon={<FontAwesomeIcon icon={faTasks} />}
              disabled={loadingCreatePartialDiagnosis}
              isLoading={loadingCreatePartialDiagnosis}
            /> */}
            <CompassActivityCard
              title="Diagnóstico completo"
              description="Fazer um simulado com 60 questões."
              estimatedTime="120 - 180 min"
              onClick={onDoDiagnosisClick}
              icon={<FontAwesomeIcon icon={faChartLine} />}
              disabled={loadingCreateDiagnosis}
              isLoading={loadingCreateDiagnosis}
            />
            <CompassActivityCard
              title="Teste gratuito"
              description="Faça seu cadastro e tenha acesso a 15 dias gratuitos!"
              onClick={() => window.open(testThePlatformFormLink)}
              icon={<FontAwesomeIcon icon={faLaptop} />}
              btnLabel="Eu quero!"
            />
          </SideCardsContainer>
        </Flex>
      </Flex>
    </AristoCompassTemplate>
  );
};
