import React, { FC, useContext, useMemo } from 'react';
import { Route, Switch, useParams, Redirect } from 'react-router-dom';
import MenuTabs from '../../../lib/components/MenuTabs';
import AgendaQuestionsData from '../types/AgendaQuestionsData';
import AgendaVideoData from '../types/AgendaVideoData';
import AgendaTextsData from '../types/AgendaTextsData';
import AgendaMindMapsData from '../types/AgendaMindMapsData';
import AgendaFlashcardsData from '../types/AgendaFlashcardsData';
import { PrivateContext } from '../../../Private.context';
import { TheoreticalLessonNavigator } from '../_components/TheoreticalLessonNavigator';
import { ForumWrapper } from '../_components/ForumWrapper';

const AgendaTheoreticalReview: FC = () => {
  const { agenda } = useContext(PrivateContext);
  const params = useParams<{ lessonId: string }>();

  const menuItems = useMemo(() => {
    if (agenda) {
      const lesson = agenda.theoreticalReview.find(item => item.lessonId === params.lessonId);
      return ({
        questao: Boolean(lesson && lesson.questions.length > 0),
        video: Boolean(lesson && lesson.videos.length > 0),
        apostila: Boolean(lesson && lesson.texts.length > 0),
        'mapa-mental': Boolean(lesson && lesson.mindmaps.length > 0),
        flashcard: Boolean(lesson && lesson.flashcards.length > 0),
      });
    }

    return {
      questao: false,
      video: false,
      apostila: false,
      'mapa-mental': false,
      flashcard: false,
    };
  }, [agenda, params.lessonId]);

  const contentType = useMemo(() => {
    return Object.entries(menuItems).filter(item => item[1])[0]?.[0];
  }, [menuItems]);

  const lessonClassification = useMemo(() => {
    if (params.lessonId && agenda) {
      const currentLesson = agenda.theoreticalStudy.find(l => l.lessonId === params.lessonId);
      return currentLesson?.classification.join('|') ?? '';
    }
    return '';
  }, [agenda, params.lessonId]);

  const classification = useMemo(() => {
    if (params.lessonId && agenda) {
      const currentLesson = agenda.theoreticalStudy.find(l => l.lessonId === params.lessonId);
      return currentLesson?.classification?.filter(e => e !== '') || [];
    }
    return [];
  }, [agenda, params.lessonId]);

  return (
    <>
      <TheoreticalLessonNavigator
        lessons={agenda?.theoreticalReview}
        type="review"
      />
      <MenuTabs
        menuItems={menuItems}
        path={`/agenda/revisao-teorica/${params.lessonId}`}
      />
      <Switch>
        <Route
          exact
          path="/agenda/revisao-teorica/:lessonId"
          render={() => (
            <Redirect
              to={`/agenda/revisao-teorica/${params.lessonId}/${contentType ?? ''}${window.location.search}`}
            />
          )}
        />
        <Route path="/agenda/revisao-teorica/:lessonId/questao" component={AgendaQuestionsData} />
        <Route path="/agenda/revisao-teorica/:lessonId/video" component={AgendaVideoData}>
          <AgendaVideoData />
        </Route>
        <Route path="/agenda/revisao-teorica/:lessonId/apostila" component={AgendaTextsData}>
          {/* <ForumWrapper
            key="ParetoGuide"
            forumId={params.lessonId}
            forumTag={lessonClassification}
            contentType="ParetoGuide"
            classification={classification}
          > */}
          <AgendaTextsData />
          {/* </ForumWrapper> */}
        </Route>
        <Route path="/agenda/revisao-teorica/:lessonId/mapa-mental" component={AgendaMindMapsData}>
          <ForumWrapper
            key="MindMap"
            forumId={params.lessonId}
            forumTag={lessonClassification}
            contentType="MindMap"
            classification={classification}
          >
            <AgendaMindMapsData />
          </ForumWrapper>
        </Route>
        <Route path="/agenda/revisao-teorica/:lessonId/flashcard" component={AgendaFlashcardsData}>
          <AgendaFlashcardsData />
        </Route>
      </Switch>
    </>
  );
};

export default AgendaTheoreticalReview;
