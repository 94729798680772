import {
  FC, useMemo,
} from 'react';
import ReactMarkdown from 'react-markdown';
import { Text, Flex, Button } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { useHistory, useParams } from 'react-router-dom';
import articles from './articles.json';
import { useDefaultStyles, useRGBColor } from '../../hooks/useDefaultStyles';

export const ArticlesPage: FC = () => {
  const { toRGB } = useRGBColor();
  const { colors } = useDefaultStyles();
  const history = useHistory();
  const { articleId } = useParams<{ articleId: string }>();

  const currArticle = useMemo(() => articles.find(x => x.id === articleId), [articleId]);

  if (!articleId) {
    return (
      <Flex w="100%" flexFlow="column" align="center" p={10}>
        {
          articles.map(art => (
            <Flex
              w="90%"
              key={Math.random()}
              flexFlow="column"
              borderBottom={`solid 3px ${toRGB(colors.secondary.keep)}`}
              p={2}
              onClick={() => history.push(`/artigos/${art.id}`)}
              as="button"
            >
              <Text
                color={colors.secondary.keep}
                fontWeight="bold"
              >
                {art.title}
              </Text>

            </Flex>
          ))
        }
      </Flex>
    );
  }

  return (
    <Flex
      w="100%"
      flexFlow="column"
      align="center"
      p={10}
    >
      <Flex
        w={{ base: '100%', md: '80%' }}
        borderBottom={`solid 3px ${toRGB(colors.secondary.keep)}`}
        p={2}
        align="center"
      >
        <Button
          variant="ghost"
          onClick={() => history.push('/artigos')}
        >
          <FontAwesomeIcon icon={faArrowLeft} />
        </Button>
        <Text
          color={colors.secondary.keep}
          fontWeight="bold"
        >
          {currArticle?.title}
        </Text>
      </Flex>
      <Flex w={{ base: '100%', md: '80%' }} mt={4} flexFlow="column">
        <ReactMarkdown className="inline markdown">
          {currArticle?.body || ''}
        </ReactMarkdown>
      </Flex>
    </Flex>
  );
};
