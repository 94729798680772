import { Center, Circle, VStack } from '@chakra-ui/react';
import { FC } from 'react';
import { useDefaultStyles, useRGBColor } from '../../../../hooks/useDefaultStyles';

export const LoadingCards: FC = () => {
  const { colors } = useDefaultStyles();
  const { toRGB } = useRGBColor();
  return (
    <VStack m={3}>
      <Center
        className="bounce-up"
        boxShadow={`4px 4px 0px 0px ${toRGB(colors.deck.shadow)}`}
        w={140}
        h={170}
        borderRadius={10}
        margin={2}
        opacity={1}
        transition="all 1s"
        bgColor={colors.primary.goALittleLighter}
      >
        <Circle
          className="loading-circle"
          size={16}
          bgColor={colors.secondary.keep}
          background={`radial-gradient(circle at 20px 20px,
            ${toRGB(colors.secondary.keep)},
            ${toRGB(colors.primary.keep)})`}
        />
      </Center>
      <Center
        className="bounce-down"
        background={`linear-gradient(transparent 20%, ${toRGB(colors.white.goAlmostFullDarker)} 90%)`}
        w={140}
        opacity={0.3}
        h={10}
        borderRadius={10}
        margin={2}
        transition="opacity 1s"
        bgColor={colors.primary.goALittleLighter}
      />
    </VStack>
  );
};
