import {
  FC, useMemo, useState,
} from 'react';
import { Box } from '@chakra-ui/react';
import { PlaylistType, useVideosJJPlusCategories } from '../../api/aristoplus';
import { Loading } from '../../lib/components/Loading';
import { PlaylistSet } from './PlaylistSet';
import { DefaultSearchBar } from '../_components/DefaultSearchBar.component';
import { EmptyPage } from '../_components/Forum/components/EmptyPage';

export const Playlists: FC = () => {
  const [searchKey, setSearchKey] = useState('');
  const categoriesQuery = useVideosJJPlusCategories();

  const categories = useMemo(() => {
    if (!categoriesQuery.data || categoriesQuery.loading) {
      return undefined;
    }
    return categoriesQuery.data.categories;
  }, [categoriesQuery.data, categoriesQuery.loading]);

  const recentPlaylists = useMemo(() => {
    return categories?.reduce((acc, curr) => {
      acc.push(...curr.playlists.map(p => ({ ...p, category: curr.category })));
      return acc;
    }, [] as PlaylistType[])
      .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
      .slice(0, 20);
  }, [categories]);

  const allPlaylistsWithCategories = useMemo(() => {
    const _playlistsWithCategories = [...(categories || [])];
    if (!searchKey) {
      _playlistsWithCategories.unshift({
        category: 'Playlists recentes',
        playlists: recentPlaylists || [],
      });
    }
    return _playlistsWithCategories.map(x => ({
      category: x.category,
      playlists: x.category.toLowerCase().includes(searchKey)
        ? x.playlists
        : x.playlists?.filter(y => y.name.toLowerCase().includes(searchKey.toLowerCase())),
    }))
      .filter(x => x.playlists && x.playlists.length > 0);
  }, [categories, recentPlaylists, searchKey]);

  if (categoriesQuery.loading) {
    return <Loading fontSize="5xl" mt={8} />;
  }

  return (
    <Box>
      <DefaultSearchBar
        searchKey={searchKey}
        setSearchKey={setSearchKey}
        placeholder="Pesquisar playlist ou seção..."
      />
      {!allPlaylistsWithCategories.length
        ? <EmptyPage label="Nada por aqui..." />
        : allPlaylistsWithCategories.map(playlists => (
          <PlaylistSet
            category={playlists.category}
            key={Math.random().toString(36)}
            playlists={playlists.playlists}
          />
        ))}
    </Box>
  );
};
