import {
  ApolloClient, ApolloLink, HttpLink, InMemoryCache,
} from '@apollo/client';

import { setContext } from '@apollo/client/link/context';
import { auth } from './firebase';

const uri = String(process.env.REACT_APP_NOTIFICATION_URL);

const httpLink = new HttpLink({
  uri,
});

const authLink = setContext(async (_, { headers }) => {
  const { currentUser } = auth;

  if (!currentUser) {
    return {
      headers,
    };
  }

  return {
    headers: {
      ...headers,
      Authorization: `Bearer ${process.env.REACT_APP_NOTIFICATION_TOKEN}`,
    },
  };
});

const notificationClient = new ApolloClient({
  link: ApolloLink.from([authLink, httpLink]),
  cache: new InMemoryCache(),
});

export default notificationClient;
