import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC } from 'react';
import { Box, Flex, LinkBox, LinkOverlay, Text } from '@chakra-ui/react';
import { useDefaultStyles } from '../../../hooks/useDefaultStyles';

interface ThemeListAssetCardProps {
  title: string;
  subtitle: string[];
  icon: IconDefinition;
  url: string;
}

const ThemeListAssetCard: FC<ThemeListAssetCardProps> = ({
  title, subtitle, url, icon,
}) => {
  const { cardBorder, colors } = useDefaultStyles();
  return (
    <LinkBox
      w="100%"
      // color={colors.darkGray.goDarker}
      _hover={{
        border: 'solid 2px',
        borderColor: colors.secondary.keep,
        color: colors.secondary.keep,
        bgColor: colors.lighter.goAlmostFullDarker,
      }}
      {...cardBorder}
      // borderColor="gray.500"
      marginRight=".3rem"
      align="center"
    >
      <Flex
        align="center"
        justify="start"
      >
        <FontAwesomeIcon icon={icon} size="lg" className="mx-1" />
        <Box flex={1} my={2} textAlign="left">
          <LinkOverlay href={url} target="_blank">
            <Text fontWeight="bold">{title}</Text>
          </LinkOverlay>
          <Text fontSize="sm" pt={1} noOfLines={2}>{subtitle.join(' - ')}</Text>
        </Box>
      </Flex>
    </LinkBox>
  );
};

export default ThemeListAssetCard;
