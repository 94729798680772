import {
  Alert,
  Drawer, DrawerBody, DrawerCloseButton,
  DrawerContent, DrawerHeader, DrawerOverlay,
  Heading, ListItem, OrderedList, Text,
} from '@chakra-ui/react';
import { FC, useState } from 'react';
import { useDefaultStyles } from '../../../../hooks/useDefaultStyles';
import { useDevice } from '../../../../hooks/useDevice';
import { DrawerTopic } from './DrawerTopic';
import { ImagePopOver } from './ImagePopOver';

type FlashcardsCreationInfoType = {
  isOpen: boolean,
  onClose: () => void,
}

export const FlashcardsCreationInfo: FC<FlashcardsCreationInfoType> = ({
  isOpen, onClose,
}: FlashcardsCreationInfoType) => {
  const device = useDevice();
  const { colors } = useDefaultStyles();
  const [currOpenIndex, setCurrOpenIndex] = useState(-1);
  return (
    <Drawer
      isOpen={isOpen}
      placement="right"
      onClose={onClose}
      size={device === 'mobile' ? 'full' : 'md'}
    >
      <DrawerOverlay />
      <DrawerContent bgColor={colors.background}>
        <DrawerCloseButton />
        <DrawerHeader>
          <Heading size="md">Saiba mais</Heading>
        </DrawerHeader>
        <DrawerBody>
          <DrawerTopic
            topicIndex={0}
            currOpenIndex={currOpenIndex}
            setCurrOpenIndex={setCurrOpenIndex}
            title="Como devo usar esta funcionalidade?"
          >
            <iframe
              width="100%"
              height={device === 'mobile' ? '180' : '250'}
              src="https://www.youtube.com/embed/sfM009Hz_G4"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
            <OrderedList spacing={2} mt={4}>
              <ListItem position="relative">
                {'Na aba '}
                <ImagePopOver
                  imageSrc="/images/flashcard-creation/Info/communityTab.png"
                  text="&quot;Comunidade&quot;"
                  maxW={300}
                />
                , procure o tema de interesse e
                clique no respectivo baralho.
              </ListItem>
              <ListItem>
                Dentro do baralho, você terá acesso aos flashcards em ordem de relevância
                (os mais adicionados por outros usuários aparecem primeiro).
              </ListItem>
              <ListItem position="relative">
                {'Clique em algum flashcard que tenha gostado e, em seguida, no botão '}
                <ImagePopOver
                  imageSrc="/images/flashcard-creation/Info/saveFlashcard.png"
                  text="&quot;Salvar&quot;"
                  maxW={150}
                />
              </ListItem>
              <ListItem>
                Pronto! Agora seus flashcards estarão disponíveis na aba &quot;Meus baralhos&quot;.
              </ListItem>
            </OrderedList>
          </DrawerTopic>
          <DrawerTopic
            topicIndex={1}
            currOpenIndex={currOpenIndex}
            setCurrOpenIndex={setCurrOpenIndex}
            title="Não encontrei um tema. Como proceder?"
          >
            É extremamente recomendado que você só crie flashcards das aulas que você já viu.
            Mas, caso queira muito criar de uma aula ainda indisponível, crie-o no baralho mais similar possível.
          </DrawerTopic>
          <DrawerTopic
            topicIndex={2}
            currOpenIndex={currOpenIndex}
            setCurrOpenIndex={setCurrOpenIndex}
            title="Como criar novos flashcards?"
          >
            <OrderedList spacing={2}>
              <ListItem position="relative">
                {'Antes de criar novos flashcards, '}
                <ImagePopOver
                  imageSrc="/images/flashcard-creation/Info/searchFlashcard.png"
                  text="sempre pesquise para saber se ele já não existe na aba comunidade"
                  maxW={300}
                />
                !
                <Alert
                  status="info"
                  variant="left-accent"
                  my={3}
                >
                  Utilize a barra de pesquisa para pesquisar termos que possam estar presentes no flashcard.
                  Por exemplo, caso esteja criando um flashcard que pergunte qual o agente do Eritema Infeccioso,
                  procure por termos como &quot;eritema&quot;, &quot;infeccioso&quot;, &quot;parvovírus&quot;,
                  &quot;B19&quot;.
                </Alert>
              </ListItem>
              <ListItem position="relative">
                {'Caso o flashcard não exista, clique na '}
                <ImagePopOver
                  imageSrc="/images/flashcard-creation/Info/addCardButton.png"
                  text="carta com o sinal de mais"
                  maxW={300}
                />
                .
              </ListItem>
              <ListItem position="relative">
                <ImagePopOver
                  imageSrc="/images/flashcard-creation/Info/createFlashcardModal.png"
                  text="Edite"
                  maxW={300}
                />
                os campos &quot;frente&quot; e &quot;verso&quot;, clicando em &quot;Criar&quot; logo em seguida.
              </ListItem>
              <ListItem>
                Pronto! Você criou um flashcard!
              </ListItem>
              <Text color={colors.red.keep}>
                Atenção: Você só conseguirá criar flashcards pela aba &quot;Comunidade&quot;, pois queremos
                que você pesquise se algum idêntico já existe antes de fazê-lo.
              </Text>
            </OrderedList>
          </DrawerTopic>
          <DrawerTopic
            topicIndex={3}
            currOpenIndex={currOpenIndex}
            setCurrOpenIndex={setCurrOpenIndex}
            title="Esta funcionalidade já está finalizada?"
          >
            <Text mb={4}>
              A funcionalidade de criação de flashcards será dividada em 3 etapas:
            </Text>
            <OrderedList>
              <ListItem>Criação e consulta de flashcards;</ListItem>
              <ListItem>Criação de atividade extra a partir dos &quot;Meus baralhos&quot;;</ListItem>
              <ListItem>Adicionar automaticamente flashcards dos &quot;Meus baralhos&quot; à RI, RT e ao ET.</ListItem>
            </OrderedList>
            <Text mt={4}>
              Atualmente, ainda falta a última etapa do desenvolvimento.
            </Text>
          </DrawerTopic>
          <DrawerTopic
            topicIndex={4}
            currOpenIndex={currOpenIndex}
            setCurrOpenIndex={setCurrOpenIndex}
            title="O que significa o sinal de mais no flashcard?"
          >
            O
            <ImagePopOver
              imageSrc="/images/flashcard-creation/Info/plusSign.png"
              text="sinal de mais"
              maxW={150}
            />
            indica quantas vezes aquele flashcard já foi adicionado por outros usuários.
          </DrawerTopic>
          <DrawerTopic
            topicIndex={5}
            currOpenIndex={currOpenIndex}
            setCurrOpenIndex={setCurrOpenIndex}
            title="Consigo criar um flashcard só meu?"
          >
            Não. A ideia da Aristo é que a funcionalidade seja comunitária.
            Desfrute de excelentes flashcards da comunidade e retribua!
          </DrawerTopic>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};
