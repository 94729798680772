import { Box, Button, Center, Flex, Image, Table, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, useMemo } from 'react';

interface PrintFeedbackProps {
  types: {
    _id: string;
    isEssay: boolean;
  }[],
}

const PrintFeedback: FC = () => {
  const {
    types,
  }: PrintFeedbackProps = useMemo(() => {
    return (
      JSON.parse(localStorage.getItem('@Aristoclass:feedbackPrintData') || '[]')
    );
  }, []);

  return (
    <>
      <Center
        w="100%"
        my={2}
        className="hideOnPrint"
      >
        <Button
          color="aristo.500"
          leftIcon={<FontAwesomeIcon icon={faPrint} />}
          onClick={() => window.print()}
          bgColor="secondary.50"
          borderStyle="solid"
          borderWidth={2}
          borderColor="aristo.500"
        >
          Imprimir
        </Button>
      </Center>
      <Flex
        borderWidth={2}
        borderColor="aristo.500"
        p={5}
        justify="space-between"
        align="center"
        mt={2}
        mb={4}
      >
        <Image
          src="/images/aristo-logo-slogan.png"
          w={200}
          alt="logo"
        />
        <Text fontSize="2xl" color="aristo.500">
          Gabarito Aristo
        </Text>
      </Flex>
      <Box fontSize="12px" style={{ columnCount: 5 }}>
        <Table>
          <Thead>
            <Tr>
              <Th color="aristo.500">N°</Th>
              <Th color="aristo.500">R:</Th>
            </Tr>
          </Thead>
          <Tbody>
            {types.map(({ _id, isEssay }, index) => {
              return (
                <Tr key={_id}>
                  <Td>{index + 1}</Td>
                  {
                    isEssay ? (
                      <Td>Aberta</Td>
                    ) : (
                      <Td />
                    )
                  }
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </Box>
    </>
  );
};

export default PrintFeedback;
