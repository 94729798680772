import {
  FC,
  useState,
  useCallback,
  useMemo,
  useEffect,
} from 'react';
import clsx from 'clsx';
import { Flex, HStack, Text } from '@chakra-ui/react';
import { FlexItem } from '../../../lib/components/Flex';
// import Text from '../../../lib/components/Text';
import Ball from './Ball';
import { useDevice } from '../../../hooks/useDevice';
import { toast } from '../../../utils/toast';
import { useDefaultStyles } from '../../../hooks/useDefaultStyles';

export interface WeekTogglerValue {
  monday: boolean;
  tuesday: boolean;
  wednesday: boolean;
  thursday: boolean;
  friday: boolean;
  saturday: boolean;
  sunday: boolean;
}

export interface WeekTogglerProps {
  label: string;
  max?: number;
  min?: number;
  value?: WeekTogglerValue;
  initialValue?: WeekTogglerValue;
  onChange?: (value: WeekTogglerValue) => void;
  onError?: (err: Error | null) => void;
  className?: string;
}

export const weekTogglerValueInitialValue: WeekTogglerValue = {
  monday: false,
  tuesday: false,
  wednesday: false,
  thursday: false,
  friday: false,
  saturday: false,
  sunday: false,
};

const weekTogglerIndexedKeys: Array<keyof WeekTogglerValue> = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
];

const WeekToggler: FC<WeekTogglerProps> = ({
  label,
  className,
  max = 7,
  min = 0,
  initialValue = weekTogglerValueInitialValue,
  value,
  onChange,
  onError,
}) => {
  const [internalValue, setInternalValue] = useState<WeekTogglerValue>(initialValue);
  const device = useDevice();
  const { colors } = useDefaultStyles();

  const displayValue = value || internalValue;

  const totalChecked = useMemo(
    () => Object.values(displayValue).reduce((acc, curr) => acc + Number(curr), 0),
    [displayValue],
  );

  const containErros = useCallback(checkedCount => {
    return checkedCount > max || checkedCount < min;
  }, [max, min]);

  useEffect(() => {
    if (!onError) {
      return;
    }
    if (containErros(totalChecked)) {
      onError(new Error(''));
    } else {
      onError(null);
    }
  }, [onError, totalChecked, containErros, max]);

  const handleChange = useCallback((index: keyof WeekTogglerValue) => () => {
    const nextInternalValue: WeekTogglerValue = { ...displayValue };
    const currentChecked = Object.values(nextInternalValue).reduce((acc, curr) => acc + Number(curr), 0);
    nextInternalValue[index] = !nextInternalValue[index];
    const nextChecked = Object.values(nextInternalValue).reduce((acc, curr) => acc + Number(curr), 0);
    if ((nextChecked > max && currentChecked < nextChecked) || (nextChecked < min && currentChecked > nextChecked)) {
      toast.error({
        title: 'Limite atingido!',
        description: `${label} deve ter no mínimo ${min} dia(s) e no máximo ${max} dia(s)!`,
      });
      return;
    }
    setInternalValue(nextInternalValue);
    if (onChange) {
      onChange(nextInternalValue);
    }
  }, [displayValue, label, max, min, onChange]);

  const withErrors = clsx({ 'color-danger': containErros(totalChecked) });

  if (min === 0 && max === 0) {
    return null;
  }

  return (
    <>
      {device === 'mobile' && (
        <HStack>
          <Text noOfLines={2} className={withErrors}>{label}</Text>
          <Text
            fontSize=".9rem"
            color={colors.secondary.goLighter}
            gridGap="1rem"
            noOfLines={1}
            className={withErrors}
          >
            {`(Meta semanal: ${min})`}
          </Text>
        </HStack>
      )}
      <Flex align="center" height="3em" my={2} px="1em">
        {device === 'web' && (
          <FlexItem flex={2}>
            <Text noOfLines={1} className={withErrors}>{label}</Text>
            <Text
              fontSize=".9rem"
              color={colors.secondary.goLighter}
              noOfLines={1}
              className={withErrors}
            >
              {`Meta semanal: ${min}`}
            </Text>
          </FlexItem>
        )}
        {weekTogglerIndexedKeys.map(day => (
          <Flex flex={1} key={day} align="center" justify="center">
            <Ball
              active={displayValue[day]}
              onClick={handleChange(day)}
            />
          </Flex>
        ))}

      </Flex>
    </>
  );
};

export default WeekToggler;
