import { QueryResult, useQuery, gql } from '@apollo/client';
import { Question } from '../agenda/questions';

interface GetActivityForPrintInput {
  activityId: string;
}

export interface GetActivityForPrintOutput {
  getActivityDetailsForPrint: {
    title: string;
    subtitle: string;
    year: string;
    institution: string;
    uf: string;
    questions: Question[];
  }
}

const GET_ACTIVITY_FOR_PRINT = gql`
  query GET_ACTIVITY_DETAILS_FOR_PRINT($activityId: ObjectId!) {
    getActivityDetailsForPrint(activityId: $activityId){
      institution
      uf
      questions {
        _id
        body
        choices
        answersProportion
        correctChoice
        institution
        uf
        year
        examOrder
        examType
        complement
        classification
        isEssay
        difficulty
        createdAt
        updatedAt
        comment {
          author
          profile
          title
          body
          createdAt
          updatedAt
        }
        answer
      }
      year
      title
      subtitle
    }
  }
`;

export function useGetActivityDetailsForPrint({
  activityId,
}: GetActivityForPrintInput): QueryResult<GetActivityForPrintOutput, GetActivityForPrintInput> {
  return useQuery<GetActivityForPrintOutput, GetActivityForPrintInput>(GET_ACTIVITY_FOR_PRINT, {
    variables: {
      activityId,
    },
    fetchPolicy: 'network-only',
    skip: activityId === '',
  });
}
