import React, { FC, useState, useEffect } from 'react';
import { ref, onValue, off, serverTimestamp, onDisconnect, update, increment } from 'firebase/database';
import { database } from './client/firebase';
import useAccount from './hooks/useAccount';
import LoadingScreen from './views/_components/LoadingScreen';
import { useDefaultStyles } from './hooks/useDefaultStyles';

const IsLoaded: FC = ({ children }) => {
  const { uid, loadingAuth } = useAccount();
  const [animation, setAnimation] = useState(true);
  const { homePage } = useDefaultStyles();

  useEffect(() => {
    const timer = setTimeout(() => {
      setAnimation(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (!uid) {
      return;
    }

    const databaseRef = ref(database);

    const isOnlineForDatabase = {
      state: 'online',
      last_changed: serverTimestamp(),
    };
    const isOfflineForDatabase = {
      state: 'offline',
      last_changed: serverTimestamp(),
    };
    onValue(ref(database, '.info/connected'), snapshot => {
      if (snapshot.val() === false) {
        return;
      }
      onDisconnect(databaseRef).update({
        [`/status/${uid}`]: isOfflineForDatabase,
        online: increment(-1),
      }).then(() => {
        update(databaseRef, {
          [`/status/${uid}`]: isOnlineForDatabase,
          online: increment(1),
        });
      });
    });

    // eslint-disable-next-line consistent-return
    return (): void => off(ref(database, '.info/connected'));
  }, [uid]);

  if (loadingAuth || animation || !homePage) {
    return <LoadingScreen />;
  }

  return (
    <>
      {children}
    </>
  );
};

export default IsLoaded;
