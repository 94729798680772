import { VStack, Text, Center, Image } from '@chakra-ui/react';
import { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { getDateParam } from '../../hooks/getDateParam';
import { useDefaultStyles, useRGBColor } from '../../hooks/useDefaultStyles';

interface TabButtonProps {
  title?: string;
  imageSrc?: string;
  linkTo?: string;
  active?: boolean;
  total?: number;
  done?: number;
}

export function TabButton({
  title, imageSrc, linkTo, active, total, done = 0,
}: TabButtonProps) {
  const history = useHistory();
  const { colors, hueRotate } = useDefaultStyles();
  const { toRGB } = useRGBColor();
  const contractedTitle = useMemo(() => {
    if (title) {
      return title.split(' ').map(w => w[0].toUpperCase()).join('');
    } return '';
  }, [title]);

  return (
    <VStack spacing={0}>
      <Center
        as="button"
        borderRadius="50%"
        position="relative"
        transition="all 0.4s"
        p={2}
        w={{ base: '48px', md: '64px' }}
        h={{ base: '48px', md: '64px' }}
        title={title}
        overflow="hidden"
        _hover={{
          // bgColor: colors.lighter.keep,
          filter: 'brightness(1.1)',
        }}
        border="3px solid"
        borderColor={active ? colors.secondary.keep : colors.lighter.goAlmostFullDarker}
        bgColor={active ? colors.secondary.goDarker : colors.lighter.goMuchDarker}
        onClick={() => {
          history.push({
            pathname: linkTo,
            search: `?date=${getDateParam()}`,
          });
        }}
      >
        <Image src={imageSrc} alt="icon" filter={hueRotate()} />
        <Center
          transition="all 0.4s"
          position="absolute"
          bottom={0}
          w="100%"
          h={active ? '100%' : 5}
          bgColor={`${toRGB(colors.secondary.keep)}80`}
        >
          <Text
            transition="font-size 0.3s"
            fontSize={active ? '2xl' : 'xs'}
            fontWeight="bold"
            color="white"
          >
            {contractedTitle}
          </Text>
        </Center>
      </Center>
    </VStack>
  );
}
