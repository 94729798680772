import {
  useState, useMemo, useCallback, ChangeEvent,
} from 'react';
import {
  useClassificationTrees,
  useGetClassificationTreeAvailablePhases,
} from '../api/classification-trees';

type UseClassifications = [
  areas: string[],
  specialities: string[],
  topics: string[],
  subtopics: string[],
  classification: string[],
  handleClassification: (pos: 0 | 1 | 2 | 3) => (e: ChangeEvent<HTMLSelectElement>) => void,
  loading: boolean,
]

const useClassifications = (treeId: string, availablePhases = false): UseClassifications => {
  const [classification, setClassification] = useState<string[]>([]);

  const queryTree = useClassificationTrees({
    treeId,
    skip: availablePhases,
  });

  const queryTree2 = useGetClassificationTreeAvailablePhases({
    courseId: treeId,
    skip: !availablePhases,
  });

  const classificationTree = useMemo(() => {
    const { data } = availablePhases ? queryTree2 : queryTree;
    if (data) {
      return data.classificationTree.tree;
    }
    return [];
  }, [availablePhases, queryTree, queryTree2]);

  const areas = useMemo(() => {
    if (classificationTree) {
      return classificationTree.map(leaf => leaf.name);
    }
    return [];
  }, [classificationTree]);

  const specialities = useMemo(() => {
    if (classification[0]) {
      const specialitiesList = classificationTree.find(leaf => leaf.name === classification[0])?.children;
      if (specialitiesList) {
        return specialitiesList.map(leaf => leaf.name);
      }
    }
    return [];
  }, [classification, classificationTree]);

  const topics = useMemo(() => {
    if (classification[1]) {
      const topicsList = classificationTree
        .find(leaf => leaf.name === classification[0])?.children
        ?.find(leaf => leaf.name === classification[1])?.children;
      if (topicsList) {
        return topicsList.map(leaf => leaf.name);
      }
    }
    return [];
  }, [classification, classificationTree]);

  const subtopics = useMemo(() => {
    if (classification[2]) {
      const subtopicsList = classificationTree
        .find(leaf => leaf.name === classification[0])?.children
        ?.find(leaf => leaf.name === classification[1])?.children
        ?.find(leaf => leaf.name === classification[2])?.children;
      if (subtopicsList) {
        return subtopicsList.map(leaf => leaf.name);
      }
    }
    return [];
  }, [classification, classificationTree]);

  const handleClassification = useCallback((pos: 0 | 1 | 2 | 3) => (e: ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target;
    switch (pos) {
      case 0:
        if (value) {
          setClassification([value]);
        } else {
          setClassification([]);
        }
        break;
      case 1:
        if (value) {
          setClassification(old => [old[0], value]);
        } else {
          setClassification(old => [old[0]]);
        }
        break;
      case 2:
        if (value) {
          setClassification(old => [old[0], old[1], value]);
        } else {
          setClassification(old => [old[0], old[1]]);
        }
        break;
      case 3:
        if (value) {
          setClassification(old => [old[0], old[1], old[2], value]);
        } else {
          setClassification(old => [old[0], old[1], old[2]]);
        }
        break;
      default:
    }
  }, []);

  return [areas, specialities, topics, subtopics, classification, handleClassification, queryTree.loading];
};

export default useClassifications;
