import { Box, Flex, Heading, HStack, Text, Tooltip, VStack } from '@chakra-ui/react';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC } from 'react';
import { TrophiesStats } from '../../../api/student-report';
import { useDefaultStyles } from '../../../hooks/useDefaultStyles';
import { useDevice } from '../../../hooks/useDevice';
import { ComparisonText } from './ComparisonText.component';
import { PrizeStar } from './PrizeStar';
import { ReportLoading } from './ReportLoading';
import { Trophy } from './Trophy.component';

type TrophiesReportType = {
  currTrophiesData?: TrophiesStats,
  prevTrophiesData?: TrophiesStats,
  loading?: boolean,
}

export const TrophiesReport: FC<TrophiesReportType> = ({
  currTrophiesData,
  prevTrophiesData,
  loading,
}: TrophiesReportType) => {
  const { cardBorder, colors } = useDefaultStyles();
  const device = useDevice();

  if (currTrophiesData && prevTrophiesData && !loading) {
    const trophyPointsPerformance = Math
      .round((currTrophiesData.trophyPoints * 100) / currTrophiesData.maxTrophyPoints);
    return (
      <Box
        mb={{ base: 4, md: 0 }}
        p={4}
        w={device === 'mobile' ? '100%' : '50%'}
        {...cardBorder}
      >
        <Flex>
          <HStack flex={2} alignItems="flex-start">
            <HStack>
              <PrizeStar
                label="Parabéns! Você alcançou 100% dos pontos de troféu!"
                goal={currTrophiesData.maxTrophyPoints}
                achieved={currTrophiesData.trophyPoints}
              />
              <Heading size="md">Troféus</Heading>
            </HStack>
          </HStack>
          <ComparisonText
            currentValue={(currTrophiesData.trophyPoints * 100) / currTrophiesData.maxTrophyPoints}
            previousValue={(prevTrophiesData.trophyPoints * 100) / prevTrophiesData.maxTrophyPoints}
            pluralSubject="de pontos"
          />
        </Flex>
        <HStack alignItems="flex-start" justify="center" spacing={10} my={6}>
          <VStack spacing={4}>
            <Trophy type="bronze" count={currTrophiesData.bronze} w={20} />
            <ComparisonText
              currentValue={currTrophiesData.bronze}
              previousValue={prevTrophiesData.bronze}
              template="absolute verbose"
              text=""
            />
          </VStack>
          <VStack spacing={4}>
            <Trophy type="silver" count={currTrophiesData.silver} w={20} />
            <ComparisonText
              currentValue={currTrophiesData.silver}
              previousValue={prevTrophiesData.silver}
              text=""
              template="absolute verbose"
            />
          </VStack>
          <VStack spacing={4}>
            <Trophy type="gold" count={currTrophiesData.gold} w={20} />
            <ComparisonText
              currentValue={currTrophiesData.gold}
              previousValue={prevTrophiesData.gold}
              text=""
              template="absolute verbose"
            />
          </VStack>
        </HStack>
        <HStack fontWeight="bold">
          <Text>
            Pontos de troféu:
          </Text>
          <Box
            display="inline"
            color={colors.secondary.keep}
            mx={1}
          >
            {`${currTrophiesData.trophyPoints}/${currTrophiesData.maxTrophyPoints} (${trophyPointsPerformance}%)`}
          </Box>
          <Tooltip
            label={`Pontos de troféu são calculados pela soma ponderada
            dos troféus de ouro (peso 3), prata (peso 2) e bronze (peso 1).`}
          >
            <Box color={colors.secondary.keep}>
              <FontAwesomeIcon icon={faInfoCircle} />
            </Box>
          </Tooltip>
        </HStack>
      </Box>
    );
  }
  return (
    <ReportLoading />
  );
};
