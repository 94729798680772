import { Text } from '@chakra-ui/react';
import { FC, useMemo } from 'react';
import { DiagnosticAreaRowInterface } from '../../../api/diagnosis';
import { useDevice } from '../../../hooks/useDevice';
import TableRowComponent, { RowDataProps } from '../../../lib/components/TableRow.component';
import { format } from '../../../utils/format';
import { TableStyleType } from './DiagnosticAreaTable';

type DiagnosticAreaRowInput = {
  tableStep: number,
  rowData?: DiagnosticAreaRowInterface,
  colorIndex: number,
  lineTooltip: string,
  isFooter?: boolean,
  visible?: boolean,
  showThemesToReview?: boolean,
  tableStyle?: TableStyleType,
}

export const DiagnosticAreaRow: FC<DiagnosticAreaRowInput> = ({
  tableStep,
  rowData,
  colorIndex,
  lineTooltip,
  isFooter = false,
  visible = true,
  showThemesToReview = true,
  tableStyle = 'diagnostic',
}) => {
  const device = useDevice();

  const rowConfig = useMemo(() => {
    if (rowData) {
      const {
        atentionCount,
        area,
        totalPerformance,
        totalStats,
        easyPerformance,
        easyStats,
        mediumPerformance,
        mediumStats,
        hardPerformance,
        hardStats,
      } = rowData;

      const row: RowDataProps[] = [
        {
          key: 'area',
          value: <Text noOfLines={1}>{area}</Text>,
          flexSpan: 2,
          align: 'left',
        },
        {
          key: 'atention',
          value: atentionCount,
          align: device === 'web' ? 'left' : 'center',
          showOnMobile: tableStep === 4,
        },
        {
          key: 'easyPerformance',
          value: <p>{`${easyStats.hits} / ${easyStats.total} (${format(easyPerformance * 100)}%)`}</p>,
          align: 'left',
          showOnMobile: tableStep === 1,
        },
        {
          key: 'mediumPerformance',
          value: <p>{`${mediumStats.hits} / ${mediumStats.total} (${format(mediumPerformance * 100)}%)`}</p>,
          align: 'left',
          showOnMobile: tableStep === 2,
        },
        {
          key: 'hardPerformance',
          value: <p>{`${hardStats.hits} / ${hardStats.total} (${format(hardPerformance * 100)}%)`}</p>,
          align: 'left',
          showOnMobile: tableStep === 3,
        },
        {
          key: 'totalPerformance',
          value: (
            <p className={isFooter ? 'color-purple' : ''}>
              {`${totalStats.hits} / ${totalStats.total}  (${format(totalPerformance * 100)}%)`}
            </p>),
          align: 'left',
          showOnMobile: tableStep === 0,
        },
      ];
      if (!showThemesToReview) {
        row.splice(1, 1);
      }
      return row;
    } return [] as RowDataProps[];
  }, [device, isFooter, rowData, showThemesToReview, tableStep]);

  if (!visible || !rowData) {
    return null;
  }

  return (
    <TableRowComponent
      key={Math.random().toString(36).slice(2)}
      lineTooltip={lineTooltip}
      paddingLeft=".8rem"
      tableStyle={tableStyle}
      rowBackgroundColorWhite={colorIndex}
      isFooter={isFooter}
      rowData={rowConfig}
    />
  );
};
