import React, { createContext, FC, useEffect, useState } from 'react';
import { useLocalStorage } from '../../hooks/useLocalStorage';

interface WholeAppContextInterface {
  hideElementsThatTriggerModals: boolean,
  setHideElementsThatTriggerModals: React.Dispatch<React.SetStateAction<boolean>>,
  areShortcutsEnabled: boolean,
  setAreShortcutsEnabled: React.Dispatch<React.SetStateAction<boolean>>,
}

const WholeAppContextDefault: WholeAppContextInterface = {
  hideElementsThatTriggerModals: false,
  setHideElementsThatTriggerModals: () => null,
  areShortcutsEnabled: false,
  setAreShortcutsEnabled: () => null,
};

export const WholeAppContext = createContext<WholeAppContextInterface>(WholeAppContextDefault);

export const WholeAppStorage: FC = ({ children }) => {
  const [hideElementsThatTriggerModals, setHideElementsThatTriggerModals] = useState(false);
  const [_areShortcutsEnabled, _setAreShortcutsEnabled] = useLocalStorage('@Aristoclass:areShortcutsEnabled', true);
  const [areShortcutsEnabled, setAreShortcutsEnabled] = useState<boolean>(_areShortcutsEnabled);

  useEffect(() => {
    _setAreShortcutsEnabled(areShortcutsEnabled);
  }, [_setAreShortcutsEnabled, areShortcutsEnabled]);

  return (
    <WholeAppContext.Provider
      value={{
        hideElementsThatTriggerModals,
        setHideElementsThatTriggerModals,
        areShortcutsEnabled,
        setAreShortcutsEnabled,
      }}
    >
      {children}
    </WholeAppContext.Provider>
  );
};
