import { Box } from '@chakra-ui/react';
import { FC, useCallback, useContext, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useExtraMockExams } from '../../../api/extra-activities/extra-mock-exams';
import { getDateParam } from '../../../hooks/getDateParam';
import { getParam } from '../../../hooks/useSearchParam';
import { Loading } from '../../../lib/components/Loading';
import { toast } from '../../../utils/toast';
import { AgendaContext } from '../Agenda.context';

const ExtraMockExamCreatePage: FC = () => {
  const agendaCtx = useContext(AgendaContext);
  const history = useHistory();
  const { onCreateMockExamExtraActivity } = useExtraMockExams();
  const hasConditionPassed = useRef(false);

  const title = getParam('title');
  const template = getParam('template');

  const getCurrentActivity = useCallback(async () => {
    const { extras } = agendaCtx;
    const { activityMockExamExtra } = extras;

    if (!activityMockExamExtra || activityMockExamExtra.length === 0) {
      return undefined;
    }

    const find = activityMockExamExtra.filter(e => e.title === title
      && e.finished === false);

    if (find.length === 0) {
      return undefined;
    }

    return find[0]._id;
  }, [agendaCtx, title]);

  const redirect = useCallback((id: string) => {
    history.push({
      pathname: '/atividades-extras/simulado',
      search: `date=${getDateParam()}&a=${id}`,
    });
  }, [history]);

  const errorDialog = useCallback(() => {
    toast.error({
      title: 'Ocorreu um erro!.',
      description: 'Não foi possível criar a atividade.',
    });
  }, []);

  const createActivity = useCallback(async () => {
    if (template.length === 0) {
      errorDialog();
      return;
    }

    try {
      const res = await onCreateMockExamExtraActivity({
        template,
      });

      if (!res) {
        errorDialog();
      }

      await agendaCtx.refetchExtras();

      if (res) {
        redirect(res);
      }
    } catch (err) {
      console.log(err);
      errorDialog();
    }
  }, [agendaCtx, errorDialog, onCreateMockExamExtraActivity, redirect, template]);

  const init = useCallback(async () => {
    if (hasConditionPassed.current) {
      return;
    }

    if (agendaCtx.extrasLoading === false) {
      hasConditionPassed.current = true;
      const id = await getCurrentActivity();
      if (id) {
        redirect(id);
      } else {
        await createActivity();
      }
    }
  }, [agendaCtx.extrasLoading, createActivity, getCurrentActivity, redirect]);

  useEffect(() => {
    init();
  }, [init]);

  return (
    <Box maxW={900} m="0 auto" padding={8}>
      <Loading subtitle="Criando Simulado Aristo" />
    </Box>
  );
};

export default ExtraMockExamCreatePage;
