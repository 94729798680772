import {
  FC, ImgHTMLAttributes,
} from 'react';
import { Box, Image, Modal, ModalContent, ModalOverlay, useDisclosure } from '@chakra-ui/react';

export const ZoomImg: FC<ImgHTMLAttributes<HTMLImageElement>> = props => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  return (
    <>
      <Box as="button" onClick={onOpen}>
        <Image {...props} />
      </Box>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent bg="none" p={2} m={0} flex={1} minW="100vw" h="100vh" alignItems="stretch">
          <Box as="button" flex={1} onClick={onClose}>
            <Image {...props} objectFit="contain" h="100%" m="0 auto" />
          </Box>
        </ModalContent>
      </Modal>
    </>
  );
};
