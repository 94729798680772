import { Center, Flex, Text } from '@chakra-ui/react';
import { FC, useCallback, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDefaultStyles } from '../../../../hooks/useDefaultStyles';
import { FlashcardIcon } from '../../icons/FlashcardIcon';

type MyDeckButtonType = {
  lessonId?: string,
  show?: boolean,
}

export const MyDeckButton: FC<MyDeckButtonType> = ({
  lessonId, show = true,
}: MyDeckButtonType) => {
  const { colors } = useDefaultStyles();
  const [isMouseOver, setIsMouseOver] = useState(false);
  const history = useHistory();
  const { lessonId: lessonIdParam } = useParams<{ lessonId: string }>();

  const currLessonId = useMemo(() => {
    if (lessonId) {
      return lessonId;
    }
    if (lessonIdParam) {
      return lessonIdParam;
    }
    return undefined;
  }, [lessonId, lessonIdParam]);

  const handleOnClick = useCallback(() => {
    history.push(`/baralhos/comunidade?${currLessonId ? `lessonId=${currLessonId}` : ''} `);
  }, [history, currLessonId]);

  if (!show) {
    return null;
  }

  return (
    <Center
      onMouseOver={() => setIsMouseOver(true)}
      onMouseLeave={() => setIsMouseOver(false)}
    >
      <Flex
        as="button"
        borderRadius={4}
        width={14}
        onClick={handleOnClick}
        flexFlow="column"
        height={16}
        transition="background-color .3s"
        bgColor={colors.primary.goLighter}
        _hover={{ bgColor: colors.secondary.goLighter }}
        color={colors.lighter.keep}
        fontSize="xl"
        align="center"
        justify="center"
      >
        <Center flexFlow="column">
          <FlashcardIcon
            my={2}
            color="gray.300"
            isMouseOver={isMouseOver}
            hoverBgColor={colors.secondary.goLighter}
            bgColor={colors.primary.goLighter}
            transition="all 0.3s"
          />
          <Text mt={2} textAlign="center" lineHeight={1} fontSize="xs">
            Cards
          </Text>
        </Center>
      </Flex>
    </Center>
  );
};
