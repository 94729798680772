import {
  FC,
} from 'react';
import { Box } from '@chakra-ui/react';
import { Video as VideoInterface } from '../../../api/agenda/videos';
import { PlaylistNavigator } from '../_components/PlaylistNavigator';

interface AgendaVideosProps {
  data: VideoInterface[];
}

const AgendaVideo: FC<AgendaVideosProps> = ({
  data,
}) => {
  return (
    <Box m="1rem auto" maxW={1000}>
      <PlaylistNavigator
        playlistData={data}
      />
    </Box>
  );
};

export default AgendaVideo;
