import { IconButton } from '@chakra-ui/react';
import { faEdit } from '@fortawesome/free-regular-svg-icons';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, {
  FC, useCallback, useState,
} from 'react';
import { useDefaultStyles } from '../../../hooks/useDefaultStyles';
import ConfirmModal from '../../../lib/components/ConfirmModal';

interface CreateExtraBtnProps {
  active: boolean;
  handleCreateExtraFn: () => Promise<void>;
  confirmModalTitle: string;
  confirmModalSubtitle: string;
}

const CreateExtraBtn: FC<CreateExtraBtnProps> = ({
  active, handleCreateExtraFn, confirmModalTitle, confirmModalSubtitle,
}) => {
  const { colors } = useDefaultStyles();
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);

  const handleCreate = useCallback(async () => {
    setLoading(true);
    try {
      await handleCreateExtraFn();
    } catch (error) {
      console.log(error.message);
    } finally {
      setLoading(false);
      setModal(false);
    }
  }, [handleCreateExtraFn]);

  return (
    <>
      {!loading ? (
        <IconButton
          aria-label="adicionar como atividade extra"
          icon={<FontAwesomeIcon icon={faEdit} />}
          color={active ? colors.secondary.goLighter : colors.darkGray.keep}
          onClick={() => setModal(true)}
          disabled={!active}
          variant="ghost"
        />
      ) : (
        <FontAwesomeIcon icon={faSpinner} spin />
      )}
      <ConfirmModal
        isOpen={modal}
        onConfirm={handleCreate}
        onDecline={() => setModal(false)}
        title={confirmModalTitle}
        subtitle={confirmModalSubtitle}
        confirmText="Adicionar"
        declineText="Cancelar"
        loading={loading}
      />
    </>
  );
};

export default CreateExtraBtn;
