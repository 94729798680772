import { Alert, AlertIcon, HStack, Text, VStack } from '@chakra-ui/react';
import { FC } from 'react';
import { isTodayExtended } from '../../../utils/isTodayExtended';

type NeedToAnswerEssayAlertType = {
  needToAnswer: number[],
  show?: boolean,
}

export const NeedToAnswerEssayAlert: FC<NeedToAnswerEssayAlertType> = ({
  needToAnswer,
  show = true,
}) => {
  if (!show) {
    return null;
  }
  return (
    <Alert mb={2} status="warning" flexFlow="column" alignItems="flex-start">
      <HStack>
        <AlertIcon />
        {
          isTodayExtended() ? (
            <VStack align="flex-start">
              <Text>
                {`Você ainda precisa responder se acertou as seguintes questões abertas:
                      ${needToAnswer.join(', ')}`}
              </Text>
              <Text fontWeight="bold">
                ATENÇÃO: Isso precisa ser feito ainda hoje para a prova ser considerada realizada!
              </Text>
            </VStack>
          ) : (
            <Text>
              {`Você não respondeu se acertou todas as questões abertas desta prova a tempo.
                    Faltaram: ${needToAnswer.join(', ')}`}
            </Text>
          )
        }
      </HStack>
    </Alert>
  );
};
