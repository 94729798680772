import React, {
  FC, useCallback, useEffect, useMemo, useState,
} from 'react';
import { Button, Flex } from '@chakra-ui/react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDiagnosticResults } from '../../../api/diagnosis';
import { DiagnosticAreaTable } from '../_components/DiagnosticAreaTable';
import { DiagnosticThemesTable } from '../_components/DiagnosticThemesTable';
import { LoadingDiagnosticResults } from '../_components/LoadingDiagnosticResults';
import { getParam } from '../../../hooks/useSearchParam';

export const DiagnosisResultsPage: FC = () => {
  const [activityId, setActivityId] = useState('');

  const history = useHistory();
  const { search } = useLocation();
  const id = useMemo(() => (
    (new URLSearchParams(search)).get('id')
  ), [search]);

  const {
    getDiagnosticResultsQuery: { data, loading },
  } = useDiagnosticResults(activityId);

  const handleReviewExam = useCallback(() => {
    localStorage.setItem('@plataformaJJMentoria:id', getParam('id'));
    history.push('/diagnostico/prova');
  }, [history]);

  useEffect(() => {
    if (id) {
      setActivityId(id);
    } else {
      history.push('/diagnostico');
    }
  }, [history, id]);

  const diagnosticResult = useMemo(() => {
    if (!data || loading) {
      return undefined;
    }
    return data.fetchDiagnosticExamResults;
  }, [data, loading]);

  if (loading) {
    return <LoadingDiagnosticResults />;
  }

  return (
    <Flex w="100%" mt={8} flexFlow="column" align="center">
      <DiagnosticAreaTable
        areas={diagnosticResult?.areas}
      />
      <DiagnosticThemesTable
        themes={diagnosticResult?.themes}
      />
      <Flex w="100%" justify="center" mb={10}>
        <Button onClick={handleReviewExam}>
          Revisar prova
        </Button>
      </Flex>
    </Flex>
  );
};
