import { Flex, HStack, Text } from '@chakra-ui/react';
import React, { FC, ReactNode } from 'react';

type TitleBarInputType = {
  show?: boolean,
  playlistName: string,
  videoListLen?: number,
  selectedVideoIndex: number,
  children?: ReactNode,
}

export const AristoPlusTitleBar: FC<TitleBarInputType> = ({
  playlistName, videoListLen, selectedVideoIndex, show = true, children,
}) => {
  if (!show) {
    return null;
  }

  return (
    <Flex flexFlow="column" w={{ base: '100%', lg: 1200, xl: 800 }} mt={{ base: 4, lg: 0 }}>
      <HStack mx={4} pl={4} align="center" w="100%">
        <Text fontWeight="bold" fontSize={{ base: 'md', md: 'lg' }} noOfLines={2}>{playlistName}</Text>
        {selectedVideoIndex && <Text>{`(${selectedVideoIndex}/${videoListLen ?? ''})`}</Text>}
      </HStack>
      {children}
    </Flex>
  );
};
