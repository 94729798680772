import React, {
  FC, useMemo,
} from 'react';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useVideoMany } from '../../../../api/agenda/videos';
import Flex from '../../../../lib/components/Flex';
import AgendaVideo from '../../types/AgendaVideo';
import VideoEmpty from '../../types/VideoEmpty';

interface ExtraTheoreticalVideoProps {
  ids?: string[];
  onFinish: () => Promise<void>;
  loading: boolean;
  finished: boolean;
}

const ExtraTheoreticalVideo: FC<ExtraTheoreticalVideoProps> = ({
  ids, onFinish, loading, finished,
}) => {
  const query = useVideoMany({
    ids: ids || [],
  });

  const dataQuery = useMemo(() => {
    const { data } = query;
    if (data) {
      return data.videoMany;
    }

    return [];
  }, [query]);

  if (query.loading) {
    return (
      <Flex align="center" justify="center" style={{ minHeight: 400 }}>
        <FontAwesomeIcon icon={faSpinner} spin size="5x" className="color-dark-gray" />
      </Flex>
    );
  }

  if (dataQuery && dataQuery.length > 0) {
    return (
      <AgendaVideo
        data={dataQuery}
      />
    );
  }

  return <VideoEmpty />;
};

export default ExtraTheoreticalVideo;
