import { ApolloError } from '@apollo/client';
import { useColorMode, useTheme } from '@chakra-ui/react';
import { useCallback, useContext, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { PreChoiceInterface, useMockExam } from '../api/agenda/mock-exam';
import { Question } from '../api/agenda/questions';
import { ManyAnswerInterface, useAnswer } from '../api/answer';
import { UseDiagnosticExamOutputType } from '../api/diagnosis';
import { PrivateContext } from '../Private.context';
import { getNavColorsUtil } from '../utils/getNavColors';
import { getResults } from '../utils/getResults';
import { isTodayExtended } from '../utils/isTodayExtended';
import { timeOver } from '../utils/timeOver';
import { toast } from '../utils/toast';
import { AgendaContext } from '../views/agenda/Agenda.context';
import { ExtraSmartReviewContext } from '../views/agenda/extra-activities/contexts/ExtraSmartReview.context';
import { getActivityType } from './getActivityType';
import { getDateParam } from './getDateParam';
import { setId } from './getId';
import { getTheoreticalType } from './getTheoreticalType';
import useClassificationCascade from './useClassificationCascade';
import { useDefaultStyles } from './useDefaultStyles';
import { useDevice } from './useDevice';
import useQuestionControls from './useQuestionControls';

export type GenerateAnswersType = {
  preChoices: PreChoiceInterface[];
  filterNotAnswered?: boolean;
}

export type QuestionCardType = 'questions' | 'exams' | 'mocks' | 'diagnostic' | 'favorites';

type useQuestionHooksProps = {
  model: QuestionCardType;
  data: Question[];
  serverPreChoices?: PreChoiceInterface[];
  finished?: boolean;
  startedAt?: Date;
  timeToDo?: number;
  activityId?: string;
  mockExamTemplate?: string;
  diagnosticExamCallback?: UseDiagnosticExamOutputType;
  disableCommunication?: boolean;
}

export const useQuestionHooks = ({
  data,
  serverPreChoices,
  model,
  finished,
  startedAt,
  disableCommunication = false,
  timeToDo,
  activityId,
  mockExamTemplate,
  diagnosticExamCallback,
}: useQuestionHooksProps) => {
  const { colors: themeColors } = useTheme();
  const [accumulatedData, setAccumulatedData] = useState(data);
  const { onCreateOrUpdateOnePreChoice, onCreateOneAnswer, onCreateManyAnswer } = useAnswer();
  const { agenda, doubtQuestionIds } = useContext(PrivateContext);
  const [loading, setLoading] = useState(false);
  const { questionsAndFlashcardsFinished, finishQuestions } = useContext(ExtraSmartReviewContext);
  const { onFinishExtraActivity } = useContext(AgendaContext);
  const { onRankMockExam } = useMockExam();
  const device = useDevice();
  const [clientPreChoices, setClientPreChoices] = useState(serverPreChoices || []);
  const [answersState, setAnswersState] = useState(accumulatedData.map(item => item.answer ?? -1));
  const { cardBorder } = useDefaultStyles();
  const { colorMode } = useColorMode();
  const [questionsToShow, setQuestionsToShow] = useState({
    easy: true,
    medium: true,
    hard: true,
    unclassified: true,
  });
  const history = useHistory();

  const { profile } = useContext(PrivateContext);

  const classificationCascade = useClassificationCascade(profile?.courses?.[0]?.detailedClassificationTree);

  const allowedDifficulties = useMemo(() => Object.entries(questionsToShow).filter(x => x[1]).map(x => x[0]),
    [questionsToShow]);

  const allowedPrefixes = useMemo(() => {
    return accumulatedData.filter(question => classificationCascade.allClassifications
      .some(prefix => question.classification.join('|').startsWith(prefix)))
      .map(x => x.classification.join('|'));
  }, [accumulatedData, classificationCascade.allClassifications]);

  // const doubtQuestionIds = useMemo(() => ['5f5fa62a8c17db9f3a8cfb46'], []);
  // const isInDoubtOnCurrQuestion = doubtQuestionIds.data?.doubtIds.includes(getParam('id'));
  const hiddenIndexes = accumulatedData
    .map((q, i) => ({
      index: i,
      difficulty: q.difficulty || 'unclassified',
      classification: q.classification.join('|'),
    }))
    .filter(q => !allowedDifficulties.includes(q.difficulty)
      || (classificationCascade.allClassifications.length > 0 && !allowedPrefixes.includes(q.classification)))
    .map(q => q.index);

  const visibleIndexes = useMemo(() => {
    const allIndexes = accumulatedData.map((_, i) => i);
    return allIndexes.filter(x => !hiddenIndexes.includes(x));
  }, [accumulatedData, hiddenIndexes]);

  const isDiagnosticExam = (model === 'diagnostic');
  const isQuestionsModel = (model === 'questions');
  const isExamsModel = (model === 'exams');
  const isMocksModel = (model === 'mocks');
  const isFavoritesModel = (model === 'favorites');
  const shouldConsiderExamMode = isQuestionsModel
    && ['extras-smartReview', 'smartReview', 'extras'].includes(getTheoreticalType());

  const answersMemo = useMemo(() => {
    return accumulatedData.map(item => {
      const find = clientPreChoices.find(i => i.questionId === item._id)?.selected;
      if (find === undefined || find === null) {
        return -1;
      }
      return find;
    });
  }, [accumulatedData, clientPreChoices]);

  const answers = useMemo(() => {
    if (isQuestionsModel) {
      return (answersState);
    }
    return (answersMemo);
  }, [answersMemo, answersState, isQuestionsModel]);

  const [prefersExamMode, setPrefersExamMode] = useState(() => {
    if (isFavoritesModel) {
      return false;
    }
    if (shouldConsiderExamMode) {
      if (clientPreChoices.length > 0) {
        return true;
      }
      if (answers.some(x => x !== -1)) {
        return false;
      }
      return JSON.parse(localStorage.getItem('@plataformajj:prefersExamMode') ?? 'false') as boolean;
    }
    return false;
  });
  const isExamMode = shouldConsiderExamMode && prefersExamMode;

  const {
    index, onPrev, onNext, onIndex,
  } = useQuestionControls(accumulatedData, hiddenIndexes);

  const goToFirstVisibleIndex = useCallback((qToShow: typeof questionsToShow) => {
    const _allowedDifficulties = Object.entries(qToShow).filter(x => x[1]).map(x => x[0]);
    const _visibleIndexes = accumulatedData
      .map((q, i) => ({ index: i, difficulty: q.difficulty || 'unclassified' }))
      .filter(q => _allowedDifficulties.includes(q.difficulty))
      .map(q => q.index);
    const firstVisibleIndex = _visibleIndexes?.[0] || 0;
    history.replace(setId(accumulatedData[firstVisibleIndex]._id));
  }, [accumulatedData, history]);

  const toggleDifficultyVisibility = useCallback((difficulty: keyof typeof questionsToShow) => {
    setQuestionsToShow(prev => {
      const newValue = { ...prev, [difficulty]: !prev[difficulty] };
      goToFirstVisibleIndex(newValue);
      return newValue;
    });
  }, [goToFirstVisibleIndex]);

  const [essaysState, setEssaysState] = useState(accumulatedData.map(item => item.essay ?? ''));

  const [finishedState, setFinishedState] = useState(() => {
    if (!isQuestionsModel) {
      return finished;
    }
    return finished || accumulatedData.every((item, i) => {
      return answersState[i] !== -1
        || essaysState[i].length >= 3
        || (clientPreChoices.find(p => p.questionId === item._id)?.essay?.length ?? 0) >= 3;
    });
  });

  const essaysMemo = useMemo(() => {
    return accumulatedData.map(item => {
      const find = clientPreChoices.find(i => i.questionId === item._id)?.essay;
      return find ?? '';
    });
  }, [accumulatedData, clientPreChoices]);

  const modelName = useMemo(() => {
    if (isMocksModel) {
      return 'simulado';
    }
    if (isExamsModel) {
      return 'prova';
    }
    return '';
  }, [isExamsModel, isMocksModel]);

  const areAllQuestionsChosen = clientPreChoices.length === accumulatedData.length;
  const answeredAll = !answers.includes(-1);
  const selectedAll = (
    (clientPreChoices
      .filter(item => item.selected === -1)
      .length === 0)
    && areAllQuestionsChosen
  );
  const isAnsweredQuestion = useMemo(() => (answers[index] !== -1), [answers, index]);

  const getNavColors = useCallback((ind: number) => {
    const showResults = () => {
      if (isQuestionsModel) {
        return Boolean(answersState[ind] !== -1 || finishedState);
      }
      return Boolean(finishedState);
    };

    return getNavColorsUtil({
      ind,
      customProps: {
        data: accumulatedData,
        index,
        answers: isQuestionsModel ? answersState : answersMemo,
        showResults: showResults(),
        clientPreChoices,
        themeColors,
        isExamMode,
        colorMode,
        doubtQuestionIds,
      },
    });
  }, [
    answersMemo, answersState, clientPreChoices, colorMode, accumulatedData, doubtQuestionIds, finishedState, index,
    isExamMode, isQuestionsModel, themeColors,
  ]);

  const preChoiceAnswers = useMemo(() => {
    return accumulatedData.map(item => {
      const find = clientPreChoices.find(i => i.questionId === item._id)?.selected;
      if (find === undefined || find === null) {
        return -1;
      }
      return find;
    });
  }, [accumulatedData, clientPreChoices]);

  const isDisabled = useMemo(() => {
    const isFavorite = getTheoreticalType() === 'favorites';
    const isPastDay = !isTodayExtended();
    const isTimeOver = timeOver(startedAt, timeToDo);
    return isFavorite || isPastDay || (isMocksModel && isTimeOver);
  }, [isMocksModel, startedAt, timeToDo]);

  const showCounter = isMocksModel && !isDisabled && !finishedState;
  const alreadyStarted = clientPreChoices.length > 0 || answers.filter(a => a !== -1).length > 0;
  const isQuestionsDefaultMode = isQuestionsModel && !isExamMode;
  const hideResults = (isTodayExtended() && (isQuestionsDefaultMode ? !answeredAll : !(finishedState && selectedAll)))
    || isFavoritesModel;

  const hashClass = useMemo(() => {
    const h = {} as Record<string, {
      difficulty: string | undefined,
      classification: string[],
      correctChoice: number;
    }>;
    accumulatedData.forEach(item => {
      h[item._id] = {
        difficulty: item.difficulty,
        classification: item.classification,
        correctChoice: item.correctChoice,
      };
    });
    return h;
  }, [accumulatedData]);

  const handleSetClientPreChoices = useCallback((id: string, value: number, essay?: string) => {
    setClientPreChoices(old => {
      const ind = old.findIndex(i => i.questionId === id);

      if (ind === -1) {
        return [...old, { questionId: id, selected: value, essay }];
      }
      return [
        ...old.slice(0, ind),
        { ...old[ind], selected: value, ...(essay && { essay }) },
        ...old.slice(ind + 1),
      ];
    });
  }, [setClientPreChoices]);

  const result = useMemo(() => {
    return getResults(accumulatedData.map((i, idx) => {
      return {
        correctChoice: i.correctChoice,
        answer: (isExamMode && preChoiceAnswers[idx] !== -1)
          ? preChoiceAnswers[idx]
          : answers[idx],
        difficulty: i.difficulty,
        isEssay: i.isEssay,
      };
    }));
  }, [answers, accumulatedData, isExamMode, preChoiceAnswers]);

  const handleSelectChoice = useCallback(async (value: number) => {
    if ((isExamMode || !isQuestionsModel) && !isDiagnosticExam && !disableCommunication) {
      try {
        const createOrUpdate = await onCreateOrUpdateOnePreChoice({
          ...(activityId && { activity: activityId }),
          ...(isQuestionsModel && !activityId && { agenda: agenda?._id }),
          question: accumulatedData[index]._id,
          selected: value,
        });
        if (createOrUpdate) {
          handleSetClientPreChoices(accumulatedData[index]._id, value);
          return true;
        }
        throw new Error('PreChoice not saved.');
      } catch (error) {
        console.log(error);
        return false;
      }
    }
    if (diagnosticExamCallback) {
      const { handleUpdateDiagnosticExamPreChoice } = diagnosticExamCallback;
      try {
        const handleUpdateDiag = await handleUpdateDiagnosticExamPreChoice({
          questionId: accumulatedData[index]._id,
          selected: value,
        });
        if (handleUpdateDiag) {
          handleSetClientPreChoices(accumulatedData[index]._id, value);
          return true;
        }
        throw new Error('PreChoice not saved.');
      } catch (error) {
        console.error(error);
        toast.error({
          title: 'Houve um problema ao salvar sua escolha.',
          description: 'Verifique sua conexão e tente novamente.',
        });
        return false;
      }
    }
    return true;
  }, [
    activityId, agenda?._id, accumulatedData, diagnosticExamCallback, handleSetClientPreChoices,
    index, isDiagnosticExam, isExamMode, isQuestionsModel, onCreateOrUpdateOnePreChoice,
    disableCommunication,
  ]);

  const questionsSummary = useMemo(() => {
    // ALERT!!! correctChoice on essay questions is 0 by default in the database.
    // It must be changed in the future, because it's counterintuitive.
    const choices = (prefersExamMode && clientPreChoices) ? preChoiceAnswers : answers;
    const summary = { correct: 0, wrong: 0, total: accumulatedData.length };
    choices.forEach((selected, i) => {
      const { isEssay, correctChoice } = accumulatedData[i];
      const isAnswered = (selected !== -1);
      const isCancelled = (correctChoice === -1);
      const questionAnswer = (isEssay && !isCancelled) ? 0 : correctChoice;
      const isWrong = (selected !== questionAnswer && !isCancelled);
      if (isAnswered) {
        if (isWrong) {
          summary.wrong += 1;
        } else {
          summary.correct += 1;
        }
      }
    });
    return summary;
  }, [answers, clientPreChoices, accumulatedData, preChoiceAnswers, prefersExamMode]);

  const generateAnswers = useMemo((): ManyAnswerInterface[] => {
    return clientPreChoices
      .filter(i => i.selected !== -1)
      .map(item => ({
        choosen: item.selected,
        classification: hashClass[item.questionId]?.classification,
        document: item.questionId,
        difficulty: hashClass[item.questionId]?.difficulty,
        correctChoice: hashClass[item.questionId]?.correctChoice,
      }));
  }, [clientPreChoices, hashClass]);

  const createManyAnswerInput = useMemo(() => {
    return {
      activity: activityId,
      calendarDate: getDateParam(),
      theoreticalType: !getTheoreticalType().includes('extras') ? getTheoreticalType() : undefined,
      type: getActivityType(),
      answers: generateAnswers,
    };
  }, [activityId, generateAnswers]);

  const handleFinish = useCallback(async () => {
    if (((!isQuestionsModel && activityId) || isExamMode) && !disableCommunication) {
      setLoading(true);
      try {
        const createMany = await onCreateManyAnswer(createManyAnswerInput);
        if (createMany) {
          setFinishedState(true);
          if (isExamMode && activityId) {
            finishQuestions(activityId);
            if (questionsAndFlashcardsFinished.find(obj => obj._id === activityId)?.flashcards) {
              await onFinishExtraActivity(activityId);
            }
          }
        }
      } catch (error) {
        if (error instanceof ApolloError) {
          console.log(error.graphQLErrors[0].message);
        }
      } finally {
        setLoading(false);
      }
    }
  }, [
    isQuestionsModel, activityId, isExamMode, disableCommunication,
    onCreateManyAnswer, createManyAnswerInput, finishQuestions,
    questionsAndFlashcardsFinished, onFinishExtraActivity,
  ]);

  const handleSaveEssay = useCallback(async (essay: string) => {
    if ((isExamMode || !isQuestionsModel) && !disableCommunication) {
      handleSetClientPreChoices(accumulatedData[index]._id, -1, essay);
      await onCreateOrUpdateOnePreChoice({
        ...(activityId && { activity: activityId }),
        ...(!activityId && isQuestionsModel && { agenda: agenda?._id }),
        question: accumulatedData[index]._id,
        selected: -1,
        essay,
      });
    }
  }, [
    activityId, agenda?._id, accumulatedData, disableCommunication, handleSetClientPreChoices,
    index, isExamMode, isQuestionsModel, onCreateOrUpdateOnePreChoice,
  ]);

  const handleSetAnswersState = useCallback((i: number, value: number) => {
    setAnswersState(old => [...old.slice(0, i), value, ...old.slice(i + 1)]);
  }, [setAnswersState]);

  const handleSetEssaysState = useCallback((i: number, value: string) => {
    setEssaysState(old => [...old.slice(0, i), value, ...old.slice(i + 1)]);
  }, [setEssaysState]);

  const handleSubmitEssayAnswer = useCallback(async (essay: string, value: 0 | 1) => {
    if ((!(isExamMode) || (isExamMode && finishedState)) && !disableCommunication) {
      if (isQuestionsModel) {
        handleSetEssaysState(index, essay);
      }
      await onCreateOneAnswer({
        essay,
        choosen: value,
        document: accumulatedData[index]._id,
        calendarDate: getDateParam(),
        type: getActivityType() as 'question' | 'smart_exams' | 'extra-smart-review-question',
        classification: accumulatedData[index].classification,
        theoreticalType: !getTheoreticalType().includes('extras') ? getTheoreticalType() : undefined,
        activity: activityId,
        correctChoice: accumulatedData[index].correctChoice,
        difficulty: accumulatedData[index].difficulty,
      });
      if (isQuestionsModel) {
        handleSetAnswersState(index, value);
      }
      handleSetClientPreChoices(accumulatedData[index]._id, value);
    }
  }, [
    isExamMode, finishedState, disableCommunication, isQuestionsModel, onCreateOneAnswer,
    accumulatedData, index, activityId, handleSetClientPreChoices, handleSetEssaysState, handleSetAnswersState,
  ]);

  const showForum = useMemo(() => {
    if (isDiagnosticExam) {
      return false;
    }
    if (!isTodayExtended()) {
      return true;
    }
    if (isQuestionsModel && !isExamMode) {
      return isAnsweredQuestion;
    }
    if (accumulatedData[index].isEssay) {
      return finishedState && isAnsweredQuestion;
    }
    return finishedState;
  }, [accumulatedData, finishedState, index, isAnsweredQuestion, isDiagnosticExam, isExamMode, isQuestionsModel]);

  const handleAnswerClick = useCallback(async (value: number) => {
    if (!isExamMode && !disableCommunication) {
      try {
        const oneAnswer = await onCreateOneAnswer({
          choosen: value,
          document: accumulatedData[index]._id,
          calendarDate: getDateParam(),
          type: getActivityType() as 'question' | 'smart_exams' | 'extra-smart-review-question',
          classification: accumulatedData[index].classification,
          theoreticalType: !getTheoreticalType().includes('extras') ? getTheoreticalType() : undefined,
          activity: activityId,
          correctChoice: accumulatedData[index].correctChoice,
          difficulty: accumulatedData[index].difficulty,
        });
        if (oneAnswer !== undefined) {
          handleSetAnswersState(index, value);
        }
      } catch (error) {
        console.log(error);
      }
    }
  }, [activityId, accumulatedData, disableCommunication, handleSetAnswersState, index, isExamMode, onCreateOneAnswer]);

  const handleRankMockExam = useCallback(async () => {
    if (mockExamTemplate && activityId) {
      try {
        await onRankMockExam({
          activity: activityId,
          mockExamTemplate,
        });
      } catch (error) {
        console.log(error);
      }
    }
  }, [activityId, mockExamTemplate, onRankMockExam]);

  const currentPreChoice = useMemo(() => {
    return (
      (isExamMode || !isQuestionsModel)
        ? clientPreChoices.find(p => p.questionId === accumulatedData[index]._id)
        : undefined
    );
  }, [clientPreChoices, accumulatedData, index, isExamMode, isQuestionsModel]);

  const handleSetPrefersExamMode = useCallback(() => {
    setPrefersExamMode(prev => {
      localStorage.setItem('@plataformajj:prefersExamMode', JSON.stringify(!prev));
      return !prev;
    });
  }, [setPrefersExamMode]);

  const needToAnswer = useMemo(() => {
    const idsArray = accumulatedData.map(item => item._id);
    const essayQuestions = clientPreChoices
      .map(item => ({
        questionNumber: idsArray.findIndex(e => e === item.questionId) + 1,
        isEssay: Boolean(item.essay),
        selected: item.selected,
      }))
      .filter(item => item.isEssay && (item.selected === -1) && item.questionNumber !== 0)
      .map(item => item.questionNumber);
    essayQuestions.sort((a, b) => (a > b ? 1 : -1));
    return essayQuestions;
  }, [clientPreChoices, accumulatedData]);

  const calcDifficulty = useCallback((
    easy = 0,
    medium = 0,
    hard = 0,
    unclassified = 0,
  ) => {
    const total = easy + medium + hard + unclassified;
    const score = Math.round(((easy + 7 * medium + 10 * hard + 3.84 * unclassified) / total) * 100) / 100;

    if (score < 3) {
      return { label: 'fácil', score };
    }
    if (score < 4) {
      return { label: 'média', score };
    }
    if (score < 5) {
      return { label: 'difícil', score };
    }
    return { label: 'extremamente difícil', score };
  }, []);

  const [selectedChoice, setSelectedChoice] = useState(answers[index]);

  const onThemeSelectorChange = useCallback((value: string, i: number) => {
    classificationCascade.handleClassification(value, i);
    // setQuickSearchValue(currClassification.join('|'));
  }, [classificationCascade]);

  const isAddThemeButtonDisabled = useMemo(() => classificationCascade.currClassification.length === 0
    || classificationCascade.allClassifications.includes(classificationCascade.currClassification.join('|')),
  [classificationCascade.allClassifications, classificationCascade.currClassification]);

  // const filteredAccumulatedData = useMemo(() => {
  //   if (allClassifications.length === 0) {
  //     return accumulatedData;
  //   }
  //   return filterQuestionsByPrefixes({ prefixes: allClassifications, questions: accumulatedData });
  // }, [accumulatedData, allClassifications, filterQuestionsByPrefixes]);

  return {
    areAllQuestionsChosen,
    index,
    device,
    onPrev,
    onNext,
    onIndex,
    getNavColors,
    clientPreChoices,
    handleSetClientPreChoices,
    setClientPreChoices,
    answers: isQuestionsModel ? answersState : answersMemo,
    setAnswers: setAnswersState,
    essays: isQuestionsModel ? essaysState : essaysMemo,
    setEssays: setEssaysState,
    finishedState,
    currentPreChoice,
    setFinishedState,
    selectedChoice,
    setSelectedChoice,
    prefersExamMode,
    handleSetPrefersExamMode,
    isDisabled,
    hashClass,
    result,
    handleSelectChoice,
    questionsSummary,
    loading,
    setLoading,
    handleFinish,
    handleSaveEssay,
    handleSubmitEssayAnswer,
    handleAnswerClick,
    showForum,
    answeredAll,
    selectedAll,
    handleRankMockExam,
    isQuestionsModel,
    isMocksModel,
    isExamsModel,
    isExamMode,
    cardBorder,
    modelName,
    shouldConsiderExamMode,
    finishQuestions,
    questionsAndFlashcardsFinished,
    onFinishExtraActivity,
    isAnsweredQuestion,
    showCounter,
    alreadyStarted,
    isQuestionsDefaultMode,
    hideResults,
    needToAnswer,
    doubtQuestionIds,
    hiddenIndexes,
    visibleIndexes,
    toggleDifficultyVisibility,
    questionsToShow,
    isFavoritesModel,
    calcDifficulty,
    accumulatedData,
    setAccumulatedData,
    classificationCascade,
    isAddThemeButtonDisabled,
    onThemeSelectorChange,
  };
};
