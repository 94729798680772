export const setParam = ({ value, key }: {
  value: string,
  key: string,
}): { search: string; } => {
  const currentUrlParams = new URLSearchParams(window.location.search);
  currentUrlParams.set(key, value);
  return ({ search: currentUrlParams.toString() });
};

export const getParam = (key: string): string => {
  const currentUrlParams = new URLSearchParams(window.location.search);
  return currentUrlParams.get(key) || '';
};
