import React, { Dispatch, useCallback, useMemo, useState } from 'react';

export interface TableFilterHook<T> {
  handleFilter: (name: string) => void;
  filter: {
    name: string;
    dir: string;
  };
  dataFiltered: T[];
  setFilter: Dispatch<React.SetStateAction<{
    name: string;
    dir: string;
  }>>
}

export default function useTableFilter<T>(data: T[]): TableFilterHook<T> {
  const [filter, setFilter] = useState({
    name: '',
    dir: 'desc',
  });

  const dataFiltered = useMemo(() => {
    if (filter.name) {
      return [...data].sort((a, b) => {
        if (a[filter.name as keyof T] === undefined || a[filter.name as keyof T] === null) {
          return filter.dir === 'asc' ? -1 : 1;
        }
        if (b[filter.name as keyof T] === undefined || b[filter.name as keyof T] === null) {
          return filter.dir === 'asc' ? 1 : -1;
        }
        if (a[filter.name as keyof T] > b[filter.name as keyof T]) {
          if (filter.dir === 'desc') {
            return -1;
          }
          return 1;
        }
        if (a[filter.name as keyof T] < b[filter.name as keyof T]) {
          if (filter.dir === 'asc') {
            return -1;
          }
          return 1;
        }
        return 0;
      });
    }
    return data;
  }, [filter.dir, filter.name, data]);

  /** Callbacks */
  const handleFilter = useCallback((name: string) => {
    if (name === filter.name) {
      if (filter.dir === 'asc') {
        setFilter({
          name,
          dir: 'desc',
        });
      } else {
        setFilter({
          name: '',
          dir: '',
        });
      }
    } else {
      setFilter({
        name,
        dir: 'asc',
      });
    }
  }, [filter]);

  return {
    handleFilter,
    filter,
    dataFiltered,
    setFilter,
  };
}
