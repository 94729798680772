import { Flex, Text } from '@chakra-ui/react';
import React, {
  FC, useCallback, useContext, useState,
} from 'react';
import { toast } from 'react-toastify';
import { useExtraSmartExams } from '../../api/extra-activities/extra-smart-exams';
import { useDefaultStyles } from '../../hooks/useDefaultStyles';
import { PrimaryButton } from '../../lib/components/PrimaryButton';
import { SecondaryButton } from '../../lib/components/SecondaryButton';
import { AgendaContext } from '../agenda/Agenda.context';
import { ExtraActivityModalProps } from './CreateActivityModal';

const SmartMockExamModal: FC<ExtraActivityModalProps> = ({
  onClose, onAfterCreate,
}) => {
  const { refetchExtras } = useContext(AgendaContext);
  const { colors } = useDefaultStyles();

  const [queryLoading, setQueryLoading] = useState(false);
  const { onCreateSmartExamActivity } = useExtraSmartExams();

  const handleCreate = useCallback(async () => {
    setQueryLoading(true);
    try {
      await onCreateSmartExamActivity();
      await refetchExtras();
      onClose();
      if (onAfterCreate) {
        onAfterCreate();
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setQueryLoading(false);
    }
  }, [onAfterCreate, onClose, onCreateSmartExamActivity, refetchExtras]);

  return (
    <>
      <Text color={colors.secondary.goALittleDarker} mb={2} fontWeight="bold">Simulado Inteligente</Text>
      <Text mb={2}>
        {`Vamos gerar uma prova com base na instituição prioritária para a qual 
        você realizou menos simulados inteligentes.`}
      </Text>
      <Flex justify="end">
        <SecondaryButton onClick={onClose} mr={2} variant="ghost">
          Cancelar
        </SecondaryButton>
        <PrimaryButton
          isLoading={queryLoading}
          onClick={handleCreate}
        >
          Adicionar
        </PrimaryButton>
      </Flex>
    </>
  );
};

export default SmartMockExamModal;
