import {
  Box,
  Button, Modal, ModalBody,
  ModalContent, ModalHeader, ModalOverlay, Text,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';
import { faKeyboard } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC, ReactNode, useContext } from 'react';
import { useDefaultStyles } from '../../../hooks/useDefaultStyles';
import { WholeAppContext } from '../WholeApp.context';

type KeyboardTipsButtonType = {
  show?: boolean,
  children?: ReactNode,
  variant?: 'circle' | 'ghost',
}

export const KeyboardTipsButton: FC<KeyboardTipsButtonType> = ({
  show = true, children, variant = 'ghost',
}) => {
  const { colors, modal } = useDefaultStyles();
  const { onOpen, isOpen, onClose } = useDisclosure();
  const { areShortcutsEnabled, setAreShortcutsEnabled } = useContext(WholeAppContext);

  const onClick = () => {
    if (!areShortcutsEnabled) {
      onOpen();
    }
    setAreShortcutsEnabled(prev => !prev);
  };

  if (!show) {
    return null;
  }

  return (
    <Tooltip label={`${areShortcutsEnabled ? 'Desativar' : 'Ativar'} atalhos`}>
      <Button
        variant="ghost"
        overflow="hidden"
        position="relative"
        color={colors.secondary.keep}
        onClick={onClick}
        transition={variant === 'circle' ? 'all 0.5s' : ''}
        _focus={variant === 'circle' ? { borderColor: 'none' } : undefined}
        fontSize={variant === 'circle' ? 20 : undefined}
        borderRadius={variant === 'circle' ? 20 : undefined}
        width={variant === 'circle' ? 10 : undefined}
        height={variant === 'circle' ? 10 : undefined}
      >
        <Modal isOpen={isOpen} onClose={onClose} isCentered>
          <ModalOverlay />
          <ModalContent mx={4} borderRadius={10} overflow="hidden" bgColor={colors.background}>
            <ModalHeader
              py={2}
              display="flex"
              alignItems="center"
              color={modal.header.color}
              bgColor={modal.header.bgColor}
            >
              <Text flex={1} textAlign="center">Atalhos</Text>
            </ModalHeader>
            <ModalBody align="center" justify="center">
              {children}
            </ModalBody>
          </ModalContent>
        </Modal>
        <Box position="absolute">
          <FontAwesomeIcon icon={faKeyboard} />
        </Box>
        {
          areShortcutsEnabled && (
            <Box
              w={8}
              position="absolute"
              transform="rotate(-35deg)"
              h={1}
              bgColor={colors.secondary.keep}
            />
          )
        }
      </Button>
    </Tooltip>
  );
};
