import { Fragment, ReactNode, useMemo, useContext } from 'react';
import {
  faCircle,
  IconName,
  IconLookup,
  faBars,
  faSignOutAlt,
  faExchangeAlt,
  faQuestionCircle, faArrowLeft, faMoon, faSun,
} from '@fortawesome/free-solid-svg-icons';
import {
  Text,
  useDisclosure,
  Drawer,
  Box,
  VStack,
  Button,
  DrawerFooter,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  HStack,
  LinkOverlay,
  LinkBox,
  ButtonProps,
  IconButton,
  useColorMode,
} from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { findIconDefinition } from '@fortawesome/fontawesome-svg-core';
import { BurgerMenuItemType } from '../../../api/burger-menu';
import useAccount from '../../../hooks/useAccount';
import CourseSelectionModal from '../CourseSelectionModal';
import { Divider } from '../../../lib/components/Divider';
import { CourseContext } from '../../../CourseInfoContext';
import { PrivateContext } from '../../../Private.context';
import { useDefaultStyles } from '../../../hooks/useDefaultStyles';
import { PlatformExperimentButton } from '../PlatformExperimentButton';

interface DrawerMenuProps {
  items?: [string, BurgerMenuItemType[]][];
}

interface ItemButtonProps extends ButtonProps {
  icon: ReactNode;
  title: string;
}

function ItemButton({
  icon, title, ...rest
}: ItemButtonProps) {
  const { colors } = useDefaultStyles();
  return (
    <Button
      variant="unstyled"
      w="100%"
      fontWeight="normal"
      _hover={{ bgColor: colors.alpha[200] }}
      {...rest}
    >
      <HStack align="center" px={2}>
        {icon}
        <Text>
          {title}
        </Text>
      </HStack>
    </Button>
  );
}

interface DrawerMenuItemProps {
  item: BurgerMenuItemType;
  onClose: () => void;
}

export function DrawerMenuItem({
  item, onClose,
}: DrawerMenuItemProps) {
  const history = useHistory();
  const iconLookup: IconLookup = { prefix: 'fas', iconName: item.icon as IconName };
  const iconDefinition = findIconDefinition(iconLookup) || faCircle;

  return !item.url.startsWith('/') ? (
    <LinkBox w="100%">
      <LinkOverlay href={item.url} isExternal>
        <ItemButton
          icon={<FontAwesomeIcon icon={iconDefinition} />}
          title={item.title}
        />
      </LinkOverlay>
    </LinkBox>
  ) : (
    <ItemButton
      icon={<FontAwesomeIcon icon={iconDefinition} />}
      title={item.title}
      onClick={() => {
        history.push(item.url);
        onClose();
      }}
    />
  );
}

export function DrawerMenu({
  items,
}: DrawerMenuProps) {
  const { colorMode, toggleColorMode } = useColorMode();
  const { colors } = useDefaultStyles();
  const { logout } = useAccount();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const {
    isOpen: isCourseSelectionOpen,
    onClose: onCourseSelectionClose,
    onOpen: onCourseSelectionOpen,
  } = useDisclosure();

  const { course, loading } = useContext(CourseContext);
  const { profile } = useContext(PrivateContext);

  const userCourse = useMemo(() => {
    if (profile.courses && profile.courses.length > 0 && course && !loading) {
      return course.find(c => c._id === profile.courses[0].course);
    }
    return undefined;
  }, [course, loading, profile.courses]);

  return (
    <>
      <Box as="button" color={colors.lighter.fromWhite} fontSize="1.5rem" onClick={onOpen}>
        <FontAwesomeIcon icon={faBars} />
      </Box>
      <Drawer
        isOpen={isOpen}
        onClose={onClose}
        placement="right"
        // size="sm"
      >
        <DrawerOverlay />
        <DrawerContent bgColor={colors.background} overflow="auto">
          <DrawerBody px={2}>
            <Box mb={2}>
              <HStack align="center" spacing={4}>
                <IconButton
                  aria-label="voltar"
                  icon={<FontAwesomeIcon icon={faArrowLeft} />}
                  onClick={onClose}
                  variant="ghost"
                />
                <Text fontWeight="bold">
                  {userCourse ? userCourse.title : 'Menu'}
                </Text>
              </HStack>
            </Box>
            <VStack align="flex-start" spacing={1}>
              <ItemButton
                icon={<FontAwesomeIcon icon={faExchangeAlt} />}
                title="Trocar de curso"
                onClick={onCourseSelectionOpen}
              />
              <ItemButton
                icon={<FontAwesomeIcon icon={faQuestionCircle} />}
                title="Tutoriais"
                id="tutorial-button"
              />
              <ItemButton
                icon={<FontAwesomeIcon icon={colorMode === 'dark' ? faSun : faMoon} />}
                title={`Tema ${colorMode === 'dark' ? 'claro' : 'escuro'}`}
                onClick={toggleColorMode}
              />
            </VStack>
            {items && items.map(subgroup => (
              <Fragment key={subgroup[0]}>
                <Divider my={2} />
                <Text fontWeight="bold">{subgroup[0]}</Text>
                <VStack title={subgroup[0]} align="flex-start" spacing={1}>
                  {subgroup[1].map(item => (
                    <DrawerMenuItem key={`${item.title}-${item.url}`} item={item} onClose={onClose} />
                  ))}
                </VStack>
              </Fragment>
            ))}
            <PlatformExperimentButton />
          </DrawerBody>
          <DrawerFooter px={2}>
            <ItemButton
              color={colors.red.keep}
              icon={<FontAwesomeIcon icon={faSignOutAlt} />}
              title="Sair"
              onClick={logout}
            />
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
      <CourseSelectionModal isOpen={isCourseSelectionOpen} onClose={onCourseSelectionClose} />
    </>
  );
}
