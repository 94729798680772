import moment from 'moment';

export function humanizePubDate(date: string) {
  const momentDate = moment(date);
  const now = moment();
  const duration = moment.duration(momentDate.diff(now));
  if (Math.abs(duration.as('month')) > 1) {
    return `Publicado em ${momentDate.format('DD[/]MM[/]YY')}`;
  }
  return `${duration.locale('pt-BR').humanize(true)}`;
}
