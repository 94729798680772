/* eslint-disable react/jsx-props-no-spreading */
import React, { FC, ComponentType } from 'react';
import { Route, Redirect } from 'react-router-dom';
import useAccount from './hooks/useAccount';

const ToHome: FC<Redirect> = () => <Redirect to="/registrar" />;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const PrivateRoute: ComponentType<any> = ({ component, ...rest }) => {
  const account = useAccount();
  const secureComponent = account.isAuthenticated() ? component : ToHome;
  return (
    <Route {...rest} component={secureComponent} />
  );
};

export default PrivateRoute;
