import { DifficultyType } from '../api/answer';

interface GetResultsDataInputType {
  correctChoice: number;
  answer: number;
  difficulty: DifficultyType;
  isEssay?: boolean;
}

export function getResults(data: GetResultsDataInputType[]): {
  easy: {
    hit: number;
    total: number;
  };
  medium: {
    hit: number;
    total: number;
  };
  hard: {
    hit: number;
    total: number;
  };
  unknown: {
    hit: number;
    total: number;
  };
} {
  const res = data.reduce((ac, item) => {
    if (item.difficulty === 'easy') {
      if (item.isEssay) {
        if (item.answer === 0 || item.correctChoice === -1) {
          return { ...ac, easy: { hit: ac.easy.hit + 1, total: ac.easy.total + 1 } };
        }
        return { ...ac, easy: { hit: ac.easy.hit, total: ac.easy.total + 1 } };
      }
      if (item.answer === item.correctChoice || item.correctChoice === -1) {
        return { ...ac, easy: { hit: ac.easy.hit + 1, total: ac.easy.total + 1 } };
      }
      return { ...ac, easy: { hit: ac.easy.hit, total: ac.easy.total + 1 } };
    }
    if (item.difficulty === 'medium') {
      if (item.isEssay) {
        if (item.answer === 0 || item.correctChoice === -1) {
          return { ...ac, medium: { hit: ac.medium.hit + 1, total: ac.medium.total + 1 } };
        }
        return { ...ac, medium: { hit: ac.medium.hit, total: ac.medium.total + 1 } };
      }
      if (item.answer === item.correctChoice || item.correctChoice === -1) {
        return { ...ac, medium: { hit: ac.medium.hit + 1, total: ac.medium.total + 1 } };
      }
      return { ...ac, medium: { hit: ac.medium.hit, total: ac.medium.total + 1 } };
    }
    if (item.difficulty === 'hard') {
      if (item.isEssay) {
        if (item.answer === 0 || item.correctChoice === -1) {
          return { ...ac, hard: { hit: ac.hard.hit + 1, total: ac.hard.total + 1 } };
        }
        return { ...ac, hard: { hit: ac.hard.hit, total: ac.hard.total + 1 } };
      }
      if (item.answer === item.correctChoice || item.correctChoice === -1) {
        return { ...ac, hard: { hit: ac.hard.hit + 1, total: ac.hard.total + 1 } };
      }
      return { ...ac, hard: { hit: ac.hard.hit, total: ac.hard.total + 1 } };
    }
    if (item.isEssay) {
      if (item.answer === 0 || item.correctChoice === -1) {
        return { ...ac, unknown: { hit: ac.unknown.hit + 1, total: ac.unknown.total + 1 } };
      }
      return { ...ac, unknown: { hit: ac.unknown.hit, total: ac.unknown.total + 1 } };
    }
    if (item.answer === item.correctChoice || item.correctChoice === -1) {
      return { ...ac, unknown: { hit: ac.unknown.hit + 1, total: ac.unknown.total + 1 } };
    }
    return { ...ac, unknown: { hit: ac.unknown.hit, total: ac.unknown.total + 1 } };
  }, {
    easy: {
      hit: 0,
      total: 0,
    },
    medium: {
      hit: 0,
      total: 0,
    },
    hard: {
      hit: 0,
      total: 0,
    },
    unknown: {
      hit: 0,
      total: 0,
    },
  });
  return res;
}
