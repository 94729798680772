import { Box, Text, Image, useBreakpointValue, Link } from '@chakra-ui/react';
import { FC, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faFacebook, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { Divider } from '../../../lib/components/Divider';
import { MyGrid } from './MyGrid';
import Flex from '../../../lib/components/Flex';

const LandingFooter: FC = () => {
  const align: 'center' | 'start' | undefined = useBreakpointValue({
    base: 'center',
    sm: 'center',
    md: 'center',
    lg: 'start',
  });

  const textLink = useCallback((text: string, href: string) => {
    return (
      <Box mb={4} align={align}>
        <Link href={href} align={align} isExternal>{text}</Link>
      </Box>
    );
  }, [align]);

  return (
    <Box background="#041223" color="#fff">
      <Box
        maxW={1220}
        px={8}
        py={8}
        mx="auto"
        my="0"
        alignItems="center"
        justifyContent="center"
        display="flex"
        flexDirection="column"
      >
        <MyGrid
          cols={[1, 1, 1, 3]}
          gap={10}
          id="main-footer"
        >
          <Flex direction="column" align={align}>
            <Image src="/images/logo-aristo-white.png" alt="logo" width={135} height={25.2} mb={4} />
            <Text align={align}>O melhor curso de Residência Médica e Revalidação do país.</Text>
          </Flex>
          <MyGrid
            cols={[2, 2, 2, 2]}
            gap={6}
            id="footer-links"
          >
            <Box>
              <Text align={align} mb={4} fontWeight="semibold">Navegue</Text>
              {textLink('Quem somos', 'https://aristo.com.br/quem-somos/')}
              {textLink('Cursos', 'https://aristo.com.br/cursos/')}
              {textLink('Editais', 'https://aristo.com.br/editais/')}
              {textLink('Depoimentos', 'https://aristo.com.br/depoimentos/')}
              {textLink('Blog', 'https://aristo.com.br/blog/')}
            </Box>
            <Box>
              <Text align={align} mb={4} fontWeight="semibold">Suporte</Text>
              {
                textLink(
                  'Política de Privacidade',
                  'https://pages.aristo.com.br/politicas-de-privacidade/',
                )
              }
              {textLink('Política de Cookies', 'https://pages.aristo.com.br/politicas-de-cookies/')}
              {textLink('Termos de Uso', 'https://pages.aristo.com.br/termos-de-uso/')}
              {textLink('Fale Conosco', 'mailto:equipe@aristo.com.br')}
            </Box>
          </MyGrid>
          <Box alignItems={align}>
            <Text align={align} mb={2}>Siga nossa redes sociais</Text>
            <Flex justify={align}>
              <Link href="https://www.instagram.com/aristoresidenciamedica/" isExternal mr={4}>
                <FontAwesomeIcon size="2x" icon={faInstagram} />
              </Link>
              <Link href="https://www.facebook.com/aristoresidenciamedica/" isExternal mr={4}>
                <FontAwesomeIcon size="2x" icon={faFacebook} />
              </Link>
              <Link href="https://www.youtube.com/c/AristoResidenciaMedica" isExternal>
                <FontAwesomeIcon size="2x" icon={faYoutube} />
              </Link>
            </Flex>
          </Box>
        </MyGrid>
        <Divider my={4} />
        <Text letterSpacing={1.05} color="#FFFFFF77">© Copyright 2024. Todos os direitos reservados.</Text>
      </Box>
    </Box>
  );
};

export default LandingFooter;
