import {
  Image,
  Popover, PopoverArrow, PopoverBody,
  PopoverCloseButton, PopoverContent, PopoverHeader, PopoverTrigger,
} from '@chakra-ui/react';
import { FC } from 'react';
import { useDefaultStyles } from '../../../../hooks/useDefaultStyles';
import { InlineText } from '../../../_components/InlineText.component';

type ImagePopOverType = {
  text: string,
  imageSrc: string,
  title?: string,
  maxW?: number,
}

export const ImagePopOver: FC<ImagePopOverType> = ({
  text,
  imageSrc,
  title,
  maxW = 150,
}: ImagePopOverType) => {
  const { colors } = useDefaultStyles();
  return (
    <Popover trigger="hover">
      <PopoverTrigger>
        <InlineText
          cursor="pointer"
          color={colors.secondary.keep}
          fontStyle="italic"
          _hover={{
            textDecoration: 'underline',
          }}
        >
          {text}
        </InlineText>
      </PopoverTrigger>
      <PopoverContent maxW={maxW}>
        <PopoverArrow />
        <PopoverCloseButton />
        {title && (<PopoverHeader>{title}</PopoverHeader>)}
        <PopoverBody>
          <Image src={imageSrc} />
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
