import { Box, Center, HStack, Text, Tooltip, useDisclosure } from '@chakra-ui/react';
import { faThumbsDown, faThumbsUp } from '@fortawesome/free-regular-svg-icons';
import {
  faThumbsDown as faThumbsDownSolid,
  faThumbsUp as faThumbsUpSolid,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useRateOne, useRatings } from '../../api/ratings';
import { useDefaultStyles } from '../../hooks/useDefaultStyles';
import { useDevice } from '../../hooks/useDevice';
import { toast } from '../../utils/toast';
import { WholeAppContext } from '../agenda/WholeApp.context';
import ModalReport from './ModalReport';

interface LikeDislikeProps {
  document: string;
  type: 'flashcard' | 'question' | 'comment';
}

export function LikeDislike({
  document, type,
}: LikeDislikeProps) {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const device = useDevice();
  const [loading, setLoading] = useState(false);
  const [hover, setHover] = useState(0);
  const [rate, setRate] = useState(0);
  const { colors } = useDefaultStyles();
  const { hideElementsThatTriggerModals } = useContext(WholeAppContext);

  const { data, loading: loadingQuery, refetch } = useRateOne({
    document,
    type,
  });

  useEffect(() => {
    setRate(data?.rateOne ?? 0);
  }, [data?.rateOne]);

  const { onRateCreateOrUpdate } = useRatings();

  const handleMouse = useCallback(num => () => {
    if (device === 'mobile') {
      return;
    }
    setHover(num);
  }, [device]);

  const handleClick = useCallback((val: number) => async () => {
    setLoading(true);
    try {
      await onRateCreateOrUpdate({
        document,
        type,
        rate: val,
      });
      await refetch();
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  }, [document, onRateCreateOrUpdate, refetch, type]);

  return (
    <HStack align="center" justify="center" fontSize="1.2rem" color="light.200" spacing={4} minW="20px">
      {
        (loading || loadingQuery) ? (
          <Center fontSize="1.2rem">
            <FontAwesomeIcon icon={faSpinner} spin />
          </Center>
        ) : (
          <>
            <Box
              as="button"
              onMouseEnter={handleMouse(1)}
              onMouseLeave={handleMouse(0)}
              onClick={handleClick(1)}
            >
              <Tooltip label="Gostei">
                {(hover === 1) || (rate === 1) ? (
                  <Text color={colors.secondary.keep}>
                    <FontAwesomeIcon icon={faThumbsUpSolid} />
                  </Text>
                ) : (
                  <Text>
                    <FontAwesomeIcon icon={faThumbsUp} />
                  </Text>
                )}
              </Tooltip>
            </Box>
            {
              !hideElementsThatTriggerModals && (
                <Box
                  as="button"
                  onMouseEnter={handleMouse(-1)}
                  onMouseLeave={handleMouse(0)}
                  onClick={onOpen}
                >
                  <Tooltip label="Não gostei">
                    {(hover === -1) || (rate === 0.2) ? (
                      <Text color={colors.secondary.keep}>
                        <FontAwesomeIcon icon={faThumbsDownSolid} />
                      </Text>
                    ) : (
                      <Text>
                        <FontAwesomeIcon icon={faThumbsDown} />
                      </Text>
                    )}
                  </Tooltip>
                </Box>
              )
            }
            <ModalReport
              id={document}
              type={type}
              isOpen={isOpen}
              onClose={onClose}
              onReport={handleClick(0.2)}
              modalIcon={<FontAwesomeIcon icon={faThumbsDownSolid} />}
            />
          </>
        )
      }
    </HStack>
  );
}
