import React, { FC } from 'react';
import { AspectRatio, AspectRatioProps } from '@chakra-ui/react';

interface VideoProps extends AspectRatioProps {
  id: string;
  url: string;
}

const Video: FC<VideoProps> = ({
  id,
  url,
  ...rest
}) => {
  return (
    <AspectRatio maxW={560 * 1.75} w="100%" bgColor="black" ratio={560 / 315} id="video-aspect-ratio" {...rest}>
      <iframe
        src={url}
        title={id}
        allowFullScreen
      />
    </AspectRatio>
  );
};

export default Video;
