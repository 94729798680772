import { Tooltip, HStack, Text, Flex } from '@chakra-ui/react';
import React, { FC } from 'react';
import { useDefaultStyles } from '../../../hooks/useDefaultStyles';
import Toggle from '../../../lib/components/Toggle';

interface ExamModeSwitchProps {
  active: boolean;
  onChange: () => void;
  disabled: boolean;
  hide?: boolean;
}

export const ExamModeSwitch: FC<ExamModeSwitchProps> = ({
  active, onChange, disabled, hide = false,
}) => {
  const { colors } = useDefaultStyles();
  const Component = (
    <Flex justify="flex-start" minW="50%">
      <HStack>
        <Text color={disabled ? colors.darkGray.goLighter : colors.secondary.keep}>Modo prova</Text>
        <Toggle
          active={active}
          color="primary"
          onChange={onChange}
          disabled={disabled}
        />
      </HStack>
    </Flex>
  );
  if (hide) {
    return <></>;
  }
  return (
    <Tooltip
      label={`O modo prova te permite ver os comentários e a resposta correta apenas depois
        de responder todas as questões da sua RI. Selecione o modo desejado antes de iniciar a sua RI.
        Após iniciar o botão fica bloqueado.`}
      placement="bottom-start"
    >
      {Component}
    </Tooltip>
  );
};
