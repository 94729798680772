import { FC } from 'react';
import { Box } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import { useDefaultStyles } from '../../hooks/useDefaultStyles';

interface ContentButtonProps {
  active?: boolean;
  to: string;
  search?: string;
  onClick?: () => void;
  minW?: { base?: number, md: number };
}

const ContentTab: FC<ContentButtonProps> = ({
  children, active = false, to, search, minW = { base: 120, md: 160 }, onClick,
}) => {
  const history = useHistory();
  const { colors } = useDefaultStyles();

  return (
    <Box
      as="button"
      display="flex"
      onClick={() => {
        if (onClick) {
          onClick();
        }
        history.push({
          pathname: to,
          search,
        });
      }}
      justifyContent="center"
      alignItems="center"
      bgColor={active ? colors.lighter.goAlmostFullDarker : undefined}
      color={active ? colors.secondary.keep : colors.lightSecondary.goDarker}
      fontWeight={active ? 'bold' : undefined}
      border="1px solid"
      borderColor={active ? colors.secondary.keep : colors.lightSecondary.goDarker}
      borderBottomColor={active ? colors.background : colors.secondary.keep}
      borderRadius="10px 10px 0 0"
      zIndex={1}
      minW={minW}
      whiteSpace="nowrap"
      py={{ base: 1, md: 2 }}
      px={{ base: 2, md: 4 }}
      transition="all 0.3s"
      _hover={{
        bgColor: active ? '' : colors.lighter.goAlmostFullDarker,
        color: active ? '' : colors.secondary.keep,
      }}
    >
      {children}
    </Box>
  );
};

export default ContentTab;
