import { Button, Input, InputGroup, InputProps, InputRightElement, Text } from '@chakra-ui/react';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Dispatch, FC, useState } from 'react';

interface PasswordAristoProps extends InputProps{
  name: string;
  label?: string;
  required?: boolean;
  setter: Dispatch<React.SetStateAction<string>>;
  getter: string;
  noOfLines?: number;
  adjust?: boolean;
}

export const PasswordAristo: FC<PasswordAristoProps> = ({
  name, label, required = true, setter, getter, noOfLines, adjust = true, ...rest
}: PasswordAristoProps) => {
  const [showPassword, setShowPassword] = useState(false);
  return (
    <>
      <Text noOfLines={noOfLines}>{label}</Text>
      <InputGroup>
        <Input
          borderColor="primary.600"
          // borderRadius={0}
          padding="1.5rem"
          name={name}
          type={showPassword ? 'text' : 'password'}
          onChange={event => setter(event.target.value)}
          value={getter}
          required={required}
          {...rest}
        />
        <InputRightElement marginTop={adjust ? '.3rem' : ''}>
          <Button
            variant="unstyled"
            _hover={{ variant: 'ghost' }}
            _focus={{ variant: 'unstyled' }}
            onClick={() => setShowPassword(prev => !prev)}
          >
            <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash} />
          </Button>
        </InputRightElement>
      </InputGroup>
    </>
  );
};
