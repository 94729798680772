import { Box, Center, ScaleFade } from '@chakra-ui/react';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, useContext, useEffect } from 'react';
import { useDefaultStyles } from '../../../../hooks/useDefaultStyles';
import { FlashcardsCreationContext } from '../../contexts/FlashcardsCreation.context';

type AddCardButtonType = {
  onClick: () => void;
  show?: boolean;
  delay?: number;
  mt?: number,
  ml?: number,
}

export const AddCardButton: FC<AddCardButtonType> = ({
  onClick, show = true, delay = 1, mt, ml,
}: AddCardButtonType) => {
  const {
    setScaleFadeIn,
    scaleFadeIn,
    tab,
    currLessonId,
    masterLoading,
  } = useContext(FlashcardsCreationContext);

  const isCommunityDecks = (tab === 'comunidade') && !currLessonId;

  useEffect(() => {
    if (!isCommunityDecks
      && !scaleFadeIn
      && !Object.values(masterLoading).some(x => x)) {
      setTimeout(() => setScaleFadeIn(true), 1000 * delay);
    }
  }, [delay, isCommunityDecks, masterLoading, scaleFadeIn, setScaleFadeIn]);

  const { colors } = useDefaultStyles();
  if (!show) {
    return <></>;
  }
  return (
    <ScaleFade
      in={isCommunityDecks || scaleFadeIn}
      delay={isCommunityDecks ? delay : 0}
      initialScale={0.1}
    >
      <Center
        w={140}
        h={170}
        borderRadius={10}
        as="button"
        onClick={onClick}
        mt={mt || 2}
        ml={ml || 2}
        transition="transform .2s, background-color .5s;"
        _hover={{
          transform: 'translateY(-5px);',
        }}
        bgColor={colors.lighter.goAlmostFullDarker}
      >
        <Center
          h={100}
          w="100%"
          borderRadius="0 15px 0 15px"
          flexFlow="column"
          position="relative"
        >
          <Box
            position="absolute"
            fontSize={100}
            color={colors.lighterSecondary.keep}
            opacity={0.2}
          >
            <FontAwesomeIcon icon={faPlusCircle} />
          </Box>
        </Center>
      </Center>
    </ScaleFade>
  );
};
