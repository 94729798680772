import React, { FC } from 'react';
import { faSignal } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import SizedBox from '../../lib/components/SizedBox';

interface DifficultyIconProps {
  difficulty: 'easy' | 'medium' | 'hard';
}

const DifficultyIcon: FC<DifficultyIconProps> = ({ difficulty }) => {
  let width;
  if (difficulty === 'easy') {
    width = '33%';
  } else if (difficulty === 'medium') {
    width = '66%';
  } else {
    width = '100%';
  }

  return (
    <SizedBox position="relative" style={{ display: 'inline-block' }}>
      <FontAwesomeIcon
        icon={faSignal}
        className="color-medium-gray"
      />
      <SizedBox
        position="absolute"
        overflow="hidden"
        top={0}
        left={0}
        width={width}
      >
        <FontAwesomeIcon
          icon={faSignal}
          className={clsx({
            'color-warning': difficulty === 'easy',
            'color-orange': difficulty === 'medium',
            'color-danger': difficulty === 'hard',
          })}
        />
      </SizedBox>
    </SizedBox>

  );
};

export default DifficultyIcon;
