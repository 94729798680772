import {
  FC, useState, useMemo, useCallback, useContext,
} from 'react';
import { Flex, Text, VStack } from '@chakra-ui/react';
import { toast } from 'react-toastify';
import { useGetMockExamTemplates } from '../../api/agenda/mock-exam';
import Select from '../../lib/components/Select';
import { AgendaContext } from '../agenda/Agenda.context';
import { useExtraMockExams } from '../../api/extra-activities/extra-mock-exams';
import { ExtraActivityModalProps } from './CreateActivityModal';
import { SecondaryButton } from '../../lib/components/SecondaryButton';
import { PrimaryButton } from '../../lib/components/PrimaryButton';
import { useDefaultStyles } from '../../hooks/useDefaultStyles';

const MockExamModal: FC<ExtraActivityModalProps> = ({
  onClose, onAfterCreate,
}) => {
  const { refetchExtras } = useContext(AgendaContext);
  const { colors } = useDefaultStyles();

  const [loading, setLoading] = useState(false);

  const { onCreateMockExamExtraActivity } = useExtraMockExams();
  const queryTemplates = useGetMockExamTemplates();

  const templates = useMemo(() => {
    const { data } = queryTemplates;
    if (data) {
      return data.getMockExamTemplates;
    }
    return [];
  }, [queryTemplates]);

  const [mockId, setMockId] = useState('');

  const handleMockId = useCallback(e => {
    setMockId(e.target.value);
  }, []);

  const handleCreate = useCallback(async () => {
    setLoading(true);
    try {
      await onCreateMockExamExtraActivity({
        template: mockId,
      });
      await refetchExtras();
      onClose();
      if (onAfterCreate) {
        onAfterCreate();
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  }, [mockId, onAfterCreate, onClose, onCreateMockExamExtraActivity, refetchExtras]);

  return (
    <>
      <Text color={colors.secondary.goALittleDarker} mb={2} fontWeight="bold">Simulados</Text>
      <Text className="mb-2">Qual simulado deseja fazer?</Text>
      <VStack py={2} mb={4} align="start">
        <Select
          w="100%"
          value={mockId}
          options={templates.map(item => ({ value: item.template, label: item.title }))}
          onChange={handleMockId}
          loading={queryTemplates.loading}
        />
      </VStack>
      <Flex justify="end">
        <SecondaryButton onClick={onClose} mr={2} variant="ghost">
          Cancelar
        </SecondaryButton>
        <PrimaryButton
          isLoading={loading}
          onClick={handleCreate}
          disabled={mockId === ''}
        >
          Adicionar
        </PrimaryButton>
      </Flex>
    </>
  );
};

export default MockExamModal;
