import { Box, Fade, Image } from '@chakra-ui/react';
import { useEffect, useState } from 'react';

const NUMBER_OF_IMAGES = 6;

export function EnterPageSlideShow() {
  const [activeImage, setActiveImage] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveImage(prev => ((prev + 1) % NUMBER_OF_IMAGES));
    }, 3500);
    return () => clearInterval(interval);
  }, []);

  return (
    <Box display={{ base: 'none', lg: 'block' }} position="relative">
      {Array.from({ length: NUMBER_OF_IMAGES }, (_, i) => i).map((e, index) => (
        <Fade
          key={`picture${e}`}
          in={activeImage === index}
          transition={{
            enter: { duration: 1 },
            exit: { duration: 1 },
          }}
          unmountOnExit
        >
          <Image
            position="absolute"
            top="14.5%"
            right="7.1%"
            width="58%"
            src={`images/screen-prints/print${index}.png`}
          />
        </Fade>
      ))}
      <Image width={450} src="images/smartphones.png" />
    </Box>
  );
}
