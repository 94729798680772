import { Button, Center, HStack, Input, InputGroup, InputLeftElement, useDisclosure } from '@chakra-ui/react';
import { faQuestionCircle, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, useContext } from 'react';
import { useDefaultStyles } from '../../../../hooks/useDefaultStyles';
import { FlashcardsCreationContext } from '../../contexts/FlashcardsCreation.context';
import { FlashcardsCreationInfo } from '../Drawers/FlashcardsCreationInfo';
import { AreaFilter } from './AreaFilter';

export const AreaSearchBar: FC = () => {
  const { setSearchInput, searchInput, currLessonId } = useContext(FlashcardsCreationContext);
  const { colors } = useDefaultStyles();
  const { onOpen, isOpen, onClose } = useDisclosure();

  if (currLessonId) {
    return <></>;
  }

  return (
    <Center
      flexFlow={{ base: 'column', md: 'row' }}
      gridGap={{ base: 1, md: 4 }}
      px={{ base: 5, md: 10 }}
      mb={{ base: 4, md: 0 }}
      w="100%"
    >
      <FlashcardsCreationInfo isOpen={isOpen} onClose={onClose} />
      <HStack w="100%" spacing={4}>
        <InputGroup
          marginY="0.5rem"
        >
          <InputLeftElement color={colors.secondary.goALittleDarker}>
            <FontAwesomeIcon icon={faSearch} />
          </InputLeftElement>
          <Input
            borderColor={colors.secondary.goALittleDarker}
            value={searchInput}
            onChange={e => {
              if (setSearchInput) {
                setSearchInput(e.target.value);
              }
            }}
            placeholder="Pesquisar tema"
            _placeholder={{ color: colors.secondary.goALittleDarker }}
          />
        </InputGroup>
        <Button
          variant="solid"
          borderColor={colors.secondary.keep}
          color={colors.secondary.keep}
          onClick={onOpen}
        >
          <FontAwesomeIcon icon={faQuestionCircle} />
        </Button>
      </HStack>
      <AreaFilter />
    </Center>
  );
};
