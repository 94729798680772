import { gql, useMutation } from '@apollo/client';

const CREATE_ACCOUNT = gql`
  mutation CreateAccount (
    $email: String!,
    $password:  String!,
    $name: String!,
    $courseId: String!,
  ) {
    createAccount (
      email: $email,
      password: $password,
      name: $name
      courseId: $courseId,
    ){
      uid
    }
  }
`;

interface CreateAccountInput {
  email: string;
  password: string;
  name: string;
  courseId: string;
}

interface CreateAccountOutput {
  uid: string;
}

export function useCreateAccount() {
  return useMutation<
    CreateAccountOutput, CreateAccountInput
  >(CREATE_ACCOUNT);
}
