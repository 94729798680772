import { Box, Button, Flex, Text, Tooltip } from '@chakra-ui/react';
import { faPlusCircle, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC } from 'react';
import { useDefaultStyles } from '../../../hooks/useDefaultStyles';
import { SearchableSelector } from '../SearchableSelector';

type ClassificationCascadeType = {
  classificationOptions: string[][];
  handleClassification: (value: string, i: number) => void;
  allClassifications: string[];
  currClassification: string[];
  addClassification: (classification: string[]) => void;
  removeClassification: (classification: string[]) => void;
  allPrefixes: string[];
  setCurrClassification: React.Dispatch<React.SetStateAction<string[]>>;
}

type FilterQuestionsByThemeInputType = {
  hide: boolean;
  isAddThemeButtonDisabled: boolean;
  onThemeSelectorChange: (e: string, i: number) => void,
  classificationCascade: ClassificationCascadeType,

}

export const FilterQuestionsByTheme: FC<FilterQuestionsByThemeInputType> = ({
  classificationCascade,
  isAddThemeButtonDisabled,
  onThemeSelectorChange,
  hide,
}) => {
  const { colors } = useDefaultStyles();
  const {
    addClassification,
    allClassifications,
    classificationOptions,
    currClassification,
    removeClassification,
  } = classificationCascade;

  if (hide) {
    return null;
  }

  return (
    <Flex w="100%" flexFlow="column" py={2}>
      <Text color={colors.secondary.keep} fontWeight="bold" minW={150}>
        Filtrar por tema:
      </Text>
      <Flex flexWrap="wrap" style={{ gap: '12px' }}>
        {
          !!classificationOptions[0] && classificationOptions.map((_, i) => (
            <SearchableSelector
              w={300}
              key={Math.random()}
              options={classificationOptions[i].map(x => ({ value: x, label: x }))}
              value={currClassification[i]}
              onChange={e => onThemeSelectorChange(e, i)}
            />
          ))
        }
        <Button
          color={colors.secondary.keep}
          variant="ghost"
          disabled={isAddThemeButtonDisabled}
          leftIcon={<FontAwesomeIcon icon={faPlusCircle} />}
          onClick={() => addClassification(currClassification)}
        >
          Adicionar tema
        </Button>
      </Flex>
      {
        allClassifications.length > 0 ? (
          <Flex
            w="100%"
            bgColor={colors.lighter.goAlmostFullDarker}
            flexFlow="column"
            p={4}
            maxH={300}
            overflowY="auto"
          >
            <Text color={colors.secondary.keep} fontWeight="bold">Temas selecionados:</Text>
            {
              allClassifications.map((classif, i) => {
                const _classif = classif.split('|');
                const lastLevel = _classif[_classif.length - 1];
                return (
                  <Flex w="100%" justify="space-between" key={Math.random()}>
                    <Text key={Math.random()} noOfLines={1}>
                      {`${i + 1} - ${lastLevel}`}
                    </Text>
                    <Tooltip label={`Remover ${lastLevel}`}>
                      <Box
                        as="button"
                        fontSize={12}
                        color={colors.secondary.keep}
                        onClick={() => removeClassification(_classif)}
                      >
                        <FontAwesomeIcon icon={faTrashAlt} />
                      </Box>
                    </Tooltip>
                  </Flex>
                );
              })
            }
          </Flex>
        ) : ''
      }
    </Flex>
  );
};
