/* eslint-disable no-else-return */
import { Box, HStack, Text, useColorMode, VStack } from '@chakra-ui/react';
import React, { FC } from 'react';
import { useDefaultStyles } from '../../../../hooks/useDefaultStyles';

interface ChartTolltipProps {
  active?: boolean;
  payload?: {
    name: string;
    value: number;
  }[];
  label?: string;
}

const ChartTooltip: FC<ChartTolltipProps> = ({ active, payload, label }) => {
  const { colorMode } = useColorMode();
  const { colors, cardBorder } = useDefaultStyles();

  if (active && payload && (payload.length >= 2)) {
    return (
      <Box
        p={2}
        bgColor={colorMode === 'light' ? 'background.light' : 'background.dark'}
        {...cardBorder}
      >
        <VStack>
          <Text fontSize="sm" fontWeight="bold" color={colors.secondary.goLighter}>{label}</Text>
          <HStack>
            <Text>Geral:</Text>
            <Text>{`${(payload[0].value * 100).toFixed(2)}%`}</Text>
          </HStack>
          <HStack color={colors.secondary.goLighter}>
            <Text>Você:</Text>
            <Text>{`${(payload[1].value * 100).toFixed(2)}%`}</Text>
          </HStack>
        </VStack>
      </Box>
    );
  }
  return null;
  // return (active && payload && payload.length) ? (
  // <div
  //   style={{
  //     marginBottom: '8px',
  //     padding: '8px',
  //     borderStyle: 'solid',
  //     borderColor: theme.colors.light[200],
  //     borderWidth: '1px',
  //     background: 'white',
  //   }}
  //   className="elevated"
  // >
  //   <div className="mb-1">
  //     {label}
  //   </div>
  //   {payload.map((item, index: number) => {
  //     const i = index;
  //     return (
  //       <div
  //         key={i}
  //         style={{
  //           color: colors[item.name as keyof typeof colors],
  //           marginBottom: '4px',
  //         }}
  //       >
  //         <Text bold>
  //           {names[item.name as keyof typeof names]}
  //           :&nbsp;
  //           {`${(item.value * 100).toFixed(2)}%`}
  //         </Text>
  //       </div>
  //     );
  //   })}
  // </div>
  // ) : (
  //   <div />
  // );
};

export default ChartTooltip;
