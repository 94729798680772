import React, { FC } from 'react';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, HStack, Text } from '@chakra-ui/react';
import { useDefaultStyles } from '../../hooks/useDefaultStyles';

const TagQuestionCanceled: FC = () => {
  const { colors } = useDefaultStyles();
  return (
    <Box
      display="flex"
      py={2}
      px={1}
      mb={1}
      backgroundColor="primary.400"
      borderRadius={4}
    >
      <HStack align="center" color={colors.secondary.goALittleDarker}>
        <FontAwesomeIcon icon={faInfoCircle} size="lg" />
        <Text fontWeight="bold">
          QUESTÃO ANULADA
        </Text>
      </HStack>
    </Box>
  );
};

export default TagQuestionCanceled;
