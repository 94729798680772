import { useState } from 'react';
import useFirebaseStorageImg from './useFirebaseStorageImg';

export function useProfileImg(profileId: string) {
  const [temporaryImage, setTemporaryImage] = useState('');

  const { getURL } = useFirebaseStorageImg();

  const firebaseImgUrl = getURL(`/images/users/${profileId}`);
  return {
    imageSrc: temporaryImage || (firebaseImgUrl.includes('broken') ? '' : firebaseImgUrl),
    setImage: setTemporaryImage,
  };
}
