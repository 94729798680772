import {
  useState, useMemo, useCallback,
} from 'react';
import {
  TreeLeaf,
  useClassificationTrees,
} from '../api/classification-trees';

const useClassificationCascade = (treeId: string) => {
  const [currClassification, setCurrClassification] = useState<string[]>([]);
  const [allClassifications, setAllClassifications] = useState<string[]>([]);

  const queryTree = useClassificationTrees({
    treeId,
    skip: false,
  });

  const classificationTree = useMemo(() => {
    const { data } = queryTree;
    if (data) {
      return data.classificationTree.tree;
    }
    return [];
  }, [queryTree]);

  const flattenClassificationTree = useCallback((trees: TreeLeaf[]): string[] => {
    return trees.map(tree => {
      if (tree.children) {
        const plainStringArray: TreeLeaf[] = tree.children?.map(x => (
          { name: `${tree.name}|${x.name}`, children: x.children }
        ));
        return flattenClassificationTree(plainStringArray);
      }
      return tree.name;
    }).flat();
  }, []);

  const flatennedClassificationTree = useMemo(() => {
    return flattenClassificationTree(classificationTree).map(x => x.split('|'));
  }, [classificationTree, flattenClassificationTree]);

  const getAllPrefixesFromString = useCallback((string: string) => {
    const array = string.split('|');
    const prefixes = [];
    for (let i = 1; i <= array.length; i += 1) {
      prefixes.push(array.slice(0, i).join('|'));
    }
    return prefixes;
  }, []);

  const getAllPrefixesFromTree = useCallback((array: string[]) => {
    const prefixes = [];
    for (let i = 0; i < array.length; i += 1) {
      prefixes.push(getAllPrefixesFromString(array[i]));
    }
    return Array.from(new Set(prefixes.flat()));
  }, [getAllPrefixesFromString]);

  const allPrefixes = useMemo(() => {
    const prefixes = getAllPrefixesFromTree(flattenClassificationTree(classificationTree));
    prefixes.sort((a, b) => {
      const aSplitted = a.split('|');
      const bSplitted = b.split('|');
      return aSplitted[aSplitted.length - 1] > bSplitted[bSplitted.length - 1] ? 1 : -1;
    });
    return prefixes;
  },
  [classificationTree, flattenClassificationTree, getAllPrefixesFromTree]);

  const getMaxInputsAmout = useCallback((classifArrays: string[][]) => {
    return classifArrays.reduce((acc, curr) => (curr.length > acc ? curr.length : acc), 0);
  }, []);

  const generateArrayOfOptions = useCallback((classifArrays: string[][]) => {
    const arrays: string[][] = [];
    for (let i = 0; i < getMaxInputsAmout(classifArrays); i += 1) {
      arrays.push([]);
    }
    classifArrays.forEach(leafArray => {
      leafArray.forEach((f, i) => {
        arrays[i].push(f);
      });
    });
    return arrays.map(x => Array.from(new Set(x)).sort());
  }, [getMaxInputsAmout]);

  const getOptionsByPrefix = useCallback((prefix: string[], classifications: string[][]) => {
    const resp = [];
    for (let i = 0; i < prefix.length; i += 1) {
      const _arr = classifications.filter(classif => (classif[i] === prefix[i]));
      const column = generateArrayOfOptions(_arr)[i + 1];
      if (column) {
        resp.push(column);
      }
    }
    return [generateArrayOfOptions(classifications)[0], ...resp];
  }, [generateArrayOfOptions]);

  const classificationOptions = useMemo(() => getOptionsByPrefix(currClassification, flatennedClassificationTree),
    [currClassification, flatennedClassificationTree, getOptionsByPrefix]);

  const handleClassification = useCallback((value: string, i: number) => {
    setCurrClassification(prev => {
      const _array = [...prev];
      _array[i] = value;
      return _array.slice(0, i + 1).filter(x => x !== '');
    });
  }, []);

  const addClassification = useCallback((classification: string[]) => {
    setAllClassifications(prev => [...prev, classification.join('|')]);
  }, []);

  const removeClassification = useCallback((classification: string[]) => {
    setAllClassifications(prev => prev.filter(x => x !== classification.join('|')));
  }, []);

  const removeClassificationString = useCallback((classification: string) => {
    setAllClassifications(prev => prev.filter(x => x !== classification));
  }, []);

  return {
    classificationOptions,
    handleClassification,
    allClassifications,
    currClassification,
    addClassification,
    removeClassification,
    removeClassificationString,
    allPrefixes,
    setCurrClassification,
  };
};

export default useClassificationCascade;
