import { Box, Button, Flex, Input, Select, Text } from '@chakra-ui/react';
import {
  FC, FormEvent, useCallback, useEffect, useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import { useDiagnosticExam } from '../../../api/diagnosis';
import { useDefaultStyles, useRGBColor } from '../../../hooks/useDefaultStyles';
import { useDevice } from '../../../hooks/useDevice';
import { useLocalStorage } from '../../../hooks/useLocalStorage';
import { toast } from '../../../utils/toast';
import { FormItem } from '../_components/FormItem';
import { MainBoxTemplate } from '../_components/MainBoxTemplate';
import { MeteorRain } from '../_components/MeteorRain';

export const DiagnosisFormPage: FC = () => {
  const [currentPage, setCurrentPage] = useState(4);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [country, setCountry] = useState('Brasil (+55)');
  const { toRGB } = useRGBColor();
  const [phone, setPhone] = useState('');
  const [activityId, setActivityId] = useState('');
  const [howDidYouMeetUs, setHowDidYouMeetUs] = useState('Selecione');
  const history = useHistory();
  const device = useDevice();
  const formOptions = ['Indicação de amigo', 'Instagram', 'Spotify', 'Youtube', 'Google', 'Outro'];
  const { colors, cardBorder } = useDefaultStyles();
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [isCompass] = useLocalStorage('@Aristoclass:isCompass');

  const { handleSubmitFormDiagnosticExam } = useDiagnosticExam({ _id: activityId });

  const handleSubmit = useCallback(async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmitLoading(true);
    try {
      await handleSubmitFormDiagnosticExam({
        _id: activityId,
        email,
        name,
        phone,
        howDidYouMeetUs,
      });
      setCurrentPage(5);
    } catch (error) {
      toast.error('Ocorreu um erro ao submeter o seu formulário');
    } finally {
      setIsSubmitLoading(false);
    }
  }, [activityId, email, handleSubmitFormDiagnosticExam, howDidYouMeetUs, name, phone]);

  const formatPhone = useCallback(({ phone: phoneNumber, unformat }: { phone: string, unformat?: boolean }) => {
    const cleaned = (phoneNumber).replace(/\D/g, '');
    if (unformat) {
      return cleaned;
    }
    if (cleaned.length === 0) {
      return '';
    }
    if (cleaned.length <= 2) {
      return `(${cleaned}`;
    }
    if (cleaned.length <= 7) {
      return `(${cleaned.slice(0, 2)}) ${cleaned.slice(2)}`;
    }
    return `(${cleaned.slice(0, 2)}) ${cleaned.slice(2, 7)}-${cleaned.slice(7)}`;
  }, []);

  useEffect(() => {
    const localActivityId = localStorage.getItem('@plataformaJJMentoria:id');
    if (!localActivityId) {
      history.push('/diagnostico');
    } else if (!(localStorage.getItem('@plataformaJJMentoria:finished') === 'true')) {
      history.push('/diagnostico/prova');
    }
    if (localActivityId) {
      setActivityId(localActivityId);
    }
  }, [history]);

  if (currentPage === 4) {
    return (
      <MainBoxTemplate
        mainTitle="Parabéns!"
        descriptionTitle="Agora você está pronto para receber seu diagnóstico de preparação!"
        color={isCompass ? colors.darkerGray.keep : undefined}
        titleColor={isCompass ? colors.primary.keep : ''}
        bgColor={isCompass ? `${toRGB(colors.white.keep)}` : ''}
        details={[
          `Mas antes, a gente gostaria de te conhecer melhor!
          Em seguida, enviaremos o seu resultado por e-mail.`]}
        submitBtn
        isSubmitButtonLoading={isSubmitLoading}
        submitBtnDisabled={phone === '' || name === '' || !email.includes('@') || howDidYouMeetUs === 'Selecione'}
        targetForm="userForm"
        textMarginBottom="1rem"
        titleMarginTop={5}
        {...(isCompass && cardBorder)}
      >
        <form
          id="userForm"
          onSubmit={handleSubmit}
        >
          <FormItem
            label="Nome completo:"
          >
            <Input
              type="text"
              borderColor="solid black 2px"
              name="name"
              required
              value={name}
              boxShadow={isCompass ? `inset 0px 0px 2px 0px ${toRGB(colors.primary.keep)}` : ''}
              bgColor="white"
              onChange={e => setName(e.target.value)}
              maxLength={75}
              placeholder="Ex: Bruno Luis da Silva"
              _placeholder={{ color: 'gray' }}
              width="100%"
            />
          </FormItem>

          <FormItem
            label="E-mail:"
          >
            <Input
              type="email"
              name="email"
              required
              value={email}
              bgColor="white"
              boxShadow={isCompass ? 'inset 0px 0px 2px 0px black' : ''}
              onChange={e => setEmail(e.target.value.replaceAll(' ', '').toLowerCase())}
              maxLength={75}
              placeholder="Ex: meucontato@mail.com"
              _placeholder={{ color: 'gray' }}
            />
          </FormItem>

          <FormItem
            label="Telefone"
            flexFlow={device === 'mobile' ? 'column' : 'row'}
          >
            <Box width={{ base: '100%', md: '30%' }}>
              <Select
                color="aristo.900"
                bgColor="white"
                name="country"
                boxShadow={isCompass ? 'inset 0px 0px 2px 0px black' : ''}
                onChange={e => {
                  setCountry(e.target.value);
                  setPhone('');
                }}
                value={country}
              >
                <option style={{ backgroundColor: 'white' }}>Brasil (+55)</option>
                <option style={{ backgroundColor: 'white' }}>Outro</option>
              </Select>
            </Box>

            <Input
              width={{ base: '100%', md: '67%' }}
              margin="0"
              bgColor="white"
              type={country === 'Brasil (+55)' ? 'tel' : 'text'}
              pattern={country === 'Brasil (+55)' ? '\\([0-9]{2}\\)\\s[0-9]{5}-[0-9]{4}' : undefined}
              title="(xx) xxxxx-xxxx"
              boxShadow={isCompass ? 'inset 0px 0px 2px 0px black' : ''}
              onChange={e => {
                if (country === 'Brasil (+55)') {
                  const formated = formatPhone({ phone: e.target.value });
                  setPhone(formated);
                } else {
                  const formated = e.target.value.replace(/\D/g, '');
                  setPhone(formated);
                }
              }}
              name="phone"
              maxLength={15}
              value={phone}
              _placeholder={{ color: 'gray' }}
              placeholder={country === 'Brasil (+55)' ? 'Ex: (11) 12345-6789' : 'Digite aqui seu telefone'}
            />
          </FormItem>

          <FormItem
            label="Como conheceu a Aristo?"
          >
            <Box width="100%">
              <Select
                bgColor="white"
                color="aristo.900"
                name="howDidYouMeetUs"
                boxShadow={isCompass ? 'inset 0px 0px 2px 0px black' : ''}
                value={howDidYouMeetUs}
                onChange={e => setHowDidYouMeetUs(e.target.value)}
              >
                <option style={{ backgroundColor: 'white' }} disabled>Selecione</option>
                {
                  formOptions.map(opt => {
                    return <option key={opt} style={{ backgroundColor: 'white' }}>{opt}</option>;
                  })
                }
              </Select>
            </Box>
          </FormItem>

        </form>
      </MainBoxTemplate>
    );
  }

  if (currentPage === 5) {
    return (
      <>
        <MeteorRain />
        <Flex
          marginTop={{ base: '0rem', md: isCompass ? 0 : '5rem' }}
          maxW={800}
          m="0 auto"
          p={5}
          marginBottom="4rem"
          align="center"
          flexFlow="column"
          color={isCompass ? colors.primary.keep : colors.white.keep}
          textAlign="center"
          gridGap="1rem"
          fontSize="1.2rem"
        >
          <Text
            fontSize={{ base: '2.5rem', md: '4rem' }}
          >
            Relatório enviado!
          </Text>
          <Text>
            Verifique no seu e-mail o relatório com os
            assuntos que você mais precisa estudar de acordo com as instituições que você selecionou!
          </Text>
          <Text>
            Caso não tenha recebido, aguarde 5 minutos e não se esqueça de verificar sua caixa de spam!
          </Text>
          <Text>
            Clique em voltar para re-enviar o e-mail ou para corrigir algum dado.
          </Text>
          <Button
            border={`solid ${isCompass ? toRGB(colors.primary.keep) : 'white'} .2rem`}
            borderRadius={0}
            fontSize="2rem"
            marginTop={{ base: '1rem', md: '2rem' }}
            _hover={{ bgColor: 'rgba(255, 255, 255, 0.212)' }}
            height="4rem"
            width={{ base: '10rem', md: '18rem' }}
            transition="background-color .3s linear"
            variant="unstyled"
            onClick={() => {
              setCurrentPage(4);
            }}
          >
            Voltar
          </Button>
        </Flex>
      </>
    );
  }

  return <></>;
};
