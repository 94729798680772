import {
  Modal, ModalContent, ModalOverlay,
} from '@chakra-ui/react';
import { FC, useCallback, useContext, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { useProfile } from '../../../api/profile';
import { CourseContext } from '../../../CourseInfoContext';
import { useDefaultStyles } from '../../../hooks/useDefaultStyles';
import { useDevice } from '../../../hooks/useDevice';
import { Loading } from '../../../lib/components/Loading';
import { PrivateContext } from '../../../Private.context';
import { OnboardingDefault } from '../../onboarding/contents/OnboardingDefault';
import { OnboardingEMC } from '../../onboarding/contents/OnboardingEMC';
import { EMCCourses } from '../../_components/Header/SideMenu';

export const FirstAccessModal: FC = () => {
  const [welcomeStep, setWelcomeStep] = useState(0);
  const device = useDevice();
  const { profile } = useContext(PrivateContext);
  const { questionnaireAnswered } = profile;
  const { onUpdateProfile } = useProfile();
  const { colors } = useDefaultStyles();

  const { course, loading } = useContext(CourseContext);

  const userCourse = useMemo(() => {
    if (profile.courses && profile.courses.length > 0 && course && !loading) {
      return course.find(c => c._id === profile.courses[0].course);
    }
    return undefined;
  }, [course, loading, profile.courses]);

  const nextStep = useCallback(() => {
    setWelcomeStep(prev => prev + 1);
  }, []);

  const previousStep = useCallback(() => {
    setWelcomeStep(prev => prev - 1);
  }, []);

  const handleOnSave = useCallback(async () => {
    try {
      await onUpdateProfile({
        questionnaireAnswered: true,
      });
    } catch (error) {
      toast.error(error);
    }
  }, [onUpdateProfile]);

  const quitModal = useCallback(() => {
    handleOnSave();
    setWelcomeStep(-1);
  }, [handleOnSave]);

  const currentModal = useMemo(() => {
    if (userCourse && EMCCourses.includes(userCourse._id)) {
      return (
        <OnboardingEMC
          courseId={userCourse._id}
          nextStep={nextStep}
          previousStep={previousStep}
          quitModal={quitModal}
          setWelcomeStep={setWelcomeStep}
          welcomeStep={welcomeStep}
        />
      );
    }
    return (
      <OnboardingDefault
        nextStep={nextStep}
        previousStep={previousStep}
        quitModal={quitModal}
        setWelcomeStep={setWelcomeStep}
        welcomeStep={welcomeStep}
      />
    );
  }, [userCourse, nextStep, previousStep, quitModal, welcomeStep]);

  return (
    <Modal
      isOpen={welcomeStep !== -1 && !questionnaireAnswered}
      onClose={() => ('')}
      size={device === 'mobile' ? 'full' : '4xl'}
      isCentered
    >
      <ModalOverlay />
      <ModalContent
        bgColor={colors.background}
        borderRadius={10}
        padding={5}
        height="80%"
        alignItems="center"
        justifyContent="center"
        overflow="auto"
      >
        {userCourse ? currentModal : <Loading />}
      </ModalContent>
    </Modal>
  );
};
