import { Center, Flex, Text, VStack } from '@chakra-ui/react';
import { faBrain, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC, useState } from 'react';
import { useDefaultStyles } from '../../../hooks/useDefaultStyles';

type InfoCardType = {
  text: string,
  icon?: IconDefinition;
  title: string;
}

export const InfoCard: FC<InfoCardType> = ({
  icon, title, text,
}) => {
  const { colors } = useDefaultStyles();
  const [isOverTheCard, setIsOverTheCard] = useState(false);
  return (
    <Flex
      w={300}
      h={260}
      borderRadius={20}
      bgColor={colors.white.keep}
      color={colors.darkerGray.keep}
      p={5}
      flexFlow="column"
      justifyContent="center"
      position="relative"
      onMouseEnter={() => setIsOverTheCard(true)}
      onMouseLeave={() => setIsOverTheCard(false)}
      textAlign="center"
      style={{ gap: '12px' }}
    >
      <VStack
        spacing={3}
        w="100%"
        flexFlow="column"
        position="absolute"
        align="center"
        justify="center"
        top={7}
        left={0}
        h="6.5rem"
      >
        <Flex w="100%" justifyContent="center" color={colors.intermediary.keep} fontSize={40}>
          <FontAwesomeIcon icon={icon || faBrain} />
        </Flex>
        <Center>
          <Text
            color={colors.intermediary.keep}
            fontSize={12}
            fontWeight="bold"
            style={{ fontVariant: 'small-caps' }}
          >
            {title}
          </Text>
        </Center>
      </VStack>
      <Text position="absolute" top="8.5rem" left={0} px={6}>{text}</Text>
      <Flex position="absolute" w="100%" bottom={0} left={3} h="12rem">
        <Flex position="relative" w="45%" right={0}>
          <Flex
            bottom={0}
            right={0}
            position="absolute"
            w={isOverTheCard ? '100%' : 0}
            transition="width 0.4s"
            bgColor={colors.intermediary.keep}
            h="5px"
          />
        </Flex>
        <Flex position="relative" w="45%" left={0}>
          <Flex
            bottom={0}
            left={0}
            position="absolute"
            w={isOverTheCard ? '100%' : 0}
            transition="width 0.4s"
            bgColor={colors.intermediary.keep}
            h="5px"
          />
        </Flex>
      </Flex>
    </Flex>
  );
};
