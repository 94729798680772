import { useEffect, useState } from 'react';
import moment from 'moment';
import { prependZeros } from '../utils/number.utils';

export function useStopwatch(timeLimit: moment.Moment): {h: string, m: string, s: string} {
  const [now, setNow] = useState('');

  useEffect(() => {
    const interval = setInterval(() => {
      const timeLeft = moment.duration(timeLimit.diff(moment(), 'milliseconds'), 'milliseconds');
      const leftHours = Math.floor(timeLeft.asHours());
      const leftMinutes = timeLeft.minutes();
      const leftSeconds = timeLeft.seconds();

      if (timeLeft.asMilliseconds() <= 0) {
        setNow('00:00:00');
      } else {
        setNow(`${prependZeros(leftHours, 2)}:${prependZeros(leftMinutes, 2)}:${prependZeros(leftSeconds, 2)}`);
      }
    }, 1000);
    return (): void => clearInterval(interval);
  }, [timeLimit]);
  return {
    h: now.split(':')[0],
    m: now.split(':')[1],
    s: now.split(':')[2],
  };
}
