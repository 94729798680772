import { Flex, FlexProps, Heading } from '@chakra-ui/react';
import { FC, useMemo, useState } from 'react';
import { InstitutionDataType } from '../../../api/aristo-compass/exam-statistics';
// import { useExamStatistics } from '../../../api/aristo-compass/exam-statistics';
import { useDefaultStyles } from '../../../hooks/useDefaultStyles';
import Select from '../../../lib/components/Select';
import { CompassInstitutionTable } from './CompassInstitutionTable';

interface ExamsThemesListType extends FlexProps {
  institutionData?: InstitutionDataType[],
  loading?: boolean,
}

export const ExamsThemesList: FC<ExamsThemesListType> = ({
  institutionData,
  loading = false,
  ...props
}) => {
  // const { data } = useExamStatistics();
  const { colors, cardBorder } = useDefaultStyles();
  const [selectedExam, setSelectedExam] = useState('Geral');

  const selectedExamData = useMemo(() => {
    if (institutionData) {
      const filtered = institutionData.filter(inst => inst.institution === selectedExam);
      if (filtered.length === 1) {
        return filtered[0].themeStatistics;
      }
    }
    return [];
  }, [institutionData, selectedExam]);

  return (
    <Flex
      flexFlow="column"
      w="100%"
      p={4}
      bgColor={colors.white.keep}
      h="100%"
      {...cardBorder}
      {...props}
    >
      <Flex w="100%" mb={3} justify="space-between" flexFlow={{ base: 'column', md: 'row' }}>
        <Heading size="md">Principais temas das suas provas</Heading>
        <Select
          my={{ base: 5, md: 0 }}
          w={{ base: '100%', md: '50%' }}
          value={selectedExam}
          placeholder={loading ? 'Carregando...' : ''}
          options={institutionData && institutionData.map(item => ({
            value: item.institution,
            label: item.institution === 'Geral' ? 'Geral das insitutições selecionadas' : item.institution,
          }))}
          onChange={e => setSelectedExam(e.target.value)}
        />
      </Flex>
      {
        selectedExamData.length > 0 && (
          <CompassInstitutionTable
            institutionsInfo={selectedExamData}
          />
        )
      }
    </Flex>
  );
};
