import { Grid, GridItem } from '@chakra-ui/react';
import { FC, ReactNode } from 'react';

type MyGridType = {
  children: ReactNode[],
  gap?: number,
  mb?: number,
  cols: number[],
  alignItems?: 'center' | 'start' | undefined,
  justifyContent?: 'center' | 'start' | undefined,
  id: string,
}

export const MyGrid: FC<MyGridType> = ({
  children, gap = 6, mb = 0, cols, alignItems = 'start', id, justifyContent = 'start',
}) => {
  return (
    <Grid
      templateColumns={{
        base: `repeat(${cols[0]}, 1fr)`, // Mobile (default)
        sm: `repeat(${cols[1]}, 1fr)`, // Small screens (min-width 30em)
        md: `repeat(${cols[2]}, 1fr)`, // Small screens (min-width 30em)
        lg: `repeat(${cols[3]}, 1fr)`, // Small screens (min-width 30em)
      }}
      gap={gap}
      mb={mb}
      alignItems={alignItems}
      justifyContent={justifyContent}
    >
      {
        children.map((e, i) => {
          const key = `${id} ${i}`;
          return <GridItem keyey={key} w="100%">{e}</GridItem>;
        })
      }
    </Grid>
  );
};
