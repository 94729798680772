import {
  FC, useCallback, useEffect, useState,
} from 'react';
import { toast } from 'react-toastify';
import { Box, BoxProps, Flex } from '@chakra-ui/react';
import { faStar } from '@fortawesome/free-regular-svg-icons';
import { faSpinner, faStar as faStarSolid } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DocTypes } from '../../api/agenda/agenda';
import { useRatings, useRateOne } from '../../api/ratings';

interface StarsProps {
  document: string;
  type: DocTypes;
}

interface StarProps extends BoxProps {
  isActive?: boolean;
}

function Star({
  isActive, ...rest
}: StarProps) {
  return (
    <Box
      as="button"
      color={isActive ? 'warning.500' : ''}
      px={1}
      {...rest}
    >
      <FontAwesomeIcon icon={isActive ? faStarSolid : faStar} />
    </Box>
  );
}

const Stars: FC<StarsProps> = ({
  document, type,
}) => {
  const [loading, setLoading] = useState(false);
  const [hover, setHover] = useState(0);
  const [rate, setRate] = useState(0);

  const { data, loading: loadingQuery, refetch } = useRateOne({
    document,
    type,
  });

  useEffect(() => {
    setRate(data?.rateOne ?? 0);
  }, [data?.rateOne]);

  const { onRateCreateOrUpdate } = useRatings();

  const handleMouse = useCallback(num => () => {
    setHover(num);
  }, []);

  const handleClick = useCallback((val: number) => async () => {
    setLoading(true);
    try {
      await onRateCreateOrUpdate({
        document,
        type,
        rate: val,
      });
      await refetch();
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
      setHover(0);
    }
  }, [document, onRateCreateOrUpdate, refetch, type]);

  return (
    <Flex justify="center" fontSize="1.2rem" color="light.200">
      {
        (loading || loadingQuery) ? (
          <Box fontSize="1.2rem">
            <FontAwesomeIcon icon={faSpinner} spin />
          </Box>
        ) : Array(5).fill(0).map((_, i) => (
          <Star
            key={String(i)}
            onClick={handleClick((i + 1) * 0.2)}
            onMouseEnter={handleMouse(i + 1)}
            onMouseLeave={handleMouse(0)}
            isActive={(((rate ?? 0) > (0.2 * i) && hover === 0) || hover > i)}
          />
        ))
      }
    </Flex>
  );
};

export default Stars;
