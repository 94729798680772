import { gql, QueryResult, useQuery } from '@apollo/client';

export interface GetActivityExamsHistory {
  _id: string;
  smartExamTemplate: string;
  institution: string;
  uf: string;
  year: number;
  grade?: number;
  _t: string;
  calendarDate: string;
}

interface GetActivityExamsHistoryOutput {
  getActivityExamsHistory: GetActivityExamsHistory[];
}

const GET_ACTIVITY_EXAMS_HISTORY = gql`
  query GetActivityExamsHistory {
    getActivityExamsHistory {
      _id
      smartExamTemplate
      institution
      uf
      year
      grade
      _t
      calendarDate
    }
  }
`;

export function useGetExamsHistory(): QueryResult<GetActivityExamsHistoryOutput> {
  return useQuery<GetActivityExamsHistoryOutput>(GET_ACTIVITY_EXAMS_HISTORY);
}
