import { Box, Flex, Text } from '@chakra-ui/react';
import React, { Dispatch, FC, useCallback } from 'react';
import { toast } from 'react-toastify';
import { InstitutionTarget } from '../../../api/profile';
import useInstitutions from '../../../hooks/useInstitutions';
import { PrimaryButton } from '../../../lib/components/PrimaryButton';
import Select from '../../../lib/components/Select';

interface InstitutionIncluderProps {
  setDirtyInputs: Dispatch<React.SetStateAction<boolean>>;
  it: InstitutionTarget[];
  setIt: Dispatch<React.SetStateAction<InstitutionTarget[]>>;
  onClose?: () => void;
}

export const InstitutionIncluder: FC<InstitutionIncluderProps> = ({
  setDirtyInputs, it, setIt, onClose,
}: InstitutionIncluderProps) => {
  const {
    uf,
    institution,
    ufs: UFS,
    institutions,
    handleUf,
    handleInstitution,
    loading: loadingInstitution,
    handleClear,
  } = useInstitutions('', '', 0, '', true);
  const handleAdd = useCallback(() => {
    if (it.find(c => c.uf === uf && c.institution === institution)) {
      toast.warning('Instituição já adicionada!');
    } else {
      setIt([...it, { uf, institution }]);
      setDirtyInputs(true);
      handleClear();
    }
  }, [handleClear, institution, it, setDirtyInputs, setIt, uf]);

  return (
    <Flex flexFlow="column" gridGap={3} margin="auto" width="80%">

      <Box>
        <Text>
          Selecione o estado
        </Text>
        <Select
          borderColor="darkgray"
          borderWidth="2px"
          value={uf}
          onChange={handleUf}
          placeholder="UF"
          options={UFS.map(item => ({ value: item, label: item }))}
          loading={loadingInstitution}
        />
      </Box>

      <Box>
        <Text>
          Instituição
        </Text>
        <Select
          borderColor="darkgray"
          borderWidth="2px"
          value={institution}
          onChange={handleInstitution}
          loading={loadingInstitution}
          placeholder="Escolha"
          options={institutions.map(item => ({ value: item, label: item }))}
        />
      </Box>

      <Flex justify="flex-end" marginY={3}>
        <PrimaryButton
          onClick={() => {
            if (onClose
              && !it.find(c => c.uf === uf && c.institution === institution)) {
              onClose();
            }
            handleAdd();
          }}
          disabled={!institution}
        >
          Adicionar
        </PrimaryButton>
      </Flex>
    </Flex>
  );
};
