import { Box, Circle, Flex, HStack, Text } from '@chakra-ui/react';
import { useMemo } from 'react';
import { useDefaultStyles } from '../../../hooks/useDefaultStyles';
import ProgressBar from '../../agenda/_components/ProgressBar.component';
import { TrophyFragments, TrophyFragmentsType } from './TrophyFragments';

interface TheoreticalTypeProgressProps {
  gold: number;
  silver: number;
  bronze: number;
  done: number;
}

type MarkerFragmentsType = {
  bronze: TrophyFragmentsType,
  silver: TrophyFragmentsType,
  gold: TrophyFragmentsType,
}

export function TheoreticalTypeProgress({
  gold, silver, bronze, done,
}: TheoreticalTypeProgressProps) {
  const { colors } = useDefaultStyles();
  const markerFragments: MarkerFragmentsType = useMemo(() => {
    if (bronze === silver && silver === gold) {
      return {
        bronze: ['bronze', 'silver', 'gold'],
        silver: [],
        gold: [],
      };
    }
    if (bronze === silver) {
      return {
        bronze: ['bronze', 'silver'],
        silver: [],
        gold: ['gold'],
      };
    }
    if (silver === gold) {
      return {
        bronze: ['bronze'],
        silver: ['silver', 'gold'],
        gold: [],
      };
    }
    return {
      bronze: ['bronze'],
      silver: ['silver'],
      gold: ['gold'],
    };
  }, [bronze, gold, silver]);

  return (
    <HStack mt={3} mb={2}>
      <Box position="relative" w="100%">
        {
          !!markerFragments.bronze.length && (
            <GoalMarker
              relativePos={bronze / gold}
              isMarkAchieved={done >= bronze}
              markerFragments={markerFragments.bronze}
              value={bronze}
            />
          )
        }
        {
          !!markerFragments.silver.length && (
            <GoalMarker
              relativePos={silver / gold}
              isMarkAchieved={done >= silver}
              markerFragments={markerFragments.silver}
              value={silver}
            />
          )
        }
        {
          !!markerFragments.gold.length && (
            <GoalMarker
              relativePos={gold / gold}
              isMarkAchieved={done >= gold}
              markerFragments={markerFragments.gold}
              value={gold}
            />
          )
        }
        <ProgressBar h={4} borderRadius={8} progress={done / gold} />
      </Box>
      <Text color={done >= gold ? colors.secondary.goLighter : undefined}>{`${done}/${gold}`}</Text>
    </HStack>
  );
}

interface GoalMarkerProps {
  isMarkAchieved?: boolean;
  relativePos: number;
  markerFragments: TrophyFragmentsType,
  value: number;
}

function GoalMarker({
  isMarkAchieved, markerFragments, relativePos, value,
}: GoalMarkerProps) {
  const { colors } = useDefaultStyles();
  return (
    <Flex
      flexFlow="column"
      h={12}
      justify="space-between"
      position="absolute"
      align="center"
      left={`${Math.round(relativePos * 100)}%`}
      transform={relativePos < 0.1 ? '' : 'translateX(-100%)'}
    >
      <Circle
        boxSize="24px"
        bgColor={isMarkAchieved ? colors.secondary.keep : colors.lightGray.goAlmostFullDarker}
        transform="translateY(-20%)"
        position="relative"
      >
        {(value !== undefined) && (
          <Text
            color={isMarkAchieved ? colors.white.keep : colors.darkGray.goLighter}
            fontWeight={isMarkAchieved ? 'bold' : ''}
            position="absolute"
          >
            {value}
          </Text>
        )}
      </Circle>
      <TrophyFragments
        fragments={markerFragments}
        hasAchieved={isMarkAchieved}
      />
    </Flex>
  );
}
