import { createStandaloneToast } from '@chakra-ui/react';
import { theme } from '../lib/theme';

type CustomToastProps = {
  title?: string;
  description?: string;
} | string;

const appTheme = theme({
  initialColorMode: 'system',
  // useSystemColorMode: false,
});

const customToast = createStandaloneToast({ theme: appTheme });

export const toast = {
  success(props: CustomToastProps) {
    if (typeof props === 'string') {
      customToast({
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
        description: props,
      });
      return;
    }
    customToast({
      status: 'success',
      duration: 5000,
      isClosable: true,
      position: 'top-right',
      title: props.title,
      description: props.description,
    });
  },
  error(props: CustomToastProps) {
    if (typeof props === 'string') {
      customToast({
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
        description: props,
      });
      return;
    }
    customToast({
      status: 'error',
      duration: 5000,
      isClosable: true,
      position: 'top-right',
      title: props.title,
      description: props.description,
    });
  },
  warning(props: CustomToastProps) {
    if (typeof props === 'string') {
      customToast({
        status: 'warning',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
        description: props,
      });
      return;
    }
    customToast({
      status: 'warning',
      duration: 5000,
      isClosable: true,
      position: 'top-right',
      title: props.title,
      description: props.description,
    });
  },
  info(props: CustomToastProps) {
    if (typeof props === 'string') {
      customToast({
        status: 'info',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
        description: props,
      });
      return;
    }
    customToast({
      status: 'info',
      duration: 5000,
      isClosable: true,
      position: 'top-right',
      title: props.title,
      description: props.description,
    });
  },
};
