import { FC, useCallback } from 'react';
import {
  Box,
  Center,
  Divider,
  Flex,
  HStack,
  Text,
  VStack,
} from '@chakra-ui/react';
import {
  PolarAngleAxis, PolarGrid, PolarRadiusAxis,
  Radar, RadarChart, ResponsiveContainer, Tooltip,
} from 'recharts';
import { AreasStatsType } from '../../../api/student-report';
import { useDefaultStyles, useRGBColor } from '../../../hooks/useDefaultStyles';
import { MockExamStudentPerformanceObjectType } from '../../../api/mock-exams-performance';

const ExamModalPerformanceChart: FC<{ studentPerformanceData?: MockExamStudentPerformanceObjectType[] }> = ({
  studentPerformanceData,
}) => {
  const adjustedData: AreasStatsType[] = studentPerformanceData?.map(it => {
    return {
      classification: it.area,
      percentage: it.average,
    };
  }) ?? [];

  return (
    <VStack width="100%" spacing="8px" alignItems="start">
      <div>
        <Text fontSize="32px">Seu desempenho</Text>
        <Text fontWeight={400} fontSize="14px">Confira seu desempenho em cada grande área e tema</Text>
      </div>
      <Divider />
      <Flex width="100%" justifyContent="center">
        <Center my={4} position="relative" w="100%" h={{ base: 200, md: 300 }}>
          {!adjustedData.length ? (
            <Box width="100%" height="100%" />
          ) : (
            <PerformanceRadarChart examPerformanceData={adjustedData} />
          )}
        </Center>
      </Flex>
    </VStack>
  );
};

export default ExamModalPerformanceChart;

const PerformanceRadarChart: FC<{ examPerformanceData?: AreasStatsType[] }> = ({
  examPerformanceData,
}) => {
  const { colors } = useDefaultStyles();
  const { toRGB } = useRGBColor();

  const getDataObject = useCallback((data?: AreasStatsType[]) => {
    const newData = {
      pediatrics: 0,
      clinic: 0,
      preventive: 0,
      surgery: 0,
      go: 0,
    };

    let noClassification = 0;

    if (data) {
      data.forEach(item => {
        switch (item.classification) {
          case 'Pediatria':
            newData.pediatrics = Math.round(item.percentage * 100);
            break;
          case 'Clínica médica':
          case 'Clínica Médica':
            newData.clinic = Math.round(item.percentage * 100);
            break;
          case 'Epidemiologia':
          case 'Saúde Coletiva':
            newData.preventive = Math.round(item.percentage * 100);
            break;
          case 'Cirurgia':
          case 'Cirurgia Geral':
            newData.surgery = Math.round(item.percentage * 100);
            break;
          case 'Ginecologia e obstetrícia':
          case 'Ginecologia e Obstetrícia':
            newData.go = Math.round(item.percentage * 100);
            break;
          case 'Sem classificação':
            noClassification = Math.round(item.percentage * 100);
            break;
          default:
            break;
        }
      });
    }

    return { ...newData, noClassification };
  }, []);

  const examData = getDataObject(examPerformanceData);

  if (!examData || !examPerformanceData) {
    return <></>;
  }

  const data = [
    { subject: 'Clínica médica', value: examData.clinic, fullMark: 100 },
    { subject: 'Pediatria', value: examData.pediatrics, fullMark: 100 },
    { subject: 'GO', value: examData.go, fullMark: 100 },
    { subject: 'Saúde Coletiva', value: examData.preventive, fullMark: 100 },
    { subject: 'Cirurgia', value: examData.surgery, fullMark: 100 },
  ];

  if (examData.noClassification > 0) {
    data.push({ subject: 'Sem classificação', value: examData.noClassification, fullMark: 100 });
  }
  return (
    <Center my={4} position="relative" w="100%" h={{ base: 190, md: 300 }}>
      <ResponsiveContainer
        width="100%"
        height="100%"
      >
        <RadarChart data={data}>
          <PolarGrid
            gridType="circle"
            strokeOpacity={0.4}
            stroke={toRGB(colors.secondary.keep)}
          />
          <Tooltip
            content={<CustomTooltip />}
          />
          <PolarAngleAxis
            axisLine={false}
            tickLine={false}
            dataKey="subject"
            fontSize={12}
            stroke={toRGB(colors.primary.goFullLighter)}
          />
          <PolarRadiusAxis
            angle={18}
            domain={[0, 100]}
            stroke={toRGB(colors.secondary.keep)}
            fontSize={10}
            tickCount={6}
            orientation="right"
          />
          <Radar
            name="Acertos"
            dataKey="value"
            stroke={toRGB(colors.secondary.keep)}
            fill={toRGB(colors.secondary.keep)}
            fillOpacity={0.5}
          />
        </RadarChart>
      </ResponsiveContainer>
    </Center>
  );
};

export type CustomTooltipType = {
  payload?: {
    value: number,
    stroke: string,
    name: string,
  }[],
  label?: string,
}

export const CustomTooltip: FC<CustomTooltipType> = ({ label, payload }: CustomTooltipType) => {
  const { cardBorder, colors } = useDefaultStyles();

  if (!payload || !payload[0]) {
    return null;
  }

  const { stroke: prevStroke, name: prevName, value: prevValue } = payload[0];

  return (
    <Box
      bgColor={colors.background}
      padding={2}
      align="center"
      {...cardBorder}
    >
      <Text mb={1} fontWeight="bold" fontSize="lg">{label}</Text>
      <VStack align="flex-start">
        <HStack>
          <Text color={prevStroke}>{`${prevName}:`}</Text>
          <Text>{`${prevValue}%`}</Text>
        </HStack>
      </VStack>
    </Box>
  );
};
