import { Flex, FlexProps, HStack, Square, Text } from '@chakra-ui/react';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { InstitutionDataType } from '../../../api/aristo-compass/exam-statistics';
import { useDefaultStyles } from '../../../hooks/useDefaultStyles';
import { DifficultyGradientBar } from './DifficultyGradientBar';
import { InstitutionsDifficultiesPanel } from './InstitutionsDifficultiesPanel';

interface ExamsDifficultiesListType extends FlexProps {
  showDropdown?: boolean,
  institutionData?: InstitutionDataType[],
  loading?: boolean,
}

export const ExamsDifficultiesList: FC<ExamsDifficultiesListType> = ({
  showDropdown = true,
  loading = false,
  institutionData,
  ...props
}) => {
  const { colors, cardBorder } = useDefaultStyles();
  const [isDifficultiesOpen, setIsDifficultiesOpen] = useState(false);
  const history = useHistory();

  useEffect(() => {
    const examType = localStorage.getItem('@Aristoclass:examVariant') || undefined;
    if (!examType) {
      history.push('/aristo-compass');
    }
  }, [history]);

  useEffect(() => {
    if (!loading) {
      setIsDifficultiesOpen(true);
    }
  }, [loading]);

  return (
    <Flex
      flexFlow="column"
      w="100%"
      align="center"
      {...props}
    >
      <HStack
        w="100%"
        as={showDropdown ? 'button' : undefined}
        border="solid"
        bgColor={colors.white.keep}
        p={4}
        pr={{ base: '15%', md: '8%' }}
        justifyContent="space-between"
        onClick={() => setIsDifficultiesOpen(prev => !prev)}
        position="relative"
        textAlign="left"
        {...cardBorder}
      >
        <Text fontSize={{ base: 12, md: 14 }} maxW="55%">
          Grau de dificuldade das provas selecionadas:
        </Text>
        <DifficultyGradientBar
          loading={loading}
          hidePercent={loading}
          difficulty={institutionData?.filter(x => x.institution === 'Geral')[0]?.difficulty ?? 0}
          w={{ base: '100px', md: '150px' }}
        />
        {
          showDropdown && (
            <Square position="absolute" right={4}>
              <FontAwesomeIcon
                icon={faChevronDown}
                className={isDifficultiesOpen ? 'rotate-icon' : 'icon-to-be-rotated'}
              />
            </Square>
          )
        }
      </HStack>
      <InstitutionsDifficultiesPanel
        isOpen={isDifficultiesOpen}
        institutionData={institutionData}
        loading={loading}
      />
    </Flex>
  );
};
