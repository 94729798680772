import { Center } from '@chakra-ui/react';
import { FC, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBrain } from '@fortawesome/free-solid-svg-icons';
import { useDevice } from '../../../../../hooks/useDevice';

export const HarrisonAvatar: FC = () => {
  const device = useDevice();

  const boxSize = useMemo(() => {
    return device === 'web' ? '48px' : '32px';
  }, [device]);

  const iconSize = useMemo(() => {
    return device === 'web' ? '24px' : '18px';
  }, [device]);

  return (
    <Center
      borderRadius="100%"
      mr={1}
      sx={{
        backgroundColor: '#9E3CFF',
        height: boxSize,
        width: boxSize,
        fontSize: iconSize,
        color: 'white',
      }}
    >
      <FontAwesomeIcon icon={faBrain} />
    </Center>
  );
};
