import { FC, useCallback } from 'react';
import {
  Box,
  Center,
  Circle,
  Divider,
  HStack,
  Text,
  useTheme,
  VStack,
} from '@chakra-ui/react';
import { Cell, Pie, PieChart, ResponsiveContainer } from 'recharts';
import { MockExamQuestionsByAreaObjectType } from '../../../api/mock-exams-performance';
import { useDefaultStyles } from '../../../hooks/useDefaultStyles';
import { useDevice } from '../../../hooks/useDevice';

interface ExamModalCompositionChartProps {
  data?: MockExamQuestionsByAreaObjectType[];
}

const ExamModalCompositionChart: FC<ExamModalCompositionChartProps> = ({
  data,
}: ExamModalCompositionChartProps) => {
  const { colors, colorPalette } = useDefaultStyles();
  const theme = useTheme();
  const device = useDevice();

  const COLORS = [
    theme.colors[colorPalette][600],
    theme.colors[colorPalette][500],
    theme.colors[colorPalette][400],
    theme.colors[colorPalette][300],
    theme.colors[colorPalette][200],
    theme.colors[colorPalette][100],
  ];

  const handlePieLabel = useCallback(props => {
    const RADIAN = Math.PI / 180;
    const { cx, cy, midAngle, outerRadius, fill, value } = props;
    const radius = outerRadius + 10;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline="central"
        fill={fill}
        fontSize="14px"
      >
        {value}
      </text>
    );
  }, []);

  return (
    <VStack
      width="100%"
      padding="16px"
      borderRadius="8px"
      spacing="8px"
      alignItems="start"
      backgroundColor={colors.alpha[100]}
    >
      <Text>Composição do Simulado</Text>
      <Divider />
      <Center my={4} position="relative" w="100%" h={{ base: 200, md: 300 }}>
        {!data ? (
          <Box width="100%" />
        ) : (
          <ResponsiveContainer width="100%" height="100%">
            <PieChart>
              {/* <Tooltip content={<CustomQuestionTooltip />} /> */}
              <Pie
                stroke="none"
                data={data}
                dataKey="questions"
                nameKey="area"
                innerRadius={device === 'web' ? 80 : 60}
                labelLine={false}
                label={handlePieLabel}
              >
                {data.map((entry, index) => (
                  <Cell key={entry.area} fill={COLORS[index]} />
                ))}
              </Pie>
            </PieChart>
          </ResponsiveContainer>
        )}
      </Center>
      {data && data.map((item, index) => (
        <HStack alignItems="center">
          <Circle size="10px" bgColor={COLORS[index]} />
          <Text fontSize="0.7rem">{item.area}</Text>
        </HStack>
      ))}
    </VStack>
  );
};

export default ExamModalCompositionChart;
