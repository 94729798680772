import {
  FC, useCallback, useContext, useState,
} from 'react';
import { toast } from 'react-toastify';
import { Flex, Text, VStack } from '@chakra-ui/react';
import useClassifications from '../../hooks/useClassifications';
import { PrivateContext } from '../../Private.context';
import { AgendaContext } from '../agenda/Agenda.context';
import { useExtraTheoreticalStudy } from '../../api/extra-activities/extra-theoretical-study';
import { SecondaryButton } from '../../lib/components/SecondaryButton';
import { PrimaryButton } from '../../lib/components/PrimaryButton';
import Select from '../../lib/components/Select';
import { ExtraActivityModalProps } from './CreateActivityModal';
import { useDefaultStyles } from '../../hooks/useDefaultStyles';

const TheoricContentModal: FC<ExtraActivityModalProps> = ({
  onClose, onAfterCreate,
}) => {
  /**
   * Contexts
   */
  const { profile } = useContext(PrivateContext);
  const treeId = profile.courses.length ? profile.courses[0].course : '';
  const { colors } = useDefaultStyles();

  const { refetchExtras } = useContext(AgendaContext);

  /**
   * States
   */
  const [
    areas, specialities, , , classification, handleClassification,
  ] = useClassifications(treeId, true);

  const [loading, setLoading] = useState(false);

  /**
   * Queries & Mutations
   */
  const { onCreateTheoreticalExtraActivity } = useExtraTheoreticalStudy();

  const handleCreate = useCallback(async () => {
    setLoading(true);
    try {
      await onCreateTheoreticalExtraActivity({
        classification,
      });
      await refetchExtras();
      onClose();
      if (onAfterCreate) {
        onAfterCreate();
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  }, [classification, onAfterCreate, onClose, onCreateTheoreticalExtraActivity, refetchExtras]);

  return (
    <>
      <Text color={colors.secondary.goALittleDarker} mb={2} fontWeight="bold">Conteúdo Teórico</Text>
      <Text mb={2}>Selecione os assuntos</Text>
      <VStack align="start" py={2} mb={4}>
        <Select
          value={classification[0] || ''}
          label="Área"
          onChange={handleClassification(0)}
          isDisabled={!areas.length}
          placeholder="Escolha"
          w="100%"
          options={areas.map(item => ({ value: item, label: item }))}
        />
        <Select
          value={classification[1] || ''}
          label="Especialidade"
          onChange={handleClassification(1)}
          isDisabled={!specialities.length}
          placeholder="Escolha"
          w="100%"
          options={specialities.map(item => ({ value: item, label: item }))}
        />
      </VStack>
      <Flex justify="end">
        <SecondaryButton onClick={onClose} mr={2} variant="ghost">
          Cancelar
        </SecondaryButton>
        <PrimaryButton
          isLoading={loading}
          onClick={handleCreate}
          isDisabled={classification.length < 2}
        >
          Adicionar
        </PrimaryButton>
      </Flex>
    </>
  );
};

export default TheoricContentModal;
