import { Box, BoxProps, Image, Text } from '@chakra-ui/react';
import { FC, useMemo } from 'react';

interface TrophyInterface extends BoxProps {
  type: 'bronze' | 'silver' | 'gold'
  count: number
  fontSize?: string;
  variant?: 'student-report' | 'student-goals'
}

export const Trophy: FC<TrophyInterface> = ({
  type,
  count,
  fontSize = '4xl',
  variant = 'student-report',
  ...props
}: TrophyInterface) => {
  const color = useMemo(() => {
    if (type === 'bronze') {
      return 'yellow.700';
    }
    if (type === 'silver') {
      return 'gray.500';
    }
    return 'orange.500';
  }, [type]);

  return (
    <Box
      w="100%"
      h="100%"
      position="relative"
      fontSize="2.2em"
      {...props}
    >
      <Text
        position="absolute"
        opacity={0.75}
        top={variant === 'student-report' ? { md: 0 } : { base: 1, sm: 3 }}
        left="50%"
        transform="translateX(-50%)"
        fontWeight="bold"
        color={color}
      >
        {String(count).padStart(2, '0')}
      </Text>
      <Image w="100%" h="100%" src={`/images/trophies/${type}.svg`} />
    </Box>
  );
};
