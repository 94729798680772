import { faCalendarAlt } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import React, {
  FC, useCallback, useContext, useMemo,
} from 'react';
import { useGetMockExamsHistory } from '../../../api/exams-history/mock-exams-history';
import { useExtraMockExams } from '../../../api/extra-activities/extra-mock-exams';
import { generateExamActivityLinkWithSearchParams } from '../../../hooks/generateExamLinkWithSearchParams';
import Flex from '../../../lib/components/Flex';
import Text from '../../../lib/components/Text';
import { AgendaContext } from '../../agenda/Agenda.context';
import useTableFilter from '../../../hooks/useTableFilter';
import TableFilterComponent from '../../theme-list/_components/TableFilterComponent';
import HistoryCardComponent from '../_components/HistoryCardComponent';
import AddExtraBtn from '../_components/AddExtraBtn.component';
import { TextWithTooltip } from '../_components/TextWithTooltip.component';

const MocksHistory: FC = () => {
  const getExamsHistoryQuery = useGetMockExamsHistory();
  const { onCreateMockExamExtraActivity } = useExtraMockExams();
  const { refetchExtras } = useContext(AgendaContext);

  /** Memos */
  const examsHistory = useMemo(() => {
    const { data } = getExamsHistoryQuery;
    if (!data) {
      return [];
    }
    return data.getActivityMockExamsHistory;
  }, [getExamsHistoryQuery]);

  const { dataFiltered: examsHistoryFiltered, setFilter } = useTableFilter(examsHistory);

  /** Callbacks */
  const handleCreateExtraMock = useCallback(async (mockExamTemplate: string) => {
    try {
      await onCreateMockExamExtraActivity({
        template: mockExamTemplate,
      });
      await refetchExtras();
    } catch (error) {
      console.log(error.message);
    }
  }, [onCreateMockExamExtraActivity, refetchExtras]);

  if (getExamsHistoryQuery.loading) {
    return (
      <Flex justify="center">
        <FontAwesomeIcon icon={faSpinner} spin size="4x" className="color-dark-gray mt-4" />
      </Flex>
    );
  }
  return examsHistory.length !== 0 ? (
    <>
      <TableFilterComponent
        setFilter={setFilter}
        options={[
          {
            value: 'startsAt',
            content: 'Data de liberação',
          },
          {
            value: 'finished',
            content: 'Status',
          },
          {
            value: 'grade',
            content: 'Desempenho',
          },
          {
            value: 'title',
            content: 'Nome da atividade',
          },
        ]}
      />
      {examsHistoryFiltered.map((mockExam, index) => (
        <HistoryCardComponent
          key={mockExam.mockExamTemplate}
          title={mockExam.title}
          iconProps={mockExam.finished ? (
            {
              label: 'Ir para a atividade',
              destiny: generateExamActivityLinkWithSearchParams(mockExam, 'simulado'),
              icon: <FontAwesomeIcon icon={faCalendarAlt} />,
            }
          ) : (
            {
              label: 'Adicionar como extra',
              icon: (
                <AddExtraBtn
                  active
                  handleCreateExtraFn={() => handleCreateExtraMock(mockExam.mockExamTemplate)}
                  confirmModalTitle="Deseja adicionar uma atividade extra desse simulado?"
                  confirmModalSubtitle="A atividade estará disponível na sua lista de atividades extras de hoje."
                />
              ),
            }
          )}
          rowData={[
            {
              label: 'Liberado em',
              key: 'startsAt',
              value: moment(mockExam.startsAt).format('DD[/]MM[/]YYYY'),
            },
            {
              label: 'Status',
              key: 'finished',
              value: mockExam.finished ? 'Realizado' : 'Não realizado',
            },
            {
              label: 'Desempenho',
              key: 'grade',
              value: (
                <TextWithTooltip extraTag="ActivityMockExamExtra" activity={mockExam} finished={mockExam.finished} />
              ),
            },
          ]}
        />
      ))}
    </>
  ) : (
    <Text tag="h4" center className="mt-6">Nenhum dado encontrado.</Text>
  );
};

export default MocksHistory;
