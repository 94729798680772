import React, {
  FC, useContext, useMemo,
} from 'react';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Box } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getActivityIndex } from '../../../hooks/getActivityIndex';
import { AgendaContext } from '../Agenda.context';
import AgendaFlashcards from '../types/AgendaFlashcards';
import { useGetExtraFlashcards } from '../../../api/extra-activities/extra-flashcards';
import Flex from '../../../lib/components/Flex';
import Text from '../../../lib/components/Text';
import { ExtraHeader } from './_components/ExtraHeader';

const ExtraFlashcardsContainer: FC = () => {
  const agendaCtx = useContext(AgendaContext);

  const id = getActivityIndex();

  const actualId = useMemo(() => {
    const qs = agendaCtx.extras.activityFlashcard;

    const index = qs?.findIndex(x => x._id === id) || 0;

    if (qs && qs.length > 0 && index !== -1) {
      return qs[index]._id;
    }

    if (qs && qs.length > 0) {
      return qs[0]._id;
    }

    return '';
  }, [agendaCtx.extras.activityFlashcard, id]);

  if (agendaCtx.extrasLoading) {
    return (
      <Flex align="center" justify="center" style={{ minHeight: 400 }}>
        <FontAwesomeIcon icon={faSpinner} spin size="6x" className="color-dark-gray" />
      </Flex>
    );
  }

  return (
    <Box maxW={900} m="0 auto">
      <ExtraHeader
        title="Flashcards [Extra]"
      />
      <ExtraFlashcards id={actualId} />
    </Box>
  );
};

interface ExtraFlashcardsProps {
  id: string;
}

const ExtraFlashcards: FC<ExtraFlashcardsProps> = ({ id }) => {
  const query = useGetExtraFlashcards({
    id,
  });

  if (query.loading) {
    return (
      <Flex align="center" justify="center" style={{ minHeight: 400 }}>
        <FontAwesomeIcon icon={faSpinner} spin size="5x" className="color-dark-gray" />
      </Flex>
    );
  }

  if (
    !query.error
    && query.data
    && query.data.getFlashcardActivityDetails.flashcards.length > 0
  ) {
    return (
      <AgendaFlashcards
        activityId={id}
        data={query.data.getFlashcardActivityDetails.flashcards}
        finished={query.data.getFlashcardActivityDetails.finished}
      />
    );
  }

  return (
    <Text>Nenhuma atividade disponível para hoje!</Text>
  );
};

export default ExtraFlashcardsContainer;
