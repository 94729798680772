import { gql, useMutation, useQuery } from '@apollo/client';
import { useCallback } from 'react';
import notificationClient from '../../client/notifications-apollo';

export type Notification = {
  _id: string;
  authorPicture?: string;
  body: {
    authorName?: string;
    message: string;
  }
  link?: string;
  thumbnail?: string;
  seen: boolean;
  createdAt: string;
};

interface GetUserNotificationsInput {
  userFirebaseId: string;
  skip?: number;
  limit?: number;
}

interface GetUserNotificationsOutput {
  findUserNotifications: Notification[];
}

const GET_USER_NOTIFICATIONS = gql`
  query FindUserNotifications(
    $userFirebaseId: String!,
    $limit: Float,
    $skip: Float,
  ) {
    findUserNotifications(
      userFirebaseId: $userFirebaseId,
      limit: $limit,
      skip: $skip,
    ) {
      _id
      authorPicture
      body {
        message
        authorName
      }
      thumbnail
      seen
      link
      createdAt
    }
  }
`;

interface FindNewNotificationsAmoutInput {
  userFirebaseId: string;
}

interface FindNewNotificationsAmoutOutput {
  findUserNewNotificationsAmount: number;
}

const FIND_NEW_NOTIFICATIONS_AMOUNT = gql`
  query FindUserNewNotificationsAmount($userFirebaseId: String!) {
    findUserNewNotificationsAmount(userFirebaseId: $userFirebaseId)
  }
`;

interface UpdateSeenInput {
  _id: string;
}

interface UpdateSeenOutput {
  updateSeen: boolean;
}

const UPDATE_SEEN = gql`
  mutation UpdateSeen($_id: ObjectId!) {
    updateSeen(_id: $_id)
  }
`;
interface UpdateAllSeenInput {
  userFirebaseId: string;
}

interface UpdateAllSeenOutput {
  updateAllSeen: number;
}

const UPDATE_ALL_SEEN = gql`
  mutation UpdateSeen($userFirebaseId: String!) {
    updateAllSeen(userFirebaseId: $userFirebaseId)
  }
`;

interface UpdateLastSeenInput {
  firebaseId: string;
}

interface UpdateLastSeenOutput {
  updateLastSeen: boolean;
}

const UPDATE_LAST_SEEN = gql`
  mutation UpdateLastSeen($firebaseId: String!) {
    updateLastSeen(firebaseId: $firebaseId)
  }
`;

export function useNotifications(userFirebaseId: string, skip: number, limit: number) {
  const getUserNewNotificationsAmount = useQuery<
    FindNewNotificationsAmoutOutput,
    FindNewNotificationsAmoutInput>(FIND_NEW_NOTIFICATIONS_AMOUNT, {
      variables: {
        userFirebaseId,
      },
      skip: !userFirebaseId,
      client: notificationClient,
    });

  const getUserNotifications = useQuery<GetUserNotificationsOutput, GetUserNotificationsInput>(GET_USER_NOTIFICATIONS, {
    variables: {
      userFirebaseId,
      limit,
      skip,
    },
    skip: !userFirebaseId,
    client: notificationClient,
    notifyOnNetworkStatusChange: true,
  });

  const [updateAllSeenMutation] = useMutation<UpdateAllSeenOutput, UpdateAllSeenInput>(UPDATE_ALL_SEEN, {
    client: notificationClient,
  });

  const [updateSeenMutation] = useMutation<UpdateSeenOutput, UpdateSeenInput>(UPDATE_SEEN, {
    client: notificationClient,
  });

  const [updateLastSeenMutation] = useMutation<UpdateLastSeenOutput, UpdateLastSeenInput>(UPDATE_LAST_SEEN, {
    client: notificationClient,
  });

  const handleUpdateSeen = useCallback(async (_id: string) => {
    try {
      await updateSeenMutation({
        variables: {
          _id,
        },
      });
    } catch (error) {
      console.error(error);
    }
  }, [updateSeenMutation]);

  const handleUpdateAllSeen = useCallback(async () => {
    try {
      return await updateAllSeenMutation({
        variables: {
          userFirebaseId,
        },
      });
    } catch (error) {
      console.error(error);
    }
    return undefined;
  }, [updateAllSeenMutation, userFirebaseId]);

  const handleUpdateLastSeen = useCallback(async () => {
    if (!userFirebaseId) {
      return;
    }
    try {
      await updateLastSeenMutation({
        variables: {
          firebaseId: userFirebaseId,
        },
      });
    } catch (error) {
      console.error(error);
    }
  }, [userFirebaseId, updateLastSeenMutation]);

  return {
    handleUpdateSeen,
    handleUpdateLastSeen,
    handleUpdateAllSeen,
    getUserNotifications,
    getUserNewNotificationsAmount,
  };
}
