/* eslint-disable import/prefer-default-export */

/**
 * Cria um Array com todos números inteiros entre o intervalo de `start` e `end`.
 *
 * @param {number} start
 * @param {number} end
 */
export function createIntegerInterval(start: number, end: number): number[] {
  return Array.from({ length: end - start }, (_, index) => start + index);
}

export function prependZeros(n: number | string, len: number): string {
  const str = n.toString();
  const intPart = parseInt(str, 10).toString();
  if (intPart.length >= len) {
    return n.toString();
  }
  return Array.from({ length: len - intPart.length }, () => 0).join('') + str;
}
