import { Flex, Text } from '@chakra-ui/react';
import React, { FC, ReactNode } from 'react';
import { useDefaultStyles } from '../../../hooks/useDefaultStyles';
import { TextComponentProps } from '../../../lib/components/Text';

interface boxDataProps extends TextComponentProps {
  key: string;
  value: (string | ReactNode);
  cellTooltip?: string;
  flexSpan?: number;
}

interface TableBoxComponentProps {
  lineTooltip?: string;
  boxData: boxDataProps[];
  finished?: boolean;
}

const TableBoxComponent: FC<TableBoxComponentProps> = ({
  lineTooltip, boxData, finished,
}) => {
  const { colors } = useDefaultStyles();
  return (
    <Flex
      align="center"
      title={lineTooltip}
      border="solid 2px"
      borderColor={finished ? colors.green.goDarker : colors.darkGray.keep}
      borderRadius={12}
      padding=".5rem"
      marginBottom="1rem"
      height="5.4rem"
    >
      {boxData.map(({
        key, value, flexSpan, align, cellTooltip, width, ...rest
      }) => (
        <Flex
          key={key}
          width={width}
          flex={flexSpan}
          justify={key === 'leaf' ? 'left' : 'center'}
          className={`px-1 text-align-${align || 'center'}`}
          title={cellTooltip}
        >
          {typeof value === 'string' ? (
            <Text>
              {value}
            </Text>
          ) : (
            <>
              {value}
            </>
          )}
        </Flex>
      ))}
    </Flex>
  );
};

export default TableBoxComponent;
