import { FC } from 'react';
import { Flex, Text } from '@chakra-ui/react';

interface ExamModalBannerProps {
  imageUrl: string;
  link: string;
  title?: string;
  subtitle?: string;
}

const ExamModalBanner: FC<ExamModalBannerProps> = ({
  imageUrl,
  link,
  title,
  subtitle,
}) => {
  const handleNavigate = () => {
    window.open(link, '_blank');
  };

  return (
    <Flex
      backgroundImage={`url(${imageUrl})`}
      backgroundSize="cover"
      backgroundPosition="center"
      direction="column"
      width="100%"
      alignItems="center"
      padding="33px 24px"
      borderRadius="16px"
      textAlign="center"
      cursor="pointer"
      role="button"
      onClick={handleNavigate}
    >
      {title && <Text fontWeight={700} fontSize="24px" color="white">{title}</Text>}
      {subtitle && <Text fontSize="14px" color="white">{subtitle}</Text>}
    </Flex>
  );
};

export default ExamModalBanner;
