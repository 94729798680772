import { Button, Center, Stack, Text } from '@chakra-ui/react';
import { FC } from 'react';
import { useDevice } from '../../../../../hooks/useDevice';

interface HarrisonFeedbackProps {
  title: string,
  description: string,
  buttonText: string,
  action: () => Promise<void>,
  bgColor: string,
  textColor?: string,
  buttonColor: string,
  buttonTextColor?: string,
}

export const HarrisonFeedback: FC<HarrisonFeedbackProps> = ({
  title,
  description,
  buttonText,
  action,
  bgColor,
  textColor,
  buttonColor,
  buttonTextColor,
}) => {
  const device = useDevice();

  return (
    <Center px="8px" py="10px" borderRadius="10px" backgroundColor={bgColor}>
      <Stack
        width="100%"
        direction={device === 'mobile' ? 'column' : 'row'}
        align={device === 'mobile' ? 'left' : 'center'}
      >
        <Stack align="left" color={textColor}>
          <Text fontSize="14px" fontWeight="bold">{title}</Text>
          <Text fontSize="12px">{description}</Text>
        </Stack>
        <Button
          minW="130px"
          fontSize="14px"
          onClick={action}
          backgroundColor={buttonColor}
          rounded="full"
          color={buttonTextColor}
        >
          {buttonText}
        </Button>
      </Stack>
    </Center>
  );
};
