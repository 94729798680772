import { Center, Circle, HStack, Text } from '@chakra-ui/react';
import { FC, ReactNode, useCallback, useMemo, useState } from 'react';
import { useDefaultStyles } from '../../hooks/useDefaultStyles';
import { Loading } from './Loading';

interface ChoiceRadioProps {
  choiceIndex: number;
  onClick: (index: number) => Promise<void> | void;
  checked?: boolean;
  disabled?: boolean;
  eliminated?: boolean;
  children?: ReactNode;
}

export const ChoiceRadio: FC<ChoiceRadioProps> = ({
  children,
  choiceIndex,
  onClick,
  disabled = false,
  checked = false,
  eliminated = false,
}: ChoiceRadioProps) => {
  const [loading, setLoading] = useState(false);
  const { colors } = useDefaultStyles();

  const handleClick = useCallback(async () => {
    if (!disabled && !checked) {
      setLoading(true);
      await onClick(choiceIndex);
      setLoading(false);
    }
  }, [checked, choiceIndex, disabled, onClick]);

  const colorScheme = useMemo(() => {
    if (disabled) {
      if (checked) {
        return {
          color: colors.white.keep,
          bgColor: colors.darkGray.keep,
          cursor: 'not-allowed',
          borderColor: colors.darkGray.keep,
        };
      }
      return {
        color: colors.darkGray.keep,
        bgColor: 'none',
        cursor: 'not-allowed',
        borderColor: colors.darkGray.keep,
      };
    }
    if (checked) {
      return {
        color: colors.white.keep,
        bgColor: colors.secondary.keep,
        cursor: 'default',
        borderColor: colors.secondary.keep,
      };
    }
    return {
      color: colors.secondary.keep,
      bgColor: 'none',
      cursor: 'pointer',
      borderColor: colors.secondary.keep,
    };
  }, [checked, colors.darkGray.keep, colors.secondary.keep, colors.white.keep, disabled]);

  return (
    <HStack
      flex={1}
      as="button"
      cursor={checked ? 'auto' : ''}
      onClick={handleClick}
    >
      <Circle
        padding={0}
        borderWidth={2}
        borderStyle="solid"
        size={6}
        borderColor={colorScheme.borderColor}
        cursor={colorScheme.cursor}
        color={colorScheme.color}
        bgColor={colorScheme.bgColor}
      >
        {!loading ? (
          <Text>
            {String.fromCharCode(65 + choiceIndex)}
          </Text>
        ) : (
          <Center fontSize="sm">
            <Loading />
          </Center>
        )}
      </Circle>
      <Text
        textDecoration={eliminated ? 'line-through' : 'auto'}
        color={eliminated ? colors.darkGray.keep : ''}
        textAlign="left"
      >
        {children}
      </Text>
    </HStack>
  );
};
