import { gql, useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { toast } from 'react-toastify';
import errorHandler from '../error/errorHandler';
import { useExtraActivities, FinishExtraActivityInput } from './extra-activities';

interface CreateSmartReviewExtraActivityOutput {
  createSmartReviewExtraActivity: {
    _id: string;
  };
}

const CREATE_SMART_REVIEW_EXTRA_ACTIVITY = gql`
  mutation CreateSmartReviewExtraActivity {
    createSmartReviewExtraActivity {
      _id
    }
  }
`;

interface UseSmartReviewExtraActivityInterface {
  onFinishExtraSmartReviewActivity: (props: FinishExtraActivityInput) => Promise<void>;
  onCreateSmartReviewExtraActivity: () => Promise<void>;
}

export function useExtraSmartReview(): UseSmartReviewExtraActivityInterface {
  const { onFinishExtraActivity } = useExtraActivities();

  const [createActivity] = useMutation<
    CreateSmartReviewExtraActivityOutput
  >(CREATE_SMART_REVIEW_EXTRA_ACTIVITY, {
    onError: errorHandler,
    onCompleted: data => {
      if (data !== null) {
        toast.success('Atividade de revisão inteligente criada com sucesso!');
      }
    },
  });

  const handleCreate = useCallback(async () => {
    await createActivity();
  }, [createActivity]);

  return {
    onFinishExtraSmartReviewActivity: onFinishExtraActivity,
    onCreateSmartReviewExtraActivity: handleCreate,
  };
}
