import { Box, BoxProps, Flex } from '@chakra-ui/react';
import { FC, ReactNode } from 'react';

interface InlineTextInterface extends BoxProps {
  children?: ReactNode;
  leftIcon?: ReactNode;
  rightIcon?: ReactNode;
  space?: 'right' | 'left' | 'both' | 'none';
}

export const InlineText: FC<InlineTextInterface> = ({
  children, leftIcon, rightIcon, space = 'right', ...props
}: InlineTextInterface) => {
  return (
    <Flex
      display="inline"
      ml={(space === 'left' || space === 'both') ? 1 : undefined}
      mr={(space === 'right' || space === 'both') ? 1 : undefined}
      {...props}
    >
      {
        leftIcon && (
          <Box mr={1} display="inline">
            {leftIcon}
          </Box>
        )
      }
      <Box display="inline">
        {children}
      </Box>
      {
        rightIcon && (
          <Box ml={1} display="inline">
            {rightIcon}
          </Box>
        )
      }
    </Flex>
  );
};
