import { Text } from '@chakra-ui/react';
import { useMemo } from 'react';
import { useDefaultStyles } from '../../../hooks/useDefaultStyles';
import { Loading } from '../../../lib/components/Loading';

export type ComparisonTextTemplateType = 'percent verbose' | 'percent succint'
  | 'absolute verbose' | 'absolute succint';

type ComparisonTextType = {
  previousValue: number,
  currentValue: number,
  loading?: boolean,
  text?: string,
  hide?: boolean,
  pluralSubject?: string,
  singularSubject?: string,
  template?: ComparisonTextTemplateType,
}

export function ComparisonText({
  previousValue,
  currentValue,
  loading = false,
  text = 'em relação ao mês anterior.',
  hide = false,
  template = 'percent verbose',
  pluralSubject = ' ',
  singularSubject = ' ',
}: ComparisonTextType) {
  const { colors } = useDefaultStyles();
  const delta = useMemo(() => {
    return (currentValue - previousValue);
  }, [currentValue, previousValue]);

  const roundedDelta = Math.round(delta) || 0;

  const subject = useMemo(() => {
    if (singularSubject !== ' ' && pluralSubject !== ' ') {
      return (roundedDelta === 1 || roundedDelta === 0) ? singularSubject : pluralSubject;
    } return pluralSubject;
  }, [pluralSubject, roundedDelta, singularSubject]);

  const formatedText = useMemo(() => {
    if (roundedDelta === 0) {
      return `Manteve${text ? ` ${text}` : ''}`;
    }
    if (template === 'percent succint') {
      return `${roundedDelta >= 0 ? '+' : ''}${roundedDelta}%`;
    }
    if (template === 'percent verbose') {
      return `${Math.abs(roundedDelta)}% ${subject}
      ${roundedDelta >= 0 ? 'a mais' : 'a menos'}${text ? ` ${text}` : ''}`;
    }
    if (template === 'absolute verbose') {
      return `${Math.abs(roundedDelta)}
      ${subject ? ` ${subject} ` : ' '}
      ${roundedDelta >= 0 ? 'a mais' : 'a menos'}
      ${text ? ` ${text}` : ''}`;
    }
    if (template === 'absolute succint') {
      return `${roundedDelta >= 0 ? '+' : ''}${Math.round(roundedDelta)}`;
    }
    return '';
  }, [roundedDelta, subject, template, text]);

  if (hide) {
    return <></>;
  }

  return (
    loading
      ? (
        <Loading />
      )
      : (
        <>
          <Text
            flex={1}
            fontSize="smaller"
            color={roundedDelta >= 0 ? colors.green.keep : colors.red.keep}
          >
            {formatedText}
          </Text>
        </>
      )
  );
}
