import { gql, QueryResult, useQuery } from '@apollo/client';
import { Comment } from './agenda';

export interface Text {
  _id: string;
  url: string;
  description: string;
  comment: Comment;
  title?: string;
  subtitle?: string;
}
interface TextManyInput {
  ids: string[];
}
interface TextManyOutput {
  textMany: Text[];
}
const TEXT_MANY = gql`
  query TextMany (
    $ids: [ObjectId!]!
  ) {
    textMany (
      ids: $ids
    ) {
      _id
      url
      description
      title
      subtitle
      comment {
        author
        profile
        title
        body
        createdAt
        updatedAt
      }
    }
  }
`;

export function useTextMany({ ids }: TextManyInput): QueryResult<TextManyOutput, TextManyInput> {
  return useQuery<TextManyOutput, TextManyInput>(TEXT_MANY, {
    variables: {
      ids,
    },
    fetchPolicy: 'network-only',
    skip: ids.length === 0,
  });
}
