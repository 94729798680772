const errorDictionary: Record<string, string> = {
  TRIAL_TIME_OVER: `O tempo de teste grátis acabou \ud83d\ude14,
  mas você ainda pode fazer atividades extras de questão e de provas na íntegra!\ud83d\ude00`,
  NO_ELEGIBLE_LESSON_TO_REVIEW: 'Não existem aulas elegíveis para revisão.',
  NO_AGENDA_CREATION_ON_PAST_DAYS: 'Ops! Nenhuma agenda nesse dia ⚠️',
  FAILED_TO_FINISH_STUDY: 'Erro ao concluir estudo teórico.',
  FAILED_TO_FINISH_REVIEW: 'Erro ao concluir revisão teórica.',
  QUESTIONS_NOT_FOUND: 'Questões não encontradas ou erro de conexão.',
  DEFAULT: '🤯 Um erro inesperado aconteceu!',
  MANY_COMMANDS_TO_SAME_QUESTION: 'Aguarde! Você está clicando muito nesse botão.',
  TOO_MANY_REQUESTS: 'Aguarde! Use o botão "marcar dúvida" com moderação.',
  SAME_COMMAND_AS_PREVIOUS: 'Não foi possível adicionar/remover marcação de dúvida. Tente novamente!',
  ERROR_CREATING_AGENDA: 'Não foi possível encontrar nem criar agenda para este dia!',
  COURSE_FINISHED: 'Que pena! Este curso se encerrou!',
};

export default errorDictionary;
