import { Flex, useTheme, VStack, Text } from '@chakra-ui/react';
import { faBookmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { PrimaryButton } from '../../../lib/components/PrimaryButton';

const FavoritesEmpty: FC = () => {
  const history = useHistory();
  const theme = useTheme();
  return (
    <Flex
      textAlign="center"
      alignItems="center"
      justifyContent="center"
      flexFlow="column"
      height="30rem"
    >
      <VStack
        align="center"
        justify="center"
        gridGap=".5rem"
        width="15rem"
        mb="2rem"
      >
        <FontAwesomeIcon
          style={{
            fontSize: '4rem',
            color: theme.colors.secondary[400],
          }}
          icon={faBookmark}
        />
        <Text
          fontSize="1.2rem"
          fontWeight="bold"
        >
          Nenhum favorito ainda!
        </Text>
        <Text>
          Todos os seus conteúdos favoritados irão aparecer aqui.
        </Text>
      </VStack>
      <PrimaryButton
        onClick={() => history.push('/agenda')}
      >
        Voltar para a agenda
      </PrimaryButton>
    </Flex>

  );
};

export default FavoritesEmpty;
