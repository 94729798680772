import { gql, QueryResult, useQuery } from '@apollo/client';

export interface PerformanceChartPoint {
  weekSpan: string;
  correct: number;
  wrong: number;
  globalWrong: number;
  globalCorrect: number;
}

interface GetPerformanceChartInput {
  startsAt: Date;
  tag: 'easy_questions'
  | 'medium_questions'
  | 'hard_questions'
  | 'exam_and_mocks'
  | 'all';
}

interface GetPerformanceChartOutput {
  getPerformanceChart: PerformanceChartPoint[];
}

const GET_PERFORMANCE_CHART = gql`
  query GetPerformanceChart (
    $startsAt: DateTime!
    $tag: String!
  ) {
    getPerformanceChart (
      startsAt: $startsAt
      tag: $tag
    ) {
      weekSpan
      correct
      wrong
      globalWrong
      globalCorrect
    }
  }
`;

export function useGetPerformanceChart({
  startsAt,
  tag,
}: GetPerformanceChartInput): QueryResult<GetPerformanceChartOutput, GetPerformanceChartInput> {
  return useQuery<GetPerformanceChartOutput, GetPerformanceChartInput>(GET_PERFORMANCE_CHART, {
    variables: {
      startsAt,
      tag,
    },
    fetchPolicy: 'cache-and-network',
  });
}
