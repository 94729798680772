import React, { FC, useMemo } from 'react';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMindmaps } from '../../../../api/agenda/mindmaps';
import Flex from '../../../../lib/components/Flex';
import AgendaMindMaps from '../../types/AgendaMindMaps';
import MindMapEmpty from '../../types/MindMapEmpty';

interface ExtraTheoreticalMindmapProps {
  ids?: string[];
  onFinish: () => Promise<void>;
  loading: boolean;
  finished: boolean;
}

const ExtraTheoreticalMindmap: FC<ExtraTheoreticalMindmapProps> = ({
  ids, onFinish, loading, finished,
}) => {
  const query = useMindmaps({
    ids: ids || [],
  });

  const dataQuery = useMemo(() => {
    const { data } = query;
    if (data) {
      return data.mindmapMany;
    }

    return [];
  }, [query]);

  if (query.loading) {
    return (
      <Flex align="center" justify="center" style={{ minHeight: 400 }}>
        <FontAwesomeIcon icon={faSpinner} spin size="5x" className="color-dark-gray" />
      </Flex>
    );
  }

  if (dataQuery && dataQuery.length > 0) {
    return (
      <AgendaMindMaps
        data={dataQuery}
      />
    );
  }

  return <MindMapEmpty />;
};

export default ExtraTheoreticalMindmap;
