import { FC } from 'react';
import { Avatar, Box, BoxProps, HStack, Text } from '@chakra-ui/react';

interface RankLineProps extends BoxProps {
  rank: number;
  name: string;
  team: string;
  pontuation: number;
}

const RankLine: FC<RankLineProps> = ({
  rank, name, team, pontuation, ...rest
}) => {
  return (
    <Box
      textAlign="center"
      boxShadow="0px 2px 4px 2px rgba(0, 0, 0, 0.25)"
      borderRadius={4}
      py={1}
      px={1}
      {...rest}
    >
      <HStack align="center">
        <Box flex={1}>
          {pontuation !== -1 ? (
            <Text bold>
              {rank}
              º
            </Text>
          ) : (
            <Text>
              -
            </Text>
          )}
        </Box>
        <Box flex={1}>
          <Avatar src="" name={name} />
        </Box>
        <Box textAlign="left" flex={6}>
          <Text>{name}</Text>
        </Box>
        <Box flex={2}>
          {pontuation !== -1 ? (
            <Text>{pontuation.toFixed(0)}</Text>
          ) : (
            <Text fontSize="xs">Não realizado</Text>
          )}
        </Box>
      </HStack>
    </Box>
  );
};

export default RankLine;
