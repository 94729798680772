import { Button, Flex, Select } from '@chakra-ui/react';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { InstitutionTarget } from '../../api/profile';
import { useDefaultStyles, useRGBColor } from '../../hooks/useDefaultStyles';

type DiagnosisPage1Props = {
  onSelectUf?: (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
  onSelectInstitution: (value: InstitutionTarget) => void;
  ufs: string[];
  uf: string;
  institutions: string[];
  loadingInstitutions: boolean;
  bgColor?: string;
  borderColor?: string;
  placeholder?: string;
  variant?: 'auto-select' | 'add-button';
};

export const InstitutionSelector: FC<DiagnosisPage1Props> = ({
  onSelectUf,
  onSelectInstitution,
  uf,
  ufs,
  institutions,
  loadingInstitutions,
  bgColor,
  borderColor,
  placeholder = 'Instituição',
  variant = 'auto-select',
}: DiagnosisPage1Props) => {
  const { colors } = useDefaultStyles();
  const { toRGB } = useRGBColor();

  const onSelect = useCallback(
    e => {
      const _institution = {
        uf,
        institution: e.target.value,
      };
      if (variant === 'auto-select') {
        onSelectInstitution(_institution);
      }
      if (variant === 'add-button') {
        setSelectedInstitution(_institution);
      }
    },
    [onSelectInstitution, uf, variant],
  );

  const [selectedInstitution, setSelectedInstitution] = useState({
    uf: '',
    institution: '',
  });
  const blueBg = toRGB(colors.intermediary.keep);
  const isCompass = window.location.pathname.includes('aristo-compass');

  useEffect(() => {
    setSelectedInstitution(prev => ({ ...prev, uf }));
  }, [uf]);

  return (
    <>
      <Flex gridGap="1rem" align="center" justify="center" width="100%" flexFlow="column" flex-wrap="wrap">
        <Flex flexFlow={{ base: 'column', md: 'row' }} w="100%" gridGap="1rem">
          <Select
            onChange={onSelectUf}
            value={selectedInstitution.uf}
            width={{ base: '100%', md: '270px' }}
            border="solid 2px"
            borderColor={borderColor}
          >
            <option style={{ backgroundColor: bgColor || blueBg }} value="">
              UF
            </option>
            {ufs.sort().map(UF => (
              <option key={UF} style={{ backgroundColor: bgColor || blueBg }} value={UF}>
                {UF}
              </option>
            ))}
          </Select>
          <Select
            width="100%"
            onChange={onSelect}
            border="2px solid"
            value={selectedInstitution.institution}
            borderColor={borderColor}
          >
            <option style={{ backgroundColor: bgColor || blueBg }} value="">
              {loadingInstitutions ? 'Carregando...' : placeholder}
            </option>
            {institutions.map(institution => (
              <option style={{ backgroundColor: bgColor || blueBg }} key={institution} value={institution}>
                {institution.length > 70 ? institution.slice(0, 70) : institution}
              </option>
            ))}
          </Select>
        </Flex>
      </Flex>
      {variant === 'add-button' && (
        <Flex w="100%" align="center" justify="flex-end" my={4}>
          <Button
            disabled={!selectedInstitution.institution || !selectedInstitution.uf}
            variant="ghost"
            onClick={() => {
              onSelectInstitution(selectedInstitution);
              setSelectedInstitution({ institution: '', uf: '' });
            }}
            color={isCompass ? colors.secondary.keep : colors.white.keep}
            _hover={{ bgColor: isCompass ? undefined : colors.intermediary.keep }}
            leftIcon={<FontAwesomeIcon icon={faPlusCircle} size="2x" />}
          >
            Adicionar instituição
          </Button>
        </Flex>
      )}
    </>
  );
};
