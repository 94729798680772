import { gql, QueryResult, useQuery } from '@apollo/client';

export interface TreeLeaf {
  name: string;
  children?: TreeLeaf[];
}

interface ClassificationTreesInput {
  treeId: string;
}

interface ClassificationTreesOutput {
  classificationTree: {
    tagTreeName: string;
    tree: TreeLeaf[];
  };
}

const CLASSIFICATION_TREES = gql`
  query ClassificationTrees ($treeId: ObjectId!) {
    classificationTree: getClassificationTree(treeId: $treeId){
      tagTreeName
      tree {
        name
        children {
          name
          children {
            name
            children {
              name
            }
          }
        }
      }
    }
  }
`;

interface UseClassificationTreesInterface extends ClassificationTreesInput {
  skip: boolean;
}

export function useClassificationTrees({
  treeId, skip,
}: UseClassificationTreesInterface): QueryResult<ClassificationTreesOutput, ClassificationTreesInput> {
  return useQuery<ClassificationTreesOutput, ClassificationTreesInput>(CLASSIFICATION_TREES, {
    variables: {
      treeId,
    },
    fetchPolicy: 'cache-first',
    skip,
  });
}

interface GetClassificationTreeAvailablePhasesInput {
  courseId: string;
}

interface GetClassificationTreeAvailablePhasesOutput {
  classificationTree: {
    tree: TreeLeaf[];
  };
}

const GET_CLASSIFICATION_TREE_AVAILABLE_PHASES = gql`
  query GetClassificationTreeAvailablePhases (
    $courseId: ObjectId!
  ) {
    classificationTree: getClassificationTreeAvailablePhases (
      courseId: $courseId
    ) {
      tree {
        name
        children {
          name
          children {
            name
            children {
              name
            }
          }
        }
      }
    }
  }
`;

interface UseGetClassificationTreeAvailablePhasesInterface extends GetClassificationTreeAvailablePhasesInput {
  skip: boolean;
}

export function useGetClassificationTreeAvailablePhases({
  courseId, skip,
}: UseGetClassificationTreeAvailablePhasesInterface): QueryResult<
  GetClassificationTreeAvailablePhasesOutput, GetClassificationTreeAvailablePhasesInput
> {
  return useQuery<
    GetClassificationTreeAvailablePhasesOutput, GetClassificationTreeAvailablePhasesInput
  >(GET_CLASSIFICATION_TREE_AVAILABLE_PHASES, {
    variables: {
      courseId,
    },
    skip,
  });
}
