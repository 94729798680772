import { Flex, Image, Text } from '@chakra-ui/react';
import { FC } from 'react';
import { useDefaultStyles } from '../../../hooks/useDefaultStyles';

export const testThePlatformFormLink = 'https://pages.aristo.com.br/aterrissagem/aristo-compass/';

export const AristoCompassHeader: FC = () => {
  const { colors } = useDefaultStyles();
  const showCTABar = window.location.href.includes('/diagnostico/prova');
  return (
    <Flex w="100%" flexFlow="column">
      <Flex
        w="100%"
        bgColor={colors.primary.keep}
        px="40px"
        alignItems="center"
        justifyContent="space-between"
        h={{ base: '6rem', md: '9rem' }}
      >
        <Image
          width={{ base: '7rem', md: '12rem' }}
          src="/images/aristo-logo.png"
          alt="logo-aristo"
        />
        <Text
          as="button"
          color={colors.lighter.keep}
          _hover={{ textDecoration: 'underline' }}
          onClick={() => window.open('https://aristo.com.br/', '_blank')}
        >
          Quem somos
        </Text>
      </Flex>
      {
        showCTABar && (
          <Flex
            as="button"
            bgColor="aristo.800"
            color={colors.lighterSecondary.keep}
            onClick={() => window.open(testThePlatformFormLink, '_blank')}
            w="100%"
            align="center"
            justify="center"
            fontSize={{ base: 13, md: 16 }}
          >
            Quero fazer minha matrícula GRATUITA!
          </Flex>
        )
      }
    </Flex>
  );
};
