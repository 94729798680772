import { FC, useCallback, useMemo, useState } from 'react';
import { Modal, ModalBody, ModalContent, ModalOverlay, useColorMode } from '@chakra-ui/react';
import { useOptimizerApi } from '../../../../hooks/useOptimizerApi';
import { ModalStep1 } from './ModalStep1';
import { ModalStep2 } from './ModalStep2';

const npsUserGroup = ['DETRACTOR', 'NEUTRAL', 'PROSECUTOR'] as const;
export type NpsUserGroup = (typeof npsUserGroup)[number];

export const PrimumNpsModalShow: FC<{ open: boolean, setOpen: () => void}> = ({
  open, setOpen,
}) => {
  const [comment, setComment] = useState('');
  const [reasons, setReasons] = useState<string[]>([]);
  const [score, setScore] = useState<number>();
  const [participate, setParticipate] = useState(false);
  const [step, setStep] = useState(1);

  const { colorMode } = useColorMode();

  const {
    completeSurvey,
    refuseSurvey,
    replyLaterSurvey,
  } = useOptimizerApi();

  const onRefuseSurvey = useCallback(() => {
    refuseSurvey();
    setOpen();
  }, [refuseSurvey, setOpen]);

  const onReplyLaterSurvey = useCallback(() => {
    replyLaterSurvey();
    setOpen();
  }, [replyLaterSurvey, setOpen]);

  const onCompleteSurvey = useCallback(() => {
    if (score) {
      completeSurvey({
        score: score ?? 9,
        reasons,
        comment,
        isUserParticipatingInTest: participate,
      });
    }
    setOpen();
  }, [comment, score, reasons, participate, completeSurvey, setOpen]);

  const onSetScore = useCallback((value: number) => {
    setScore(value);
  }, []);

  const nextStep = useCallback(() => {
    setStep(2);
  }, []);

  const handleReason = useCallback((v: string[]) => setReasons(v), []);

  const handleComment = useCallback((v: string) => setComment(v), []);

  const handleParticipate = useCallback(() => setParticipate(!participate), [participate]);

  const userGroup: NpsUserGroup = useMemo(() => {
    if (!score) {
      return 'NEUTRAL';
    }

    if (score < 7) {
      return 'DETRACTOR';
    }

    if (score < 9) {
      return 'NEUTRAL';
    }

    return 'PROSECUTOR';
  }, [score]);

  return (
    <>
      <Modal
        isCentered
        isOpen={open}
        onClose={onReplyLaterSurvey}
        closeOnOverlayClick={false}
        closeOnEsc={false}
        scrollBehavior="inside"
        size={step === 1 ? 'lg' : 'md'}
      >
        <ModalOverlay />
        <ModalContent borderRadius={10} margin="8px">
          <ModalBody borderRadius={10} p={0} backgroundColor={colorMode === 'light' ? '#FFFFFF' : '#111317'}>
            {step === 1 ? (
              <ModalStep1
                onSetScore={onSetScore}
                onReplyLater={onReplyLaterSurvey}
                onRefuse={onRefuseSurvey}
                onNext={nextStep}
                disabled={score === undefined}
              />
            ) : (
              <ModalStep2
                userGroup={userGroup}
                reasons={reasons}
                setReasons={handleReason}
                comment={comment}
                setComment={handleComment}
                participate={participate}
                onSetParticipate={handleParticipate}
                onCompleteSurvey={onCompleteSurvey}
              />
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
