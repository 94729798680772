import { Center, Circle, SlideFade, Text } from '@chakra-ui/react';
import React, { FC, useCallback, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useUserClasses } from '../../../../api/flashcards';
import { useDefaultStyles, useRGBColor } from '../../../../hooks/useDefaultStyles';
import { setParam } from '../../../../hooks/useSearchParam';
import { FlashcardsCreationContext } from '../../contexts/FlashcardsCreation.context';

type DeckCardType = {
  lessonTitle: string,
  amount: number,
  index: number,
  lessonId?: string,
}
export const DELAY_INDEX = 0.05;

export const DeckCard: FC<DeckCardType> = ({
  lessonTitle, amount, lessonId, index,
}: DeckCardType) => {
  const { setAllowDelay, allowDelay } = useContext(FlashcardsCreationContext);
  const { colors } = useDefaultStyles();
  const { toRGB } = useRGBColor();
  const history = useHistory();
  const { getAreaByLessonId, loading } = useUserClasses();
  const area = getAreaByLessonId({ lessonId });

  const handleDeckClick = useCallback(() => {
    setAllowDelay(true);
    if (lessonId) {
      history.push(setParam({ key: 'lessonId', value: lessonId }));
    }
    if (lessonId === null) {
      history.push(setParam({ key: 'lessonId', value: 'todos' }));
    }
  }, [history, lessonId, setAllowDelay]);

  if (loading) {
    return <></>;
  }

  return (
    <SlideFade in offsetX="-200px" delay={DELAY_INDEX * index * Number(allowDelay)}>
      <Center
        as="button"
        boxShadow={`4px 4px 0px 0px ${toRGB(colors.deck.shadow)}`}
        w={140}
        h={170}
        onClick={handleDeckClick}
        borderRadius={10}
        margin={2}
        px={4}
        pt={4}
        transition="transform .2s, background-color .5s;"
        _hover={{
          transform: 'translateY(-10px);',
        }}
        color={colors.lighter.goDarker}
        bgColor={colors.primary.goALittleLighter}
        flexFlow="column"
      >
        <Center flex={1} w="100%" justifyContent="center">
          <Circle
            bgColor={`${colors.areas[area]}.500`}
            size={8}
          >
            <Text fontWeight="bold" fontSize="xs" color={colors.white.keep}>
              {area}
            </Text>
          </Circle>
        </Center>
        <Center flex={4}>
          <Text
            lineHeight="12px"
            w="100%"
            fontSize="xs"
            fontWeight="bold"
            transition="color .5s"
            noOfLines={2}
          >
            {lessonTitle}
          </Text>
        </Center>
        <Center
          flex={1}
          p={2}
        >
          <Text
            lineHeight="12px"
            w="100%"
            fontSize="xs"
            transition="color .5s"
          >
            {`${amount} flashcard${amount > 1 ? 's' : ''}`}
          </Text>
        </Center>
      </Center>
    </SlideFade>
  );
};
