import { HStack, Square, Text } from '@chakra-ui/react';
import { FC } from 'react';
import { useDefaultStyles } from '../../../hooks/useDefaultStyles';

export const TrophiesSubtitle: FC = () => {
  const { colors } = useDefaultStyles();
  return (
    <HStack justify="center" spacing={4}>
      <HStack spacing={1}>
        <Square
          boxSize="20px"
          borderWidth={3}
          borderColor="orange.500"
          bgColor={colors.prizes.bronze}
          borderRadius={4}
        />
        <Text>Bronze</Text>
      </HStack>
      <HStack spacing={1}>
        <Square
          boxSize="20px"
          borderWidth={3}
          borderColor="gray.400"
          bgColor={colors.prizes.silver}
          borderRadius={4}
        />
        <Text>Prata</Text>
      </HStack>
      <HStack spacing={1}>
        <Square
          boxSize="20px"
          borderWidth={3}
          borderColor="yellow.500"
          bgColor={colors.prizes.gold}
          borderRadius={4}
        />
        <Text>Ouro</Text>
      </HStack>
    </HStack>
  );
};
