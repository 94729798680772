import { Button, HStack, Text } from '@chakra-ui/react';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC } from 'react';

export interface ReturnButtonProps {
  label: string;
  onClick: () => void;
}

export const ReturnButton: FC<ReturnButtonProps> = ({ label, onClick }: ReturnButtonProps) => {
  return (
    <HStack marginBottom={8}>
      <Button
        onClick={onClick}
        variant="ghost"
      >
        <FontAwesomeIcon icon={faArrowLeft} />
      </Button>
      <Text
        fontWeight="bold"
        marginBottom={4}
        fontSize="1.2rem"
      >
        {label}
      </Text>
    </HStack>
  );
};
