import { ReactNode, useContext, useEffect, useMemo } from 'react';
import { MainWrapperContext } from '../contexts/MainWrapper.context';

export function useHeader(header: ReactNode) {
  const { setHeaderComponent } = useContext(MainWrapperContext);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const headerComp = useMemo(() => header, []);

  useEffect(() => {
    setHeaderComponent(headerComp);
    return () => setHeaderComponent();
  }, [headerComp, setHeaderComponent]);
}
