import { AspectRatio, Avatar, Box, Circle, Flex, HStack, Image, Link, Text, VStack } from '@chakra-ui/react';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { FC, useMemo } from 'react';
import { Notification } from '../../../api/notifications';
import { useDefaultStyles } from '../../../hooks/useDefaultStyles';
import { formatName } from '../../../utils/forumUtils';

interface NotificationProps {
  notification: Notification;
  updateSeen: () => void;
}

const formatLink = (link?: string) => {
  const forumStartString = 'https://aristoclass.com.br/forum-viewer/';
  if (link && link.startsWith(forumStartString)) {
    const rawUid = link.split('forum-viewer/')[1];
    const formatedUid = rawUid.replaceAll('/', '-');
    return forumStartString + formatedUid;
  }
  return link;
};

const NotificationItem: FC<NotificationProps> = ({
  notification: { createdAt, authorPicture, body, thumbnail, link: rawLink, seen },
  updateSeen,
}) => {
  const link = formatLink(rawLink);
  const { colors } = useDefaultStyles();
  return (
    <HStack
      align="start"
      as={(link || !seen) ? Link : Flex}
      bgColor={!seen ? colors.lighter.goAlpha[200] : ''}
      borderRadius={{ base: 0, md: 8 }}
      href={link}
      onClick={updateSeen}
      p={2}
      pl={6}
      position="relative"
      target="_blank"
      w="100%"
      _hover={{ textDecoration: 'none' }}
    >
      <Avatar
        src={authorPicture}
        alt={'Foto de '.concat(body.authorName ?? 'Anônimo')}
        bgColor={colors.lighter.goFullDarker}
        color={colors.secondary.keep}
        icon={<FontAwesomeIcon icon={faUser} />}
      />
      <Box flex={1} alignSelf="center">
        {body.authorName && (
          <Text as="span" fontWeight="bold" display="inline" mr={1}>
            {formatName(body.authorName)}
          </Text>
        )}
        <Text as="span" noOfLines={4} display="inline">{body.message}</Text>
        <Text color="dark.400">{moment(createdAt).fromNow()}</Text>
      </Box>
      {thumbnail && (
        <AspectRatio w="100%" maxW="50px" ratio={1}>
          <Image
            src={thumbnail}
            alt="Thumbnail"
            objectFit="cover"
            w="100%"
          />
        </AspectRatio>
      )}
      {!seen && (
        <Circle
          size="10px"
          bgColor={colors.secondary.goDarker}
          position="absolute"
          top="50%"
          transform="translateY(-50%)"
          left={0}
        />
      )}
    </HStack>
  );
};

interface NotificationSectionProps {
  title: string;
  intervalRange: {
    start: number;
    end: number | 'infinite';
  };
  intervalType?: moment.unitOfTime.Diff;
  notifications: Notification[];
  updateSeen: (id: string) => void;
}

export const NotificationSection: FC<NotificationSectionProps> = ({
  title, intervalRange, intervalType = 'days', notifications, updateSeen,
}) => {
  const notificationsFilter = useMemo(() => (
    notifications.filter(item => {
      if (intervalRange.end === 'infinite') {
        return moment(item.createdAt).diff(moment(), intervalType) <= -1 * intervalRange.start;
      }
      return (
        moment(item.createdAt).diff(moment(), intervalType) <= -1 * intervalRange.start
        && moment(item.createdAt).diff(moment(), intervalType) > -1 * intervalRange.end
      );
    })
  ), [intervalRange.end, intervalRange.start, intervalType, notifications]);

  return notificationsFilter.length > 0 ? (
    <Box w="100%">
      <Text
        fontWeight="bold"
        fontSize="md"
        my={2}
        ml={2}
      >
        {title}
      </Text>
      <VStack>
        {
          [...notificationsFilter]
            .sort((a, b) => (
              moment(a.createdAt).diff(moment(), 'milliseconds')
                <= moment(b.createdAt).diff(moment(), 'milliseconds') ? 1 : -1))
            .map(notification => (
              <NotificationItem
                key={notification._id}
                notification={notification}
                updateSeen={() => updateSeen(notification._id)}
              />
            ))
        }
      </VStack>
    </Box>
  ) : null;
};
