import { gql, useQuery } from '@apollo/client';

export type GetPremiumCoursesForUserType = {
  courseId: string,
  title: string,
  description?: string,
  image?: string,
}

const GET_PREMIUM_COURSES_FOR_USER = gql`
  query GetPremiumCoursesForUser {
    getPremiumCoursesForUser {
      courseId
      title
      description
      image
    }
  }
`;

type GetPremiumCoursesForUserOutput = {
  getPremiumCoursesForUser: GetPremiumCoursesForUserType[],
};

export function usePremiumCoursesForUser() {
  return useQuery<GetPremiumCoursesForUserOutput>(GET_PREMIUM_COURSES_FOR_USER);
}
