import { Button, Tooltip } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart as faHeartRegular } from '@fortawesome/free-regular-svg-icons';
import { faHeart } from '@fortawesome/free-solid-svg-icons';
import { FC } from 'react';
import { useDefaultStyles } from '../../../../hooks/useDefaultStyles';

type HeartLikeButtonType = {
  isLiked: boolean,
  onClick: () => Promise<void>,
}

export const HeartLikeButton: FC<HeartLikeButtonType> = ({
  isLiked,
  onClick,
}: HeartLikeButtonType) => {
  const { colors } = useDefaultStyles();
  return (
    <Tooltip label="Curtir">

      <Button
        fontSize="xs"
        variant="unstyled"
        transition="0.3s all"
        onClick={onClick}
        color={isLiked ? colors.red.keep : undefined}
        _focus={{ variant: 'unstyled', outline: 'none' }}
        _hover={{
          bgColor: 'transparent',
          filter: 'brightness(2)',
          color: 'red',
        }}
        textShadow="3px 2px red"
      >
        <FontAwesomeIcon
          style={{ textShadow: '2px 2px red' }}
          icon={isLiked ? faHeart : faHeartRegular}
        />
      </Button>
    </Tooltip>
  );
};
