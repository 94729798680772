import { FC, useState } from 'react';
import {
  Divider,
  Flex,
  Text,
  VStack,
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronUp,
  faChevronDown,
} from '@fortawesome/free-solid-svg-icons';
import { MockExamStudentPerformanceObjectType } from '../../../api/mock-exams-performance';
import { useDefaultStyles } from '../../../hooks/useDefaultStyles';

interface AreaPerformanceCardProps {
  data?: MockExamStudentPerformanceObjectType;
}

const ModalPerformanceCard: FC<AreaPerformanceCardProps> = ({
  data,
}: AreaPerformanceCardProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const { colors } = useDefaultStyles();

  const handleOpen = () => {
    setIsOpen(!isOpen);
  };

  if (!data) {
    return <></>;
  }

  return (
    <VStack
      borderRadius="8px"
      backgroundColor={colors.alpha[100]}
      justifyContent="start"
      width="100%"
      spacing="16px"
      padding="8px 16px"
    >
      <Flex width="100%" justifyContent="space-between">
        <Text>{data.area}</Text>
        <Text onClick={handleOpen} cursor="pointer">
          {isOpen ? (
            <FontAwesomeIcon icon={faChevronUp} />
          ) : (
            <FontAwesomeIcon icon={faChevronDown} />
          )}
        </Text>
      </Flex>
      <Divider />
      <Flex width="100%" justifyContent="center">
        <Flex marginX="8px" direction="column" justifyContent="center" alignItems="center">
          <Text fontWeight="bold">{`${data.correct}/${data.totalQuestionsByArea}`}</Text>
          <Text fontSize="12px">acertos</Text>
        </Flex>
        <Flex marginX="8px" direction="column" justifyContent="center" alignItems="center">
          <Text fontWeight="bold">{`${Math.round(data.average * 100)}%`}</Text>
          <Text fontSize="12px">desempenho</Text>
        </Flex>
      </Flex>
      {isOpen && (
        <VStack width="100%" spacing="8px">
          {data.themas.map(it => (
            <Flex width="100%" justifyContent="space-between" alignItems="start">
              <Text fontSize="12px">{it.leaf}</Text>
              <Text fontSize="16px" fontWeight="bold">{`${it.correct}/${it.totalQuestionsByThema}`}</Text>
            </Flex>
          ))}
        </VStack>
      )}
    </VStack>
  );
};

export default ModalPerformanceCard;
