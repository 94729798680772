import React, {
  useContext, createContext, useMemo, FC,
} from 'react';
import { CourseManyInfo, useCoursesManyInfo } from './api/courses';
import { PrivateContext } from './Private.context';

interface CourseContextType {
  course: CourseManyInfo[];
  loading: boolean;
}

export const CourseContext = createContext({} as CourseContextType);

export const CourseStorage: FC = ({
  children,
}) => {
  const { profile } = useContext(PrivateContext);

  const { data, loading } = useCoursesManyInfo(
    profile && profile.courses && profile.courses.length > 0
      ? [profile.courses[0].course]
      : [],
  );

  const course = useMemo(() => {
    if (!data || loading) {
      return [];
    }
    return [...data.coursesManyInfo];
  }, [data, loading]);

  return (
    <CourseContext.Provider value={{ course, loading }}>
      {children}
    </CourseContext.Provider>
  );
};
