import { gql, QueryResult, useMutation, useQuery } from '@apollo/client';
import { useCallback } from 'react';
import { DifficultyType } from '../answer';
import errorHandler from '../error/errorHandler';
import { Comment, TheoreticalTypes } from './agenda';

export interface Question {
  _id: string;
  body: string;
  choices: string[]
  answersProportion: number[];
  correctChoice: number;
  institution: string;
  uf: string;
  year: string;
  examOrder: number;
  examType: string;
  complement: string;
  classification: string[];
  difficulty: DifficultyType;
  createdAt: string;
  updatedAt: string;
  comment: Comment;
  answer: number;
  isEssay?: boolean;
  essay?: string;
}

interface QuestionManyInput {
  calendarDate: string;
  type?: TheoreticalTypes;
  ids: string[];
  activity?: string;
}

interface QuestionManyOutput {
  questionMany: Question[];
}

const QUESTION_MANY = gql`
  query QuestionMany(
    $calendarDate: CalendarDate!
    $type: TheoreticalType
    $ids: [ObjectId!]!
    $activity: ObjectId
  ) {
    questionMany(
      calendarDate: $calendarDate
      type: $type
      ids: $ids
      activity: $activity
    ) {
      _id
      body
      choices
      answersProportion
      correctChoice
      institution
      uf
      year
      examOrder
      examType
      complement
      classification
      difficulty
      createdAt
      updatedAt
      comment {
        author
        profile
        title
        body
        createdAt
        updatedAt
      }
      answer
      essay
      isEssay
    }
  }
`;

export function useQuestionMany({
  ids,
  calendarDate,
  type,
  activity,
}: QuestionManyInput): QueryResult<QuestionManyOutput, QuestionManyInput> {
  return useQuery<QuestionManyOutput, QuestionManyInput>(QUESTION_MANY, {
    variables: {
      ids,
      calendarDate,
      type,
      activity,
    },
    fetchPolicy: 'network-only',
    skip: ids.length === 0,
  });
}

type AddOrRemoveQuestionDoubtInputType = {
  input: {
    action: 'add' | 'remove',
    questionId: string,
    activityId?: string,
    agendaId?: string,
    type?: string,
  }
}

type AddOrRemoveQuestionDoubtOutputType = {
  addOrRemoveQuestionAsDoubt: string,
}

export type GetActivityDoubtsInputType = {
  input: {
    activityId?: string,
    agendaId?: string,
    type?: string,
  }
}

export type GetActivityDoubtsOutputType = {
  getActivityDoubts : {
    doubtIds: string[],
  },
}

const ADD_OR_REMOVE_QUESTION_AS_DOUBT = gql`
  mutation AddOrRemoveQuestionAsDoubt (
    $input: AddOrRemoveQuestionAsDoubtInputType!
  ) {
    addOrRemoveQuestionAsDoubt (
      input: $input
    )
  }
`;

const GET_ACTIVITY_DOUBTS = gql`
  query GetActivityDoubts (
    $input: GetActivityDoubtsInputType!
  ) {
    getActivityDoubts (
      input: $input
    ) {
      doubtIds
    }
  }
`;

export function useGetActivityDoubts({ input }: GetActivityDoubtsInputType) {
  const noInputs = Boolean(!input.activityId && !input.agendaId && !input.type);
  const unpatchingInputs = Boolean(input.activityId && (input.type || input.agendaId));
  const missingVar = Boolean(!input.activityId && (!input.type || !input.agendaId));
  return useQuery<GetActivityDoubtsOutputType, GetActivityDoubtsInputType>(GET_ACTIVITY_DOUBTS, {
    variables: {
      input,
    },
    fetchPolicy: 'network-only',
    skip: noInputs || unpatchingInputs || missingVar,
  });
}

export function useAddOrRemoveQuestionAsDoubt() {
  const [addOrRemoveQuestionAsDoubtMutation] = useMutation<
    AddOrRemoveQuestionDoubtOutputType, AddOrRemoveQuestionDoubtInputType
  >(ADD_OR_REMOVE_QUESTION_AS_DOUBT, {
    onError: errorHandler,
  });

  const addOrRemoveQuestionAsDoubt = useCallback(async ({
    action,
    type,
    questionId,
    activityId,
    agendaId,
  }: AddOrRemoveQuestionDoubtInputType['input']) => {
    await addOrRemoveQuestionAsDoubtMutation({
      variables: {
        input: {
          action,
          questionId,
          activityId,
          agendaId,
          type,
        },
      },
    });
  }, [addOrRemoveQuestionAsDoubtMutation]);

  return {
    addOrRemoveQuestionAsDoubt,
  };
}
