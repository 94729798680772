import { gql, QueryResult, useQuery } from '@apollo/client';

export interface GetActivityMockExamsHistory {
  _id?: string;
  mockExamTemplate: string;
  title: string;
  subtitle: string;
  startsAt: string;
  startedAt?: string;
  grade?: number;
  _t: string;
  finished: boolean;
  calendarDate: string;
}

interface GetActivityMockExamsHistoryOutput {
  getActivityMockExamsHistory: GetActivityMockExamsHistory[];
}

const GET_ACTIVITY_MOCK_EXAMS_HISTORY = gql`
  query GetActivityMockExamsHistory {
    getActivityMockExamsHistory {
      mockExamTemplate
      title
      subtitle
      startsAt
      startedAt
      grade
      _t
      finished
      calendarDate
    }
  }
`;

export function useGetMockExamsHistory(): QueryResult<GetActivityMockExamsHistoryOutput> {
  return useQuery<GetActivityMockExamsHistoryOutput>(GET_ACTIVITY_MOCK_EXAMS_HISTORY);
}
