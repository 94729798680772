import { Flex, HStack, Text } from '@chakra-ui/react';
import { FC } from 'react';
import { PlaylistType } from '../../api/aristoplus';
import { Playlist } from './Playlist.component';

type PlaylistSetType = {
  playlists?: PlaylistType[],
  category: string,
}

export const PlaylistSet: FC<PlaylistSetType> = ({
  playlists,
  category,
}) => {
  if (!playlists) {
    return null;
  }
  return (
    <Flex flexFlow="column" w="100%">
      <Text mt={4} mb={2} fontSize="2xl" fontWeight="bold">{category}</Text>
      <HStack spacing={4} w="100%" overflowX="auto" className="hide-scrollbar">
        {playlists.map((playlistOpt, i) => (
          <Playlist
            key={Math.random().toString(36)}
            category={category}
            {...playlistOpt}
          />
        ))}
      </HStack>
    </Flex>
  );
};
