import { Box, Tooltip } from '@chakra-ui/react';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC } from 'react';

type PrizeStarType = {
  goal: number;
  achieved: number;
  label?: string;
  hidden?: boolean;
}

export const PrizeStar: FC<PrizeStarType> = ({
  label,
  goal,
  achieved,
  hidden = false,
}: PrizeStarType) => {
  if (achieved < goal || hidden) {
    return <></>;
  }
  return (
    <Tooltip label={label} hidden={!label}>
      <Box fontSize={12} ml={1} display="inline">
        <FontAwesomeIcon className="spin-rainbow" icon={faStar} />
      </Box>
    </Tooltip>
  );
};
