import React, { MouseEventHandler, FC } from 'react';
import { Box, Text } from '@chakra-ui/react';
import clsx from 'clsx';
import { useDefaultStyles } from '../../hooks/useDefaultStyles';

interface ToggleProps {
  active: boolean;
  className?: string;
  onChange: MouseEventHandler;
  color?: 'default' | 'primary';
  label?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [x: string]: any;
}

const Toggle: FC<ToggleProps> = ({
  active, className, onChange, color = 'default', label, ...rest
}) => {
  const { colors } = useDefaultStyles();
  const cls = {
    toggle: true,
    active,
    'toggle--primary': color === 'primary',
  };

  return (
    <Box>
      {
        label && (
          <Text fontSize="sm" color={colors.secondary.goALittleDarker} w="100%">{label}</Text>
        )
      }
      <Box as="button" {...rest} className={clsx(cls, className)} onClick={onChange} />
    </Box>
  );
};

export default Toggle;
