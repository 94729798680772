import { Flex, HStack } from '@chakra-ui/react';
import { FC } from 'react';
import { useDefaultStyles } from '../../hooks/useDefaultStyles';
import ContentTab from './ContentTab';

type ContentTabType = {
  to: string,
  title: string,
  visible?: boolean,
  onClick?: () => void,
}

type AristoTabType = {
  tabsData: ContentTabType[],
  search: string,
  h?: number,
  visible?: boolean,
}

export const AristoTab: FC<AristoTabType> = ({
  tabsData,
  search,
  h,
  visible = true,
}: AristoTabType) => {
  const { colors } = useDefaultStyles();
  const { pathname } = window.location;

  if (!visible) {
    return <></>;
  }

  return (
    <Flex
      h={h}
      my={{ base: 2, md: 4 }}
      borderBottom="1px solid"
      borderColor={colors.secondary.keep}
    >
      <HStack align="stretch" overflowX={{ base: 'scroll', md: 'auto' }} m="0 auto" mb="-1px">
        {
          tabsData.map(currTab => {
            return (
              currTab.visible && (
                <ContentTab
                  key={currTab.to}
                  to={currTab.to}
                  active={pathname === currTab.to}
                  search={search}
                  onClick={currTab.onClick}
                >
                  {currTab.title}
                </ContentTab>
              )
            );
          })
        }
      </HStack>
    </Flex>
  );
};
