import React, { FC } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import MenuTabs from '../../../lib/components/MenuTabs';
import FavoriteVideos from './FavoriteVideos';
import FavoriteFlashcards from './FavoriteFlashcards';
import FavoriteTexts from './FavoriteTexts';
import FavoriteMindmaps from './FavoriteMindmaps';
import FavoriteQuestions from './FavoriteQuestions';

const AgendaFavorites: FC = () => {
  return (
    <>
      <MenuTabs
        menuItems={{
          questao: true,
          video: true,
          apostila: true,
          'mapa-mental': true,
          flashcard: true,
        }}
        path="/favoritos"
      />
      <Switch>
        <Route
          exact
          path="/favoritos"
          render={() => (
            <Redirect
              to="/favoritos/questao"
            />
          )}
        />
        <Route
          path="/favoritos/questao"
          render={() => <FavoriteQuestions />}
        />
        <Route
          path="/favoritos/video"
          render={() => <FavoriteVideos />}
        />
        <Route
          path="/favoritos/apostila"
          render={() => <FavoriteTexts />}
        />
        <Route
          path="/favoritos/mapa-mental"
          render={() => <FavoriteMindmaps />}
        />
        <Route
          path="/favoritos/flashcard"
          render={() => <FavoriteFlashcards />}
        />
      </Switch>
    </>
  );
};

export default AgendaFavorites;
