/* eslint-disable no-undef */
import { useCallback } from 'react';
import ReactMarkdown from 'react-markdown';
import useFirebaseStorageImg from '../../../hooks/useFirebaseStorageImg';
import { ZoomImg } from '../../../lib/components/ZoomImg';
import { HighlightableMarkdown } from '../HighlightableMarkdown';

interface QuestionBodyProps {
  body: string;
  id: string;
  index: number;
  info?: string;
  disableHighlight?: boolean;
}

export function QuestionBody({
  body, id, index, info, disableHighlight = false,
}: QuestionBodyProps) {
  const storage = useFirebaseStorageImg();
  const getImage = useCallback((uri: string) => storage.getURL(uri), [storage]);
  return (
    <>
      {disableHighlight ? (
        <ReactMarkdown
          disallowedTypes={['image']}
          className="mb-2"
        >
          {`${info} ${body}`}
        </ReactMarkdown>
      ) : (
        <HighlightableMarkdown
          id={id}
          index={index}
        >
          <ReactMarkdown
            disallowedTypes={['image']}
            className="mb-2"
          >
            {`${info} ${body}`}
          </ReactMarkdown>
        </HighlightableMarkdown>
      )}
      <ReactMarkdown
        transformImageUri={getImage}
        renderers={{ image: (props): JSX.Element => <ZoomImg {...props} /> }}
      >
        {body.match(/!\[.*\)/g)?.join('') || ''}
      </ReactMarkdown>
    </>
  );
}
