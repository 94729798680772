import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalFooter,
  ModalBody,
  Select,
  Box,
  Text,
} from '@chakra-ui/react';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  FC, FormEvent, ReactNode, useCallback, useState,
} from 'react';
import { DocTypes } from '../../api/agenda/agenda';
import { useReport } from '../../api/report';
import { defaultOptions, reportOptions } from '../../constants/report-options';
import { useDefaultStyles } from '../../hooks/useDefaultStyles';
import { PrimaryButton } from '../../lib/components/PrimaryButton';
import { SecondaryButton } from '../../lib/components/SecondaryButton';
import Textarea from '../../lib/components/Textarea';
import { toast } from '../../utils/toast';

export interface ModalReportProps {
  id: string;
  type: DocTypes | 'comment';
  isOpen?: boolean;
  onClose?: () => void;
  onReport?: () => void;
  modalTitle?: string;
  modalIcon?: ReactNode;
  customOptions?: string[];
}

const ModalReport: FC<ModalReportProps> = ({
  id,
  type,
  isOpen,
  onClose,
  customOptions = reportOptions[type as keyof typeof reportOptions] ?? defaultOptions,
  onReport,
  modalTitle = 'Reportar problema',
  modalIcon = <FontAwesomeIcon icon={faExclamationCircle} />,
}) => {
  const [reportText, setReportText] = useState('');
  const [title, setTitle] = useState('');
  const [loading, setLoading] = useState(false);
  const { modal } = useDefaultStyles();

  const { onReportCreateOne } = useReport();

  const handleReportText = useCallback((event: FormEvent<HTMLTextAreaElement>) => {
    setReportText(event.currentTarget.value);
  }, []);

  const handleTitle = useCallback(e => {
    setTitle(e.target.value);
  }, []);

  const handleCreateIssue = useCallback(async () => {
    setLoading(true);
    try {
      await onReportCreateOne({
        contentId: id,
        body: reportText,
        contentType: type,
        title,
      });
      setReportText('');
      setTitle('');
      if (onReport) {
        onReport();
      }
      if (onClose) {
        onClose();
      }
      toast.success({
        title: 'Problema reportado',
        description: 'Obrigado pelo seu feedback 😊',
      });
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  }, [onReportCreateOne, id, reportText, type, title, onReport, onClose]);

  return (
    <Modal isOpen={isOpen ?? false} onClose={onClose ?? (() => ({}))} isCentered>
      <ModalOverlay />
      <ModalContent mx={4} borderRadius={10} overflow="hidden">
        <ModalHeader
          py={2}
          display="flex"
          alignItems="center"
          bgColor={modal.header.bgColor}
          color={modal.header.color}
        >
          <Box position="absolute" top={2} left={3}>
            {modalIcon}
          </Box>
          <Text flex={1} textAlign="center">{modalTitle}</Text>
        </ModalHeader>
        <ModalBody bgColor={modal.body.bgColor}>
          <Select placeholder="Escolha" value={title} onChange={handleTitle}>
            {customOptions.map(opt => (
              <option key={opt} value={opt}>{opt}</option>
            ))}
          </Select>
          <Box mt={1}>
            <Textarea
              style={{ backgroundColor: 'transparent' }}
              placeholder="Descreva aqui o problema..."
              value={reportText}
              onChange={handleReportText}
            />
          </Box>
        </ModalBody>
        <ModalFooter bgColor={modal.footer.bgColor} justifyContent="center">
          <SecondaryButton mr={3} onClick={onClose} colorScheme="dark">
            Cancelar
          </SecondaryButton>
          <PrimaryButton
            onClick={() => {
              handleCreateIssue();
            }}
            isLoading={loading}
            loadingText="Enviando..."
          >
            Enviar
          </PrimaryButton>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalReport;
