// eslint-disable-next-line import/no-unresolved
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { Box, Link } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC, ReactNode } from 'react';

type SocialMediaItemProps = {
  icon: IconDefinition,
  address: string,
  children: ReactNode,
  className?: string,
  title?: string,
}

export const SocialMediaItem: FC<SocialMediaItemProps> = ({
  icon, address, children, className, title,
}: SocialMediaItemProps) => {
  return (
    <Box
      className="socialMediaContainer"
      title={title || ''}
    >
      <Box>
        <FontAwesomeIcon className={`socialMediaIcon ${className}`} icon={icon} />
      </Box>
      <Box
        className="socialMediaLabel"
        width={0}
        transition="width .4s linear"
        overflow="hidden"
        fontSize="1.8rem"
        maxHeight="2.3rem"
        whiteSpace="nowrap"
      >
        <Link
          border="none"
          _focus={{ border: 'none' }}
          _hover={{ textDecoration: 'none', border: 'none' }}
          target="blank"
          href={address}
        >
          {children}
        </Link>
      </Box>
    </Box>
  );
};
