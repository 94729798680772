import { useHistory } from 'react-router-dom';
import { Box, Button, HStack } from '@chakra-ui/react';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC } from 'react';
import { getDateParam } from '../../../../hooks/getDateParam';
import { useDefaultStyles } from '../../../../hooks/useDefaultStyles';

interface ExtraHeaderProps {
  title: string;
  redirectLink?: string,
}

export const ExtraHeader: FC<ExtraHeaderProps> = ({ title, redirectLink = '/atividades-extras' }: ExtraHeaderProps) => {
  const history = useHistory();
  const date = getDateParam();
  const { colors } = useDefaultStyles();

  return (
    <HStack
      align="center"
      margin="1rem 0"
    >
      <Button
        variant="ghost"
        onClick={() => history.push({ pathname: redirectLink, search: `date=${date}` })}
      >
        <FontAwesomeIcon icon={faArrowLeft} />
      </Button>
      <Box
        flex={4}
        color={colors.secondary.keep}
        fontWeight="bold"
        fontSize="1.2rem"
      >
        {title}
      </Box>
    </HStack>
  );
};
