import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Box, Center, Flex, HStack, ScaleFade, Text } from '@chakra-ui/react';
import { PrivateContext } from '../../../Private.context';
import { useDefaultStyles } from '../../../hooks/useDefaultStyles';
import { Loading } from '../../../lib/components/Loading';
import { EmptyPage } from './components/EmptyPage';
import { PrimaryButton } from '../../../lib/components/PrimaryButton';
import { CommentDtoProps, ForumContextProps } from './types/forum2.types';
import { COMMENT_PAGE_STEP, useForum2API } from '../../../hooks/useForum2API';
import { Forum2Context } from '../../../contexts/Forum2.context';
import { Forum2CommentWithAnswers } from './components/Forum2CommentWithAnswers.component';
import { CommentBox } from './components/CommentBox';
import { InlineText } from '../InlineText.component';
import { toast } from '../../../utils/toast';

export const SPLIT_MARKER = '{|||||}';
export const IS_DEV_ENV = process.env.REACT_APP_ENV === 'DEV';

export const Forum2Off: FC<ForumContextProps> = ({ id, classification, tag, contentType, show = true }) => null;

export const Forum2: FC<ForumContextProps> = ({
  id,
  classification,
  tag,
  contentType,
  show = true,
  courseIdFromParam,
  questionData,
  selectedChoice,
}) => {
  const {
    createOrGetForumByUID,
    deleteComment,
  } = useForum2API();

  const {
    comments,
    updateComments,
    setComments,
    forumRef,
    answerActive,
    getLimits,
    loadQuestionData,
    loadAnswer,
    onHealthCheck,
  } = useContext(Forum2Context);

  const { forumCredentials, profile } = useContext(PrivateContext);

  const [currForumNav, setCurrForumNav] = useState(1);
  const [currForumCommentsAmount, setCurrForumCommentsAmount] = useState(0);
  const [loadingMoreFirstLevelComments, setLoadingMoreFirstLevelComments] = useState(false);
  const [forumLoading, setForumLoading] = useState(false);
  const [currError, setCurrError] = useState<string | null>(null);
  const { colors } = useDefaultStyles();

  const courseId = useMemo(() => {
    if (profile) {
      return profile.courses.map(c => c.course)[0];
    } return '';
  }, [profile]);

  useEffect(() => {
    setForumLoading(true);
    setCurrForumNav(1);
  }, [id, setCurrForumNav]);

  useEffect(() => {
    if (comments.length) {
      getLimits(comments.map(it => it.id));
    }
    if (questionData) {
      loadQuestionData(questionData);
    }
    loadAnswer(selectedChoice ?? 0);
    onHealthCheck();
  }, [comments, getLimits, questionData, loadQuestionData, onHealthCheck, loadAnswer, selectedChoice]);

  const uid = useMemo(() => {
    return tag ? [tag ?? '', id].join('|') : id;
  }, [id, tag]);

  const redirectUrl = useMemo(() => {
    return `${window.location.origin}/forum-viewer/${uid}`;
  }, [uid]);

  const loadData = useCallback(() => {
    if (forumCredentials && profile._id && courseId && show) {
      const request = async () => {
        setForumLoading(true);
        const data = await createOrGetForumByUID({
          contentId: id,
          contentType,
          classification,
          courseId: courseIdFromParam ?? courseId,
          commentListPage: currForumNav,
          commentsPerPage: COMMENT_PAGE_STEP,
        });
        if (!data) {
          return false;
        }
        if (data.comments) {
          updateComments(data.comments.items);
        }
        if (data.comments !== undefined) {
          setCurrForumCommentsAmount(data.comments.total);
        }
        setForumLoading(false);
        return true;
      };
      try {
        const res = request();
        setLoadingMoreFirstLevelComments(false);
        if (!res) {
          throw new Error('Não foi possível fazer a chamada do fórum.');
        }
      } catch (e) {
        console.error(e);
        setCurrError(e.toString());
      }
    } else {
      setForumLoading(false);
    }
  }, [
    classification, contentType, courseId,
    createOrGetForumByUID, currForumNav,
    forumCredentials, id, profile._id, show,
    updateComments, courseIdFromParam,
  ]);

  useEffect(() => {
    if (forumCredentials && profile._id && courseId && show) {
      loadData();
    }
  }, [courseId, forumCredentials, loadData, profile._id, show]);

  const loadMoreFirstLevelComments = useCallback(async () => {
    setLoadingMoreFirstLevelComments(true);
    setCurrForumNav(prev => prev + 1);
  }, []);

  const handleSendCommentClick = useCallback(async (value: CommentDtoProps) => {
    setComments(prev => [value, ...prev]);
  }, [setComments]);

  useEffect(() => {
    setComments([]);
  }, [id, setComments]);

  const remainingCommentsCount = useMemo(() => {
    const remains = currForumCommentsAmount - currForumNav * COMMENT_PAGE_STEP;
    return remains;
  }, [currForumCommentsAmount, currForumNav]);

  const handleDeleteComment = useCallback(async (value: string) => {
    await deleteComment({
      commentId: value,
    });
    setComments(prev => {
      return prev.filter(e => e.id !== value);
    });
  }, [deleteComment, setComments]);

  if (!show) {
    return <></>;
  }

  if (currError) {
    return (
      <HStack
        bgColor={colors.lighter.goAlmostFullDarker}
        borderRadius={16}
        align="center"
        justify="flex-start"
        mt={5}
        p={6}
      >
        <Box color={colors.secondary.keep} fontSize={22}>
          <FontAwesomeIcon icon={faInfoCircle} />
        </Box>
        <Box>
          <InlineText>
            Não foi possível carregar o fórum.
          </InlineText>
          <InlineText
            as="button"
            onClick={() => {
              toast.info('Copiado para a área de transferência.');
              navigator.clipboard.writeText(currError ?? '');
            }}
            textDecoration="underline"
            color={colors.secondary.keep}
          >
            Clique aqui
          </InlineText>
          <InlineText>
            para copiar o registro do erro.
          </InlineText>
        </Box>
      </HStack>
    );
  }

  // if (true) {
  if (forumLoading || courseId === '') {
    return (
      <Box>
        <HStack justify="center" color={colors.black.goInvert}>
          <Loading color={colors.black.goInvert} />
          <Text>Carregando fórum...</Text>
        </HStack>
        <Box fontSize="xs" mt={4}>
          <Text>Não consegue ver o fórum?</Text>
          <InlineText
            textDecoration="underline"
            as="button"
            onClick={loadData}
            color={colors.secondary.keep}
          >
            Recarregue o fórum,
          </InlineText>
          <InlineText
            textDecoration="underline"
            as="button"
            onClick={() => window.location.reload()}
            color={colors.secondary.keep}
          >
            atualize a página,
          </InlineText>
          <InlineText>entre com uma aba anônima ou aperte Ctrl + Shift + R</InlineText>
        </Box>

      </Box>
    );
  }

  if (contentType === 'Resources') {
    return (
      <ScaleFade in={!forumLoading} initialScale={0.5}>
        <Flex flexFlow="column" align="center" my={5} transition="all 0.4s" minH={300}>
          <Flex maxW={{ md: 700 }} flexFlow="column" justify="center" w="100%" mt={2} ref={forumRef}>
            <Box w="100%">
              {comments
                && comments.map(comment => {
                  return (
                    <Forum2CommentWithAnswers
                      contentId={comment.contentId}
                      key={comment.id}
                      commentData={comment}
                      redirectUrl={redirectUrl}
                      commentOfQuestion
                      onDelete={async () => handleDeleteComment(comment.id)}
                      level={1}
                      contentType={contentType}
                      courseIdFromParam={courseIdFromParam}
                    />
                  );
                })}
            </Box>
            <HStack w="100%" justify="end">
              {comments && comments.length > 0 && remainingCommentsCount > 0 && (
                <Center w="100%">
                  {loadingMoreFirstLevelComments ? (
                    <Loading />
                  ) : (
                    <Text my={4} as="button" color={colors.black.goInvert} onClick={loadMoreFirstLevelComments}>
                      Ver mais comentários...
                    </Text>
                  )}
                </Center>
              )}
            </HStack>
            {(!comments || (comments && comments.length === 0)) && (
              <EmptyPage
                label="Por enquanto, essa prova não está completamente comentada.
                  Para solicitar um recurso exclusivo pra sua prova, clique no botão abaixo.
                  A resposta da sua solicitação irá chegar no seu e-mail!"
                variant="normal"
              />
            )}
            <PrimaryButton
              m="0 auto"
              mt={4}
              onClick={() => {
                window.open('https://airtable.com/shrj0KVv8QzkJdjqG', '_blank');
              }}
            >
              Solicitar recurso
            </PrimaryButton>
          </Flex>
        </Flex>
      </ScaleFade>
    );
  }

  return (
    <ScaleFade in={!forumLoading} initialScale={0.5}>
      <Flex flexFlow="column" align="center" my={5} w="100%" transition="all 0.4s" minH={300}>
        <Flex maxW={{ md: 700 }} flexFlow="column" justify="center" w="100%" mt={2} ref={forumRef}>
          <Text fontWeight={500} marginBottom={2} fontSize={18}>Fórum</Text>
          {
            answerActive === ''
            && (
              <>
                <CommentBox
                  loading={forumLoading}
                  onClick={handleSendCommentClick}
                  contentId={id}
                  contentType={contentType}
                  level={1}
                  courseId={courseIdFromParam ?? courseId}
                />
              </>
            )
          }
          <Box w="100%" marginTop={4}>
            {comments
              && comments.map(comment => {
                return (
                  <Forum2CommentWithAnswers
                    contentId={comment.contentId}
                    key={comment.id}
                    commentData={comment}
                    redirectUrl={redirectUrl}
                    onDelete={async () => handleDeleteComment(comment.id)}
                    level={1}
                    contentType={contentType}
                    courseIdFromParam={courseIdFromParam}
                  />
                );
              })}
          </Box>
          <HStack w="100%" justify="end">
            {comments && remainingCommentsCount > 0 && (
              <Center w="100%">
                {loadingMoreFirstLevelComments ? (
                  <Loading />
                ) : (
                  <Text my={4} as="button" color={colors.black.goInvert} onClick={loadMoreFirstLevelComments}>
                    Ver mais comentários...
                  </Text>
                )}
              </Center>
            )}
          </HStack>
          {comments && comments.length === 0 && <EmptyPage label="Sem comentários por aqui..." />}
        </Flex>
      </Flex>
    </ScaleFade>
  );
};
