import { Button, ButtonProps, Tooltip } from '@chakra-ui/react';
import { faCompress, faExpand } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC } from 'react';
import { useDefaultStyles } from '../../../hooks/useDefaultStyles';

interface FocusModeButtonInterface extends ButtonProps {
  show?: boolean,
  isFullscreen: boolean,
  toggleFullscreen: () => void,
}
export const FocusModeButton: FC<FocusModeButtonInterface> = ({
  show = true,
  isFullscreen,
  toggleFullscreen,
  ...props
}) => {
  const { colors } = useDefaultStyles();

  if (!show) {
    return null;
  }

  return (
    <Tooltip label={isFullscreen ? 'Sair do modo foco' : 'Modo foco'}>
      <Button
        variant="ghost"
        onClick={() => toggleFullscreen()}
        color={colors.secondary.keep}
        {...props}
      >
        <FontAwesomeIcon icon={isFullscreen ? faCompress : faExpand} />
      </Button>
    </Tooltip>
  );
};
