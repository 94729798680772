import { Box, Heading, Center, Text } from '@chakra-ui/react';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMemo } from 'react';
import { useQueryGetPerformanceTheme } from '../../../../api/performance-theme';
import { useDefaultStyles } from '../../../../hooks/useDefaultStyles';
import useTableFilter from '../../../../hooks/useTableFilter';
import TableHeaderComponent from '../../../../lib/components/TableHeader.component';
import TableRowComponent from '../../../../lib/components/TableRow.component';
import { getThemeIncidenceLevel } from '../../../../utils/getThemeDifficulty';
import DifficultyIcon from '../../../_components/DifficultyIcon';

export function QuestionPerformanceBySubjectTable() {
  const performanceTheme = useQueryGetPerformanceTheme();
  const { cardBorder, colors } = useDefaultStyles();

  const cr = useMemo(() => {
    const { data: perfomanceThemeData } = performanceTheme;
    if (perfomanceThemeData) {
      return perfomanceThemeData.getPerformanceByClassification.map(item => {
        return ({
          classification: item.classification,
          performance: item.performance,
          relevance: item.relevance,
        });
      });
    }
    return [];
  }, [performanceTheme]);

  const { dataFiltered: crFiltered, filter, handleFilter } = useTableFilter(cr);
  return (
    <Box
      mt={4}
      p={4}
      {...cardBorder}
    >
      <Heading size="md">Desempenho por assunto</Heading>
      <Text fontSize="xs" color={colors.secondary.keep}>Atualiza a cada 24 horas</Text>
      <Box maxHeight={400} overflow="auto">
        {performanceTheme.loading && (
          <Center height={200}>
            <FontAwesomeIcon icon={faSpinner} spin size="3x" className="color-dark-gray" />
          </Center>
        )}
        {
          (!performanceTheme.loading && performanceTheme.called) && (
            <>
              <TableHeaderComponent
                headers={[
                  {
                    key: 'space',
                    value: ' ',
                    flex: 3,
                  },
                  {
                    key: 'performance',
                    value: 'Desempenho',
                    flex: 1,
                    textAlign: 'center',
                    fontSize: '0.7rem',
                  },
                  {
                    key: 'relevance',
                    value: 'Incidência',
                    flex: 1,
                    textAlign: 'center',
                    fontSize: '0.7rem',
                  },
                ]}
                handleFilter={handleFilter}
                filter={filter}
              />
              {crFiltered.map((item, i) => {
                return (
                  <TableRowComponent
                    key={item.classification.join('')}
                    rowBackgroundColorWhite={i % 2}
                    rowData={[
                      {
                        key: 'tag',
                        value: item.classification[1] ?? 'Sem classificação',
                        align: 'left',
                        fontSize: '0.7rem',
                        flexSpan: 3,
                      },
                      {
                        key: 'performance',
                        value: typeof item.performance === 'number' ? `${(item.performance * 100).toFixed(0)} %` : '',
                      },
                      {
                        key: 'relevance',
                        value: <DifficultyIcon difficulty={getThemeIncidenceLevel(item.relevance || 0)} />,
                      },
                    ]}
                  />
                );
              })}
            </>
          )
        }
      </Box>
    </Box>
  );
}
