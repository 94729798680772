import { gql, QueryResult, useQuery } from '@apollo/client';
import { PreChoiceInterface } from './mock-exam';
import { Question } from './questions';

export interface SmartExamInterface {
  _id: string;
  profile: string;
  finished: boolean;
  preChoice: PreChoiceInterface[];
  createdAt: string;
  updatedAt: string;
  calendarDate: string;
  institution: string;
  uf: string;
  questions: Question[];
}

interface GetSmartExamActivityInput {
  _id: string;
}

interface GetSmartExamActivityOutput {
  smartExam: SmartExamInterface;
}

const GET_SMART_EXAM_ACTIVITY = gql`
  query GetSmartExamActivity ($_id: ObjectId!) {
    smartExam: getSmartExamActivity (_id: $_id) {
      _id
      profile
      finished
      preChoice {
        questionId
        selected
        essay
      }
      createdAt
      updatedAt
      calendarDate
      questions {
        _id
        body
        choices
        answersProportion
        correctChoice
        institution
        uf
        year
        examOrder
        examType
        complement
        classification
        difficulty
        createdAt
        updatedAt
        essay
        isEssay
        comment {
          author
          profile
          title
          body
          createdAt
          updatedAt
        }
        answer
      }
      institution
      uf
    }
  }
`;

export function useGetSmartExam({
  _id,
}: GetSmartExamActivityInput): QueryResult<
  GetSmartExamActivityOutput,
  GetSmartExamActivityInput
> {
  return useQuery<
    GetSmartExamActivityOutput,
    GetSmartExamActivityInput
  >(GET_SMART_EXAM_ACTIVITY, {
    variables: {
      _id,
    },
    fetchPolicy: 'network-only',
    skip: !_id,
  });
}
