const profileExpertises = [
  'Acupuntura',
  'Alergia e imunologia',
  'Anestesiologia',
  'Cirurgia cardiovascular',
  'Cirurgia geral',
  'Clínica médica',
  'Dermatologia',
  'Genética médica',
  'Ginecologia e obstetrícia',
  'Homeopatia',
  'Infectologia',
  'Medicina de família e comunidade',
  'Medicina de Emergência',
  'Medicina do trabalho',
  'Medicina do tráfego',
  'Medicina esportiva',
  'Medicina física e reabilitação',
  'Medicina intensiva',
  'Medicina legal',
  'Medicina nuclear',
  'Medicina preventiva e social',
  'Neurocirurgia',
  'Neurologia',
  'Nutrologia',
  'Oftalmologia',
  'Ortopedia e traumatologia',
  'Otorrinolaringologia',
  'Patologia',
  'Patologia Clínica/Medicina Laboratorial',
  'Pediatria',
  'Psiquiatria',
  'Radiologia e Diagnóstico por imagem',
  'Radioterapia',
];

export default profileExpertises;
