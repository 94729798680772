import { FC } from 'react';
import { Box, BoxProps, Flex, Image, Text } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';

interface RankCardProps extends BoxProps {
  name: string;
  rank: number;
  team: string;
  img?: string;
  pontuation: number;
}

const RankCard: FC<RankCardProps> = ({
  name, rank, team, pontuation, img, ...rest
}) => {
  return (
    <Flex
      direction="column"
      p={1}
      borderRadius={8}
      flex={1}
      w={250}
      maxW={200}
      minH={200}
      {...rest}
    >
      <Flex bgColor="rgba(0,0,0,0.25)" borderRadius={8} px={2} py={1}>
        <Text fontSize="sm" color="light.50" bold>{`${rank}º lugar`}</Text>
        <Box boxSize="20px" ml="auto">
          <Image src={img} />
        </Box>
      </Flex>
      <Flex flex={1} direction="column" justify="center" px={1}>
        <Box textAlign="center" my={2} color="light.50">
          <FontAwesomeIcon icon={faUserCircle} size="3x" />
        </Box>
        <Text color="light.50" textAlign="center">{name}</Text>
        <Text color="light.50" fontSize="sm" textAlign="center">{team}</Text>
        <Text as="h2" fontSize="2xl" fontWeight="bold" color="light.50" textAlign="center">
          {pontuation.toFixed(0)}
        </Text>
      </Flex>
    </Flex>
  );
};

export default RankCard;
