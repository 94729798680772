import { Box, SkeletonCircle, SkeletonText } from '@chakra-ui/react';
import { FC } from 'react';

export const SkeletonLoading: FC = () => {
  return (
    <Box px="20%" py="2rem" w="100%">
      <SkeletonCircle size="10" />
      <SkeletonText mt="4" noOfLines={4} spacing="4" />
    </Box>
  );
};
