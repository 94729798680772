import {
  Box, HStack, Menu, MenuButton,
  MenuDivider, MenuGroup, MenuItem, MenuList, Text, Tooltip,
} from '@chakra-ui/react';
import { faCheck, faSort } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC } from 'react';
import { useDefaultStyles } from '../../../../hooks/useDefaultStyles';
import { OrderOptionType } from '../../types/types';

export type OrderSelectorType = {
  onOptionClick: (sortBy: OrderOptionType) => void;
  selectedOrderOption: OrderOptionType;
}

export const OrderSelector: FC<OrderSelectorType> = ({
  onOptionClick, selectedOrderOption,
}: OrderSelectorType) => {
  const { colors } = useDefaultStyles();

  return (
    <Menu autoSelect={false}>
      <Tooltip label="Selecionar ordem" hasArrow>
        <MenuButton
          p={2}
          color={colors.secondary.keep}
        >
          <FontAwesomeIcon icon={faSort} />
        </MenuButton>
      </Tooltip>
      <MenuList>
        <MenuGroup
          color={colors.secondary.keep}
          title="Ordenar por"
        >
          <MenuDivider />
          <MenuItem
            onClick={() => onOptionClick('relevance')}
          >
            <HStack>
              <Text>Relevância</Text>
              <Box color={colors.green.keep}>
                {
                  selectedOrderOption === 'relevance' && (
                    <FontAwesomeIcon icon={faCheck} />
                  )
                }
              </Box>
            </HStack>
          </MenuItem>
          <MenuItem
            onClick={() => onOptionClick('date')}
          >
            <HStack>
              <Text>Mais novos</Text>
              <Box color={colors.green.keep}>
                {
                  selectedOrderOption === 'date' && (
                    <FontAwesomeIcon icon={faCheck} />
                  )
                }
              </Box>
            </HStack>
          </MenuItem>
        </MenuGroup>
      </MenuList>
    </Menu>
  );
};
