import { Box, Button, Flex, HStack, Text, Tooltip } from '@chakra-ui/react';
import { faArrowLeft, faUser, faUsers } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useDefaultStyles } from '../../../../hooks/useDefaultStyles';
import { FlashcardsCreationContext } from '../../contexts/FlashcardsCreation.context';

export const GoBack: FC = () => {
  const { lessonName, pathname, currLessonId } = useContext(FlashcardsCreationContext);
  const history = useHistory();
  const { colors } = useDefaultStyles();

  return (
    <Flex justify="flex-start" w="100%" ml={4} mt={4} fontSize="xl">
      <HStack spacing={1}>
        <Button
          onClick={() => history.push(pathname)}
          variant="ghost"
        >
          <FontAwesomeIcon icon={faArrowLeft} />
        </Button>
        <HStack fontSize={{ base: 'md', md: 'xl' }}>
          <Text noOfLines={1} color={colors.secondary.keep} fontWeight="bold">
            {currLessonId === 'todos' ? 'Todos' : lessonName}
          </Text>
          <Tooltip label={pathname.includes('comunidade') ? 'Da comunidade' : 'Seu baralho'}>
            <Box color={colors.secondary.keep}>
              <FontAwesomeIcon icon={pathname.includes('comunidade') ? faUsers : faUser} />
            </Box>
          </Tooltip>
        </HStack>
      </HStack>
    </Flex>
  );
};
