import { Box, BoxProps, Center } from '@chakra-ui/react';
import { FC, useMemo } from 'react';
import { useDefaultStyles, useRGBColor } from '../../../hooks/useDefaultStyles';

interface FlashcardIconType extends BoxProps {
  color?: string,
  hoverColor?: string,
  bgColor?: string,
  hoverBgColor?: string,
  size?: number,
  isMouseOver?: boolean,
  transition?: string,
}

export const FlashcardIcon: FC<FlashcardIconType> = ({
  color,
  hoverColor,
  size = 16,
  bgColor,
  hoverBgColor,
  isMouseOver = false,
  transition,
  ...props
}: FlashcardIconType) => {
  const { colors } = useDefaultStyles();
  const { toRGB } = useRGBColor();
  const currColor = useMemo(() => {
    if (isMouseOver && hoverColor) {
      return toRGB(hoverColor);
    }
    if (color) {
      return toRGB(color);
    }
    return 'rgb(126,135,138)';
  }, [color, hoverColor, isMouseOver, toRGB]);

  const currBgColor = useMemo(() => {
    if (isMouseOver && hoverBgColor) {
      return hoverBgColor;
    } return bgColor || colors.background;
  }, [bgColor, colors.background, hoverBgColor, isMouseOver]);

  return (
    <Center
      position="relative"
      {...props}
    >
      <Box
        position="absolute"
        w={`${0.75 * size}px`}
        h={`${size}px`}
        border="2px solid"
        borderColor={currColor}
        boxShadow={`0 0 1px 0px ${currColor} inset,
         0 0 1px 0px ${currColor}`}
        bgColor={currBgColor}
        borderRadius={2}
        transition={transition}
        zIndex={1}
      />
      <Box
        position="absolute"
        transform={`rotate(-15deg) translateX(-${0.3 * size}px)`}
        w={`${0.75 * size}px`}
        h={`${size}px`}
        border="2px solid"
        borderColor={currColor}
        borderRight="none"
        boxShadow={`0 0 1px 0px ${currColor} inset,
         0 0 1px 0px ${currColor}`}
        bgColor={currBgColor}
        borderRadius={2}
        transition={transition}
      />
      <Box
        position="absolute"
        transform={`rotate(15deg) translateX(${0.3 * size}px)`}
        w={`${0.75 * size}px`}
        h={`${size}px`}
        border="2px solid"
        borderColor={currColor}
        borderLeft="none"
        boxShadow={`0 0 1px 0px ${currColor} inset,
         0 0 1px 0px ${currColor}`}
        bgColor={currBgColor}
        borderRadius={2}
        transition={transition}
      />
    </Center>
  );
};
