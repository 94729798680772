export const timeOver = (startedAt?: Date, timeToDo?: number) => {
  if (startedAt && timeToDo) {
    const started = new Date(startedAt);
    const millisec = started.getTime();
    const until = new Date(millisec + timeToDo);
    if (until < new Date()) {
      return true;
    }
  }
  return false;
};
