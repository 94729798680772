import { Flex, FlexProps, useColorMode } from '@chakra-ui/react';
import { FC, ReactNode, useEffect } from 'react';
import { useDefaultStyles, useRGBColor } from '../../hooks/useDefaultStyles';
import { AristoCompassFooter } from './_components/Footer';
import { AristoCompassHeader } from './_components/Header';

interface AristoCompassTemplateType extends FlexProps {
  children?: ReactNode;
  active?: boolean;
}

export const AristoCompassTemplate: FC<AristoCompassTemplateType> = ({
  children,
  active = true,
  ...props
}) => {
  const { colors } = useDefaultStyles();
  const { toRGB } = useRGBColor();
  const rgbLightSecondary = toRGB(colors.lighter.keep);
  const rgbWhite = toRGB(colors.white.keep);
  const { colorMode, toggleColorMode } = useColorMode();

  useEffect(() => {
    if (colorMode === 'dark') {
      toggleColorMode();
    }
  }, [colorMode, toggleColorMode]);

  if (!active) {
    return <>{children}</>;
  }

  return (
    <Flex
      flexFlow="column"
      backgroundImage={`url(/images/aristo-compass/bg_white_logos.png),
      linear-gradient(${rgbWhite} 0%, ${rgbLightSecondary} 50%, ${rgbLightSecondary} 100%)`}
      backgroundAttachment="fixed, fixed"
      backgroundSize="cover, cover"
      w="100%"
      minH="100vh"
    >
      <AristoCompassHeader />
      <Flex w="100%" flexFlow="column" flex={1} {...props}>
        {children}
      </Flex>
      <AristoCompassFooter />
    </Flex>
  );
};
