import { Button, HStack, Text } from '@chakra-ui/react';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC } from 'react';
import { PerformanceMockControlsType } from '../sections/WeekGoals';

type MockTrophyControlsInputType = {
  setMock: React.Dispatch<React.SetStateAction<PerformanceMockControlsType>>,
}

export const MockTrophyControls: FC<MockTrophyControlsInputType> = ({
  setMock,
}) => {
  return (
    <HStack>
      <Text>ET</Text>
      <Button
        onClick={() => setMock(prev => ({ ...prev, ET: prev.ET - 1 }))}
      >
        <FontAwesomeIcon icon={faMinus} />
      </Button>
      <Button
        onClick={() => setMock(prev => ({ ...prev, ET: prev.ET + 1 }))}
      >
        <FontAwesomeIcon icon={faPlus} />
      </Button>
      <Text>RI</Text>
      <Button
        onClick={() => setMock(prev => ({ ...prev, RI: prev.RI - 1 }))}
      >
        <FontAwesomeIcon icon={faMinus} />
      </Button>
      <Button
        onClick={() => setMock(prev => ({ ...prev, RI: prev.RI + 1 }))}
      >
        <FontAwesomeIcon icon={faPlus} />
      </Button>
      <Text>RT</Text>
      <Button
        onClick={() => setMock(prev => ({ ...prev, RT: prev.RT - 1 }))}
      >
        <FontAwesomeIcon icon={faMinus} />
      </Button>
      <Button
        onClick={() => setMock(prev => ({ ...prev, RT: prev.RT + 1 }))}
      >
        <FontAwesomeIcon icon={faPlus} />
      </Button>
      <Text>P</Text>
      <Button
        onClick={() => setMock(prev => ({ ...prev, P: prev.P - 1 }))}
      >
        <FontAwesomeIcon icon={faMinus} />
      </Button>
      <Button
        onClick={() => setMock(prev => ({ ...prev, P: prev.P + 1 }))}
      >
        <FontAwesomeIcon icon={faPlus} />
      </Button>
    </HStack>
  );
};
