import React, { FC, useContext, useMemo } from 'react';
import FavoritesEmpty from '../types/FavoritesEmpty';
import AgendaFlashcards from '../types/AgendaFlashcards';
import { Flashcard } from '../../../api/agenda/flashcards';
import { useFlashcardManyFavorite } from '../../../api/favorites';
import { SkeletonLoading } from '../../_components/SkeletonLoading';
import { GlobalContext } from '../../../Global.context';
import FavoritesSprintEmpty from '../types/FavoritesSprintEmpty';

const FavoriteFlashcards: FC = () => {
  const { data, loading } = useFlashcardManyFavorite();
  const { isSprintCourse } = useContext(GlobalContext);

  const flashcards = useMemo(() => {
    return data?.flashcardManyFavorite || [] as Flashcard[];
  }, [data?.flashcardManyFavorite]);

  if (loading) {
    return (
      <SkeletonLoading />
    );
  }

  if (flashcards.length > 0) {
    return (
      <AgendaFlashcards
        data={flashcards}
      />
    );
  }

  if (isSprintCourse) {
    return (
      <FavoritesSprintEmpty />
    );
  }

  return (
    <FavoritesEmpty />
  );
};

export default FavoriteFlashcards;
