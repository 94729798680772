import { Box, Flex, Text } from '@chakra-ui/react';
import { FC } from 'react';
import { useDefaultStyles } from '../../../hooks/useDefaultStyles';
import AgendaFlashcards from '../../agenda/types/AgendaFlashcards';
import { AristoCompassTemplate } from '../template';
import FlashcardsMock from '../_mocks/FlashcardsMock.json';

export const CompassFlashcardsPage: FC = () => {
  const { cardBorder, colors } = useDefaultStyles();
  return (
    <AristoCompassTemplate>
      <Flex
        w="100%"
        p={{ md: 10 }}
        justifyContent="center"
        alignItems="center"
        style={{ gap: 35 }}
        flexFlow="column"
      >
        <Box maxW={900} w="100%">
          <AgendaFlashcards
            showTopButtons={false}
            data={FlashcardsMock.flashcards}
          />
        </Box>
        <Box
          bgColor={colors.white.keep}
          {...cardBorder}
          w={{ base: '80%', md: '50%' }}
          fontWeight="bold"
          align={{ base: 'flex-start', md: 'center' }}
          mb={4}
        >
          <Flex
            w="100%"
            as="button"
            flexFlow={{ base: 'column', xl: 'row' }}
            align="center"
            justify="center"
            p={4}
            onClick={() => window.open('https://aristoclass.com.br/registrar')}
          >
            <Text display="inline">
              Gostou do que viu? Tenha acesso a isso e muito mais na nossa plataforma!
            </Text>
            <Text
              fontWeight="bold"
              color={colors.secondary.keep}
              ml={2}
            >
              Faça o teste grátis!
            </Text>
          </Flex>

        </Box>
      </Flex>
    </AristoCompassTemplate>
  );
};
