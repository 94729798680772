import React, {
  FC, useContext, useMemo,
} from 'react';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Box } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Route, Switch } from 'react-router-dom';
import { getActivityIndex } from '../../../hooks/getActivityIndex';
import Flex from '../../../lib/components/Flex';
import { AgendaContext } from '../Agenda.context';
import MenuTabs from '../../../lib/components/MenuTabs';
import ExtraSmartReviewMain from './_components/ExtraSmartReviewMain';
import { ActivitySmartReviewExtra } from '../../../api/extra-activities/extra-activities';
import { ExtraSmartReviewStorage } from './contexts/ExtraSmartReview.context';
import ExtraSmartReviewQuestion from './_components/ExtraSmartReviewQuestion';
import ExtraSmartReviewFlashcard from './_components/ExtraSmartReviewFlashcard';
import { ExtraHeader } from './_components/ExtraHeader';

const ExtraSmartReviewContainer: FC = () => {
  const { extras, extrasLoading } = useContext(AgendaContext);

  const id = getActivityIndex();

  const activity: ActivitySmartReviewExtra = useMemo(() => {
    const qs = extras.activitySmartReviewExtra;

    const index = qs?.findIndex(x => x._id === id) || 0;

    if (qs && qs.length > 0 && index !== -1) {
      return qs[index];
    }

    if (qs && qs.length > 0) {
      return qs[0];
    }

    return {
      _id: '',
      flashcards: [],
      questions: [],
      finished: false,
    };
  }, [extras.activitySmartReviewExtra, id]);

  if (extrasLoading) {
    return (
      <Flex align="center" justify="center" style={{ minHeight: 400 }}>
        <FontAwesomeIcon icon={faSpinner} spin size="6x" className="color-dark-gray" />
      </Flex>
    );
  }

  return (
    <Box maxW={900} m="0 auto">
      <ExtraHeader
        title="Revisão inteligente [Extra]"
      />
      <ExtraSmartReview data={activity} />
    </Box>
  );
};

interface ExtraSmartReviewProps {
  data: ActivitySmartReviewExtra;
}

const ExtraSmartReview: FC<ExtraSmartReviewProps> = ({ data }) => {
  const menuItems = useMemo(() => {
    return ({
      questao: Boolean(data.questions && data.questions.length > 0),
      flashcard: Boolean(data.flashcards && data.flashcards.length > 0),
      video: false,
      apostila: false,
      'mapa-mental': false,
    });
  }, [data]);

  return (
    <>
      <MenuTabs
        menuItems={menuItems}
        path="/atividades-extras/revisao-inteligente"
      />
      <ExtraSmartReviewStorage>
        <Switch>
          <Route
            exact
            path="/atividades-extras/revisao-inteligente"
            render={() => <ExtraSmartReviewMain menuItems={menuItems} context="revisao-inteligente" />}
          />
          <Route
            path="/atividades-extras/revisao-inteligente/questao"
            render={() => (
              <ExtraSmartReviewQuestion
                id={data._id}
                ids={data.questions}
                finished={data.finished}
                preChoice={data.preChoice}
              />
            )}
          />
          <Route
            path="/atividades-extras/revisao-inteligente/flashcard"
            render={() => <ExtraSmartReviewFlashcard id={data._id} ids={data.flashcards} finished={data.finished} />}
          />
        </Switch>
      </ExtraSmartReviewStorage>
    </>
  );
};

export default ExtraSmartReviewContainer;
