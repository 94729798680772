import { HStack, Square, Text, Tooltip } from '@chakra-ui/react';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC } from 'react';
import { GetActivityExamsHistory } from '../../../api/exams-history/exams-history';
import { GetActivityMockExamsHistory } from '../../../api/exams-history/mock-exams-history';
import { GetActivitySmartExamsHistory } from '../../../api/exams-history/smart-exams-history';
import { useDefaultStyles } from '../../../hooks/useDefaultStyles';

interface TextWithTooltipProps {
  activity: GetActivityExamsHistory | GetActivitySmartExamsHistory | GetActivityMockExamsHistory,
  extraTag: 'ActivityExamExtra' | 'ActivitySmartExamExtra' | 'ActivityMockExamExtra',
  finished?: boolean,
}

export const TextWithTooltip: FC<TextWithTooltipProps> = ({ activity, extraTag, finished }: TextWithTooltipProps) => {
  const { colors } = useDefaultStyles();
  return (
    <HStack justify="center">
      <Text>
        {activity.grade ? Math.round(activity.grade * 100).toFixed() : '-'}
        &nbsp;%
      </Text>
      {activity._t === extraTag && (
        <Tooltip label="Feito como atividade extra">
          <Square size="20px" color={colors.secondary.goALittleDarker}>
            <FontAwesomeIcon icon={faInfoCircle} className="mx-1" />
          </Square>
        </Tooltip>
      )}
    </HStack>
  );
};
