import { FC, useMemo, useState } from 'react';
import {
  Button,
  HStack,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Switch,
  Text,
  VStack,
} from '@chakra-ui/react';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAddUserToExperiment, useEnabledDisplayButtonABExperiment } from '../../api/platform-experiment';
import { toast } from '../../utils/toast';
import { auth } from '../../client/firebase';
import { useDefaultStyles } from '../../hooks/useDefaultStyles';
import { Divider } from '../../lib/components/Divider';
import { PrimaryButton } from '../../lib/components/PrimaryButton';

export const PlatformExperimentButton: FC = () => {
  const [isActive, setIsActive] = useState(false);
  const [open, setOpen] = useState(false);
  const { modal } = useDefaultStyles();

  const { currentUser } = auth;

  const { handleAddUserToExperiment } = useAddUserToExperiment();
  const { data, loading, error } = useEnabledDisplayButtonABExperiment();

  const showButton = useMemo(() => {
    if (data && !error) {
      return data.enabledDisplayButtonABExperiment;
    }
    return false;
  }, [data, error]);

  const handleConfirm = async (): Promise<void> => {
    const externalRedirectUrl = process.env.REACT_APP_FLUTTER_URL;

    try {
      const userToken = await currentUser?.getIdToken();
      if (userToken && externalRedirectUrl) {
        await handleAddUserToExperiment();
        const fullRedirectUrl = `${externalRedirectUrl}/#/external-auth?userToken=${userToken}`;
        window.location.href = fullRedirectUrl;
      }
    } catch (e) {
      toast.error({
        description: 'Não foi possível mudar de versão!',
      });
      setOpen(false);
      setIsActive(false);
    }
  };

  const handleCancel = (): void => {
    setIsActive(!isActive);
    setOpen(!open);
  };

  if (!showButton || loading) {
    return (<></>);
  }

  return (
    <>
      <Modal isOpen={open} onClose={handleCancel} isCentered closeOnOverlayClick={false}>
        <ModalOverlay />
        <ModalContent style={{ maxWidth: '380px' }} borderRadius="16px" overflow="hidden">
          <ModalBody style={{ padding: '20px' }} bgColor={modal.body.bgColor}>
            <VStack spacing="24px">
              <HStack align="flex-start">
                <Text flex={1} fontSize="22px" fontWeight="bold">
                  Experimente a nova versão da Aristo Class
                </Text>
                <IconButton
                  aria-label="close"
                  variant="ghost"
                  onClick={handleCancel}
                >
                  <FontAwesomeIcon icon={faTimes} size="lg" />
                </IconButton>
              </HStack>
              <Text>
                Você pode voltar à versão atual a qualquer momento.
              </Text>
              <VStack w="100%" spacing="8px" align="center">
                <PrimaryButton
                  style={{ borderRadius: '50px' }}
                  w="100%"
                  onClick={handleConfirm}
                >
                  Experimentar nova versão
                </PrimaryButton>
                <Button
                  style={{ borderRadius: '50px' }}
                  variant="ghost"
                  w="100%"
                  onClick={handleCancel}
                >
                  Continuar com a versão atual
                </Button>
              </VStack>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
      <Divider my={2} />
      <HStack spacing="16px" align="center" px={2}>
        <Text>
          Experimente a nova versão da Aristo Class
        </Text>
        <Switch
          isChecked={isActive}
          onChange={_ => handleCancel()}
        />
      </HStack>
      <Divider my={2} />
    </>
  );
};
