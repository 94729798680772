import { gql, QueryResult, useQuery } from '@apollo/client';

export interface GetActivitySmartExamsHistory {
  _id: string;
  institution: string;
  uf: string;
  grade: number;
  _t: string;
  calendarDate: string;
}

interface GetActivitySmartExamsHistoryOutput {
  getActivitySmartExamsHistory: GetActivitySmartExamsHistory[];
}

const GET_ACTIVITY_SMART_EXAMS_HISTORY = gql`
  query GetActivitySmartExamsHistory {
    getActivitySmartExamsHistory {
      _id
      institution
      uf
      grade
      _t
      calendarDate
    }
  }
`;

export function useGetSmartExamsHistory(): QueryResult<GetActivitySmartExamsHistoryOutput> {
  return useQuery<GetActivitySmartExamsHistoryOutput>(GET_ACTIVITY_SMART_EXAMS_HISTORY);
}
