import { FC, useState, useCallback, ChangeEvent, useMemo, useEffect } from 'react';
import {
  Box, Text, Input, InputGroup, InputLeftElement, Flex, useMediaQuery, Link, ChakraProvider, ColorModeScript,
} from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faUser } from '@fortawesome/free-regular-svg-icons';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import { PrimaryButton } from '../../lib/components/PrimaryButton';
import { useLanding } from '../../api/landing';
import { theme } from '../../lib/theme';

const key = '@Aristo/landing-contact';

const appTheme = theme({
  initialColorMode: 'light',
  useSystemColorMode: false,
}, {
  components: {
    Input: {
      baseStyle: {
        field: {
          _placeholder: { color: 'gray.400' },
          borderColor: 'blue.500',
          _hover: { borderColor: 'blue.500' },
          _focus: { borderColor: 'blue.500' },
        },
      },
    },
  },
});

const LandingLogin: FC = () => {
  const history = useHistory();

  const [showBgImage] = useMediaQuery('(min-width: 1260px)');
  const { onCreateContact } = useLanding();

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [loading, setLoading] = useState(false);

  const isNameValid = useMemo(() => {
    return name.length >= 3;
  }, [name.length]);

  const isPhoneValid = useMemo(() => {
    return phone.length >= 14;
  }, [phone.length]);

  const isEmailValid = useMemo(() => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }, [email]);

  const disabled = useMemo(() => {
    return !isNameValid || !isPhoneValid || !isEmailValid;
  }, [isEmailValid, isNameValid, isPhoneValid]);

  const handlePhone = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    let input = e.target.value.replace(/\D/g, '');

    if (input.length <= 10) {
      input = input.replace(/(\d{2})(\d{4})(\d{0,4})/, '($1) $2-$3');
    } else {
      input = input.replace(/(\d{2})(\d{1})(\d{4})(\d{0,4})/, '($1) $2 $3-$4');
    }

    setPhone(input.trim());
  }, []);

  const onClick = useCallback(async () => {
    setLoading(true);
    try {
      await onCreateContact({
        name,
        email,
        phone,
      });
      localStorage.setItem(key, JSON.stringify({
        name,
        email,
        phone,
      }));
      history.push('/landing/recursos');
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }, [email, history, name, onCreateContact, phone]);

  useEffect(() => {
    const localContact = localStorage.getItem(key);

    if (localContact) {
      history.push('/landing/recursos');
    }
  }, [history]);

  return (
    <ChakraProvider theme={appTheme}>
      <ColorModeScript initialColorMode={appTheme.config.initialColorMode} />
      <div
        style={{
          backgroundImage: showBgImage ? 'url(/images/landing/cover.png)' : '',
          backgroundPosition: 'left',
          backgroundRepeat: 'no-repeat',
          minHeight: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: '#191C20',
          backgroundColor: '#fff',
        }}
      >
        <Box w="470px" p={4}>
          <Text fontSize={24} mb={1} lineHeight={1.3}>Cadastre-se para acessar os recursos</Text>
          <Text fontSize={14} mb={6}>
            Insira seu nome, e-mail e telefone para acessar gratuitamente os recursos das questões da sua instituição.
          </Text>
          <InputGroup mb={4}>
            <InputLeftElement pointerEvents="none">
              <FontAwesomeIcon icon={faUser} color="gray.400" />
            </InputLeftElement>
            <Input
              placeholder="Nome completo"
              value={name}
              onChange={e => setName(e.target.value)}
            />
          </InputGroup>
          <InputGroup mb={4}>
            <InputLeftElement pointerEvents="none">
              <FontAwesomeIcon icon={faEnvelope} color="gray.400" />
            </InputLeftElement>
            <Input
              placeholder="Email"
              type="email"
              value={email}
              onChange={e => setEmail(e.target.value)}
            />
          </InputGroup>
          <InputGroup mb={6}>
            <InputLeftElement pointerEvents="none">
              <FontAwesomeIcon icon={faPhone} color="gray.400" />
            </InputLeftElement>
            <Input
              placeholder="Número de telefone"
              value={phone}
              onChange={handlePhone}
              maxLength={16}
            />
          </InputGroup>
          <PrimaryButton
            size="lg"
            borderRadius={8}
            w="100%"
            mb={4}
            onClick={onClick}
            disabled={loading || disabled}
          >
            Acessar recursos
          </PrimaryButton>
          <Text fontSize={14} color="#75777E">
            Nós respeitamos sua privacidade.
            Seus dados serão usados apenas para o acesso aos recursos e não serão compartilhados com terceiros.
          </Text>
          <Text fontSize={14} color="#75777E" mb={4}>
            Ao preencher este formulário e acessar os recursos,
            você concorda com nossa Política de Privacidade e Termos de Uso.
          </Text>
          <Flex>
            <Link href="https://pages.aristo.com.br/politicas-de-privacidade/" isExternal mr={4}>
              <Text fontSize={14} fontWeight={500}>Política de Privacidade</Text>
            </Link>
            <Link href="https://pages.aristo.com.br/termos-de-uso/" isExternal>
              <Text fontSize={14} fontWeight={500}>Termos de uso</Text>
            </Link>
          </Flex>
        </Box>
      </div>
    </ChakraProvider>
  );
};

export default LandingLogin;
