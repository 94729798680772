import { Flex } from '@chakra-ui/react';
import { FC, useState } from 'react';
import { AristoCompassBanner } from './_components/Banner';
import { InfoCardContainer } from './_components/InfoCardContainer';
import { InstitutionSelectionPage } from './pages/InstitutionSelectionPage';
import { AristoCompassTemplate } from './template';

export const AristoCompass: FC = () => {
  const [currPage, setCurrPage] = useState(0);

  return (
    <AristoCompassTemplate>
      {
        currPage === 0 && (
          <>
            <AristoCompassBanner setCurrPage={setCurrPage} />
            <InfoCardContainer />
          </>
        )
      }
      {
        currPage === 1 && (
          <Flex w="100%" px={5}>
            <InstitutionSelectionPage setCurrentPage={setCurrPage} />
          </Flex>
        )
      }
      {/* {
        currPage === 2 && (
          <StatisticsPage />
        )
      } */}
    </AristoCompassTemplate>
  );
};
