import { useEffect, useState } from 'react';
import { ref, get, child } from 'firebase/database';
import { database } from '../client/firebase';

export function useOnline(refetch: boolean): number {
  const [onlineUsers, setOnlineUsers] = useState<number>();

  useEffect(() => {
    get(child(ref(database), '/online/')).then(status => {
      const statusVal = status.val();
      if (statusVal !== undefined) {
        setOnlineUsers(statusVal);
      }
    });
  }, [refetch]);

  return onlineUsers ?? 0;
}
