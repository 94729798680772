import {
  FC, useCallback, useContext, useMemo, useState,
} from 'react';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Route, Switch } from 'react-router-dom';
import { Box, Text } from '@chakra-ui/react';
import { getActivityIndex } from '../../../hooks/getActivityIndex';
import Flex from '../../../lib/components/Flex';
import { AgendaContext } from '../Agenda.context';
import MenuTabs from '../../../lib/components/MenuTabs';
import ExtraTheoreticalQuestion from './_components/ExtraTheoreticalQuestion';
import ExtraTheoreticalFlashcard from './_components/ExtraTheoreticalFlashcard';
import ExtraTheoreticalMain from './_components/ExtraTheoreticalMain';
import ExtraTheoreticalMindmap from './_components/ExtraTheoreticalMindmap';
import ExtraTheoreticalText from './_components/ExtraTheoreticalText';
import ExtraTheoreticalVideo from './_components/ExtraTheoreticalVideo';
import { ActivityTheoreticalExtra } from '../../../api/extra-activities/extra-activities';
import { useExtraTheoreticalReview } from '../../../api/extra-activities/extra-theoretical-review';
import { ExtraHeader } from './_components/ExtraHeader';
import { FinishLessonButton } from '../_components/FinishLessonButton';
import { useDefaultStyles } from '../../../hooks/useDefaultStyles';
import { isTodayExtended } from '../../../utils/isTodayExtended';
// import { ForumWrapper } from '../_components/ForumWrapper';

const ExtraTheoreticalReviewContainer: FC = () => {
  const { extras, extrasLoading } = useContext(AgendaContext);

  const id = getActivityIndex();

  const activity: ActivityTheoreticalExtra = useMemo(() => {
    const qs = extras.activityTheoreticalReviewExtra;

    const index = qs?.findIndex(x => x._id === id) || 0;

    if (qs && qs.length > 0 && index !== -1) {
      return qs[index];
    }

    if (qs && qs.length > 0) {
      return qs[0];
    }

    return {
      _id: '',
      classification: [],
      contents: {
        videos: [],
        texts: [],
        mindmaps: [],
        flashcards: [],
        questions: [],
      },
      lessonId: '',
      finished: false,
    };
  }, [extras.activityTheoreticalReviewExtra, id]);

  if (extrasLoading) {
    return (
      <Flex align="center" justify="center" style={{ minHeight: 400 }}>
        <FontAwesomeIcon icon={faSpinner} spin size="6x" className="color-dark-gray" />
      </Flex>
    );
  }

  return (
    <Box maxW={900} m="0 auto">
      <ExtraHeader
        title="Revisão teórica [Extra]"
      />
      <ExtraTheoreticalReview data={activity} />
    </Box>
  );
};

interface ExtraTheoreticalReviewProps {
  data: ActivityTheoreticalExtra;
}

const ExtraTheoreticalReview: FC<ExtraTheoreticalReviewProps> = ({ data }) => {
  const [loading, setLoading] = useState(false);
  const { colors } = useDefaultStyles();

  const menuItems = useMemo(() => {
    return ({
      questao: Boolean(data.contents.questions && data.contents.questions.length > 0),
      video: Boolean(data.contents.videos && data.contents.videos.length > 0),
      apostila: Boolean(data.contents.texts && data.contents.texts.length > 0),
      'mapa-mental': Boolean(data.contents.mindmaps && data.contents.mindmaps.length > 0),
      flashcard: Boolean(data.contents.flashcards && data.contents.flashcards.length > 0),
    });
  }, [data]);

  const { onFinishExtraTheoreticalReviewActivity } = useExtraTheoreticalReview();
  const { refetchExtras, percentOfQuestionsAnswered } = useContext(AgendaContext);

  const handleFinish = useCallback(async () => {
    setLoading(true);
    try {
      await onFinishExtraTheoreticalReviewActivity({
        classification: data.classification,
        id: data._id,
      });
      await refetchExtras();
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [data._id, data.classification, onFinishExtraTheoreticalReviewActivity, refetchExtras]);

  return (
    <>
      <Flex align="center" justify="space-between">
        <Text>{data.classification[1]}</Text>
        {
          (isTodayExtended() || data.finished) && (
            <FinishLessonButton
              isTheoreticalReview
              unfinishedText="Concluir revisão"
              finishedText="Revisão concluída"
              isFinished={data.finished}
              isConfirmDisabled={percentOfQuestionsAnswered !== 1}
              modalTitle="Concluir revisão"
              onConfirm={handleFinish}
            >
              {
                percentOfQuestionsAnswered !== 1 ? (
                  <>
                    <Text color={colors.red.keep} textAlign="center">
                      Faça no mínimo 10 questões para concluir.
                    </Text>
                    <Text textAlign="center">
                      Assim, poderemos atestar que você está bem no tema, evitindo repetições nas RTs.
                    </Text>
                  </>
                ) : (
                  <Text>
                    Tem certeza que deseja concluir essa revisão? Esse processo é irreversível.
                  </Text>

                )
              }
            </FinishLessonButton>
          )
        }
      </Flex>
      <MenuTabs
        menuItems={menuItems}
        path="/atividades-extras/revisao-teorica"
      />
      <Switch>
        <Route
          exact
          path="/atividades-extras/revisao-teorica"
          render={() => <ExtraTheoreticalMain menuItems={menuItems} context="revisao-teorica" />}
        />
        <Route path="/atividades-extras/revisao-teorica/questao">
          <ExtraTheoreticalQuestion
            id={data._id}
            ids={data.contents.questions}
          />
        </Route>
        <Route path="/atividades-extras/revisao-teorica/video">
          <ExtraTheoreticalVideo
            ids={data.contents.videos}
            onFinish={handleFinish}
            loading={loading}
            finished={data.finished}
          />
        </Route>
        <Route path="/atividades-extras/revisao-teorica/apostila">
          {/* <ForumWrapper
            key="ParetoGuide"
            forumId={data.lessonId}
            forumTag={data.classification.join('|')}
            contentType="ParetoGuide"
            classification={data.classification}
          > */}
          <ExtraTheoreticalText
            ids={data.contents.texts}
            onFinish={handleFinish}
            loading={loading}
            finished={data.finished}
          />
          {/* </ForumWrapper> */}
        </Route>
        <Route path="/atividades-extras/revisao-teorica/mapa-mental">
          {/* <ForumWrapper
            key="Mindmap"
            forumId={data.lessonId}
            forumTag={data.classification.join('|')}
            contentType="MindMap"
            classification={data.classification}
          > */}
          <ExtraTheoreticalMindmap
            ids={data.contents.mindmaps}
            onFinish={handleFinish}
            loading={loading}
            finished={data.finished}
          />
          {/* </ForumWrapper> */}

        </Route>
        <Route path="/atividades-extras/revisao-teorica/flashcard">
          <ExtraTheoreticalFlashcard
            id={data._id}
            ids={data.contents.flashcards}
          />
        </Route>
      </Switch>
    </>
  );
};

export default ExtraTheoreticalReviewContainer;
