import { gql, useMutation, useQuery } from '@apollo/client';
import { useCallback } from 'react';
import { toast } from '../utils/toast';

export interface DeckType {
  lesson?: string;
  lessonTitle: string;
  _id?: string;
  flashcards: string[];
  flashcardsAmount: number;
}

interface GetCommunityDecksOutput {
  getCommunityDecks: DeckType[];
}

const GET_COMMUNITY_DECKS = gql`
  query GetCommunityDecks {
    getCommunityDecks {
      lesson
      lessonTitle
      flashcards
      flashcardsAmount
    }
  }
`;

export function useGetCommunityDecks() {
  return useQuery<GetCommunityDecksOutput>(GET_COMMUNITY_DECKS);
}

interface GetUserDecksOutput {
  getUserDecks: DeckType[];
}

const GET_USER_DECKS = gql`
  query GetUserDecks {
    getUserDecks {
      lesson
      _id
      lessonTitle
      flashcards
      flashcardsAmount
    }
  }
`;

export function useGetUserDecks() {
  const query = useQuery<GetUserDecksOutput>(GET_USER_DECKS);
  const _decks = query.data?.getUserDecks;

  const getUserDeckLengthByLessonId = useCallback(({ lessonId }: { lessonId: string }) => {
    if (_decks) {
      const filtered = _decks.filter(x => x.lesson === lessonId);
      if (filtered.length > 0) {
        return filtered[0].flashcardsAmount;
      }
    }
    return 0;
  }, [_decks]);

  return {
    userDecks: query,
    getUserDeckLengthByLessonId,
  };
}

const CREATE_USER_FLASHCARD = gql`
    mutation CreateUserFlashcard(
      $front: String!,
      $back: String!,
      $lessonId: ObjectId!,
    ) {
      createUserFlashcard(
        front: $front,
        back: $back,
        lessonId: $lessonId,
      )
    }
`;

type CreateUserFlashcardInputType = {
  front: string,
  back: string,
  lessonId: string,
}

type CreateUserFlashcardOutputType = {
  createUserFlashcard: {
    _id: string,
  }
}

const DELETE_USER_FLASHCARD = gql`
  mutation DeleteUserFlashcard(
    $_id: ObjectId!,
  ) {
    deleteUserFlashcard (
      _id: $_id,
    )
  }
`;

type DeleteUserFlashcardInputType = {
  _id: string,
}

type DeleteUserFlashcardOutputType = {
  deleteUserFlashcard: boolean,
}

export function useFlashcardsCRUD() {
  const [createFlashcard] = useMutation<
    CreateUserFlashcardOutputType, CreateUserFlashcardInputType
  >(CREATE_USER_FLASHCARD);
  const [deleteFlashcard] = useMutation<
    DeleteUserFlashcardOutputType, DeleteUserFlashcardInputType
  >(DELETE_USER_FLASHCARD);

  const handleCreateFlashcard = useCallback(async ({
    front,
    back,
    lessonId,
  }: CreateUserFlashcardInputType) => {
    try {
      const res = await createFlashcard({
        variables: {
          front,
          back,
          lessonId,
        },
      });
      if (res.data) {
        toast.success({
          title: 'Flashcard criado com sucesso!',
        });
        return res.data.createUserFlashcard._id;
      }
      throw new Error('Received a null response');
    } catch (e) {
      console.error(e);
      toast.error({
        title: 'Houve um problema ao criar o flashcard.',
        description: 'Verifique sua conexão e tente novamente.',
      });
      return undefined;
    }
  }, [createFlashcard]);

  const handleDeleteFlashcard = useCallback(async ({
    _id,
  }: DeleteUserFlashcardInputType) => {
    try {
      const res = await deleteFlashcard({
        variables: {
          _id,
        },
      });
      if (res.data) {
        return res.data.deleteUserFlashcard;
      }
      throw new Error('Received a null response');
    } catch (e) {
      console.error(e);
      toast.error({
        title: 'Houve um problema ao deletar o flashcard.',
        description: 'Verifique sua conexão e tente novamente.',
      });
      return undefined;
    }
  }, [deleteFlashcard]);

  return {
    handleCreateFlashcard,
    handleDeleteFlashcard,
  };
}

const ADD_TO_DECK = gql`
  mutation AddToDeck (
    $_id: ObjectId!
    ) {
    addToDeck (_id: $_id)
  }
`;

const REMOVE_FROM_DECK = gql`
  mutation RemoveFromDeck (
    $_id: ObjectId!
  ) {
    removeFromDeck (_id: $_id)
  }
`;

type AddOrRemoveFromDeckInputType = {
  _id: string,
};

export function useAddOrRemoveFlashcardFromDeck() {
  const [addToDeck] = useMutation<AddOrRemoveFromDeckInputType>(ADD_TO_DECK);
  const [removeFromDeck] = useMutation<AddOrRemoveFromDeckInputType>(REMOVE_FROM_DECK);

  const addFlashcardToDeck = useCallback(async ({
    _id,
  }: { _id: string }) => {
    try {
      await addToDeck({
        variables: {
          _id,
        },
      });
    } catch (e) {
      toast.error({
        title: 'Não foi possível adicionar ao seu baralho.',
        description: 'Verifique sua conexão e tente novamente.',
      });
    }
  }, [addToDeck]);

  const removeFlashcardFromDeck = useCallback(async ({
    _id,
  }: { _id: string }) => {
    try {
      await removeFromDeck({
        variables: {
          _id,
        },
      });
    } catch (e) {
      toast.error({
        title: 'Não foi possível remover do seu baralho.',
        description: 'Verifique sua conexão e tente novamente.',
      });
    }
  }, [removeFromDeck]);

  return {
    addFlashcardToDeck,
    removeFlashcardFromDeck,
  };
}
