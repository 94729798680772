import {
  gql, QueryResult, useQuery,
} from '@apollo/client';

export interface MockExamTemplateInfo {
  totalQuestions: number;
  studentScore: number;
  rankingGeneratedOn: Date;
  cutOffScore?: number;
  infoDescription?: string; // esse campo é para aquele texto que está destacado em azul no figma
  studentRanking?: number;
  banner?: MockExamTemplateBanner;
}

export interface MockExamTemplateBanner {
  imageUrl: string;
  link: string;
  title?: string;
  subtitle?: string;
}

interface MockExamTemplateInfoOutput {
  mockExamTemplateInfo: MockExamTemplateInfo;
}

const MOCK_EXAM_TEMPLATE_INFO = gql`
  query MockExamTemplateInfo($activityId: ObjectId!) {
    mockExamTemplateInfo(activityId: $activityId) {
      totalQuestions
      studentScore
      rankingGeneratedOn
      cutOffScore
      infoDescription
      studentRanking
      banner {
        imageUrl
        link
        title
        subtitle
      }
    }
  }
`;

export function useMockExamInfo(activityId: string, skip: boolean): QueryResult<MockExamTemplateInfoOutput> {
  return useQuery<MockExamTemplateInfoOutput>(MOCK_EXAM_TEMPLATE_INFO, {
    variables: {
      activityId,
    },
    skip,
  });
}

const STUDENT_PERFORMANCE_MOCK_EXAM = gql`
  query StudentPerformanceMockExam($activityId: ObjectId!) {
    studentPerformanceMockExam(activityId: $activityId) {
      area
      totalQuestionsByArea
      correct
      wrong
      average
      themas {
        leaf
        totalQuestionsByThema
        correct
      }
    }
  }
`;

interface MockExamStudentPerformanceByThemaObjectType {
  leaf: string;
  totalQuestionsByThema: number;
  correct: number;
}

export interface MockExamStudentPerformanceObjectType {
  area: string;
  totalQuestionsByArea: number;
  correct: number;
  wrong: number;
  average: number;
  themas: MockExamStudentPerformanceByThemaObjectType[];
}

export interface StudentPerformanceMockExamData {
  studentPerformanceMockExam: MockExamStudentPerformanceObjectType[];
}

export function useStudentPerformanceMockExam(
  activityId: string, skip: boolean,
): QueryResult<StudentPerformanceMockExamData> {
  return useQuery<StudentPerformanceMockExamData>(STUDENT_PERFORMANCE_MOCK_EXAM, {
    variables: {
      activityId,
    },
    skip,
  });
}

const MOCK_EXAM_QUESTIONS_BY_AREA = gql`
  query QuestionsByAreaMockExam($mockExamTemplateId: ObjectId!) {
    questionsByAreaMockExam(mockExamTemplateId: $mockExamTemplateId) {
      area
      questions
    }
  }
`;

export interface MockExamQuestionsByAreaObjectType {
  area: string;
  questions: number;
}

export interface MockExamQuestionsByAreaData {
  questionsByAreaMockExam: MockExamQuestionsByAreaObjectType[];
}

export function useMockExamQuestionsByArea(
  mockExamTemplateId: string, skip: boolean,
): QueryResult<MockExamQuestionsByAreaData> {
  return useQuery<MockExamQuestionsByAreaData>(MOCK_EXAM_QUESTIONS_BY_AREA, {
    variables: { mockExamTemplateId },
    skip,
  });
}
