import React, { FC, useContext } from 'react';
import { DeckCard, DELAY_INDEX } from '../Miniatures/MiniDeck';
import { FlashcardsCreationContext } from '../../contexts/FlashcardsCreation.context';
import { LoadingCards } from '../Loadings/LoadingCards';
import { AddCardButton } from '../Buttons/AddCardButton';
import { EmptyPage } from '../../../_components/Forum/components/EmptyPage';

export const ListAllDecks: FC = () => {
  const {
    allowDelay,
    masterLoading,
    sortedAndFilteredDecksData,
    onCreateFlashcardOpen,
    tab,
    refetchLoading,
    remainingLessons,
    currLessonId,
  } = useContext(FlashcardsCreationContext);

  if (masterLoading.communityDecks || masterLoading.userDecks) {
    return <LoadingCards />;
  }

  if (tab === 'meus-baralhos' && sortedAndFilteredDecksData.length === 0) {
    return <EmptyPage label="Nada por aqui..." />;
  }

  return (
    <>
      {
        sortedAndFilteredDecksData
          .map((deck, index) => {
            const { lessonTitle, lesson, flashcardsAmount } = deck;
            return (
              <DeckCard
                key={lesson}
                lessonId={lesson}
                index={index}
                lessonTitle={lessonTitle}
                amount={flashcardsAmount}
              />
            );
          })
      }
      <AddCardButton
        onClick={onCreateFlashcardOpen}
        delay={sortedAndFilteredDecksData.length * DELAY_INDEX * Number(allowDelay)}
        show={
          tab === 'comunidade'
          && !currLessonId
          && remainingLessons.length > 0
          && !refetchLoading.communityDecks
        }
      />
    </>
  );
};
