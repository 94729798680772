import { gql, useQuery } from '@apollo/client';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import { InstitutionTarget } from '../profile';

export type CompassInstitutionInfoType = {
  leaf: string[],
  numberOfQuestions: number,
  difficulty?: number,
  constancy: number,
}[]

export type InstitutionDataType = {
  institution: string,
  difficulty: number,
  themeStatistics: CompassInstitutionInfoType,
}

const GET_EXAM_STATISTICS = gql`
  query GetExamStatistics(
    $institutionTarget: [ProfileInstitutionTargetInputType!]!
    $examType: String!
  ) {
    getExamStatistics (
      institutionTarget: $institutionTarget
      examType: $examType
    ) {
      institution
      difficulty
      themeStatistics {
        leaf
        numberOfQuestions
        difficulty
        constancy
      }
    }
  }
`;

type GetExamStatisticsInput = {
  institutionTarget: InstitutionTarget[];
  examType?: string,
};

type GetExamStatisticsOutput = {
  getExamStatistics: InstitutionDataType[]
};

export function useExamStatistics(institutionTarget: InstitutionTarget[]) {
  const [examType] = useLocalStorage('@Aristoclass:examVariant') || undefined;

  return useQuery<GetExamStatisticsOutput, GetExamStatisticsInput>(GET_EXAM_STATISTICS, {
    variables: {
      institutionTarget,
      examType,
    },
  });
}
