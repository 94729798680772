import { FC } from 'react';
import { Box, BoxProps } from '@chakra-ui/react';
import { useDefaultStyles } from '../../../hooks/useDefaultStyles';

interface ProgressBarByGoalProps extends BoxProps {
  gold: number;
  silver: number;
  bronze: number;
  total: number;
}

export const ProgressBarByGoal: FC<ProgressBarByGoalProps> = ({ bronze, silver, gold, total, h, height, ...rest }) => {
  const { colors } = useDefaultStyles();
  return (
    <Box display="flex" bgColor={colors.alpha[200]} h={h || height || 4} flex={1} {...rest} overflow="hidden">
      <Box
        bgColor={colors.prizes.bronze}
        transition="all 0.5s"
        borderLeftRadius="inherit"
        borderRightRadius="none"
        width={`${(bronze / total) * 100}%`}
      />
      <Box
        bgColor={colors.prizes.silver}
        transition="all 0.5s"
        borderLeftRadius={bronze ? 'none' : 'inherit'}
        borderRightRadius={gold ? 'none' : 'inherit'}
        width={`${(silver / total) * 100}%`}
      />
      <Box
        bgColor={colors.prizes.gold}
        transition="all 0.5s"
        borderLeftRadius="none"
        borderRightRadius="inherit"
        width={`${(gold / total) * 100}%`}
      />
    </Box>
  );
};
