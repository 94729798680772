import { Box, Flex, Text, Tooltip, VStack } from '@chakra-ui/react';
import { faList } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { PlaylistType } from '../../api/aristoplus';
import { useDefaultStyles } from '../../hooks/useDefaultStyles';
import { humanizePubDate } from '../../utils/humanizePubDate';

export const Playlist: FC<PlaylistType> = ({
  category,
  amount,
  name,
  createdAt,
  thumbnail = '/images/thumb_JJ.png',
}) => {
  const history = useHistory();
  const { colors } = useDefaultStyles();
  const playlistSetRef = useRef<HTMLDivElement & HTMLButtonElement>(null);

  return (
    <Box
      onMouseEnter={() => {
        playlistSetRef.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
      }}
    >
      <Tooltip label={name}>
        <Box
          ref={playlistSetRef}
          as="button"
          h={118}
          w={200}
          backgroundImage={`url(${thumbnail})`}
          bgSize="cover"
          transition="all 0.2s"
          _hover={{
            transform: 'scale(1.1)',
          }}
          onClick={() => history.push(`/mais-aristo/videos/${category}/${name}`)}
        >
          <Flex justify="flex-end" h="100%" color={colors.lighter.fromWhite}>
            <VStack w="40%" justify="center" backgroundColor="rgba(0,0,0,0.5)">
              <Text fontSize="sm">
                {amount}
                {` vídeo${amount === 1 ? '' : 's'}`}
              </Text>
              <FontAwesomeIcon icon={faList} />
            </VStack>
          </Flex>
        </Box>
      </Tooltip>
      <Text noOfLines={1} w={200} fontWeight="bold">{name}</Text>
      <Text fontSize="sm" w={200} fontWeight="light">{humanizePubDate(createdAt)}</Text>
    </Box>
  );
};
