import { Center, Image, Text } from '@chakra-ui/react';
import { FC } from 'react';
import { PrimaryButton } from '../../../../lib/components/PrimaryButton';

type EmptyPageType = {
  label?: string,
  variant?: 'colorful' | 'gray' | 'normal',
  fontSize?: string | number,
  url?: string,
  buttonLabel?: string,
}

export const EmptyPage: FC<EmptyPageType> = ({
  label, variant = 'gray', fontSize = 'md', url, buttonLabel,
}: EmptyPageType) => {
  return (
    <Center
      filter={variant === 'gray' ? 'grayscale(100%) opacity(0.3);' : undefined}
      opacity={variant === 'colorful' ? 0.7 : undefined}
      w="100%"
      flexFlow="column"
      gridGap={2}
      mt={5}
    >
      <Image
        objectFit="cover"
        objectPosition="25px 0px"
        w={250}
        h={180}
        src="/images/aristo-design/p1.svg"
      />
      {
        label && (
          <Text
            fontSize={fontSize}
            style={{ fontVariant: variant === 'gray' ? 'small-caps' : undefined, textAlign: 'center' }}
          >
            {label}
          </Text>
        )
      }
      {
        url && (
          <PrimaryButton
            m="0 auto"
            mt={4}
            onClick={() => {
              window.open(url, '_blank');
            }}
          >
            {buttonLabel}
          </PrimaryButton>
        )
      }
    </Center>
  );
};
