import { useCallback, useMemo, useState } from 'react';
import { ref, getDownloadURL, uploadBytes, listAll, getMetadata } from 'firebase/storage';

import { storage } from '../client/firebase';

const cache: { [k: string]: string } = {};

async function addFileURL(path: string): Promise<typeof cache> {
  if (!path) {
    throw new Error('invalid path.');
  }
  if (cache[path]) {
    return cache;
  }
  try {
    const storageUrl = await getDownloadURL(ref(storage, path));
    cache[path] = storageUrl;
  } catch (error) {
    cache[path] = '/images/broken.png';
    console.error(error);
  }
  return { ...cache };
}

async function uploadFromBase64(path: string, b64: string, fileName?: string): Promise<string> {
  const contentType = /data:([^;]*);base64,/.exec(b64)?.[1].replace('jpeg', 'jpg');
  if (!contentType) {
    throw new Error('Missing content type');
  }
  const data = b64.replace(/data:([^;]*);base64,/, '');
  // eslint-disable-next-line no-useless-escape
  // const ext = contentType.replace(/[^\/]*\//, '').split('+')[0];
  const chars = atob(data);
  const bytes = new Uint8Array(Array.from({ length: chars.length }, (_, i) => chars.charCodeAt(i)));
  const snap = await uploadBytes(ref(storage, `${path}/${fileName}`), bytes, { contentType, cacheControl: 'no-cache' });
  return snap.ref.fullPath;
}

export interface FirebaseStorageHook {
  getURL: (path: string) => string;
  uploadFromBase64: (path: string, b64: string, fileName?: string) => Promise<string>;
}

export async function getImg(path: string) {
  const url = await getDownloadURL(ref(storage, path));
  return url;
}

export default function useFirebaseStorageImg(): FirebaseStorageHook {
  const [hookCache, setHookCache] = useState(cache);
  return useMemo(() => {
    const getURL = (path: string): string => {
      if (hookCache[path]) {
        return hookCache[path];
      }
      addFileURL(path)
        .then(setHookCache)
        .catch(e => console.error(e));

      return '/loading.gif';
    };

    return { getURL, uploadFromBase64 };
  }, [hookCache]);
}

export interface FirebaseStorageImageList {
  name: string;
  url: string;
  path: string;
  metadata: {
    customMetadata?: { link?: string };
  };
}

interface IUseFirebaseStorageImageList {
  handleListImages: () => Promise<FirebaseStorageImageList[]>;
}

export function useFirebaseStorageImageList(path: string, prefix?: string): IUseFirebaseStorageImageList {
  const handleListImages = useCallback(async () => {
    const listRef = await listAll(ref(storage, path));
    const filtered = listRef.items.filter(i => (prefix ? i.name.includes(prefix) : true));
    const imgs = await Promise.all(filtered.map(async item => ({
      name: item.name,
      url: await getDownloadURL(item),
      path: item.fullPath,
      metadata: await getMetadata(item),
    })));
    return imgs;
  }, [path, prefix]);

  return { handleListImages };
}
