import { Circle, Flex } from '@chakra-ui/react';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC } from 'react';
import { useDefaultStyles } from '../../../hooks/useDefaultStyles';

export type TrophyVariantType = 'bronze' | 'silver' | 'gold';
export type TrophyFragmentsType = TrophyVariantType[];

type TrophyFragmentsInputType = {
  fragments: TrophyFragmentsType,
  size?: number,
  hasAchieved?: boolean,
}

export const TrophyFragments: FC<TrophyFragmentsInputType> = ({
  fragments, hasAchieved, size = 1,
}) => {
  const { colors } = useDefaultStyles();

  if (!fragments.length) {
    return null;
  }

  return (
    <Flex
      position="absolute"
      align="center"
      bottom={0}
      ml={hasAchieved ? 0.5 : 0}
      h={`${1.25 * size}rem`}
      w={`${(1.4 + 0.7 * (fragments.length - 1)) * size}rem`}
    >
      {
        fragments.map((frag, i) => (
          <Circle
            size={`${1.25 * size}rem`}
            position="absolute"
            left={`${0.75 * i * size}rem`}
            bgColor={colors.prizes[frag]}
          />
        ))
      }
      {
        hasAchieved && (
          <Circle
            color={colors.green.keep}
            bgColor={colors.white.keep}
            fontSize={10 * size}
            position="absolute"
            size={`${0.5 * size}rem`}
            bottom={0}
            right={0}
          >
            <FontAwesomeIcon icon={faCheckCircle} />
          </Circle>
        )
      }
    </Flex>
  );
};
