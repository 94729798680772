import React, { FC } from 'react';
import { Route, Switch, useHistory, useParams, Redirect } from 'react-router-dom';
import { Flex, Text, HStack } from '@chakra-ui/react';
import MocksHistory from './exams-history-sections/MocksHistory';
import SmartExamsHistory from './exams-history-sections/SmartExamsHistory';
import ExamsHistory from './exams-history-sections/ExamsHistory';
import Select from '../../lib/components/Select';
import { useDefaultStyles } from '../../hooks/useDefaultStyles';

const ExamsHistoryPage: FC = () => {
  const history = useHistory();
  const params = useParams<{ id: string }>();
  const { colors } = useDefaultStyles();

  return (
    <Flex
      flexFlow="column"
      align="center"
      margin="0 .7rem"
    >
      {/* <SubMenuGeneral /> */}
      <Flex
        flexFlow="column"
        maxWidth={900}
      >
        <Flex
          flexFlow="column"
          align="center"
          marginTop="1rem"
        >
          <Text
            fontSize="xl"
            fontWeight="bolder"
            color={colors.secondary.goALittleDarker}
          >
            Histórico
          </Text>
          <Text
            align="justify"
            margin="1rem 0"
          >
            Veja o histórico de todos os simulados e provas realizados e liberados para você. As notas dos simulados
            realizados fora do prazo não serão contabilizadas no ranking na aba de desempenho.
          </Text>
        </Flex>
        <HStack
          flex={1}
          justify="center"
          align="center"
          marginY=".5rem"
        >
          <Text
            color={colors.secondary.goALittleDarker}
            fontWeight="bold"
            width={180}
          >
            Selecionar atividade
          </Text>
          <Select
            borderRadius={{ base: 100, md: 8 }}
            borderColor={colors.secondary.goALittleDarker}
            required
            w="100%"
            onChange={e => {
              if (e.target.value !== '') {
                history.push(`/historico-provas/${e.target.value}`);
              }
            }}
            value={params.id}
            options={[
              {
                value: 'simulados-aristo',
                label: 'Simulados Aristo',
              },
              {
                value: 'simulados-inteligentes',
                label: 'Simulados inteligentes',
              },
              {
                value: 'provas-na-integra',
                label: 'Provas na íntegra',
              },
            ]}
          />
        </HStack>
        <Switch>
          <Route exact path="/historico-provas">
            <Redirect to="/historico-provas/simulados-aristo" />
          </Route>
          <Route path="/historico-provas/simulados-aristo" component={MocksHistory} />
          <Route path="/historico-provas/simulados-inteligentes" component={SmartExamsHistory} />
          <Route path="/historico-provas/provas-na-integra" component={ExamsHistory} />
        </Switch>
      </Flex>
    </Flex>
  );
};

export default ExamsHistoryPage;
