import { FC } from 'react';
import { Box, BoxProps } from '@chakra-ui/react';
import { useDefaultStyles } from '../../../hooks/useDefaultStyles';

interface ProgressBarProps extends BoxProps {
  progress: number;
}

const ProgressBar: FC<ProgressBarProps> = ({ progress, h, height, ...rest }) => {
  const { colors } = useDefaultStyles();
  return (
    <Box
      display="flex"
      bgColor={colors.alpha[200]}
      h={h || height || 4}
      flex={1}
      {...rest}
      overflow="hidden"
    >
      <Box
        bgColor={colors.secondary.keep}
        transition="all 0.5s"
        borderRadius="inherit"
        width={`${progress * 100}%`}
      />
    </Box>
  );
};

export default ProgressBar;
