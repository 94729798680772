import { Button, Flex, HStack, Input } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-regular-svg-icons';
import { FC, useCallback, useContext, useRef, useState } from 'react';
import { useDefaultStyles } from '../../../../hooks/useDefaultStyles';
import { Loading } from '../../../../lib/components/Loading';
import { SPLIT_MARKER } from '../Forum2';
import { useDevice } from '../../../../hooks/useDevice';
import { WholeAppContext } from '../../../agenda/WholeApp.context';
import { CommentDtoProps, ContentType } from '../types/forum2.types';
import { Forum2Context } from '../../../../contexts/Forum2.context';

type CommentBoxType = {
  onClick: (comment: CommentDtoProps) => void,
  loading: boolean,
  contentId: string,
  courseId: string,
  parentId?: string,
  level: number,
  contentType: ContentType,
}

export const CommentBox: FC<CommentBoxType> = ({
  onClick,
  loading,
  contentId,
  courseId,
  parentId,
  level,
  contentType,
}) => {
  const { colors } = useDefaultStyles();
  const device = useDevice();
  const { setAreShortcutsEnabled, areShortcutsEnabled } = useContext(WholeAppContext);
  const { onCreateComment } = useContext(Forum2Context);

  const [loadingSendComment, setLoadingSendComment] = useState(false);
  const [answering, setAnswering] = useState(false);
  const [commentBeingCreated, setCommentBeingCreated] = useState('');
  const [hasAutoDisabledShortcut, setHasAutoDisabledShortcut] = useState(false);

  const commentBoxRef = useRef<HTMLInputElement>(null);

  const handleClick = useCallback(async () => {
    setLoadingSendComment(true);
    const res = await onCreateComment({
      body: commentBeingCreated,
      contentId,
      courseIdFromParam: courseId,
      parentId,
      level,
      contentType,
    });
    onClick(res);
    setLoadingSendComment(false);
    setCommentBeingCreated('');
    commentBoxRef.current?.blur();
  }, [commentBeingCreated, contentId, contentType, courseId, level, onClick, onCreateComment, parentId]);

  const handleEnterPress = useCallback(
    e => {
      if (e.key === 'Enter') {
        handleClick();
      }
    },
    [handleClick],
  );

  return (
    <Flex flexFlow="column">
      <HStack
        border="1px solid"
        borderColor="gray.300"
        bgColor={loadingSendComment || loading ? colors.lightGray.goAlmostFullDarker : undefined}
        borderRadius={4}
        minH={10}
        px={2}
        mb={4}
        w="100%"
      >
        <Input
          resize="none"
          ref={commentBoxRef}
          _placeholder={{ fontSize: device === 'mobile' ? 'xs' : undefined }}
          value={commentBeingCreated}
          rows={1}
          onFocus={() => setAnswering(true)}
          onBlur={() => setAnswering(false)}
          onChange={c => {
            setCommentBeingCreated(c.target.value.replaceAll(SPLIT_MARKER, ''));
            if (areShortcutsEnabled) {
              setAreShortcutsEnabled(false);
              setHasAutoDisabledShortcut(true);
            }
            if (hasAutoDisabledShortcut && c.target.value === '') {
              setAreShortcutsEnabled(true);
              setHasAutoDisabledShortcut(false);
            }
          }}
          onKeyDown={handleEnterPress}
          disabled={loadingSendComment}
          color={loadingSendComment ? 'gray' : undefined}
          variant="unstyled"
          height="56px"
          placeholder="Escreva um comentário..."
        />
      </HStack>
      <Flex justify="flex-end">
        {loadingSendComment || loading ? (
          <Loading />
        ) : (answering || commentBeingCreated) && (
          <>
            <Button
              color={colors.darkerGray.goLighter}
              onClick={() => setCommentBeingCreated('')}
              borderRadius="100px"
              marginRight={4}
              backgroundColor={colors.background}
              border="1px"
              size="lg"
            >
              cancelar
            </Button>
            <Button
              color={colors.white.goPallete50}
              onClick={handleClick}
              _hover={{
                backgroundColor: colors.lightSecondary.keep,
              }}
              borderRadius="100px"
              backgroundColor={colors.secondary.goDarker}
              size="lg"
            >
              <FontAwesomeIcon icon={faPaperPlane} />
              &nbsp;&nbsp;
              publicar
            </Button>
          </>
        )}
      </Flex>
    </Flex>
  );
};
