import { Center, CenterProps, Text } from '@chakra-ui/react';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDefaultStyles } from '../../hooks/useDefaultStyles';

export function Loading({
  subtitle, color, ...rest
}: CenterProps & { subtitle?: string }) {
  const { colors } = useDefaultStyles();
  return (
    <Center color={color || colors.secondary.goFullLighter} {...rest}>
      <FontAwesomeIcon icon={faSpinner} spin />
      {subtitle && <Text ml={2}>{subtitle}</Text>}
    </Center>
  );
}
