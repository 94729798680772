import React, { FC, useMemo } from 'react';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getDateParam } from '../../../../hooks/getDateParam';
import Flex from '../../../../lib/components/Flex';
import QuestionEmpty from '../../types/QuestionEmpty';
import { useQuestionMany } from '../../../../api/agenda/questions';
import { Questions } from '../../types/Questions.component';

interface ExtraTheoreticalQuestionProps {
  ids?: string[];
  id: string;
}

const ExtraTheoreticalQuestion: FC<ExtraTheoreticalQuestionProps> = ({ id, ids }) => {
  const query = useQuestionMany({
    ids: ids || [],
    calendarDate: getDateParam(),
    activity: id,
  });

  const dataQuery = useMemo(() => {
    const { data } = query;
    if (data) {
      return data.questionMany;
    }

    return [];
  }, [query]);

  if (query.loading) {
    return (
      <Flex align="center" justify="center" style={{ minHeight: 400 }}>
        <FontAwesomeIcon icon={faSpinner} spin size="5x" className="color-dark-gray" />
      </Flex>
    );
  }

  if (dataQuery && dataQuery.length > 0) {
    return (
      <Questions
        model="questions"
        data={dataQuery}
        activityId={id}
        shouldFinishActivity={false}
      />
    );
  }

  return <QuestionEmpty />;
};

export default ExtraTheoreticalQuestion;
