import { Box, HStack, Image, Link, Text, VStack } from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useFirebaseStorage from '../../hooks/useFirebaseStorage';
import { useDefaultStyles } from '../../hooks/useDefaultStyles';

interface PdfItemProps {
  icon?: IconProp;
  imageSrc?: string;
  title?: string;
  subtitle?: string;
  href?: string;
}

export function PdfItem({
  icon, imageSrc, title, subtitle, href,
}: PdfItemProps) {
  const { getURL } = useFirebaseStorage();
  const { cardBorder, colors, hueRotate } = useDefaultStyles();
  return (
    <Link href={href && getURL(href)} isExternal _hover={{ textDecoration: 'none' }}>
      <HStack
        spacing={4}
        _hover={{
          border: 'solid 2px',
          borderColor: colors.secondary.keep,
          color: colors.secondary.keep,
          bgColor: colors.lighter.goAlmostFullDarker,
        }}
        {...cardBorder}
        // borderColor="gray.500"
        px={{ base: 2, md: 3 }}
        py={{ base: 1, md: 3 }}
        align="center"
      >
        <Box h="64px" w="64px" color={colors.primary.keep}>
          {icon && !imageSrc && <FontAwesomeIcon style={{ width: '100%', height: '100%' }} icon={icon} />}
          {(imageSrc || !icon) && (
            <Image
              filter={hueRotate()}
              borderRadius={5}
              w="100%"
              h="100%"
              src={imageSrc || '/images/icons/pdf.svg'}
            />
          )}
        </Box>
        <VStack spacing={1} align="flex-start" flex={1}>
          <Text fontWeight="bold" fontSize="lg">{title}</Text>
          <Text>{subtitle}</Text>
        </VStack>
        <FontAwesomeIcon icon={faChevronRight} />
      </HStack>
    </Link>
  );
}
