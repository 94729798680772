import React, {
  FC, useContext, useState, useCallback,
} from 'react';
import { toast } from 'react-toastify';
import { Flex, Text, VStack } from '@chakra-ui/react';
import useInstitutions from '../../hooks/useInstitutions';
import { AgendaContext } from '../agenda/Agenda.context';
import { useExtraExams } from '../../api/extra-activities/extra-exams';
import Select from '../../lib/components/Select';
import { PrimaryButton } from '../../lib/components/PrimaryButton';
import { SecondaryButton } from '../../lib/components/SecondaryButton';
import { ExtraActivityModalProps } from './CreateActivityModal';
import { useDefaultStyles } from '../../hooks/useDefaultStyles';

const ExamModal: FC<ExtraActivityModalProps> = ({
  onClose, onAfterCreate,
}) => {
  const { refetchExtras } = useContext(AgendaContext);

  const {
    uf,
    institution,
    year,
    complement,
    ufs,
    institutions,
    years,
    complements,
    handleUf,
    handleInstitution,
    handleYear,
    handleComplement,
    loading: loadingInstitution,
    template,
  } = useInstitutions('', '', 0);

  const { colors } = useDefaultStyles();
  const [loading, setLoading] = useState(false);

  const { onCreateExamExtraActivity } = useExtraExams();

  const handleCreate = useCallback(async () => {
    if (year === 0 || uf === '' || institution === '') {
      toast.warning('Campo(s) não preenchido(s)!');
      return;
    }

    setLoading(true);

    try {
      await onCreateExamExtraActivity({
        template,
      });
      await refetchExtras();
      onClose();
      if (onAfterCreate) {
        onAfterCreate();
      }
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
    }
  }, [institution, onAfterCreate, onClose, onCreateExamExtraActivity, refetchExtras, template, uf, year]);

  return (
    <>
      <Text color={colors.secondary.goDarker} mb={2} fontWeight="bold">Provas</Text>
      <Text mb={2}>Qual prova deseja fazer?</Text>
      <VStack py={2} mb={4} align="start">
        <Select
          w="100%"
          label="UF"
          placeholder="Selecione"
          value={uf}
          onChange={handleUf}
          options={ufs.map(item => ({ value: item, label: item }))}
        />
        <Select
          w="100%"
          label="Instituição"
          value={institution}
          onChange={handleInstitution}
          isDisabled={!institutions.length}
          options={institutions.map(item => ({ value: item, label: item }))}
          loading={loadingInstitution}
        />
        <Select
          w="100%"
          label="Ano"
          value={year}
          onChange={handleYear}
          isDisabled={!years.length}
          loading={loadingInstitution}
          options={years.map(item => ({ value: String(item), label: String(item) }))}
        />
        <Select
          w="100%"
          label="Complemento"
          value={complement}
          onChange={handleComplement}
          isDisabled={complements.length === 0}
          loading={loadingInstitution}
          options={complements.map(item => ({ value: String(item), label: String(item) }))}
        />
        {
          year && year < new Date().getFullYear() - 5 && (
            <Text color={colors.red.keep}>
              ATENÇÃO! Você selecionou uma prova muito antiga, que pode estar
              desatualizada, não comentada ou divergir do padrão atual da banca.
            </Text>
          )
        }
      </VStack>
      <Flex justify="end">
        <SecondaryButton onClick={onClose} mr={2} variant="ghost">
          Cancelar
        </SecondaryButton>
        <PrimaryButton
          isLoading={loading}
          onClick={handleCreate}
          disabled={uf === '' || institution === '' || year === 0}
        >
          Adicionar
        </PrimaryButton>
      </Flex>
    </>
  );
};

export default ExamModal;
