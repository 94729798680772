import {
  Drawer, DrawerBody, DrawerContent, DrawerHeader, DrawerOverlay, HStack, IconButton, Text,
} from '@chakra-ui/react';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC, ReactNode } from 'react';
import { useDefaultStyles } from '../../hooks/useDefaultStyles';

interface AristoDrawerProps {
  children?: ReactNode;
  onClose: () => void;
  isOpen: boolean;
  title: string;

}

export const AristoDrawer: FC<AristoDrawerProps> = ({
  children, onClose, isOpen, title,
}: AristoDrawerProps) => {
  const { colors } = useDefaultStyles();
  return (
    <Drawer placement="right" size="md" onClose={onClose} isOpen={isOpen}>
      <DrawerOverlay />
      <DrawerContent bgColor={colors.background}>
        <DrawerHeader>
          <HStack align="center" spacing={4}>
            <IconButton
              aria-label="voltar"
              icon={<FontAwesomeIcon icon={faArrowLeft} />}
              onClick={() => {
                onClose();
              }}
              variant="ghost"
            />
            <Text fontWeight="bold">{title}</Text>
          </HStack>
        </DrawerHeader>
        <DrawerBody px="1.5rem">
          {children}
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};
