import React, {
  FC, useContext, useMemo,
} from 'react';
import { Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay, Flex, HStack, IconButton, Image, Text, useDisclosure, VStack } from '@chakra-ui/react';
import { faArrowLeft, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useParams } from 'react-router-dom';
import { useFlashcardMany } from '../../../api/agenda/flashcards';
import { getDateParam } from '../../../hooks/getDateParam';
import { getTheoreticalType } from '../../../hooks/getTheoreticalType';
import AgendaFlashcards from './AgendaFlashcards';
import FlashCardEmpty from './FlashCardEmpty';
import { PrivateContext } from '../../../Private.context';
import { useDevice } from '../../../hooks/useDevice';
import { PrimaryButton } from '../../../lib/components/PrimaryButton';
import { useDefaultStyles } from '../../../hooks/useDefaultStyles';

const AgendaFlashcardsData: FC = () => {
  const { agenda } = useContext(PrivateContext);
  const params = useParams<{ lessonId: string }>();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const device = useDevice();
  const { colors, hueRotate } = useDefaultStyles();

  const theoreticalType = getTheoreticalType();

  const agendaIds = useMemo(() => {
    if (theoreticalType === 'theoreticalStudy') {
      const lessonStudy = agenda?.theoreticalStudy.find(item => item.lessonId === params.lessonId);
      return lessonStudy;
    }
    if (theoreticalType === 'theoreticalReview') {
      const lessonReview = agenda?.theoreticalReview.find(item => item.lessonId === params.lessonId);
      return lessonReview;
    }
    if (theoreticalType === 'smartReview') {
      return agenda?.smartReview || undefined;
    }

    return undefined;
  }, [agenda?.smartReview, agenda?.theoreticalReview, agenda?.theoreticalStudy, params.lessonId, theoreticalType]);

  const flashcardsObj = agendaIds?.flashcards;
  const ids = flashcardsObj || [];

  const query = useFlashcardMany({
    ids,
    type: getTheoreticalType(),
    calendarDate: getDateParam(),
  });

  const obj = useMemo(() => {
    if (query.data) {
      return ({
        loading: query.loading,
        data: query.data.flashcardMany,
        length: query.data.flashcardMany.length,
      });
    }
    return ({
      loading: query.loading,
      data: [],
      length: 0,
    });
  }, [query]);

  if (obj.loading) {
    return (
      <Flex align="center" justify="center" style={{ minHeight: 400 }}>
        <FontAwesomeIcon icon={faSpinner} spin size="5x" className="color-dark-gray" />
      </Flex>
    );
  }

  if (!obj.loading && obj.length > 0 && obj.data) {
    if (device === 'mobile') {
      return (
        <>
          <Flex
            direction="column"
            bgColor={colors.primary.goALittleLighter}
            color={colors.lighter.keep}
            py={4}
            mx="auto"
            maxW={300}
            borderRadius={8}
            h="420px"
            mt={10}
          >
            <VStack justify="space-between" h="100%">
              <Image src="/images/flash-card.svg" filter={hueRotate()} />
              <Text textAlign="center" fontWeight="bold">Clique em VER ATIVIDADE para acessar</Text>
              <PrimaryButton bgColor={colors.secondary.keep} onClick={onOpen}>Ver atividade</PrimaryButton>
            </VStack>
          </Flex>
          <Drawer placement="bottom" onClose={onClose} isOpen={isOpen}>
            <DrawerOverlay />
            <DrawerContent h="100vh" bgColor={colors.background}>
              <DrawerHeader>
                <HStack align="center" spacing={4}>
                  <IconButton
                    aria-label="voltar"
                    icon={<FontAwesomeIcon icon={faArrowLeft} />}
                    onClick={onClose}
                    variant="ghost"
                  />
                  <Text fontWeight="bold">Flashcards</Text>
                </HStack>
              </DrawerHeader>
              <DrawerBody>
                <AgendaFlashcards
                  data={obj.data}
                />
              </DrawerBody>
            </DrawerContent>
          </Drawer>
        </>
      );
    }

    return (
      <AgendaFlashcards
        data={obj.data}
      />
    );
  }

  return <FlashCardEmpty />;
};

export default AgendaFlashcardsData;
