import { gql, useMutation } from '@apollo/client';
import { useCallback } from 'react';

const CREATE_APPEAL_ACTIVE_CAMPAIGN_CONTACT = gql`
  mutation CreateAppealActiveCampaignContact($input: ActiveCampaignContactInputType!) {
    createAppealActiveCampaignContact(input: $input)
  }
`;

interface ActiveCampaignContactInputType {
  name: string;
  email: string;
  phone: string;
}

interface UseLandingInterface {
  onCreateContact: (input: ActiveCampaignContactInputType) => Promise<boolean>
}

export function useLanding(): UseLandingInterface {
  const [createContact] = useMutation<
    { createAppealActiveCampaignContact: boolean }, { input: ActiveCampaignContactInputType }
  >(CREATE_APPEAL_ACTIVE_CAMPAIGN_CONTACT);

  const handleCreateContact = useCallback(async ({
    name,
    email,
    phone,
  }: ActiveCampaignContactInputType) => {
    const res = await createContact({
      variables: {
        input: {
          name,
          email,
          phone,
        },
      },
    });

    return res.data?.createAppealActiveCampaignContact || false;
  }, [createContact]);

  return {
    onCreateContact: handleCreateContact,
  };
}
