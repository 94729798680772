import { Button, Tooltip } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { FC, useCallback, useMemo } from 'react';
import { Question } from '../../../api/agenda/questions';
import { useDefaultStyles } from '../../../hooks/useDefaultStyles';
import { PrintProps } from '../../agenda/_components/QuestionIndexNavigator';

export interface PrintButtonProps {
  questions: Question[]
  printProps?: PrintProps
}

export const PrintButton: FC<PrintButtonProps> = ({
  questions, printProps,
}: PrintButtonProps) => {
  const today = moment().format('DD/MM/YYYY');
  const { colors } = useDefaultStyles();

  const data = useMemo(() => {
    return (
      {
        questions,
        printProps: { ...printProps, date: today },
      }
    );
  }, [printProps, questions, today]);

  const handlePrint = useCallback(() => {
    localStorage.setItem('@Aristoclass:printData', JSON.stringify(data));
    window.open(`${window.location.origin}/impressao`, '_blank');
  }, [data]);

  return (
    <Tooltip label="Imprimir">
      <Button
        onClick={handlePrint}
        color={colors.secondary.keep}
        variant="ghost"
      >
        <FontAwesomeIcon icon="print" />
      </Button>
    </Tooltip>
  );
};
