import {
  FC, useCallback, useContext, useMemo, useState,
} from 'react';
import {
  Button, Flex, Text, VStack,
} from '@chakra-ui/react';
import { toast } from 'react-toastify';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useClassifications from '../../hooks/useClassifications';
import Select from '../../lib/components/Select';
import { AgendaContext } from '../agenda/Agenda.context';
import { PrivateContext } from '../../Private.context';
import { useExtraFlashcards, useExtraUserFlashcardActivity } from '../../api/extra-activities/extra-flashcards';
import { ExtraActivityModalProps } from './CreateActivityModal';
import { SecondaryButton } from '../../lib/components/SecondaryButton';
import { PrimaryButton } from '../../lib/components/PrimaryButton';
import { useDefaultStyles } from '../../hooks/useDefaultStyles';
import { useGetUserDecks } from '../../api/decks';
import { SquareSwitch } from './SquareSwitch';
import { CustomNumberInput } from './CustomNumberInput';
import { ThemeListBox } from './ThemeListBox';

const FlashcardModal: FC<ExtraActivityModalProps> = ({
  onClose, onAfterCreate,
}) => {
  const { refetchExtras } = useContext(AgendaContext);
  const { colors } = useDefaultStyles();

  const { profile } = useContext(PrivateContext);
  const { userDecks } = useGetUserDecks();
  const [selectedUserDecks, setSelectedUserDecks] = useState<string[]>([]);
  const [currSelectedDeck, setCurrSelectedDeck] = useState('');
  const [allThemesSelectorValue, setAllThemesSelectorValue] = useState(false);
  const [userPrefersAristoFlashcards, setUserPrefersAristoFlashcards] = useState(true);
  const { onCreateUserFlashcardActivity } = useExtraUserFlashcardActivity();
  const treeId = profile.courses.length ? profile.courses[0].course : '';

  const userDecksData = useMemo(() => {
    if (userDecks.data?.getUserDecks) {
      return userDecks.data.getUserDecks
        .map(x => ({ value: x._id || '', label: x.lessonTitle }))
        .sort((a, b) => (a.label > b.label ? 1 : -1));
    } return [];
  }, [userDecks.data?.getUserDecks]);

  const addDeck = useCallback((deck: string) => {
    if (!selectedUserDecks.includes(deck)) {
      setSelectedUserDecks(prev => [...prev, deck]);
    }
  }, [selectedUserDecks]);

  const removeDeck = useCallback((deck: string) => {
    if (selectedUserDecks.includes(deck)) {
      setSelectedUserDecks(prev => prev.filter(x => x !== deck));
    }
  }, [selectedUserDecks]);

  const getLabelByValue = useCallback((value: string) => {
    return userDecksData.find(x => x.value === value)?.label || '';
  }, [userDecksData]);

  const [
    areas,
    specialities, , ,
    classification,
    handleClassification,
    classLoading,
  ] = useClassifications(treeId, true);

  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(10);
  const { onCreateFlashcardActivity } = useExtraFlashcards();

  const handleCreate = useCallback(async () => {
    setLoading(true);
    try {
      if (userPrefersAristoFlashcards) {
        await onCreateFlashcardActivity({
          classification: classification.length ? classification : undefined,
          treeId,
          count,
        });
      } else {
        await onCreateUserFlashcardActivity({
          deckIds: selectedUserDecks,
          length: count,
        });
      }
      await refetchExtras();
      onClose();
      if (onAfterCreate) {
        onAfterCreate();
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  }, [
    classification, count, onAfterCreate, onClose, onCreateFlashcardActivity,
    onCreateUserFlashcardActivity, refetchExtras, selectedUserDecks, treeId,
    userPrefersAristoFlashcards]);

  return (
    <>
      <Text color={colors.secondary.goALittleDarker} mb={2} fontWeight="bold">Flashcards</Text>
      <Flex
        align="flex-start"
        flexFlow={{ base: 'column', md: 'row' }}
        justify="space-between"
        gridGap={3}
      >
        <SquareSwitch
          isSelected={userPrefersAristoFlashcards}
          setIsSelected={setUserPrefersAristoFlashcards}
        />
        <CustomNumberInput
          w={{ base: '100%', md: '40%' }}
          count={count}
          label="Limite de flashcards"
          info={`Exemplo: Se você selecionar 10 flashcards, mas existirem apenas 3
          com os filtros desejados, apenas 3 serão retornados.`}
          setCount={setCount}
        />
      </Flex>
      {
        userPrefersAristoFlashcards ? (
          <>
            <VStack py={2} mb={4} align="start">
              <Select
                width="100%"
                label="Grande Área"
                value={classification[0] || ''}
                onChange={handleClassification(0)}
                loading={classLoading}
                isDisabled={!areas.length}
                options={areas.map(item => ({ value: item, label: item }))}
              />
              <Select
                width="100%"
                label="Especialidade"
                value={classification[1] || ''}
                onChange={handleClassification(1)}
                loading={classLoading}
                isDisabled={!specialities.length}
                options={specialities.map(item => ({ value: item, label: item }))}
              />
            </VStack>
          </>
        ) : (
          <>
            <VStack py={2} mb={4} align="start">
              {
                !allThemesSelectorValue && (
                  <>
                    <Select
                      width="100%"
                      label="Tema"
                      value={currSelectedDeck}
                      onChange={e => setCurrSelectedDeck(e.target.value)}
                      loading={userDecks.loading}
                      isDisabled={!userDecksData.length || userDecks.loading}
                      options={userDecksData.filter(x => !selectedUserDecks.includes(x.value))}
                    />
                    <Flex w="100%" justify="flex-end">
                      <Button
                        color={colors.secondary.keep}
                        variant="ghost"
                        disabled={selectedUserDecks.includes(currSelectedDeck) || !currSelectedDeck}
                        leftIcon={<FontAwesomeIcon icon={faPlusCircle} />}
                        onClick={() => addDeck(currSelectedDeck)}
                      >
                        Adicionar tema
                      </Button>
                    </Flex>
                  </>
                )
              }
              <ThemeListBox
                inputVariant="plainStrings"
                isAllThemesSelectorChecked={allThemesSelectorValue}
                setIsAllThemesSelectorChecked={setAllThemesSelectorValue}
                itemsList={selectedUserDecks}
                onRemoveItemClick={removeDeck}
                valueTranslatorFn={getLabelByValue}
              />
            </VStack>
          </>
        )
      }
      <Flex justify="end">
        <SecondaryButton onClick={onClose} mr={2} variant="ghost">
          Cancelar
        </SecondaryButton>
        <PrimaryButton
          isLoading={loading}
          disabled={loading
            || (!userPrefersAristoFlashcards && (!selectedUserDecks.length && !allThemesSelectorValue))}
          onClick={handleCreate}
        >
          Adicionar
        </PrimaryButton>
      </Flex>
    </>
  );
};

export default FlashcardModal;
