import { Box, Flex, FlexProps, HStack, Text } from '@chakra-ui/react';
import { faClock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC, ReactNode } from 'react';
import { useDefaultStyles } from '../../../hooks/useDefaultStyles';
import { PrimaryButton } from '../../../lib/components/PrimaryButton';

interface CompassActivityCardType extends FlexProps {
  title: string,
  btnLabel?: string,
  description: string,
  estimatedTime?: string,
  icon: ReactNode,
  disabled?: boolean,
  isLoading?: boolean,
  onClick: () => void,
}

export const CompassActivityCard: FC<CompassActivityCardType> = ({
  title,
  description,
  estimatedTime,
  icon,
  onClick,
  btnLabel = 'Fazer',
  disabled = false,
  isLoading = false,
  ...props
}) => {
  const { colors } = useDefaultStyles();
  return (
    <Flex
      w={232}
      h={332}
      p={5}
      m={3}
      align="center"
      borderRadius={20}
      style={{ gap: 5 }}
      justify="space-around"
      textAlign="center"
      flexFlow="column"
      bgColor="gray.50"
      boxShadow="2px 2px 2px 2px lightgray"
      {...props}
    >
      <Box fontSize={42} color={colors.secondary.keep}>
        {icon}
      </Box>
      <Text fontWeight="bold">{title}</Text>
      <Text>{description}</Text>
      <HStack fontSize={12}>
        {
          estimatedTime && (
            <>
              <Box color={colors.darkGray.keep}>
                <FontAwesomeIcon icon={faClock} />
              </Box>
              <Text>{estimatedTime}</Text>
            </>
          )
        }
      </HStack>
      <PrimaryButton
        w="75%"
        h={8}
        onClick={onClick}
        disabled={disabled}
        isLoading={isLoading}
      >
        {btnLabel}
      </PrimaryButton>
    </Flex>
  );
};
