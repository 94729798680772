import { Box, Button, Flex, Image } from '@chakra-ui/react';
import { faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { faGlobe, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, {
  FC, ReactNode, useCallback, useContext,
} from 'react';
import { DiagnosisAlertContext } from '../contexts/DiagnosisAlert.context';
import { SocialMediaItem } from './SocialMediaItem';

type DiagnosisTemplateProps = {
  areStarsActive?: boolean;
  active?: boolean;
  children?: ReactNode;
}

export const DiagnosisTemplate: FC<DiagnosisTemplateProps> = ({
  children, areStarsActive = true, active = true,
}: DiagnosisTemplateProps) => {
  const { handleSetAlertProps, handleSetActive } = useContext(DiagnosisAlertContext);

  const handleSocialMediaAlert = useCallback(() => {
    handleSetAlertProps({
      title: 'Acompanhe-nos nas redes sociais!',
      active: true,
      btn1: { text: 'Fechar', className: 'alertBtn1', action: () => handleSetActive(false) },
      body: (
        <Box width="15rem">
          <SocialMediaItem
            className="websiteColor"
            icon={faGlobe}
            address="https://www.aristo.com.br/"
          >
            Site da Aristo
          </SocialMediaItem>
          <SocialMediaItem
            className="instagramColor"
            icon={faInstagram}
            address="https://www.instagram.com/aristoresidenciamedica/"
          >
            Instagram
          </SocialMediaItem>
          <SocialMediaItem
            className="youtubeColor"
            icon={faYoutube}
            address="https://www.youtube.com/c/AristoResid%C3%AAnciaM%C3%A9dica"
          >
            Youtube
          </SocialMediaItem>
        </Box>
      ),
    });
  }, [handleSetAlertProps, handleSetActive]);

  if (!active) {
    return <>{children}</>;
  }

  return (
    <Flex
      backgroundImage="linear-gradient(90deg, #011224, #0473E3)"
      bgAttachment="fixed"
      backgroundBlendMode="soft-light"
      h="100vh"
      overflow="hidden"
      backgroundSize="cover"
      display="flex"
      align="center"
      justify="flex-start"
      flexFlow="column"
    >
      {areStarsActive
        ? (
          <>
            <Image
              src="../../images/brightStars2.png"
              alt="background"
              pointerEvents="none"
              zIndex={0}
              width="100%"
              height="100vh"
              opacity={0}
              position="absolute"
              animation="starblink linear alternate 2s infinite"
            />
            <Box position="fixed" className="shootingStar" />
          </>
        ) : ''}
      <Flex
        align="center"
        justify="space-between"
        px={8}
        py={6}
        w="100%"
      >
        <Image width="10rem" src="../../images/logo-aristo-white.png" alt="logo-aristo" />
        <Button
          variant="unstyled"
          _focus={{ border: 'none' }}
          onClick={handleSocialMediaAlert}
          title="Conheça a Aristo!"
        >
          <FontAwesomeIcon
            icon={faInfoCircle}
            className="templateHamburgerMenu"
          />
        </Button>
      </Flex>
      <Flex
        w="100%"
        flexFlow="column"
        align="center"
        // justify={{ md: 'center' }}
        overflowY="auto"
        px={2}
        flex={1}
      >
        {children}
      </Flex>
    </Flex>
  );
};
