import { ApolloError } from '@apollo/client';
import { toast } from '../../utils/toast';
import errorDictionary from './errorDictionary';

export default (error: ApolloError): void => {
  const errorName = error.graphQLErrors[0].extensions?.exception?.name;

  if (errorName in errorDictionary) {
    toast.error(errorDictionary[errorName]);
  } else {
    toast.error(errorDictionary.DEFAULT);
  }
};
