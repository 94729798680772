import { useCallback, useContext } from 'react';
import { DiagnosisAlertContext } from '../contexts/DiagnosisAlert.context';

interface UseOnExamAlertReturnType {
    handleCannotFinishExam: () => void;
    handleCanFinishExam: (onFinishExam: () => Promise<void>) => void;
    handleWelcomeBack: (onResetExam: () => void) => void;
}

export function useOnExamAlert(): UseOnExamAlertReturnType {
  const { handleSetActive, handleSetAlertProps } = useContext(DiagnosisAlertContext);

  const handleCannotFinishExam = useCallback(() => {
    handleSetAlertProps({
      title: 'Você ainda não pode finalizar o simulado.',
      body: 'Responda todas as questões para que o resultado do seu diagnóstico seja preciso.',
      active: true,
      btn2: { text: 'Continuar', className: 'alertBtn2', action: () => handleSetActive(false) },
    });
  }, [handleSetActive, handleSetAlertProps]);

  const handleCanFinishExam = useCallback((onFinishExam: () => Promise<void>) => {
    handleSetAlertProps({
      title: 'Muito bem! Você respondeu todas as questões!',
      body: 'Clique em Finalizar caso deseje encerrar a prova e ter acesso ao gabarito. Esse processo é irreversível.',
      active: true,
      btn1: {
        text: 'Finalizar',
        className: 'alertBtn1',
        action: async () => {
          await onFinishExam();
          handleSetActive(false);
        },
      },
      btn2: { text: 'Continuar o Simulado', className: 'alertBtn2', action: () => handleSetActive(false) },
    });
  }, [handleSetActive, handleSetAlertProps]);

  const handleWelcomeBack = useCallback((onResetExam: () => void) => {
    handleSetAlertProps({
      title: 'Bem-vindo de volta!',
      body: 'Vimos que você já iniciou esse diagnóstico anteriormente, deseja continuar?',
      active: true,
      btn1: {
        text: 'Reiniciar',
        className: 'alertBtn1',
        action: () => {
          handleSetActive(false);
          onResetExam();
        },
      },
      btn2: { text: 'Continuar o Simulado', className: 'alertBtn2', action: () => handleSetActive(false) },
    });
  }, [handleSetActive, handleSetAlertProps]);

  return {
    handleCannotFinishExam,
    handleCanFinishExam,
    handleWelcomeBack,
  };
}
