import { Button } from '@chakra-ui/react';
import { faEdit, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, {
  FC, useCallback, useState,
} from 'react';
import { useDefaultStyles } from '../../../hooks/useDefaultStyles';
import ConfirmModal from '../../../lib/components/ConfirmModal';

interface AddExtraBtnProps {
  active: boolean;
  handleCreateExtraFn: () => Promise<void>;
  confirmModalTitle: string;
  confirmModalSubtitle: string;
}

const AddExtraBtn: FC<AddExtraBtnProps> = ({
  active, handleCreateExtraFn, confirmModalTitle,
  confirmModalSubtitle,
}) => {
  const { colors } = useDefaultStyles();
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);

  const handleCreate = useCallback(async () => {
    setLoading(true);
    try {
      await handleCreateExtraFn();
    } catch (error) {
      console.log(error.message);
    } finally {
      setLoading(false);
      setModal(false);
    }
  }, [handleCreateExtraFn]);

  return (
    <>
      {!loading ? (
        <Button
          onClick={() => setModal(true)}
          disabled={!active}
          color={colors.secondary.keep}
          variant="unstyled"
          bgColor="none"
          _hover={{ bgColor: 'none' }}
          _focus={{ bgColor: 'none' }}
          fontSize="1.3rem"
          left="2px"
        >
          <FontAwesomeIcon icon={faEdit} />
        </Button>
      ) : (
        <FontAwesomeIcon icon={faSpinner} spin />
      )}
      <ConfirmModal
        isOpen={modal}
        onConfirm={handleCreate}
        onDecline={() => setModal(false)}
        title={confirmModalTitle}
        subtitle={confirmModalSubtitle}
        confirmText="Adicionar"
        declineText="Cancelar"
        loading={loading}
      />
    </>
  );
};

export default AddExtraBtn;
