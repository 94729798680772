import { Flex } from '@chakra-ui/react';
import React, { FC } from 'react';
import ReactSelect from 'react-select';
import { useDefaultStyles, useRGBColor } from '../../hooks/useDefaultStyles';

type SelectorOptType = {
  value: string,
  label: string,
}

type SearchableSelectorType = {
  options: SelectorOptType[],
  value: string,
  w?: number,
  optionLabel?: string,
  onChange: (value: string, label: string) => void,
}

export const SearchableSelector: FC<SearchableSelectorType> = ({
  value,
  options,
  w,
  optionLabel,
  onChange,
}) => {
  const { colors } = useDefaultStyles();
  const { toRGB } = useRGBColor();
  return (
    <Flex w={w || '100%'}>
      <ReactSelect
        menuPlacement="auto"
        menuPosition="fixed"
        styles={{
          container: provided => {
            return { ...provided, width: '100%' };
          },
          menu: provided => {
            return { ...provided, width: '100%' };
          },
          control: provided => {
            return { ...provided, backgroundColor: 'transparent', width: '100%' };
          },
          option: (provided, { isSelected }) => {
            return {
              ...provided,
              color: isSelected ? toRGB(colors.secondary.keep) : undefined,
              backgroundColor: toRGB(colors.white.goGray[700]),
              ':hover': { backgroundColor: toRGB(colors.lighter.goAlmostFullDarker) },
            };
          },
          placeholder: provided => {
            return { ...provided, color: toRGB(colors.darkGray.keep) };
          },
          input: provided => {
            return { ...provided, color: toRGB(colors.primary.goFullLighter) };
          },
          singleValue: provided => {
            return { ...provided, color: toRGB(colors.primary.goFullLighter) };
          },
        }}
        isSearchable
        isClearable
        onChange={e => {
          onChange(e?.value || '', e?.label || '');
        }}
        value={{ value, label: optionLabel || value }}
        placeholder="Selecione"
        options={options}
      />
    </Flex>
  );
};
