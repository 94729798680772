import {
  Drawer, DrawerBody, DrawerCloseButton,
  DrawerContent, DrawerHeader, DrawerOverlay,
  Heading, Image, Text, VStack,
} from '@chakra-ui/react';
import { FC, useState } from 'react';
import { useDefaultStyles } from '../../../hooks/useDefaultStyles';
import { useDevice } from '../../../hooks/useDevice';
import { DrawerTopic } from '../../flashcards-creation/_components/Drawers/DrawerTopic';

type CompassStatisticsTutorialType = {
  isOpen: boolean,
  onClose: () => void,
}

export const CompassStatisticsTutorial: FC<CompassStatisticsTutorialType> = ({
  isOpen, onClose,
}: CompassStatisticsTutorialType) => {
  const device = useDevice();
  const { colors } = useDefaultStyles();
  const [currOpenIndex, setCurrOpenIndex] = useState(-1);
  return (
    <Drawer
      isOpen={isOpen}
      placement="right"
      onClose={onClose}
      size={device === 'mobile' ? 'full' : 'md'}
    >
      <DrawerOverlay />
      <DrawerContent bgColor={colors.background}>
        <DrawerCloseButton />
        <DrawerHeader>
          <Heading size="md">Saiba mais</Heading>
        </DrawerHeader>
        <DrawerBody>
          <DrawerTopic
            topicIndex={0}
            currOpenIndex={currOpenIndex}
            setCurrOpenIndex={setCurrOpenIndex}
            title="Como interpretar o grau de dificuldade das provas?"
          >
            <VStack align="left">
              <Text>
                O grau de dificuldade varia de 0% (mais fácil) a 100% (mais difícil).
                A dificuldade 100% é atribuída à prova mais difícil a que temos conhecimento.
              </Text>
              <Text>
                Observe a figura abaixo:
              </Text>
              <Image src="/images/aristo-compass/exams-comparison.png" />
              <Text>
                Nela, podemos interpretar que, das instituições prioritárias selecionadas,
                o ENARE é a prova mais fácil (39% de dificuldade) e que a AMP tem uma prova muito difícil (74%).
              </Text>
              <Text>
                Notamos também que o Hospital Barra D&apos;Or apresentou uma prova com dificuldade bem próxima à mais
                difícil que temos conhecimento.
              </Text>
            </VStack>
          </DrawerTopic>
          <DrawerTopic
            topicIndex={1}
            currOpenIndex={currOpenIndex}
            setCurrOpenIndex={setCurrOpenIndex}
            title="Como interpretar &quot;N° questões (≤ 5 anos)&quot;?"
          >
            <VStack align="left">
              <Text>
                Este item representa o número de questões que apareceram do tema em
                um período de até 5 anos. Caso não existam em nosso banco de dados
                todas as provas dos últimos 5 anos da instituição selecionada, utilizaremos
                o intervalo disponível.
              </Text>
              <Text>
                Por exemplo: se só possuirmos a prova do ano passado em nosso banco de dados,
                somente ela entrará na contabilização.
              </Text>
              <Text>
                Observe a figura abaixo:
              </Text>
              <Image src="/images/aristo-compass/number-of-questions.png" />
              <Text>
                Nela, podemos concluir que caíram 12 questões de parto nos últimos 5 anos,
                10 de humanização do atendimento médico e 10 de conceitos na atenção primária.
              </Text>
            </VStack>
          </DrawerTopic>
          <DrawerTopic
            topicIndex={2}
            currOpenIndex={currOpenIndex}
            setCurrOpenIndex={setCurrOpenIndex}
            title="Como interpretar a &quot;Dificuldade&quot; do tema?"
          >
            <VStack align="left">
              <Text>
                Este item representa a dificuldade do tema naquela prova:
                Quanto mais cheia e mais próxima do vermelho a barra, maior a proporção de
                questões difíceis daquele tema na prova. Quanto mais vazia e mais próxima do verde, maior
                a proporção de questões fáceis.
              </Text>
              <Text>
                Observe a figura abaixo:
              </Text>
              <Image src="/images/aristo-compass/theme-difficulty.png" />
              <Text>
                Nela, podemos concluir que praticamente todas as questões de Doenças Inflamatórias Intestinais
                são difíceis nessa prova; e que praticamente todas as questões de Tuberculose são fáceis.
              </Text>
            </VStack>
          </DrawerTopic>
          <DrawerTopic
            topicIndex={3}
            currOpenIndex={currOpenIndex}
            setCurrOpenIndex={setCurrOpenIndex}
            title="Como interpretar a &quot;Constância de aparição&quot; do tema?"
          >
            <VStack align="left">
              <Text>
                Este item evidencia se o tema tende a cair todos os anos ou só esporadicamente.
              </Text>
              <Text>
                Por exemplo, um tema assinalado com &quot;Sempre 🔥&quot; é um tema que caiu em todas
                as últimas provas, portanto, é quase certo que ele cairá novamente. Um tema assinalado
                como &quot;Raramente&quot; aparece em um quantidade bem pequena de provas.
              </Text>
              <Text>
                Observe a figura abaixo:
              </Text>
              <Image src="/images/aristo-compass/constancy.png" />
              <Text>
                Nela, podemos concluir que cai questão de &quot;Complicações crônicas do diabetes&quot;
                todos os anos na prova selecionada, mas é bem raro aparecer
                &quot;Doenças inflamatórias intestinais&quot;!
              </Text>
            </VStack>
          </DrawerTopic>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};
