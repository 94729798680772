import { Box, FlexProps, Button, Flex } from '@chakra-ui/react';
import { ReactNode, FC } from 'react';
import { useDefaultStyles, useRGBColor } from '../../../hooks/useDefaultStyles';
import { PrimaryButton } from '../../../lib/components/PrimaryButton';

interface MainBoxTemplateProps extends FlexProps {
  children?: ReactNode;
  mainTitle?: string;
  hasMargin?: boolean;
  isSubmitButtonLoading?: boolean;
  descriptionTitle: string;
  details?: string[];
  btn1Params?: { onClick: () => void, text: string, color?: string, disabled?: boolean };
  btn2Params?: { onClick: () => void, text: string, color?: string, disabled?: boolean };
  submitBtn?: boolean;
  submitBtnText?: string;
  targetForm?: string;
  submitBtnDisabled?: boolean;
  textMarginBottom?: string;
  my?: string | number;
  color?: string,
  bgColor?: string,
  titleColor?: string,
  titleMarginTop?: number | string,
}

export const MainBoxTemplate: FC<MainBoxTemplateProps> = ({
  children,
  mainTitle = 'Simulado Aristo DX',
  my = 0,
  descriptionTitle,
  details,
  hasMargin = true,
  btn1Params,
  color = 'white',
  btn2Params,
  submitBtn = false,
  submitBtnText = 'Enviar',
  targetForm,
  submitBtnDisabled = false,
  isSubmitButtonLoading = false,
  textMarginBottom = '3rem',
  bgColor,
  titleColor,
  titleMarginTop,
  ...props
}: MainBoxTemplateProps) => {
  const { toRGB } = useRGBColor();
  const { colors } = useDefaultStyles();
  return (
    <Box
      color={color}
      marginBottom="10rem"
      maxW={900}
      w="100%"
      my={my}
      mx="auto"
    >
      <Box
        fontWeight="bold"
        fontSize="2rem"
        marginBottom="1rem"
        ml={8}
        color={titleColor}
        mt={titleMarginTop}
      >
        {mainTitle}
      </Box>
      <Flex
        color={color}
        bgColor={bgColor || `${toRGB(colors.primary.keep)}50`}
        borderRadius="1rem"
        flexFlow="column"
        padding="2rem"
        minWidth="15rem"
        {...props}
      >
        <Box marginBottom={textMarginBottom}>
          <Box
            marginBottom="1rem"
            fontSize="1.5rem"
            fontWeight="bold"
          >
            {descriptionTitle}
          </Box>
          {details?.map(content => (
            <Box
              key={content}
              margin={!hasMargin ? 0 : '1rem 0'}
              width="100%"
              minWidth="12rem"
              className="mainBoxTemplatePageDetails"
            >
              {content}
            </Box>
          ))}
        </Box>
        {children}
      </Flex>
      <Flex
        justify={{ base: 'center', lg: 'flex-end' }}
        minHeight="4rem"
        marginBottom="2rem"
        gridGap="1rem"
      >
        {btn1Params
          ? (
            <Button
              border={`${color ? toRGB(color) : 'white'} .4vh solid`}
              transition="background-color linear .3s"
              fontWeight="bold"
              marginTop="1.5rem"
              borderRadius=".5rem"
              color={btn1Params.color || 'white'}
              minHeight="3rem"
              minWidth="10rem"
              variant="unstyled"
              padding="0 1rem"
              _hover={{ bgColor: 'rgba(0, 0, 0, 0.178)', color: 'white' }}
              onClick={btn1Params.onClick}
              disabled={btn1Params.disabled === true}
            >
              {btn1Params.text}
            </Button>
          ) : ''}
        {btn2Params
          ? (
            <Button
              bgColor="secondary.50"
              color={btn2Params.color || 'aristo.500'}
              transition="background-color linear .3s"
              fontWeight="bold"
              disabled={btn2Params.disabled === true}
              marginTop="1.5rem"
              borderRadius=".5rem"
              minHeight="3rem"
              fontSize="1rem"
              minWidth="10rem"
              variant="unstyled"
              padding="0 1rem"
              _hover={{ bgColor: 'rgba(0, 0, 0, 0.178)', color: 'white' }}
              onClick={btn2Params.onClick}
            >
              {btn2Params.text}
            </Button>
          ) : ''}
        {submitBtn
          ? (
            <PrimaryButton
              type="submit"
              mt={4}
              w="12rem"
              form={targetForm || ''}
              disabled={submitBtnDisabled || isSubmitButtonLoading}
              isLoading={isSubmitButtonLoading}
            >
              {submitBtnText}
            </PrimaryButton>
          ) : ''}
      </Flex>
    </Box>
  );
};
