import { Box, Circle, Flex, HStack, Square, Text, VStack } from '@chakra-ui/react';
import { faCompass } from '@fortawesome/free-regular-svg-icons';
import { faBrain, faChartBar } from '@fortawesome/free-solid-svg-icons';
import { FC, useState } from 'react';
import { useDefaultStyles } from '../../../hooks/useDefaultStyles';
import { InfoCard } from './InfoCard';

export const InfoCardContainer: FC = () => {
  const { colors } = useDefaultStyles();
  const [isHover, setIsHover] = useState(false);
  return (
    <VStack
      spacing={4}
      w="100%"
      flexFlow="column"
      alignItems="center"
      justifyContent="center"
      textAlign={{ base: 'center', md: undefined }}
      pt={20}
      px={{ base: '1rem', md: 0 }}
      pb={20}
    >
      <Flex
        w="100%"
        flexFlow="column"
        align="center"
        justify="center"
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
      >
        <HStack spacing={4}>
          <Box
            w="32px"
            h="3px"
            bgColor={colors.intermediary.keep}
          />
          <Text
            color={colors.intermediary.keep}
            fontWeight="bold"
            fontSize={18}
          >
            O diagnóstico completo da sua prova
          </Text>
          <Square size="40px" positon="relative">
            <Circle
              position="absolute"
              bgColor={colors.white.keep}
              transition="width 0.4s, height 0.4s"
              size={isHover ? '50px' : 0}
            />
            <Flex
              className={isHover ? 'left-compass-detail' : ''}
              w="32px"
              h="3px"
              bgColor={colors.intermediary.keep}
            >
              <Box w={isHover ? '50%' : '100%'} h="100%" bgColor={colors.intermediary.keep} transition="width 0.4s" />
              <Box w={isHover ? '50%' : '0%'} h="100%" bgColor={colors.red.keep} transition="width 0.4s" />
            </Flex>
          </Square>
        </HStack>
        <VStack spacing={0}>
          <Text
            fontWeight="bold"
            fontSize={{ base: 25, md: 32 }}
            color={colors.primary.keep}
          >
            Tenha um direcionamento profissional na sua
          </Text>
          <Text
            fontWeight="bold"
            fontSize={{ base: 25, md: 32 }}
            color={colors.primary.keep}
          >
            preparação para a Residência Médica e Revalidação!
          </Text>
        </VStack>
      </Flex>
      <Flex
        style={{ gap: '25px' }}
        w="100%"
        overflow={{ base: 'auto', lg: 'unset' }}
        py={30}
        px={{ base: 5, md: 10 }}
      >
        <Flex
          style={{ gap: '2rem' }}
          w="400vw"
          justify={{ base: 'flex-end', lg: 'center' }}
        >
          <InfoCard
            title="DIRECIONAMENTO"
            icon={faCompass}
            text="Descubra quais são os temas mais incidentes nas suas provas prioritárias."
          />
          <InfoCard
            title="GRAU DE DIFICULDADE"
            icon={faBrain}
            text="Compare a dificuldade das suas provas prioritárias entre si e com a média nacional."
          />
          <InfoCard
            title="ESTATÍSTICAS DAS QUESTÕES"
            icon={faChartBar}
            text="Saiba a dificuldade e quantas questões caíram de cada tema nos últimos 5 anos da sua prova."
          />
        </Flex>
      </Flex>
    </VStack>
  );
};
