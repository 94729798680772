import { Center } from '@chakra-ui/react';
import moment from 'moment';
import {
  FC, useCallback, useContext, useMemo, useState,
} from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { ApolloError } from '@apollo/client';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PrivateContext } from '../../../Private.context';
import {
  useGetOrCreateMockExam,
  useMockExam,
} from '../../../api/agenda/mock-exam';
import { getActivityIndex } from '../../../hooks/getActivityIndex';
import { useGetActivityDetailsForPrint } from '../../../api/extra-activities/activities-for-print';
import { Questions } from '../types/Questions.component';
import { AgendaMockExamStartMessage } from './AgendaMockExamStartMessage';
import { AgendaMockExamExpiredMessage } from './AendaMockExamExpiredMessage';
import { AgendaMockExamEmptyMessage } from './AgendaMockExamEmptyMessage';

const AgendaMockExam: FC = () => {
  /** Context */
  const { agenda, hasAgenda } = useContext(PrivateContext);

  /** History */
  const history = useHistory();

  /** Params */
  const { search } = window.location;
  const params = new URLSearchParams(search);
  const activityId = params.get('a') || '';

  /** State */
  const [loading, setLoading] = useState(false);

  /** Memo */
  const id = useMemo(() => {
    if (agenda && agenda.mocks.length) {
      return agenda.mocks[0];
    }

    return '';
  }, [agenda]);

  /**
   * Queries & Mutations
   */
  const { onStartExam } = useMockExam();

  const query = useGetOrCreateMockExam({
    mockExamTemplate: id,
  });

  const { loading: printLoading, data: printData } = useGetActivityDetailsForPrint({
    activityId,
  });

  const activityDetails = printData?.getActivityDetailsForPrint;

  /**
   * Callbacks
   */

  const setFeedbackModel = useCallback(() => {
    if (activityDetails) {
      const types = activityDetails.questions.map(q => ({ _id: q._id, isEssay: q.isEssay }));
      localStorage.setItem('@Aristoclass:feedbackPrintData', JSON.stringify({ types }));
      window.open(`${window.location.origin}/gabarito`, '_blank');
    }
  }, [activityDetails]);

  const handleStart = useCallback(async () => {
    if (query.data && query.data.mockExam._id) {
      setLoading(true);
      try {
        await onStartExam({
          activityId: query.data.mockExam._id,
        });
        await query.refetch();
      } catch (error) {
        if (error instanceof ApolloError) {
          console.log(error.graphQLErrors[0].message);
        }
      } finally {
        setLoading(false);
      }
    }
  }, [onStartExam, query]);

  if (hasAgenda.examType === 'exams') {
    return <Redirect to={`/agenda/prova${window.location.search}`} />;
  }

  if (hasAgenda.examType === 'smart-exams') {
    return <Redirect to={`/agenda/simulado-inteligente${window.location.search}`} />;
  }

  if (query.loading) {
    return (
      <Center height="400px">
        <FontAwesomeIcon icon={faSpinner} spin className="color-dark-gray" size="5x" />
      </Center>
    );
  }

  if (query.data && !getActivityIndex()) {
    const currentUrlParams = new URLSearchParams(window.location.search);
    currentUrlParams.set('a', query.data.mockExam._id);
    history.replace({ search: currentUrlParams.toString() });
  }

  if (!query.error && query.data && query.data.mockExam && !query.data.mockExam.startedAt) {
    const mock = query.data.mockExam;

    const now = moment();
    const limit = moment(mock.limitDateToStart);

    const timeExpired = limit.diff(now) <= 0;

    if (timeExpired) {
      return <AgendaMockExamExpiredMessage mock={mock} />;
    }

    return (
      <AgendaMockExamStartMessage
        data={query.data}
        activityDetails={activityDetails}
        handleStart={handleStart}
        loading={loading}
        printLoading={printLoading}
        setFeedbackModel={setFeedbackModel}
        blockStart={timeExpired}
      />
    );
  }

  if (!query.error && query.data && query.data.mockExam) {
    return (
      <Questions
        model="mocks"
        data={query.data.mockExam.questions}
        activityId={query.data.mockExam._id}
        timeToDo={query.data.mockExam.timeToDo}
        answersAt={new Date(query.data.mockExam.answersAt)}
        finishesAt={new Date(query.data.mockExam.finishesAt)}
        finished={query.data.mockExam.finished}
        ranking={query.data.mockExam.ranking}
        mockExamTemplate={query.data.mockExam.mockExamTemplate}
        rankingAt={new Date(query.data.mockExam.rankingAt)}
        startedAt={query.data.mockExam.startedAt ? new Date(query.data.mockExam.startedAt) : undefined}
        serverPreChoices={query.data.mockExam.preChoice}
        examBlockPrinting={query.data.mockExam.printingIsBlocked}
      />
    );
  }

  return (
    <AgendaMockExamEmptyMessage />
  );
};

export default AgendaMockExam;
