import {
  gql, QueryResult, useMutation, useQuery,
} from '@apollo/client';
import { useCallback } from 'react';
import { PreChoiceInterface } from './agenda/mock-exam';
import { Question } from './agenda/questions';
import { InstitutionTarget } from './profile';

export type DiagnosticExam = {
  _id: string;
  calendarDate: string
  finished: boolean;
  preChoice?: PreChoiceInterface[];
  template: string;
  questions: Question[];
  institutionTarget: InstitutionTarget[];
  email?: string;
}

interface FindDiagnosticExamInput {
  _id: string;
}

interface FindDiagnosticExamOutput {
  findDiagnosticExam: DiagnosticExam;
}

const FIND_DIAGNOSTIC_EXAM = gql`
  query FindDiagnosticExam($_id: ObjectId!) {
    findDiagnosticExam(_id: $_id) {
      _id
      calendarDate
      finished
      preChoice {
        selected
        questionId
      }
      template
      questions {
        _id
        body
        choices
        answersProportion
        correctChoice
        institution
        uf
        year
        examOrder
        examType
        complement
        classification
        difficulty
        createdAt
        updatedAt
        comment {
          author
          profile
          title
          body
          createdAt
          updatedAt
        }
        answer
      }
      institutionTarget {
        uf
        institution
      }
      email
    }
  }
`;

interface DiagnosticStats {
  hits: number;
  total: number;
}
export interface DiagnosticThemeRowInterface {
  priorityIndex: number;
  isPriority: boolean;
  leaf: string;
  stats: DiagnosticStats;
  totalPerformance: number;
  relevance: number;
}
export interface DiagnosticAreaRowInterface {
  atentionCount: number;
  area: string;
  totalPerformance: number;
  totalStats: DiagnosticStats;
  easyPerformance: number;
  easyStats: DiagnosticStats;
  mediumPerformance: number;
  mediumStats: DiagnosticStats;
  hardPerformance: number;
  hardStats: DiagnosticStats;
}
export interface DiagnosticResultsInterface {
  themes: DiagnosticThemeRowInterface[];
  areas: DiagnosticAreaRowInterface[];
}

interface DiagnosticResultsInput {
  _id: string;
}

interface DiagnosticResultsOutput {
  fetchDiagnosticExamResults: DiagnosticResultsInterface;
}

const DIAGNOSTIC_RESULTS = gql`
  query FetchDiagnosticExamResults($_id: ObjectId!) {
    fetchDiagnosticExamResults(_id: $_id) {
      themes {
        priorityIndex
        isPriority
        leaf
        stats {
          hits
          total
        }
        totalPerformance
        relevance
      }
      areas {
        atentionCount
        area
        totalPerformance
        totalStats {
          hits
          total
        }
        easyPerformance
        easyStats {
          hits
          total
        }
        mediumPerformance
        mediumStats {
          hits
          total
        }
        hardPerformance
        hardStats {
          hits
          total
        }
      }
    }
  }
`;

interface CreateDiagnosticExamInput {
  institutionTarget: InstitutionTarget[];
  templateId?: string;
}

interface CreateDiagnosticExamOutput {
  createDiagnosticExam: string;
}

const CREATE_DIAGNOSTIC_EXAM = gql`
  mutation CreateDiagnosticExam(
    $institutionTarget: [ProfileInstitutionTargetInputType!]!,
    $templateId: ObjectId
  ) {
    createDiagnosticExam(institutionTarget: $institutionTarget, templateId: $templateId)
  }
`;

interface UpdateDiagnosticExamPreChoiceInput {
  _id: string;
  preChoice: PreChoiceInterface;
}

interface UpdateDiagnosticExamPreChoiceOutput {
  updateDiagnosticExamPreChoice: boolean;
}

const UPDATE_DIAGNOSTIC_EXAM_PRE_CHOICE = gql`
  mutation UpdateDiagnosticExamPreChoice($_id: ObjectId!, $preChoice: DiagnosticExamPreChoiceInputType!) {
    updateDiagnosticExamPreChoice(_id: $_id, preChoice: $preChoice)
  }
`;

interface FinishDiagnosticExamInput {
  _id: string;
}

interface FinishDiagnosticExamOutput {
  finishDiagnosticExam: boolean;
}

const FINISH_DIAGNOSTIC_EXAM = gql`
  mutation FinishDiagnosticExam($_id: ObjectId!) {
    finishDiagnosticExam(_id: $_id)
  }
`;

interface SubmitFormDiagnosticExamInput {
  _id: string;
  email: string;
  name: string;
  phone?: string;
  howDidYouMeetUs?: string;
}

interface SubmitFormDiagnosticExamOutput {
  submitDiagnosticExamForm: boolean;
}

const SUBMIT_FORM_DIAGNOSTIC_EXAM = gql`
  mutation SubmitFormDiagnosticExam(
    $_id: ObjectId!,
    $email: String!,
    $name: String!,
    $phone: String,
    $howDidYouMeetUs: String,
    ) {
    submitDiagnosticExamForm(
      _id: $_id,
      email: $email,
      name: $name,
      phone: $phone,
      howDidYouMeetUs: $howDidYouMeetUs,
      )
  }
`;

export function useDiagnosticResults(_id = '') {
  const getDiagnosticResultsQuery = useQuery<DiagnosticResultsOutput, DiagnosticResultsInput>(DIAGNOSTIC_RESULTS, {
    variables: {
      _id,
    },
    skip: !_id,
  });
  return {
    getDiagnosticResultsQuery,
  };
}

type UseDiagnosticExamParams = {
  _id: string;
  templateId?: string;
}

export type UseDiagnosticExamOutputType = {
  findDiagnosticExamQuery: QueryResult<FindDiagnosticExamOutput, FindDiagnosticExamInput>;
  handleCreateDiagnosticExam: (institutionTarget: InstitutionTarget[]) => Promise<string>;
  handleUpdateDiagnosticExamPreChoice: (preChoice: PreChoiceInterface) => Promise<boolean>;
  handleFinishDiagnosticExam: () => Promise<void>;
  handleSubmitFormDiagnosticExam: ({
    email, name, howDidYouMeetUs, phone,
  }: SubmitFormDiagnosticExamInput) => Promise<void>;
}

export function useDiagnosticExam({ _id, templateId }: UseDiagnosticExamParams): UseDiagnosticExamOutputType {
  const findDiagnosticExamQuery = useQuery<FindDiagnosticExamOutput, FindDiagnosticExamInput>(FIND_DIAGNOSTIC_EXAM, {
    variables: {
      _id,
    },
    skip: !_id,
  });

  const [createDiagnosticExamMutation] = useMutation<
    CreateDiagnosticExamOutput,
    CreateDiagnosticExamInput
  >(CREATE_DIAGNOSTIC_EXAM);
  const handleCreateDiagnosticExam = useCallback(async (institutionTarget: InstitutionTarget[]) => {
    const result = await createDiagnosticExamMutation({
      variables: {
        institutionTarget,
        templateId,
      },
    });
    if (result.data) {
      return result.data.createDiagnosticExam;
    }
    return '';
  }, [createDiagnosticExamMutation, templateId]);

  const [updateDiagnosticExamPreChoiceMutation] = useMutation<
    UpdateDiagnosticExamPreChoiceOutput,
    UpdateDiagnosticExamPreChoiceInput
  >(UPDATE_DIAGNOSTIC_EXAM_PRE_CHOICE);
  const handleUpdateDiagnosticExamPreChoice = useCallback(async (preChoice: PreChoiceInterface) => {
    try {
      const updateDiag = await updateDiagnosticExamPreChoiceMutation({
        variables: {
          _id,
          preChoice,
        },
      });
      if (updateDiag) {
        return true;
      }
      throw new Error('PreChoice not saved.');
    } catch (error) {
      console.log(error);
      return false;
    }
  }, [_id, updateDiagnosticExamPreChoiceMutation]);

  const [finishDiagnosticExamMutation] = useMutation<
    FinishDiagnosticExamOutput,
    FinishDiagnosticExamInput
  >(FINISH_DIAGNOSTIC_EXAM);
  const handleFinishDiagnosticExam = useCallback(async () => {
    await finishDiagnosticExamMutation({
      variables: {
        _id,
      },
    });
  }, [_id, finishDiagnosticExamMutation]);

  const [submitFormDiagnosticExamMutation] = useMutation<
    SubmitFormDiagnosticExamOutput,
    SubmitFormDiagnosticExamInput
  >(SUBMIT_FORM_DIAGNOSTIC_EXAM);
  const handleSubmitFormDiagnosticExam = useCallback(async ({
    email, name, howDidYouMeetUs, phone,
  }: SubmitFormDiagnosticExamInput) => {
    await submitFormDiagnosticExamMutation({
      variables: {
        _id,
        email,
        name,
        howDidYouMeetUs,
        phone,
      },
    });
  }, [_id, submitFormDiagnosticExamMutation]);

  return {
    findDiagnosticExamQuery,
    handleCreateDiagnosticExam,
    handleUpdateDiagnosticExamPreChoice,
    handleFinishDiagnosticExam,
    handleSubmitFormDiagnosticExam,
  };
}
