import {
  Box,
  Flex,
  SkeletonCircle,
  SkeletonText,
  VStack,
} from '@chakra-ui/react';
import { useMemo, useState } from 'react';
import { useGetCurrentWeekAchievementDoc } from '../../../api/achievements';
import { getDateParam } from '../../../hooks/getDateParam';
import { MockTrophyControls } from '../_components/MockTrophyControls';
import { MyTrophies } from '../_components/MyTrophies';
import { TrophyFragmentsExplanationCard } from '../_components/TrophyFragmentsExplanationCard';
import { WeekDocType, WeekProgress } from '../_components/WeekProgress';
import { WeekTrophyCard } from '../_components/WeekTrophyCard';

export type PerformanceMockControlsType = {
  enabled: boolean,
  ET: number,
  RT: number,
  P: number,
  RI: number,
}

export function WeekGoals() {
  const dateParam = getDateParam();
  const { data, loading } = useGetCurrentWeekAchievementDoc(dateParam);

  const [mock, setMock] = useState<PerformanceMockControlsType>({
    enabled: false,
    ET: 0,
    RT: 0,
    P: 0,
    RI: 0,
  });

  const weekDoc: WeekDocType = useMemo(() => {
    if (loading || !data) {
      return undefined;
    }

    const {
      theoreticalStudy,
      theoreticalReview,
      smartReview,
      extraTheoreticalReview,
      exams,
      goals,
    } = data.getWeeklyAchievement;

    return [
      ...(theoreticalStudy.total ? [{
        type: 'Estudos teóricos',
        abbr: 'ET',
        done: mock.enabled ? mock.ET : theoreticalStudy.done,
        total: theoreticalStudy.total,
        icon: '/images/aristo-icons/theoric-study.png',
        ...goals.theoreticalStudy,
      }] : []),
      ...(theoreticalReview.total ? [{
        type: 'Revisões teóricas',
        abbr: 'RT',
        done: mock.enabled
          ? mock.RT
          : Math.min(theoreticalReview.done + (extraTheoreticalReview ?? 0), theoreticalReview.total),
        total: theoreticalReview.total,
        icon: '/images/aristo-icons/theoretical-review.png',
        ...goals.theoreticalReview,
      }] : []),
      ...(smartReview.total ? [{
        type: 'Revisões inteligentes',
        abbr: 'RI',
        done: mock.enabled ? mock.RI : Math.min(smartReview.questions, smartReview.flashcards, smartReview.total),
        total: smartReview.total,
        icon: '/images/aristo-icons/smart-review.png',
        ...goals.smartReview,
      }] : []),
      ...(exams.total ? [{
        type: 'Provas/simulados',
        abbr: 'PROVA',
        done: mock.enabled ? mock.P : Math.min(exams.done, exams.total),
        total: exams.total,
        icon: '/images/aristo-icons/exam.png',
        ...goals.exam,
      }] : []),
    ];
  }, [loading, data, mock.enabled, mock.ET, mock.RT, mock.RI, mock.P]);

  const numberOfActivities = useMemo(() => {
    return weekDoc?.filter(x => x.total !== 0).length || 0;
  }, [weekDoc]);

  if (loading) {
    return (
      <Box px="20%" py="2rem" w="100%">
        <SkeletonCircle size="10" />
        <SkeletonText mt="4" noOfLines={4} spacing="4" />
      </Box>
    );
  }

  return (
    <Box maxW={850} margin="0.5rem auto">
      <Flex
        mb={4}
        w="100%"
        flexFlow={{ base: 'column', md: 'row' }}
        gridGap={5}
      >
        <WeekTrophyCard weekDoc={weekDoc} />
        <TrophyFragmentsExplanationCard numberOfActivities={numberOfActivities} />
      </Flex>
      {
        mock.enabled && (
          <MockTrophyControls
            setMock={setMock}
          />
        )
      }
      <VStack spacing={4}>
        <WeekProgress weekDoc={weekDoc} />
        <MyTrophies />
      </VStack>
    </Box>
  );
}
