import { Box, Center, Flex, Heading, HStack, Text, useColorModeValue, VStack } from '@chakra-ui/react';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback, useContext, useMemo, useState } from 'react';
import { useGetMockExamTemplates } from '../../../api/agenda/mock-exam';
import { useGetGeneralRanking, useGetUserPercentile } from '../../../api/general-ranking';
import { useDefaultStyles } from '../../../hooks/useDefaultStyles';
import { Divider } from '../../../lib/components/Divider';
import Select from '../../../lib/components/Select';
import { PrivateContext } from '../../../Private.context';
import RankCard from '../_components/RankCard.component';
import RankLine from '../_components/RankLine.component';

export function GeneralRanking() {
  const [mockId, setMockId] = useState('');
  const { profile } = useContext(PrivateContext);

  const handleMockId = useCallback(e => {
    setMockId(e.target.value);
  }, []);

  const queryRanking = useGetGeneralRanking({
    mockExamTemplate: mockId,
  });

  const queryPercentile = useGetUserPercentile({ mockExamTemplate: mockId });

  const queryTemplates = useGetMockExamTemplates();

  const templates = useMemo(() => {
    const { data } = queryTemplates;
    if (data) {
      return data.getMockExamTemplates;
    }
    return [];
  }, [queryTemplates]);

  const ranking = useMemo(() => {
    const { data } = queryRanking;
    if (data) {
      return data.getUserRankingStatus;
    }
    return [];
  }, [queryRanking]);

  const percentile = useMemo(() => {
    const { data } = queryPercentile;
    if (data) {
      return data.getRankingPercentiles;
    }
    return undefined;
  }, [queryPercentile]);

  const queriesLoading = useMemo(() => queryRanking.loading && queryPercentile.loading,
    [queryPercentile.loading, queryRanking.loading]);

  const podiumColor: string[] = [
    useColorModeValue('#69B656', '#0b3001'),
    useColorModeValue('#5382AC', '#01223f'),
    useColorModeValue('#F1AC13', '#422e01'),
  ];
  const { cardBorder, colors } = useDefaultStyles();

  return (
    <Box maxW={700} margin="0.5rem auto">
      <Box
        p={{ base: 4, md: 8 }}
        {...cardBorder}
      >
        <Heading size="md">Ranking geral dos simulados</Heading>
        <Flex align="center" my={2}>
          <Box flex={1}>
            <Select
              ml="auto"
              maxW={400}
              borderRadius={20}
              value={mockId}
              options={templates.map(item => ({ value: item.template, label: item.title }))}
              onChange={handleMockId}
            />
          </Box>
        </Flex>
        {queriesLoading && (
          <Center height={300} m="0 auto">
            <FontAwesomeIcon icon={faSpinner} spin size="3x" className="color-dark-gray" />
          </Center>
        )}
        {percentile && !queriesLoading && (
          <>
            {percentile.userRanking.total && (
              <Box mt={4}>
                <Text
                  display="inline"
                  color={colors.secondary.keep}
                  fontWeight="bold"
                >
                  {percentile.userRanking.total}
                </Text>
                <Text display="inline"> pessoas realizaram esse simulado.</Text>
              </Box>
            )}
            {percentile.userPercentile !== -1 && (
              <Box mt={2}>
                <Text display="inline">Sua nota foi </Text>
                <Text color={colors.secondary.keep} fontWeight="bold" display="inline">
                  {`${Math.round(percentile.userRanking.grade * 100)}%`}
                </Text>
                <Text display="inline"> e você foi melhor que aproximadamente </Text>
                <Text
                  display="inline"
                  color={colors.secondary.keep}
                  fontWeight="bold"
                >
                  {`${percentile.userPercentile}%`}
                </Text>
                <Text display="inline"> dos usuarios que realizaram essa prova.</Text>
              </Box>
            )}
          </>
        )}
        {ranking.length >= 3 && !queriesLoading && (
          <>
            <VStack align="stretch" mt={8}>
              <RankCard
                m="0 auto"
                flex={1}
                maxW={{ base: 200, md: 250 }}
                bgColor={podiumColor[ranking[0].ranking - 1]}
                rank={ranking[0].ranking}
                name={ranking[0].name}
                team=""
                img="/images/aristo-icons/trophy-gold.png"
                pontuation={ranking[0].grade}
              />
              <HStack justify="center">
                <RankCard
                  bgColor={podiumColor[ranking[1].ranking - 1]}
                  rank={ranking[1].ranking}
                  name={ranking[1].name}
                  team=""
                  pontuation={ranking[1].grade}
                  img="/images/aristo-icons/trophy-silver.png"
                />
                <RankCard
                  bgColor={podiumColor[ranking[2].ranking - 1]}
                  rank={ranking[2].ranking}
                  name={ranking[2].name}
                  team=""
                  pontuation={ranking[2].grade}
                  img="/images/aristo-icons/trophy-bronze.png"
                />
              </HStack>
            </VStack>
            <Box mt={4}>
              <VStack align="stretch" spacing={4}>
                {
                  ranking.slice(0, 10).map((userRankingInfo, ind) => {
                    const key = ind;
                    return (
                      <RankLine
                        key={`${userRankingInfo.userId}${key}`}
                        rank={userRankingInfo.ranking}
                        name={userRankingInfo.name}
                        team=""
                        pontuation={userRankingInfo.grade}
                        bgColor={userRankingInfo.userId === profile._id
                          ? colors.secondary.goDarker
                          : colors.alpha[50]}
                        color={userRankingInfo.userId === profile._id
                          ? colors.lighter.fromWhite
                          : colors.primary.goFullLighter}
                      />
                    );
                  })
                }
              </VStack>
              {ranking.length > 10 && (
                <>
                  <Divider my={6} />
                  <RankLine
                    rank={ranking[10].ranking}
                    name={ranking[10].name}
                    team=""
                    pontuation={ranking[10].grade}
                    bgColor={ranking[10].userId === profile._id ? colors.secondary.goDarker : 'white'}
                    color={ranking[10].userId === profile._id ? colors.lighter.fromWhite : 'gray'}
                  />
                </>
              )}
            </Box>
          </>
        )}
        {!queryRanking.loading && mockId && ranking.length < 3 && ranking.length > 0 && (
          <Box display="flex" align="center" justify="center" height={300}>
            <Text>Não houve dados suficientes para a criação do ranking.</Text>
          </Box>
        )}
        {!queryRanking.loading && mockId && ranking.length === 0 && (
          <Box display="flex" align="center" justify="center" height={300}>
            <Text>Nenhum dado encontrado!</Text>
          </Box>
        )}
      </Box>
    </Box>
  );
}
