import { Input, InputGroup, InputLeftElement } from '@chakra-ui/react';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC } from 'react';
import { useDefaultStyles } from '../../hooks/useDefaultStyles';

type DefaultSearchBarType = {
  searchKey: string,
  setSearchKey: (value: React.SetStateAction<string>) => void,
  placeholder?: string,
}

export const DefaultSearchBar: FC<DefaultSearchBarType> = ({
  searchKey,
  setSearchKey,
  placeholder = 'Pesquisar tema',
}) => {
  const { colors } = useDefaultStyles();
  return (
    <InputGroup marginY="0.5rem">
      <InputLeftElement color={colors.secondary.goALittleDarker}>
        <FontAwesomeIcon icon={faSearch} />
      </InputLeftElement>
      <Input
        value={searchKey}
        onChange={e => setSearchKey(e.target.value)}
        placeholder={placeholder}
        borderColor={colors.secondary.goALittleDarker}
        _placeholder={{ color: colors.secondary.goALittleDarker }}
      />
    </InputGroup>
  );
};
