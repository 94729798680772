import { FC } from 'react';
import { Redirect } from 'react-router-dom';
import { MenuTabsItems } from '../../../../lib/components/MenuTabs';
import { EmptyPage } from '../../_components/EmptyPage';

interface ExtraSmartReviewMainProps {
  menuItems: MenuTabsItems;
  context: 'revisao-inteligente';
}

const ExtraSmartReviewMain: FC<ExtraSmartReviewMainProps> = ({ menuItems, context }) => {
  if (menuItems.questao) {
    return <Redirect to={`/atividades-extras/${context}/questao${window.location.search}`} />;
  }

  if (menuItems.flashcard) {
    return <Redirect to={`/atividades-extras/${context}/flashcard${window.location.search}`} />;
  }

  return (
    <EmptyPage />
  );
};

export default ExtraSmartReviewMain;
