import { gql, QueryResult, useQuery } from '@apollo/client';
import { Comment, TheoreticalTypes } from './agenda';

export interface Flashcard {
  _id: string;
  front: string;
  back: string;
  classification: string[];
  prefix?: string;
  comment: Comment | null;
  createdAt: string | null;
  updatedAt: string | null;
  createdBy: string | null;
  answer: number | null;
}

interface FlashcardManyInput {
  calendarDate: string;
  type?: TheoreticalTypes;
  activity?: string;
  ids: string[];
}

interface FlashcardManyOutput {
  flashcardMany: Flashcard[];
}

const FLASHCARD_MANY = gql`
  query FlashcardMany(
    $calendarDate: CalendarDate!
    $type: TheoreticalType
    $activity: ObjectId
    $ids: [ObjectId!]!
  ) {
    flashcardMany(
      calendarDate: $calendarDate
      type: $type
      activity: $activity
      ids: $ids
    ) {
      _id
      front
      back
      classification
      prefix
      createdAt
      updatedAt
      comment {
        author
        profile
        title
        body
        createdAt
        updatedAt
      }
      answer
    }
  }
`;

export function useFlashcardMany({
  calendarDate,
  ids,
  activity,
  type,
}: FlashcardManyInput): QueryResult<FlashcardManyOutput, FlashcardManyInput> {
  return useQuery<FlashcardManyOutput, FlashcardManyInput>(FLASHCARD_MANY, {
    variables: {
      calendarDate,
      ids,
      activity,
      type,
    },
    fetchPolicy: 'network-only',
    skip: ids.length === 0,
  });
}
