import {
  FC, useMemo, useContext,
} from 'react';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useVideoMany } from '../../../api/agenda/videos';
import Flex from '../../../lib/components/Flex';
import AgendaVideo from './AgendaVideo';
import VideoEmpty from './VideoEmpty';
import { PrivateContext } from '../../../Private.context';
import { getTheoreticalType } from '../../../hooks/getTheoreticalType';

const AgendaVideoData: FC = () => {
  const params = useParams<{ lessonId: string }>();

  const { agenda } = useContext(PrivateContext);

  const theoreticalType = getTheoreticalType();

  const agendaIds = useMemo(() => {
    if (theoreticalType === 'theoreticalStudy') {
      const lessonStudy = agenda?.theoreticalStudy.find(item => item.lessonId === params.lessonId);
      return lessonStudy?.videos;
    }
    if (theoreticalType === 'theoreticalReview') {
      const lessonReview = agenda?.theoreticalReview.find(item => item.lessonId === params.lessonId);
      return lessonReview?.videos;
    }

    return undefined;
  }, [agenda?.theoreticalReview, agenda?.theoreticalStudy, params.lessonId, theoreticalType]);

  const query = useVideoMany({
    ids: agendaIds || [],
  });

  if (query.loading) {
    return (
      <Flex align="center" justify="center" style={{ minHeight: 400 }}>
        <FontAwesomeIcon icon={faSpinner} spin size="5x" className="color-dark-gray" />
      </Flex>
    );
  }

  if (!query.error
    && !query.loading
    && query.data
    && query.data.videoMany.length > 0
  ) {
    return (
      <AgendaVideo
        data={query.data.videoMany}
      />
    );
  }

  return <VideoEmpty />;
};

export default AgendaVideoData;
