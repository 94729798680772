import {
  FC, useCallback, useContext, useMemo, useState,
} from 'react';
import moment from 'moment';
import { ApolloError } from '@apollo/client';
import { faCheckCircle, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import { faClock } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Circle, Flex, Image, Text } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import { useExtraActivities } from '../../api/extra-activities/extra-activities';
import { getDateParam } from '../../hooks/getDateParam';
import ConfirmModal from '../../lib/components/ConfirmModal';
import { AgendaContext } from '../agenda/Agenda.context';
import { useDefaultStyles } from '../../hooks/useDefaultStyles';

interface ActivityListUnion {
  _id: string;
  finished: boolean;
  classification?: string[];
  institution?: string;
  title?: string;
  subtitle?: string;
  uf?: string;
  year?: number;
  createdAt?: string;
  type: string;
}

const ListExtraActivities: FC = () => {
  const { extras, refetchExtras } = useContext(AgendaContext);
  const [loading, setLoading] = useState(false);
  const [active, setActive] = useState(false);

  const { onDeleteExtraActivity } = useExtraActivities();

  const handleActive = useCallback((action: boolean) => () => {
    setActive(action);
  }, []);

  const handleDelete = useCallback((id: string) => async () => {
    setLoading(true);
    try {
      await onDeleteExtraActivity({
        activityId: id,
      });
      await refetchExtras();
    } catch (error) {
      if (error instanceof ApolloError) {
        console.log(error.graphQLErrors[0].message);
      }
    } finally {
      setLoading(false);
      setActive(false);
    }
  }, [onDeleteExtraActivity, refetchExtras]);

  const activities = useMemo(() => {
    return (Object.entries(extras) as [string, ActivityListUnion[]][]).reduce((acc, [key, activitiesList]) => {
      if (key === '__typename') {
        return acc;
      }
      acc.push(...activitiesList.map(act => ({ ...act, type: key })));
      return acc;
    }, [] as ActivityListUnion[]).sort((a, b) => moment(a.createdAt).diff(moment(b.createdAt)));
  }, [extras]);

  const typeMap = useMemo(() => ({
    activityTheoreticalExtra: {
      type: 'theoric-study',
      to: 'estudo-teorico',
      prefix: 'Conteúdo teórico',
    },
    activityTheoreticalReviewExtra: {
      type: 'theoretical-review',
      to: 'revisao-teorica',
      prefix: 'Revisão teórica',
    },
    activitySmartReviewExtra: {
      type: 'smart-review',
      to: 'revisao-inteligente',
      prefix: 'Atividade de revisão inteligente',
    },
    activityQuestion: {
      type: 'question',
      to: 'questao',
      prefix: 'Atividade de questão',
    },
    activityFlashcard: {
      type: 'flashcard',
      to: 'flashcard',
      prefix: 'Atividade de flashcard',
    },
    activityExamExtra: {
      type: 'exam',
      to: 'prova',
      prefix: 'Atividade de prova',
    },
    activityMockExamExtra: {
      type: 'mock',
      to: 'simulado',
      prefix: 'Atividade de simulado',
    },
    activitySmartExamExtra: {
      type: 'smart-mock',
      to: 'simulado-inteligente',
      prefix: 'Atividade de simulado inteligente',
    },
  }) as Record<string, { type: ListItemType, to: string, prefix: string }>, []);

  return (
    <Box px={2}>
      {activities.map((item, i) => {
        let activitySuffix = '';
        switch (item.type) {
          case 'activityTheoreticalExtra':
          case 'activityTheoreticalReviewExtra':
          case 'activityFlashcard':
            activitySuffix = (item.classification && item.classification.length)
              ? `[${item.classification.join(' - ')}]` : '';
            break;
          case 'activityExamExtra':
            activitySuffix = `[${[item.uf, item.institution, item.year].join(' - ')}]`;
            break;
          case ('activityMockExamExtra'):
            activitySuffix = `[${[item.title, item.subtitle].join(' - ')}]`;
            break;
          case ('activitySmartExamExtra'):
            activitySuffix = `[${[item.uf, item.institution].join(' - ')}]`;
            break;
          case ('activitySmartReviewExtra'):
          case ('activityQuestion'):
          default:
            break;
        }
        return (
          <ListItem
            type={typeMap[item.type as keyof typeof typeMap].type}
            key={item._id}
            onDelete={handleDelete(item._id)}
            loadingDelete={loading}
            n={i}
            to={typeMap[item.type as keyof typeof typeMap].to}
            text={`${typeMap[item.type as keyof typeof typeMap].prefix} ${activitySuffix}`}
            id={item._id}
            active={active}
            onActive={handleActive}
            finished={item.finished}
          />
        );
      })}
    </Box>
  );
};

type ListItemType = 'question' | 'mock' | 'exam' | 'flashcard' | 'video'
  | 'mindmap' | 'text' | 'smart-mock' | 'theoric-study' | 'theoretical-review' | 'smart-review';

interface ListItemProps {
  type: ListItemType
  to: string;
  n: number;
  text: string;
  noIndex?: boolean;
  onDelete: () => Promise<void>;
  loadingDelete: boolean;
  id: string;
  active: boolean;
  onActive: (action: boolean) => () => void;
  finished: boolean;
}

const ListItem: FC<ListItemProps> = ({
  type, to, n, text, noIndex, onDelete, loadingDelete, active, onActive, id, finished,
}) => {
  const history = useHistory();
  const { cardBorder, colors } = useDefaultStyles();
  const [open, setOpen] = useState(false);

  const handleOpen = useCallback((action: boolean) => () => {
    setOpen(action);
    onActive(action)();
  }, [onActive]);

  return (
    <>
      <Box
        display="flex"
        position="relative"
        align="center"
        my="0.8rem"
        overflow="hidden"
        _hover={{
          bgColor: finished ? colors.green.lighter : colors.lighter.goAlmostFullDarker,
          borderColor: finished ? 'green.400' : colors.secondary.goALittleDarker,
          color: finished ? 'green.400' : colors.secondary.goALittleDarker,
        }}
        {...cardBorder}
        borderColor={finished ? colors.green.goDarker : colors.secondary.goDarker}
        mb={4}
        _after={{
          content: '""',
          position: 'absolute',
          bottom: 0,
          height: '8px',
          width: '100%',
          bgColor: finished ? colors.green.goDarker : colors.secondary.goDarker,
        }}
      >
        <Box
          as="button"
          flex={1}
          pb={4}
          pt={2}
          mx={2}
          onClick={() => {
            history.push({
              pathname: `/atividades-extras/${to}`,
              search: `date=${getDateParam()}&a=${id}`,
            });
          }}
        >
          <Flex ml={2} alignItems="center">
            <Circle
              fontSize="xs"
              color={finished ? 'green.400' : colors.secondary.goALittleDarker}
            >
              {finished ? (
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  size="2x"
                  title="Atividade concluída"
                />
              ) : (
                <FontAwesomeIcon
                  icon={faClock}
                  size="2x"
                  title="Atividade em andamento"
                />
              )}
            </Circle>
            <Circle
              size="45px"
              ml={4}
              padding=".4rem"
              border="solid .15rem"
              bgColor={finished ? 'green.100' : colors.lighter.goDarker}
              borderColor={finished ? 'green.400' : colors.secondary.goALittleDarker}
              overflow="hidden"
            >
              <Image filter={finished ? 'hue-rotate(270deg)' : undefined} src={`/images/aristo-icons/${type}.png`} />
            </Circle>
            <Text mx={4} textAlign="left" fontWeight="bold">{`${text}${noIndex ? '' : ` - ${n + 1}`}`}</Text>
          </Flex>
        </Box>
        <Box
          as="button"
          ml="auto"
          mr={2}
          opacity={0.8}
          title="Excluir atividade"
          disabled={loadingDelete || active}
          onClick={handleOpen(true)}
        >
          <FontAwesomeIcon icon={faTimes} size="lg" />
        </Box>
      </Box>
      <ConfirmModal
        title="Excluir"
        icon={<FontAwesomeIcon icon={faTrash} />}
        subtitle="Deseja excluir essa atividade? Essa ação é irreversível."
        confirmText="Confirmar"
        declineText="Cancelar"
        onConfirm={onDelete}
        onDecline={handleOpen(false)}
        isOpen={open}
        isConfirmDisabled={loadingDelete}
        loading={loadingDelete}
      />
    </>
  );
};

export default ListExtraActivities;
