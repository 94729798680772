import { gql, useQuery } from '@apollo/client';

type AnswerCountOutput = {
  countAnswerClassification: {
    area: string;
    questions: number;
    correct: number;
    wrong: number;
  }[];
}

type AnswerCountInput = {
  dateStart?: string;
  dateEnd?: string;
}

const ANSWER_COUNT_BY_CLASSIFICATION = gql`
  query CountAnswerClassification($dateStart: CalendarDate, $dateEnd: CalendarDate) {
    countAnswerClassification(dateStart: $dateStart, dateEnd: $dateEnd) {
      area
      questions
      correct
      wrong
    }
  }
`;

export function useAnswerCountByTag(props?: AnswerCountInput) {
  return useQuery<AnswerCountOutput, AnswerCountInput>(ANSWER_COUNT_BY_CLASSIFICATION, {
    variables: props,
  });
}
