import {
  gql, QueryResult, QueryTuple, useLazyQuery, useQuery,
} from '@apollo/client';
import { Video } from './agenda/videos';

export interface CustomRelevancesInterface {
  leaf: string[];
  relevance: number;
  phase: string;
  enableAfter: string;
  lessonId: string;
  isPreCourse?: boolean;
  theoreticalStudyFinishedAt?: string;
}

interface CustomRelevancesOutput {
  customRelevances: CustomRelevancesInterface[];
}

const CUSTOM_RELEVANCES = gql`
  query CustomRelevances {
    customRelevances {
      leaf
      relevance
      phase
      enableAfter
      isPreCourse
      lessonId
      theoreticalStudyFinishedAt
    }
  }
`;

export function useCustomRelevances(): QueryResult<CustomRelevancesOutput> {
  return useQuery<CustomRelevancesOutput>(CUSTOM_RELEVANCES, {
    fetchPolicy: 'network-only',
  });
}

interface LessonOneVideosInput {
  id: string;
}

interface LessonOneVideosOutput {
  lessonOneVideos: Video[];
}

const LESSON_ONE_VIDEOS = gql`
  query LessonOneVideos ($id: ObjectId!) {
    lessonOneVideos (id: $id) {
      _id
      url
      title
      subtitle
      comment {
        author
        profile
        title
        body
        createdAt
        updatedAt
      }
    }
  }
`;

export function useLazyLessonOneVideos(): QueryTuple<LessonOneVideosOutput, LessonOneVideosInput> {
  return useLazyQuery<LessonOneVideosOutput, LessonOneVideosInput>(LESSON_ONE_VIDEOS);
}

export interface FinishTheoreticalStudyInput {
  lesson: string
}

export const FINISH_THEORETICAL_STUDY = gql`
  mutation FinishTheoreticalStudy($lesson: ObjectId!) {
    finishTheoreticalStudy(lesson: $lesson)
  }
`;
