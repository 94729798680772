import { Center } from '@chakra-ui/react';
import { FC } from 'react';
import { useDefaultStyles } from '../../../hooks/useDefaultStyles';
import { Loading } from '../../../lib/components/Loading';

export const ReportLoading: FC = () => {
  const { cardBorder } = useDefaultStyles();
  return (
    <Center
      flex={1}
      mb={{ base: 4, md: 0 }}
      p={4}
      h={450}
      transitionTimingFunction="ease-in-out"
      transitionDuration="5s"
      transitionProperty="height"
      {...cardBorder}
    >
      <Loading />
    </Center>
  );
};
