import { FC } from 'react';
import { Redirect } from 'react-router-dom';
import { MenuTabsItems } from '../../../lib/components/MenuTabs';
import { EmptyPage } from '../_components/EmptyPage';

interface AgendaSmartReviewMainProps {
  menuItems: MenuTabsItems;
}

const AgendaSmartReviewMain: FC<AgendaSmartReviewMainProps> = ({ menuItems }) => {
  if (menuItems.questao) {
    return <Redirect to={`/agenda/revisao-inteligente/questao${window.location.search}`} />;
  }

  if (menuItems.flashcard) {
    return <Redirect to={`/agenda/revisao-inteligente/flashcard${window.location.search}`} />;
  }

  return (
    <EmptyPage />
  );
};

export default AgendaSmartReviewMain;
