import { FC, useEffect } from 'react';
import { usePlatformExperiment } from './api/platform-experiment';
import { auth } from './client/firebase';
import { Loading } from './lib/components/Loading';

const externalRedirectUrl = process.env.REACT_APP_FLUTTER_URL;

const PlatformExperiment: FC = ({ children }) => {
  const { loading, error, data } = usePlatformExperiment();
  const { currentUser } = auth;

  useEffect(() => {
    if (currentUser) {
      currentUser.getIdToken().then(token => {
        // eslint-disable-next-line max-len
        if (!loading && !error && data && data.userIncludedInPlatformABExperiment?.isIncludedInExperimentPlatformUserList) {
          const fullRedirectUrl = `${externalRedirectUrl}/#/external-auth?userToken=${token}`;
          window.location.href = fullRedirectUrl;
        }
      }).catch(err => {
        console.error('Error fetching user token:', err);
      });
    }
  }, [loading, error, data, currentUser]);

  if (loading) {
    return <Loading />;
  }

  return <>{children}</>;
};

export default PlatformExperiment;
