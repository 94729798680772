import { Button, ButtonProps, Tooltip } from '@chakra-ui/react';
import { FC, useCallback, useContext, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDefaultStyles } from '../../../../hooks/useDefaultStyles';
import { WholeAppContext } from '../../../agenda/WholeApp.context';
import { FlashcardIcon } from '../../icons/FlashcardIcon';

interface CardsButtonInterface extends ButtonProps {
  lessonId?: string,
}

export const CardsButton: FC<CardsButtonInterface> = ({ lessonId, ...props }: CardsButtonInterface) => {
  const [isMouseOver, setIsMouseOver] = useState(false);
  const { colors } = useDefaultStyles();
  const { hideElementsThatTriggerModals } = useContext(WholeAppContext);
  const { lessonId: lessonIdParam } = useParams<{ lessonId: string }>();

  const currLessonId = useMemo(() => {
    if (lessonId) {
      return lessonId;
    }
    if (lessonIdParam) {
      return lessonIdParam;
    }
    return undefined;
  }, [lessonId, lessonIdParam]);

  const handleOnClick = useCallback(() => {
    window.open(`/baralhos/comunidade?${currLessonId ? `lessonId=${currLessonId}` : ''} `, '_blank');
  }, [currLessonId]);

  if (hideElementsThatTriggerModals) {
    return <></>;
  }

  return (
    <Tooltip label="Criar flashcards">
      <Button
        variant="unstyled"
        onClick={handleOnClick}
        onMouseOver={() => setIsMouseOver(true)}
        onMouseLeave={() => setIsMouseOver(false)}
        _focus={{
          border: 'none',
        }}
      >
        <FlashcardIcon
          hoverColor={colors.secondary.keep}
          isMouseOver={isMouseOver}
        />
      </Button>
    </Tooltip>
  );
};
