import {
  Box, Circle, CircularProgress, CircularProgressLabel,
  Flex, Heading, HStack, Image, Text, VStack,
} from '@chakra-ui/react';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC, useMemo } from 'react';
import { useDefaultStyles } from '../../../hooks/useDefaultStyles';
import { WeekDocType } from './WeekProgress';

type WeekTrophyCardType = {
  weekDoc: WeekDocType,
}

const TROPHY_DICTIONARY = {
  bronze: 'bronze',
  silver: 'prata',
  gold: 'ouro',
};

export const WeekTrophyCard: FC<WeekTrophyCardType> = ({ weekDoc }) => {
  const { cardBorder, colors } = useDefaultStyles();

  const numberOfActivities = useMemo(() => {
    return weekDoc?.length || 0;
  }, [weekDoc]);

  const fragmentsAchievedSummary = useMemo(() => {
    const initial = {
      bronze: 0,
      silver: 0,
      gold: 0,
    };

    if (weekDoc) {
      return weekDoc.reduce((acc, curr) => {
        if (curr.done >= curr.bronze) {
          acc.bronze += 1;
        }
        if (curr.done >= curr.silver) {
          acc.silver += 1;
        }
        if (curr.done >= curr.gold) {
          acc.gold += 1;
        }
        return acc;
      }, initial);
    }
    return initial;
  }, [weekDoc]);

  const nextTrophy = useMemo(() => {
    const { bronze, silver } = fragmentsAchievedSummary;
    if (bronze < numberOfActivities) {
      return 'bronze';
    }
    if (silver < numberOfActivities) {
      return 'silver';
    }
    return 'gold';
  }, [fragmentsAchievedSummary, numberOfActivities]);

  return (
    <Flex
      p={{ base: 4, md: 8 }}
      w="100%"
      {...cardBorder}
      flexFlow="column"
      gridGap={4}
    >
      <Heading size="md">Troféu semanal</Heading>
      <Text>Faça suas tarefas para conquistar o seu troféu semanal!</Text>
      <VStack align="center" maxW="90%" w="100%" mx="auto" spacing={4}>
        <CircularProgress
          className={
            `weekly-progress-circle ${fragmentsAchievedSummary.gold === numberOfActivities ? 'golden-trophy-effect' : ''
            }`
          }
          size="220px"
          value={(fragmentsAchievedSummary[nextTrophy] * 100) / numberOfActivities}
          thickness="2px"
          color={fragmentsAchievedSummary.gold === numberOfActivities ? colors.prizes.gold : colors.secondary.keep}
          trackColor={colors.darkGray.goDarker}
        >
          <CircularProgressLabel>
            <Flex justify="center">
              <Box boxSize="110px">
                <Image
                  src={`/images/trophies/${nextTrophy}.svg`}
                />
              </Box>
            </Flex>
          </CircularProgressLabel>
        </CircularProgress>
        <Flex
          w="100%"
          justify="space-between"
          fontSize={14}
          align="center"
          gridGap={3}
          p={4}
          borderRadius={10}
          bgColor={colors.lighter.goAlmostFullDarker}
        >
          <Text>{`Fragmentos de troféu ${TROPHY_DICTIONARY[nextTrophy]} conquistados`}</Text>
          <HStack>
            {
              new Array(numberOfActivities).fill(0).map((_, i) => (
                <Flex key={Math.random()} position="relative">
                  <Circle size={4} bgColor={colors.prizes[nextTrophy]} />
                  {
                    fragmentsAchievedSummary[nextTrophy] >= i + 1 && (
                      <Circle
                        size={2}
                        position="absolute"
                        bgColor={colors.white.keep}
                        color={colors.green.keep}
                        fontSize={9}
                        bottom={0}
                        right={-1}
                      >
                        <FontAwesomeIcon icon={faCheckCircle} />
                      </Circle>
                    )
                  }
                </Flex>
              ))
            }
          </HStack>
        </Flex>
      </VStack>
    </Flex>
  );
};
