/* eslint-disable no-undef */
import React, {
  FC, ReactNode, useCallback, useContext, useEffect, useMemo, useRef, useState,
} from 'react';
import {
  faHighlighter, faPalette, faUndo, faFlag, faTrashAlt, faCut,
} from '@fortawesome/free-solid-svg-icons';
import { faFlag as faFlagRegular } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Flex, HStack, Text, VStack } from '@chakra-ui/react';
import { useHighlight } from '../../hooks/useHighlight';
import { CircleIconButton } from './CircleIconButton.component';
import { useDefaultStyles } from '../../hooks/useDefaultStyles';
import { PrivateContext } from '../../Private.context';
import { useKeyPressed } from '../../hooks/useKeyPressed';
import { KeyboardTipsButton } from '../agenda/_components/KeyboardTipsButton';
import { useDevice } from '../../hooks/useDevice';
import { KeyboardKey } from '../agenda/_components/KeyboardKey';
import { WholeAppContext } from '../agenda/WholeApp.context';

type HighlightableMarkdownProps = {
  id: string;
  index: number;
  children?: ReactNode;
}

export const HighlightableMarkdown: FC<HighlightableMarkdownProps> = ({
  id, index, children,
}: HighlightableMarkdownProps) => {
  const outerRef = useRef<HTMLDivElement | null>(null);
  const { areShortcutsEnabled } = useContext(WholeAppContext);
  const ref = useRef<HTMLDivElement | null>(null);
  const { colors } = useDefaultStyles();
  const {
    onDoubtClick,
    isInDoubtOnCurrQuestion,
    shouldEnableMarkDoubts,
  } = useContext(PrivateContext);

  const {
    currentColor,
    onChangeColor,
    highlightSelection,
    highlightIsActive,
    setHighlightIsActive,
  } = useHighlight();

  // TODO: refatorar com o listOfColors que está no useHighlight
  const listOfColors = useMemo(() => ([
    { iconColor: 'aristo.500', cursorColor: 'highlight-blue' },
    { iconColor: '#E2231A', cursorColor: 'highlight-red' },
    { iconColor: '#55D455', cursorColor: 'highlight-green' },
    { iconColor: '#EBC756', cursorColor: 'highlight-yellow' },
  ]
  ), []);

  const [highlightHistory, setHighlightHistory] = useState<Record<string, string[]>>({});
  const [outerRefInnerHTML, setOuterRefInnerHTML] = useState('');
  const leftControlPressed = useKeyPressed('ControlLeft');
  const zPressed = useKeyPressed('KeyZ');
  const shiftLeftPressed = useKeyPressed('ShiftLeft');
  const deletePressed = useKeyPressed('Delete');
  const spacePressed = useKeyPressed('Space');
  const device = useDevice();

  useEffect(() => {
    if (ref.current) {
      const initialInnerHTML = ref.current.innerHTML;
      if (!(id in highlightHistory)) {
        setOuterRefInnerHTML(initialInnerHTML);
        setHighlightHistory(Object.assign(highlightHistory, { [id]: [initialInnerHTML] }));
      }
    }
  }, [highlightHistory, id, outerRefInnerHTML]);

  useEffect(() => {
    if (id in highlightHistory) {
      const len = highlightHistory[id].length;
      setOuterRefInnerHTML(highlightHistory[id][len - 1]);
    }
  }, [id, highlightHistory, outerRefInnerHTML]);

  const applyHighlight = useCallback(() => {
    const selection = document.getSelection();
    if (selection && !selection.isCollapsed) {
      highlightSelection();
      selection.collapseToStart();
      if (ref.current && ref.current.textContent?.startsWith(`${index + 1}.`)) {
        setHighlightHistory(prev => {
          const len = highlightHistory[id].length;
          const currentHTML = outerRef.current?.innerHTML ?? '';
          if (highlightHistory[id][len - 1] !== currentHTML) {
            return Object.assign(prev, { [id]: [...highlightHistory[id], outerRef.current?.innerHTML ?? ''] });
          }
          return highlightHistory;
        });
      }
    }
  }, [highlightSelection, highlightHistory, id, index]);

  const listener = useCallback(() => {
    if (highlightIsActive) {
      applyHighlight();
    }
  }, [applyHighlight, highlightIsActive]);

  const onShift = useCallback(() => {
    applyHighlight();
    setHighlightIsActive(old => !old);
  }, [applyHighlight, setHighlightIsActive]);

  const onUndo = useCallback((options?: { all: boolean }) => {
    setHighlightHistory(prev => {
      const len = prev[id].length;
      setOuterRefInnerHTML(prev[id][len - 1]);
      if (len > 1) {
        return { ...prev, [id]: prev[id].slice(0, options?.all ? 1 : -1) };
      }
      return prev;
    });
  }, [id]);

  useEffect(() => {
    // const prevOnKeyUp = document.onkeyup;
    if (areShortcutsEnabled) {
      if (leftControlPressed && zPressed) {
        onUndo();
      } else if (shiftLeftPressed) {
        onShift();
      } else if (leftControlPressed && deletePressed) {
        onUndo({ all: true });
      } else if (leftControlPressed && spacePressed) {
        onChangeColor();
      }
    }
  }, [
    areShortcutsEnabled, deletePressed, id, leftControlPressed,
    onChangeColor, onDoubtClick, onShift, onUndo, shiftLeftPressed, shouldEnableMarkDoubts,
    spacePressed, zPressed,
  ]);

  return (
    <>
      <HStack
        spacing={4}
        flexFlow="row"
        justifyContent="center"
        alignItems="center"
      >
        <CircleIconButton
          color={listOfColors[currentColor].iconColor}
          faIcon={faHighlighter}
          onClick={onShift}
          tooltipLabel={`Grifar${areShortcutsEnabled ? ' (Shift)' : ''}`}
        />
        <CircleIconButton
          color={listOfColors[currentColor].iconColor}
          faIcon={faPalette}
          onClick={onChangeColor}
          tooltipLabel={`Mudar cor${areShortcutsEnabled ? ' (Ctrl + Espaço)' : ''}`}
        />
        <CircleIconButton
          color={colors.secondary.keep}
          faIcon={faUndo}
          onClick={onUndo}
          tooltipLabel={`Desfazer${areShortcutsEnabled ? ' (Ctrl + Z)' : ''}`}
        />
        {
          shouldEnableMarkDoubts ? (
            <CircleIconButton
              faIcon={isInDoubtOnCurrQuestion ? faFlag : faFlagRegular}
              color={isInDoubtOnCurrQuestion ? colors.red.keep : colors.secondary.keep}
              onClick={onDoubtClick}
              tooltipLabel={`${isInDoubtOnCurrQuestion
                ? 'Remover'
                : 'Marcar'} dúvida`}
            />
          ) : (
            <CircleIconButton
              faIcon={faTrashAlt}
              color={colors.secondary.keep}
              onClick={() => onUndo({ all: true })}
              tooltipLabel="Limpar (Ctrl + Del)"
            />
          )
        }
        <KeyboardTipsButton
          variant="circle"
          show={device === 'web'}
        >
          <VStack spacing={4}>
            <Text color={colors.secondary.keep} fontWeight="bold">Marcar e eliminar alternativas</Text>
            <HStack spacing={5}>
              <KeyboardKey
                text="1"
                bgColor="green.200"
                icon={<Text>A</Text>}
                iconColor="green.500"
              />
              <KeyboardKey
                text="2"
                bgColor="yellow.200"
                iconColor="yellow.500"
                icon={<Text>B</Text>}
              />
              <KeyboardKey
                text="3"
                bgColor="red.200"
                iconColor="red.500"
                icon={<Text>C</Text>}
              />
              <KeyboardKey
                text="4"
                bgColor="cyan.200"
                iconColor="cyan.500"
                icon={<Text>D</Text>}
              />
              <KeyboardKey
                text="5"
                bgColor="purple.200"
                iconColor="purple.500"
                icon={<Text>E</Text>}
              />
            </HStack>
            <HStack spacing={5} pl={8}>
              <KeyboardKey
                text="Q"
                bgColor="green.100"
                iconColor="green.400"
                icon={<FontAwesomeIcon icon={faCut} />}
              />
              <KeyboardKey
                text="W"
                bgColor="yellow.100"
                iconColor="yellow.400"
                icon={<FontAwesomeIcon icon={faCut} />}
              />
              <KeyboardKey
                text="E"
                bgColor="red.100"
                iconColor="red.400"
                icon={<FontAwesomeIcon icon={faCut} />}
              />
              <KeyboardKey
                text="R"
                bgColor="cyan.100"
                iconColor="cyan.400"
                icon={<FontAwesomeIcon icon={faCut} />}
              />
              <KeyboardKey
                text="T"
                bgColor="purple.100"
                iconColor="purple.400"
                icon={<FontAwesomeIcon icon={faCut} />}
              />
            </HStack>
            <Text color={colors.secondary.keep} fontWeight="bold">Responder e navegar pelas questões</Text>
            <VStack>
              <Flex w="100%" align="center">
                <KeyboardKey
                  bgColor="green.100"
                  fontSize={11}
                  mr={2}
                  text="Shift direito"
                />
                <Text fontSize={12} color="green.500" mr={6}>
                  Comentário
                </Text>
                <KeyboardKey
                  text="↑"
                />
              </Flex>
              <HStack spacing={8}>
                <KeyboardKey
                  fontSize={11}
                  text="Ctrl direito"
                  bgColor="pink.100"
                />
                <HStack>
                  <KeyboardKey
                    text="←"
                    bgColor="blue.100"
                  />
                  <KeyboardKey
                    text="↓"
                  />
                  <KeyboardKey
                    text="→"
                    bgColor="orange.100"
                  />
                </HStack>
              </HStack>
            </VStack>
            <Flex w="80%" pl={5} fontSize={12}>
              <Text mr={8} color="pink.500">Responder*</Text>
              <Text mr={20} color="blue.500">Anterior</Text>
              <Text color="orange.500">Próxima</Text>
            </Flex>
          </VStack>
          <Flex mt={2} w="100%" justify="flex-end">
            <Text fontSize={10} color="pink.500">* Apenas para algumas atividades</Text>
          </Flex>
        </KeyboardTipsButton>

      </HStack>

      <Box
        className={highlightIsActive ? listOfColors[currentColor].cursorColor : ''}
        ref={outerRef}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: outerRefInnerHTML }}
        onMouseUp={listener}
      />
      <Box
        ref={ref}
        display="none"
      >
        {children}
      </Box>
    </>
  );
};
