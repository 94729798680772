import { gql, QueryResult, useQuery } from '@apollo/client';
import { PreChoiceInterface } from './mock-exam';
import { Question } from './questions';

export interface ExamInterface {
  _id: string;
  profile: string;
  finished: boolean;
  preChoice: PreChoiceInterface[];
  createdAt: Date;
  updatedAt: Date;
  calendarDate: string;
  institution: string;
  uf: string;
  year: string;
  questions: Question[];
  examTemplate: string;
}

interface GetOrCreateExamActivityInput {
  examTemplate: string;
  calendarDate: string;
}

interface GetOrCreateExamActivityOutput {
  exam: ExamInterface;
}

const GET_OR_CREATE_EXAM_ACTIVITY = gql`
  query GetOrCreateExamActivity (
    $examTemplate: ObjectId!
    $calendarDate: CalendarDate!
  ) {
    exam: getOrCreateExamActivity (
      examTemplate: $examTemplate
      calendarDate: $calendarDate
    ) {
      _id
      profile
      finished
      preChoice {
        questionId
        selected
        essay
      }
      createdAt
      updatedAt
      calendarDate
      institution
      uf
      year
      questions {
        _id
        body
        choices
        answersProportion
        correctChoice
        institution
        uf
        year
        examOrder
        examType
        complement
        classification
        difficulty
        createdAt
        updatedAt
        comment {
          author
          profile
          title
          body
          createdAt
          updatedAt
        }
        answer
        essay
        isEssay
      }
      examTemplate
    }
  }
`;

export function useGetOrCreateExam({
  calendarDate,
  examTemplate,
}: GetOrCreateExamActivityInput): QueryResult<GetOrCreateExamActivityOutput, GetOrCreateExamActivityInput> {
  return useQuery<GetOrCreateExamActivityOutput, GetOrCreateExamActivityInput>(GET_OR_CREATE_EXAM_ACTIVITY, {
    variables: {
      calendarDate,
      examTemplate,
    },
    fetchPolicy: 'network-only',
    skip: examTemplate === '',
  });
}
