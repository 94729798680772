import { FC } from 'react';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, Flex } from '@chakra-ui/react';

interface SliderProps {
  onPrev?: () => void;
  onNext?: () => void;
  noPrev?: boolean;
  noNext?: boolean;
  color?: string;
  widthOnMobile?: string;
}

const Slider: FC<SliderProps> = ({
  onPrev, onNext, children, noPrev, noNext, color = 'light.200', widthOnMobile,
}) => {
  return (
    <Flex w={{ base: widthOnMobile, md: '100%' }} maxW={950} align="center" justify="center" m="0 auto" px={2}>
      <Button variant="unstyled" color={color} type="button" onClick={onPrev} disabled={noPrev}>
        <FontAwesomeIcon icon={faChevronLeft} />
      </Button>
      <Box flex={1} maxW={1000}>
        {children}
      </Box>
      <Button variant="unstyled" color={color} type="button" onClick={onNext} disabled={noNext}>
        <FontAwesomeIcon icon={faChevronRight} />
      </Button>
    </Flex>
  );
};

export default Slider;
