import { Circle, Image } from '@chakra-ui/react';
import { FC } from 'react';
import { useDefaultStyles } from '../../hooks/useDefaultStyles';

export const CustomSirenaButton: FC = () => {
  const { colors } = useDefaultStyles();
  return (
    <Circle
      as="button"
      onClick={() => window.open('https://wa.me/5521996998020', '_blank')}
      size={{ base: '2rem', md: '3.5rem' }}
      bgColor={colors.secondary.keep}
      position="fixed"
      bottom={{ base: '4rem', md: '1.5rem' }}
      right={{ base: '1rem', md: '2rem' }}
    >
      <Image src="/images/wpp.png" w={{ base: '1.1rem', md: '2rem' }} h={{ base: '1.1rem', md: '2rem' }} />
    </Circle>
  );
};
