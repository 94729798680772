import { gql, useQuery } from '@apollo/client';

type PlannerType = {
  _id: string;
  url: string;
  title: string;
  subtitle?: string;
}

const GET_PLANNERS = gql`
  query GetPlanners {
    getPlanners {
      _id
      url
      title
      subtitle
    }
  }
`;

type GetPlannersOutputType = {
  getPlanners: PlannerType[],
}

export function usePlanners() {
  const planners = useQuery<GetPlannersOutputType>(GET_PLANNERS);
  return { ...planners, plannersData: planners.data?.getPlanners ?? [] };
}
