/* eslint-disable react/jsx-props-no-spreading */
import React, { FC, Component } from 'react';
import clsx from 'clsx';
import { colorsType } from '../types/types';

// eslint-disable-next-line no-undef
type AnyTag<P> = keyof JSX.IntrinsicElements | FC<P> | (new (props: P) => Component)

export interface TextComponentProps {
  align?: 'left' | 'right' | 'center' | 'justify';
  color?: colorsType;
  inline?: boolean;
  center?: boolean;
  right?: boolean;
  bold?: boolean;
  noBreak?: boolean;
  underline?: boolean;
  uppercase?: boolean;
  ellipsis?: boolean;
  link?: boolean;
  className?: string;
  tooltip?: string;
  lineClamp?: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  tag?: AnyTag<any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [x: string]: any;
}

const Text: FC<TextComponentProps> = ({
  align,
  color = 'inherit',
  center = false,
  right = false,
  bold = false,
  ellipsis = false,
  underline = false,
  uppercase = false,
  noBreak = false,
  link = false,
  children,
  className,
  tag = 'span',
  tooltip,
  inline = false,
  lineClamp = 0,
  ...rest
}) => {
  const Tag = tag;

  const cls = {
    'text-align-center': center,
    'text-align-right': right,
    'text-bold': bold,
    'text-uppercase': uppercase,
    'text-underline': underline,
    'text-ellipsis': lineClamp > 1 ? false : (ellipsis || lineClamp === 1),
    'text-link': link,
    'no-break': noBreak,
  };

  const parentCls = {
    'text-inline': inline,
  };

  const colorCls = color ? `color-${color}` : '';

  return (
    <div className={clsx(className, parentCls)} title={tooltip} {...rest}>
      <Tag className={clsx(
        cls,
        colorCls,
        align && `text-align-${align}`,
        lineClamp > 1 && `line-clamp-${lineClamp}`,
      )}
      >
        {children}
      </Tag>
    </div>
  );
};

export default Text;
