import {
  Box,
  Text,
  Center,
  Heading,
  Flex,
  VStack,
} from '@chakra-ui/react';
import { FC } from 'react';
import { useDefaultStyles } from '../../../hooks/useDefaultStyles';
import { LearnMoreBtnWeekProgress } from './LearnMoreBtnWeekProgress';
import { TheoreticalTypeProgress } from './TheoreticalTypeProgress';

export type WeekDocType = {
  bronze: number;
  silver: number;
  gold: number;
  type: string;
  abbr: string;
  done: number;
  total: number;
  icon: string;
}[] | undefined

type WeekProgressInputType = {
  weekDoc: WeekDocType,
}

export const WeekProgress: FC<WeekProgressInputType> = ({ weekDoc }) => {
  const { cardBorder } = useDefaultStyles();

  return (
    <Box
      p={{ base: 4, md: 8 }}
      w="100%"
      {...cardBorder}
    >
      <VStack w="100%" spacing={5}>
        <Flex w="100%" justify="space-between" align="flex-start">
          <Heading size="md">Metas semanais</Heading>
          <LearnMoreBtnWeekProgress w={40} weekDoc={weekDoc} />
        </Flex>
        <Flex w="100%">
          <Text>
            Complete todos os estudos abaixo para conquistar todos os fragmentos de troféu.
          </Text>
        </Flex>
      </VStack>
      <Center flexFlow="column">
        <Flex w="100%" flexWrap="wrap" justify="space-between">
          {weekDoc && weekDoc.filter(x => x.total !== 0).map(item => (
            <Flex my={5} key={Math.random()} w={{ base: '100%', md: '45%' }} flexFlow="column">
              <Text fontSize={12} fontWeight="bold">{item.type}</Text>
              <TheoreticalTypeProgress {...item} />
            </Flex>
          ))}
        </Flex>
      </Center>
    </Box>
  );
};
