import {
  FC, useContext, useMemo,
} from 'react';
import { Flex } from '@chakra-ui/react';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useQuestionMany } from '../../../api/agenda/questions';
import { getDateParam } from '../../../hooks/getDateParam';
import { getTheoreticalType } from '../../../hooks/getTheoreticalType';
import QuestionEmpty from './QuestionEmpty';
import { PrivateContext } from '../../../Private.context';
import { Questions } from './Questions.component';

const AgendaQuestionsData: FC = () => {
  const params = useParams<{ lessonId: string }>();
  const { agenda } = useContext(PrivateContext);

  const theoreticalType = getTheoreticalType();

  const agendaIds = useMemo(() => {
    if (theoreticalType === 'theoreticalReview') {
      const lessonReview = agenda?.theoreticalReview.find(item => item.lessonId === params.lessonId);
      return lessonReview;
    }
    if (theoreticalType === 'theoreticalStudy') {
      const lessonReview = agenda?.theoreticalStudy.find(item => item.lessonId === params.lessonId);
      return lessonReview;
    }
    if (theoreticalType === 'smartReview') {
      return agenda?.smartReview || undefined;
    }

    return undefined;
  }, [agenda?.smartReview, agenda?.theoreticalReview, agenda?.theoreticalStudy, params.lessonId, theoreticalType]);

  const questionObj = agendaIds?.questions;
  const ids = questionObj || [];

  const query = useQuestionMany({
    ids: ids || [],
    calendarDate: getDateParam(),
    type: getTheoreticalType(),
  });

  const obj = useMemo(() => {
    const { data } = query;

    if (data) {
      return ({
        loading: query.loading,
        data: data.questionMany,
        length: data.questionMany.length,
      });
    }
    return ({
      loading: query.loading,
      data: [],
      length: 0,
    });
  }, [query]);

  if (obj.loading) {
    return (
      <Flex align="center" justify="center" style={{ minHeight: 400 }}>
        <FontAwesomeIcon icon={faSpinner} spin size="5x" className="color-dark-gray" />
      </Flex>
    );
  }

  if (!obj.loading && obj.length > 0 && obj.data) {
    return (
      <Questions
        model="questions"
        data={obj.data}
        serverPreChoices={agenda?.smartReviewPreChoice?.map(item => {
          const customSelected = obj.data.find(q => q._id === item.questionId)?.answer ?? item.selected;
          return {
            ...item,
            selected: customSelected,
          };
        })}
      />
    );
  }

  return <QuestionEmpty />;
};

export default AgendaQuestionsData;
