import { Button, Flex, HStack } from '@chakra-ui/react';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, useCallback } from 'react';

const TABLE_LINE_CHUNKS_LEN = 5;

type TableVisibleLinesHandlerType = {
  noOfVisibleLines: number,
  setNoOfVisibleLines: React.Dispatch<React.SetStateAction<number>>,
  maxLen: number,
}

export const TableVisibleLinesHandler: FC<TableVisibleLinesHandlerType> = ({
  noOfVisibleLines,
  setNoOfVisibleLines,
  maxLen,
}) => {
  const handleShowMoreClick = useCallback((reverse = false) => {
    const currLen = noOfVisibleLines;
    const stepRest = currLen % TABLE_LINE_CHUNKS_LEN;
    setNoOfVisibleLines(prev => {
      if (!reverse) {
        if (noOfVisibleLines + TABLE_LINE_CHUNKS_LEN > maxLen) {
          return maxLen;
        } return prev + TABLE_LINE_CHUNKS_LEN;
      }
      if (noOfVisibleLines - TABLE_LINE_CHUNKS_LEN < TABLE_LINE_CHUNKS_LEN) {
        return TABLE_LINE_CHUNKS_LEN;
      }
      if (stepRest) {
        return prev - stepRest;
      }
      return prev - TABLE_LINE_CHUNKS_LEN;
    });
  }, [maxLen, noOfVisibleLines, setNoOfVisibleLines]);

  return (
    <Flex justifyContent={{ base: 'center', md: 'space-between' }} mt={2}>
      <HStack>
        <Button
          disabled={noOfVisibleLines <= TABLE_LINE_CHUNKS_LEN}
          onClick={() => handleShowMoreClick(true)}
        >
          -
        </Button>
        <Button
          disabled={noOfVisibleLines === maxLen}
          onClick={() => handleShowMoreClick()}
        >
          +
        </Button>
        <Button
          onClick={() => {
            setNoOfVisibleLines(TABLE_LINE_CHUNKS_LEN);
          }}
          disabled={noOfVisibleLines <= TABLE_LINE_CHUNKS_LEN}
        >
          <FontAwesomeIcon icon={faChevronUp} />
        </Button>
        <Button
          disabled={noOfVisibleLines === maxLen}
          onClick={() => setNoOfVisibleLines(() => maxLen)}
        >
          <FontAwesomeIcon icon={faChevronDown} />
        </Button>
      </HStack>
    </Flex>
  );
};
