import { parseISO } from 'date-fns';
import {
  CommentDataType, ForumCommentType, ForumUserIdentificationType,
} from '../views/_components/Forum/types/forum.types';
import { IS_DEV_ENV, SPLIT_MARKER } from '../views/_components/Forum/Forum';

export const getCommentChildren = ({
  comment, allComments,
}: {
  comment: ForumCommentType, allComments: ForumCommentType[]
}) => {
  const targetCommentsIds = comment.respostas;
  if (targetCommentsIds) {
    return allComments.filter(curr => targetCommentsIds.includes(curr._id));
  } return [];
};

export const formatName = (name?: string, device?: 'mobile' | 'web') => {
  const MAX_FULL_NAME_LENGTH = (device === 'mobile' ? 20 : 30);
  const MAX_FIRST_NAME_LENGTH = (device === 'mobile' ? 12 : 15);
  if (name) {
    const splitted = name.trim().split(' ');
    const nameOnTitleFormat = splitted.map(x => x[0]?.toUpperCase() + x.slice(1)?.toLowerCase());
    const nameWordsCount = nameOnTitleFormat.length;
    if (nameWordsCount === 1) {
      if (name.length > MAX_FULL_NAME_LENGTH) {
        return `${nameOnTitleFormat[0].slice(0, MAX_FULL_NAME_LENGTH)}...`;
      }
      return nameOnTitleFormat[0];
    }
    if (nameWordsCount > 1) {
      const firstName = (nameOnTitleFormat[0].length > MAX_FIRST_NAME_LENGTH)
        ? `${nameOnTitleFormat[0][0]}.`
        : nameOnTitleFormat[0];
      const lastName = (nameOnTitleFormat[nameWordsCount - 1].length > MAX_FULL_NAME_LENGTH - MAX_FIRST_NAME_LENGTH)
        ? `${nameOnTitleFormat[nameWordsCount - 1][0]}.`
        : nameOnTitleFormat[nameWordsCount - 1];
      return `${firstName} ${lastName}`;
    }
  } return 'Aristoter';
};

const getTargetNameByCommentContent = ({
  content,
  forumUserIdMap,
}: {
  content: string,
  forumUserIdMap: Record<string, ForumUserIdentificationType>,
}) => {
  const splitted = content.split(SPLIT_MARKER);
  if (splitted.length > 1) {
    const [aristoAcademyUserId, commentContent] = splitted;
    return {
      targetUserName: formatName(forumUserIdMap[aristoAcademyUserId]?.name),
      content: commentContent,
    };
  } return {
    targetUserName: undefined,
    content,
  };
};

export const generateCommentData = ({
  comment, forumUserIdMap, forumId, userId,
}: {
  comment: ForumCommentType,
  forumUserIdMap: Record<string, ForumUserIdentificationType>,
  forumId?: string,
  userId: string,
}) => {
  const identificationExists = Boolean(forumUserIdMap[comment['Created By']]);
  const { targetUserName, content } = getTargetNameByCommentContent({
    content: comment.comentario || '',
    forumUserIdMap,
  });

  const creationDate = comment['Created Date'];

  return {
    commentId: comment._id,
    userName: identificationExists ? formatName(forumUserIdMap[comment['Created By']].name) : '',
    nick: identificationExists ? forumUserIdMap[comment['Created By']].nick : '',
    createdAt: creationDate && typeof creationDate === 'string' ? parseISO(creationDate) : creationDate,
    content,
    targetUserName,
    userId: comment['Created By'],
    aristoClassId: identificationExists ? forumUserIdMap[comment['Created By']].aristoClassId : '',
    profileImage: identificationExists ? forumUserIdMap[comment['Created By']].profileImage : '',
    liked: comment.UsersReacted,
    answers: comment.commentAnswers,
    answerIds: comment.respostas,
    isPrivate: comment.Privado,
    isFirstLevel: !comment['Resposta?'],
    hasUserRated: comment.UsersEval?.includes(userId),
    forumId,
    specialist: identificationExists ? forumUserIdMap[comment['Created By']].specialist : '',
  } as CommentDataType;
};

export const getFirstLevelCommentId = ({
  currCommentId,
  allComments,
}: {
  currCommentId: string,
  allComments: ForumCommentType[],
}) => {
  const parentComment = allComments.filter(comment => {
    if (comment.respostas) {
      return comment.respostas.includes(currCommentId);
    } return false;
  });
  if (parentComment.length) {
    return parentComment[0]._id;
  } return undefined;
};

export const getUserPicture = (aristoClassID?: string) => {
  if (aristoClassID) {
    return `https://firebasestorage.googleapis.com/v0/b/jj-${
      IS_DEV_ENV ? 'dev-a2b7a' : 'prod-e38e7'}.appspot.com/o/images%2Fusers%2F${aristoClassID}?alt=media&`;
  } return '';
};
