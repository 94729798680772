import { gql, useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { toast } from 'react-toastify';
import errorHandler from '../error/errorHandler';

interface CreateTheoreticalExtraActivityInput {
  classification: string[];
}

interface CreateTheoreticalExtraActivityOutput {
  createTheoreticalExtraActivity: {
    classification: string[],
    _id: string,
    contents: {
      videos?: string[],
      texts?: string[],
      mindmaps?: string[],
      flashcards?: string[],
      questions?: string[],
    },
  };
}

const CREATE_THEORETICAL_EXTRA_ACTIVITY = gql`
  mutation CreateTheoreticalExtraActivity ($classification: [String!]!) {
    createTheoreticalExtraActivity (classification: $classification) {
      _id
      contents {
        videos
        texts
        mindmaps
        flashcards
        questions
      }
    }
  }
`;

interface FinishExtraTheoreticalStudyExtraActivityInput {
  id: string;
  classification: string[];
}

interface FinishExtraTheoreticalStudyExtraActivityOutput {
  finishExtraTheoreticalStudyExtraActivity: boolean;
}

const FINISH_EXTRA_THEORETICAL_STUDY_EXTRA_ACTIVITY = gql`
  mutation FinishExtraTheoreticalStudyExtraActivity (
    $id: ObjectId!
    $classification: [String!]!
  ) {
    finishExtraTheoreticalStudyExtraActivity (
      _id: $id
      classification: $classification
    )
  }
`;

interface UseTheoreticalStudyExtraActivityInterface {
  onFinishExtraTheoreticalStudyExtraActivity: (props: FinishExtraTheoreticalStudyExtraActivityInput) => Promise<void>;
  onCreateTheoreticalExtraActivity: ({ classification }:
    CreateTheoreticalExtraActivityInput) => Promise<string | undefined>;
}

export function useExtraTheoreticalStudy(): UseTheoreticalStudyExtraActivityInterface {
  const [finish] = useMutation<
    FinishExtraTheoreticalStudyExtraActivityOutput,
    FinishExtraTheoreticalStudyExtraActivityInput
  >(FINISH_EXTRA_THEORETICAL_STUDY_EXTRA_ACTIVITY, {
    onError: errorHandler,
  });

  const [createActivity] = useMutation<
    CreateTheoreticalExtraActivityOutput,
    CreateTheoreticalExtraActivityInput
  >(CREATE_THEORETICAL_EXTRA_ACTIVITY, {
    onError: errorHandler,
    onCompleted: data => {
      if (data !== null) {
        toast.success('Atividade de estudo teórico criada com sucesso!');
      }
    },
  });

  const handleFinish = useCallback(async ({
    id,
    classification,
  }: FinishExtraTheoreticalStudyExtraActivityInput) => {
    await finish({
      variables: {
        id,
        classification,
      },
    });
  }, [finish]);

  const handleCreate = useCallback(async ({
    classification,
  }: CreateTheoreticalExtraActivityInput) => {
    const act = await createActivity({
      variables: {
        classification,
      },
    });
    return act.data?.createTheoreticalExtraActivity._id;
  }, [createActivity]);

  return {
    onFinishExtraTheoreticalStudyExtraActivity: handleFinish,
    onCreateTheoreticalExtraActivity: handleCreate,
  };
}
