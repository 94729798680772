import {
  FC, useContext, useMemo,
} from 'react';
import { useParams } from 'react-router-dom';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTextMany } from '../../../api/agenda/texts';
import Flex from '../../../lib/components/Flex';
import AgendaTexts from './AgendaTexts';
import TextEmpty from './TextEmpty';
import { PrivateContext } from '../../../Private.context';
import { getTheoreticalType } from '../../../hooks/getTheoreticalType';

const AgendaTextsData: FC = () => {
  const params = useParams<{ lessonId: string }>();
  const { agenda } = useContext(PrivateContext);

  const theoreticalType = getTheoreticalType();

  const agendaIds = useMemo(() => {
    if (theoreticalType === 'theoreticalStudy') {
      const lessonStudy = agenda?.theoreticalStudy.find(item => item.lessonId === params.lessonId);
      return lessonStudy;
    }
    if (theoreticalType === 'theoreticalReview') {
      const lessonReview = agenda?.theoreticalReview.find(item => item.lessonId === params.lessonId);
      return lessonReview;
    }

    return undefined;
  }, [agenda?.theoreticalReview, agenda?.theoreticalStudy, params.lessonId, theoreticalType]);

  const textsObj = agendaIds?.texts;

  const query = useTextMany({
    ids: textsObj || [],
  });

  const obj = useMemo(() => {
    if (query.data) {
      return ({
        loading: query.loading,
        data: query.data.textMany,
        length: query.data.textMany.length,
      });
    }
    return ({
      loading: query.loading,
      data: [],
      length: 0,
    });
  }, [query]);

  if (obj.loading) {
    return (
      <Flex align="center" justify="center" style={{ minHeight: 400 }}>
        <FontAwesomeIcon icon={faSpinner} spin size="5x" className="color-dark-gray" />
      </Flex>
    );
  }

  if (!obj.loading && obj.length > 0 && obj.data) {
    return (
      <AgendaTexts
        data={obj.data}
      />
    );
  }

  return <TextEmpty />;
};

export default AgendaTextsData;
