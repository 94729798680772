import { Box, Flex, Image, Text } from '@chakra-ui/react';
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC } from 'react';
import { useDefaultStyles } from '../../../hooks/useDefaultStyles';

interface PreviewFlashcardProps {
  index: number;
  onClick: (i: number) => () => void;
  active?: boolean;
  answer?: number;
}

export const PreviewFlashcard: FC<PreviewFlashcardProps> = ({
  index, onClick, active, answer,
}) => {
  const { colors, hueRotate } = useDefaultStyles();
  return (
    <Box as="button" onClick={onClick(index)} m={1}>
      <Box
        borderRadius={4}
        width="52px"
        height="65px"
        backgroundColor={colors.primary.goLighter}
        color={colors.lighter.keep}
        opacity={active ? 1 : 0.5}
        transform={active ? 'scale(1.1)' : ''}
      >
        <Flex direction="column">
          <Flex m={1} align="center">
            <Box width="20px">
              <Image filter={hueRotate()} src="/images/hash.svg" alt="hash" />
            </Box>
            <Text pl={1}>{index + 1}</Text>
          </Flex>
          <Flex align="end" justify="end">
            {(answer === 0 || answer === 1) && (
              <Box
                backgroundColor={colors.lighter.keep}
                position="relative"
                boxSize="18px"
                color={answer ? colors.green.keep : colors.red.keep}
                borderRadius={100}
                top={4}
              >
                <FontAwesomeIcon
                  style={{
                    position: 'absolute',
                    top: -1,
                    left: -1,
                  }}
                  icon={answer ? faCheckCircle : faTimesCircle}
                  size="lg"
                />
              </Box>
            )}
          </Flex>
        </Flex>
      </Box>
    </Box>
  );
};
