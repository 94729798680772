import {
  Box, Flex, HStack, NumberDecrementStepper,
  NumberIncrementStepper, NumberInput, NumberInputField,
  NumberInputStepper, Text, Tooltip, VStack,
} from '@chakra-ui/react';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, useCallback, useContext, useState } from 'react';
import { useExtraQuestions } from '../../api/extra-activities/extra-questions';
import { useDefaultStyles } from '../../hooks/useDefaultStyles';
import useInstitutions from '../../hooks/useInstitutions';
import { PrimaryButton } from '../../lib/components/PrimaryButton';
import { SecondaryButton } from '../../lib/components/SecondaryButton';
import Select from '../../lib/components/Select';
import Toggle from '../../lib/components/Toggle';
import { toast } from '../../utils/toast';
import { AgendaContext } from '../agenda/Agenda.context';
import DoubleRangeSlider from '../_components/DoubleRangeSlider';

type GeneralQuestionOptionsType = {
  onClose: () => void;
  onAfterCreate?: () => void;
  allClassifications: string[];
  setStep: React.Dispatch<React.SetStateAction<number>>;
}

export const GeneralQuestionOptions: FC<GeneralQuestionOptionsType> = ({
  onClose,
  onAfterCreate,
  allClassifications,
  setStep,
}) => {
  const [difficulty, setDifficulty] = useState('');
  const { onCreateQuestionActivity } = useExtraQuestions();
  const [minYear, setMinYear] = useState(2005);
  const [maxYear, setMaxYear] = useState((new Date()).getFullYear() + 1);
  const { refetchExtras } = useContext(AgendaContext);
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState('10');
  const [commented, setCommented] = useState(false);
  const { colors } = useDefaultStyles();

  const {
    uf,
    institution,
    ufs,
    institutions,
    handleUf,
    handleInstitution,
    loading: institutionLoading,
  } = useInstitutions('', '', 0);

  const handleYearChange = useCallback(([minYearParam, maxYearParam]: number[]) => {
    setMinYear(minYearParam);
    setMaxYear(maxYearParam);
  }, []);

  const handleCreate = useCallback(async () => {
    setLoading(true);
    try {
      await onCreateQuestionActivity({
        ...(allClassifications.length && { classification: allClassifications }),
        uf: uf || undefined,
        institution: institution || undefined,
        count: Number(count),
        difficulty: difficulty || undefined,
        onlyWithComments: commented,
        minYear,
        maxYear,
      });
      await refetchExtras();
      onClose();
      if (onAfterCreate) {
        onAfterCreate();
      }
    } catch (err) {
      const msg = err.graphQLErrors && err.graphQLErrors[0].message as string;
      if (msg === 'No question found.') {
        toast.warning('Nenhuma questão encontrada.');
      } else {
        console.log(msg);
      }
    } finally {
      setLoading(false);
    }
  }, [
    allClassifications, commented, count, difficulty,
    institution, maxYear, minYear, onAfterCreate, onClose,
    onCreateQuestionActivity, refetchExtras, uf]);
  return (
    <>
      <Text>Selecione os demais filtros:</Text>
      <VStack w="100%" align="flex-start">
        <Select
          label="Dificuldade"
          width="100%"
          value={difficulty}
          onChange={e => setDifficulty(e.target.value)}
          options={[
            { value: 'unknown', label: 'Desconhecida' },
            { value: 'easy', label: 'Fácil' },
            { value: 'medium', label: 'Média' },
            { value: 'hard', label: 'Difícil' },
          ]}
        />
        <Select
          label="UF"
          width="100%"
          value={uf}
          onChange={handleUf}
          options={ufs.sort().map(item => ({ value: item, label: item }))}
        />
        <Select
          label="Instituição"
          width="100%"
          value={institution}
          onChange={handleInstitution}
          isDisabled={uf === '' || !institutions.length}
          options={institutions.sort().map(item => ({ value: item, label: item }))}
          loading={institutionLoading}
        />
        <VStack w="100%" align="flex-start">
          <HStack w="100%">
            <Text fontSize="sm" color={colors.secondary.keep}>Quantidade máxima de questões</Text>
            <Tooltip label="Exemplo: Se você selecionar 10 questões, mas existirem apenas 3
          com os filtros desejados, apenas 3 serão retornadas."
            >
              <Box color={colors.secondary.keep}>
                <FontAwesomeIcon icon={faInfoCircle} />
              </Box>
            </Tooltip>
          </HStack>
          <NumberInput
            min={1}
            max={200}
            w="100%"
            onChange={setCount}
            value={count}
          >
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </VStack>
        <Toggle
          label="Só comentadas"
          active={commented}
          onChange={() => setCommented(!commented)}
          color="primary"
        />
        <DoubleRangeSlider
          min={2005}
          max={(new Date()).getFullYear() + 1}
          defaultValue={[2005, (new Date()).getFullYear() + 1]}
          onChange={handleYearChange}
          currentMax={maxYear}
          currentMin={minYear}
          label="Ano"
        />
      </VStack>
      <Flex justify="end" w="100%">
        <SecondaryButton onClick={() => setStep(prev => prev - 1)} mr={2} variant="ghost">
          Anterior
        </SecondaryButton>
        <PrimaryButton
          isLoading={loading}
          onClick={handleCreate}
        >
          Adicionar
        </PrimaryButton>
      </Flex>
    </>
  );
};
