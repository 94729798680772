import {
  Alert,
  AlertIcon,
  Button, Input, Link, Modal, ModalBody, ModalCloseButton,
  ModalContent, ModalFooter, ModalHeader, ModalOverlay, Table, Tbody, Td, Text, Th, Thead, Tooltip, Tr, useDisclosure,
} from '@chakra-ui/react';
import { faTasks } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dispatch, FC, SetStateAction, useCallback, useContext, useMemo, useRef, useState } from 'react';
import { PreChoiceInterface } from '../../../api/agenda/mock-exam';
import { Question } from '../../../api/agenda/questions';
import { useAnswer } from '../../../api/answer';
import { useDefaultStyles } from '../../../hooks/useDefaultStyles';
import { useDevice } from '../../../hooks/useDevice';
import { PrimaryButton } from '../../../lib/components/PrimaryButton';
import { SecondaryButton } from '../../../lib/components/SecondaryButton';
import { PrivateContext } from '../../../Private.context';
import { isTodayExtended } from '../../../utils/isTodayExtended';

interface ExamFeedbackButtonProps {
  data: Question[],
  clientPreChoices: PreChoiceInterface[],
  setClientPreChoices: Dispatch<SetStateAction<PreChoiceInterface[]>>,
  hide?: boolean;
  device?: 'mobile' | 'web' | 'both';
}

export const ExamFeedbackButton: FC<ExamFeedbackButtonProps> = ({
  data, clientPreChoices, setClientPreChoices, hide = false, device = 'both',
}: ExamFeedbackButtonProps) => {
  const currDevice = useDevice();
  const { colors } = useDefaultStyles();
  const idList = useMemo(() => {
    return (
      data.map(q => ({
        id: q._id,
        isEssay: q.isEssay,
      }))
    );
  }, [data]);

  const { search } = window.location;
  const params = new URLSearchParams(search);
  const { agenda } = useContext(PrivateContext);
  const activityId = String(params.get('a') || '');
  const [loadingApplyFeedback, setLoadingApplyFeedback] = useState(false);

  // TODO: refatorar nomes, seguir um mesmo padrão (pra callbacks normalmente é handle{x}...)

  const setFeedbackModel = useCallback(() => {
    const types = data.map(q => ({ _id: q._id, isEssay: q.isEssay }));
    localStorage.setItem('@Aristoclass:feedbackPrintData', JSON.stringify({ types }));
    window.open(`${window.location.origin}/gabarito`, '_blank');
  }, [data]);

  const getCheckedLetter = useCallback(index => {
    const choice = clientPreChoices.find(q => q.questionId === idList[index].id)?.selected;
    if (choice !== undefined) {
      return String.fromCharCode(choice + 65);
    } return '';
  }, [idList, clientPreChoices]);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const presToFeedback = useCallback(() => {
    // TODO: refatorar
    const emptyFeedback = { ...Array(data.length).fill('') };
    clientPreChoices.forEach(item => {
      const questionIndex = idList.findIndex(x => x.id === item.questionId);
      emptyFeedback[questionIndex] = String.fromCharCode(item.selected + 65);
    });
    return emptyFeedback;
  }, [data.length, idList, clientPreChoices]);

  const handleBackspaceOrDeletePress = useCallback((e, index) => {
    if (e.key === 'Backspace' || e.key === 'Delete') {
      setFeedback(prev => ({ ...prev, [index]: '' }));
    }
  }, []);

  const handleNextInput = useCallback(index => {
    if (tBodyRef.current && (index < data.length - 1)) {
      if (data[index + 1].isEssay) {
        handleNextInput(index + 1);
      } else {
        const element = tBodyRef.current?.childNodes[index + 1]
          .childNodes[1]
          .firstChild as HTMLElement;
        element.focus();
      }
    }
  }, [data]);

  const onInputChange = useCallback((e, index) => {
    const pressedKey = (e.nativeEvent as InputEvent).data;
    if (pressedKey) {
      const acceptedKeys = ['A', 'B', 'C', 'D', 'E'];
      const newValue = pressedKey.toUpperCase();
      const newValueCode = newValue.charCodeAt(0) - 65;
      if (acceptedKeys.includes(newValue)
        && newValueCode >= 0
        && newValueCode < data[index].choices.length) {
        setFeedback(prev => ({ ...prev, [index]: newValue }));
        handleNextInput(index);
      } else {
        setFeedback(prev => ({ ...prev, [index]: '' }));
      }
    }
  }, [data, handleNextInput]);

  const preChosenFeedback = useMemo(() => {
    return (
      { ...Array(data.length).fill('').map((_, index) => getCheckedLetter(index)) }
    );
  }, [data.length, getCheckedLetter]);

  const [feedback, setFeedback] = useState(preChosenFeedback);
  const tBodyRef = useRef<HTMLTableSectionElement>(null);

  const preChoicesTranslated = useMemo(() => {
    return (
      idList
        .map((item, index) => {
          if (!item.isEssay) {
            return (
              {
                questionId: item.id,
                selected: (feedback[index].charCodeAt(0) - 65),
              }
            );
          }
          return {
            questionId: 'essay',
            selected: 0,
          };
        })
        .filter(item => item.questionId !== 'essay')
        .filter(item => !Number.isNaN(item.selected))
    );
  }, [feedback, idList]);

  const { onCreateManyPreChoices } = useAnswer();

  const onApplyFeedback = useCallback(async () => {
    try {
      const essays = clientPreChoices
        .filter(item => item.essay)
        .map(item => {
          return (
            {
              questionId: item.questionId,
              selected: item.selected,
              essay: item.essay,
            }
          );
        });
      setLoadingApplyFeedback(true);
      const onCreateMany = await onCreateManyPreChoices(
        {
          preChoices: [...essays, ...preChoicesTranslated],
          ...(activityId && { activity: activityId }),
          ...(!activityId && { agenda: agenda?._id }),
        },
      );
      if (onCreateMany) {
        setClientPreChoices(() => {
          return ([...essays, ...preChoicesTranslated]);
        });
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoadingApplyFeedback(false);
      onClose();
    }
  }, [activityId, agenda?._id, onClose, onCreateManyPreChoices,
    preChoicesTranslated, clientPreChoices, setClientPreChoices]);

  if (hide || (currDevice !== device && (device !== 'both')) || !isTodayExtended()) {
    return <></>;
  }

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent bgColor={colors.background}>
          <ModalHeader>
            Preencher gabarito
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Alert
              bgColor={colors.lighter.goMuchDarker}
              mb={5}
              variant="left-accent"
              status="info"
              borderColor={colors.secondary.keep}
            >
              <AlertIcon color={colors.secondary.goLighter} />
              <Text fontSize="sm">
                {`
                Caso tenha imprimido a prova, insira o gabarito abaixo.
                Baixe o modelo
                `}
                {/* TODO: refatorar para usar Button com variant: link */}
                <Link
                  color={colors.secondary.goLighter}
                  to="/gabarito"
                  onClick={setFeedbackModel}
                >
                  aqui
                </Link>
                .
              </Text>
            </Alert>
            <Table w={25} align="center" textAlign="center">
              <Thead>
                <Tr>
                  <Th>Questão</Th>
                  <Th>Alternativa</Th>
                </Tr>
              </Thead>
              <Tbody align="center" ref={tBodyRef}>
                {
                  data.map((choice, index) => {
                    const itemKey = `${choice}${index}`;
                    return (
                      <Tr key={itemKey}>
                        <Td textAlign="center">{index + 1}</Td>
                        <Td textAlign="center">
                          {data[index].isEssay ? (
                            <Text>Aberta</Text>
                          ) : (
                            <Input
                              borderColor="gray.400"
                              value={feedback[index]}
                              onChange={e => onInputChange(e, index)}
                              onKeyDown={e => handleBackspaceOrDeletePress(e, index)}
                            />
                          )}
                        </Td>
                      </Tr>
                    );
                  })
                }

              </Tbody>
            </Table>
          </ModalBody>
          <ModalFooter>
            <SecondaryButton mr={3} onClick={onClose} colorScheme="dark">
              Cancelar
            </SecondaryButton>
            <PrimaryButton
              onClick={onApplyFeedback}
              isLoading={loadingApplyFeedback}
              loadingText="Enviando..."
            >
              Marcar alternativas
            </PrimaryButton>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Tooltip
        label={`Caso tenha imprimido a prova, preencha o gabarito aqui.
        Também disponibilizamos um modelo de gabarito para impressão.`}
        placement="bottom-start"
      >
        <Button
          color={colors.secondary.keep}
          variant="ghost"
          onClick={() => {
            onOpen();
            setFeedback(presToFeedback());
          }}
        >
          <FontAwesomeIcon icon={faTasks} />
        </Button>
      </Tooltip>
    </>
  );
};
