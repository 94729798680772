import React, {
  FC, useContext, useMemo,
} from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useGetOrCreateExam } from '../../../api/agenda/exam';
import { getDateParam } from '../../../hooks/getDateParam';
import SizedBox from '../../../lib/components/SizedBox';
import Text from '../../../lib/components/Text';
import { PrivateContext } from '../../../Private.context';
import { getActivityIndex } from '../../../hooks/getActivityIndex';
import { Questions } from '../types/Questions.component';

const AgendaExam: FC = () => {
  const { agenda, hasAgenda } = useContext(PrivateContext);
  const history = useHistory();

  const id = useMemo(() => {
    if (agenda && agenda.exams.length) {
      return agenda.exams[0];
    }

    return '';
  }, [agenda]);

  const query = useGetOrCreateExam({
    calendarDate: getDateParam(),
    examTemplate: id,
  });

  if (hasAgenda.examType === 'mocks') {
    return <Redirect to={`/agenda/simulado${window.location.search}`} />;
  }

  if (hasAgenda.examType === 'smart-exams') {
    return <Redirect to={`/agenda/simulado-inteligente${window.location.search}`} />;
  }

  if (query.loading) {
    return (
      <SizedBox flex align="center" justify="center" height={400}>
        <FontAwesomeIcon icon={faSpinner} spin className="color-dark-gray" size="5x" />
      </SizedBox>
    );
  }

  if (query.data && !getActivityIndex()) {
    const currentUrlParams = new URLSearchParams(window.location.search);
    currentUrlParams.set('a', query.data.exam._id);
    history.replace({ search: currentUrlParams.toString() });
  }

  if (query.data) {
    return (
      <Questions
        model="exams"
        data={query.data.exam.questions}
        activityId={query.data.exam._id}
        serverPreChoices={query.data.exam.preChoice}
        finished={query.data.exam.finished}
      />
    );
  }

  return (
    <SizedBox className="py-4">
      <Text tag="h5" center>
        Não há prova disponível para esse dia
      </Text>
    </SizedBox>
  );
};

export default AgendaExam;
