import { Button, Flex, Text } from '@chakra-ui/react';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC } from 'react';

interface InstitutionSelectedProps {
  name: string;
  onDelete: () => void;
  index: number;
  borderColor?: string;
}

export const InstitutionSelected: FC<InstitutionSelectedProps> = ({
  name, onDelete, index, borderColor = 'white',
}) => {
  return (
    <Flex
      align="center"
      justify="space-between"
      padding="1rem"
      border=".15rem solid"
      borderColor={borderColor}
      borderRadius=".3rem"
      min-height="2rem"
      width="100%"
      marginBottom="1rem"
      overflow="hidden"
      flexWrap="wrap"
    >
      <Text width="80%">{`${index + 1}° - ${name}`}</Text>
      <Button
        variant="unstyled"
        className="page1DeleteInstitutionBtn"
        cursor="pointer"
        fontSize="20px"
        onClick={onDelete}
      >
        <FontAwesomeIcon style={{ pointerEvents: 'none' }} icon={faTrashAlt} />
      </Button>
    </Flex>
  );
};
