import { QueryResult, gql, useMutation, useQuery } from '@apollo/client';
import { useCallback } from 'react';

const USER_INCLUDED_IN_EXPERIMENT_PLATFORM_USER_LIST = gql`
  query UserIncludedInPlatformABExperiment {
    userIncludedInPlatformABExperiment {
      isIncludedInExperimentPlatformUserList
      customToken
    }
  }
`;

const ADD_OR_REMOVE_USER_AB_EXPERIMENT = gql`
  mutation AddOrRemoveUserFromPlatformABExperiment ($add: Boolean!) {
    addOrRemoveUserFromPlatformABExperiment (add: $add)
  }
`;

const ENEABLED_DISPLAY_BUTTON_AB_EXPERIMENT = gql`
  query EnabledDisplayButtonABExperiment {
    enabledDisplayButtonABExperiment
  }
`;

const AM_I_PART_OF_EXPERIMENT_OF_NAME = gql`
  query AmIPartOfExperimentOfName ($experimentName: String!) {
    amIPartOfExperimentOfName (experimentName: $experimentName)
  }
`;

interface PlatformExperimentOutput {
  userIncludedInPlatformABExperiment: {
    isIncludedInExperimentPlatformUserList: boolean;
  };
}

interface AddOrRemoveUserFromPlatformABExperimentOutput {
  addOrRemoveUserFromPlatformABExperiment: boolean;
}

interface EnabledDisplayButtonABExperimentOutput {
  enabledDisplayButtonABExperiment: boolean;
}

interface AmIPartOfExperimentOfNameOutput {
  amIPartOfExperimentOfName: boolean;
}

export function usePlatformExperiment(): QueryResult<PlatformExperimentOutput> {
  return useQuery<PlatformExperimentOutput>(USER_INCLUDED_IN_EXPERIMENT_PLATFORM_USER_LIST, {
    fetchPolicy: 'network-only',
  });
}

export function useAddUserToExperiment(): {handleAddUserToExperiment: () => Promise<void>} {
  // eslint-disable-next-line max-len
  const [addOrRemoveUserFromPlatformABExperiment] = useMutation<AddOrRemoveUserFromPlatformABExperimentOutput, {add: boolean}>(ADD_OR_REMOVE_USER_AB_EXPERIMENT);

  const handleAddUserToExperiment = useCallback(async () => {
    await addOrRemoveUserFromPlatformABExperiment({
      variables: {
        add: true,
      },
    });
  }, [addOrRemoveUserFromPlatformABExperiment]);

  return {
    handleAddUserToExperiment,
  };
}

export function useEnabledDisplayButtonABExperiment(): QueryResult<EnabledDisplayButtonABExperimentOutput> {
  return useQuery<EnabledDisplayButtonABExperimentOutput>(ENEABLED_DISPLAY_BUTTON_AB_EXPERIMENT);
}

export function useAmIPartOfExperimentOfName(experimentName: string) : QueryResult<AmIPartOfExperimentOfNameOutput> {
  return useQuery<AmIPartOfExperimentOfNameOutput>(AM_I_PART_OF_EXPERIMENT_OF_NAME, {
    variables: { experimentName },
  });
}
