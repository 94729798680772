import { gql, useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { toast } from 'react-toastify';
import errorHandler from '../error/errorHandler';

interface CreateTheoreticalReviewExtraActivityOutput {
  createTheoreticalReviewExtraActivity: {
    classification: string[],
    contents: {
      videos?: string[],
      texts?: string[],
      mindmaps?: string[],
      flashcards?: string[],
      questions?: string[],
    },
  };
}

const CREATE_THEORETICAL_REVIEW_EXTRA_ACTIVITY = gql`
  mutation CreateTheoreticalReviewExtraActivity {
    createTheoreticalReviewExtraActivity {
      _id
    }
  }
`;

interface FinishExtraTheoreticalReviewActivityInput {
  id: string;
  classification: string[];
}

interface FinishExtraTheoreticalReviewActivityOutput {
  finishExtraTheoreticalReviewActivity: boolean;
}

const FINISH_EXTRA_THEORETICAL_REVIEW_ACTIVITY = gql`
  mutation FinishExtraTheoreticalReviewActivity (
    $id: ObjectId!
    $classification: [String!]!
  ) {
    finishExtraTheoreticalReviewActivity (
      _id: $id
      classification: $classification
    )
  }
`;

interface UseTheoreticalReviewExtraActivityInterface {
  onFinishExtraTheoreticalReviewActivity: (props: FinishExtraTheoreticalReviewActivityInput) => Promise<void>;
  onCreateTheoreticalReviewExtraActivity: () => Promise<void>;
}

export function useExtraTheoreticalReview(): UseTheoreticalReviewExtraActivityInterface {
  const [finish] = useMutation<
    FinishExtraTheoreticalReviewActivityOutput,
    FinishExtraTheoreticalReviewActivityInput
  >(FINISH_EXTRA_THEORETICAL_REVIEW_ACTIVITY, {
    onError: errorHandler,
  });

  const [createActivity] = useMutation<
    CreateTheoreticalReviewExtraActivityOutput
  >(CREATE_THEORETICAL_REVIEW_EXTRA_ACTIVITY, {
    onError: errorHandler,
    onCompleted: data => {
      if (data !== null) {
        toast.success('Atividade de revisão teórica criada com sucesso!');
      }
    },
  });

  const handleFinish = useCallback(async ({
    id,
    classification,
  }: FinishExtraTheoreticalReviewActivityInput) => {
    await finish({
      variables: {
        id,
        classification,
      },
    });
  }, [finish]);

  const handleCreate = useCallback(async () => {
    await createActivity();
  }, [createActivity]);

  return {
    onFinishExtraTheoreticalReviewActivity: handleFinish,
    onCreateTheoreticalReviewExtraActivity: handleCreate,
  };
}
