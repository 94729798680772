/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function cleanTypename(obj: any): any {
  return Object.fromEntries(Object
    .entries(obj)
    .filter(([k]) => k !== '__typename')
    .map(([k, v]) => {
      if (typeof v === 'object') {
        return [k, cleanTypename(v)];
      }
      return [k, v];
    }));
}
