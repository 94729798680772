import { ApolloQueryResult } from '@apollo/client';
import {
  Box, Button, Flex, Heading, HStack,
  Radio,
  RadioGroup,
  Stack,
  Switch, Text, useDisclosure, VStack,
} from '@chakra-ui/react';
import { faCaretLeft, faCaretRight, faChevronUp, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC, useCallback, useContext, useMemo, useState } from 'react';
import { useExtraQuestions } from '../../../api/extra-activities/extra-questions';
import {
  PerformanceAndPriorityByThemaInputType,
  PerformanceAndPriorityByThemaOutputType,
  PerformanceObjectType,
} from '../../../api/student-report';
import { useDefaultStyles, useRGBColor } from '../../../hooks/useDefaultStyles';
import { useDevice } from '../../../hooks/useDevice';
import useTableFilter from '../../../hooks/useTableFilter';
import ConfirmModal from '../../../lib/components/ConfirmModal';
import { Loading } from '../../../lib/components/Loading';
import TableHeaderComponent, { HeaderProps } from '../../../lib/components/TableHeader.component';
import TableRowComponent from '../../../lib/components/TableRow.component';
import { format } from '../../../utils/format';
import { getThemeIncidenceLevel } from '../../../utils/getThemeDifficulty';
import { AgendaContext } from '../../agenda/Agenda.context';
import DifficultyIcon from '../../_components/DifficultyIcon';
import { ComparisonText } from './ComparisonText.component';
import { PrizeStar } from './PrizeStar';
import { ReportLoading } from './ReportLoading';
import { CustomNumberInput } from '../../create-activity/CustomNumberInput';

type MonthDiagnosisType = {
  result?: PerformanceObjectType
  totalHits?: number
  totalQuestions?: number
  loading?: boolean
  priorityLeaves?: string[]
  refetch?: (variables?: Partial<PerformanceAndPriorityByThemaInputType> | undefined) =>
    Promise<ApolloQueryResult<PerformanceAndPriorityByThemaOutputType>>
}

type CreateNewActivityInputType = {
  classification: string[],
  count: number,
  noOfThemes: number,
}

export const MonthDiagnosis: FC<MonthDiagnosisType> = ({
  result, totalHits, totalQuestions, loading, priorityLeaves, refetch,
}: MonthDiagnosisType) => {
  const { refetchExtras } = useContext(AgendaContext);
  const { cardBorder, colors } = useDefaultStyles();
  const { toRGB } = useRGBColor();
  const [noOfVisibleLines, setNoOfVisibleLines] = useState(5);
  const device = useDevice();
  const [currColumnOnMobile, setCurrColumnOnMobile] = useState(0);
  const TABLE_LINE_CHUNKS_LEN = 5;
  const [difficultyValue, setDifficultyValue] = useState('');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [newActivityLoading, setNewActivityLoading] = useState(false);
  const { onCreateQuestionActivity } = useExtraQuestions();
  const [isOnlyFinishedChecked, setIsOnlyFinishedChecked] = useState(false);
  const [isThemesSwitchLoading, setIsThemesSwitchLoading] = useState(false);
  const [questionLimit, setQuestionLimit] = useState(10);
  const [prioritiesLimit, setPrioritiesLimit] = useState(5);

  const handleCreateNewActivity = useCallback(async ({
    classification, count, noOfThemes,
  }: CreateNewActivityInputType) => {
    const currYear = (new Date()).getFullYear();
    try {
      setNewActivityLoading(true);
      await onCreateQuestionActivity({
        minYear: currYear - 5,
        maxYear: currYear,
        classification: classification.slice(0, noOfThemes),
        count,
        onlyWithComments: true,
        ...(difficultyValue && { difficulty: difficultyValue }),
      });
      onClose();
      await refetchExtras();
    } catch (e) {
      console.log(e);
    } finally {
      setNewActivityLoading(false);
    }
  }, [difficultyValue, onClose, onCreateQuestionActivity, refetchExtras]);

  const { dataFiltered: crFiltered, filter, handleFilter } = useTableFilter(
    [...(result?.performance || [])]
      .sort((a, b) => {
        return (a.priorityIndex > b.priorityIndex) ? -1 : 1;
      })
      .map((e, index) => ({
        ...e,
        position: index + 1,
        alertColor: index < 5 ? toRGB(colors.red.keep) : toRGB(colors.yellow.keep),
      })),
  );
  const crFilteredSlice = useMemo(() => {
    return crFiltered.slice(0, noOfVisibleLines);
  }, [crFiltered, noOfVisibleLines]);

  const handleShowMoreClick = useCallback((reverse = false) => {
    const maxLen = crFiltered.length;
    const currLen = crFilteredSlice.length;
    const stepRest = currLen % TABLE_LINE_CHUNKS_LEN;
    setNoOfVisibleLines(prev => {
      if (!reverse) {
        if (noOfVisibleLines + TABLE_LINE_CHUNKS_LEN > maxLen) {
          return maxLen;
        } return prev + TABLE_LINE_CHUNKS_LEN;
      }
      if (noOfVisibleLines - TABLE_LINE_CHUNKS_LEN < TABLE_LINE_CHUNKS_LEN) {
        return TABLE_LINE_CHUNKS_LEN;
      }
      if (stepRest) {
        return prev - stepRest;
      }
      return prev - TABLE_LINE_CHUNKS_LEN;
    });
  }, [crFiltered.length, crFilteredSlice.length, noOfVisibleLines]);

  const tableHeaders = useMemo((): HeaderProps[] => {
    return (
      [
        {
          key: 'leaf',
          value: 'Tema',
          flex: { base: 5, md: 4 },
          alignItems: 'left',
          title: 'Tema',
        },
        {
          key: 'position',
          flex: { base: 2, md: 1 },
          textAlign: 'center',
          value: 'Prioridade',
          title: 'Ordem de prioridade de temas para estudo/revisão. Considera inciências e desempenho.',
          showOnMobile: currColumnOnMobile === 0,
        },
        {
          key: 'relevance',
          value: 'Incidência',
          flex: { base: 2, md: 1 },
          textAlign: 'center',
          alignItems: 'center',
          title: 'Nível de incidência do tema nas instituições escolhidas por você.',
          showOnMobile: currColumnOnMobile === 1,
        },
        {
          key: 'performanceLastMonth',
          value: 'Mês passado',
          flex: { base: 2, md: 1 },
          alignItems: 'center',
          textAlign: 'center',
          title: 'Acertos / Total de questões (% de acertos).',
          showOnMobile: currColumnOnMobile === 2,
        },
        {
          key: 'totalPerformance',
          value: 'Este mês',
          flex: { base: 2, md: 1 },
          alignItems: 'center',
          textAlign: 'center',
          title: 'Acertos / Total de questões (% de acertos).',
          showOnMobile: currColumnOnMobile === 3,
        },
      ]
    );
  }, [currColumnOnMobile]);

  const onToggleThemesFilter = useCallback(async () => {
    setIsThemesSwitchLoading(true);
    try {
      if (refetch) {
        await refetch({ onlyCompletedThems: !isOnlyFinishedChecked });
      }
      setNoOfVisibleLines(TABLE_LINE_CHUNKS_LEN);
      setIsOnlyFinishedChecked(prev => !prev);
    } catch (e) {
      console.error(e);
    } finally {
      setIsThemesSwitchLoading(false);
    }
  }, [isOnlyFinishedChecked, refetch]);

  if ((result && result.performance.length < 5) || (!result && !loading)) {
    return <></>;
  }

  if (loading) {
    return <ReportLoading />;
  }

  return (
    <Box
      mb={{ base: 4, md: 0 }}
      p={4}
      {...cardBorder}
    >
      <HStack alignItems="flex-start">
        <HStack flex={2} align="flex-start">
          <PrizeStar
            label="Parabéns! Você acertou mais que 1200 questões este mês!"
            goal={1200}
            achieved={totalHits || 0}
          />
          <VStack align="flex-start">
            <Heading size="md">Desempenho e prioridades por tema</Heading>
            {
              totalHits && totalQuestions && (
                <Text color={colors.secondary.goLighter} fontSize="xs">
                  {`Você acertou ${totalHits} questões de ${totalQuestions} (${Math.round((totalHits * 100)
                    / totalQuestions)}% de acerto)`}
                </Text>
              )
            }
          </VStack>
        </HStack>
        <ComparisonText
          currentValue={(result?.totalPerformance.currMonth || 0) * 100}
          previousValue={(result?.totalPerformance.lastMonth || 0) * 100}
          template="percent verbose"
          text="de desempenho em relação ao mês anterior."
        />
      </HStack>
      <Box
        maxWidth={1120}
        width="100%"
        margin="0 auto"
        borderRadius="1rem"
        marginBottom="2rem"
      >
        {device === 'mobile' && (
          <HStack
            gridGap={2}
            justifyContent="center"
          >
            <Button
              disabled={currColumnOnMobile === 0}
              onClick={() => {
                setCurrColumnOnMobile(prev => (prev !== 0 ? prev - 1 : prev));
              }}
              leftIcon={<FontAwesomeIcon icon={faCaretLeft} />}
            >
              Anterior
            </Button>
            <Button
              _hover={{ bgColor: 'white' }}
              disabled={currColumnOnMobile === tableHeaders.length - 2}
              onClick={() => {
                setCurrColumnOnMobile(prev => (prev !== tableHeaders.length - 1 ? prev + 1 : prev));
              }}
              rightIcon={<FontAwesomeIcon icon={faCaretRight} />}
            >
              Próximo
            </Button>
          </HStack>
        )}
        <TableHeaderComponent
          paddingLeft=".5rem"
          fontSize={13}
          headers={tableHeaders}
          handleFilter={handleFilter}
          filter={filter}
        />
        {
          crFilteredSlice.map((item, i) => {
            const subthemes = item.leaf.split(' - ');
            const len = subthemes.length;
            const lastTwoST = `${subthemes[len - 2]} - ${subthemes[len - 1]}`;
            return (
              <TableRowComponent
                key={item.leaf}
                lineTooltip="Os itens que você mais precisa prestar atenção ficam marcados de vermelho"
                paddingLeft={device === 'mobile' ? '1rem' : '.8rem'}
                rowBackgroundColorWhite={i % 2}
                rowData={[
                  {
                    key: 'leaf',
                    value: (
                      <Text
                        title={item.leaf}
                      >
                        {lastTwoST}
                      </Text>
                    ),
                    flexSpan: device === 'web' ? 7 : 4,
                    align: 'left',
                  },
                  {
                    key: 'position',
                    value: (
                      <>
                        <span
                          style={{
                            color: item.isPriority ? item.alertColor : '#8a9b8e',
                            display: 'inline',
                          }}
                        >
                          {`${item.position}° `}
                        </span>
                        <FontAwesomeIcon
                          icon={faExclamationCircle}
                          color={item.isPriority ? item.alertColor : '#8a9b8e'}
                        />
                      </>),
                    align: 'center',
                    flexSpan: 2,
                    showOnMobile: currColumnOnMobile === 0,
                  },
                  {
                    key: 'relevance',
                    value: <DifficultyIcon difficulty={getThemeIncidenceLevel(item.relevance)} />,
                    align: 'center',
                    showOnMobile: currColumnOnMobile === 1,
                    flexSpan: 2,
                  },
                  {
                    key: 'performanceLastMonth',
                    align: 'center',
                    value: (
                      <p style={{ fontSize: '12px' }}>
                        {
                          `${item.lastMonthStats.hits} / ${item.lastMonthStats.total
                          } (${format(item.performanceLastMonth * 100)
                          }%)`
                        }
                      </p>
                    ),
                    showOnMobile: currColumnOnMobile === 2,
                    flexSpan: 2,
                  },
                  {
                    key: 'totalPerformance',
                    align: 'center',
                    value: (
                      <p style={{ fontSize: '12px' }}>
                        {`${item.stats.hits} / ${item.stats.total} (${format(item.totalPerformance * 100)}%)`}
                      </p>
                    ),
                    showOnMobile: currColumnOnMobile === 3,
                    flexSpan: 2,
                  },
                ]}
              />
            );
          })
        }
      </Box>
      <Flex
        w="100%"
        mb={2}
        pr={2}
        justify="flex-end"
      >
        {
          isThemesSwitchLoading ? (
            <Loading />
          ) : (
            <HStack>
              <Text
                color={colors.secondary.keep}
                fontSize="xs"
              >
                Apenas temas concluídos
              </Text>
              <Switch
                isChecked={isOnlyFinishedChecked}
                onChange={onToggleThemesFilter}
              />
            </HStack>
          )
        }
      </Flex>
      <ConfirmModal
        confirmText="Confirmar"
        declineText="Cancelar"
        title="Adicionar atividade extra?"
        onDecline={onClose}
        loading={newActivityLoading}
        isConfirmDisabled={newActivityLoading || (prioritiesLimit > questionLimit)}
        onConfirm={async () => {
          setNewActivityLoading(true);
          await refetchExtras();
          if (priorityLeaves) {
            await handleCreateNewActivity({
              classification: priorityLeaves,
              count: questionLimit,
              noOfThemes: prioritiesLimit,
            });
          }
          setNewActivityLoading(false);
        }}
        isOpen={isOpen}
      >
        <VStack w="100%" align="flex-start">
          <Text>
            Aqui é possível criar uma atividade extra com a quantidade escolhida de
            questões dos seus temas prioritários. Escolha:
          </Text>
          <Stack direction={{ base: 'column', md: 'row' }}>
            <CustomNumberInput
              label="Limite de questões"
              count={questionLimit}
              setCount={setQuestionLimit}
              min={prioritiesLimit}
              info="Número máximo de questões para a atividade revisão."
            />
            <CustomNumberInput
              label="Limite de prioridades"
              count={prioritiesLimit}
              max={15}
              setCount={setPrioritiesLimit}
              info="Número máximo de temas para a atividade revisão."
            />
          </Stack>
          <Flex w="100%">
            <RadioGroup onChange={setDifficultyValue} value={difficultyValue}>
              <VStack align="flex-start">
                <Text color={colors.secondary.keep} fontSize="sm">Dificuldade</Text>
                <HStack justify="flex-start">
                  <Radio value="easy">Fácil</Radio>
                  <Radio value="medium">Média</Radio>
                  <Radio value="hard">Difícil</Radio>
                  <Radio value="">Todas</Radio>
                </HStack>
              </VStack>
            </RadioGroup>
          </Flex>
        </VStack>

      </ConfirmModal>
      <Flex justifyContent="space-between">
        <HStack>
          <Button
            onClick={() => {
              setNoOfVisibleLines(TABLE_LINE_CHUNKS_LEN);
            }}
            disabled={noOfVisibleLines <= TABLE_LINE_CHUNKS_LEN}
          >
            <FontAwesomeIcon icon={faChevronUp} />
          </Button>
          <Button
            disabled={noOfVisibleLines === crFiltered.length}
            onClick={() => handleShowMoreClick()}
          >
            +
          </Button>
        </HStack>
        <Button onClick={onOpen}>
          Revisar prioridades
        </Button>
      </Flex>
    </Box>
  );
};
