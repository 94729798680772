import { Flex, HStack, Image, Text, VStack } from '@chakra-ui/react';
import { FC } from 'react';
import { useDefaultStyles } from '../../../hooks/useDefaultStyles';
import { useDevice } from '../../../hooks/useDevice';
import { FooterListItem } from './FooterListItem';
import { SocialMediaIcon } from './SocialMediaIcon';

export const AristoCompassFooter: FC = () => {
  const { colors } = useDefaultStyles();
  const device = useDevice();
  return (
    <Flex
      justifySelf="flex-end"
      w="100%"
      bgColor={colors.primary.keep}
      px={{ md: '2vw', lg: '10%' }}
      alignItems="center"
      h={{ base: '7rem', md: '30rem' }}
    >
      {
        device === 'web' ? (
          <Flex
            justifyContent="space-between"
            w="100%"
            alignItems="flex-start"
          >
            <VStack spacing={8} maxW="15rem" alignItems="flex-start" justifyContent="flex-start">
              <Image src="/images/aristo-logo.png" w="10rem" />
              <Text color={colors.darkGray.keep}>O melhor curso de Residência Médica do país.</Text>
            </VStack>
            <VStack spacing={5} maxW="15rem" alignItems="flex-start" justifyContent="flex-start">
              <Text fontWeight="bold" color={colors.white.keep}>Navegue</Text>
              <FooterListItem label="Quem somos" link="https://aristo.com.br/quem-somos/" />
              <FooterListItem label="Cursos" link="https://aristo.com.br/cursos/" />
              <FooterListItem label="Editais" link="https://aristo.com.br/editais/" />
              <FooterListItem label="Depoimentos" link="https://aristo.com.br/depoimentos/" />
              <FooterListItem label="Blog" link="https://aristo.com.br/blog/" />
            </VStack>
            <VStack spacing={5} maxW="15rem" alignItems="flex-start" justifyContent="flex-start">
              <Text fontWeight="bold" color={colors.white.keep}>Conheça a plataforma</Text>
              <FooterListItem label="AristoClass" link="https://aristoclass.com.br/" />
            </VStack>
            <VStack spacing={5} maxW="15rem" alignItems="flex-start" justifyContent="flex-start">
              <Text fontWeight="bold" color={colors.white.keep}>Siga nossas redes sociais</Text>
              <HStack align="center" justify="space-around" w="100%">
                <SocialMediaIcon type="instagram" />
                <SocialMediaIcon type="facebook" />
                <SocialMediaIcon type="youtube" />
              </HStack>
            </VStack>
          </Flex>
        ) : (
          <HStack align="center" justify="space-around" w="100%">
            <SocialMediaIcon type="instagram" />
            <SocialMediaIcon type="facebook" />
            <SocialMediaIcon type="youtube" />
          </HStack>
        )
      }
    </Flex>
  );
};
