import { Box, Button, Center, Flex, Text } from '@chakra-ui/react';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  FC, ReactNode, useCallback, useState,
} from 'react';

export type MockTestAlertProps = {
  title: string,
  body?: string | ReactNode,
  btn1?: { text: string, className: string, action: () => void | Promise<void> },
  btn2?: { text: string, className: string, action: () => void | Promise<void> },
  active: boolean;
}

export const MockTestAlert: FC<MockTestAlertProps> = ({
  title, body, btn1, btn2, active,
}: MockTestAlertProps) => {
  const [loadingOne, setLoadingOne] = useState(false);
  const [loadingTwo, setLoadingTwo] = useState(false);

  const handleBtn1 = useCallback(async () => {
    setLoadingOne(true);
    try {
      await btn1?.action();
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingOne(false);
    }
  }, [btn1]);

  const handleBtn2 = useCallback(async () => {
    setLoadingTwo(true);
    try {
      await btn2?.action();
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingTwo(false);
    }
  }, [btn2]);
  if (active) {
    return (
      <Center
        width="100%"
        height="100%"
        position="fixed"
        bgColor="rgba(0, 0, 0, .5)"
        zIndex={30}
      >
        <Flex
          width="35%"
          minHeight="30%"
          bgColor="aristo.500"
          borderRadius="1rem"
          border="rgba(255, 255, 255, .5) solid .3rem"
          color="white"
          align="center"
          justify="space-between"
          flexFlow="column"
          textAlign="center"
          padding="2rem"
          minWidth="20rem"
        >
          <Text
            fontWeight="bold"
            fontSize="1.5rem"
            color="secondary.50"
          >
            {title}
          </Text>
          <Box
            fontSize="1.3rem"
          >
            {body}
          </Box>

          <Flex
            width="100%"
            flexFlow={{ base: 'column', xlg: 'row' }}
            justify="center"
            gridGap="1.5rem"
            flexWrap="wrap"
            marginTop="1rem"
          >
            {btn1
              ? (
                <Button
                  variant="unstyled"
                  onClick={handleBtn1}
                  className={btn1.className}
                  width="35%"
                  minWidth="15rem"
                  minHeight="3rem"
                  borderRadius=".5rem"
                  fontWeight="bold"
                  transition="background-color .3s linear"
                  border="solid 3px"
                  borderColor="#8CCAFF"
                >
                  {loadingOne ? (
                    <FontAwesomeIcon icon={faSpinner} spin />
                  ) : btn1.text}
                </Button>
              )
              : ''}
            {btn2
              ? (
                <Button
                  variant="unstyled"
                  onClick={handleBtn2}
                  className={btn2.className}
                  bgColor="white"
                  color="aristo.500"
                  width="35%"
                  minWidth="15rem"
                  minHeight="3rem"
                  borderRadius=".5rem"
                  fontWeight="bold"
                  border="solid 3px transparent"
                  transition="background-color .3s linear"
                >
                  {loadingTwo ? (
                    <FontAwesomeIcon icon={faSpinner} spin />
                  ) : btn2.text}
                </Button>
              ) : ''}
          </Flex>
        </Flex>

      </Center>
    );
  }
  return (<></>);
};
