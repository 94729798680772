import React, {
  createContext, FC, useState, useMemo, useCallback,
} from 'react';
import {
  ActivitiesList, useExtraActivities, useGetExtraActivities,
} from '../../api/extra-activities/extra-activities';
import { getDateParam } from '../../hooks/getDateParam';

export interface AgendaInterface {
  extras: ActivitiesList;
  extrasLoading: boolean;
  nExtras: [number, number];
  modalReport: boolean;
  onModalReport: () => void;
  tab?: 'comment' | 'doubt';
  onComment: () => void;
  onDoubt: () => void;
  refetchExtras: () => Promise<void>;
  onFinishExtraActivity: (activityId: string) => Promise<void>;
  percentOfQuestionsAnswered: number;
  setPercentOfQuestionsAnswered: React.Dispatch<React.SetStateAction<number>>;
}

export const AgendaContext = createContext<AgendaInterface>({
  extras: {
    activityQuestion: undefined,
    activityFlashcard: undefined,
    activityExamExtra: undefined,
    activityMockExamExtra: undefined,
  },
  extrasLoading: false,
  nExtras: [0, 0],
  modalReport: false,
  onModalReport: () => undefined,
  tab: undefined,
  onComment: () => undefined,
  onDoubt: () => undefined,
  refetchExtras: async () => undefined,
  onFinishExtraActivity: async () => undefined,
  percentOfQuestionsAnswered: 1,
  setPercentOfQuestionsAnswered: () => undefined,
});

const countFinished = (activities?: { finished: boolean }[]) => {
  return (activities ?? []).filter(a => !a.finished).length;
};

export const AgendaStorage: FC = ({ children }) => {
  /**
   * States
   */
  const [modalReport, setModalReport] = useState(false);
  const [percentOfQuestionsAnswered, setPercentOfQuestionsAnswered] = useState(1);
  const [tab, setTab] = useState<'comment' | 'doubt'>('comment');

  /**
   * Queries and Mutations
   */
  const { onFinishExtraActivity } = useExtraActivities();

  const extrasQuery = useGetExtraActivities({
    calendarDate: getDateParam(),
  });

  /**
   * Memos
   */
  const extras: ActivitiesList = useMemo(() => {
    const { data } = extrasQuery;

    if (data) {
      return data.getExtraActivities;
    }

    return ({
      activityQuestion: undefined,
      activityFlashcard: undefined,
      activityExamExtra: undefined,
      activityMockExamExtra: undefined,
      activityTheoreticalExtra: undefined,
      activityTheoreticalReviewExtra: undefined,
      activitySmartReviewExtra: undefined,
    });
  }, [extrasQuery]);

  const nExtras: [number, number] = useMemo(() => {
    const a = countFinished(extras.activityQuestion);
    const b = countFinished(extras.activityFlashcard);
    const c = countFinished(extras.activityExamExtra);
    const d = countFinished(extras.activityMockExamExtra);
    const e = countFinished(extras.activityTheoreticalExtra);
    const f = countFinished(extras.activityTheoreticalReviewExtra);
    const g = countFinished(extras.activitySmartExamExtra);
    const h = countFinished(extras.activitySmartReviewExtra);

    const a1 = (extras.activityQuestion ?? []).length;
    const b1 = (extras.activityFlashcard ?? []).length;
    const c1 = (extras.activityExamExtra ?? []).length;
    const d1 = (extras.activityMockExamExtra ?? []).length;
    const e1 = (extras.activityTheoreticalExtra ?? []).length;
    const f1 = (extras.activityTheoreticalReviewExtra ?? []).length;
    const g1 = (extras.activitySmartExamExtra ?? []).length;
    const h1 = (extras.activitySmartReviewExtra ?? []).length;

    return [a + b + c + d + e + f + g + h, a1 + b1 + c1 + d1 + e1 + f1 + g1 + h1];
  }, [
    extras.activityExamExtra,
    extras.activityFlashcard,
    extras.activityMockExamExtra,
    extras.activityQuestion,
    extras.activitySmartExamExtra,
    extras.activityTheoreticalExtra,
    extras.activityTheoreticalReviewExtra,
    extras.activitySmartReviewExtra,
  ]);

  /**
   * Callbacks
   */
  const handleModalReport = useCallback(() => {
    setModalReport(old => !old);
  }, []);

  const handleComment = useCallback(() => {
    setTab('comment');
  }, []);

  const handleDoubt = useCallback(() => {
    setTab('doubt');
  }, []);

  const refetchExtras = useCallback(async () => {
    await extrasQuery.refetch();
  }, [extrasQuery]);

  const handleFinishExtraActivity = useCallback(async (activityId: string) => {
    try {
      await onFinishExtraActivity({
        _id: activityId,
      });
      await extrasQuery.refetch();
    } catch (error) {
      console.error(error);
      throw error;
    }
  }, [extrasQuery, onFinishExtraActivity]);

  return (
    <AgendaContext.Provider
      value={{
        extras,
        extrasLoading: extrasQuery.loading,
        nExtras,
        modalReport,
        onModalReport: handleModalReport,
        tab,
        onComment: handleComment,
        onDoubt: handleDoubt,
        refetchExtras,
        onFinishExtraActivity: handleFinishExtraActivity,
        percentOfQuestionsAnswered,
        setPercentOfQuestionsAnswered,
      }}
    >
      {children}
    </AgendaContext.Provider>
  );
};
