import { Checkbox, HStack, Text } from '@chakra-ui/react';
import { FC } from 'react';
import { colorPalette, useDefaultStyles } from '../../../hooks/useDefaultStyles';

type QuestionsToShowType = {
  easy: boolean;
  medium: boolean;
  hard: boolean;
  unclassified: boolean;
}

type FilterQuestionsByDifficultyInputType = {
  questionsToShow: QuestionsToShowType;
  hide?: boolean;
  toggleDifficultyVisibility: (difficulty: 'medium' | 'easy' | 'hard' | 'unclassified') => void;
}

export const FilterQuestionsByDifficulty: FC<FilterQuestionsByDifficultyInputType> = ({
  questionsToShow, toggleDifficultyVisibility, hide = false,
}) => {
  const { colors } = useDefaultStyles();

  if (hide) {
    return null;
  }

  return (
    <HStack w="100%" overflowX="auto">
      <Text color={colors.secondary.keep} fontWeight="bold" minW={150}>
        Questões visíveis:
      </Text>
      <Checkbox
        onChange={() => toggleDifficultyVisibility('easy')}
        colorScheme={colorPalette}
        isChecked={questionsToShow.easy}
      >
        Fáceis
      </Checkbox>
      <Checkbox
        onChange={() => toggleDifficultyVisibility('medium')}
        colorScheme={colorPalette}
        isChecked={questionsToShow.medium}
      >
        Médias
      </Checkbox>
      <Checkbox
        onChange={() => toggleDifficultyVisibility('hard')}
        colorScheme={colorPalette}
        isChecked={questionsToShow.hard}
      >
        Difíceis
      </Checkbox>
      <Checkbox
        onChange={() => toggleDifficultyVisibility('unclassified')}
        colorScheme={colorPalette}
        minW={140}
        isChecked={questionsToShow.unclassified}
      >
        Sem categoria
      </Checkbox>
    </HStack>
  );
};
