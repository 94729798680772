import { Flex, useDisclosure } from '@chakra-ui/react';
import React, { createContext, FC, useCallback, useMemo, useState } from 'react';
import { DeckType, useGetCommunityDecks, useGetUserDecks } from '../../../api/decks';
import {
  FlashcardType,
  GetAllClassesOutputType, useGetCommunityFlashcards,
  useGetUserFlashcards, useUserClasses,
} from '../../../api/flashcards';
import { useDevice } from '../../../hooks/useDevice';
import { useLocalStorage } from '../../../hooks/useLocalStorage';
import { getParam } from '../../../hooks/useSearchParam';
import { AristoTab } from '../../../lib/components/AristoTab.component';
import {
  AreaType, FCCreationTabType, LoadingType, OrderOptionType, RefetchCardsType,
} from '../types/types';
import { CreateFlashcardModal } from '../_components/FlashcardViewer/CreateFlashcardModal';
import { FlashcardViewerModal } from '../_components/FlashcardViewer/FlashcardViewerModal';
import { AreaSearchBar } from '../_components/SearchBars/AreaSearchBar';

type FlashcardsCreationContextType = {
  refetch?: ({ targets, params }: RefetchCardsType) => Promise<void>,
  setAllowDelay: (x: boolean) => void,
  allowDelay: boolean,
  refetchLoading: LoadingType,
  masterLoading: LoadingType,
  listOfAllLessons: GetAllClassesOutputType[],
  lessonName: string,
  flashcards: FlashcardType[],
  flashcardsCacheLen: number,
  remainingLessons: string[],
  skip: number,
  setSkip?: React.Dispatch<React.SetStateAction<number>>,
  fetchMoreCards?: () => Promise<void>,
  sortedAndFilteredDecksData: DeckType[],
  activeAreasArray: AreaType[],
  searchInput: string,
  onCreateFlashcardOpen: () => void,
  setActiveAreasArray?: React.Dispatch<React.SetStateAction<AreaType[]>>,
  setSearchInput?: React.Dispatch<React.SetStateAction<string>>,
  pathname: string,
  tab: FCCreationTabType,
  setCurrData?: React.Dispatch<React.SetStateAction<FlashcardType | undefined>>,
  onFlashcardViewerOpen: () => void,
  currLessonId: string,
  loadingFetchMore: boolean,
  setLoadingFetchMore: (x: boolean) => void,
  currPage: number,
  CARDS_LIMIT: number,
  scaleFadeIn: boolean,
  setScaleFadeIn: (x: boolean) => void,
  cardsSearchInput: string,
  setCardsSearchInput: (x: string) => void,
  noOfPages: number,
  currPageLen: number,
  flashcardViewerModalFlip: boolean,
  setFlashcardViewerModalFlip: React.Dispatch<React.SetStateAction<boolean>>,
  currFlashcardIndex: number,
  setCurrFlashcardIndex: React.Dispatch<React.SetStateAction<number>>,
  handleFCNavigatorClick: (target?: 'next' | 'prev') => void,
  isFlashcardNavDisabled: {
    next: boolean,
    prev: boolean,
  },
  handleNextPageClick: () => Promise<void>,
  handlePreviousPageClick: () => Promise<void>,
  isOnTheLastFlashcardOfThePage: boolean,
  isOnTheFirstFlashcardOfThePage: boolean,
  resetCurrContentSearchBar: () => void,
  selectedOrderOption: OrderOptionType,
  setSelectedOrderOption: React.Dispatch<React.SetStateAction<OrderOptionType>>,
}

const dataDefault: FlashcardsCreationContextType = {
  refetchLoading: {
    communityDecks: false,
    userDecks: false,
    communityFlashcards: false,
    userFlashcards: false,
  },
  masterLoading: {
    communityDecks: false,
    userDecks: false,
    communityFlashcards: false,
    userFlashcards: false,
  },
  setAllowDelay: () => null,
  allowDelay: true,
  listOfAllLessons: [],
  lessonName: '',
  flashcardsCacheLen: 0,
  remainingLessons: [],
  skip: 0,
  flashcards: [],
  sortedAndFilteredDecksData: [],
  activeAreasArray: [],
  searchInput: '',
  onCreateFlashcardOpen: () => null,
  pathname: '',
  tab: 'comunidade',
  onFlashcardViewerOpen: () => null,
  currLessonId: 'todos',
  loadingFetchMore: false,
  setLoadingFetchMore: () => null,
  currPage: 0,
  CARDS_LIMIT: 18,
  scaleFadeIn: false,
  setScaleFadeIn: () => null,
  cardsSearchInput: '',
  setCardsSearchInput: () => null,
  noOfPages: 0,
  currPageLen: 0,
  flashcardViewerModalFlip: false,
  setFlashcardViewerModalFlip: () => null,
  currFlashcardIndex: 1,
  setCurrFlashcardIndex: () => null,
  handleFCNavigatorClick: () => null,
  isFlashcardNavDisabled: { next: true, prev: true },
  handleNextPageClick: () => Promise.resolve(),
  handlePreviousPageClick: () => Promise.resolve(),
  isOnTheLastFlashcardOfThePage: true,
  isOnTheFirstFlashcardOfThePage: true,
  resetCurrContentSearchBar: () => null,
  selectedOrderOption: 'relevance',
  setSelectedOrderOption: () => null,
};

export const FlashcardsCreationContext = createContext<FlashcardsCreationContextType>(dataDefault);

export const FlashcardsCreationStorage: FC = ({ children }) => {
  const device = useDevice();
  const { pathname } = window.location;
  const currLessonId = getParam('lessonId');
  const tab: FCCreationTabType = pathname.includes('comunidade') ? 'comunidade' : 'meus-baralhos';
  const CARDS_LIMIT = 15 - (tab === 'comunidade' && currLessonId !== 'todos' ? 1 : 0);

  /*
  STATES
  */
  const [storageSearchInput, setStorageSearchInput] = useLocalStorage('@Aristoclass:contentSearchValues');
  const [currFlashcardIndex, setCurrFlashcardIndex] = useState(1);
  const [cardsSearchInput, setCardsSearchInput] = useState(storageSearchInput[currLessonId]?.searchBy);
  const [allowDelay, setAllowDelay] = useState(true);
  const [skip, setSkip] = useState(0);
  const [loadingFetchMore, setLoadingFetchMore] = useState(false);
  const [currData, setCurrData] = useState<FlashcardType>();
  const [activeAreasArray, setActiveAreasArray] = useState<AreaType[]>(['GO', 'PED', 'CIR', 'SC', 'CM', 'EXT']);
  const [searchInput, setSearchInput] = useState('');
  const [scaleFadeIn, setScaleFadeIn] = useState(false);
  const [flashcardViewerModalFlip, setFlashcardViewerModalFlip] = useState(false);
  const [selectedOrderOption, setSelectedOrderOption] = useState<OrderOptionType>(
    storageSearchInput[currLessonId]?.sortBy || 'relevance',
  );
  const [refetchLoading, setRefetchLoading] = useState<LoadingType>({
    communityDecks: false,
    userDecks: false,
    communityFlashcards: false,
    userFlashcards: false,
  });

  /*
HOOKS
*/

  const { listOfAllLessons, getAreaByLessonId, getLessonNameById } = useUserClasses();
  const communityDecks = useGetCommunityDecks();
  const communityFlashcards = useGetCommunityFlashcards({
    lessonId: currLessonId === 'todos' ? null : currLessonId,
    skip: 0,
    limit: 5 * CARDS_LIMIT,
    sortBy: storageSearchInput[currLessonId]?.sortBy,
    includes: storageSearchInput[currLessonId]?.searchBy,
  });
  const { userDecks, getUserDeckLengthByLessonId } = useGetUserDecks();
  const userFlashcards = useGetUserFlashcards({
    lessonId: currLessonId,
    skip: 0,
    limit: 5 * CARDS_LIMIT,
  });
  const {
    onClose: onCreateFlashcardClose,
    onOpen: onCreateFlashcardOpen,
    isOpen: isCreateFlashcardOpen,
  } = useDisclosure();
  const {
    onClose: onFlashcardViewerClose,
    onOpen: onFlashcardViewerOpen,
    isOpen: isFlashcardViewerOpen,
  } = useDisclosure();

  /*
  MEMOS
  */

  const totalNoOfCards = useMemo(() => {
    if (tab === 'comunidade') {
      return communityFlashcards.data?.getCommunityFlashcards.totalAmount || 0;
    }
    return getUserDeckLengthByLessonId({ lessonId: currLessonId });
  }, [communityFlashcards.data?.getCommunityFlashcards.totalAmount, currLessonId, getUserDeckLengthByLessonId, tab]);

  const noOfPages = useMemo(() => {
    return Math.ceil(totalNoOfCards / CARDS_LIMIT);
  }, [CARDS_LIMIT, totalNoOfCards]);

  const currPage = useMemo(() => (skip / CARDS_LIMIT) + 1, [CARDS_LIMIT, skip]);

  const currPageLen = useMemo(() => {
    if (currPage !== noOfPages) {
      return CARDS_LIMIT;
    } return totalNoOfCards % CARDS_LIMIT;
  }, [CARDS_LIMIT, currPage, noOfPages, totalNoOfCards]);

  const lessonName = useMemo(() => {
    return getLessonNameById({ lessonId: currLessonId });
  }, [currLessonId, getLessonNameById]);

  const decks = useMemo(() => {
    const _decks = pathname.includes('comunidade')
      ? communityDecks.data?.getCommunityDecks
      : userDecks.data?.getUserDecks;
    return _decks || [];
  }, [communityDecks.data?.getCommunityDecks, pathname, userDecks.data?.getUserDecks]);

  const flashcards = useMemo(() => {
    const _data = pathname.includes('comunidade')
      ? communityFlashcards.data?.getCommunityFlashcards.flashcards
      : userFlashcards.data?.getUserFlashcards;
    return _data?.slice(skip, skip + CARDS_LIMIT) || [];
  }, [CARDS_LIMIT, communityFlashcards.data?.getCommunityFlashcards.flashcards,
    pathname, skip, userFlashcards.data?.getUserFlashcards]);

  const flashcardsCacheLen = useMemo(() => {
    const _data = pathname.includes('comunidade')
      ? communityFlashcards.data?.getCommunityFlashcards.flashcards
      : userFlashcards.data?.getUserFlashcards;
    return _data?.length || 0;
  }, [communityFlashcards.data?.getCommunityFlashcards, pathname, userFlashcards.data?.getUserFlashcards]);

  const remainingLessons = useMemo(() => {
    if (decks) {
      const allLessons = listOfAllLessons.map(x => x.lessonId);
      const alreadyExistentLessons = decks.map(x => x.lesson);
      const remaining = allLessons.filter(lesson => !alreadyExistentLessons.includes(lesson));
      return remaining;
    } return [];
  }, [decks, listOfAllLessons]);

  const masterLoading = useMemo(() => {
    return {
      communityDecks: communityDecks.loading || refetchLoading.communityDecks,
      userDecks: userDecks.loading || refetchLoading.userDecks,
      communityFlashcards: communityFlashcards.loading || refetchLoading.communityFlashcards,
      userFlashcards: userFlashcards.loading || refetchLoading.userFlashcards,
    } as LoadingType;
  }, [
    communityDecks.loading, communityFlashcards.loading,
    refetchLoading.communityDecks, refetchLoading.communityFlashcards, refetchLoading.userDecks,
    refetchLoading.userFlashcards, userDecks.loading, userFlashcards.loading]);

  const sortedAndFilteredDecksData = useMemo(() => {
    if (decks) {
      const sortedDecks = [...decks]
        .sort((a, b) => {
          if ((a.lessonTitle <= b.lessonTitle) || b.lesson === null) {
            return -1;
          } return 1;
        });
      const filteredDecks = sortedDecks
        .filter(x => {
          const area = getAreaByLessonId({ lessonId: x.lesson });
          const lowerCaseTitle = x.lessonTitle.toLowerCase();
          const includesSearch = lowerCaseTitle.includes(searchInput.toLowerCase());
          const isFromSelectedAreas = activeAreasArray.includes(area);
          return includesSearch && isFromSelectedAreas && x.lessonTitle !== 'unknown';
        });
      return filteredDecks;
    } return [];
  }, [activeAreasArray, decks, getAreaByLessonId, searchInput]);

  const noOfPagesLoaded = useMemo(() => Math.ceil(flashcardsCacheLen / CARDS_LIMIT),
    [CARDS_LIMIT, flashcardsCacheLen]);

  const pageLastFlashcardIndex = useMemo(() => {
    return (currPage - 1) * CARDS_LIMIT + currPageLen - 1;
  }, [CARDS_LIMIT, currPage, currPageLen]);

  const isOnTheLastFlashcardOfThePage = useMemo(() => {
    return currFlashcardIndex === pageLastFlashcardIndex;
  }, [currFlashcardIndex, pageLastFlashcardIndex]);

  const isOnTheFirstFlashcardOfThePage = useMemo(() => {
    return currFlashcardIndex % CARDS_LIMIT === 0;
  }, [CARDS_LIMIT, currFlashcardIndex]);

  const isFlashcardNavDisabled = useMemo(() => {
    return {
      prev: isOnTheFirstFlashcardOfThePage,
      next: isOnTheLastFlashcardOfThePage,
    };
  }, [isOnTheFirstFlashcardOfThePage, isOnTheLastFlashcardOfThePage]);
  /*
  CALLBACKS
  */
  const resetCurrContentSearchBar = useCallback(() => {
    setCardsSearchInput('');
    setSelectedOrderOption('relevance');
    setStorageSearchInput({
      ...storageSearchInput,
      [currLessonId]: {
        searchBy: '',
        sortBy: 'relevance',
      },
    });
  }, [currLessonId, setStorageSearchInput, storageSearchInput]);

  const handleFCNavigatorClick = useCallback((target: 'next' | 'prev' = 'next') => {
    const targetIndex = currFlashcardIndex + (target === 'next' ? 1 : -1);
    setCurrFlashcardIndex(targetIndex);
    if (setCurrData) {
      setCurrData({ ...flashcards[targetIndex % CARDS_LIMIT], index: targetIndex });
    }
  }, [CARDS_LIMIT, currFlashcardIndex, flashcards, setCurrData]);

  const refetch = useCallback(async ({
    targets,
    params,
  }: RefetchCardsType) => {
    try {
      if (targets.includes('community-decks') || targets === 'all') {
        setRefetchLoading(prev => ({ ...prev, communityDecks: true }));
        setAllowDelay(true);
        await communityDecks.refetch();
        setRefetchLoading(prev => ({ ...prev, communityDecks: false }));
      }
      if (targets.includes('user-decks') || targets === 'all') {
        setRefetchLoading(prev => ({ ...prev, userDecks: true }));
        setAllowDelay(true);
        await userDecks.refetch();
        setRefetchLoading(prev => ({ ...prev, userDecks: false }));
      }
      if (targets.includes('community-flashcards') || targets === 'all') {
        setRefetchLoading(prev => ({ ...prev, communityFlashcards: true }));
        await communityFlashcards.refetch({ sortBy: params?.sortBy, includes: params?.includes });
        setRefetchLoading(prev => ({ ...prev, communityFlashcards: false }));
      }
      if (targets.includes('user-flashcards') || targets === 'all') {
        setRefetchLoading(prev => ({ ...prev, userFlashcards: true }));
        await userFlashcards.refetch();
        setRefetchLoading(prev => ({ ...prev, userFlashcards: false }));
      }
    } catch (e) {
      console.error(e);
    } finally {
      setSkip(0);
      setAllowDelay(true);
      setRefetchLoading({
        communityDecks: false,
        userDecks: false,
        communityFlashcards: false,
        userFlashcards: false,
      });
    }
  }, [communityDecks, communityFlashcards, setAllowDelay, userDecks, userFlashcards]);

  const fetchMoreCards = useCallback(async () => {
    if (pathname.includes('comunidade')) {
      await communityFlashcards.fetchMore({
        variables: {
          skip: flashcardsCacheLen || CARDS_LIMIT,
        },
      });
    } else {
      await userFlashcards.fetchMore({
        variables: {
          skip: flashcardsCacheLen || CARDS_LIMIT,
        },
      });
    }
  }, [CARDS_LIMIT, communityFlashcards, flashcardsCacheLen, pathname, userFlashcards]);

  const handleNextPageClick = useCallback(async () => {
    setLoadingFetchMore(true);
    if (flashcards
      && flashcardsCacheLen
      && (currPage + 1 > noOfPagesLoaded)) {
      if (fetchMoreCards) {
        await fetchMoreCards();
      }
    }
    setLoadingFetchMore(false);
    if (setSkip) {
      setSkip(prev => prev + CARDS_LIMIT);
    }
    setScaleFadeIn(false);
  }, [CARDS_LIMIT, currPage, fetchMoreCards, flashcards, flashcardsCacheLen,
    noOfPagesLoaded, setLoadingFetchMore, setScaleFadeIn, setSkip]);

  const handlePreviousPageClick = useCallback(async () => {
    setScaleFadeIn(false);
    if (setSkip) {
      setSkip(prev => prev - CARDS_LIMIT);
    }
  }, [CARDS_LIMIT, setScaleFadeIn, setSkip]);

  return (
    <FlashcardsCreationContext.Provider
      value={{
        refetch,
        refetchLoading,
        masterLoading,
        setAllowDelay,
        allowDelay,
        listOfAllLessons,
        lessonName,
        flashcardsCacheLen,
        remainingLessons,
        setSkip,
        pathname,
        skip,
        flashcards,
        fetchMoreCards,
        sortedAndFilteredDecksData,
        activeAreasArray,
        searchInput,
        onCreateFlashcardOpen,
        setActiveAreasArray,
        setSearchInput,
        tab,
        setCurrData,
        onFlashcardViewerOpen,
        currLessonId,
        loadingFetchMore,
        setLoadingFetchMore,
        currPage,
        CARDS_LIMIT,
        scaleFadeIn,
        setScaleFadeIn,
        cardsSearchInput,
        setCardsSearchInput,
        noOfPages,
        currPageLen,
        flashcardViewerModalFlip,
        setFlashcardViewerModalFlip,
        currFlashcardIndex,
        setCurrFlashcardIndex,
        handleFCNavigatorClick,
        isFlashcardNavDisabled,
        handleNextPageClick,
        handlePreviousPageClick,
        isOnTheLastFlashcardOfThePage,
        isOnTheFirstFlashcardOfThePage,
        resetCurrContentSearchBar,
        selectedOrderOption,
        setSelectedOrderOption,
      }}
    >
      <CreateFlashcardModal
        isOpen={isCreateFlashcardOpen}
        onClose={onCreateFlashcardClose}
      />
      <FlashcardViewerModal
        onClose={onFlashcardViewerClose}
        isOpen={isFlashcardViewerOpen}
        data={currData}
        navigator={{
          prev: {
            disabled: isFlashcardNavDisabled.prev,
            onClick: async () => {
              setFlashcardViewerModalFlip(false);
              handleFCNavigatorClick('prev');
            },
          },
          next: {
            disabled: isFlashcardNavDisabled.next,
            onClick: async () => {
              setFlashcardViewerModalFlip(false);
              handleFCNavigatorClick('next');
            },
          },
        }}
      />
      <AristoTab
        h={device === 'web' ? 45 : 35}
        search=""
        visible={!currLessonId}
        tabsData={[
          {
            title: 'Comunidade',
            to: '/baralhos/comunidade',
            visible: true,
            onClick: () => setAllowDelay(false),
          },
          {
            title: 'Meus baralhos',
            to: '/baralhos/meus-baralhos',
            visible: true,
            onClick: () => setAllowDelay(false),
          },
        ]}
      />
      <AreaSearchBar />
      <Flex w="100%" justify="center">
        <Flex flexWrap="wrap" w="94%">
          {children}
        </Flex>
      </Flex>
    </FlashcardsCreationContext.Provider>
  );
};
