import { HStack, Text, IconButton, VStack, useColorMode } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC, useMemo } from 'react';
import { faTired, faFrown, faMeh, faSmile, faGrin, IconDefinition } from '@fortawesome/free-regular-svg-icons';

interface HarrisonInteractionProps {
  action:(rating: number) => Promise<void>
}

export const HarrisonInteraction: FC<HarrisonInteractionProps> = ({
  action,
}) => {
  const { colorMode } = useColorMode();

  const icons: IconDefinition[] = useMemo(() => [
    faTired, faFrown, faMeh, faSmile, faGrin,
  ], []);

  return (
    <VStack
      borderRadius="12px"
      backgroundColor={colorMode === 'light' ? '#F3F3FA' : '#151C2A'}
      padding="12px 16px 16px 16px"
      color={colorMode === 'light' ? 'black' : 'white'}
      maxW="288px"
      spacing="8px"
      align="left"
    >
      <Text fontSize="14px">Como você avaliaria a resposta?</Text>
      <HStack justifyContent="space-between">
        {icons.map((it, index) => (
          <IconButton
            aria-label="Call support"
            rounded="full"
            onClick={() => action(index + 1)}
            key={it.iconName}
            fontSize="20px"
            backgroundColor={colorMode === 'light' ? '#B1F1C1' : '#12512E'}
          >
            <FontAwesomeIcon icon={it} color={colorMode === 'light' ? '#00210E' : '#B1F1C1'} />
          </IconButton>
        ))}
      </HStack>
      <HStack justifyContent="space-between">
        <Text fontSize="11px">Insatisfeito</Text>
        <Text fontSize="11px">Satisfeito</Text>
      </HStack>
    </VStack>
  );
};
