import { Circle, Text } from '@chakra-ui/react';
import { faCut } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC } from 'react';
import { useDefaultStyles } from '../../../hooks/useDefaultStyles';

type ChoiceCutAndProportionsCircleInputType = {
  hidePercents?: boolean,
  onEliminateChoiceClick: () => void;
  currIndex: number;
  chosenIndex: number;
  showResults: boolean;
  proportions?: string[];
}

export const ChoiceCutAndProportionsCircle: FC<ChoiceCutAndProportionsCircleInputType> = ({
  hidePercents = false,
  proportions,
  showResults,
  onEliminateChoiceClick,
  currIndex,
  chosenIndex,
}) => {
  const { colors } = useDefaultStyles();

  if (hidePercents && showResults) {
    return null;
  }

  if (proportions && proportions.length > 0 && showResults) {
    return (
      <Circle
        borderRadius={30}
        borderColor={colors.secondary.goLighter}
        bgColor={colors.white.goTransparent}
        color={colors.secondary.goLighter}
        borderWidth="1px"
        size="45px"
        ml={2}
      >
        <Text tag="small">{`${proportions[currIndex]}%`}</Text>
      </Circle>
    );
  }

  return (
    <Circle
      as="button"
      color={colors.secondary.keep}
      borderRadius={20}
      size="40px"
      onClick={onEliminateChoiceClick}
      disabled={chosenIndex === currIndex}
      _disabled={{
        opacity: 0.5,
        cursor: 'not-allowed',
      }}
      _hover={{
        bgColor: colors.alpha[100],
      }}
    >
      <FontAwesomeIcon icon={faCut} />
    </Circle>
  );
};
