import { Button, Flex, Text } from '@chakra-ui/react';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useDefaultStyles } from '../../hooks/useDefaultStyles';
import { PrimaryButton } from '../../lib/components/PrimaryButton';
import { SecondaryButton } from '../../lib/components/SecondaryButton';
import { SearchableSelector } from '../_components/SearchableSelector';
import { ThemeListBox } from './ThemeListBox';

type ClassificationQuestionOptionsType = {
  classificationOptions: string[][];
  handleClassification: (e: string, i: number) => void;
  currClassification: string[];
  setCurrClassification: React.Dispatch<React.SetStateAction<string[]>>;
  allClassifications: string[];
  setStep: React.Dispatch<React.SetStateAction<number>>;
  onClose: () => void;
  addClassification: (classification: string[]) => void;
  removeClassification: (classification: string) => void;
  allPrefixes: string[];
}

export const ClassificationQuestionOptions: FC<ClassificationQuestionOptionsType> = ({
  classificationOptions,
  handleClassification,
  currClassification,
  setStep,
  onClose,
  allClassifications,
  addClassification,
  removeClassification,
  allPrefixes,
  setCurrClassification,
}) => {
  const { colors } = useDefaultStyles();
  const [quickSearchValue, setQuickSearchValue] = useState('');
  const [allThemesSelectorValue, setAllThemesSelectorValue] = useState(false);

  useEffect(() => {
    if (currClassification.join('|') !== quickSearchValue) {
      setQuickSearchValue('');
    }
  }, [quickSearchValue, currClassification]);

  const quickSearchOptions = useMemo(() => allPrefixes.map(x => {
    const array = x.split('|');
    return { value: x, label: array[array.length - 1] };
  }), [allPrefixes]);

  const onQuickSearchChange = useCallback((e: string) => {
    setQuickSearchValue(e);
    setCurrClassification(e.split('|').filter(x => x !== ''));
  }, [setCurrClassification]);

  const onThemeSelectorChange = useCallback((value, i) => {
    handleClassification(value, i);
    setQuickSearchValue(currClassification.join('|'));
  }, [currClassification, handleClassification]);

  const isAddThemeButtonDisabled = useMemo(() => currClassification.length === 0
    || allClassifications.includes(currClassification.join('|')), [allClassifications, currClassification]);

  return (
    <>
      <Text>Crie uma atividade com vários temas!</Text>
      {
        !allThemesSelectorValue && (
          <>
            <Text color={colors.secondary.keep} fontSize={12}>Busca rápida</Text>
            <SearchableSelector
              options={quickSearchOptions}
              onChange={onQuickSearchChange}
              value={quickSearchValue}
              optionLabel={quickSearchValue.split('|')[quickSearchValue.split('|').length - 1]}
            />
            <Text color={colors.secondary.keep} fontSize={12}>Seleção de tema</Text>
            {
              !!classificationOptions[0] && classificationOptions.map((_, i) => (
                <SearchableSelector
                  key={Math.random()}
                  options={classificationOptions[i].map(x => ({ value: x, label: x }))}
                  value={currClassification[i]}
                  onChange={e => onThemeSelectorChange(e, i)}
                />
              ))
            }
            <Flex w="100%" justify="flex-end">
              <Button
                color={colors.secondary.keep}
                variant="ghost"
                disabled={isAddThemeButtonDisabled}
                leftIcon={<FontAwesomeIcon icon={faPlusCircle} />}
                onClick={() => addClassification(currClassification)}
              >
                Adicionar tema
              </Button>
            </Flex>
          </>
        )
      }
      <ThemeListBox
        inputVariant="classifications"
        itemsList={allClassifications}
        isAllThemesSelectorChecked={allThemesSelectorValue}
        setIsAllThemesSelectorChecked={setAllThemesSelectorValue}
        onRemoveItemClick={removeClassification}
      />
      <Flex justify="end" w="100%">
        <SecondaryButton onClick={onClose} mr={2} variant="ghost">
          Cancelar
        </SecondaryButton>
        <PrimaryButton
          disabled={!allThemesSelectorValue && allClassifications.length === 0}
          onClick={() => setStep(prev => prev + 1)}
        >
          Próximo
        </PrimaryButton>
      </Flex>
    </>
  );
};
