import {
  ButtonProps,
  ListItem, Modal, ModalBody, ModalCloseButton, ModalContent,
  ModalHeader, ModalOverlay, OrderedList, Text, UnorderedList,
  useDisclosure,
} from '@chakra-ui/react';
import { FC } from 'react';
import { useDefaultStyles } from '../../../hooks/useDefaultStyles';
import { SecondaryButton } from '../../../lib/components/SecondaryButton';
import { WeekDocType } from './WeekProgress';

interface LearnMoreBtnWeekProgressType extends ButtonProps {
  weekDoc: WeekDocType,
}

export const LearnMoreBtnWeekProgress: FC<LearnMoreBtnWeekProgressType> = ({
  weekDoc,
  ...props
}) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { colors } = useDefaultStyles();
  return (
    <>
      <SecondaryButton
        onClick={onOpen}
        {...props}
      >
        Saiba mais
      </SecondaryButton>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent pb={2} bgColor={colors.background}>
          <ModalHeader />
          <ModalCloseButton />
          <ModalBody>
            <Text fontWeight="bold" fontSize="lg">
              Complete as tarefas abaixo para conquistar seus troféus.
              Cada atividade tem a sua meta bronze, prata e ouro e elas obedecem as seguintes regras:
            </Text>
            <OrderedList my={2} spacing={2}>
              <ListItem>Você só pode ganhar, no máximo, 1 troféu por semana.</ListItem>
              <ListItem>
                Para ganhar o troféu bronze, é preciso garantir que todas as
                atividades propostas alcançaram a meta bronze e só assim é possível atingir a meta prata.
              </ListItem>
              <ListItem>
                Para ganhar o troféu prata, é preciso atingir as metas prata de todas as
                atividades propostas da sua agenda. Só assim é possível atingir a meta ouro.
              </ListItem>
              <ListItem>
                A meta ouro é atingida quando você alcança as metas semanais previstas no seu
                curso e apontadas em cada uma das atividades abaixo.
                Exemplo: se na Revisão Inteligente aparece a opção (0/5),
                isso significa que 5 é a meta semanal proposta para aquela atividade.
              </ListItem>
              <ListItem>
                As metas parciais dessa semana são uma proporção das metas semanais do seu curso, e são as seguintes:
              </ListItem>
            </OrderedList>
            <UnorderedList>
              {weekDoc?.map(item => (
                <ListItem key={item.abbr}>
                  {`${item.abbr}:`}
                  <Text display="inline">
                    {' Bronze '}
                    <Text as="span" display="inline" color={colors.secondary.keep}>{item.bronze}</Text>
                    {' / '}
                  </Text>
                  <Text display="inline">
                    {' Prata '}
                    <Text as="span" display="inline" color={colors.secondary.keep}>{item.silver}</Text>
                    {' / '}
                  </Text>
                  <Text display="inline">
                    {' Ouro '}
                    <Text as="span" display="inline" color={colors.secondary.keep}>{item.gold}</Text>
                  </Text>
                </ListItem>
              ))}
            </UnorderedList>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
