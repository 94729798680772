import { Box } from '@chakra-ui/react';
import { FC } from 'react';
import { useDefaultStyles } from '../../../../../hooks/useDefaultStyles';
import { useDevice } from '../../../../../hooks/useDevice';

export const HarrisonTag: FC = () => {
  const { colors } = useDefaultStyles();
  const device = useDevice();

  return (
    <Box
      display="inline"
      fontSize={device === 'mobile' ? 'xx-small' : 'xs'}
      color={colors.secondary.keep}
      px={2}
      borderRadius={10}
      border="1px solid"
      borderColor={colors.secondary.keep}
    >
      Baseado em Dados Academicos
    </Box>
  );
};
