import React, {
  FC, useContext, useMemo,
} from 'react';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Redirect, useHistory } from 'react-router-dom';
import { useGetSmartExam } from '../../../api/agenda/smart-exam';
import SizedBox from '../../../lib/components/SizedBox';
import Text from '../../../lib/components/Text';
import { PrivateContext } from '../../../Private.context';
import { getActivityIndex } from '../../../hooks/getActivityIndex';
import { Questions } from '../types/Questions.component';

const AgendaSmartExam: FC = () => {
  const { agenda, hasAgenda } = useContext(PrivateContext);
  const history = useHistory();

  const id = useMemo(() => {
    return agenda && agenda.smartExamActivity ? agenda.smartExamActivity : '';
  }, [agenda]);

  const query = useGetSmartExam({ _id: id });

  if (hasAgenda.examType === 'exams') {
    return <Redirect to={`/agenda/prova${window.location.search}`} />;
  }

  if (hasAgenda.examType === 'mocks') {
    return <Redirect to={`/agenda/simulado${window.location.search}`} />;
  }

  if (query.data && !getActivityIndex()) {
    const currentUrlParams = new URLSearchParams(window.location.search);
    currentUrlParams.set('a', query.data.smartExam._id);
    history.replace({ search: currentUrlParams.toString() });
  }

  if (query.loading) {
    return (
      <SizedBox flex align="center" justify="center" height={400}>
        <FontAwesomeIcon icon={faSpinner} spin className="color-dark-gray" size="5x" />
      </SizedBox>
    );
  }

  if (query.data && query.data.smartExam) {
    return (
      <Questions
        model="exams"
        data={query.data.smartExam.questions}
        activityId={query.data.smartExam._id}
        finished={query.data.smartExam.finished}
        serverPreChoices={query.data.smartExam.preChoice}
      />
    );
  }

  return (
    <SizedBox className="py-4">
      <Text tag="h5" center>
        Não há simulado disponível para esse dia
      </Text>
    </SizedBox>
  );
};

export default AgendaSmartExam;
