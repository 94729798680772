import { Box, Flex, Text, Tooltip, VStack } from '@chakra-ui/react';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC, useMemo } from 'react';
import { useDefaultStyles } from '../../../hooks/useDefaultStyles';
import { ComparisonText, ComparisonTextTemplateType } from './ComparisonText.component';

type ValueWithLabelAndPercentType = {
  label: string,
  previousValue: number,
  currentValue: number,
  template?: ComparisonTextTemplateType,
  fontSize?: string | number,
  starThreshold?: number,
}

export const ValueWithLabelAndPercent: FC<ValueWithLabelAndPercentType> = ({
  label, previousValue, currentValue, fontSize = '2xl',
  starThreshold, template = 'percent verbose',
}: ValueWithLabelAndPercentType) => {
  const { colors } = useDefaultStyles();
  const text = useMemo(() => {
    if (template.startsWith('percent')) {
      return `${Math.round(currentValue)}%`;
    }
    return currentValue;
  }, [currentValue, template]);

  return (
    <VStack>
      <Flex position="relative">
        {
          starThreshold && currentValue >= starThreshold && (
            <Tooltip label={`Parabéns! Você cumpriu a meta de ${starThreshold}%
                 de desempenho em questões ${label.toLowerCase()}!`}
            >
              <Box position="absolute" color="warning.500" left={-6} top={1} fontSize={12} ml={1}>
                <FontAwesomeIcon icon={faStar} />
              </Box>
            </Tooltip>
          )
        }
        <Text fontWeight="bold">
          {label}
        </Text>
      </Flex>
      <Text
        lineHeight={1}
        color={colors.secondary.keep}
        fontSize={fontSize}
      >
        {text}
      </Text>
      <ComparisonText
        text=""
        currentValue={currentValue}
        previousValue={previousValue}
        template={template}
      />
    </VStack>
  );
};
