import { FC, useMemo, useState } from 'react';
import moment from 'moment';
import { Box, Button, Flex, HStack, Modal, ModalContent, ModalOverlay, Text } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faEye, faEyeSlash, faStopCircle } from '@fortawesome/free-regular-svg-icons';
import { useStopwatch } from '../../hooks/useStopWatch';
import { useDefaultStyles } from '../../hooks/useDefaultStyles';
import { useDevice } from '../../hooks/useDevice';

interface CounterProps {
  startedAt?: Date;
  timeToDo?: number;
  showCounter?: boolean;
  device?: 'web' | 'mobile' | 'both';
  finish?: () => Promise<void>;
  answered?: number;
  total?: number;
}

export const Counter: FC<CounterProps> = ({
  startedAt,
  timeToDo,
  device = 'both',
  showCounter = true,
}) => {
  const canDoUntil = useMemo(() => {
    if (!startedAt) {
      return moment();
    }
    return moment(startedAt).add(timeToDo, 'milliseconds');
  }, [startedAt, timeToDo]);

  const now = useStopwatch(canDoUntil);
  const currentDevice = useDevice();
  const { cardBorder, colors } = useDefaultStyles();

  if ((device !== 'both' && device !== currentDevice) || !showCounter || !timeToDo) {
    return <></>;
  }

  return (now.h && now.m && now.s) ? (
    <Box
      {...cardBorder}
      position="relative"
      px={1}
      py={2}
      mb={2}
    >
      <Flex align="center" justify="center">
        <Box position="absolute" left={2} color={colors.secondary.goALittleDarker}>
          <FontAwesomeIcon icon={faClock} size="lg" />
        </Box>
        <Text fontSize="small" fontWeight="bold" textAlign="center">
          {`${now.h}h${now.m}m${now.s}s`}
        </Text>
      </Flex>
    </Box>
  ) : null;
};

export const CounterV2: FC<CounterProps> = ({
  startedAt,
  timeToDo,
  device = 'both',
  showCounter = true,
  finish,
  answered,
  total,
}: CounterProps) => {
  const [hidden, setHidden] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const canDoUntil = useMemo(() => {
    if (!startedAt) {
      return moment();
    }
    return moment(startedAt).add(timeToDo, 'milliseconds');
  }, [startedAt, timeToDo]);

  const now = useStopwatch(canDoUntil);
  const currentDevice = useDevice();
  const { colors } = useDefaultStyles();

  const handleShow = () => {
    setHidden(!hidden);
  };

  const handleModal = () => {
    setIsOpen(!isOpen);
    console.log(isOpen ? 'Abriu modal' : 'Fechou modal');
  };

  const handleConfirm = async () => {
    console.log('teste', finish);
    if (finish) {
      await finish();
    }
    handleModal();
  };

  if ((device !== 'both' && device !== currentDevice) || !showCounter || !timeToDo) {
    return <></>;
  }

  const timerColor = '#001E2F';
  const buttonColor = '#415F91';

  return (now.h && now.m && now.s) ? (
    <>
      <ConfirmModal
        isOpen={isOpen}
        answered={answered ?? 0}
        total={total ?? 0}
        onConfirm={handleConfirm}
        onClose={handleModal}
      />
      <Flex
        alignItems="center"
        justifyContent="space-between"
        backgroundColor={colors.lighter.goMuchDarker}
        position="relative"
        padding="4px 16px"
        borderRadius="16px"
        w="100%"
        maxW="320px"
        flexWrap="wrap"
      >
        <Flex flexShrink={1} align="center" flexGrow={1}>
          <Box color={timerColor}>
            <FontAwesomeIcon icon={faClock} />
          </Box>
          <Text color={timerColor} pl="8px" fontSize="16px" fontWeight="400" textAlign="center">
            {hidden ? '--:--:--' : `${now.h}:${now.m}:${now.s}`}
          </Text>
        </Flex>
        <Flex flexShrink={1} align="center">
          <Box cursor="pointer" color={colors.secondary.goDarker}>
            <FontAwesomeIcon color={buttonColor} onClick={handleShow} icon={hidden ? faEyeSlash : faEye} />
          </Box>
          <Box cursor="pointer" pl="16px" color={colors.secondary.goDarker}>
            <FontAwesomeIcon color={buttonColor} onClick={handleModal} icon={faStopCircle} />
          </Box>
        </Flex>
      </Flex>
    </>
  ) : null;
};

interface ConfirmModalProps {
  isOpen: boolean;
  answered: number;
  total: number
  onClose: () => void;
  onConfirm: () => Promise<void>;
}

const ConfirmModal: FC<ConfirmModalProps> = ({
  isOpen,
  answered,
  total,
  onClose,
  onConfirm,
}: ConfirmModalProps) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent
        bgColor="E7E8EE"
        padding="32px 24px"
        maxW="312px"
        borderRadius="32px"
      >
        <Text fontSize="24px">Encerrar Simulado?</Text>
        <p>
          {`Deseja encerrar o seu simulado?\nVocê respondeu ${answered} de ${total}
        questões, as questões não-respondidas serão consideradas como incorretas.`}
        </p>
        <HStack mt="24px" spacing="8px" justifyContent="flex-end">
          <Button cursor="pointer" size="sm" color="#415F91" variant="ghost" onClick={onClose}>
            voltar ao simulado
          </Button>
          <Button cursor="pointer" size="sm" color="#415F91" variant="ghost" onClick={onConfirm}>
            encerrar
          </Button>
        </HStack>
      </ModalContent>
    </Modal>
  );
};
