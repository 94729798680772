import { Flex } from '@chakra-ui/react';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useVideoMany } from '../../api/agenda/videos';
import { getParam } from '../../hooks/useSearchParam';
import AgendaVideo from '../agenda/types/AgendaVideo';
import VideoEmpty from '../agenda/types/VideoEmpty';

export function VideoViewer() {
  const videoId = getParam('id');

  const query = useVideoMany({
    ids: [videoId] || [],
  });

  if (query.loading) {
    return (
      <Flex align="center" justify="center" style={{ minHeight: 400 }}>
        <FontAwesomeIcon icon={faSpinner} spin size="5x" className="color-dark-gray" />
      </Flex>
    );
  }

  if (!query.error
    && !query.loading
    && query.data
    && query.data.videoMany.length > 0
  ) {
    return (
      <AgendaVideo
        data={query.data.videoMany}
      />
    );
  }

  return <VideoEmpty />;
}
