import React, {
  createContext, FC, useCallback, useEffect, useState,
} from 'react';
import { MockTestAlert, MockTestAlertProps } from '../_components/MockTestAlert';

interface DiagnosisAlertContextProps {
  handleSetAlertProps: (props: MockTestAlertProps) => void;
  handleSetActive: (isActive: boolean) => void;
}

export const DiagnosisAlertContext = createContext({} as DiagnosisAlertContextProps);

export const DiagnosisAlertContextProvider: FC = ({
  children,
}) => {
  const [alertProps, setAlertProps] = useState<MockTestAlertProps>({
    title: '',
    body: '',
    active: false,
  });

  const handleSetAlertProps = useCallback((props: MockTestAlertProps) => {
    setAlertProps(props);
  }, []);

  const handleSetActive = useCallback((isActive: boolean) => {
    setAlertProps(prev => ({
      ...prev,
      active: isActive,
    }));
  }, []);

  useEffect(() => {
    document.onkeydown = e => {
      if (e.code === 'Escape') {
        handleSetActive(false);
      }
    };
    return () => {
      document.onkeydown = null;
    };
  }, [handleSetActive]);

  return (
    <DiagnosisAlertContext.Provider value={{
      handleSetAlertProps,
      handleSetActive,
    }}
    >
      <MockTestAlert
        {...alertProps}
      />
      {children}
    </DiagnosisAlertContext.Provider>
  );
};
