import { Flex } from '@chakra-ui/react';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC } from 'react';
import { useDefaultStyles } from '../../../hooks/useDefaultStyles';

interface BallProps {
  active?: boolean;
  onClick?: () => void;
}

const Ball: FC<BallProps> = ({ active = false, onClick }) => {
  const { colors } = useDefaultStyles();
  return (
    <Flex
      as="button"
      align="center"
      justify="center"
      width={30}
      height={30}
      borderRadius={15}
      border="solid 2px"
      borderColor={colors.secondary.keep}
      backgroundColor={active ? colors.secondary.keep : colors.lighter.goAlmostFullDarker}
      color={colors.lighter.keep}
      onClick={onClick}
    >
      <FontAwesomeIcon icon={faCheck} opacity={active ? 1 : 0} />
    </Flex>
  );
};

export default Ball;
