import { format } from 'date-fns';

export function formatDate(date: Date) {
  return format(date, 'yyyyLLdd');
}

export function stringToDate(date: string) {
  return new Date(
    Number(date.slice(0, 4)),
    Number(date.slice(4, 6)) - 1,
    Number(date.slice(6)),
  );
}
