import { Flex, Image } from '@chakra-ui/react';
import { FC } from 'react';
import { Route, Switch } from 'react-router-dom';
import NotFoundPage from '../not-found/NotFound.page';
import ForgotPasswordForm from './ForgotPassword.form';
import LoginForm from './Login.form';
import RegisterForm from './Register.form';
import { EnterPageSlideShow } from './_components/EnterPageSlideShow';

const EnterPage: FC = () => {
  return (
    <Flex
      align="center"
      paddingTop={{ base: 0, md: '4rem' }}
      flexFlow="column"
      gridGap="3rem"
      overflow="auto"
      backgroundColor={{ base: 'white', md: 'secondary.50' }}
      minHeight="100vh"
    >
      <Image
        display={{ base: 'none', md: 'block' }}
        src="images/aristo-logo-slogan.png"
        width="15.5rem"
        marginX="auto"
      />
      <Flex gridGap="4rem" align="center">
        <EnterPageSlideShow />
        <Flex
          borderRadius={{ base: 0, md: 8 }}
          minHeight={{ md: 550 }}
          align="flex-start"
          justify="center"
          // overflow="hidden"
          wrap={{ base: 'wrap', md: 'nowrap' }}
          bgColor="white"
          width={{ base: '100vw', md: 500 }}
          boxShadow={{ base: '', md: '0px 0px 5px 2px rgba(0, 0, 0, .25)' }}
        >
          <Flex justify="center" flexFlow="column" width={500}>
            <Image
              display={{ base: 'block', md: 'none' }}
              src="images/logo-name-blue.png"
              width="7rem"
              marginLeft="2rem"
              marginY="2rem"
            />
            <Flex flexFlow="column" align="center">
              <Switch>
                <Route exact path="/" component={LoginForm} />
                <Route path="/registrar" component={RegisterForm} />
                <Route path="/redefinir-senha" component={ForgotPasswordForm} />
                <Route path="*" component={NotFoundPage} />
              </Switch>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default EnterPage;
