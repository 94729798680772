import { Box, Center, HStack, Image, Square, Text, VStack } from '@chakra-ui/react';
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, useEffect, useMemo } from 'react';
import { useDefaultStyles } from '../../../hooks/useDefaultStyles';
import { Loading } from '../../../lib/components/Loading';

type LoadingQueueItemType = {
  label: string,
  loading: boolean,
}

type LoadingBarInputType = {
  loadingQueue: LoadingQueueItemType[],
  hide?: boolean,
  setFinishedTimePad?: React.Dispatch<React.SetStateAction<boolean>>,
}

export const LoadingBar: FC<LoadingBarInputType> = ({
  loadingQueue, hide = false, setFinishedTimePad,
}: LoadingBarInputType) => {
  const onlyLoading = useMemo(() => {
    return loadingQueue.filter(x => x.loading).map(x => x.label);
  }, [loadingQueue]);
  const { colors } = useDefaultStyles();

  const percentCompleted = Math.round(((loadingQueue.length - onlyLoading.length) * 100) / loadingQueue.length);

  useEffect(() => {
    if (setFinishedTimePad && onlyLoading.length === 0) {
      setFinishedTimePad(false);
      setTimeout(() => {
        setFinishedTimePad(true);
      }, 2000);
    }
  }, [onlyLoading.length, setFinishedTimePad]);

  if (hide) {
    return <></>;
  }

  return (
    <VStack align="center" justify="center">
      <Image src="/images/aristo-design/p1.svg" w={200} />
      <Center w="100%" mt={50}>
        <Box
          mt={5}
          w={250}
          h={5}
          bgColor={colors.primary.goLighter}
          borderRadius={7}
          position="relative"
        >
          <Box
            position="absolute"
            transition="all .6s"
            w={`${percentCompleted}%`}
            borderRadius={7}
            h="100%"
            bgColor={colors.secondary.goLighter}
          />
          <Center w="100%" position="relative">
            <HStack>
              <Loading color={colors.lighter.keep} />
              <Text fontSize="sm" color={colors.lighter.keep}>
                Gerando...
              </Text>
            </HStack>
          </Center>
        </Box>
      </Center>
      <Box>
        {
          loadingQueue.map(item => {
            const isLoaded = !onlyLoading.includes(item.label);
            return (
              <HStack key={item.label}>
                {
                  isLoaded ? (
                    <Square color={colors.secondary.goLighter}>
                      <FontAwesomeIcon icon={faCheckCircle} />
                    </Square>
                  ) : (
                    <Square color={colors.primary.goLighter}>
                      <FontAwesomeIcon icon={faTimesCircle} />
                    </Square>
                  )
                }
                <Text
                  color={isLoaded ? colors.secondary.goLighter : colors.primary.goLighter}
                >
                  {item.label}
                </Text>
              </HStack>
            );
          })
        }
      </Box>
    </VStack>
  );
};
