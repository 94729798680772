import { Box, Flex, Text } from '@chakra-ui/react';
import { FC, ReactNode } from 'react';
import { useDefaultStyles } from '../../../../hooks/useDefaultStyles';

type ComparativeCardType = {
  title: string,
  leftBox: string | ReactNode,
  rightBox?: string | ReactNode,
}

export const ComparativeCard: FC<ComparativeCardType> = ({
  title, leftBox, rightBox,
}: ComparativeCardType) => {
  const { colors } = useDefaultStyles();
  return (
    <Flex flexFlow="column" w="100%">
      <Flex
        w="100%"
        px={4}
        borderRadius="5px 5px 0px 0px"
        color={colors.background}
        bgColor={colors.secondary.goALittleDarker}
        justify="center"
      >
        <Text>
          {title}
        </Text>
      </Flex>
      <Flex
        w="100%"
      >
        <Box
          p={2}
          flex={1}
          border="1px solid"
          borderColor={colors.secondary.goALittleDarker}
          borderRadius="0px 0px 0px 5px"
          overflow="hidden"
        >
          {leftBox}
        </Box>
        <Box
          p={2}
          flex={1}
          border="1px solid"
          borderColor={colors.secondary.goALittleDarker}
          borderRadius="0px 0px 5px 0px"
          overflow="hidden"
        >
          {rightBox || leftBox}
        </Box>
      </Flex>
    </Flex>
  );
};
