import { Box, SimpleGrid } from '@chakra-ui/react';
import { QuestionPerformanceChart } from './QuestionPerformanceChart';
import { QuestionPerformanceBySubjectTable } from './QuestionPerformanceBySubjectTable';
import { useDevice } from '../../../../hooks/useDevice';
import { QuestionCountByTag } from './QuestionCountByTag';

export function GeneralPerformance() {
  const device = useDevice();
  return (
    <Box maxW={900} margin="0.5rem auto">
      {device === 'web' && (
        <SimpleGrid columns={2} columnGap={4}>
          <Box>
            <QuestionCountByTag />
            <QuestionPerformanceBySubjectTable />
          </Box>
          <QuestionPerformanceChart />
        </SimpleGrid>
      )}
      {device === 'mobile' && (
        <>
          <QuestionCountByTag />
          <QuestionPerformanceChart />
          <QuestionPerformanceBySubjectTable />
        </>
      )}
    </Box>
  );
}
