import {
  FC, useContext, useState,
} from 'react';
import { Text, VStack } from '@chakra-ui/react';
import { PrivateContext } from '../../Private.context';
import { ExtraActivityModalProps } from './CreateActivityModal';
import { useDefaultStyles } from '../../hooks/useDefaultStyles';
import useClassificationCascade from '../../hooks/useClassificationCascade';
import { GeneralQuestionOptions } from './GeneralQuestionOptions';
import { ClassificationQuestionOptions } from './ClassificationQuestionOptions';

const QuestionModal: FC<ExtraActivityModalProps> = ({
  onClose, onAfterCreate,
}) => {
  const { profile } = useContext(PrivateContext);
  const { colors } = useDefaultStyles();
  const [step, setStep] = useState(0);

  const {
    classificationOptions,
    handleClassification,
    allClassifications,
    currClassification,
    addClassification,
    removeClassificationString,
    setCurrClassification,
    allPrefixes,
  } = useClassificationCascade(profile.courses[0].detailedClassificationTree);

  return (
    <>
      <Text color={colors.secondary.goALittleDarker} mb={2} fontWeight="bold">Questões</Text>
      <VStack py={2} mb={4} align="start">
        {
          step === 0 && (
            <ClassificationQuestionOptions
              classificationOptions={classificationOptions}
              handleClassification={handleClassification}
              currClassification={currClassification}
              setCurrClassification={setCurrClassification}
              setStep={setStep}
              onClose={onClose}
              allClassifications={allClassifications}
              addClassification={addClassification}
              removeClassification={removeClassificationString}
              allPrefixes={allPrefixes}
            />
          )
        }
        {
          step === 1 && (
            <GeneralQuestionOptions
              allClassifications={allClassifications}
              onClose={onClose}
              onAfterCreate={onAfterCreate}
              setStep={setStep}
            />
          )
        }
      </VStack>
    </>
  );
};

export default QuestionModal;
