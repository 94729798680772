import { Box, Flex, HStack } from '@chakra-ui/react';
import { Flashcard } from '../../../api/agenda/flashcards';
import { useDevice } from '../../../hooks/useDevice';
import Slider from '../../../lib/components/Slider';
import { MyDeckButton } from '../../flashcards-creation/_components/Buttons/MyDeckButton';
import { PreviewFlashcard } from './PreviewFlashcard';

const quant = 10;

interface FlashcardIndexNavigatorProps {
  data: Flashcard[]
  init: number;
  handlePrev: () => void;
  handleNext: () => void;
  answers: number[];
  index: number;
  onClick: (i: number) => () => void;
}

export function FlashcardIndexNavigator({
  index, onClick, answers, data, init, handlePrev, handleNext,
}: FlashcardIndexNavigatorProps) {
  const device = useDevice();

  if (device === 'mobile') {
    return (
      <HStack>
        <HStack justify="space-between" align="stretch" m="0 auto" p={2} py={4} overflowX="scroll">
          <Flex justify="center">
            {
              data.map((card, i) => {
                const _key = Math.random().toString(36).slice(2);
                return (
                  <PreviewFlashcard
                    index={init + i}
                    onClick={onClick}
                    key={_key}
                    active={init + i === index}
                    answer={answers[init + i]}
                  />
                );
              })
            }
          </Flex>
        </HStack>
        <MyDeckButton show={!window.location.pathname.includes('aristo-compass')} />
      </HStack>
    );
  }

  return (
    <Box my={4}>
      <HStack justify="space-between">
        <Slider onPrev={handlePrev} onNext={handleNext}>
          <Flex justify="center">
            {
              data.slice(init, init + quant).map((card, i) => {
                const _key = Math.random().toString(36).slice(2);
                return (
                  <PreviewFlashcard
                    index={init + i}
                    onClick={onClick}
                    key={_key}
                    active={init + i === index}
                    answer={answers[init + i]}
                  />
                );
              })
            }
          </Flex>
        </Slider>
        <MyDeckButton show={!window.location.pathname.includes('aristo-compass')} />
      </HStack>
    </Box>
  );
}
