import { Box, Button, Flex, HStack, Text, VStack } from '@chakra-ui/react';
import { faVolleyballBall } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC, useCallback, useContext, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDefaultStyles, useRGBColor } from '../../../hooks/useDefaultStyles';
import { useLocalStorage } from '../../../hooks/useLocalStorage';
import Select from '../../../lib/components/Select';
import { PrivateContext } from '../../../Private.context';
import ids from './AristoCupSelectedIds.json';

export const AristoCupMain: FC = () => {
  const { colors } = useDefaultStyles();
  const { toRGB } = useRGBColor();
  const [selectedOption, setSelectedOption] = useState('');
  const { isUserAristoTeam } = useContext(PrivateContext);
  const history = useHistory();
  const [alreadySortedIds, setAlreadySortedIds] = useLocalStorage('@Aristoclass:sortedIds', {
    easy: [],
    medium: [],
    hard: [],
    flashcards: [],
  });

  const randomChoice = useCallback((array: string[]) => {
    return array[Math.floor(array.length * Math.random())];
  }, []);

  const availableIds = useMemo(() => {
    return {
      flashcards: ids.flashcards.filter(x => !alreadySortedIds.flashcards.includes(x)),
      easy: ids.easy.filter(x => !alreadySortedIds.easy.includes(x)),
      medium: ids.medium.filter(x => !alreadySortedIds.medium.includes(x)),
      hard: ids.hard.filter(x => !alreadySortedIds.hard.includes(x)),
    };
  }, [alreadySortedIds.easy, alreadySortedIds.flashcards, alreadySortedIds.hard, alreadySortedIds.medium]);

  const disabledOptions = useMemo(() => {
    return Object.keys(availableIds).filter(x => availableIds[x as keyof typeof availableIds].length === 0);
  }, [availableIds]);

  const handleSortClick = useCallback(() => {
    const sortedId = randomChoice(availableIds[selectedOption as keyof typeof availableIds]);

    setAlreadySortedIds({
      ...alreadySortedIds,
      [selectedOption]: [...(alreadySortedIds[selectedOption]), sortedId],
    });
    window.open(`/viewer/${selectedOption === 'flashcards' ? 'flashcard' : 'questao'}?id=${sortedId}`, '_blank');
  }, [alreadySortedIds, availableIds, randomChoice, selectedOption, setAlreadySortedIds]);

  if (!isUserAristoTeam) {
    history.push('/agenda');
    return null;
  }

  return (
    <Flex
      w="100%"
      h="100vh"
      background={`linear-gradient(${toRGB(colors.primary.keep)}, ${toRGB(colors.intermediary.keep)});`}
      align="center"
      justify="center"
      flexFlow="column"
      position="relative"
    >
      <Flex
        w="100%"
        h="100vh"
        position="absolute"
        backgroundImage="images/aristo-cup.jpg"
        backgroundSize="cover"
        opacity={0.1}
        filter="hue-rotate(210deg)"
      />
      <Flex align="center" justify="center">
        <HStack align="center">
          <Text
            color={colors.white.keep}
            fontSize={40}
          >
            Copa Aristo
          </Text>
          <Box color={colors.white.keep} fontSize="2xl" className="rotate-ball">
            <FontAwesomeIcon icon={faVolleyballBall} />
          </Box>
        </HStack>
      </Flex>
      <Flex
        maxW={800}
        borderRadius={10}
        bgColor={colors.lighter.keep}
        flexFlow="column"
        padding={10}
        align="center"
        justify="center"
      >
        <VStack>
          <Text fontSize="xl" color={colors.secondary.keep}>Selecione o tipo a ser sorteado:</Text>
          <Select
            bgColor="white"
            w={280}
            value={selectedOption}
            onChange={e => setSelectedOption(e.target.value)}
            options={[
              { value: 'easy', label: 'Questões fáceis' },
              { value: 'medium', label: 'Questões médias' },
              { value: 'hard', label: 'Questões difíceis' },
              { value: 'flashcards', label: 'Flashcards' },
            ]}
          />
          {
            disabledOptions.includes(selectedOption) && (
              <Text color={colors.red.keep}>
                Sem mais itens dessa categoria!
              </Text>
            )
          }
        </VStack>
        <Button
          mt={10}
          w={280}
          disabled={disabledOptions.includes(selectedOption) || selectedOption === ''}
          bgColor={colors.intermediary.keep}
          onClick={handleSortClick}
          color={colors.white.keep}
          _hover={{ bgColor: colors.secondary.keep }}
        >
          Sortear!
        </Button>
      </Flex>
    </Flex>
  );
};
