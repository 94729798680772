import { Box, Center, Divider, Flex, HStack, Image, Text } from '@chakra-ui/react';
import { faCheckCircle, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC, useContext } from 'react';
import { FlashcardType } from '../../../../api/flashcards';
import { useDefaultStyles, useRGBColor } from '../../../../hooks/useDefaultStyles';
import { Loading } from '../../../../lib/components/Loading';
import { formatName } from '../../../../utils/forumUtils';
import { FlashcardsCreationContext } from '../../contexts/FlashcardsCreation.context';

type MiniFlashcardFaceType = {
  data?: FlashcardType,
  index: number,
  face: 'front' | 'back',
}

export const MiniFlashcardFace: FC<MiniFlashcardFaceType> = ({
  data, face, index,
}: MiniFlashcardFaceType) => {
  const { toRGB } = useRGBColor();
  const { colors, hueRotate } = useDefaultStyles();
  const { tab } = useContext(FlashcardsCreationContext);
  return (
    <Flex
      boxShadow={`4px 4px 0px 0px ${toRGB(colors.deck.shadow)}`}
      w="100%"
      h="100%"
      borderRadius={10}
      margin={2}
      px={4}
      pt={4}
      color={colors.white.goPallete50}
      bgColor={colors.primary.goALittleLighter}
      flexFlow="column"
    >
      <Flex w="100%" justify="space-between" flex={1}>
        <Image
          left={0}
          filter={hueRotate()}
          w="30px"
          src="/images/flash-card.svg"
          alt="flash"
        />
        <Text
          fontSize={12}
          fontWeight="bold"
        >
          {index}
        </Text>
      </Flex>
      <Center w="100%" flex={1}>
        <Text
          fontSize="xx-small"
          color={colors.white.keep}
          noOfLines={1}
        >
          {data?.prefix}
        </Text>
      </Center>
      <Divider />
      <Flex w="100%" align="center" justify="center" flex={8}>
        {
          data ? (
            <Text
              noOfLines={5}
              textAlign="left"
              fontSize="xs"
            >
              {face === 'front' ? data?.front : data?.back}
            </Text>
          ) : (
            <Loading />
          )
        }
      </Flex>
      <Divider />
      <Flex w="100%" flex={2} fontSize="sm" align="center">
        {
          face === 'front' ? (
            <Flex w="100%" justify="space-between">
              <HStack spacing={1}>
                <Box mr={1}>
                  <FontAwesomeIcon icon={faPlusCircle} />
                </Box>
                <Text>{data?.timesAdded}</Text>
              </HStack>
              {
                (data?.alreadyAdded || data?.ownFlashcard || tab === 'meus-baralhos') && (
                  <Box color={colors.green.keep}>
                    <FontAwesomeIcon icon={faCheckCircle} />
                  </Box>
                )
              }
            </Flex>
          ) : (
            <Text
              fontSize="xx-small"
              noOfLines={1}
            >
              {`Criador: ${formatName(data?.createdBy?.name)}`}
            </Text>
          )
        }
      </Flex>
    </Flex>
  );
};
