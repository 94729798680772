import { Box, Flex, Text, TextProps } from '@chakra-ui/react';
import { FC, ReactNode } from 'react';
import { useDefaultStyles } from '../../hooks/useDefaultStyles';
import { useDevice } from '../../hooks/useDevice';
import { TableStyleType } from '../../views/diagnosis/_components/DiagnosticAreaTable';
import { colorsType } from '../types/types';

export interface RowDataProps extends TextProps {
  key: string;
  value: (string | ReactNode);
  cellTooltip?: string;
  flexSpan?: number;
  showOnMobile?: boolean;
}

interface TableRowComponentProps {
  rowBackgroundColorWhite: number;
  lineTooltip?: string;
  rowData: RowDataProps[];
  isFooter?: boolean;
  footerColor?: colorsType | undefined;
  paddingLeft?: string;
  tableStyle?: TableStyleType;
}

const TableRowComponent: FC<TableRowComponentProps> = ({
  rowBackgroundColorWhite, lineTooltip, rowData, isFooter = false, footerColor = 'light-blue',
  paddingLeft, tableStyle,
}) => {
  const device = useDevice();
  const { colors } = useDefaultStyles();
  return (
    <Flex
      minH="40px"
      backgroundColor={rowBackgroundColorWhite ? colors.alpha[50] : 'transparent'}
      align="center"
      fontWeight={isFooter && tableStyle === 'compass' ? 'bold' : ''}
      color={tableStyle === 'compass' ? 'gray.600' : ''}
      paddingLeft={paddingLeft}
      py={1}
      title={lineTooltip}
    >
      {rowData.map(({
        key, value, flexSpan, align, cellTooltip, showOnMobile = true, ...rest
      }) => {
        return !showOnMobile && device === 'mobile' ? (
          <Box key={key} />
        ) : (
          <Box
            key={key}
            flex={flexSpan || 1}
            my="auto"
            px={1}
            textAlign={align || 'center'}
            title={cellTooltip}
          >
            {typeof value === 'string' ? (
              <Text
                fontSize="xs"
                {...rest}
              >
                {value}
              </Text>
            ) : (
              <>
                {value}
              </>
            )}
          </Box>
        );
      })}
    </Flex>
  );
};

export default TableRowComponent;
