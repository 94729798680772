import { Flex, Image, Text } from '@chakra-ui/react';
import { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { useDevice } from '../../../hooks/useDevice';
import { PrimaryButton } from '../../../lib/components/PrimaryButton';
import { SecondaryButton } from '../../../lib/components/SecondaryButton';

interface EmptyPageButtonProps {
  label: string,
  onClickRoute: string,
}
interface EmptyPageProps {
  primaryButton?: EmptyPageButtonProps | 'hide',
  secondaryButton?: EmptyPageButtonProps | 'hide',
  title?: string,
  paragraphs?: string[],
  marginTop?: number,
}

export const EmptyPage: FC<EmptyPageProps> = ({
  primaryButton = { label: 'Criar atividade', onClickRoute: '/atividades-extras' },
  secondaryButton = { label: 'Perfil de Estudo', onClickRoute: '/perfil' },
  title = 'Você não tem nada hoje!',
  paragraphs = [
    'Não temos nenhuma atividade hoje para você.',
    `Confira no seu perfil de estudo se você disponibilizou tarefas para
    esse dia ou crie uma atividade extra!`,
    'Obs.: As mudanças no perfil de estudo só passam a valer a partir do dia seguinte.',
  ],
  marginTop,
}: EmptyPageProps) => {
  const device = useDevice();
  const history = useHistory();
  return (
    <Flex
      padding={4}
      alignItems="center"
      justifyContent="center"
      flexFlow="column"
      marginTop={marginTop}
    >
      <Flex
        flexFlow={{ base: 'column', md: 'row' }}
        justifyContent="center"
        alignItems="center"
      >
        <Image
          src={`/images/aristo-design/${device === 'mobile' ? 'p1' : 'p2'}.svg`}
          width="12rem"
        />
        <Flex
          flexFlow="column"
          gridGap={5}
          fontSize="md"
          height="20rem"
          width="20rem"
          marginLeft={{ md: '2.5rem' }}
        >
          <Text fontSize="2xl" fontWeight="bold" marginTop={8}>{title}</Text>
          {paragraphs.map(p => <Text key={p}>{p}</Text>)}
        </Flex>
      </Flex>
      <Flex
        maxWidth="35rem"
        justifyContent="flex-end"
        gridGap={{ base: '1rem', md: '3rem' }}
        margin="1rem"
      >
        {
          secondaryButton !== 'hide' && (
            <SecondaryButton
              height="3rem"
              width={{ md: '16rem' }}
              onClick={() => history.push(secondaryButton.onClickRoute)}
            >
              {secondaryButton.label}
            </SecondaryButton>
          )
        }
        {
          primaryButton !== 'hide' && (
            <PrimaryButton
              height="3rem"
              width={{ md: '16rem' }}
              onClick={() => history.push(primaryButton.onClickRoute)}
            >
              {primaryButton.label}
            </PrimaryButton>
          )
        }
      </Flex>
    </Flex>
  );
};
