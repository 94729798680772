import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRocket } from '@fortawesome/free-solid-svg-icons';
import { FC, useCallback, useContext, useMemo, useState } from 'react';
import {
  Box, Button, Center, Flex, HStack, Image, Text, Tooltip,
} from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import { BurgerMenuItemType } from '../../../api/burger-menu';
import { DrawerMenu } from './DrawerMenu';
import { NotificationsComponent } from '../Notification/Notifications';
import { CourseContext } from '../../../CourseInfoContext';
import { PrivateContext } from '../../../Private.context';
import { useOnline } from '../../../hooks/useOnline';
import { toast } from '../../../utils/toast';
import { useDevice } from '../../../hooks/useDevice';
import { useDefaultStyles } from '../../../hooks/useDefaultStyles';
import { GlobalContext } from '../../../Global.context';

interface HeaderProps {
  burgerItems?: [string, BurgerMenuItemType[]][];
}

export const Header: FC<HeaderProps> = ({ burgerItems, children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const history = useHistory();
  const onlineCount = useOnline(isOpen);
  const device = useDevice();
  const { colors, logo } = useDefaultStyles();

  const handleRocket = useCallback(() => {
    setIsOpen(prev => !prev);
    toast.info({
      title: 'Hora de decolar!',
      description: `${onlineCount} pessoas estudando agora!`,
    });
  }, [onlineCount]);

  const { course, loading } = useContext(CourseContext);
  const { profile } = useContext(PrivateContext);
  const { isSprintCourse } = useContext(GlobalContext);

  const userCourse = useMemo(() => {
    if (profile.courses && profile.courses.length > 0 && course && !loading) {
      return course.find(c => c._id === profile.courses[0].course);
    }
    return undefined;
  }, [course, loading, profile.courses]);

  return (
    <Flex
      position="relative"
      bg={colors.primary.goALittleLighter}
      as="header"
      w="100%"
      h={{ base: '3.5rem', md: '5rem' }}
      px="1rem"
    >
      <HStack
        position="absolute"
        top={{ base: '4px', md: '14px' }}
        as="button"
        onClick={() => {
          if (isSprintCourse) {
            history.push('/sprint');
          } else {
            history.push('/agenda');
          }
        }}
        w="14%"
        align="center"
        h={12}
      >
        <Image w={{ base: '35px', md: '40px' }} src={logo('logo-header') || '/images/logo-aristo.svg'} alt="logo" />
        <Box display={{ base: 'none', xl: 'block' }} w="100%">
          <Tooltip label={userCourse?.title ?? ''}>
            <Text
              color={colors.lighter.fromWhite}
              noOfLines={2}
              textAlign="left"
              fontSize="sm"
            >
              {userCourse?.title ?? ''}
            </Text>
          </Tooltip>
        </Box>
      </HStack>
      {
        device === 'mobile' && (
          <Button
            variant="unstyled"
            borderRadius="50%"
            position="absolute"
            align="center"
            justify="center"
            top={2}
            left={61}
            textAlign="center"
            color={colors.lighter.fromWhite}
            fontSize="lg"
            border="solid white 1px"
            onClick={handleRocket}
            pr="2px"
            pt="2px"
          >
            <FontAwesomeIcon icon={faRocket} />
          </Button>
        )
      }
      <Center
        marginX={{ base: '0px', md: '80px' }}
        width="100%"
      >
        {children}
      </Center>
      <NotificationsComponent />
      <HStack
        position="absolute"
        top={{ base: '12px', md: '24px' }}
        right={{ base: '22px', md: '22px' }}
        spacing={8}
        ml="1rem"
      >
        <DrawerMenu items={burgerItems} />
      </HStack>
    </Flex>
  );
};
