import {
  FC,
} from 'react';
import { Box, Flex, HStack } from '@chakra-ui/react';
import useFirebaseStorage from '../../../hooks/useFirebaseStorage';
import { Text } from '../../../api/agenda/texts';
import { PdfItem } from '../../../lib/components/PdfItem';
import { MoreOptions } from '../../_components/MoreOptions';
import Stars from '../../_components/Stars';
import FavoriteBtn from '../_components/FavoriteBtn.component';

interface AgendaTextsProps {
  data: Text[];
}

const AgendaTexts: FC<AgendaTextsProps> = ({
  data,
}) => {
  const storage = useFirebaseStorage();

  return (
    <Box mt={8}>
      {data && data.map(item => {
        const storageUrl = storage.getURL(item.url);
        return (
          <Box key={item._id} maxW={700} m="1rem auto">
            <PdfItem
              href={storageUrl}
              title={item.title}
              subtitle={item.subtitle}
            />
            <Flex mt={2} px={2} align="center" justify="space-between">
              <Stars
                document={item._id}
                type="text"
              />
              <HStack>
                <FavoriteBtn
                  document={item._id}
                  type="text"
                />
                <MoreOptions
                  contentId={item._id}
                  type="text"
                />
              </HStack>
            </Flex>
          </Box>
        );
      })}
    </Box>
  );
};

export default AgendaTexts;
