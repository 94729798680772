import { faCalendarAlt } from '@fortawesome/free-regular-svg-icons';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import {
  FC, useMemo,
} from 'react';
import { useGetExamsHistory } from '../../../api/exams-history/exams-history';
import { generateExamActivityLinkWithSearchParams } from '../../../hooks/generateExamLinkWithSearchParams';
import Flex from '../../../lib/components/Flex';
import Text from '../../../lib/components/Text';
import useTableFilter from '../../../hooks/useTableFilter';
import TableFilterComponent from '../../theme-list/_components/TableFilterComponent';
import HistoryCardComponent from '../_components/HistoryCardComponent';
import { TextWithTooltip } from '../_components/TextWithTooltip.component';

const ExamsHistory: FC = () => {
  const getExamsHistoryQuery = useGetExamsHistory();

  /** Memos */
  const examsHistory = useMemo(() => {
    const { data } = getExamsHistoryQuery;
    if (!data) {
      return [];
    }
    return data.getActivityExamsHistory;
  }, [getExamsHistoryQuery]);

  const { dataFiltered: examsHistoryFiltered, setFilter } = useTableFilter(examsHistory);

  if (getExamsHistoryQuery.loading) {
    return (
      <Flex justify="center">
        <FontAwesomeIcon icon={faSpinner} spin size="4x" className="color-dark-gray mt-4" />
      </Flex>
    );
  }
  return examsHistoryFiltered.length !== 0 ? (
    <>
      <TableFilterComponent
        setFilter={setFilter}
        options={[
          {
            value: 'institution',
            content: 'Nome da instituição',
          },
          {
            value: 'calendarDate',
            content: 'Data de realização',
          },
          {
            value: 'grade',
            content: 'Desempenho',
          },
        ]}
      />

      {examsHistoryFiltered.map(exam => (
        <HistoryCardComponent
          title={`${exam.institution} - ${exam.uf} - ${exam.year}`}
          iconProps={{
            label: 'Ir para a atividade',
            destiny: generateExamActivityLinkWithSearchParams(exam, 'prova'),
            icon: <FontAwesomeIcon icon={faCalendarAlt} />,
          }}
          key={exam._id}
          rowData={[
            {
              label: 'Realizado em',
              key: 'calendarDate',
              value: moment(exam.calendarDate).format('DD[/]MM[/]YYYY'),
            },
            {
              label: 'Desempenho',
              key: 'grade',
              value: (
                <TextWithTooltip extraTag="ActivityExamExtra" activity={exam} />
              ),
            },
          ]}
        />
      ))}
    </>
  ) : (
    <Text tag="h4" center className="mt-6">Nenhum dado encontrado.</Text>
  );
};

export default ExamsHistory;
