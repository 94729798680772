import { Alert, AlertIcon, Link, Text, useColorModeValue, VStack } from '@chakra-ui/react';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';
import { getDateParam } from '../../hooks/getDateParam';
import { useDefaultStyles } from '../../hooks/useDefaultStyles';
import { HOURS_TO_EXTEND, isTodayExtended } from '../../utils/isTodayExtended';

export function PastDayTag() {
  const { colors } = useDefaultStyles();
  const errorBgColor = useColorModeValue('red.100', 'whiteAlpha.400');
  const errorIconColor = useColorModeValue('red.400', 'whiteAlpha.700');
  const warningBgColor = useColorModeValue('yellow.100', 'whiteAlpha.400');
  const warningIconColor = useColorModeValue('yellow.400', 'whiteAlpha.700');
  const [isInExtendedTime, setIsInExtendedTime] = useState(false);
  const [yesterday, setYesterday] = useState(moment().subtract(1, 'days').format('YYYYMMDD'));

  const selectedDate = getDateParam();

  useEffect(() => {
    const timer = setInterval(() => {
      setYesterday(moment().subtract(1, 'days').format('YYYYMMDD'));
      if (new Date().getHours() < HOURS_TO_EXTEND) {
        setIsInExtendedTime(true);
      } else {
        setIsInExtendedTime(false);
      }
    }, 2000);

    return () => clearInterval(timer);
  }, []);

  if (!isTodayExtended()) {
    return (
      <Alert
        status="error"
        bgColor={errorBgColor}
        justifyContent="center"
      >
        <AlertIcon color={errorIconColor} />
        <Text display="inline">
          Você está em um dia passado e, por isso, não poderá responder nenhuma atividade!
          {' '}
          <Link
            as={ReactRouterLink}
            to="/agenda"
            color={colors.secondary.keep}
            display="inline"
          >
            Ir para o dia atual
          </Link>
        </Text>
      </Alert>
    );
  }

  if (isInExtendedTime && selectedDate === yesterday) {
    return (
      <Alert
        status="warning"
        bgColor={warningBgColor}
        justifyContent="center"
      >
        <AlertIcon color={warningIconColor} />
        <VStack w="100%" spacing={0}>
          <Text>
            Você já passou da meia-noite e sua agenda de hoje provavelmente já foi calculada.
          </Text>
          <Text>
            Para evitar que questões se repitam na RI de hoje, gere uma atividade extra quando for fazê-la.
          </Text>
        </VStack>
      </Alert>
    );
  }

  return null;
}
