import { HStack, Stack, VStack, useColorMode } from '@chakra-ui/react';
import { FC, useCallback, useContext, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { HarrisonAvatar } from './HarrisonAvatar';
import { InlineText } from '../../../InlineText.component';
import { useDefaultStyles } from '../../../../../hooks/useDefaultStyles';
import { HarrisonInteraction } from './HarrisonInteractions';
import { HarrisonFeedback } from './HarrisonFeedback';
import { Forum2Context } from '../../../../../contexts/Forum2.context';
import { useDevice } from '../../../../../hooks/useDevice';
import { ErrorWarning } from './ErrorWarning';
import { HarrisonTag } from './HarrisonTag';

interface HarrisonCommentProps {
  parentId: string,
  askAgain: () => Promise<void>,
  comment: string,
  requestMonitor: () => Promise<void>,
  onCreateHarrisonComment: (commentid: string) => Promise<void>,
  chatId: string,
  messageId: string,
  loadingHarrison: boolean,
  hasError: boolean,
  hasLimit: boolean,
  typing: boolean,
}

export const HarrisonComment: FC<HarrisonCommentProps> = ({
  parentId, askAgain, comment, requestMonitor,
  onCreateHarrisonComment, chatId, messageId,
  loadingHarrison, hasError, hasLimit, typing,
}) => {
  const [hasVote, setHasVote] = useState(false);
  const { onRateAnswer } = useContext(Forum2Context);
  const { colors } = useDefaultStyles();
  const { colorMode } = useColorMode();
  const device = useDevice();

  const [showOptions, setShowOptions] = useState(false);

  const handleVote = useCallback(async (rating: number) => {
    setHasVote(true);

    try {
      const res = await onRateAnswer({ rating, chatId, parentId, messageId });
      if (res.result === 'COMMENT_CREATED' && res.commentId) {
        onCreateHarrisonComment(res.commentId);
      } else {
        setShowOptions(true);
      }

      return;
    } catch (error) {
      console.error('Error rating Harrison comment!');
    }
  }, [parentId, onRateAnswer, onCreateHarrisonComment, chatId, messageId]);

  const handleAskAgain = useCallback(async () => {
    await askAgain();
    setHasVote(false);
  }, [askAgain]);

  const handleRequestMonitor = useCallback(async () => {
    await requestMonitor();
  }, [requestMonitor]);

  return (
    <VStack align="left" w="100%">
      <HStack>
        <HarrisonAvatar />
        <VStack align="left" spacing="4px">
          <InlineText
            fontWeight="bold"
            color={colors.black.goInvert}
            noOfLines={1}
          >
            Harrison AI
          </InlineText>
          <HarrisonTag />
        </VStack>
      </HStack>
      {hasError && !loadingHarrison && (
        <ErrorWarning
          askAgain={askAgain}
          requestMonitor={requestMonitor}
        />
      )}
      {comment && (
        <ReactMarkdown className="inline">
          {comment}
        </ReactMarkdown>
      )}

      {comment && !loadingHarrison && !hasVote && !hasError && !typing && (
      <HarrisonInteraction action={handleVote} />
      )}

      {showOptions && (
        <Stack
          direction={device === 'mobile' ? 'column' : 'row'}
          spacing="8px"
        >
          {hasLimit && (
          <HarrisonFeedback
            action={handleAskAgain}
            title="Obter outra resposta"
            description="Receba uma nova resposta do Harrison."
            buttonText="Obter resposta"
            bgColor={colorMode === 'light' ? '#B1F1C1' : '#12512E'}
            textColor={colorMode === 'light' ? '#00210E' : '#B1F1C1'}
            buttonColor={colorMode === 'light' ? 'white' : '#00391C'}
            buttonTextColor={colorMode === 'light' ? '#00210E' : '#B1F1C1'}
          />
          )}
          <HarrisonFeedback
            action={handleRequestMonitor}
            title="Solicitar um monitor"
            description="O tempo de espera estimado é de até 24 horas."
            buttonText="Solicitar monitor"
            bgColor={colorMode === 'light' ? '#F3F3FA' : '#151C2A'}
            textColor={colorMode === 'light' ? 'black' : 'white'}
            buttonColor={colorMode === 'light' ? '#415F91' : '#AAC7FF'}
            buttonTextColor={colorMode === 'light' ? 'white' : '#0B305F'}
          />
        </Stack>
      )}
    </VStack>
  );
};
