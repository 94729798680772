import {
  FC, useCallback, useContext, useState,
} from 'react';
import { Alert, Flex, Text } from '@chakra-ui/react';
import { toast } from 'react-toastify';
import { AgendaContext } from '../agenda/Agenda.context';
import { useExtraTheoreticalReview } from '../../api/extra-activities/extra-theoretical-review';
import { ExtraActivityModalProps } from './CreateActivityModal';
import { SecondaryButton } from '../../lib/components/SecondaryButton';
import { PrimaryButton } from '../../lib/components/PrimaryButton';
import { useDefaultStyles } from '../../hooks/useDefaultStyles';

const TheoreticalReviewModal: FC<ExtraActivityModalProps> = ({
  onClose, onAfterCreate,
}) => {
  const { refetchExtras } = useContext(AgendaContext);
  const { colors } = useDefaultStyles();

  const [loading, setLoading] = useState(false);

  const { onCreateTheoreticalReviewExtraActivity } = useExtraTheoreticalReview();

  const handleCreate = useCallback(async () => {
    setLoading(true);
    try {
      await onCreateTheoreticalReviewExtraActivity();
      await refetchExtras();
      onClose();
      if (onAfterCreate) {
        onAfterCreate();
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  }, [onAfterCreate, onClose, onCreateTheoreticalReviewExtraActivity, refetchExtras]);

  return (
    <>
      <Text color={colors.secondary.goALittleDarker} mb={2} fontWeight="bold">Revisão Teórica (RT)</Text>
      <Text mb={2}>
        Ao adicionar uma revisão teórica, selecionaremos uma aula que você já assistiu mas
        que, devido ao seu desempenho em questões, é interessante que você
        veja novamente para fixar melhor o conteúdo.
      </Text>
      <Alert
        mb={2}
        status="info"
        variant="left-accent"
        borderRadius={4}
        bgColor={colors.lighter.goAlmostFullDarker}
        borderColor={colors.secondary.goALittleDarker}
      >
        <Text fontSize="sm">
          A revisão teórica é calculada com base nos estudos teóricos finalizados há mais de 2 semanas. Por isso
          se você estiver no início do curso ou não tiver finalizado nenhum estudo teórico, nenhuma aula elegível
          para revisão será encontrada.
        </Text>
      </Alert>
      <Text color={colors.red.keep} mb={4}>
        Apenas uma RT extra é contabilizada por dia.
      </Text>
      <Flex justify="end">
        <SecondaryButton onClick={onClose} mr={2} variant="ghost">
          Cancelar
        </SecondaryButton>
        <PrimaryButton
          isLoading={loading}
          onClick={handleCreate}
        >
          Adicionar
        </PrimaryButton>
      </Flex>
    </>
  );
};

export default TheoreticalReviewModal;
