interface ActivityInfoForExamLink {
  _id?: string;
  calendarDate: string;
  _t: string;
}

export function generateExamActivityLinkWithSearchParams<T extends ActivityInfoForExamLink>(
  examActivity: T,
  activityLinkName: string,
): string {
  let link = '';
  if (!examActivity._t.includes('Extra')) {
    link = link.concat(`/agenda/${activityLinkName}`);
  } else {
    link = link.concat(`/atividades-extras/${activityLinkName}`);
  }
  link = link.concat(`?date=${examActivity.calendarDate}`);
  if (examActivity._id) {
    link = link.concat(`&a=${examActivity._id}`);
  }
  return link;
}
