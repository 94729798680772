import { Button, Flex, HStack, Tooltip, useBreakpointValue, VStack } from '@chakra-ui/react';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Video } from '../../api/agenda/videos';
import { useDefaultStyles } from '../../hooks/useDefaultStyles';
import { DefaultSearchBar } from '../_components/DefaultSearchBar.component';
import { EmptyPage } from '../_components/Forum/components/EmptyPage';
import { PlaylistVideoCard } from './PlaylistVideoCard';

type AristoPlusPlaylistNavigatorType = {
  videos: Video[]
}

export const AristoPlusPlaylistNavigator: FC<AristoPlusPlaylistNavigatorType> = ({
  videos,
}) => {
  const [isVideoListAscendant, setIsVideoListAscendant] = useState(false);
  const CURSOR_STEP = 5;
  const { colors } = useDefaultStyles();
  const isUpToMd = useBreakpointValue({ base: true, md: true, lg: false });
  const [cursor, setCursor] = useState(CURSOR_STEP);
  const [searchKey, setSearchKey] = useState('');

  const sortedVideos = useMemo(() => {
    if (isVideoListAscendant) {
      return [...videos].reverse();
    }
    return videos;
  }, [isVideoListAscendant, videos]);

  const filteredVideos = useMemo(() => {
    if (searchKey) {
      return sortedVideos.filter(x => `${x.title} - ${x.subtitle}`.toLowerCase().includes(searchKey.toLowerCase()));
    }
    if (isUpToMd) {
      return sortedVideos.slice(0, cursor);
    }
    return sortedVideos;
  }, [cursor, isUpToMd, searchKey, sortedVideos]);

  const { search } = useLocation();
  const query = useMemo(() => new URLSearchParams(search), [search]);

  const selectedVideoIndex = useMemo(() => {
    return (filteredVideos.findIndex(v => v._id === query.get('id')) ?? -1) + 1;
  }, [filteredVideos, query]);

  return (
    <VStack
      align="start"
      bgColor="transparent"
      px={2}
      py={4}
      w="100%"
      spacing={4}
      overflow="auto"
    >
      <HStack w="100%" pl={6}>
        <DefaultSearchBar
          searchKey={searchKey}
          setSearchKey={setSearchKey}
          placeholder="Pesquisar vídeo..."
        />
        <Tooltip label={`Ordenar do mais ${isVideoListAscendant ? 'recente' : 'antigo'} para o mais
                ${isVideoListAscendant ? 'antigo' : 'recente'}`}
        >
          <Button
            onClick={() => setIsVideoListAscendant(prev => !prev)}
            color={isVideoListAscendant ? colors.green.keep : colors.red.keep}
          >
            <FontAwesomeIcon icon={isVideoListAscendant ? faCaretUp : faCaretDown} />
          </Button>
        </Tooltip>
      </HStack>
      {
        !filteredVideos || filteredVideos.length === 0
          ? (
            <Flex w="100%">
              <EmptyPage label="Sem vídeos aqui..." />
            </Flex>
          )
          : (
            <>
              {filteredVideos.map((video, currVideoIndex) => (
                <PlaylistVideoCard
                  key={Math.random().toString(32)}
                  currVideoIndex={currVideoIndex}
                  selectedVideoIndex={selectedVideoIndex}
                  videoId={video._id}
                  videoTitle={video.title}
                  createdAt={video.createdAt}
                />
              ))}
              {
                isUpToMd && !searchKey && cursor < videos.length && (
                  <Flex w="100%" justify="center">
                    <Button
                      w="100%"
                      onClick={() => setCursor(prev => prev + CURSOR_STEP)}
                      color={colors.secondary.keep}
                    >
                      Mostrar mais
                    </Button>
                  </Flex>
                )
              }
            </>
          )
      }
    </VStack>
  );
};
