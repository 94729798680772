import { Box, HStack } from '@chakra-ui/react';
import { Trophy } from './Trophy.component';

interface TrophyPodiumProps {
  gold: number;
  silver: number;
  bronze: number;
  variant?: 'student-report' | 'student-goals';
}

export function TrophyPodium({
  bronze, silver, gold, variant = 'student-report',
}: TrophyPodiumProps) {
  return (
    <Box w="100%" maxW={420} mx="auto">
      <HStack justify="space-between" spacing={4} mx={2}>
        <Box alignSelf="flex-end" w="160px">
          <Trophy type="bronze" count={bronze} variant={variant} />
        </Box>
        <Box alignSelf="flex-end" w="160px">
          <Trophy type="silver" count={silver} variant={variant} />
        </Box>
        <Box alignSelf="flex-end" w="160px">
          <Trophy type="gold" count={gold} variant={variant} />
        </Box>
      </HStack>
    </Box>
  );
}
