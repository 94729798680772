import React, { FC, useEffect, useState } from 'react';
import { Switch, Route, useHistory } from 'react-router-dom';
import { Center } from '@chakra-ui/react';
import { DiagnosisPage } from '.';
import { DiagnosisFormPage } from './pages/DiagnosisFormPage';
import { DiagnosisResultsPage } from './pages/DiagnosisResultsPage';
import { DiagnosisTemplate } from './_components/DiagnosisTemplate';
import { AristoCompassTemplate } from '../aristo-compass/template';
import { DiagnosticMockPage } from './pages/DiagnosticMockPage';
import { useLocalStorage } from '../../hooks/useLocalStorage';

export const DiagnosisPageRoutes: FC = () => {
  const history = useHistory();
  const [starsActive, setStarsActive] = useState(true);
  const [isCompass] = useLocalStorage('@Aristoclass:isCompass');

  useEffect(() => {
    const { pathname } = history.location;
    if (pathname.includes('/prova') || isCompass) {
      setStarsActive(false);
    } else {
      setStarsActive(true);
    }
  }, [history.location, history.location.pathname, isCompass]);

  return (
    <AristoCompassTemplate active={isCompass}>
      <DiagnosisTemplate
        active={!isCompass}
        areStarsActive={starsActive}
      >
        <Center w="100%">
          <Switch>
            <Route exact path="/diagnostico" component={DiagnosisPage} />
            <Route path="/diagnostico/prova" component={DiagnosticMockPage} />
            <Route path="/diagnostico/finalizar" component={DiagnosisFormPage} />
            <Route path="/diagnostico/resultado" component={DiagnosisResultsPage} />
          </Switch>
        </Center>
      </DiagnosisTemplate>
    </AristoCompassTemplate>
  );
};
