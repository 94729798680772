import React, { FC, useMemo } from 'react';
import FavoritesEmpty from '../types/FavoritesEmpty';
import { Question } from '../../../api/agenda/questions';
import { Questions } from '../types/Questions.component';
import { useQuestionManyFavorite } from '../../../api/favorites';
import { SkeletonLoading } from '../../_components/SkeletonLoading';

const FavoriteQuestions: FC = () => {
  const { data, loading, refetch, variables } = useQuestionManyFavorite({ skip: 0, limit: 100 });

  const questions = useMemo(() => {
    return data?.questionManyFavorite || [] as Question[];
  }, [data?.questionManyFavorite]);

  if (loading) {
    return (
      <SkeletonLoading />
    );
  }

  if (questions.length > 0 || variables?.skip !== 0) {
    return (
      <Questions
        model="favorites"
        data={questions.map(x => ({ ...x, answer: -1 }))}
        disableCommunication
        refetchFavorites={refetch}
      />
    );
  }

  return (
    <FavoritesEmpty />
  );
};

export default FavoriteQuestions;
