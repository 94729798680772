import { Checkbox, HStack, VStack, Text, Switch, Textarea, Box } from '@chakra-ui/react';
import { FC, useCallback, useMemo } from 'react';
import { useDefaultStyles } from '../../../../hooks/useDefaultStyles';
import { PrimaryButton } from '../../../../lib/components/PrimaryButton';
import { NpsUserGroup } from './PrimumNpsModalShow';

interface ModalStep2Props {
  userGroup: NpsUserGroup,
  reasons: string[],
  setReasons: (v: string[]) => void,
  comment: string,
  setComment: (v: string) => void,
  participate: boolean,
  onSetParticipate: () => void,
  onCompleteSurvey: () => void,
}

export const ModalStep2: FC<ModalStep2Props> = ({
  userGroup,
  reasons,
  setReasons,
  comment,
  setComment,
  participate,
  onSetParticipate,
  onCompleteSurvey,
}) => {
  const { colors } = useDefaultStyles();

  const placeHolder = useMemo(() => {
    switch (userGroup) {
      case 'DETRACTOR':
        return 'Conte-nos o que não atendeu às suas expectativas e como podemos melhorar.';
      case 'PROSECUTOR':
        return 'O que podemos fazer para tornar sua experiência ainda melhor?';
      default:
        return 'O que você mais gosta na Aristo? Alguma sugestão para continuarmos melhorando?';
    }
  }, [userGroup]);

  const reasonsList = useMemo(() => {
    switch (userGroup) {
      case 'DETRACTOR':
        return [
          'Achei difícil de entender a plataforma',
          'Não gostei dos comentários',
          'A revisão inteligente tá repetindo muito',
          'A revisão teórica tá repetindo muito',
          'Respostas do fórum não foram boas',
          'A plataforma está com problemas',
          'O tema que eu quero estudar ainda não está liberado',
          'Problemas com o aplicativo no celular/tablet',
          'Não tive orientação suficiente do meu especialista',
          'Outro',
        ];
      case 'PROSECUTOR':
        return [
          'As revisões inteligentes são a melhor coisa do mundo',
          'Ótimos comentários, mapas mentais e apostilas',
          'Plataforma simples de entender',
          'Aulas muito boas',
          'Organizou os meus estudos',
          'Flashcards sensacionais',
          'Didática dos professores muito boa e atualizada',
          'Auxílio da equipe de suporte',
          'Lives e materias atualizados',
          'Outro',
        ];
      default:
        return [
          'Achei difícil de entender a plataforma',
          'Não gostei dos comentários',
          'A revisão inteligente tá repetindo muito',
          'A revisão teórica tá repetindo muito',
          'Respostas do fórum não foram boas',
          'A plataforma está com problemas',
          'O tema que eu quero estudar ainda não está liberado',
          'Problemas com o aplicativo no celular/tablet',
          'Não tive orientação suficiente do meu especialista',
          'Outro',
        ];
    }
  }, [userGroup]);

  const handleCheckReason = useCallback((value: string) => {
    const index = reasons.findIndex(it => it === value);
    const copy = [...reasons];
    if (index === -1) {
      copy.push(value);
      setReasons(copy);
      return;
    }

    copy.splice(index, 1);

    setReasons(copy);
  }, [reasons, setReasons]);

  const disable = useMemo(() => {
    if (reasons.includes('Outro')) {
      if (!comment) {
        return true;
      }
      return false;
    }

    if (!reasons.length) {
      return true;
    }

    return false;
  }, [comment, reasons]);

  const hasError = useMemo(() => {
    return reasons.includes('Outro') && !comment.length;
  }, [reasons, comment]);

  return (
    <VStack align="left">
      <Box p="8px 16px" backgroundColor="#041223">
        <Text fontSize="20px" fontWeight="bold" color="#89B2F9">Por que você deu essa nota?</Text>
      </Box>
      <VStack align="left" spacing="16px" p="16px 24px">
        <VStack align="left" spacing="16px">
          {reasonsList.map((it, index) => {
            return (
              <HStack key={it} spacing="8px">
                <Checkbox
                  checked={reasons.includes(it)}
                  onChange={_ => handleCheckReason(it)}
                />
                <Text fontSize="14px">{`${it}${index === (reasonsList.length - 1) ? '*' : ''}`}</Text>
              </HStack>
            );
          })}
        </VStack>
        <Textarea
          variant="filled"
          borderColor={hasError ? colors.red.keep : colors.secondary.goALittleDarker}
          size="lg"
          fontSize="md"
          minH={200}
          disabled={!reasons.includes('Outro')}
          _disabled={{
            color: colors.darkGray.keep,
            cursor: 'not-allowed',
          }}
          placeholder={placeHolder}
          value={comment}
          onChange={e => setComment(e.target.value)}
        />
        <PrimaryButton
          onClick={onCompleteSurvey}
          disabled={disable}
          fontSize="14px"
          backgroundColor={colors.customColors.lightBlue}
        >
          Enviar
        </PrimaryButton>
        <HStack>
          <Switch
            checked={participate}
            onChange={_ => onSetParticipate()}
            size="lg"
          />
          <Text fontSize="14px">Gostaria de participar de testes e nos ajudar a melhorar a Aristo?</Text>
        </HStack>
      </VStack>
    </VStack>
  );
};
