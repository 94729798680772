/* eslint-disable no-param-reassign */
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { auth } from '../client/firebase';

type CustomHeadersParams = Record<string, unknown>;

async function attachToken(
  config: AxiosRequestConfig<unknown>,
  customHeaders: CustomHeadersParams = {},
): Promise<AxiosRequestConfig<unknown>> {
  console.log('attach token chamado');
  const { currentUser } = auth;

  const token = await currentUser?.getIdToken();
  config.headers = {
    ...(config.headers ?? {}),
    ...customHeaders,
    authorization: `Bearer ${token ?? ''}`,
  };

  return config;
}

export function getAxiosInstance(
  baseURL: string,
  customHeaders?: CustomHeadersParams,
): AxiosInstance {
  const axiosInstance = axios.create({
    baseURL,
  });

  axiosInstance.interceptors.request.use(
    async config => {
      const newConfig = await attachToken(config, customHeaders);
      return newConfig;
    },
    error => Promise.reject(error),
  );

  return axiosInstance;
}
