import { useCallback } from 'react';
import {
  gql, QueryResult, useMutation, useQuery,
} from '@apollo/client';
import { toast } from 'react-toastify';
import { Activity } from './extra-activities';
import errorHandler from '../error/errorHandler';
import { Flashcard } from '../agenda/flashcards';

interface ExtraFlashcard {
  _id: string;
  profile: string;
  finished: boolean;
  createdAt: Date;
  updatedAt: Date;
  classification: string[];
  flashcards: Flashcard[];
}

interface GetExtraFlashcardsInput {
  id: string;
}

interface GetExtraFlashcardsOutput {
  getFlashcardActivityDetails: ExtraFlashcard;
}

const GET_EXTRA_FLASHCARDS = gql`
  query GetFlashcardActivityDetails ($id: ObjectId!) {
    getFlashcardActivityDetails (_id: $id) {
      _id
      profile
      finished
      createdAt
      updatedAt
      classification
      flashcards {
        prefix
        _id
        front
        back
        createdBy {
          _id
          name
        }
        classification
        createdAt
        updatedAt
        comment {
          author
          profile
          title
          body
          createdAt
          updatedAt
        }
        answer
      }
    }
  }
`;

export function useGetExtraFlashcards({
  id,
}: GetExtraFlashcardsInput): QueryResult<GetExtraFlashcardsOutput, GetExtraFlashcardsInput> {
  return useQuery<GetExtraFlashcardsOutput, GetExtraFlashcardsInput>(GET_EXTRA_FLASHCARDS, {
    variables: {
      id,
    },
    fetchPolicy: 'network-only',
    skip: id === '',
  });
}

interface ActivityFlashcardInput {
  classification?: string[];
  count?: number;
  treeId: string;
}

interface ActivityFlashcard extends Activity {
  flashcards: Flashcard[];
}

interface CreateFlashcardActivityInput {
  input: ActivityFlashcardInput;
}

interface CreateFlashcardActivityOutput {
  createFlashcardActivity: ActivityFlashcard;
}

const CREATE_FLASHCARD_ACTIVITY = gql`
  mutation CreateFlashcardActivity ($input: ActivityFlashcardInputType!) {
    createFlashcardActivity (input: $input) {
      _id
    }
  }
`;

interface UseExtraFlashcardsInterface {
  onCreateFlashcardActivity: (props: ActivityFlashcardInput) => Promise<void>;
}

export function useExtraFlashcards(): UseExtraFlashcardsInterface {
  const [createActivity] = useMutation<
    CreateFlashcardActivityOutput, CreateFlashcardActivityInput
  >(CREATE_FLASHCARD_ACTIVITY, {
    onError: errorHandler,
    onCompleted: data => {
      if (data !== null) {
        toast.success('Atividade de flashcard criada com sucesso!');
      }
    },
  });

  const handleCreate = useCallback(async ({
    treeId,
    classification,
    count,
  }: ActivityFlashcardInput) => {
    await createActivity({
      variables: {
        input: {
          treeId,
          classification,
          count,
        },
      },
    });
  }, [createActivity]);

  return {
    onCreateFlashcardActivity: handleCreate,
  };
}

const CREATE_USER_FLASHCARD_ACTIVITY = gql`
  mutation CreateUserFlashcardActivity ($input: UserFlashcardActivityInputType!) {
    createUserFlashcardActivity (input: $input) {
      _id
    }
  }
`;

type CreateUserFlashcardActivityInputParameters = {
  deckIds: string[],
  length?: number,
}
interface CreateUserFlashcardActivityInputType {
  input: CreateUserFlashcardActivityInputParameters;
}

type CreateUserFlashcardActivityOutputType = {
  createUserFlashcardActivity: ActivityFlashcard;
}

export function useExtraUserFlashcardActivity() {
  const [createUserFlashcardActivity] = useMutation<
    CreateUserFlashcardActivityOutputType, CreateUserFlashcardActivityInputType
  >(CREATE_USER_FLASHCARD_ACTIVITY, {
    onError: errorHandler,
    onCompleted: data => {
      if (data !== null) {
        toast.success('Atividade de flashcard criada com sucesso!');
      }
    },
  });

  const onCreateUserFlashcardActivity = useCallback(async ({
    deckIds,
    length,
  }: CreateUserFlashcardActivityInputParameters) => {
    await createUserFlashcardActivity({
      variables: {
        input: {
          deckIds,
          length,
        },
      },
    });
  }, [createUserFlashcardActivity]);

  return {
    onCreateUserFlashcardActivity,
  };
}
