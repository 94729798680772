import { faCalendarAlt } from '@fortawesome/free-regular-svg-icons';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { Text } from '@chakra-ui/react';
import {
  FC, useMemo,
} from 'react';
import { useGetSmartExamsHistory } from '../../../api/exams-history/smart-exams-history';
import { generateExamActivityLinkWithSearchParams } from '../../../hooks/generateExamLinkWithSearchParams';
import Flex from '../../../lib/components/Flex';
import useTableFilter from '../../../hooks/useTableFilter';
import TableFilterComponent from '../../theme-list/_components/TableFilterComponent';
import HistoryCardComponent from '../_components/HistoryCardComponent';
import { TextWithTooltip } from '../_components/TextWithTooltip.component';

const SmartExamsHistory: FC = () => {
  const getSmartExamsHistoryQuery = useGetSmartExamsHistory();

  /** Memos */
  const smartExamsHistory = useMemo(() => {
    const { data } = getSmartExamsHistoryQuery;
    if (!data) {
      return [];
    }
    return data.getActivitySmartExamsHistory;
  }, [getSmartExamsHistoryQuery]);

  const {
    dataFiltered: smartExamsHistoryFiltered, setFilter,
  } = useTableFilter(smartExamsHistory);

  if (getSmartExamsHistoryQuery.loading) {
    return (
      <Flex justify="center">
        <FontAwesomeIcon icon={faSpinner} spin size="4x" className="color-dark-gray mt-4" />
      </Flex>
    );
  }
  return smartExamsHistoryFiltered.length ? (
    <>
      <TableFilterComponent
        setFilter={setFilter}
        options={[
          {
            value: 'institution',
            content: 'Nome da instituição',
          },
          {
            value: 'calendarDate',
            content: 'Data de realização',
          },
          {
            value: 'grade',
            content: 'Desempenho',
          },
        ]}
      />
      {smartExamsHistoryFiltered.map(smartExam => (
        <HistoryCardComponent
          key={smartExam._id}
          iconProps={{
            icon: <FontAwesomeIcon icon={faCalendarAlt} />,
            label: 'Ir para a atividade',
            destiny: generateExamActivityLinkWithSearchParams(smartExam, 'simulado-inteligente'),
          }}
          title={`${smartExam.institution} [${smartExam.uf}]`}
          rowData={[
            {
              key: 'calendarDate',
              value: moment(smartExam.calendarDate).format('DD[/]MM[/]YYYY'),
              label: 'Realizado em:',
            },
            {
              key: 'grade',
              label: 'Desempenho:',
              value: (
                <TextWithTooltip extraTag="ActivitySmartExamExtra" activity={smartExam} />
              ),
            },
          ]}
        />
      ))}
    </>
  ) : (
    <Text tag="h4" center className="mt-6">Oops, dados não encontrados!</Text>
  );
};

export default SmartExamsHistory;
