import { gql, QueryResult, useQuery } from '@apollo/client';
import { Video } from './agenda/videos';

export type PlaylistType = {
  name: string;
  amount: number;
  createdAt: string;
  category?: string;
  thumbnail?: string;
}
interface VideosJJPlusLabelsOutput {
  categories: {
    category: string;
    playlists: PlaylistType[];
  }[];
}

export type PDFPlusType = {
  _id: string;
  url: string;
  title: string;
  subtitle?: string;
  category: string;
  description?: string;
  aristoPlus?: {
    permissions: string[];
  };
  createdAt?: string;
}

const VIDEOS_JJ_PLUS_CATEGORIES = gql`
  query VideosJJPlusCategories {
    categories: videosJJPlusCategories {
      category
      playlists {
        name
        amount
        createdAt
      }
    }
  }
`;

export function useVideosJJPlusCategories(): QueryResult<VideosJJPlusLabelsOutput> {
  return useQuery<VideosJJPlusLabelsOutput>(VIDEOS_JJ_PLUS_CATEGORIES);
}

interface VideosJJPlusManyInput {
  category?: string;
  playlist?: string;
  limit?: number;
  skip?: number;
}

interface VideosJJPlusManyOutput {
  playlists: {
    playlistName: string;
    videos: Video[];
  }[];
}

const VIDEOS_JJ_PLUS_MANY = gql`
  query VideosJJPlusMany (
    $limit: Float,
    $skip: Float,
    $playlist: String
    $category: String
    ) {
    playlists: videosJJPlusMany (
      limit: $limit,
      skip: $skip,
      category: $category
      playlist: $playlist
    ) {
     playlistName
     videos {
      _id
      url
      title
      subtitle
      jjPlus {
        category
        playlist
      }
      createdAt
     }
    }
  }
`;

export function useVideosJJPlusMany({
  category, playlist, limit, skip,
}: VideosJJPlusManyInput): QueryResult<VideosJJPlusManyOutput, VideosJJPlusManyInput> {
  return useQuery<VideosJJPlusManyOutput, VideosJJPlusManyInput>(VIDEOS_JJ_PLUS_MANY, {
    variables: {
      category,
      playlist,
      limit,
      skip,
    },
  });
}

interface PDFPlusManyInput {
  limit?: number;
  skip?: number;
}

interface PDFPlusManyOutput {
  pdfs: PDFPlusType[];
}

export const PDF_PLUS_MANY = gql`
  query PDFPlusMany (
    $limit: Float,
    $skip: Float,
    ) {
      pdfs: pdfsPlusMany(
      limit: $limit,
      skip: $skip,
    ) {
      _id
      url
      title
      subtitle
      description
      category
      createdAt
    }
  }
`;

export function usePdfPlusMany({
  limit, skip,
}: PDFPlusManyInput) {
  return useQuery<PDFPlusManyOutput, PDFPlusManyInput>(PDF_PLUS_MANY, {
    variables: {
      limit,
      skip,
    },
  });
}
