import { Box, Modal, ModalBody, ModalContent, ModalOverlay } from '@chakra-ui/react';
import { FC } from 'react';
import { useDefaultStyles } from '../../hooks/useDefaultStyles';

type ModalOptionType = {
  label: string,
  onClick: () => void,
  color?: string,
}

type MoreOptionsModalType = {
  options: ModalOptionType[],
  isOpen: boolean,
  onClose: () => void,
  disableAll: boolean,
}

export const MoreOptionsModal: FC<MoreOptionsModalType> = ({
  options, isOpen, onClose, disableAll,
}: MoreOptionsModalType) => {
  const len = options.length;
  const { colors } = useDefaultStyles();

  if (!len) {
    return <></>;
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent alignSelf={{ base: 'flex-end', md: 'center' }} mx={4}>
        <ModalBody display="flex" flexDirection="column" px={0}>
          {
            options.map(opt => {
              return (
                <Box
                  as="button"
                  key={opt.label}
                  color={opt.color}
                  disabled={disableAll}
                  py={2}
                  _hover={{
                    bgColor: colors.alpha[200],
                  }}
                  onClick={opt.onClick}
                  borderBottom="1px lightgray solid"
                >
                  {opt.label}
                </Box>
              );
            })
          }
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
