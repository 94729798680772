/* eslint-disable no-await-in-loop */
import { Center, Flex, VStack, Box } from '@chakra-ui/react';
import moment from 'moment';
import { FC, useCallback, useContext, useState } from 'react';
import { toast } from 'react-toastify';
import { Loading } from '../../../../lib/components/Loading';
import { Forum2Comment } from './Forum2Comment.component';
import { useForum2API } from '../../../../hooks/useForum2API';
import { CommentDtoProps, ForumCommentWithAnswersType } from '../types/forum2.types';
import { Forum2Context } from '../../../../contexts/Forum2.context';

export const Forum2CommentWithAnswers: FC<ForumCommentWithAnswersType> = ({
  commentData, redirectUrl, commentOfQuestion, parent, onDelete, level, contentId, contentType, courseIdFromParam,
}: ForumCommentWithAnswersType) => {
  const [showAnswers, setShowAnswers] = useState(false);
  const [answerCount, setAnswerCount] = useState(commentData.children.length);
  const [comments, setComments] = useState<CommentDtoProps[]>([]);
  const { getReplies, deleteComment, askBasedOnQuestion } = useForum2API();
  const [loadingComments, setLoadingComments] = useState(false);

  const { currentQuestion, onRequestMonitor,
    updateCommentLimit, onCreateHarrisonComment, answer,
  } = useContext(Forum2Context);

  const [harrisonComment, setHarrisonComment] = useState('');
  const [loadingHarrison, setLoadingHarrison] = useState(false);
  const [chatId, setChatId] = useState('');
  const [messageId, setMessageId] = useState('');
  const [hasError, setHasError] = useState(false);
  const [typing, setTyping] = useState(false);

  const typeEffect = async (text: string) => {
    setTyping(true);
    const words = text.split(' ');

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < words.length; i++) {
      await new Promise(resolve => setTimeout(resolve, 50));
      setHarrisonComment(prev => `${prev} ${words[i]}`);
    }
  };

  const fetchHarrisonStreamData = useCallback(async () => {
    setHarrisonComment('');
    setLoadingHarrison(true);
    updateCommentLimit(commentData.id);
    setHasError(false);
    setTyping(false);

    try {
      const res = await askBasedOnQuestion({
        commentId: commentData.id,
        questionBody: currentQuestion?.body ?? '',
        questionAnswers: currentQuestion?.choices,
        correctAnswer: currentQuestion?.correctChoice,
        userChoice: currentQuestion?.answer ?? answer,
      });

      setChatId(res.headers['forum-chat-id']);
      setMessageId(res.headers['forum-message-id']);

      if (typeof res.data === 'string') {
        setLoadingHarrison(false);
        await typeEffect(res.data);
      } else {
        const reader = res.data.getReader();
        const decoder = new TextDecoder();

        let done = false;
        while (!done) {
          const { value, done: streamDone } = await reader.read();
          done = streamDone;

          const chunk = decoder.decode(value, { stream: true });
          setLoadingHarrison(false);
          await typeEffect(chunk);
        }
      }
    } catch (error) {
      console.error('Error when receiving stream data:', error);
      setHasError(true);
      setLoadingHarrison(false);
    } finally {
      setTyping(false);
    }
  }, [askBasedOnQuestion, commentData.id,
    currentQuestion?.body, currentQuestion?.answer, currentQuestion?.choices,
    currentQuestion?.correctChoice, updateCommentLimit, answer]);

  const handleRequestMonitor = useCallback(async () => {
    await onRequestMonitor(commentData.id);
    setHarrisonComment('');
    setLoadingHarrison(false);
    toast.success('Um monitor foi solicitado. O tempo de espera estimado é de até 24 horas.');
  }, [commentData.id, onRequestMonitor, setLoadingHarrison]);

  const refetchAnswers = useCallback(async () => {
    const res = await getReplies({ ids: commentData.children });

    setComments(res);

    setLoadingComments(false);
  }, [commentData.children, getReplies]);

  const handleDeleteComment = useCallback(async (id: string) => {
    await deleteComment({
      commentId: id,
    });

    setComments(prev => prev.filter(e => e.id !== id));
  }, [deleteComment]);

  const handleCreateComment = useCallback((v: CommentDtoProps) => {
    setComments(prev => [v, ...prev]);
    setShowAnswers(true);
    setAnswerCount(prev => prev + 1);
  }, []);

  const handleCreateHarrisonComment = useCallback(async (commentId: string) => {
    const newComment = onCreateHarrisonComment({
      body: harrisonComment ?? '',
      contentId: commentData.contentId,
      commentId: '',
      level: level + 1,
      parentId: commentData.id,
      courseIdFromParam: courseIdFromParam ?? '',
      contentType,
    });
    setHarrisonComment('');
    handleCreateComment(newComment);
  }, [onCreateHarrisonComment, commentData.contentId, commentData.id,
    level, courseIdFromParam, contentType, harrisonComment, handleCreateComment]);

  return (
    <Flex flexFlow="column" w="100%">
      <Forum2Comment
        setShowAnswers={setShowAnswers}
        showAnswers={showAnswers}
        setLoadingMoreSecondLevelComments={setLoadingComments}
        refetchAnswers={refetchAnswers}
        parent={parent?.name}
        comment={commentData}
        redirectUrl={redirectUrl}
        commentOfQuestion={commentOfQuestion}
        onDelete={async () => onDelete(commentData.id)}
        level={level}
        contentId={contentId}
        contentType={contentType}
        onCreate={handleCreateComment}
        answersCount={answerCount}
        courseIdFromParam={courseIdFromParam}
        harrisonComment={harrisonComment}
        askHarrison={fetchHarrisonStreamData}
        loadingHarrison={loadingHarrison}
        requestMonitor={handleRequestMonitor}
        onCreateHarrisonComment={handleCreateHarrisonComment}
        chatId={chatId}
        messageId={messageId}
        hasError={hasError}
        typing={typing}
      />
      <VStack
        display={!showAnswers ? 'none' : undefined}
        pl={{ base: 5, md: 10 }}
        align="flex-end"
      >
        <Flex flexFlow="row">
          <Box
            backgroundColor="lightGray"
            width="1px"
          />
          <Flex flexFlow="column" flex="1">
            {comments && comments
              .sort((a, b) => {
                return moment(a.createdAt) > moment(b.createdAt) ? 1 : -1;
              })
              .map(commentAnswer => {
                return (
                  <Forum2CommentWithAnswers
                    contentId={contentId}
                    key={commentAnswer.id}
                    commentData={commentAnswer}
                    redirectUrl={redirectUrl}
                    parent={commentData.user}
                    commentOfQuestion={commentOfQuestion}
                    onDelete={handleDeleteComment}
                    level={level + 1}
                    contentType={contentType}
                    courseIdFromParam={courseIdFromParam}
                  />
                );
              })}
          </Flex>
        </Flex>
        {loadingComments && (
          <Center w="100%" fontSize="sm">
            <Loading />
          </Center>
        )}
      </VStack>
    </Flex>
  );
};
