import moment from 'moment';

export interface DayItem {
  value: string;
  label: string;
  time: 'past' | 'future';
}

export function buildWeekArray(pastNum: number): [DayItem[], number] {
  const gap = moment().format('dddd') === 'domingo' ? 6 : -1;
  const d = Number(moment().format('d')) + gap;
  const past = pastNum + d;
  const days: DayItem[] = [];

  for (let i = -past; i < -d; i += 1) {
    const day = moment().startOf('day').add(i, 'days');
    days.push({
      label: day.format('DD[/]MM'),
      value: day.format('YYYYMMDD'),
      time: 'past',
    });
  }

  for (let i = -d; i < 7 - d; i += 1) {
    const day = moment().startOf('day').add(i, 'days');
    if (day <= moment()) {
      days.push({
        label: day.format('dddd'),
        value: day.format('YYYYMMDD'),
        time: 'past',
      });
    } else {
      days.push({
        label: day.format('dddd'),
        value: day.format('YYYYMMDD'),
        time: 'future',
      });
    }
  }

  return [days, past];
}
