import { Box, Flex, HStack, Text } from '@chakra-ui/react';
import { faPlay } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { FC, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDefaultStyles } from '../../hooks/useDefaultStyles';

type PlaylistVideoCardType = {
  selectedVideoIndex: number,
  currVideoIndex: number,
  videoId: string,
  videoTitle: string,
  createdAt?: string,
}

export const PlaylistVideoCard: FC<PlaylistVideoCardType> = ({
  selectedVideoIndex,
  currVideoIndex,
  videoId,
  videoTitle,
  createdAt,
}) => {
  const { colors } = useDefaultStyles();
  const history = useHistory();
  const { search } = useLocation();
  const query = useMemo(() => new URLSearchParams(search), [search]);
  return (
    <HStack
      as="button"
      key={Math.random().toString(36)}
      align="flex-start"
      w="100%"
      bgColor={selectedVideoIndex === (currVideoIndex + 1) ? colors.lighter.goAlmostFullDarker : ''}
      p={2}
      transition="all 0.4s"
      _hover={{
        bgColor: colors.alpha[200],
      }}
      onClick={() => {
        query.set('id', videoId);
        history.replace({ search: String(query) });
      }}
    >
      <Text
        alignSelf="center"
        minW={4}
        w={50}
        color={selectedVideoIndex === (currVideoIndex + 1) ? colors.secondary.goFullLighter : ''}
      >
        {selectedVideoIndex === (currVideoIndex + 1) ? <FontAwesomeIcon icon={faPlay} /> : (currVideoIndex + 1)}
      </Text>

      <Box
        h={{ base: '90px', md: '118px' }}
        w={{ base: '150px', md: '200px' }}
        backgroundImage="url(/images/thumb_JJ.png)"
        backgroundRepeat="no-repeat"
        backgroundPosition="center"
        backgroundSize="contain"
      />
      <Flex flexFlow="column" py={2} w={200} align="flex-start">
        <Text
          textAlign="left"
          fontWeight={selectedVideoIndex === (currVideoIndex + 1) ? 'bold' : ''}
          color={selectedVideoIndex === (currVideoIndex + 1) ? colors.secondary.goFullLighter : ''}
        >
          {videoTitle}
        </Text>
        {createdAt && <Text fontSize="sm" color={colors.darkGray.keep}>{moment(createdAt).fromNow()}</Text>}
      </Flex>
    </HStack>
  );
};
