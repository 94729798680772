import { Box } from '@chakra-ui/react';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { auth } from '../../client/firebase';
import { Loading } from '../../lib/components/Loading';

const AppealsPage: FC = () => {
  const [token, setToken] = useState<string>('');

  const getToken = useCallback(async () => {
    const { currentUser } = auth;

    const res = await currentUser?.getIdToken();
    if (res) {
      setToken(res);
    }
  }, []);

  const url = useMemo(() => {
    return process.env.REACT_APP_FLUTTER_URL;
  }, []);

  useEffect(() => {
    getToken();
  }, [getToken]);

  if (token.length === 0) {
    return <Loading />;
  }

  return (
    <Box height="calc(100vh - 80px)">
      <iframe
        width="100%"
        height="100%"
        style={{
          height: 'calc(100vh) - 80px)',
        }}
        title="Appeals"
        allow="clipboard-write"
        src={`${url}/#/external-appeal?userToken=${token}`}
      />
    </Box>
  );
};

export default AppealsPage;
