import { Box, Center, Flex, HStack } from '@chakra-ui/react';
import { faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import {
  FC,
  useCallback,
  useContext,
} from 'react';
import { useDefaultStyles } from '../../../hooks/useDefaultStyles';
import { useLocalStorage } from '../../../hooks/useLocalStorage';
import { PrimaryButton } from '../../../lib/components/PrimaryButton';
import { SecondaryButton } from '../../../lib/components/SecondaryButton';
import { DiagnosisAlertContext } from '../contexts/DiagnosisAlert.context';
import { DiagnosisCard } from '../_components/DiagnosisCard';
import { SocialMediaItem } from '../_components/SocialMediaItem';

type DiagnosisHomePageProps = {
  onStart: () => void;
}

export const DiagnosisHomePage: FC<DiagnosisHomePageProps> = ({
  onStart,
}: DiagnosisHomePageProps) => {
  const { handleSetAlertProps, handleSetActive } = useContext(DiagnosisAlertContext);
  const { colors } = useDefaultStyles();
  const [isCompass] = useLocalStorage('@Aristoclass:isCompass');

  const handleSocialMediaAlert = useCallback(() => {
    handleSetAlertProps({
      title: 'Acompanhe-nos nas redes sociais!',
      active: true,
      btn1: { text: 'Fechar', className: 'alertBtn1', action: () => handleSetActive(false) },
      body: (
        <div style={{ width: '15rem' }}>
          <SocialMediaItem
            className="websiteColor"
            icon={faGlobe}
            address="https://www.aristo.com.br/"
          >
            Site da Aristo
          </SocialMediaItem>
          <SocialMediaItem
            className="instagramColor"
            icon={faInstagram}
            address="https://www.instagram.com/aristoresidenciamedica/"
          >
            Instagram
          </SocialMediaItem>
          <SocialMediaItem
            className="youtubeColor"
            icon={faYoutube}
            address="https://www.youtube.com/c/AristoResid%C3%AAnciaM%C3%A9dica"
          >
            Youtube
          </SocialMediaItem>
        </div>
      ),
    });
  }, [handleSetAlertProps, handleSetActive]);
  return (
    <Flex
      flexFlow="column"
      justify="center"
      height="80vh"
      w="100%"
    // bgColor="red"
    >
      <HStack
        // {...(isCompass && cardBorder)}
        // zIndex={1}
        backgroundColor={isCompass ? undefined : 'white'}
        borderRadius="2rem"
        flexFlow="column"
        justify="space-between"
        overflowY={{ base: 'scroll', md: 'auto' }}
        overflowX="hidden"
        // p={2}
        w="100%"
        maxW={1120}
        m="0 auto"
      >
        <Flex
          align="center"
          justify="flex-end"
          marginBottom="1rem"
          flexFlow="column"
          width="100%"
        >
          <Box
            fontWeight="bold"
            fontSize={{ base: '2rem', md: '3rem' }}
            color={colors.intermediary.keep}
            textAlign="center"
          >
            Seja bem-vindo
          </Box>
          <Box
            fontSize={{ base: '1rem', md: '2rem' }}
            color={colors.secondary.keep}
            style={{ fontVariant: 'small-caps' }}
            textAlign="center"
          >
            ao simulado Aristo DX
          </Box>
        </Flex>
        <Flex
          w="100%"
          overflowY="auto"
          justify={{ md: 'center' }}
        >
          <DiagnosisCard title="Qual o seu objetivo?">
            Selecione as provas que deseja prestar e realize o simulado.
            Nosso sistema inteligente calculará seus temas prioritários de estudo.
          </DiagnosisCard>
          <DiagnosisCard title="Ainda não decidiu?" bgColor="secondary.600">
            Sem problemas. A incidência geral dos temas será considerada
            pelo nosso sistema no cálculo das suas prioridades.
          </DiagnosisCard>
          <DiagnosisCard title="Vamos começar?">
            Antes de iniciar o simulado, procure um espaço adequado para que
            você possa se concentrar e responder todas as questões com atenção.
          </DiagnosisCard>
          <DiagnosisCard title="Receba o seu diagnóstico" bgColor="secondary.600">
            Você receberá um relatório com a ordem de assuntos que você deve priorizar
            para que otimize seu desempenho nas instituições escolhidas.
          </DiagnosisCard>
        </Flex>

      </HStack>
      <Center
        minHeight="4rem"
        // marginBottom="2rem"
        gridGap="1rem"
        my="1rem"
      >
        <SecondaryButton
          minW={40}
          onClick={handleSocialMediaAlert}
        >
          Conheça a Aristo
        </SecondaryButton>
        <PrimaryButton
          onClick={onStart}
        >
          Quero começar
        </PrimaryButton>
      </Center>
    </Flex>
  );
};
