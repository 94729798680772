import { Box, Button, Text } from '@chakra-ui/react';
import { useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useFlashcardMany } from '../../api/agenda/flashcards';
import { Loading } from '../../lib/components/Loading';
import Flashcard from '../_components/Flashcard.component';
import { Forum2 } from '../_components/Forum/Forum2';

export function FlashcardViewer() {
  const { search } = useLocation();
  const [showForum, setShowForum] = useState(false);

  const flashcardId = useMemo(() => {
    return new URLSearchParams(search).get('id');
  }, [search]);

  const courseId = useMemo(() => {
    return new URLSearchParams(search).get('courseId') ?? undefined;
  }, [search]);

  const { data, loading } = useFlashcardMany({
    ids: flashcardId ? [flashcardId] : [],
    calendarDate: '20210101',
    type: 'theoreticalStudy',
  });

  const flashcardData = useMemo(() => {
    if (!data || loading) {
      return undefined;
    }
    return data.flashcardMany[0];
  }, [data, loading]);

  return (
    <Box m="1rem auto" maxW={980}>
      {loading && <Loading />}
      {!loading && flashcardData && (
        <Box align="center">
          <Flashcard
            front={flashcardData.front}
            back={flashcardData.back}
            answer={-1}
            index={0}
            disabled
            classification={flashcardData.classification}
            id={flashcardData._id}
          />
          <Button mt={8} onClick={() => setShowForum(prev => !prev)}>
            {`${showForum ? 'Esconder' : 'Ver'} fórum`}
          </Button>
          <Box textAlign="left">
            {
              flashcardData.classification && (
                <Forum2
                  show={showForum}
                  id={flashcardData._id}
                  tag={['Flashcard', flashcardData.classification.join('|') || 'Sem classificação'].join('|')}
                  classification={flashcardData.classification}
                  contentType="Flashcard"
                  courseIdFromParam={courseId}
                />
              )
            }
          </Box>
        </Box>
      )}
      {!loading && !flashcardData && <Text>Flashcard não encontrado!</Text>}
    </Box>
  );
}
