// import clsx from 'clsx';
import { Box, BoxProps, Button, Flex } from '@chakra-ui/react';
import clsx from 'clsx';
import { FC, ReactNode } from 'react';
import { useDevice } from '../../hooks/useDevice';
// import Text from './Text';

export interface HeaderProps extends BoxProps {
  key: string;
  value: (string | ReactNode);
  showOnMobile?: boolean,
}
interface TableHeaderComponentProps {
  headers: HeaderProps[];
  color?: string;
  bgColor?: string;
  paddingLeft?: string;
  fontSize?: string | number;
  handleFilter: (key: string) => void;
  filter: {
    name: string;
    dir: string;
  }
  padding?: string;
}

const TableHeaderComponent: FC<TableHeaderComponentProps> = ({
  headers, filter, handleFilter, padding, paddingLeft, color, bgColor, fontSize,
}) => {
  const device = useDevice();

  return (
    <Box
      height="40px"
      sx={{ padding }}
      paddingLeft={paddingLeft}
      bgColor={bgColor}
    >
      <Flex h="100%" align="center">
        {headers.length !== 0 && headers.map(({
          key, value, showOnMobile = true, ...rest
        }) => {
          return !showOnMobile && device === 'mobile' ? (
            <Box key={String(key)} />
          ) : (
            <Box
              key={String(key)}
              className={`px-1 ${key ? ' h100' : ''}`}
              {...rest}
            >
              {key ? (
                <Button
                  variant="unstyled"
                  _focus={{ border: 'none' }}
                  color={color}
                  onClick={() => handleFilter(key)}
                  className={clsx('sort-btn', {
                    asc: filter.name === key && filter.dir === 'asc',
                    desc: filter.name === key && filter.dir === 'desc',
                  })}
                  fontSize={fontSize}
                >
                  {value}
                </Button>
              ) : (
                <Box
                  w="100%"
                  fontSize={fontSize}
                >
                  {value}
                </Box>
              )}
            </Box>
          );
        })}
      </Flex>
    </Box>
  );
};

export default TableHeaderComponent;
