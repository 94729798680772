import {
  Box,
  Center,
  Heading,
  Square,
  Text,
  useTheme,
  HStack,
  Circle,
  Wrap,
  WrapItem,
  Flex,
  useColorMode,
} from '@chakra-ui/react';
import moment from 'moment';
import DatePicker, { registerLocale } from 'react-datepicker';
import pt from 'date-fns/locale/pt-BR';
import { endOfMonth, startOfMonth } from 'date-fns';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';
import { useAnswerCountByTag } from '../../../../api/performance';
import { useDefaultStyles } from '../../../../hooks/useDefaultStyles';
import { useDevice } from '../../../../hooks/useDevice';
import { PrivateContext } from '../../../../Private.context';
import 'react-datepicker/dist/react-datepicker.css';
import './range-date-picker.css';
import { formatDate } from '../../../../utils/formatDate';
import { ComparisonText } from '../../_components/ComparisonText.component';
import { PrizeStar } from '../../_components/PrizeStar';
import { ReportLoading } from '../../_components/ReportLoading';
import { CustomTooltipType } from '../../_components/AreasPerformances';

registerLocale('pt-BR', pt);

const RADIAN = Math.PI / 180;
function constructClearDate(date: Date) {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate());
}
const TODAY = constructClearDate(new Date());

type QuestionCountByTagProps = {
  anyDayOfCurrMonth?: Date;
  setQuestionsLoading?: React.Dispatch<React.SetStateAction<boolean>>;
}

export function QuestionCountByTag({
  anyDayOfCurrMonth, setQuestionsLoading,
}: QuestionCountByTagProps) {
  const { colorMode } = useColorMode();
  const { colorPalette } = useDefaultStyles();
  const { profile } = useContext(PrivateContext);
  const [dateRange, setDateRange] = useState<(Date | null)[]>([null, null]);
  const [dateStart, dateEnd] = useMemo(() => {
    if (anyDayOfCurrMonth) {
      return [startOfMonth(anyDayOfCurrMonth), endOfMonth(anyDayOfCurrMonth)];
    }
    return dateRange;
  }, [anyDayOfCurrMonth, dateRange]);

  const lastMonthBoundaries = useMemo(() => {
    if (anyDayOfCurrMonth) {
      return {
        dateStart: formatDate(
          startOfMonth(
            new Date(anyDayOfCurrMonth
              .getTime())
              .setMonth(anyDayOfCurrMonth.getMonth() - 1),
          ),
        ),
        dateEnd:
          formatDate(
            endOfMonth(
              new Date(anyDayOfCurrMonth
                .getTime())
                .setMonth(anyDayOfCurrMonth.getMonth() - 1),
            ),
          ),
      };
    }
    return {};
  }, [anyDayOfCurrMonth]);

  const { data: lastMonthData, loading: lastMonthDataLoading } = useAnswerCountByTag(
    anyDayOfCurrMonth && {
      dateStart: lastMonthBoundaries.dateStart,
      dateEnd: lastMonthBoundaries.dateEnd,
    },
  );

  const lastMonthNoOfQuestions = useMemo(() => {
    if (lastMonthData) {
      return lastMonthData
        .countAnswerClassification
        .reduce((acc, curr) => acc + curr.questions, 0);
    } return 0;
  }, [lastMonthData]);

  const { data, loading } = useAnswerCountByTag({
    ...(dateStart && { dateStart: moment(dateStart).format('YYYYMMDD') }),
    ...(dateEnd && { dateEnd: moment(dateEnd).format('YYYYMMDD') }),
  });
  const device = useDevice();
  const { cardBorder, selectBorder, colors } = useDefaultStyles();
  const theme = useTheme();

  // const COLORS = [
  //   theme.colors.primary[400],
  //   theme.colors.secondary[600],
  //   theme.colors.secondary[500],
  //   theme.colors.tertiary[500],
  //   theme.colors.primary[500],
  // ];

  const COLORS = [
    theme.colors[colorPalette][600],
    theme.colors[colorPalette][500],
    theme.colors[colorPalette][400],
    theme.colors[colorPalette][300],
    theme.colors[colorPalette][200],
    theme.colors[colorPalette][100],
  ];

  const chartData = useMemo(() => {
    if (loading || !data) {
      return undefined;
    }
    return data.countAnswerClassification;
  }, [data, loading]);

  const noOfQuestions = useMemo(() => chartData?.reduce((acc, curr) => acc + curr.questions, 0) || 0, [chartData]);

  const currMonthNoOfQuestions = useMemo(() => {
    if (chartData) {
      return chartData.reduce((acc, curr) => acc + curr.questions, 0);
    } return 0;
  }, [chartData]);

  const handleDayClassName = useCallback(date => {
    const clearDate = constructClearDate(date);
    const isToday = clearDate.valueOf() === TODAY.valueOf();
    let isInRange = false;
    if (dateStart && dateEnd) {
      isInRange = clearDate.valueOf() >= constructClearDate(dateStart).valueOf()
        && clearDate.valueOf() <= constructClearDate(dateEnd).valueOf();
    }
    return (isToday || isInRange) ? 'date-picker-day-selected' : 'date-picker-day-not-selected';
  }, [dateEnd, dateStart]);

  const [hasPayload, setHasPayload] = useState(false);

  const CustomQuestionTooltip: FC<CustomTooltipType> = (
    { payload }: CustomTooltipType,
  ) => {
    if (payload && payload.length > 0) {
      setHasPayload(true);
      return (
        <Box
          bgColor={colorMode === 'light' ? 'background.light' : 'background.dark'}
          padding={2}
          align="center"
          {...cardBorder}
        >
          <Text mb={1} color={colors.secondary.keep} fontWeight="bold" fontSize="md">
            {`${payload[0].name}: ${payload[0].value}`}
          </Text>
        </Box>
      );
    }
    setHasPayload(false);
    return null;
  };

  const handlePieLabel = useCallback(props => {
    const { cx, cy, midAngle, outerRadius, fill, value } = props;
    const radius = outerRadius + 10;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline="central"
        fill={fill}
        fontSize="14px"
      >
        {value}
      </text>
    );
  }, []);

  useEffect(() => {
    if (setQuestionsLoading) {
      if (loading && lastMonthDataLoading) {
        setQuestionsLoading(true);
      } else {
        setQuestionsLoading(false);
      }
    }
  }, [lastMonthDataLoading, loading, setQuestionsLoading]);

  if (loading && anyDayOfCurrMonth) {
    return <ReportLoading />;
  }

  return (
    <Box
      mb={{ base: 4, md: 0 }}
      w={device === 'mobile' || !anyDayOfCurrMonth ? '100%' : '50%'}
      p={4}
      {...cardBorder}
    >
      <Flex gridGap={1}>
        <HStack flex={2} alignItems="flex-start">
          <PrizeStar
            hidden={!anyDayOfCurrMonth}
            label="Parabéns! Você fez no mínimo 1500 questões este mês!"
            goal={1500}
            achieved={noOfQuestions}
          />
          <Heading size="md">
            Quantidade de questões
          </Heading>
        </HStack>
        <ComparisonText
          currentValue={currMonthNoOfQuestions}
          previousValue={lastMonthNoOfQuestions}
          loading={lastMonthDataLoading}
          hide={!anyDayOfCurrMonth}
          pluralSubject="questões"
          singularSubject="questão"
          template="absolute verbose"
        />
      </Flex>
      {
        !anyDayOfCurrMonth && (
          <Flex
            {...selectBorder}
          >
            <DatePicker
              placeholderText="Escolha um período"
              minDate={new Date(profile.createdAt)}
              maxDate={new Date()}
              startDate={dateStart}
              endDate={dateEnd}
              locale="pt-BR"
              onChange={setDateRange}
              dateFormat="P"
              selectsRange
              isClearable
              clearButtonClassName="date-picker-close-button"
              className={`date-picker-main ${colorMode === 'light' ? 'date-picker-light' : 'date-picker-dark'}`}
              dayClassName={handleDayClassName}
            />
          </Flex>
        )
      }
      {loading ? (
        <Square color="light.200" size="100%" mt={2}>
          <FontAwesomeIcon icon={faSpinner} spin size="2x" />
        </Square>
      ) : (
        <>
          <Center my={4} position="relative" w="100%" h={{ base: 200, md: 300 }}>
            <ResponsiveContainer width="100%" height="100%">
              <PieChart>
                <Tooltip content={<CustomQuestionTooltip />} />
                {chartData && (
                  <Pie
                    stroke="none"
                    data={chartData}
                    dataKey="questions"
                    nameKey="area"
                    innerRadius={device === 'web' ? 80 : 60}
                    labelLine={false}
                    label={handlePieLabel}
                  >
                    {chartData.map((entry, index) => (
                      <Cell key={entry.area} fill={COLORS[index]} />
                    ))}
                  </Pie>
                )}
              </PieChart>
            </ResponsiveContainer>
            <Text
              color={!hasPayload ? colors.secondary.goLighter : 'transparent'}
              transition="all 0.3s"
              position="absolute"
              fontWeight="bold"
              fontSize="2xl"
            >
              {noOfQuestions}
            </Text>
          </Center>
          <Wrap justify="center" spacing={4}>
            {chartData?.map((item, index) => (
              <WrapItem key={item.area}>
                <HStack>
                  <Circle size="10px" bgColor={COLORS[index]} />
                  <Text fontSize="0.7rem">{item.area}</Text>
                </HStack>
              </WrapItem>
            ))}
          </Wrap>
        </>
      )}
    </Box>
  );
}
