import { FC, ReactNode, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDefaultStyles } from '../../../hooks/useDefaultStyles';

type RouteManagerType = {
  children: ReactNode,
}

export const RouteManager: FC<RouteManagerType> = ({
  children,
}: RouteManagerType) => {
  const history = useHistory();
  const { homePage } = useDefaultStyles();

  useEffect(() => {
    if (homePage) {
      history.push(homePage);
    }
  }, [history, homePage]);

  return (
    <>
      {children}
    </>
  );
};
