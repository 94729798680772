import { useTheme } from '@chakra-ui/react';
import React, { FC } from 'react';
import {
  CartesianGrid, LineChart, XAxis, YAxis, Line, Tooltip, ResponsiveContainer,
} from 'recharts';
import { useDefaultStyles, useRGBColor } from '../../../../hooks/useDefaultStyles';
import Text from '../../../../lib/components/Text';
import ChartTooltip from './ChartTooltip';

interface ChartComponentProps {
  chartData: {
    xLabel: string;
    user: number;
    global: number;
  }[]
}

const ChartComponent: FC<ChartComponentProps> = ({
  chartData,
}) => {
  const theme = useTheme();
  const { colors } = useDefaultStyles();
  const { toRGB } = useRGBColor();

  return (
    <div
      style={{
        height: '300px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      className="pb-2"
    >
      {chartData.length !== 0 ? (
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            width={530}
            height={300}
            data={chartData}
            margin={{
              top: 15,
              right: 30,
              left: -5,
              bottom: 10,
            }}
          >
            <XAxis
              dataKey="xLabel"
              fontSize={12}
              stroke={toRGB(colors.primary.goFullLighter)}
              type="category"
              tickMargin={15}
            />
            <YAxis
              fontSize={12}
              stroke={toRGB(colors.primary.goFullLighter)}
              unit="%"
              tickFormatter={value => String(100 * value)}
            />
            <CartesianGrid stroke={theme.colors.gray[300]} strokeDasharray="5 5" />
            <Line
              type="monotone"
              dataKey="global"
              stroke={theme.colors.gray[400]}
              strokeWidth={2}
            />
            <Line
              type="monotone"
              dataKey="user"
              stroke={toRGB(colors.secondary.keep)}
              strokeWidth={2}
            />
            <Tooltip
              content={<ChartTooltip />}
            />
          </LineChart>
        </ResponsiveContainer>
      ) : (
        <Text>
          Nenhum dado encontrado
        </Text>
      )}
    </div>
  );
};

export default ChartComponent;
