import { Box, Button, Center, Flex, HStack, Text, Tooltip, VStack } from '@chakra-ui/react';
import {
  faArrowCircleLeft, faArrowCircleRight,
  faCheckCircle, faSync, faTimesCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import { FullScreenProps } from 'react-full-screen';
import { useDefaultStyles } from '../../../hooks/useDefaultStyles';
import { useDevice } from '../../../hooks/useDevice';
import { getTimeSpentSince } from '../../../utils/getTimeSpent';
import { toast } from '../../../utils/toast';
import { LikeDislike } from '../../_components/LikeDislike';
import { MoreOptions } from '../../_components/MoreOptions';
import { WholeAppContext } from '../WholeApp.context';
import FavoriteBtn from './FavoriteBtn.component';
import { FocusModeButton } from './FocusModeButton';
import { FocusTimeButton } from './FocusTimeButton';
import { KeyboardKey } from './KeyboardKey';
import { KeyboardTipsButton } from './KeyboardTipsButton';

interface FlashcardsTopButtonsInterface {
  flashcardId: string,
  fullscreenHandle: FullScreenProps['handle'],
  show?: boolean,
  isReverse: boolean,
  setIsReverse: React.Dispatch<React.SetStateAction<boolean>>,
  isCommunityFlashcard?: boolean,
}

export const FlashcardsTopButtons: FC<FlashcardsTopButtonsInterface> = ({
  flashcardId,
  fullscreenHandle,
  show = true,
  isReverse,
  setIsReverse,
  isCommunityFlashcard,
}) => {
  const device = useDevice();
  const [focusStartDate, setFocusStartDate] = useState<Date | undefined>(new Date());
  const [focusTimeSpent, setFocusTimeSpent] = useState('');
  const { setHideElementsThatTriggerModals } = useContext(WholeAppContext);
  const isFullscreen = fullscreenHandle.active;
  const isFullscreenOnWeb = isFullscreen && device === 'web';
  const { colors } = useDefaultStyles();

  useEffect(() => {
    if (!isFullscreen) {
      setFocusStartDate(undefined);
      setHideElementsThatTriggerModals(false);
    } else {
      setHideElementsThatTriggerModals(true);
    }
  }, [isFullscreen, setFocusStartDate, setHideElementsThatTriggerModals]);

  useEffect(() => {
    if (isFullscreenOnWeb) {
      const intervalId = setInterval(() => {
        if (focusStartDate) {
          setFocusTimeSpent(getTimeSpentSince(focusStartDate));
        }
      }, 1000);
      return () => clearInterval(intervalId);
    }
    return () => null;
  }, [focusStartDate, isFullscreenOnWeb]);

  const toggleFullscreen = useCallback(async () => {
    if (!fullscreenHandle.active) {
      fullscreenHandle.enter();
      setFocusStartDate(new Date());
    } else {
      try {
        fullscreenHandle.exit();
        setFocusStartDate(undefined);
      } catch (e) {
        toast.warning('Saia do modo tela cheia para ativar o modo foco (Aperte F11)!');
        console.error(e);
      }
    }
  }, [fullscreenHandle]);

  if (!show) {
    return null;
  }

  return (
    <Flex
      m="0 auto"
      gridGap={14}
      w={device === 'mobile' ? '100%' : undefined}
      maxW={isFullscreen && device === 'web' ? undefined : 370}
      my={4}
      align="center"
      justify="space-between"
    >
      {
        !isCommunityFlashcard && (
          <LikeDislike
            document={flashcardId}
            type="flashcard"
          />
        )
      }
      <HStack>
        <Tooltip label={`${isReverse ? 'Desativar' : 'Ativar'} modo reverso`}>
          <Button
            variant="ghost"
            onClick={() => setIsReverse(prev => !prev)}
            color={colors.secondary.keep}
            position="relative"
          >
            <HStack color={colors.secondary.keep} spacing={0} position="relative">
              <Box>
                <FontAwesomeIcon icon={faSync} />
              </Box>
            </HStack>
            <Text position="absolute" bottom={1} right={2} fontSize="xs">
              {isReverse ? '' : 'R'}
            </Text>
          </Button>
        </Tooltip>
        <FocusTimeButton
          show={isFullscreen}
          focusTime={focusTimeSpent}
          variant="ghost"
        />
        <FocusModeButton
          show={device === 'web'}
          toggleFullscreen={toggleFullscreen}
          isFullscreen={isFullscreen}
        />
        <KeyboardTipsButton
          show={device === 'web' && !isFullscreen}
        >
          <VStack spacing={4}>
            <HStack spacing={3}>
              <KeyboardKey
                icon={<FontAwesomeIcon icon={faTimesCircle} />}
                text="Q"
                iconColor={colors.red.keep}
              />
              <KeyboardKey />
              <KeyboardKey
                icon={<FontAwesomeIcon icon={faCheckCircle} />}
                text="E"
                iconColor={colors.green.keep}
              />
            </HStack>
            <HStack spacing={3} pl={8}>
              <KeyboardKey
                icon={<FontAwesomeIcon icon={faArrowCircleLeft} />}
                text="A"
                iconColor={colors.secondary.keep}
              />
              <KeyboardKey
                icon={<FontAwesomeIcon icon={faSync} />}
                text="S"
                iconColor={colors.secondary.keep}
              />
              <KeyboardKey
                icon={<FontAwesomeIcon icon={faArrowCircleRight} />}
                text="D"
                iconColor={colors.secondary.keep}
              />
            </HStack>
          </VStack>
          <Center w="100%" mt={5}>
            <Flex flexFlow="column" align="flex-start">
              <Text>Q: Errei</Text>
              <Text>E: Acertei</Text>
              <Text>A: Flashcard anterior</Text>
              <Text>S: Virar flashcard</Text>
              <Text>D: Próximo flashcard</Text>
            </Flex>
          </Center>
        </KeyboardTipsButton>
      </HStack>
      {
        !isCommunityFlashcard && (
          <HStack>
            <FavoriteBtn
              document={flashcardId}
              type="flashcard"
            />
            <MoreOptions
              contentId={flashcardId}
              type="flashcard"
            />
          </HStack>
        )
      }
    </Flex>
  );
};
