import { Box, Center, Circle, Flex, Heading, HStack, Text, VStack } from '@chakra-ui/react';
import { FC } from 'react';
import {
  Bar, CartesianGrid, Tooltip, XAxis, YAxis,
  ResponsiveContainer, BarChart,
} from 'recharts';
import { FCStatsType } from '../../../api/student-report';
import { useDefaultStyles, useRGBColor } from '../../../hooks/useDefaultStyles';
import { useDevice } from '../../../hooks/useDevice';
import { CustomTooltipType } from './AreasPerformances';
import { ComparisonText } from './ComparisonText.component';
import { PrizeStar } from './PrizeStar';
import { ReportLoading } from './ReportLoading';

const CustomTooltip: FC<CustomTooltipType> = ({ label, payload }: CustomTooltipType) => {
  const { cardBorder, colors } = useDefaultStyles();
  if (!payload || !payload[0] || !payload[1]) {
    return null;
  }
  const { name: hitsName, value: hitsValue } = payload[0];
  const { name: missesName, value: missesValue } = payload[1];
  const performance = Math.round((hitsValue * 100) / (missesValue + hitsValue));

  return (
    <Box
      bgColor={colors.background}
      padding={2}
      align="center"
      {...cardBorder}
    >
      <Text mb={1} color={colors.secondary.keep} fontWeight="bold" fontSize="lg">{label}</Text>
      <VStack align="flex-start">
        <HStack>
          <Text>{`${missesName}:`}</Text>
          <Text>{missesValue}</Text>
        </HStack>
        <HStack>
          <Text>{`${hitsName}:`}</Text>
          <Text>{hitsValue}</Text>
        </HStack>
        <HStack>
          <Text>Total:</Text>
          <Text>{hitsValue + missesValue}</Text>
        </HStack>
        <HStack color={colors.secondary.keep}>
          <Text>Rendimento:</Text>
          <Text>{`${performance || 0}%`}</Text>
        </HStack>
      </VStack>
    </Box>
  );
};

type FlashcardsReportType = {
  prevFCData?: FCStatsType,
  currFCData?: FCStatsType,
}

export const FlashcardsReport: FC<FlashcardsReportType> = ({
  prevFCData, currFCData,
}: FlashcardsReportType) => {
  const { cardBorder, colors } = useDefaultStyles();
  const { toRGB } = useRGBColor();
  const device = useDevice();

  if (!prevFCData || !currFCData) {
    return <ReportLoading />;
  }

  const chartData = [
    {
      label: 'Mês passado',
      hits: prevFCData.hits,
      misses: prevFCData.misses,
    },
    {
      label: 'Este mês',
      hits: currFCData.hits,
      misses: currFCData.misses,
    },
  ];

  return (
    <Box
      mb={{ base: 4, md: 0 }}
      p={4}
      w={device === 'mobile' ? '100%' : '50%'}
      {...cardBorder}
    >
      <HStack mb={5} alignItems="flex-start">
        <HStack flex={3}>
          <PrizeStar
            label="Parabéns! Você fez no mínimo 1500 flashcards no mês!"
            goal={1500}
            achieved={chartData[1].hits + chartData[1].misses}
          />
          <Heading size="md">Quantidade de flashcards</Heading>
        </HStack>
        <ComparisonText
          previousValue={chartData[0].hits + chartData[0].misses}
          currentValue={chartData[1].hits + chartData[1].misses}
          pluralSubject="flashcards"
          singularSubject="flashcard"
          template="absolute verbose"
        />
      </HStack>
      <Center>

        <Center
          my={4}
          position="relative"
          w="100%"
          maxW={350}
          h={{ base: 200, md: 300 }}
          flexFlow="column"
        >
          <ResponsiveContainer height="100%">
            <BarChart
              margin={{
                right: 40,
              }}
              data={chartData}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis stroke={toRGB(colors.primary.goFullLighter)} type="category" dataKey="label" />
              <YAxis stroke={toRGB(colors.primary.goFullLighter)} type="number" />
              <Tooltip
                cursor={{ fill: toRGB(colors.alpha[100]) }}
                content={<CustomTooltip />}
              />
              <Bar
                barSize={25}
                stackId="a"
                dataKey="hits"
                name="Acertos"
                fill={toRGB(colors.secondary.goLighter)}
                fillOpacity={0.8}
              />
              <Bar
                barSize={25}
                stackId="a"
                dataKey="misses"
                name="Erros"
                fill={toRGB(colors.primary.goLighter)}
                fillOpacity={0.8}
              />
            </BarChart>
          </ResponsiveContainer>
          <Flex gridGap={5} marginTop={device === 'mobile' ? 2 : 4}>
            <HStack color={colors.secondary.goLighter}>
              <Circle size={2} bgColor={colors.secondary.goLighter} />
              <Text>Acertos</Text>
            </HStack>
            <HStack color={colors.primary.goLighter}>
              <Circle size={2} bgColor={colors.primary.goLighter} />
              <Text>Erros</Text>
            </HStack>
          </Flex>
        </Center>
      </Center>
    </Box>
  );
};
