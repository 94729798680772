import { HStack, Text } from '@chakra-ui/react';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDefaultStyles } from '../../hooks/useDefaultStyles';
import { PrimaryButton } from './PrimaryButton';
import { SecondaryButton } from './SecondaryButton';

interface ButtonNavigatorProps {
  isNextDisabled: boolean;
  isPrevDisabled: boolean;
  handleNext: () => void;
  handlePrev: () => void;
  justifyMobile?: 'end' | 'start' | 'center';
  justifyDesktop?: 'end' | 'start' | 'center';
}

export function ButtonNavigator({
  isNextDisabled, isPrevDisabled, handleNext, handlePrev,
  justifyMobile = 'center', justifyDesktop = 'end',
}: ButtonNavigatorProps) {
  const { colors } = useDefaultStyles();
  return (
    <HStack
      spacing={4}
      my={4}
      justify={{ base: justifyMobile, md: justifyDesktop }}
      maxW={1000}
    >
      <SecondaryButton
        fontWeight="normal"
        disabled={isPrevDisabled}
        leftIcon={<FontAwesomeIcon icon={faChevronLeft} />}
        onClick={handlePrev}
      >
        <Text color={colors.secondary.goFullLighter}>Anterior</Text>
      </SecondaryButton>
      <PrimaryButton
        fontWeight="normal"
        disabled={isNextDisabled}
        rightIcon={<FontAwesomeIcon icon={faChevronRight} />}
        onClick={handleNext}
      >
        Próximo
      </PrimaryButton>
    </HStack>
  );
}
