import { gql, QueryResult, useQuery } from '@apollo/client';

export interface PerformanceThemeRow {
  classification: string[];
  performance: number | null;
  relevance: number | null;
}

export interface GetPerformanceThemeOutput {
  getPerformanceByClassification: PerformanceThemeRow[];
}

export const GET_PERFORMANCE_THEME = gql`
  query GET_PERFORMANCE_THEME {
    getPerformanceByClassification {
      classification
      performance
      relevance
    }
  }
`;

export function useQueryGetPerformanceTheme(): QueryResult<GetPerformanceThemeOutput> {
  return useQuery<GetPerformanceThemeOutput>(GET_PERFORMANCE_THEME, {
    fetchPolicy: 'cache-and-network',
  });
}
