import { Box, Divider, Modal, ModalBody, ModalContent, ModalOverlay, useDisclosure } from '@chakra-ui/react';
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContext } from 'react';
import { DocTypes } from '../../api/agenda/agenda';
import { toast } from '../../utils/toast';
import { WholeAppContext } from '../agenda/WholeApp.context';
import ModalReport from './ModalReport';

interface MoreOptionsProps {
  contentId: string;
  type: DocTypes | 'comment';
}

export function MoreOptions({
  contentId, type,
}: MoreOptionsProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { hideElementsThatTriggerModals } = useContext(WholeAppContext);
  const { isOpen: isOpenReport, onOpen: onOpenReport, onClose: onCloseReport } = useDisclosure();

  if (hideElementsThatTriggerModals) {
    return null;
  }

  return (
    <>
      <Box
        as="button"
        onClick={onOpen}
        px={2}
      >
        <FontAwesomeIcon icon={faEllipsisH} />
      </Box>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent alignSelf={{ base: 'flex-end', md: 'center' }} mx={4}>
          <ModalBody display="flex" flexDirection="column" px={0}>
            <Box
              as="button"
              py={2}
              color="error.500"
              _hover={{
                bgColor: 'light.100',
              }}
              onClick={() => {
                onClose();
                onOpenReport();
              }}
            >
              Reportar problema
            </Box>
            <Divider />
            <Box
              as="button"
              py={2}
              _hover={{
                bgColor: 'light.100',
              }}
              onClick={() => {
                navigator.clipboard.writeText(`https://aristoclass.com.br/viewer/questao?id=${contentId}`);
                onClose();
                toast.info('ID da questão copiado para a área de transferência!');
              }}
            >
              Copiar ID da questão
            </Box>
            <Divider />
            <Box
              as="button"
              py={2}
              _hover={{
                bgColor: 'light.100',
              }}
              onClick={onClose}
            >
              Cancelar
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
      <ModalReport
        id={contentId}
        type={type}
        isOpen={isOpenReport}
        onClose={onCloseReport}
      />
    </>
  );
}
