import { FC } from 'react';
import { VStack, Text } from '@chakra-ui/react';
import moment from 'moment';
import { useDefaultStyles } from '../../../hooks/useDefaultStyles';
import { MockExamInterface } from '../../../api/agenda/mock-exam';

export const AgendaMockExamExpiredMessage: FC<{ mock: MockExamInterface }> = ({
  mock,
}) => {
  const { cardBorder } = useDefaultStyles();
  const finishesAtFormatted = moment(mock.limitDateToStart).format('HH:mm [do dia] DD/MM/YYYY');

  return (
    <VStack
      spacing="16px"
      {...cardBorder}
      padding="16px"
      alignItems="flex-start"
      maxWidth="600px"
      margin="0px auto"
    >
      <Text fontSize="lg">{mock.title}</Text>
      <Text fontSize="md">
        {`Instituição: ${mock.subtitle}`}
      </Text>
      <Text fontSize="32px">O prazo para participar desse simulado já encerrou 🙁</Text>
      <Text>
        {`Esse simulado só pode ser iniciado até às ${finishesAtFormatted}.`}
      </Text>
    </VStack>
  );
};
