import React, { FC } from 'react';
import {
  Box,
  Flex,
  RangeSlider,
  RangeSliderFilledTrack,
  RangeSliderProps,
  RangeSliderThumb,
  RangeSliderTrack,
  Text,
} from '@chakra-ui/react';
import { useDefaultStyles } from '../../hooks/useDefaultStyles';

interface DoubleRangeSliderProps extends RangeSliderProps {
  currentMin: number;
  currentMax: number;
  label: string;
}

const DoubleRangeSlider: FC<DoubleRangeSliderProps> = ({
  currentMin, currentMax, label, ...rest
}) => {
  const { colors } = useDefaultStyles();
  return (
    <Box w="100%">
      {
        label && (
          <Text fontSize="sm" color={colors.secondary.goALittleDarker}>{label}</Text>
        )
      }
      <Flex w="100%" justify="space-between">
        <Text fontSize="sm">{currentMin}</Text>
        <Text fontSize="sm">{currentMax}</Text>
      </Flex>
      <RangeSlider {...rest} step={1}>
        <RangeSliderTrack bg="secondary.500">
          <RangeSliderFilledTrack bg={colors.secondary.goALittleDarker} />
        </RangeSliderTrack>
        <RangeSliderThumb bgColor={colors.secondary.goALittleDarker} boxSize={6} index={0} />
        <RangeSliderThumb bgColor={colors.secondary.goALittleDarker} boxSize={6} index={1} />
      </RangeSlider>
    </Box>
    // <div>
    //   <Text tag="small" color="primary" className="mb-1" ellipsis>{label}</Text>
    //   <Range
    //     min={min}
    //     max={max}
    //     defaultValue={defaultValue}
    //     onChange={onChange}
    //     className="mb-1"
    //   />
    //   <Flex justify="space-between">
    //     <Text color="black">{currentMin}</Text>
    //     <Text color="black">{currentMax}</Text>
    //   </Flex>
    // </div>
  );
};

export default DoubleRangeSlider;
