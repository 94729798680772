import { Box, BoxProps } from '@chakra-ui/react';
import { FC, useMemo, useState } from 'react';
import { DiagnosticAreaRowInterface } from '../../../api/diagnosis';
import { useDevice } from '../../../hooks/useDevice';
import useTableFilter from '../../../hooks/useTableFilter';
import TableHeaderComponent from '../../../lib/components/TableHeader.component';
import { DiagnosticAreaRow } from './DiagnosticAreaRow';
import { DiagnosticTableMobileNav } from './DiagnosticTableMobileNav';

export type TableStyleType = 'diagnostic' | 'compass';

interface DiagnosticAreaTableType extends BoxProps {
  areas?: DiagnosticAreaRowInterface[],
  showThemesToReview?: boolean,
  tableStyle?: TableStyleType,
}

export const DiagnosticAreaTable: FC<DiagnosticAreaTableType> = ({
  areas,
  showThemesToReview = true,
  tableStyle = 'diagnostic',
  ...props
}) => {
  const device = useDevice();
  const NUMBER_OF_TABLE_STEPS = 3;
  const [tableStep, setTableStep] = useState(0);
  const filteredAreas = useMemo(() => (
    areas?.filter(e => e.area !== 'Total') ?? []
  ), [areas]);

  const {
    dataFiltered: areasFiltered,
    filter: areaFilter,
    handleFilter: handleAreaFilter,
  } = useTableFilter(filteredAreas);

  const areaFooter = useMemo(() => (
    areas?.find(e => e.area === 'Total')
  ), [areas]);

  const headers = useMemo(() => {
    const _headers = [
      {
        key: 'area',
        value: 'Grande Área',
        flex: 2,
        alignItems: 'left',
        title: 'Grande área',
      },
      {
        flex: 1,
        key: 'atentionCount',
        value: 'Temas a rever',
        alignItems: device === 'web' ? 'left' : 'center',
        title: 'Número de temas a rever por grande área.',
        showOnMobile: tableStep === 4,
      },
      {
        key: 'easyPerformance',
        value: 'Fáceis',
        flex: 1,
        alignItems: 'left',
        title: 'Acertos em questões fáceis.',
        showOnMobile: tableStep === 1,
      },
      {
        key: 'mediumPerformance',
        value: 'Médias',
        flex: 1,
        alignItems: 'left',
        title: 'Acertos em questões de média dificuldade.',
        showOnMobile: tableStep === 2,
      },
      {
        key: 'hardPerformance',
        value: 'Difíceis',
        alignItems: 'left',
        flex: 1,
        title: 'Acertos em questões difíceis.',
        showOnMobile: tableStep === 3,
      },
      {
        key: 'totalPerformance',
        value: 'Total',
        alignItems: 'left',
        flex: 1,
        title: 'Acertos totais.',
        showOnMobile: tableStep === 0,
      },
    ];
    if (!showThemesToReview) {
      _headers.splice(1, 1);
      return _headers;
    }
    return _headers;
  }, [device, showThemesToReview, tableStep]);

  return (
    <>
      <DiagnosticTableMobileNav
        noOfSteps={NUMBER_OF_TABLE_STEPS}
        tableStep={tableStep}
        setTableStep={setTableStep}
      />
      <Box
        maxWidth={1120}
        width="100%"
        bgColor="white"
        color="aristo.600"
        // borderRadius="1rem"
        marginBottom="2rem"
        {...props}
      >
        <TableHeaderComponent
          paddingLeft=".5rem"
          color={tableStyle === 'diagnostic' ? 'aristo.500' : 'gray.600'}
          bgColor={tableStyle === 'diagnostic' ? '#8CCAFF' : ''}
          fontSize={tableStyle === 'diagnostic' ? undefined : 13}
          headers={headers}
          handleFilter={handleAreaFilter}
          filter={areaFilter}
        />
        {
          areasFiltered.map((item, i) => {
            const _key = Math.random().toString(36).slice(2);
            return (
              <DiagnosticAreaRow
                showThemesToReview={showThemesToReview}
                key={_key}
                tableStyle={tableStyle}
                tableStep={tableStep}
                colorIndex={i % 2}
                lineTooltip={`${item.atentionCount} temas de ${item.area} precisam de atenção.`}
                rowData={item}
              />
            );
          })
        }
        <DiagnosticAreaRow
          showThemesToReview={showThemesToReview}
          tableStep={tableStep}
          tableStyle={tableStyle}
          colorIndex={1}
          lineTooltip="Resultado geral"
          isFooter
          rowData={areaFooter}
        />
      </Box>
    </>
  );
};
