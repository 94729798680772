import { FC, useContext, useEffect } from 'react';
import { AREAS } from './_components/SearchBars/AreaFilter';
import { ListDeckCards } from './_components/DeckViewer/ListDeckCards';
import { ListAllDecks } from './_components/DeckViewer/ListAllDecks';
import { FlashcardsCreationContext } from './contexts/FlashcardsCreation.context';

export const FlashcardsCreationMainPage: FC = () => {
  const {
    setAllowDelay,
    setSkip,
    activeAreasArray,
    searchInput,
    currLessonId,
  } = useContext(FlashcardsCreationContext);

  useEffect(() => {
    if (activeAreasArray.join('') !== AREAS.join('') || searchInput !== '') {
      setAllowDelay(false);
    } else {
      setAllowDelay(true);
    }
  }, [activeAreasArray, searchInput, setAllowDelay]);

  useEffect(() => {
    if (setSkip) {
      setSkip(0);
    }
  }, [currLessonId, setSkip]);

  return currLessonId ? <ListDeckCards /> : <ListAllDecks />;
};
