/* eslint-disable no-undef */
import React, {
  FC, useState, useEffect, useCallback, useContext,
} from 'react';
import Markdown from 'react-markdown';
import { Box, Button, Divider, Flex, HStack, Image, Text } from '@chakra-ui/react';
import { getTheoreticalType } from '../../hooks/getTheoreticalType';
import { getDateParam } from '../../hooks/getDateParam';
import useFirebaseStorageImg from '../../hooks/useFirebaseStorageImg';
import { useAnswer } from '../../api/answer';
import { getActivityType } from '../../hooks/getActivityType';
import { ZoomImg } from '../../lib/components/ZoomImg';
import { useDefaultStyles } from '../../hooks/useDefaultStyles';
import { FlashcardButton } from './FlashcardButton.component';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import { useKeyPressed } from '../../hooks/useKeyPressed';
import { WholeAppContext } from '../agenda/WholeApp.context';
import { useDevice } from '../../hooks/useDevice';

interface FlashcardProps {
  id: string;
  index: number;
  additionalInfo?: string;
  front: string;
  back: string;
  area?: string;
  active?: boolean;
  answer?: number;
  finished?: boolean;
  activityId?: string;
  classification: string[];
  onSetAnswers?: (i: number, value: number) => void;
  disabled?: boolean;
}

const Flashcard: FC<FlashcardProps> = ({
  front,
  back,
  area,
  additionalInfo,
  index,
  active,
  id,
  answer,
  finished,
  activityId,
  classification,
  onSetAnswers,
  disabled,
}) => {
  /**
   * States
   */
  const [open, setOpen] = useState(false);
  const [answerLoading, setAnswerLoading] = useState(false);
  const { colors, hueRotate } = useDefaultStyles();
  const device = useDevice();
  const flipPressed = useKeyPressed('KeyS');
  const hitPressed = useKeyPressed('KeyE');
  const missPressed = useKeyPressed('KeyQ');
  const { areShortcutsEnabled } = useContext(WholeAppContext);

  /**
   * Contexts
   */
  const { onCreateOneAnswer } = useAnswer();
  const [isCompass] = useLocalStorage('@Aristoclass:isCompass');

  const storage = useFirebaseStorageImg();
  const getImage = useCallback((uri: string) => storage.getURL(uri), [storage]);

  /**
   * Callbacks
   */
  const handleAnswer = useCallback((value: number) => async () => {
    setAnswerLoading(true);
    try {
      if (!isCompass) {
        await onCreateOneAnswer({
          choosen: value,
          document: id,
          calendarDate: getDateParam(),
          type: getActivityType() as 'flashcard' | 'extra-smart-review-flashcard',
          theoreticalType: !getTheoreticalType().includes('extras') ? getTheoreticalType() : undefined,
          classification: classification || [],
          activity: activityId,
          correctChoice: 1,
        });
      }
      if (onSetAnswers) {
        onSetAnswers(index, value);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setAnswerLoading(false);
      setOpen(false);
    }
  }, [activityId, classification, id, index, isCompass, onCreateOneAnswer, onSetAnswers]);

  /**
   * Effects
   */
  useEffect(() => {
    setOpen(false);
  }, [active, index]);

  useEffect(() => {
    if (answer === -1) {
      setOpen(false);
    }
  }, [answer, index]);

  useEffect(() => {
    async function exec() {
      await handleAnswer(1)();
    }
    if (areShortcutsEnabled && open && hitPressed && answer === -1) {
      exec();
    }
  }, [hitPressed, answer, handleAnswer, open, areShortcutsEnabled]);

  useEffect(() => {
    async function exec() {
      await handleAnswer(0)();
    }
    if (areShortcutsEnabled && open && missPressed && answer === -1) {
      exec();
    }
  }, [hitPressed, answer, missPressed, handleAnswer, open, areShortcutsEnabled]);

  useEffect(() => {
    if (device !== 'mobile' && areShortcutsEnabled && flipPressed) {
      setOpen(prev => !prev);
    }
  }, [areShortcutsEnabled, device, flipPressed]);

  return (
    <Box sx={{ perspective: '1000px' }} color={colors.lighter.keep}>
      <Box
        position="relative"
        transition="transform 0.56s"
        transform={open ? 'rotateY(180deg)' : ''}
        pointerEvents="none"
        h="452px"
        w="340px"
        zIndex={-1}
        sx={{ transformStyle: 'preserve-3d' }}
      >
        <Flex
          pointerEvents="auto"
          position="absolute"
          bgColor={colors.primary.goALittleLighter}
          direction="column"
          zIndex={0}
          borderRadius="20px"
          justify="space-between"
          p={4}
          w="100%"
          h="100%"
          sx={{
            backfaceVisibility: 'hidden',
            WebkitBackfaceVisibility: 'hidden',
          }}
        >
          <Flex justify="space-between" align="center">
            <Image filter={hueRotate()} w="80px" src="/images/flash-card.svg" alt="flash" />
            <Flex
              bgColor={colors.secondary.keep}
              w="40px"
              h="40px"
              p={1}
              align="center"
              justify="center"
              borderRadius="500px"
              border="1px solid white"
            >
              <Text fontWeight="bold">{index + 1}</Text>
            </Flex>
          </Flex>
          <Flex justify="center" mt={4}>
            <Text textAlign="center">{additionalInfo}</Text>
          </Flex>
          <Divider borderColor={colors.lighter.keep} />
          <Flex flex={1} p={2} maxHeight={280} textAlign="center" overflow="auto" justify="center">
            <Markdown
              transformImageUri={getImage}
              renderers={{ image: (props): JSX.Element => <ZoomImg {...props} /> }}
              className="markdown-body"
            >
              {front}
            </Markdown>
          </Flex>
          <Divider borderColor={colors.lighter.keep} />
          <Flex my={2} mt={5} justify="center">
            <Button
              w={200}
              bgColor={colors.secondary.goALittleDarker}
              onClick={() => setOpen(old => !old)}
              _hover={{
                filter: 'brightness(0.9)',
              }}
              transition="all 0.3s"
            >
              Resposta
            </Button>
          </Flex>
          <Text>{area}</Text>
        </Flex>
        <Flex
          position="absolute"
          direction="column"
          pointerEvents="auto"
          borderRadius="20px"
          zIndex={0}
          justify="space-between"
          p={4}
          bgColor={colors.primary.goALittleLighter}
          w="100%"
          h="100%"
          sx={{
            backfaceVisibility: 'hidden',
            WebkitBackfaceVisibility: 'hidden',
          }}
          transform="rotateY(180deg)"
        >
          <Flex justify="space-between" align="center">
            <Image filter={hueRotate()} w="80px" src="/images/flash-card.svg" alt="flash" />
            <Flex
              bgColor={colors.secondary.keep}
              w="40px"
              h="40px"
              p={1}
              align="center"
              justify="center"
              borderRadius="500px"
              border="1px solid white"
            >
              <Text fontWeight="bold">{index + 1}</Text>
            </Flex>
          </Flex>
          <Flex justify="center" mt={4}>
            <Text textAlign="center">{additionalInfo}</Text>
          </Flex>
          <Divider borderColor={colors.lighter.keep} />
          <Flex flex={1} p={2} maxHeight={280} textAlign="center" overflow="auto" justify="center">
            <Markdown
              transformImageUri={getImage}
              renderers={{ image: (props): JSX.Element => <ZoomImg {...props} /> }}
              className="markdown-body"
            >
              {back}
            </Markdown>
          </Flex>
          <Divider borderColor={colors.lighter.keep} />
          <Flex my={2} direction="column" align="stretch" zIndex={1}>
            {
              answer === 0 && (
                <Button
                  bgColor={colors.red.goDarker}
                  colorScheme="red"
                  _hover={{
                    filter: 'brightness(0.9)',
                  }}
                  transition="all 0.3s"
                  cursor="default"
                >
                  <Text color={colors.lighter.keep}>Errou</Text>
                </Button>
              )
            }
            {
              answer === 1 && (
                <Button
                  bgColor={colors.green.goDarker}
                  colorScheme="green"
                  color={colors.lighter.keep}
                  _hover={{
                    filter: 'brightness(0.9)',
                  }}
                  transition="all 0.3s"
                  cursor="default"
                >
                  <Text color={colors.lighter.keep}>Acertou</Text>
                </Button>
              )
            }
            {
              (answer !== 0 && answer !== 1) && (
                <Flex direction="column" zIndex={1} pointerEvents="auto">
                  <HStack justify="center">
                    <FlashcardButton
                      pointerEvents="auto"
                      zIndex={1}
                      bgColor={colors.red.goDarker}
                      label="Errei"
                      onClick={handleAnswer(0)}
                      disabled={finished || disabled || answerLoading}
                      isLoading={answerLoading}
                    />
                    <FlashcardButton
                      pointerEvents="auto"
                      zIndex={1}
                      bgColor={colors.green.goDarker}
                      label="Acertei"
                      onClick={handleAnswer(1)}
                      disabled={finished || disabled || answerLoading}
                      isLoading={answerLoading}
                    />
                  </HStack>
                </Flex>
              )
            }
            <Button
              w={200}
              bgColor={colors.secondary.goALittleDarker}
              onClick={() => setOpen(old => !old)}
              _hover={{
                filter: 'brightness(0.9)',
              }}
              transition="all 0.3s"
              alignSelf="center"
              mt={2}
            >
              Pergunta
            </Button>
          </Flex>
        </Flex>
      </Box>
    </Box>
  );
};

export default Flashcard;
