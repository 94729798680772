import { FC, useState } from 'react';
import moment from 'moment';
import {
  Flex,
  Text,
  Modal,
  ModalContent,
  ModalOverlay,
  VStack,
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faExclamationTriangle,
  faTrophy,
} from '@fortawesome/free-solid-svg-icons';
import { faLightbulb, faCheckCircle, faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import { useDefaultStyles } from '../../../hooks/useDefaultStyles';

interface ExamModalGradeInfoProps {
  totalQuestions?: number;
  studentScore?: number;
  rankingGeneratedOn?: Date;
  cutOffScore?: number;
  infoDescription?: string;
  studentRanking?: number;
}

const ExamModalGradeInfo: FC<ExamModalGradeInfoProps> = ({
  totalQuestions,
  studentScore,
  rankingGeneratedOn,
  cutOffScore,
  infoDescription,
  studentRanking,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { colors } = useDefaultStyles();

  const handleDialog = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div style={{ width: '100%' }}>
      <RankingInfoModal
        isOpen={isOpen}
        onClose={handleDialog}
        rankingGeneratedOn={rankingGeneratedOn}
      />
      <VStack sapcing="10px">
        <Flex width="100%" alignItems="center">
          <FontAwesomeIcon size="lg" icon={faCheckCircle} />
          <p style={{ marginLeft: '8px' }}>
            {`Total de acertos ${studentScore ?? '--'}/${totalQuestions ?? '--'}`}
            <strong>{` (${studentScore ?? '--'} pts)`}</strong>
          </p>
        </Flex>
        <Flex width="100%" alignItems="center">
          <FontAwesomeIcon size="lg" icon={faExclamationTriangle} />
          <p style={{ marginLeft: '8px' }}>
            Nota de Corte deste Simulado:
            <strong>{` ${cutOffScore ?? '--'} pts`}</strong>
          </p>
        </Flex>
        {infoDescription && (
          <Flex
            width="100%"
            padding="16px"
            borderRadius="16px"
            alignItems="flex-start"
            backgroundColor={colors.lighter.goMuchDarker}
          >
            <FontAwesomeIcon size="lg" icon={faLightbulb} />
            <Text marginLeft="8px" fontSize="14px">{infoDescription}</Text>
          </Flex>
        )}
        <Flex width="100%" alignItems="center">
          <FontAwesomeIcon size="lg" icon={faTrophy} />
          <Text marginLeft="8px">{`Posição no ranking: ${studentRanking ?? '-'}º`}</Text>
          {rankingGeneratedOn && (
            <FontAwesomeIcon onClick={handleDialog} icon={faQuestionCircle} size="lg" style={{ marginLeft: '8px' }} />
          )}
        </Flex>
      </VStack>
    </div>
  );
};

export default ExamModalGradeInfo;

interface RankingInfoModalProps {
  isOpen: boolean;
  onClose: () => void;
  rankingGeneratedOn?: Date,
}

const RankingInfoModal: FC<RankingInfoModalProps> = ({
  isOpen,
  onClose,
  rankingGeneratedOn,
}) => {
  const date = moment(rankingGeneratedOn).format('DD/MM/YYYY [às] HH:mm');

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      scrollBehavior="inside"
      isCentered
    >
      <ModalOverlay />
      <ModalContent
        maxW="254px"
        borderRadius="16px"
        padding="16px"
      >
        <Text>{`O Ranking será calculado em ${date}`}</Text>
      </ModalContent>
    </Modal>
  );
};
