// TODO: mudar 'hard' e 'easy', para 'high' e 'low'
export function getThemeIncidenceLevel(incidence: number): 'easy' | 'medium' | 'hard' {
  if (incidence < 0.005) {
    return 'easy';
  }

  if (incidence < 0.009) {
    return 'medium';
  }
  return 'hard';
}
