import { gql, QueryResult, useQuery } from '@apollo/client';

interface BurgerMenuTagType {
  label: string;
  color: string;
}

export interface BurgerMenuItemType {
  title: string;
  icon: string;
  url: string;
  order: number;
  subgroup: string;
  color?: string;
  tag?: BurgerMenuTagType;
}

interface FindBurgerMenuOutput {
  findBurgerMenuItems: BurgerMenuItemType[];
}

const FIND_BURGER_MENU_ITEMS = gql`
  query {
    findBurgerMenuItems {
      title
      icon
      url
      order
      subgroup
      color
      tag {
        color
        label
      }
    }
  }
`;

export function useFindBurgerMenu(): QueryResult<FindBurgerMenuOutput> {
  return useQuery<FindBurgerMenuOutput>(FIND_BURGER_MENU_ITEMS);
}
