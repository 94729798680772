import {
  Box, Circle, Flex, Heading, HStack, Table,
  Tbody, Td, Text, Thead, Tr, VStack,
} from '@chakra-ui/react';
import { faCheckCircle, faCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { startOfMonth, endOfMonth, addDays, subDays } from 'date-fns';
import { useMemo } from 'react';
import { useDefaultStyles, useRGBColor } from '../../../hooks/useDefaultStyles';
import { useDevice } from '../../../hooks/useDevice';
import { formatDate } from '../../../utils/formatDate';
import { ComparisonText } from './ComparisonText.component';
import { PrizeStar } from './PrizeStar';
import { ReportLoading } from './ReportLoading';

type CalendarSlotType = {
  hasCreatedAgenda: boolean;
  dayNumber: number;
}

type FrequencyCalendarProps = {
  anyDayOfCurrMonth: Date;
  frequencyArray: string[];
  loading: boolean;
}

function getMonthString(date: Date) {
  return formatDate(date).slice(0, 6);
}

export function FrequencyCalendar({
  anyDayOfCurrMonth,
  frequencyArray,
  loading,
}: FrequencyCalendarProps) {
  const WEEK_DAYS_ABBR = useMemo(() => ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'], []);
  const device = useDevice();
  const { cardBorder, colors } = useDefaultStyles();
  const { toRGB } = useRGBColor();
  const today = new Date();

  const month = useMemo(() => {
    const startOfCurrMonth = startOfMonth(anyDayOfCurrMonth);
    const endOfCurrMonth = endOfMonth(anyDayOfCurrMonth);
    const endOfLastMonth = subDays(startOfCurrMonth, 1);
    const lastMonthString = getMonthString(endOfLastMonth);
    const currMonthString = getMonthString(endOfCurrMonth);
    const lastMonthNoOfDays = endOfLastMonth.getDate();
    const currMonthNoOfDays = endOfCurrMonth.getDate();
    const currMonthStartWeekDay = startOfCurrMonth.getDay();
    const lastMonthDays = frequencyArray.filter(day => day.startsWith(lastMonthString));
    const currMonthDays = frequencyArray.filter(day => day.startsWith(currMonthString));
    const lastMonthPresenceIndex = lastMonthDays.length / lastMonthNoOfDays;
    const currMonthPresenceIndex = currMonthDays.length / currMonthNoOfDays;

    return {
      current: {
        start: {
          date: startOfCurrMonth,
          weekDay: currMonthStartWeekDay,
        },
        end: {
          date: endOfCurrMonth,
        },
        string: currMonthString,
        noOfDays: currMonthNoOfDays,
        days: currMonthDays,
        presenceIndex: currMonthPresenceIndex,
      },
      last: {
        string: lastMonthString,
        noOfDays: lastMonthNoOfDays,
        days: lastMonthDays,
        presenceIndex: lastMonthPresenceIndex,
      },
    };
  }, [anyDayOfCurrMonth, frequencyArray]);

  const monthDates = useMemo(() => {
    const dates = [];
    for (let i = 0; i < month.current.noOfDays; i += 1) {
      dates.push(formatDate(addDays(month.current.start.date, i)));
    }
    return dates;
  }, [month]);

  const calendarMap = useMemo(() => {
    const WEEK_LENGTH = 7;
    const calendarPaddingLeft = Array(month.current.start.weekDay)
      .fill({
        hasCreatedAgenda: false,
        dayNumber: -1,
      });

    const calendarDays = [];
    for (let i = 0; i < month.current.noOfDays; i += 1) {
      calendarDays.push({
        hasCreatedAgenda: month.current.days.includes(monthDates[i]),
        dayNumber: i + 1,
      });
    }

    const paddedCalendarLength = calendarPaddingLeft.length + calendarDays.length;
    const CALENDAR_SLOTS = (() => {
      if (paddedCalendarLength > 35) {
        return 42;
      }
      if (paddedCalendarLength > 28) {
        return 35;
      }
      return 28;
    })();
    const remainingFills = CALENDAR_SLOTS - paddedCalendarLength;

    const calendarPaddingRight = remainingFills > 0
      ? Array(remainingFills)
        .fill({
          hasCreatedAgenda: false,
          dayNumber: -1,
        })
      : [];

    const planCalendarMap = calendarPaddingLeft
      .concat(calendarDays)
      .concat(calendarPaddingRight);

    const splittedInWeeksCalendar: CalendarSlotType[][] = [];

    for (let slot = 0; slot < CALENDAR_SLOTS; slot += WEEK_LENGTH) {
      const chunk = planCalendarMap.slice(slot, slot + WEEK_LENGTH);
      splittedInWeeksCalendar.push(chunk);
    }

    return splittedInWeeksCalendar;
  }, [month, monthDates]);

  const presencePercent = Math.round((month.current.days.length * 100) / month.current.noOfDays);

  if (loading) {
    return <ReportLoading />;
  }

  return (
    <Box
      mb={{ base: 4, md: 0 }}
      p={4}
      w={device === 'mobile' ? '100%' : '50%'}
      {...cardBorder}
    >
      <HStack alignItems="flex-start">
        <HStack flex={2}>
          <VStack align="left">
            <HStack>
              <PrizeStar
                label="Parabéns! Você alcançou 100% de presença!"
                goal={1}
                achieved={month.current.presenceIndex}
              />
              <Heading size="md">Frequência</Heading>
            </HStack>
            <Text fontSize="xs" color={colors.secondary.goLighter}>
              {`Este mês: ${presencePercent}%`}
            </Text>
          </VStack>
        </HStack>
        <ComparisonText
          currentValue={month.current.presenceIndex * 100}
          previousValue={month.last.presenceIndex * 100}
        />
      </HStack>
      <Table my={5}>
        <Thead>
          <Tr>
            {WEEK_DAYS_ABBR.map((item, index) => {
              const key = `${item}${index}`;
              return (
                <Td
                  textAlign="center"
                  fontWeight="bolder"
                  key={key}
                  padding={device === 'mobile' ? 0 : undefined}
                  paddingX={0}
                >
                  {item}
                </Td>
              );
            })}
          </Tr>
        </Thead>
        <Tbody>
          {
            calendarMap.map((weeks, index) => {
              const weekKey = `w${index}`;
              return (
                <Tr key={weekKey}>
                  {weeks.map((days, ind) => {
                    const dayKey = `d${ind}`;
                    return (
                      <Td
                        key={dayKey}
                        padding={0}
                      >
                        {
                          days.dayNumber !== -1
                          && (
                            <Box
                              mx="auto"
                              w={8}
                              my={2}
                            >
                              {
                                ((days.dayNumber <= today.getDate())
                                  || (anyDayOfCurrMonth.getMonth() !== today.getMonth()))
                                  || (anyDayOfCurrMonth.getFullYear() !== today.getFullYear())
                                  ? (
                                    <Flex
                                      color={days.hasCreatedAgenda ? colors.green.keep : colors.red.keep}
                                      justifyContent="flex-end"
                                    >
                                      <FontAwesomeIcon icon={days.hasCreatedAgenda ? faCheckCircle : faTimesCircle} />
                                    </Flex>
                                  ) : (
                                    <Flex
                                      color={colors.white.keep}
                                      justifyContent="flex-end"
                                    >
                                      <Circle
                                        border={`1px solid ${toRGB(colors.darkGray.goLighter)}`}
                                        size="14px"
                                        icon={faCircle}
                                      />
                                    </Flex>
                                  )
                              }
                              <Text>{days.dayNumber}</Text>
                            </Box>
                          )
                        }
                      </Td>
                    );
                  })}
                </Tr>
              );
            })
          }
        </Tbody>
      </Table>
      <Box fontWeight="bold">
        Presença:
        <Box
          display="inline"
          color={colors.secondary.keep}
        >
          {` ${month.current.days.length}/${month.current.noOfDays} `}
        </Box>
        dias.
      </Box>
    </Box>
  );
}
