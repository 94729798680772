import {
  Box, Button, Circle, Divider, Flex, HStack, Image,
  ModalBody, ModalFooter, ModalHeader, Text, Tooltip, useDisclosure,
} from '@chakra-ui/react';
import { faCheckCircle, faMinusCircle, faPlusCircle, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { useAddOrRemoveFlashcardFromDeck, useFlashcardsCRUD } from '../../../../api/decks';
import { FlashcardType } from '../../../../api/flashcards';
import { useDefaultStyles } from '../../../../hooks/useDefaultStyles';
import useFirebaseStorageImg from '../../../../hooks/useFirebaseStorageImg';
import ConfirmModal from '../../../../lib/components/ConfirmModal';
import { Loading } from '../../../../lib/components/Loading';
import { ZoomImg } from '../../../../lib/components/ZoomImg';
import { formatName } from '../../../../utils/forumUtils';
import { FlashcardButton } from '../../../_components/FlashcardButton.component';
import { FlashcardsCreationContext } from '../../contexts/FlashcardsCreation.context';

type FlashcardViewerFaceType = {
  data?: FlashcardType;
  face: 'front' | 'back';
  setFlip: React.Dispatch<React.SetStateAction<boolean>>;
  onClose?: () => void,
}

export const FlashcardViewerFace: FC<FlashcardViewerFaceType> = ({
  data, face, setFlip, onClose,
}: FlashcardViewerFaceType) => {
  const storage = useFirebaseStorageImg();
  const getImage = useCallback((uri: string) => storage.getURL(uri), [storage]);
  const { colors, hueRotate } = useDefaultStyles();
  const { refetch, tab, resetCurrContentSearchBar } = useContext(FlashcardsCreationContext);
  const { addFlashcardToDeck, removeFlashcardFromDeck } = useAddOrRemoveFlashcardFromDeck();
  const { handleDeleteFlashcard } = useFlashcardsCRUD();
  const [loadingFCActions, setLoadingFCActions] = useState(false);
  const showAddedTag = data && (data.ownFlashcard || data.alreadyAdded) && face === 'front';

  useEffect(() => setFlip(false), [setFlip]);
  const {
    onOpen: onConfirmModalOpen,
    onClose: onConfirmModalClose,
    isOpen: isConfirmModalOpen,
  } = useDisclosure();

  const onDeleteFlashcardClick = useCallback(async () => {
    if (data) {
      const { _id } = data;
      setLoadingFCActions(true);
      await handleDeleteFlashcard({ _id });
      if (refetch) {
        await refetch({ targets: 'all' });
      }
      if (onClose) {
        onClose();
      }
      resetCurrContentSearchBar();
      setLoadingFCActions(false);
    }
  }, [data, handleDeleteFlashcard, onClose, refetch, resetCurrContentSearchBar]);

  const onAddFlashcardToDeckClick = useCallback(async () => {
    if (data) {
      const { _id } = data;
      setLoadingFCActions(true);
      await addFlashcardToDeck({ _id });
      if (refetch) {
        await refetch({ targets: ['community-flashcards', 'user-decks', 'user-flashcards'] });
      }
      if (onClose) {
        onClose();
      }
      resetCurrContentSearchBar();
      setLoadingFCActions(false);
    }
  }, [addFlashcardToDeck, data, onClose, refetch, resetCurrContentSearchBar]);

  const onRemoveFlashcardFromDeckClick = useCallback(async () => {
    if (data) {
      const { _id } = data;
      setLoadingFCActions(true);
      await removeFlashcardFromDeck({ _id });
      if (refetch) {
        await refetch({ targets: ['community-flashcards', 'user-decks', 'user-flashcards'] });
      }
      if (onClose) {
        onClose();
      }
      resetCurrContentSearchBar();
      setLoadingFCActions(false);
    }
  }, [data, onClose, refetch, removeFlashcardFromDeck, resetCurrContentSearchBar]);

  const buttonProps = useMemo(() => {
    if (data) {
      const { ownFlashcard, alreadyAdded } = data;
      if (ownFlashcard) {
        return {
          onClick: onConfirmModalOpen,
          icon: faTrash,
          label: 'Excluir',
          bgColor: colors.red.keep,
        };
      }
      if (alreadyAdded || tab === 'meus-baralhos') {
        return {
          onClick: onRemoveFlashcardFromDeckClick,
          icon: faMinusCircle,
          label: 'Remover dos salvos',
          bgColor: colors.red.keep,
        };
      }
      return {
        onClick: onAddFlashcardToDeckClick,
        icon: faPlusCircle,
        label: 'Salvar',
        bgColor: colors.green.keep,
      };
    }
    return undefined;
  }, [colors.green.keep, colors.red.keep, data, onAddFlashcardToDeckClick,
    onConfirmModalOpen, onRemoveFlashcardFromDeckClick, tab]);

  return (
    <Flex
      bgColor={colors.primary.goALittleLighter}
      flexFlow="column"
      borderRadius={20}
      overflow="hidden"
      p={4}
      w="100%"
      h="100%"
    >
      <ConfirmModal
        onDecline={onConfirmModalClose}
        isOpen={isConfirmModalOpen}
        confirmText="Excluir"
        loading={loadingFCActions}
        loadingText="Excluindo..."
        declineText="Cancelar"
        onConfirm={onDeleteFlashcardClick}
        title="Excluir flashcard?"
        subtitle="Tem certeza que deseja excluir este flashcard para todos? Esta ação é irreversível!"
      />
      <ModalHeader
        pb={0}
        bgColor={colors.primary.goALittleLighter}
        w="100%"
        flex={1}
      >
        <Flex justify="space-between">
          <Image filter={hueRotate()} w="80px" src="/images/flash-card.svg" alt="flash" />
          <Circle
            align="center"
            justify="center"
            bgColor={colors.secondary.keep}
            color={colors.white.keep}
            size={10}
            border="2px solid white"
          >
            <Text fontSize={String(data?.index).length >= 3 ? 'xs' : 'md'}>
              {data?.index !== undefined ? data.index + 1 : ''}
            </Text>
          </Circle>
        </Flex>
      </ModalHeader>
      <ModalBody align="center" flex={8}>
        <Flex flexFlow="column" h="100%">
          <Box>
            <Text noOfLines={1} color={colors.lighter.keep}>
              {data?.prefix}
            </Text>
            <Divider mb={5} />
          </Box>
          <Flex
            h={230}
            px={2}
            flexFlow="column"
            mb={3}
            overflowY="auto"
            color={colors.lighter.keep}
          >
            <ReactMarkdown
              transformImageUri={getImage}
              // eslint-disable-next-line no-undef
              renderers={{ image: (props): JSX.Element => <ZoomImg {...props} /> }}
              className="markdown-body"
            >
              {face === 'front' ? data?.front || '' : data?.back || ''}
            </ReactMarkdown>
          </Flex>
          <Divider />
        </Flex>
      </ModalBody>
      <ModalFooter
        flex={1}
        flexFlow="column"
        w="100%"
        pb={0}
      >
        <HStack w="100%">
          {
            buttonProps && face === 'front' && (
              <Tooltip label={buttonProps.label}>
                <Button
                  color={colors.white.keep}
                  bgColor={buttonProps.bgColor}
                  onClick={buttonProps.onClick}
                  fontSize="lg"
                  disabled={loadingFCActions}
                >
                  {
                    loadingFCActions ? (
                      <Loading />
                    ) : (
                      <FontAwesomeIcon icon={buttonProps.icon} />
                    )
                  }
                </Button>
              </Tooltip>
            )
          }
          <FlashcardButton
            autoFocus
            bgColor={colors.secondary.keep}
            label={face === 'front' ? 'Resposta' : 'Pergunta'}
            onClick={() => setFlip(prev => !prev)}
          />
        </HStack>
        <Flex spacing={3} w="100%" justify="space-between" align="center" mt={2}>
          {
            showAddedTag
            && (
              <HStack color={colors.green.keep} spacing={1} fontSize="xs">
                <Box>
                  <FontAwesomeIcon icon={faCheckCircle} />
                </Box>
                <Text>
                  Adicionado
                </Text>
              </HStack>
            )
          }
          {
            (face === 'back' || !showAddedTag) && (
              <HStack color={colors.lighter.keep} spacing={1} fontSize="xs">
                <Box>
                  <FontAwesomeIcon icon={faPlusCircle} />
                </Box>
                <Text>
                  {data?.timesAdded}
                </Text>
              </HStack>
            )
          }
          {
            face === 'front' && (
              <Text
                fontSize="xs"
                color={colors.lighter.keep}
              >
                {`Criador: ${formatName(data?.createdBy?.name)}`}
              </Text>
            )
          }
          {
            face === 'back' && (
              <Text
                fontSize="xs"
                color={colors.lighter.keep}
              >
                {`Criado ${moment(data?.createdAt).fromNow()}`}
              </Text>
            )
          }
        </Flex>
      </ModalFooter>
    </Flex>
  );
};
