import { Button, ButtonProps } from '@chakra-ui/react';
import { FC } from 'react';
import { useDefaultStyles } from '../../hooks/useDefaultStyles';

interface FlashcardButtonInterface extends ButtonProps {
  bgColor: string,
  label: string,
  onClick: () => void,
  disabled?: boolean,
  isLoading?: boolean,
}

export const FlashcardButton: FC<FlashcardButtonInterface> = ({
  bgColor, onClick, disabled = false, isLoading = false, label, ...props
}: FlashcardButtonInterface) => {
  const { colors } = useDefaultStyles();
  return (
    <Button
      flex={1}
      bgColor={bgColor}
      color={colors.lighter.keep}
      onClick={onClick}
      isLoading={isLoading}
      disabled={disabled}
      _hover={{
        filter: 'brightness(0.9)',
      }}
      transition="all 0.3s"
      {...props}
    >
      {label}
    </Button>
  );
};
