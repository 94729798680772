import { Flex, Text } from '@chakra-ui/react';
import React, { FC } from 'react';
import { useDefaultStyles } from '../../hooks/useDefaultStyles';

type SquareSwitchInput = {
  isSelected: boolean,
  setIsSelected: (value: React.SetStateAction<boolean>) => void
}

export const SquareSwitch: FC<SquareSwitchInput> = ({
  isSelected,
  setIsSelected,
}) => {
  const { colors } = useDefaultStyles();
  return (
    <Flex
      align="flex-start"
      justify="center"
      fontSize="sm"
      flexFlow="column"
      gridGap={3}
    >
      <Text color={colors.secondary.goALittleDarker} fontSize="sm">Modalidade</Text>
      <Flex>
        <Flex
          transition="all .8s"
          p={2}
          bgColor={isSelected ? colors.secondary.keep : colors.lightGray.goAlmostFullDarker}
          color={isSelected ? colors.white.keep : colors.darkerGray.goLighter}
          as="button"
          onClick={() => setIsSelected(true)}
        >
          <Text>Aristo</Text>
        </Flex>
        <Flex
          transition="all .6s"
          p={2}
          bgColor={!isSelected ? colors.secondary.keep : colors.lightGray.goAlmostFullDarker}
          color={!isSelected ? colors.white.keep : colors.darkerGray.goLighter}
          as="button"
          flexShrink={0}
          onClick={() => setIsSelected(false)}
        >
          <Text>Meus Baralhos</Text>
        </Flex>
      </Flex>
    </Flex>
  );
};
