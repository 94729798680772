import { useCallback } from 'react';
import {
  gql, QueryResult, useMutation, useQuery,
} from '@apollo/client';
import { DocTypes } from './agenda/agenda';

interface RateOneInput {
  document: string;
  type: DocTypes | 'comment';
}

interface RateOneOutput {
  rateOne: number;
}

const RATE_ONE = gql`
  query RateOne (
    $document: ObjectId!
    $type: ContentType!
  ) {
    rateOne (
      document: $document
      type: $type
    )
  }
`;

interface RatingInput {
  document: string;
  type: DocTypes | 'comment';
  rate: number;
}

interface RateCreateOrUpdateInput {
  input: RatingInput;
}

interface RateCreateOrUpdateOutput {
  rateCreateOrUpdate: number;
}

const RATE_CREATE_OR_UPDATE = gql`
  mutation RateCreateOrUpdate ($input: RatingInputType!) {
    rateCreateOrUpdate (input: $input)
  }
`;

interface UseRatingsInterface {
  onRateCreateOrUpdate: (props: RatingInput) => Promise<void>;
}

export function useRatings(): UseRatingsInterface {
  const [createOrUpdate] = useMutation<RateCreateOrUpdateOutput, RateCreateOrUpdateInput>(RATE_CREATE_OR_UPDATE);
  const handleRateCreateOrUpdate = useCallback(async ({
    document, rate, type,
  }: RatingInput) => {
    await createOrUpdate({
      variables: {
        input: {
          document,
          rate,
          type,
        },
      },
    });
  }, [createOrUpdate]);

  return {
    onRateCreateOrUpdate: handleRateCreateOrUpdate,
  };
}

export function useRateOne({ type, document }: RateOneInput): QueryResult<RateOneOutput, RateOneInput> {
  return useQuery<RateOneOutput, RateOneInput>(RATE_ONE, {
    variables: {
      type,
      document,
    },
  });
}
