import { SlideFade } from '@chakra-ui/react';
import { FC, useContext } from 'react';
import { FlashcardType } from '../../../../api/flashcards';
import { FlashcardsCreationContext } from '../../contexts/FlashcardsCreation.context';
import { FlipEffect } from '../../transitions/FlipEffect';
import { DELAY_INDEX } from './MiniDeck';
import { MiniFlashcardFace } from './MiniFlashcardFace';

type MiniFlashcardType = {
  flashcardData?: FlashcardType;
  onClick: () => void;
  index: number;
}

export const MiniFlashcard: FC<MiniFlashcardType> = ({
  flashcardData, index, onClick,
}: MiniFlashcardType) => {
  const { CARDS_LIMIT } = useContext(FlashcardsCreationContext);
  return (
    <SlideFade in offsetX="-200px" delay={DELAY_INDEX * ((index - 1) % CARDS_LIMIT)}>
      <FlipEffect
        front={<MiniFlashcardFace index={index} face="front" data={flashcardData} />}
        back={<MiniFlashcardFace index={index} face="back" data={flashcardData} />}
        onClick={onClick}
        flipMode="hover"
        dimensions={{ w: 140, h: 170, m: 2 }}
      />
    </SlideFade>
  );
};
