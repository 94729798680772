import { Box, BoxProps, Text } from '@chakra-ui/react';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC, useMemo, useState } from 'react';
import { DiagnosticThemeRowInterface } from '../../../api/diagnosis';
import { useDefaultStyles, useRGBColor } from '../../../hooks/useDefaultStyles';
import { useDevice } from '../../../hooks/useDevice';
import useTableFilter from '../../../hooks/useTableFilter';
import TableHeaderComponent from '../../../lib/components/TableHeader.component';
import TableRowComponent from '../../../lib/components/TableRow.component';
import { format } from '../../../utils/format';
import { getThemeIncidenceLevel } from '../../../utils/getThemeDifficulty';
import { TableVisibleLinesHandler } from '../../aristo-compass/_components/TableVisibleLinesHandler';
import DifficultyIcon from '../../_components/DifficultyIcon';
import { TableStyleType } from './DiagnosticAreaTable';
import { DiagnosticTableMobileNav } from './DiagnosticTableMobileNav';

interface DiagnosticThemesTableType extends BoxProps {
  themes?: DiagnosticThemeRowInterface[],
  tableStyle?: TableStyleType;
}

export const DiagnosticThemesTable: FC<DiagnosticThemesTableType> = ({
  themes,
  tableStyle = 'diagnostic',
  ...props
}) => {
  const device = useDevice();
  const { colors } = useDefaultStyles();
  const { toRGB } = useRGBColor();
  const [tableStep, setTableStep] = useState(0);
  const [noOfVisibleLines, setNoOfVisibleLines] = useState(5);
  const NUMBER_OF_TABLE_STEPS = 2;
  const { dataFiltered: crFiltered, filter, handleFilter } = useTableFilter(
    [...(themes ?? [])]
      .sort((a, b) => {
        return (a.priorityIndex > b.priorityIndex) ? -1 : 1;
      })
      .map((e, index) => {
        const _leaf = e.leaf.split('|');
        const len = _leaf.length;

        return {
          ...e,
          leaf: `${_leaf[len - 1]} - ${_leaf[len - 2]}`,
          position: index + 1,
          alertColor: index < 10 ? toRGB(colors.red.keep) : toRGB(colors.yellow.keep),
        };
      }),
  );

  const crFilteredSlice = useMemo(() => {
    return crFiltered.slice(0, noOfVisibleLines);
  }, [crFiltered, noOfVisibleLines]);

  return (
    <>
      <DiagnosticTableMobileNav
        noOfSteps={NUMBER_OF_TABLE_STEPS}
        tableStep={tableStep}
        setTableStep={setTableStep}
      />
      <Box
        color={tableStyle === 'diagnostic' ? 'aristo.600' : ''}
        maxWidth={1120}
        width="100%"
        // margin="0 auto"
        bgColor="white"
        // borderRadius="1rem"
        marginBottom="2rem"
        {...props}
      >
        <TableHeaderComponent
          paddingLeft=".5rem"
          color={tableStyle === 'diagnostic' ? 'aristo.500' : 'gray.600'}
          bgColor={tableStyle === 'diagnostic' ? '#8CCAFF' : ''}
          fontSize={tableStyle === 'diagnostic' ? undefined : 13}
          headers={[
            {
              key: 'leaf',
              value: 'Tema',
              flex: 5,
              alignItems: 'left',
              title: 'Tema',
            },
            {
              key: 'position',
              flex: { base: 2, md: 1 },
              textAlign: 'center',
              value: 'Prioridade',
              title: 'Ordem de prioridade de temas para estudo/revisão. Considera inciências e desempenho.',
              showOnMobile: tableStep === 0,
            },
            {
              key: 'relevance',
              value: 'Incidência',
              flex: { base: 2, md: 1 },
              textAlign: 'center',
              alignItems: 'center',
              title: 'Nível de incidência do tema nas instituições escolhidas por você.',
              showOnMobile: tableStep === 1,
            },
            {
              key: 'performance',
              value: 'Desempenho',
              flex: { base: 2, md: 1 },
              alignItems: 'left',
              title: 'Acertos / Total de questões (% de acertos).',
              showOnMobile: tableStep === 2,
            },
          ]}
          handleFilter={handleFilter}
          filter={filter}
        />
        {
          crFilteredSlice.map((item, i) => {
            return (
              <TableRowComponent
                key={item.leaf}
                tableStyle={tableStyle}
                lineTooltip="Os itens que você mais precisa prestar atenção ficam marcados de vermelho"
                paddingLeft={device === 'mobile' ? '1rem' : '.8rem'}
                rowBackgroundColorWhite={i % 2}
                rowData={[
                  {
                    key: 'leaf',
                    value: (
                      <Text
                        title={item.leaf}
                        ellipsis
                      >
                        {item.leaf}
                      </Text>
                    ),
                    flexSpan: 5,
                    align: 'left',
                  },
                  {
                    key: 'position',
                    value: (
                      <>
                        <span
                          style={{
                            color: item.isPriority ? item.alertColor : '#8a9b8e',
                            display: 'inline',
                          }}
                        >
                          {`${item.position}° `}
                        </span>
                        <FontAwesomeIcon
                          icon={faExclamationCircle}
                          color={item.isPriority ? item.alertColor : '#8a9b8e'}
                        />
                      </>),
                    align: 'center',
                    flexSpan: device === 'mobile' ? 2 : 1,
                    showOnMobile: tableStep === 0,
                  },
                  {
                    key: 'relevance',
                    value: <DifficultyIcon difficulty={getThemeIncidenceLevel(item.relevance)} />,
                    align: 'center',
                    showOnMobile: tableStep === 1,
                    flexSpan: device === 'mobile' ? 2 : 1,
                  },
                  {
                    key: 'performance',
                    align: 'left',
                    value: (
                      <p>
                        {`${item.stats.hits} / ${item.stats.total} (${format(item.totalPerformance * 100)}%)`}
                      </p>
                    ),
                    showOnMobile: tableStep === 2,
                    flexSpan: device === 'mobile' ? 2 : 1,
                  },
                ]}
              />
            );
          })
        }
        <TableVisibleLinesHandler
          noOfVisibleLines={noOfVisibleLines}
          setNoOfVisibleLines={setNoOfVisibleLines}
          maxLen={crFiltered.length}
        />
      </Box>
    </>
  );
};
