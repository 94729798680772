import React, {
  FC, useContext, useMemo,
} from 'react';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Box } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getActivityIndex } from '../../../hooks/getActivityIndex';
import Flex from '../../../lib/components/Flex';
import Text from '../../../lib/components/Text';
import { AgendaContext } from '../Agenda.context';
import { useGetExtraExam } from '../../../api/extra-activities/extra-exams';
import { ExtraHeader } from './_components/ExtraHeader';
import { Questions } from '../types/Questions.component';

const ExtraExamContainer: FC = () => {
  const agendaCtx = useContext(AgendaContext);

  const id = getActivityIndex();

  const actualId = useMemo(() => {
    const qs = agendaCtx.extras.activityExamExtra;

    const index = qs?.findIndex(x => x._id === id) || 0;

    if (qs && qs.length > 0 && index !== -1) {
      return qs[index]._id;
    }

    if (qs && qs.length > 0) {
      return qs[0]._id;
    }

    return '';
  }, [agendaCtx.extras.activityExamExtra, id]);

  if (agendaCtx.extrasLoading) {
    return (
      <Flex align="center" justify="center" style={{ minHeight: 400 }}>
        <FontAwesomeIcon icon={faSpinner} spin size="6x" className="color-dark-gray" />
      </Flex>
    );
  }

  return (
    <Box maxW={900} m="0 auto">
      <ExtraHeader
        title="Prova [Extra]"
      />
      <ExtraExam id={actualId} />
    </Box>
  );
};

interface ExtraExamProps {
  id: string;
}

const ExtraExam: FC<ExtraExamProps> = ({ id }) => {
  const { loading, error, data } = useGetExtraExam({
    id,
  });

  if (loading) {
    return (
      <Flex align="center" justify="center" style={{ minHeight: 400 }}>
        <FontAwesomeIcon icon={faSpinner} spin size="5x" className="color-dark-gray" />
      </Flex>
    );
  }

  if (!error
    && !loading
    && data
    && data.getExamExtraActivityDetails
    && data.getExamExtraActivityDetails.questions.length > 0
  ) {
    const { questions, preChoice, finished } = data.getExamExtraActivityDetails;
    return (
      <Questions
        model="exams"
        activityId={id}
        data={questions}
        serverPreChoices={preChoice}
        finished={finished}
      />
    );
  }

  return (
    <Text>Nenhuma atividade disponível para hoje!</Text>
  );
};

export default ExtraExamContainer;
