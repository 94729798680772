import { Collapse, Flex, Text } from '@chakra-ui/react';
import React, { FC, ReactNode, useCallback } from 'react';
import { useDefaultStyles } from '../../../../hooks/useDefaultStyles';

type DrawerTopicType = {
  title: string,
  children: ReactNode,
  topicIndex: number,
  currOpenIndex: number,
  setCurrOpenIndex: React.Dispatch<React.SetStateAction<number>>,
}

export const DrawerTopic: FC<DrawerTopicType> = ({
  title,
  children,
  topicIndex,
  currOpenIndex,
  setCurrOpenIndex,
}: DrawerTopicType) => {
  const { colors } = useDefaultStyles();
  const isOpen = topicIndex === currOpenIndex;

  const onClick = useCallback(() => {
    if (isOpen) {
      setCurrOpenIndex(-1);
    } else {
      setCurrOpenIndex(topicIndex);
    }
  }, [isOpen, setCurrOpenIndex, topicIndex]);

  return (
    <Flex
      w="100%"
      flexFlow="column"
      position="relative"
      mb={isOpen ? 4 : 2}
    >
      <Text
        as="button"
        _hover={{
          textDecoration: 'underline',
        }}
        textDecoration={isOpen ? 'underline' : ''}
        textAlign="left"
        variant="link"
        color={colors.secondary.goFullLighter}
        onClick={onClick}
        mb={isOpen ? 4 : undefined}
      >
        {title}
      </Text>
      <Collapse in={isOpen} animateOpacity>
        {children}
      </Collapse>
    </Flex>
  );
};
