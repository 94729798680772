import {
  Modal, ModalBody, ModalContent, ModalHeader, ModalOverlay, Box, Image, Text, IconButton,
} from '@chakra-ui/react';
import { FC, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { PrimaryButton } from '../../../lib/components/PrimaryButton';
import { useOptimizerApi } from '../../../hooks/useOptimizerApi';

export const NpsModalShow: FC<{ open: boolean, setOpen: () => void}> = ({
  open, setOpen,
}) => {
  const {
    completeSurveyTemp,
    skipSurveyTemp,
  } = useOptimizerApi();

  const onClose = useCallback(async () => {
    skipSurveyTemp();
    setOpen();
  }, [skipSurveyTemp, setOpen]);

  const onComplete = useCallback(async () => {
    completeSurveyTemp();
    window.open('https://forms.office.com/r/L6xpN6AXni', '_blank');
    setOpen();
  }, [completeSurveyTemp, setOpen]);

  return (
    <>
      <Modal isOpen={open} onClose={onClose} isCentered scrollBehavior="inside" closeOnOverlayClick={false}>
        <ModalOverlay />
        <ModalContent mx={2} borderRadius={10} overflow="hidden">
          <ModalHeader
            py={2}
            px={0}
            display="flex"
          >
            <Box width={50}><div /></Box>
            <Box flex={1} alignItems="center" justifyContent="center" display="flex">
              <Image w="210px" src="/images/nps.png" alt="npsImage" />
            </Box>
            <Box width={50}>
              <IconButton
                icon={<FontAwesomeIcon icon={faTimes} />}
                aria-label="Close btn"
                onClick={onClose}
              />
            </Box>
          </ModalHeader>
          <ModalBody>
            <Text textAlign="center" fontWeight="bold" fontSize="lg" mb={4}>
              Sua opinião é muito importante para nós!
            </Text>
            <Text textAlign="center" mb={3}>
              Estamos sempre buscando melhorar a sua experiência na Aristo, e para isso,
              gostaríamos de saber o que você pensa.
            </Text>
            <Text textAlign="center" mb={6}>
              Ajude-nos respondendo a uma breve pesquisa de satisfação! Sua opinião faz toda a diferença.
            </Text>
            <Box textAlign="center" mb={6}>
              <PrimaryButton
                borderRadius={18}
                size="lg"
                onClick={onComplete}
              >
                Avalie sua experiência
              </PrimaryButton>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
