import { Box, Button, Flex, ScaleFade, useColorMode, useDisclosure } from '@chakra-ui/react';
import { faAngleDown, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { endOfMonth, startOfMonth, subDays } from 'date-fns';
import moment from 'moment';
import { useContext, useEffect, useMemo, useState } from 'react';
import DatePicker from 'react-datepicker';
import {
  useGetExamPerformance,
  useGetFlashcardsCount,
  useGetPerformanceByArea,
  useGetTrophiesByDateRange,
  usePerformanceAndPriorityByThema,
  useStudentPresences,
} from '../../../api/student-report';
import { useDefaultStyles } from '../../../hooks/useDefaultStyles';
import { useDevice } from '../../../hooks/useDevice';
import { PrivateContext } from '../../../Private.context';
import { formatDate } from '../../../utils/formatDate';
import { AreasPerformances } from '../_components/AreasPerformances';
import { ExamsPerformanceCard } from '../_components/ExamsPerformanceCard';
import { FlashcardsReport } from '../_components/FlashcardsReport';
import { FrequencyCalendar } from '../_components/FrequencyCalendar';
import { LoadingBar } from '../_components/LoadingBar.component';
import { MonthDiagnosis } from '../_components/MonthDiagnosis';
import { StudentReportDrawer } from '../_components/StudentReportDrawer';
import { TrophiesReport } from '../_components/TrophiesReport';
import { QuestionCountByTag } from './questions-performance/QuestionCountByTag';

export function StudentReport() {
  const { frequencyArray, frequencyLoading } = useStudentPresences();
  const { profile } = useContext(PrivateContext);
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const { selectBorder } = useDefaultStyles();
  const startOfCurrMonth = startOfMonth(selectedDate);
  const endOfCurrMonth = endOfMonth(selectedDate);
  const endOfPrevMonth = subDays(startOfCurrMonth, 1);
  const startOfPrevMonth = startOfMonth(endOfPrevMonth);
  const device = useDevice();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [questionsLoading, setQuestionsLoading] = useState(true);
  const [finishedTimePad, setFinishedTimePad] = useState(false);
  const { colorMode } = useColorMode();

  const monthBounds = {
    curr: {
      dateStart: formatDate(startOfCurrMonth),
      dateEnd: formatDate(endOfCurrMonth),
    },
    prev: {
      dateStart: formatDate(startOfPrevMonth),
      dateEnd: formatDate(endOfPrevMonth),
    },
  };

  const {
    data: currAreasData,
    loading: currAreasLoading,
  } = useGetPerformanceByArea(monthBounds.curr);
  const {
    data: prevAreasData,
    loading: prevAreasLoading,
  } = useGetPerformanceByArea(monthBounds.prev);
  const {
    data: currFCData,
    loading: currFCLoading,
  } = useGetFlashcardsCount(monthBounds.curr);
  const {
    data: prevFCData,
    loading: prevFCLoading,
  } = useGetFlashcardsCount(monthBounds.prev);
  const {
    data: currExamsData,
    loading: currExamsLoading,
  } = useGetExamPerformance(monthBounds.curr);
  const {
    data: prevExamsData,
    loading: prevExamsLoading,
  } = useGetExamPerformance(monthBounds.prev);
  const {
    data: currTrophiesData,
    loading: currTrophiesLoading,
  } = useGetTrophiesByDateRange(monthBounds.curr);
  const {
    data: prevTrophiesData,
    loading: prevTrophiesLoading,
  } = useGetTrophiesByDateRange(monthBounds.prev);
  const {
    data: performanceData,
    loading: performanceLoading,
    refetch: refetchPerformanceData,
    priorityLeaves,
    totalHits,
    totalQuestions,
  } = usePerformanceAndPriorityByThema(monthBounds.curr);

  const loadingQueue = useMemo(() => {
    return [
      {
        label: 'Troféus',
        loading: currTrophiesLoading && prevTrophiesLoading,
      },
      {
        label: 'Questões',
        loading: questionsLoading,
      },
      {
        label: 'Flashcards',
        loading: currFCLoading && prevFCLoading,
      },
      {
        label: 'Frequência',
        loading: frequencyLoading,
      },
      {
        label: 'Desempenho por tema',
        loading: performanceLoading,
      },
      {
        label: 'Desempenho por dificuldade',
        loading: currExamsLoading && prevExamsLoading,
      },
      {
        label: 'Desempenho por grande área',
        loading: currAreasLoading && prevAreasLoading,
      },
    ];
  }, [currAreasLoading, currExamsLoading, currFCLoading,
    currTrophiesLoading, frequencyLoading, performanceLoading,
    prevAreasLoading, prevExamsLoading, prevFCLoading, prevTrophiesLoading,
    questionsLoading]);

  const isLoading = questionsLoading || currExamsLoading || prevExamsLoading || currTrophiesLoading
    || prevTrophiesLoading || frequencyLoading || performanceLoading || currAreasLoading || currAreasLoading
    || prevAreasLoading || currFCLoading || prevFCLoading;

  useEffect(() => {
    if (!isLoading) {
      setTimeout(() => {
        setFinishedTimePad(true);
      }, 2000);
    }
  }, [isLoading]);

  return (
    <Box maxW={900}>
      <Flex align="center" justify="center" w="100%">
        <Button
          onClick={onOpen}
          leftIcon={<FontAwesomeIcon icon={faQuestionCircle} />}
          display={(isLoading || !finishedTimePad) ? 'none' : undefined}
        >
          Saiba mais
        </Button>
        <Flex {...selectBorder} my="auto" position="relative">
          <DatePicker
            placeholderText="Escolha um mês"
            selected={selectedDate}
            minDate={moment(profile.createdAt).subtract(1, 'month').toDate()}
            maxDate={new Date()}
            locale="pt-BR"
            onChange={date => {
              if (date) {
                setSelectedDate(date);
              }
            }}
            clearButtonClassName="date-picker-close-button"
            className={`date-picker-main date-picker-report ${colorMode === 'light'
              ? 'date-picker-light'
              : 'date-picker-dark'}`}
            dateFormat="LLLL/yy"
            showMonthYearPicker
            showFullMonthYearPicker
          />
          <FontAwesomeIcon
            style={{ right: '15px', position: 'absolute', pointerEvents: 'none' }}
            icon={faAngleDown}
          />
        </Flex>
      </Flex>
      <LoadingBar
        loadingQueue={loadingQueue}
        hide={!isLoading && finishedTimePad}
        setFinishedTimePad={setFinishedTimePad}
      />
      <ScaleFade in={!isLoading && finishedTimePad} initialScale={0.3}>
        <Flex
          flexFlow="column"
          maxW={900}
          margin=".5rem auto"
          gridGap={3}
          display={(!isLoading && finishedTimePad) ? undefined : 'none'}
        >
          <StudentReportDrawer onClose={onClose} isOpen={isOpen} />
          <Flex style={{ gap: 12 }} flexFlow={device === 'mobile' ? 'column' : 'row'}>
            <QuestionCountByTag
              anyDayOfCurrMonth={selectedDate}
              setQuestionsLoading={setQuestionsLoading}
            />
            <FlashcardsReport
              currFCData={currFCData}
              prevFCData={prevFCData}
            />
          </Flex>
          <MonthDiagnosis
            result={performanceData}
            totalHits={totalHits}
            totalQuestions={totalQuestions}
            loading={performanceLoading}
            priorityLeaves={priorityLeaves}
            refetch={refetchPerformanceData}
          />
          <Flex style={{ gap: 12 }} flexFlow={device === 'mobile' ? 'column' : 'row'}>
            <ExamsPerformanceCard
              currExamsData={currExamsData}
              prevExamsData={prevExamsData}
              loading={currExamsLoading || prevExamsLoading}
            />
            <TrophiesReport
              currTrophiesData={currTrophiesData}
              prevTrophiesData={prevTrophiesData}
              loading={currTrophiesLoading || prevTrophiesLoading}
            />
          </Flex>
          <Flex style={{ gap: 12 }} flexFlow={device === 'mobile' ? 'column' : 'row'}>
            <FrequencyCalendar
              anyDayOfCurrMonth={selectedDate}
              frequencyArray={frequencyArray}
              loading={frequencyLoading}
            />
            <AreasPerformances
              currAreasData={currAreasData}
              prevAreasData={prevAreasData}
            />
          </Flex>
        </Flex>
      </ScaleFade>
    </Box>
  );
}
