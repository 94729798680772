import React, {
  FC, useState, useEffect, useCallback, useMemo, useContext,
} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Flex,
  HStack,
  IconButton,
  Modal,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useColorMode,
  useDisclosure,
} from '@chakra-ui/react';
import {
  faArrowLeft, faGraduationCap,
} from '@fortawesome/free-solid-svg-icons';
import { useHistory, useLocation } from 'react-router-dom';
import DifficultyBlocks from '../DifficultyBlocks';
import { PreChoiceInterface } from '../../../api/agenda/mock-exam';
import TagQuestionCanceled from '../TagQuestionCanceled';
import { PrimaryButton } from '../../../lib/components/PrimaryButton';
import FavoriteBtn from '../../agenda/_components/FavoriteBtn.component';
import { MoreOptions } from '../MoreOptions';
import { Question } from '../../../api/agenda/questions';
import { Comment } from '../Comment';
import { useDevice } from '../../../hooks/useDevice';
import { SecondaryButton } from '../../../lib/components/SecondaryButton';
import { QuestionBody } from './QuestionBody';
import { QuestionWrapper } from './QuestionWrapper';
import { Divider } from '../../../lib/components/Divider';
import { LikeDislike } from '../LikeDislike';
import { ChoiceRadio } from '../../../lib/components/ChoiceRadio.component';
import { useDefaultStyles } from '../../../hooks/useDefaultStyles';
import { CardsButton } from '../../flashcards-creation/_components/Buttons/CardsButton';
import { WholeAppContext } from '../../agenda/WholeApp.context';
import { ChoiceCutAndProportionsCircle } from './ChoiceCutAndProportionsCircle';

interface QuestionCardProps {
  data: Question;
  answer: number;
  disabled?: boolean;
  index: number;
  disableCommunication?: boolean;
  onSelectChoice?: (choice: number) => Promise<boolean | void>;
  onAnswerClick?: (choice: number) => Promise<void>;
  preChoice?: PreChoiceInterface;
  showResults?: boolean;
  showComment?: boolean;
  showCommentElements?: boolean;
  showMoreOptions?: boolean;
  isExam?: boolean;
  bgColorProp?: string;
  colorProp?: string;
  onPrev?: () => void,
  onNext?: () => void,
  isNavigatorVisible?: boolean,
  isFullscreen?: boolean,
  isFavoritesModel?: boolean,
  setSelectedChoice: React.Dispatch<React.SetStateAction<number>>;
  selectedChoice: number,
}

const QuestionCard: FC<QuestionCardProps> = ({
  answer,
  disableCommunication = false,
  data: {
    _id: id,
    answersProportion,
    choices,
    correctChoice,
    difficulty,
    institution,
    uf: state,
    year,
    body,
    classification,
    comment,
  },
  disabled = false,
  isExam,
  isFavoritesModel,
  setSelectedChoice,
  selectedChoice,
  index,
  onSelectChoice,
  onAnswerClick,
  preChoice,
  showResults = true,
  showComment = false,
  showCommentElements = true,
  showMoreOptions = true,
  bgColorProp,
  colorProp,
  onPrev,
  isNavigatorVisible,
  isFullscreen,
  onNext,
}) => {
  /**
   * States
   */
  const { isOpen: isCommentOpen, onOpen, onClose, onToggle } = useDisclosure();
  /** Hooks */
  const device = useDevice();
  const { colorMode } = useColorMode();
  const { colors } = useDefaultStyles();

  /** States */
  const { areShortcutsEnabled } = useContext(WholeAppContext);
  const [chooseLoading, setChooseLoading] = useState(false);
  const [answerLoading, setAnswerLoading] = useState(false);
  const [eliminatedChoices, setEliminatedChoices] = useState<boolean[]>([]);
  const history = useHistory();
  const location = useLocation();
  const disableAnswer = selectedChoice === -1 || showResults || disabled || answerLoading;
  /** Memos */
  const proportions = useMemo(() => {
    if (answersProportion) {
      const total = answersProportion.reduce((ac, item) => ac + item, 0) || 1;
      return answersProportion.map(i => ((i * 100) / total).toFixed(0));
    }
    return undefined;
  }, [answersProportion]);

  /** Callbacks */
  const onCommentOpenMobile = useCallback(() => {
    const search = new URLSearchParams(location.search);
    search.set('showComment', 'true');
    history.push({
      pathname: history.location.pathname,
      search: String(search),
    });
  }, [history, location.search]);

  const onCommentCloseMobile = useCallback(() => {
    const search = new URLSearchParams(location.search);
    search.delete('showComment');
    history.push({
      pathname: history.location.pathname,
      search: String(search),
    });
  }, [history, location.search]);

  const handleChoose = useCallback(async val => {
    if (onSelectChoice) {
      setChooseLoading(true);
      try {
        const onSelect = await onSelectChoice(val);
        if (onSelect) {
          setSelectedChoice(val);
        }
      } catch (err) {
        console.log(err);
      } finally {
        setChooseLoading(false);
      }
    }
  }, [onSelectChoice, setSelectedChoice]);

  const getColor = useCallback((ind: number, tint?: number) => {
    if (showResults) {
      if (correctChoice === ind || correctChoice === -1) {
        return `success.${tint ?? 100}`;
      }
      if (selectedChoice === ind) {
        return `error.${tint ?? 100}`;
      }
    }
    return 'transparent';
  }, [correctChoice, showResults, selectedChoice]);

  const handleAnswer = useCallback(async () => {
    setAnswerLoading(true);
    try {
      if (onAnswerClick) {
        await onAnswerClick(selectedChoice);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setAnswerLoading(false);
      setEliminatedChoices(Array(choices.length).fill(false));
    }
  }, [choices.length, onAnswerClick, selectedChoice]);

  const handleEliminateChoice = useCallback((choiceIndex: number) => {
    setEliminatedChoices(prev => {
      const newArr = [...prev];
      newArr[choiceIndex] = !prev[choiceIndex];
      return newArr;
    });
  }, []);

  useEffect(() => {
    if (preChoice && answer === -1) {
      setSelectedChoice(preChoice.selected);
      return;
    }
    setSelectedChoice(answer);
  }, [answer, id, preChoice, setSelectedChoice]);

  useEffect(() => {
    setEliminatedChoices(Array(choices.length).fill(false));
  }, [choices]);

  useEffect(() => {
    if (!showComment) {
      onClose();
    }
  }, [onClose, showComment]);

  useEffect(() => {
    const search = new URLSearchParams(location.search);
    if (search.get('showComment') === 'true') {
      onOpen();
      return;
    }
    onClose();
  }, [location.search, onClose, onOpen]);

  useEffect(() => {
    const prevOnKeyUp = document.onkeyup;
    if (areShortcutsEnabled) {
      document.onkeyup = async e => {
        if (!(disabled || showResults || answerLoading || chooseLoading || eliminatedChoices[Number(e.key) - 1])) {
          if (e.key === '1' && choices.length > 0 && selectedChoice !== 0) {
            await handleChoose(0);
          } else if (e.key === '2' && choices.length > 1 && selectedChoice !== 1) {
            await handleChoose(1);
          } else if (e.key === '3' && choices.length > 2 && selectedChoice !== 2) {
            await handleChoose(2);
          } else if (e.key === '4' && choices.length > 3 && selectedChoice !== 3) {
            await handleChoose(3);
          } else if (e.key === '5' && choices.length > 4 && selectedChoice !== 4) {
            await handleChoose(4);
          } else if (e.code === 'ControlRight' && !disableAnswer
            && (!isExam && !(selectedChoice === -1 || showResults || disabled))) {
            await handleAnswer();
          }
        }
        if (e.key === 'ArrowLeft' && onPrev) {
          onPrev();
        } else if (e.key === 'ArrowRight' && onNext) {
          onNext();
        } else if (e.code === 'ShiftRight' && showComment) {
          onToggle();
        }

        if (!(proportions && proportions.length > 0 && showResults)) {
          if (e.key === 'q' && selectedChoice !== 0) {
            handleEliminateChoice(0);
          } else if (e.key === 'w' && choices.length > 1 && selectedChoice !== 1) {
            handleEliminateChoice(1);
          } else if (e.key === 'e' && choices.length > 2 && selectedChoice !== 2) {
            handleEliminateChoice(2);
          } else if (e.key === 'r' && choices.length > 3 && selectedChoice !== 3) {
            handleEliminateChoice(3);
          } else if (e.key === 't' && choices.length > 4 && selectedChoice !== 4) {
            handleEliminateChoice(4);
          }
        }
      };
    }
    return () => {
      document.onkeyup = prevOnKeyUp;
    };
  }, [
    answerLoading, areShortcutsEnabled, choices.length, chooseLoading,
    disableAnswer, disabled, eliminatedChoices, handleAnswer, handleChoose,
    handleEliminateChoice, isExam, onNext, onPrev, onToggle, proportions,
    showComment, showResults, selectedChoice]);

  return (
    <>
      <QuestionWrapper
        borderColor={getColor(selectedChoice, 500)}
        bgColor={bgColorProp}
        color={colorProp}
      >
        <Flex flexDir="column">
          {
            showResults && (
              <>
                <Flex align="center" justify="space-between" mb={2}>
                  <Flex flexFlow={{ base: 'column', md: 'row' }}>
                    <Text fontWeight="bold" fontSize="sm" mr={2}>Dificuldade</Text>
                    <DifficultyBlocks difficulty={difficulty} />
                  </Flex>
                  {showMoreOptions && (
                    <HStack>
                      <CardsButton />
                      <LikeDislike
                        document={id}
                        type="question"
                      />
                      <FavoriteBtn
                        document={id}
                        type="question"
                      />
                      <MoreOptions
                        contentId={id}
                        type="question"
                      />
                    </HStack>
                  )}
                </Flex>
                <Divider borderColor={getColor(selectedChoice, 500)} />
              </>
            )
          }
          <QuestionBody
            body={body}
            id={id}
            index={index}
            info={`**${index + 1}. [${institution} - ${state} - ${year}]**`}
          />
          {correctChoice === -1 && showResults ? <TagQuestionCanceled /> : ''}
        </Flex>
        {
          choices.map((choice, i) => {
            const key = `${id}-${i}`;
            return (
              <Box
                py={2}
                px={1}
                mb={1}
                key={key}
                border={colorMode === 'dark' ? 'solid 1px' : undefined}
                borderColor={colorMode === 'dark' ? getColor(i, 400) : undefined}
                backgroundColor={colorMode === 'light' ? getColor(i) : undefined}
                borderRadius={4}
                display="flex"
                direction="row"
                alignItems="center"
              >
                <ChoiceRadio
                  choiceIndex={i}
                  checked={selectedChoice === i}
                  onClick={handleChoose}
                  disabled={disabled || showResults || answerLoading || chooseLoading || eliminatedChoices[i]}
                  eliminated={Boolean(eliminatedChoices[i])}
                >
                  {choice}
                </ChoiceRadio>
                <ChoiceCutAndProportionsCircle
                  chosenIndex={selectedChoice}
                  currIndex={i}
                  onEliminateChoiceClick={() => handleEliminateChoice(i)}
                  proportions={proportions}
                  showResults={showResults}
                  hidePercents={isFullscreen && isNavigatorVisible === false}
                />
              </Box>
            );
          })
        }
        <Flex className="mt-4">
          {(showComment || (isFavoritesModel && selectedChoice !== -1)) && (
            <SecondaryButton
              leftIcon={<FontAwesomeIcon color={colors.secondary.keep} icon={faGraduationCap} />}
              onClick={device === 'mobile' ? onCommentOpenMobile : onToggle}
              disabled={!comment?.body}
              borderColor={colors.secondary.keep}
              color={colors.secondary.keep}
              borderRadius={30}
              fontWeight="normal"
            >
              {!comment?.body ? 'Sem comentário' : 'Comentário Aristo'}
            </SecondaryButton>
          )}
          {
            !isExam && !(selectedChoice === -1 || showResults || disabled) && (
              <PrimaryButton
                ml="auto"
                disabled={disableAnswer}
                onClick={handleAnswer}
                isLoading={answerLoading}
                justifySelf="end"
              >
                Responder
              </PrimaryButton>
            )
          }
        </Flex>
      </QuestionWrapper>
      {device === 'mobile' ? (
        <Modal isOpen={isCommentOpen} onClose={onCommentCloseMobile} size="full">
          <ModalOverlay />
          <ModalContent bgColor={colors.background}>
            <ModalHeader>
              <IconButton
                aria-label="voltar"
                icon={<FontAwesomeIcon icon={faArrowLeft} />}
                onClick={onCommentCloseMobile}
                variant="ghost"
              />
            </ModalHeader>
            <Box p={2}>
              {comment && <Comment id={id} data={comment} classification={classification} />}
            </Box>
          </ModalContent>
        </Modal>
      ) : (
        <>
          {comment && isCommentOpen && (
            <Box mt={4}>
              <Comment
                colorProp={colorProp}
                bgColorProp={bgColorProp}
                id={id}
                data={comment}
                classification={classification}
                showCommentElements={showCommentElements}
              />
            </Box>
          )}
        </>
      )}
    </>
  );
};

export default QuestionCard;
