import { Flex, FlexProps, Heading, Text } from '@chakra-ui/react';
import { FC } from 'react';
import { useDefaultStyles } from '../../../hooks/useDefaultStyles';

interface SideCardsContainerType extends FlexProps {
  description: string[],
  title: string,
}

export const SideCardsContainer: FC<SideCardsContainerType> = ({
  description,
  title,
  children,
  ...props
}) => {
  const { colors, cardBorder } = useDefaultStyles();
  return (
    <Flex
      p={5}
      alignSelf="start"
      px={5}
      flexFlow="column"
      style={{ gap: '15px' }}
      bgColor={colors.white.keep}
      {...cardBorder}
      {...props}
    >
      <Heading size="md">{title}</Heading>
      {
        description.map(p => (
          <Text key={p}>
            {p}
          </Text>
        ))
      }
      <Flex
        flexWrap="wrap"
        justifyContent="space-around"
      >
        {children}
      </Flex>
    </Flex>
  );
};
