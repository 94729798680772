import { Box, Center, Flex, HStack, Text } from '@chakra-ui/react';
import { FC, useMemo } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { useDefaultStyles } from '../../hooks/useDefaultStyles';
import { useHeader } from '../../hooks/useHeader';
import ContentTab from '../../lib/components/ContentTab';
import { SizeLimiter } from '../_components/SizeLimiter';
import { AristoPlusDocs } from './AristoPlusDocs';
import { VideosPlaylist } from './JJPlaylist';
import { Playlists } from './Playlists';

const JJPage: FC = () => {
  const { pathname } = window.location;
  const { colors } = useDefaultStyles();
  useHeader(() => (
    <Center flex={1}>
      <Text
        color="light.50"
        fontWeight="bold"
        fontSize={{ base: 'md', md: '2xl' }}
      >
        + Aristo
      </Text>
    </Center>
  ));

  const showContentButtons = useMemo(() => {
    return ['/mais-aristo/videos', '/mais-aristo/documentos'].includes(pathname);
  }, [pathname]);

  return (
    <Box m="1rem auto" w="100%">
      <Box mt={2}>
        <SizeLimiter>
          {showContentButtons && (
            <Flex
              my={{ base: 2, md: 4 }}
              borderBottom="1px solid"
              borderColor={colors.secondary.goDarker}
            >
              <HStack align="stretch" overflowX={{ base: 'scroll', md: 'auto' }} m="0 auto" mb="-1px">
                <ContentTab to="/mais-aristo/videos" active={pathname.startsWith('/mais-aristo/videos')}>
                  Vídeos
                </ContentTab>
                <ContentTab to="/mais-aristo/documentos" active={pathname === '/mais-aristo/documentos'}>
                  Documentos
                </ContentTab>
              </HStack>
            </Flex>
          )}
          <Switch>
            <Route exact path="/mais-aristo">
              <Redirect to="/mais-aristo/videos" />
            </Route>
            <Route exact path="/mais-aristo/videos" component={Playlists} />
            <Route path="/mais-aristo/documentos" component={AristoPlusDocs} />
          </Switch>
        </SizeLimiter>
      </Box>
      <Switch>
        <Route exact path="/mais-aristo/videos/:category" component={() => <Redirect to="/mais-aristo/videos" />} />
        <Route path="/mais-aristo/videos/:category/:playlist" component={VideosPlaylist} />
      </Switch>
    </Box>
  );
};

export default JJPage;
