import {
  Box, HStack, NumberDecrementStepper, NumberIncrementStepper,
  NumberInput, NumberInputField, NumberInputStepper, Text, Tooltip, VStack,
} from '@chakra-ui/react';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC } from 'react';
import { useDefaultStyles } from '../../hooks/useDefaultStyles';

type CustomNumberInputType = {
  count: number,
  setCount: (value: React.SetStateAction<number>) => void,
  info?: string,
  label?: string,
  max?: number,
  min?: number,
  w?: { base: string, md: string },
}

export const CustomNumberInput: FC<CustomNumberInputType> = ({
  count,
  setCount,
  label,
  info,
  max,
  min,
  w,
}) => {
  const { colors } = useDefaultStyles();
  return (
    <VStack align="flex-start" w={w}>
      <HStack justify="flex-start">
        {label && (
          <Text fontSize="sm" color={colors.secondary.goALittleDarker}>{label}</Text>
        )}
        <Tooltip
          label={info}
        >
          <Box color={colors.secondary.keep}>
            <FontAwesomeIcon icon={faInfoCircle} />
          </Box>
        </Tooltip>
      </HStack>
      <NumberInput
        min={min ?? 1}
        max={max ?? 200}
        onChange={e => setCount(Number(e))}
        value={count}
      >
        <NumberInputField />
        <NumberInputStepper>
          <NumberIncrementStepper />
          <NumberDecrementStepper />
        </NumberInputStepper>
      </NumberInput>
    </VStack>
  );
};
