import { Button, Tooltip } from '@chakra-ui/react';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC } from 'react';
import { useDefaultStyles } from '../../../hooks/useDefaultStyles';

type ShowAnswersOnExamsButtonType = {
  show?: boolean,
  isViewMode: boolean,
  setIsViewMode: React.Dispatch<React.SetStateAction<boolean>>,
}

export const ShowAnswersOnExamsButton: FC<ShowAnswersOnExamsButtonType> = ({
  show = true,
  isViewMode,
  setIsViewMode,
}) => {
  const { colors } = useDefaultStyles();

  if (!show) {
    return null;
  }

  return (
    <Tooltip label="Opção indisponível se você iniciar a prova.">
      <Button
        mx={2}
        fontSize={12}
        onClick={() => setIsViewMode(prev => !prev)}
        leftIcon={<FontAwesomeIcon icon={isViewMode ? faEyeSlash : faEye} />}
        color={colors.secondary.keep}
      >
        {
          isViewMode ? 'Ocultar respostas' : 'Ver respostas'
        }
      </Button>
    </Tooltip>
  );
};
