import { Box, BoxProps, Flex, HStack, Text } from '@chakra-ui/react';
import { faCheckCircle, faPercent, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDefaultStyles } from '../../../hooks/useDefaultStyles';
import ProgressBar from './ProgressBar.component';

interface ActivityProgressProps extends BoxProps {
  summary: {
    correct: number;
    wrong: number;
    total: number;
  };
  index: number;
  showStats?: boolean;
  hide?: boolean;
}

export function ActivityProgress({
  summary, index, showStats = false, hide = false, ...rest
}: ActivityProgressProps) {
  const { correct, wrong, total } = summary;
  const { colors } = useDefaultStyles();
  const performance = Math.round((correct * 100) / (correct + wrong)) || '--';

  if (hide) {
    return null;
  }

  return (
    <Box {...rest}>
      <Flex justify="space-between" px={4}>
        <Text>{`${index + 1}/${total}`}</Text>
        {showStats && (
          <Flex justify="end">
            <HStack spacing={5} px={2}>
              <HStack>
                <Box color={colors.green.goDarker}>
                  <FontAwesomeIcon icon={faCheckCircle} />
                </Box>
                <Text>{correct}</Text>
              </HStack>
              <HStack>
                <Box color={colors.red.goDarker}>
                  <FontAwesomeIcon icon={faTimesCircle} />
                </Box>
                <Text>{wrong}</Text>
              </HStack>
              <HStack>
                <Box fontSize="sm" color={colors.secondary.goDarker}>
                  <FontAwesomeIcon icon={faPercent} />
                </Box>
                <Text>{performance}</Text>
              </HStack>
            </HStack>
          </Flex>
        )}
      </Flex>
      <Box mt={2}>
        <ProgressBar
          progress={(summary.correct + summary.wrong) / summary.total}
          h={2}
        />
      </Box>
    </Box>
  );
}
