import { chakra, Box, HTMLChakraProps, Image, Square } from '@chakra-ui/react';
import { HTMLMotionProps, motion } from 'framer-motion';
import React, { FC } from 'react';
import { useDefaultStyles } from '../../hooks/useDefaultStyles';

type Merge<P, T> = Omit<P, keyof T> & T;

type MotionBoxProps = Merge<HTMLChakraProps<'div'>, HTMLMotionProps<'div'>>;

export const MotionBox: React.FC<MotionBoxProps> = motion(chakra.div);

const LoadingScreen: FC = () => {
  const { logo } = useDefaultStyles();
  return (
    <Square
      w="100vw"
      h="100vh"
    >
      <MotionBox
        as="aside"
        animate={{
          scale: [1, 1.2, 1],
          opacity: [0.3, 1, 0.3],
        }}
        transition={{
          duration: 2,
          ease: 'easeInOut',
          times: [0, 0.5, 1],
          repeat: Infinity,
          repeatType: 'loop',
        }}
        padding={2}
        display="flex"
      >
        <Box boxSize={200}>
          <Image src={logo('logo-loading')} alt="logo" />
        </Box>
      </MotionBox>
    </Square>
  );
};

export default LoadingScreen;
