import { gql, QueryResult, useQuery } from '@apollo/client';
import { Comment } from './agenda';

export interface Video {
  _id: string;
  url: string;
  title: string;
  subtitle: string;
  liveChatLink?: string;
  comment: Comment;
  jjPlus?: {
    playlist: string;
    category: string;
  }
  createdAt?: string;
}

interface VideoInput {
  ids: string[];
}

interface VideoOutput {
  videoMany: Video[];
}

const VIDEO_MANY = gql`
  query VideoMany($ids: [ObjectId!]!) {
    videoMany (ids: $ids) {
      _id
      url
      title
      subtitle
      liveChatLink
      comment {
        author
        profile
        title
        body
        createdAt
        updatedAt
      }
    }
  }
`;

export function useVideoMany({ ids }: VideoInput): QueryResult<VideoOutput, VideoInput> {
  return useQuery<VideoOutput, VideoInput>(VIDEO_MANY, {
    variables: {
      ids,
    },
    fetchPolicy: 'network-only',
    skip: ids.length === 0,
  });
}
