import { FC, useMemo } from 'react';
import {
  Box,
  Divider,
  Flex,
  HStack,
  Image,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  VStack,
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faList,
} from '@fortawesome/free-solid-svg-icons';
import ModalPerformanceCard from './ExamModalPerformanceCard';
import ExamModalBanner from './ExamModalBanner';
import ExamPerformanceChart from './ExamModalPerformanceChart';
import ExamModalCompositionChart from './ExamModalCompositionChart';
import ExamModalGradeInfo from './ExamModalGradeInfo';
import {
  useMockExamInfo, useStudentPerformanceMockExam, useMockExamQuestionsByArea,
} from '../../../api/mock-exams-performance';
import { useDevice } from '../../../hooks/useDevice';
import { useDefaultStyles } from '../../../hooks/useDefaultStyles';

interface ExamModalProps {
  isOpen?: boolean;
  onClose: () => void;
  activityId?: string;
  templateId?: string;
}

interface ByAreaSubjectData {
  subject: string;
  value: number;
  total: number;
}

export interface ByAreaData {
  area: string;
  bySubjects: ByAreaSubjectData[];
}

export interface CompositionData {
  area: string;
  value: number;
}

const FinishExamModal: FC<ExamModalProps> = ({
  isOpen = false,
  onClose,
  activityId,
  templateId,
}) => {
  const mockExamInfo = useMockExamInfo(activityId ?? '', !isOpen);
  const mockExamQuestionsByArea = useMockExamQuestionsByArea(templateId ?? '', !isOpen);
  const studentPerformance = useStudentPerformanceMockExam(activityId ?? '', !isOpen);

  const mockExamInfoData = useMemo(() => {
    const { data } = mockExamInfo;
    if (data) {
      return data.mockExamTemplateInfo;
    }
    return undefined;
  }, [mockExamInfo]);

  const mockExamQuestionsByAreaData = useMemo(() => {
    const { data } = mockExamQuestionsByArea;
    if (data) {
      return data.questionsByAreaMockExam;
    }
    return undefined;
  }, [mockExamQuestionsByArea]);

  const studentPerformanceData = useMemo(() => {
    const { data } = studentPerformance;
    if (data) {
      return data.studentPerformanceMockExam;
    }
    return undefined;
  }, [studentPerformance]);

  const currentDevice = useDevice();

  const isMobile = currentDevice === 'mobile';

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      scrollBehavior="inside"
      size={isMobile ? 'full' : undefined}
      isCentered
    >
      <ModalOverlay />
      <ModalContent
        maxW="960px"
        borderRadius={isMobile ? '0px' : '32px'}
        padding="16px 4px 0px 0px"
      >
        <ModalCloseButton />
        <VStack
          width="100%"
          marginTop="24px"
          spacing="16px"
          alignItems="start"
          justifyContent="start"
          overflowY="auto"
          paddingX="32px"
        >
          <Flex alignItems="center">
            <Box padding="8px" borderRadius="16px" backgroundColor="white">
              <Image
                src="/images/exam-performance-hero.png"
                alt="Exam Performance Image"
                height={120}
                width={120}
              />
            </Box>
            <Text marginLeft="16px" flex="1" fontSize="32px">Resultado do Simulado</Text>
          </Flex>
          <Divider />

          <Flex
            direction="row"
            flexWrap="wrap"
            width="100%"
          >
            <VStack
              flex={1}
              minW="250px"
              spacing="16px"
              alignItems="center"
              justifyContent="start"
            >
              <ExamModalGradeInfo
                totalQuestions={mockExamInfoData?.totalQuestions}
                studentScore={mockExamInfoData?.studentScore}
                rankingGeneratedOn={mockExamInfoData?.rankingGeneratedOn}
                cutOffScore={mockExamInfoData?.cutOffScore}
                infoDescription={mockExamInfoData?.infoDescription}
                studentRanking={mockExamInfoData?.studentRanking}
              />
              {mockExamInfoData?.banner && (
              <ExamModalBanner
                imageUrl={mockExamInfoData.banner.imageUrl}
                link={mockExamInfoData.banner.link}
                title={mockExamInfoData.banner.title}
                subtitle={mockExamInfoData.banner.subtitle}
              />
              )}
              <ExamModalCompositionChart data={mockExamQuestionsByAreaData} />
            </VStack>

            <div style={{ height: '16px', width: '16px' }} />

            <VStack
              flex={1}
              minW="250px"
              spacing="16px"
              alignItems="center"
              justifyContent="start"
              marginBottom="70px"
            >
              <ExamPerformanceChart studentPerformanceData={studentPerformanceData} />

              {!studentPerformanceData ? (
                <ModalPerformanceCard />
              ) : (
                studentPerformanceData.map(it => (
                  <ModalPerformanceCard data={it} />
                ))
              )}
            </VStack>
          </Flex>
        </VStack>
        <ExamModalFloatingButton onClick={onClose} />
      </ModalContent>
    </Modal>
  );
};

export default FinishExamModal;

const ExamModalFloatingButton: FC<{ onClick: () => void }> = ({
  onClick,
}) => {
  const { colors } = useDefaultStyles();

  return (
    <HStack
      width="100%"
      justifyContent="center"
      height="0px"
    >
      <HStack
        padding="8px"
        alignItem="center"
        justifyContent="center"
        cursor="pointer"
        role="button"
        onClick={onClick}
        backgroundColor={colors.lighter.goMuchDarker}
        positioned="absolute"
        marginBottom="70px"
        borderRadius="8px"
        zIndex="99"
      >
        <FontAwesomeIcon icon={faList} />
        <Text>visualizar comentários</Text>
      </HStack>
    </HStack>
  );
};
