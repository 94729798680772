import { Box } from '@chakra-ui/react';
import { FC, useContext, useCallback, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { getDateParam } from '../../../hooks/getDateParam';
import { getParam } from '../../../hooks/useSearchParam';
import { AgendaContext } from '../Agenda.context';
import useInstitutions from '../../../hooks/useInstitutions';
import { useExtraExams } from '../../../api/extra-activities/extra-exams';
import { toast } from '../../../utils/toast';
import { Loading } from '../../../lib/components/Loading';

const ExtraExamCreatePage: FC = () => {
  const agendaCtx = useContext(AgendaContext);
  const history = useHistory();
  const { onCreateExamExtraActivity } = useExtraExams();
  const hasConditionPassed = useRef(false);

  const uf = getParam('uf');
  const institution = getParam('institution');
  const aristoInstitution = getParam('aristoInstitution');
  const year = parseInt(getParam('year') || '0', 10);

  const { template, loading: templateLoading } = useInstitutions(
    uf, aristoInstitution, year,
  );

  const getCurrentActivity = useCallback(async () => {
    const { extras } = agendaCtx;
    const { activityExamExtra } = extras;

    if (!activityExamExtra || activityExamExtra.length === 0) {
      return undefined;
    }

    const find = activityExamExtra.filter(e => e.institution === institution
      && e.year === year
      && e.finished === false);

    if (find.length === 0) {
      return undefined;
    }

    return find[0]._id;
  }, [agendaCtx, institution, year]);

  const redirect = useCallback((id: string) => {
    history.push({
      pathname: '/atividades-extras/prova',
      search: `date=${getDateParam()}&a=${id}`,
    });
  }, [history]);

  const errorDialog = useCallback(() => {
    toast.error({
      title: 'Ocorreu um erro!.',
      description: 'Não foi possível criar a atividade.',
    });
  }, []);

  const createActivity = useCallback(async () => {
    if (template.length === 0) {
      errorDialog();
      return;
    }

    try {
      const res = await onCreateExamExtraActivity({
        template,
      });

      if (!res) {
        errorDialog();
      }

      await agendaCtx.refetchExtras();

      if (res) {
        redirect(res);
      }
    } catch (err) {
      console.log(err);
      errorDialog();
    }
  }, [agendaCtx, errorDialog, onCreateExamExtraActivity, redirect, template]);

  const init = useCallback(async () => {
    if (hasConditionPassed.current) {
      return;
    }

    if (agendaCtx.extrasLoading === false && templateLoading === false && template.length > 0) {
      hasConditionPassed.current = true;
      const id = await getCurrentActivity();
      if (id) {
        redirect(id);
      } else {
        await createActivity();
      }
    }
  }, [agendaCtx.extrasLoading, createActivity, getCurrentActivity, redirect, template, templateLoading]);

  useEffect(() => {
    init();
  }, [init, template]);

  return (
    <Box maxW={900} m="0 auto" padding={8}>
      <Loading subtitle="Criando prova" />
    </Box>
  );
};

export default ExtraExamCreatePage;
