import { FC, useCallback, useContext, useMemo, useRef, useState } from 'react';
import {
  Box, Circle, Container, Flex,
  Grid, HStack, Image, Text, Tooltip, useDisclosure,
} from '@chakra-ui/react';
import { AgendaContext } from '../agenda/Agenda.context';
import ListExtraActivities from './ListExtraActivities';
import CreateActivity, { CreateActivityStates } from './CreateActivityModal';
import { Divider } from '../../lib/components/Divider';
import { EmptyPage } from '../agenda/_components/EmptyPage';
import { isTodayExtended } from '../../utils/isTodayExtended';
import { getDateParam } from '../../hooks/getDateParam';
import { useDefaultStyles, useRGBColor } from '../../hooks/useDefaultStyles';
import { useGetCourseExtraActivities } from '../../api/extra-activities/extra-activities';

interface ChooseModalProps {
  onClick?: (name: CreateActivityStates) => () => void;
}

const ChooseModal: FC<ChooseModalProps> = () => {
  const boxRef = useRef<HTMLDivElement | null>(null);
  const today = getDateParam();

  const { nExtras } = useContext(AgendaContext);
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [state, setState] = useState<CreateActivityStates>();

  const handleState = useCallback((name: CreateActivityStates) => {
    setState(name);
    onOpen();
  }, [onOpen]);

  const handleOnAfterCreate = useCallback(() => {
    setTimeout(() => {
      if (boxRef.current) {
        boxRef.current.scrollIntoView({ block: 'end', inline: 'nearest', behavior: 'smooth' });
      }
    }, 700);
  }, []);

  const availableOptions = useGetCourseExtraActivities().data;

  if (isTodayExtended()) {
    return (
      <>
        <Box id="test" ref={boxRef} m="2rem auto" maxW={1000}>
          <Text fontSize="xl" mb={4} fontWeight="bold">Escolha a atividade extra que deseja adicionar</Text>
          <Grid
            templateColumns={{ base: 'repeat(2,1fr)', md: 'repeat(4,1fr)' }}
            columnGap={2}
            rowGap={4}
            mb={6}
            justifyItems="center"
          >
            <OptionBtn
              onClick={() => handleState('theoricContent')}
              text="Conteúdo teórico"
              background="theoric-study"
              disabled={!availableOptions?.includes('TheoreticalStudy')}
            />
            <OptionBtn
              onClick={() => handleState('question')}
              text="Questões"
              background="question"
              disabled={!availableOptions?.includes('Question')}
            />
            <OptionBtn
              onClick={() => handleState('flashcard')}
              text="Flashcards"
              background="flashcard"
              disabled={!availableOptions?.includes('Flashcard')}
            />
            <OptionBtn
              onClick={() => handleState('exam')}
              text="Prova"
              background="exam"
              disabled={!availableOptions?.includes('Exam')}
            />
            <OptionBtn
              onClick={() => handleState('mockExam')}
              text="Simulados Aristo"
              background="mock"
              disabled={!availableOptions?.includes('MockExam')}
            />
            <OptionBtn
              onClick={() => handleState('smartMockExam')}
              text="Simulado inteligente"
              background="smart-mock"
              disabled={!availableOptions?.includes('SmartExam')}
            />
            <OptionBtn
              onClick={() => handleState('theoreticalReview')}
              text="Revisão teórica"
              background="theoretical-review"
              disabled={!availableOptions?.includes('TheoreticalReview')}
            />
            <OptionBtn
              onClick={() => handleState('smartReview')}
              text="Revisão inteligente"
              background="smart-review"
              disabled={!availableOptions?.includes('SmartReview')}
            />
          </Grid>
          {
            nExtras[1] !== 0 && (
              <Box ref={boxRef}>
                <Flex align="center">
                  <Divider />
                  <Text mx={2} fontWeight="bold" color="light.200" whiteSpace="nowrap">
                    {`Atividades extras adicionadas (${nExtras[1]})`}
                  </Text>
                  <Divider />
                </Flex>
                <ListExtraActivities />
              </Box>
            )
          }
        </Box>
        <CreateActivity
          isOpen={isOpen}
          onClose={onClose}
          onAfterCreate={handleOnAfterCreate}
          selected={state}
        />
      </>
    );
  }
  return (
    nExtras[1] === 0 ? (
      <EmptyPage
        marginTop={8}
        secondaryButton="hide"
        primaryButton={{ label: 'Ir para hoje', onClickRoute: '/agenda' }}
        title="Sem registro de atividade extra neste dia!"
        paragraphs={[
          'Você está em um dia passado e não encontramos registros de atividades extras neste dia.',
          `Caso queira ver a agenda de hoje, clique no botão abaixo.
          Depois, é só fazer as atividades propostas ou adicionar extras!`,
        ]}
      />
    ) : (
      <Box
        flexFlow="column"
      >
        <Text
          py="1rem"
          fontWeight="bold"
          fontSize={{ base: 'lg', md: 'xl' }}
          textAlign="center"
        >
          {`Atividades extras criadas no dia ${today.slice(6)}/${today.slice(4, 6)}`}
        </Text>
        <ListExtraActivities />
      </Box>
    )
  );
};

interface OptionBtnProps {
  onClick: () => void;
  text: string;
  background: 'theoric-study' | 'question' | 'flashcard' | 'mock'
  | 'exam' | 'smart-mock' | 'theoretical-review' | 'smart-review';
  disabled?: boolean,
}

const OptionBtn: FC<OptionBtnProps> = ({
  onClick, text, background, disabled = false,
}) => {
  const { cardBorder, colors, hueRotate } = useDefaultStyles();
  const [mouseOnCard, setMouseOnCard] = useState(false);
  const { toRGB } = useRGBColor();

  const cardColors = useMemo(() => {
    return {
      circleBgColor: mouseOnCard ? colors.lighterSecondary.goDarker : colors.lighter.goAlmostFullDarker,
      textColor: mouseOnCard ? colors.secondary.goLighter : colors.primary.goFullLighter,
      boxBorder: `solid 2px ${mouseOnCard
        ? toRGB(colors.secondary.goLighter)
        : toRGB(colors.darkGray.goPallete700)}`,
    };
  }, [colors.darkGray.goPallete700, colors.lighterSecondary.goDarker, colors.lighter.goAlmostFullDarker,
    colors.primary.goFullLighter, colors.secondary.goLighter, mouseOnCard, toRGB]);

  return (
    <Tooltip
      label={disabled ? 'Esta opção não está disponível para este curso.' : ''}
      hasArrow
    >
      <Box
        as="button"
        onClick={!disabled ? onClick : undefined}
        w="100%"
        px={2}
        _hover={{ color: colors.secondary.goLighter }}
        opacity={disabled ? 0.3 : undefined}
        cursor={disabled ? 'not-allowed' : 'pointer'}
        filter={disabled ? 'grayscale(1)' : undefined}
        borderRadius={cardBorder.borderRadius}
        boxShadow={cardBorder.boxShadow}
        border={cardColors.boxBorder}
        onMouseEnter={() => setMouseOnCard(true)}
        onMouseLeave={() => setMouseOnCard(false)}
        h={{ base: '90px', md: '120px' }}
        transition="all 0.3s"
      >
        <HStack
          my={1}
          align="center"
          justify="center"
        >
          <Circle
            size={{ base: '60px', md: '70px' }}
            bgColor={cardColors.circleBgColor}
            transition="all 0.3s"
          >
            <Container centerContent p={3}>
              <Image
                style={{ imageRendering: '-webkit-optimize-contrast' }}
                filter={hueRotate()}
                src={`/images/aristo-icons/${background}.png`}
              />
            </Container>
          </Circle>
          <Box align="center" justify="center" flex={1}>
            <Text
              transition="all 0.3s"
              textAlign="center"
              fontSize={{ base: 'sm', md: 'md' }}
            >
              {text}
            </Text>
          </Box>
        </HStack>
      </Box>
    </Tooltip>
  );
};
export default ChooseModal;
