import { Box, Checkbox, Flex, Text, Tooltip, VStack } from '@chakra-ui/react';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, useCallback } from 'react';
import { colorPalette, useDefaultStyles } from '../../hooks/useDefaultStyles';

type ThemeListBoxInputType = {
  inputVariant: 'classifications' | 'plainStrings',
  itemsList: string[],
  isAllThemesSelectorChecked: boolean,
  setIsAllThemesSelectorChecked: (value: React.SetStateAction<boolean>) => void,
  onRemoveItemClick: (item: string) => void;
  valueTranslatorFn?: (item: string) => string;
}

export const ThemeListBox: FC<ThemeListBoxInputType> = ({
  itemsList,
  isAllThemesSelectorChecked,
  setIsAllThemesSelectorChecked,
  onRemoveItemClick,
  inputVariant,
  valueTranslatorFn,

}) => {
  const { colors } = useDefaultStyles();

  const getItemName = useCallback((item: string) => {
    if (valueTranslatorFn && inputVariant === 'plainStrings') {
      return valueTranslatorFn(item);
    }
    if (inputVariant === 'classifications') {
      const itemSplit = item.split('|');
      return itemSplit[itemSplit.length - 1];
    }
    return '';
  }, [inputVariant, valueTranslatorFn]);

  return (
    <Flex w="100%" bgColor={colors.lighter.goAlmostFullDarker} flexFlow="column" p={4} maxH={300} overflowY="auto">
      <Text color={colors.secondary.keep} fontWeight="bold">Temas selecionados:</Text>
      {
        !itemsList.length && (
          <VStack align="flex-start">
            <Text>
              Nenhum tema específico selecionado.
            </Text>
            <Checkbox
              colorScheme={colorPalette}
              borderColor={colors.secondary.keep}
              isChecked={isAllThemesSelectorChecked}
              onChange={() => setIsAllThemesSelectorChecked(prev => !prev)}
            >
              <Text color={colors.secondary.keep} fontSize={12}>
                Atividade com temas aleatórios
              </Text>
            </Checkbox>
          </VStack>
        )
      }
      {
        itemsList.map((item, index) => {
          const itemName = getItemName(item);
          return (
            <Flex w="100%" justify="space-between" key={Math.random()}>
              <Text key={Math.random()} noOfLines={1}>
                {`${index + 1} - ${itemName}`}
              </Text>
              <Tooltip label={`Remover ${itemName}`}>
                <Box
                  as="button"
                  fontSize={12}
                  color={colors.secondary.keep}
                  onClick={() => onRemoveItemClick(item)}
                >
                  <FontAwesomeIcon icon={faTrashAlt} />
                </Box>
              </Tooltip>
            </Flex>
          );
        })
      }

    </Flex>
  );
};
