import {
  FC, useContext, ReactNode, useCallback, useState, useMemo,
} from 'react';
import moment from 'moment';
import {
  Box, Flex, Text, Input, HStack, Button, FlexProps,
} from '@chakra-ui/react';
import ReactSelect from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faMars, faTimes, faVenus } from '@fortawesome/free-solid-svg-icons';
import { PrivateContext } from '../../../Private.context';
import { useProfile } from '../../../api/profile';
import { SecondaryButton } from '../../../lib/components/SecondaryButton';
import { PrimaryButton } from '../../../lib/components/PrimaryButton';
import profileExpertises from '../expertises';
import Select from '../../../lib/components/Select';
import instNames from '../../../json/universitiesByUF.json';
import { useDefaultStyles, useRGBColor } from '../../../hooks/useDefaultStyles';

const PersonalInformation: FC = () => {
  const { profile } = useContext(PrivateContext);
  const [name, setName] = useState(profile.name);
  const [gender, setGender] = useState<string | undefined>(profile.gender);
  const [birthday, setBirthday] = useState(moment(profile.birthday).format('YYYY-MM-DD'));
  const [dirtyInputs, setDirtyInputs] = useState(false);
  const [loading, setLoading] = useState(false);
  const { onUpdateProfile } = useProfile();
  const [uf, setUf] = useState(profile.graduation.uf);
  const [isGraduated, setIsGraduated] = useState(profile.graduation.isGraduated);
  const [institution, setInstitution] = useState(profile.graduation.institution);
  const [yearConclusion, setYearConclusion] = useState(profile.graduation.yearConclusion);
  const [desiredExpertise, setDesiredExpertise] = useState(profile.graduation.desiredExpertise);
  const [inHealthArea, setInHealthArea] = useState(profile.work.inHealthArea);
  const [placeOfWork, setPlaceOfWork] = useState(profile.work.placeOfWork);
  const [weeklyHours, setWeeklyHours] = useState(profile.work.weeklyHours);
  const YEARS = Array(30).fill(0).map((_, index) => index - 21 + (new Date().getFullYear()));
  const { colors } = useDefaultStyles();
  const { toRGB } = useRGBColor();

  const institutions = useMemo(() => {
    if (uf && uf !== 'UX') {
      return (
        instNames[uf as keyof typeof instNames]
      );
    } return [];
  }, [uf]);

  const handleIsGraduated = useCallback(() => {
    setDirtyInputs(true);
    setIsGraduated(old => !old);
  }, []);

  const handleYearConclusion = useCallback(e => {
    setDirtyInputs(true);
    setYearConclusion(e.target.value);
  }, []);

  const handleInstitution = useCallback(e => {
    setDirtyInputs(true);
    if (e !== null) {
      setInstitution(e.value);
    } else {
      setInstitution('');
    }
  }, []);

  const handleDesiredExpertise = useCallback(e => {
    setDirtyInputs(true);
    setDesiredExpertise(e.target.value);
  }, []);

  const handleName = useCallback(e => {
    setDirtyInputs(true);
    setName(e.target.value);
  }, []);

  const handleGender = useCallback(e => {
    setDirtyInputs(true);
    setGender(prev => (
      prev === 'm' ? 'f' : 'm'
    ));
  }, []);

  const handleBirthday = useCallback(e => {
    setDirtyInputs(true);
    setBirthday(e.target.value);
  }, []);

  const handleUndo = useCallback(() => {
    setName(profile.name);
    setGender(profile.gender);
    setBirthday(moment(profile.birthday).format('YYYY-MM-DD'));
    setIsGraduated(profile.graduation.isGraduated);
    setYearConclusion(profile.graduation.yearConclusion);
    setInstitution(profile.graduation.institution);
    setDesiredExpertise(profile.graduation.desiredExpertise);
    setInHealthArea(profile.work.inHealthArea);
    setPlaceOfWork(profile.work.placeOfWork);
    setWeeklyHours(profile.work.weeklyHours);
    setDirtyInputs(false);
    setUf(profile.graduation.uf);
  }, [profile.birthday, profile.gender, profile.graduation.desiredExpertise, profile.graduation.institution,
    profile.graduation.isGraduated, profile.graduation.uf, profile.graduation.yearConclusion, profile.name,
    profile.work.inHealthArea, profile.work.placeOfWork, profile.work.weeklyHours]);

  const handleUpdate = useCallback(async () => {
    setLoading(true);
    const newBirthday = new Date(birthday);

    if (birthday) {
      newBirthday.setHours(newBirthday.getHours() + 3);
    }

    try {
      await onUpdateProfile({
        name,
        gender: (gender as 'm' | 'f' | undefined) || undefined,
        birthday: birthday ? newBirthday : undefined,
        graduation: {
          institution,
          uf,
          yearConclusion,
          desiredExpertise,
          isGraduated,
        },
        work: {
          inHealthArea,
          placeOfWork,
          weeklyHours,
        },
      });
      setDirtyInputs(false);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [birthday, desiredExpertise, gender, inHealthArea, institution,
    isGraduated, name, onUpdateProfile, placeOfWork, uf, weeklyHours, yearConclusion]);

  return (
    <Flex // PEGA TODOS
      flexFlow="column"
      justifyContent="center"
      maxWidth={700}
      margin="0 auto"
    >
      <Box>
        <Item
          label={(
            <Text
              fontWeight="bold"
            >
              E-mail:
            </Text>
          )}
          component={(
            <Text>
              {profile.email}
            </Text>
          )}
        />
        <Item
          label={(
            <Text
              fontWeight="bold"
            >
              Nome completo:
            </Text>
          )}
          component={(
            <Input
              borderColor={colors.lightGray.goDarker}
              maxLength={150}
              value={name}
              onChange={handleName}
            />
          )}
        />
        <Item
          label={(
            <Text
              fontWeight="bold"
            >
              Nascimento:
            </Text>
          )}
          component={(
            <Input
              borderColor={colors.lightGray.goDarker}
              placeholder="Selecione"
              type="date"
              value={birthday}
              onChange={handleBirthday}
            />
          )}
        />
        <Flex gridGap={5}>
          <Item
            flex={1}
            label={<Text fontWeight="bold">Sexo:</Text>}
            component={(
              <Button
                padding="0"
                width="100%"
                align="center"
                onClick={handleGender}
                rightIcon={(
                  <FontAwesomeIcon
                    icon={gender === 'm' ? faMars : faVenus}
                  />
                )}
              >
                {gender === 'm' ? 'Masculino' : 'Feminino'}
              </Button>
            )}
          />
          <Item
            flex={1}
            label={<Text fontWeight="bold">Sou formado:</Text>}
            component={(
              <Button
                width="100%"
                align="center"
                onClick={handleIsGraduated}
                rightIcon={(
                  <FontAwesomeIcon
                    color={isGraduated ? 'green' : 'red'}
                    icon={isGraduated ? faCheck : faTimes}
                  />
                )}
              >
                {isGraduated ? 'Sim' : 'Não'}
              </Button>
            )}
          />
        </Flex>
      </Box>

      <Item
        label={<Text fontWeight="bold">Ano de formação:</Text>}
        component={(
          <Select
            value={yearConclusion}
            onChange={handleYearConclusion}
            options={YEARS.map(item => ({ value: String(item), label: String(item) }))}
          />
        )}
      />
      <Item
        label={<Text fontWeight="bold">Qual a sua universidade?</Text>}
        component={(
          <HStack>
            <Select
              flex={1}
              placeholder="Escolha"
              value={uf}
              onChange={e => {
                setUf(e.target.value);
                setInstitution('');
                setDirtyInputs(true);
              }}
              minWidth="4rem"
              options={[{ value: 'UX', label: 'Exterior' },
                ...Object.keys(instNames).map(item => ({ value: item, label: item }))]}
            />
            <Box flex={5}>
              {uf !== 'UX'
                ? (
                  <ReactSelect
                    styles={{
                      control: provided => {
                        return { ...provided, backgroundColor: 'transparent' };
                      },
                      option: provided => {
                        return {
                          ...provided,
                          backgroundColor: toRGB(colors.white.goGray[700]),
                          ':hover': { backgroundColor: toRGB(colors.lighter.goAlmostFullDarker) },
                        };
                      },
                      placeholder: provided => {
                        return { ...provided, color: toRGB(colors.darkGray.keep) };
                      },
                      input: provided => {
                        return { ...provided, color: toRGB(colors.primary.goFullLighter) };
                      },
                      singleValue: provided => {
                        return { ...provided, color: toRGB(colors.primary.goFullLighter) };
                      },
                    }}
                    isSearchable
                    isClearable
                    onChange={handleInstitution}
                    value={institution ? { value: institution, label: institution } : null}
                    placeholder="Selecione"
                    options={
                      institutions
                        .map(inst => (
                          {
                            value: inst,
                            label: inst,
                          }))
                    }
                  />
                ) : (
                  <Input
                    placeholder="Digite o nome da sua instituição"
                    value={institution}
                    onChange={e => {
                      setInstitution(e.target.value);
                      setDirtyInputs(true);
                    }}
                  />
                )}
            </Box>

          </HStack>
        )}
      />
      <Item
        label={<Text fontWeight="bold"> Qual a especialidade pretendida ?</Text>}
        component={(
          <Select
            value={desiredExpertise}
            onChange={handleDesiredExpertise}
            options={profileExpertises.map(item => ({ value: item, label: item }))}
          />
        )}
      />

      <Flex justify="end" marginTop="2rem">
        <HStack>
          <SecondaryButton
            disabled={!dirtyInputs || loading}
            onClick={handleUndo}
            color={colors.secondary.goLighter}
          >
            <Text fontSize={{ base: 'xs', md: 'md' }} color={colors.secondary.goLighter}>
              Desfazer alterações
            </Text>
          </SecondaryButton>
          <PrimaryButton
            onClick={handleUpdate}
            disabled={!dirtyInputs}
            isLoading={loading}
          >
            <Text fontSize={{ base: 'xs', md: 'md' }}>
              Salvar alterações
            </Text>
          </PrimaryButton>
        </HStack>
      </Flex>

    </Flex>
  );
};

interface ItemProps extends FlexProps {
  label: ReactNode;
  component: ReactNode;
}

const Item: FC<ItemProps> = ({ label, component, ...rest }) => {
  return (
    <Flex
      align="left"
      flexFlow="column"
      {...rest}
    >
      <Box marginBottom="1">
        {label}
      </Box>
      <Box
        marginBottom="4"
        width="100%"
      >
        {component}
      </Box>
    </Flex>
  );
};

export default PersonalInformation;
