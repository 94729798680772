import {
  FC, useCallback, useContext, useState,
} from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { toast } from 'react-toastify';
import { AgendaContext } from '../agenda/Agenda.context';
import { useExtraSmartReview } from '../../api/extra-activities/extra-smart-review';
import { ExtraActivityModalProps } from './CreateActivityModal';
import { SecondaryButton } from '../../lib/components/SecondaryButton';
import { PrimaryButton } from '../../lib/components/PrimaryButton';
import { useDefaultStyles } from '../../hooks/useDefaultStyles';

const SmartReviewModal: FC<ExtraActivityModalProps> = ({
  onClose, onAfterCreate,
}) => {
  const { refetchExtras } = useContext(AgendaContext);
  const { colors } = useDefaultStyles();

  const [loading, setLoading] = useState(false);

  const { onCreateSmartReviewExtraActivity } = useExtraSmartReview();

  const handleCreate = useCallback(async () => {
    setLoading(true);
    try {
      await onCreateSmartReviewExtraActivity();
      await refetchExtras();
      onClose();
      if (onAfterCreate) {
        onAfterCreate();
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  }, [onAfterCreate, onClose, onCreateSmartReviewExtraActivity, refetchExtras]);

  return (
    <>
      <Text color={colors.secondary.goALittleDarker} mb={2} fontWeight="bold">Revisão Inteligente (RI)</Text>
      <Text mb={2}>
        Ao adicionar uma revisão inteligente, adiantaremos a
        sua revisão inteligente que foi calculada com base
        nos assuntos elegíveis.
      </Text>
      <Text color={colors.red.keep} mb={4}>
        Apenas uma RI extra é contabilizada por dia.
      </Text>
      <Flex justify="end">
        <SecondaryButton onClick={onClose} mr={2} variant="ghost">
          Cancelar
        </SecondaryButton>
        <PrimaryButton
          isLoading={loading}
          onClick={handleCreate}
        >
          Adicionar
        </PrimaryButton>
      </Flex>
    </>
  );
};

export default SmartReviewModal;
