import { FC } from 'react';
import { Button, ButtonProps } from '@chakra-ui/react';
import { useDefaultStyles } from '../../hooks/useDefaultStyles';
import { useLocalStorage } from '../../hooks/useLocalStorage';

// interface PrimaryButtonProps extends ButtonProps { }

export const PrimaryButton: FC<ButtonProps> = ({
  children, ...rest
}) => {
  const { colors } = useDefaultStyles();
  const [colorPalette] = useLocalStorage('@Aristoclass:colorPalette');
  return (
    <Button
      colorScheme={colorPalette}
      color={colors.lightGray.keep}
      bgColor={colors.secondary.goDarker}
      {...rest}
    >
      {children}
    </Button>
  );
};
