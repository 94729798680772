import {
  FC,
} from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { Flex, HStack } from '@chakra-ui/react';
import { WeekGoals } from './sections/WeekGoals';
import { GeneralPerformance } from './sections/questions-performance/QuestionPerformance';
import { GeneralRanking } from './sections/GeneralRanking';
import { StudentReport } from './sections/StudentReport';
import { useDefaultStyles } from '../../hooks/useDefaultStyles';
import ContentTab from '../../lib/components/ContentTab';

const PerformancePage: FC = () => {
  const { pathname } = window.location;
  const { colors } = useDefaultStyles();

  return (
    <>
      <Flex
        my={{ base: 2, md: 4 }}
        borderBottom="1px solid"
        borderColor={colors.secondary.goDarker}
      >
        <HStack align="stretch" overflowX={{ base: 'scroll', md: 'auto' }} m="0 auto" mb="-1px">
          <ContentTab
            minW={{ base: 90, md: 160 }}
            to="/desempenho/geral"
            active={pathname === '/desempenho/geral'}
          >
            Geral
          </ContentTab>
          <ContentTab
            minW={{ base: 90, md: 160 }}
            to="/desempenho/boletim"
            active={pathname === '/desempenho/boletim'}
          >
            Boletim
          </ContentTab>
          <ContentTab
            minW={{ base: 90, md: 160 }}
            to="/desempenho/metas-semanais"
            active={pathname === '/desempenho/metas-semanais'}
          >
            Metas
          </ContentTab>
          <ContentTab
            minW={{ base: 85, md: 160 }}
            to="/desempenho/ranking"
            active={pathname === '/desempenho/ranking'}
          >
            Ranking
          </ContentTab>
        </HStack>
      </Flex>
      <Switch>
        <Route exact path="/desempenho">
          <Redirect to="/desempenho/metas-semanais" />
        </Route>
        <Route path="/desempenho/metas-semanais" component={WeekGoals} />
        <Route path="/desempenho/geral" component={GeneralPerformance} />
        <Route path="/desempenho/ranking" component={GeneralRanking} />
        <Route path="/desempenho/boletim" component={StudentReport} />
      </Switch>
    </>
  );
};

export default PerformancePage;
