import React, { FC, useContext, useMemo } from 'react';
import { Route, Switch } from 'react-router-dom';
import MenuTabs from '../../../lib/components/MenuTabs';
import AgendaSmartReviewMain from './AgendaSmartReviewMain';
import AgendaQuestionsData from '../types/AgendaQuestionsData';
import { PrivateContext } from '../../../Private.context';
import AgendaFlashcardsData from '../types/AgendaFlashcardsData';

const AgendaSmartReview: FC = () => {
  const { agenda } = useContext(PrivateContext);

  const menuItems = useMemo(() => {
    if (agenda) {
      return ({
        questao: Boolean(agenda && agenda.smartReview.questions.length > 0),
        video: false,
        apostila: false,
        'mapa-mental': false,
        flashcard: Boolean(agenda && agenda.smartReview.flashcards.length > 0),
      });
    }

    return {
      questao: false,
      video: false,
      apostila: false,
      'mapa-mental': false,
      flashcard: false,
    };
  }, [agenda]);

  return (
    <>
      <MenuTabs
        menuItems={menuItems}
        path="/agenda/revisao-inteligente"
      />
      <Switch>
        <Route
          exact
          path="/agenda/revisao-inteligente"
          component={() => <AgendaSmartReviewMain menuItems={menuItems} />}
        />
        <Route path="/agenda/revisao-inteligente/questao" component={AgendaQuestionsData} />
        <Route path="/agenda/revisao-inteligente/flashcard" component={AgendaFlashcardsData} />
      </Switch>
    </>
  );
};

export default AgendaSmartReview;
