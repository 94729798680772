import {
  Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerHeader,
  DrawerOverlay, Heading, HStack, ListItem, OrderedList, Table,
  TableContainer, Tbody, Td, Text, Th, Thead, Tr, VStack,
} from '@chakra-ui/react';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC } from 'react';
import { useDefaultStyles } from '../../../hooks/useDefaultStyles';
import { useDevice } from '../../../hooks/useDevice';
import { InlineText } from '../../_components/InlineText.component';
import { PrizeStar } from './PrizeStar';

type StudentReportDrawerType = {
  isOpen: boolean,
  onClose: () => void,
}

export const StudentReportDrawer: FC<StudentReportDrawerType> = ({ isOpen, onClose }: StudentReportDrawerType) => {
  const device = useDevice();
  const { colors } = useDefaultStyles();
  return (
    <Drawer
      isOpen={isOpen}
      placement="right"
      onClose={onClose}
      size={device === 'mobile' ? 'full' : 'md'}
    >
      <DrawerOverlay />
      <DrawerContent bgColor={colors.background}>
        <DrawerCloseButton />
        <DrawerHeader>
          <Heading size="md">Saiba mais</Heading>
        </DrawerHeader>
        <DrawerBody>
          <VStack align="left">
            <HStack flex={3} align="left">
              <PrizeStar
                label="Está é uma Rainbow Star!"
                goal={0}
                achieved={0}
              />
              <Heading size="md">Rainbow Stars</Heading>
            </HStack>
            <Text align="justify">
              As Rainbow Stars aparecem quando você cumprir uma meta mensal desafiadora
              e que te deixa mais próximo(a) da tão sonhada aprovação!
            </Text>
            <Text align="justify">
              A seguir, temos os objetivos a serem alcançados para conquistá-las:
            </Text>
            <TableContainer whiteSpace="pre-wrap">
              <Table size="sm" mt={2}>
                <Thead>
                  <Tr>
                    <Th color={colors.secondary.goLighter}>Seção</Th>
                    <Th color={colors.secondary.goLighter}>Objetivo</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  <Tr>
                    <Td>Quantidade de questões</Td>
                    <Td>Fazer 1500 questões no mês (aproximadamente 50 por dia).</Td>
                  </Tr>
                  <Tr>
                    <Td>Quantidade de flashcards</Td>
                    <Td>Fazer 1500 flashcards no mês (aproximadamente 50 por dia).</Td>
                  </Tr>
                  <Tr>
                    <Td>Desempenho e prioridades por tema</Td>
                    <Td>Acertar 1200 questões no mês (aproximadamente 40 por dia).</Td>
                  </Tr>
                  <Tr>
                    <Td>Desempenho em provas e simulados</Td>
                    <Td>
                      Desempenho maior ou igual a 95% nas questões fáceis, 75% nas médias
                      e 20% nas difíceis.
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>Troféus</Td>
                    <Td>
                      Conseguir todos os troféus ouro.
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>Frequência</Td>
                    <Td>
                      Conseguir 100% de frequência.
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>Desempenho por grandes áreas em provas e simulados</Td>
                    <Td>
                      Conseguir no mínimo 80% de desempenho em todas as grandes áreas.
                    </Td>
                  </Tr>
                </Tbody>
              </Table>
            </TableContainer>
          </VStack>
          <VStack align="left">
            <Heading mt={4} fontSize="xl">Informações importantes</Heading>
            <OrderedList spacing={4} textAlign="justify">
              <ListItem>
                As diferenças em porcentagem são
                <InlineText space="left" color={colors.secondary.goLighter}>absolutas</InlineText>
                <InlineText mr={1}>
                  ! Portanto, se você fez 25% de desempenho mês passado e 50% este mês,
                  mostraremos que você subiu 25% e não 100%!
                </InlineText>
              </ListItem>
              <ListItem>
                Todos os temas em que você precisa prestar atenção ficam destacados em
                <InlineText
                  ml={1}
                  color={colors.red.keep}
                  rightIcon={<FontAwesomeIcon icon={faExclamationCircle} />}
                >
                  vermelho
                </InlineText>
                <InlineText>ou</InlineText>
                <InlineText
                  color={colors.yellow.keep}
                  space="none"
                  rightIcon={<FontAwesomeIcon icon={faExclamationCircle} />}
                >
                  amarelo
                </InlineText>
                <InlineText>
                  . Os 5 primeiros ficam em vermelho e os demais, em amarelo.
                </InlineText>
              </ListItem>
              <ListItem>
                Na seção desempenho por tema, o desempenho é exibido no formato
                &quot;acertos / total (desempenho)&quot;. Exemplo: 60/100 (60%) significa que
                você acertou 60 questões em 100 (60%).
              </ListItem>
              <ListItem>
                O cálculo de prioridade leva em consideração seu desempenho no tema e a respectiva incidência
                nas suas instituições prioritárias.
              </ListItem>
              <ListItem mb={100}>
                O card de desempenho e prioridades por tema só aparece se você tiver feito
                questões de, no mínimo, 5 temas.
              </ListItem>
            </OrderedList>
          </VStack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};
