import { Box, Center, Heading, HStack } from '@chakra-ui/react';
import { FC, useCallback } from 'react';
import { DifficultyType } from '../../../api/answer';
import { useDefaultStyles } from '../../../hooks/useDefaultStyles';
import { useDevice } from '../../../hooks/useDevice';
import { PrizeStar } from './PrizeStar';
import { ReportLoading } from './ReportLoading';
import { ValueWithLabelAndPercent } from './ValueWithLabelAndPercent';

export type ExamsDataType = {
  countExam: number,
  answers: {
    difficulty: DifficultyType,
    countAnswer: number,
    percentage: number,
  }[],
}

type ExamsPerformanceCardType = {
  currExamsData?: ExamsDataType,
  prevExamsData?: ExamsDataType,
  loading?: boolean,
}

export const ExamsPerformanceCard: FC<ExamsPerformanceCardType> = ({
  currExamsData, prevExamsData, loading,
}: ExamsPerformanceCardType) => {
  const { cardBorder } = useDefaultStyles();
  const device = useDevice();

  const getPerformances = useCallback((data: ExamsDataType) => {
    const difficultyObject = { easy: 0, medium: 0, hard: 0 };
    data.answers.forEach(x => {
      if (x.difficulty) {
        difficultyObject[x.difficulty] += x.percentage;
      }
    });
    return difficultyObject;
  }, []);

  if (!currExamsData || !prevExamsData || loading) {
    return <ReportLoading />;
  }

  const currPerformance = getPerformances(currExamsData);
  const prevPerformance = getPerformances(prevExamsData);

  return (
    <Box
      mb={{ base: 4, md: 0 }}
      p={4}
      w={device === 'mobile' ? '100%' : '50%'}
      {...cardBorder}
    >
      <HStack flex={3}>
        <PrizeStar
          label={`Parabéns! Você fez uma média superior 95%, 75% e 20%
          nas questões de nível fácil, médio e difícil, respectivamente!`}
          goal={1}
          achieved={Number(currPerformance.easy >= 95
            && currPerformance.medium >= 75
            && currPerformance.hard >= 20)}
        />
        <Heading flex={3} size="md">Desempenho em provas e simulados</Heading>
      </HStack>
      <Center mt={5} flexFlow="column">
        <ValueWithLabelAndPercent
          label="Total feito"
          fontSize="4xl"
          previousValue={prevExamsData.countExam}
          currentValue={currExamsData.countExam}
          template="absolute verbose"
        />
        <HStack spacing={35} mt={4} alignItems="flex-start">
          <ValueWithLabelAndPercent
            label="Fáceis"
            previousValue={prevPerformance.easy}
            currentValue={currPerformance.easy}
            starThreshold={95}
          />
          <ValueWithLabelAndPercent
            label="Médias"
            previousValue={prevPerformance.medium}
            currentValue={currPerformance.medium}
            starThreshold={75}
          />
          <ValueWithLabelAndPercent
            label="Difíceis"
            previousValue={prevPerformance.hard}
            currentValue={currPerformance.hard}
            starThreshold={20}
          />
        </HStack>
      </Center>
    </Box>
  );
};
