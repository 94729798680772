import { Circle, color, HStack, Text, Tooltip } from '@chakra-ui/react';
import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useDefaultStyles } from '../../../../hooks/useDefaultStyles';
import { FlashcardsCreationContext } from '../../contexts/FlashcardsCreation.context';
import { AreaType } from '../../types/types';

export const AREAS: AreaType[] = ['CIR', 'CM', 'SC', 'GO', 'PED', 'EXT'];

export const AREAS_DICT: Record<AreaType, string[]> = {
  CIR: ['Cirurgia', 'Cirurgia Geral'],
  PED: ['Pediatria'],
  SC: ['Epidemiologia', 'Saúde Coletiva'],
  CM: ['Clínica médica', 'Clínica Médica'],
  GO: ['Ginecologia e obstetrícia', 'Ginecologia e Obstetrícia'],
  EXT: ['Extras'],
};

export const AreaFilter: FC = () => {
  const { colors } = useDefaultStyles();
  const { setActiveAreasArray } = useContext(FlashcardsCreationContext);
  const [activeAreas, setActiveAreas] = useState<Record<string, boolean>>({
    GO: true,
    PED: true,
    CIR: true,
    SC: true,
    CM: true,
    EXT: true,
  });

  useEffect(() => {
    if (setActiveAreasArray) {
      setActiveAreasArray(AREAS.filter(area => activeAreas[area]));
    }
  }, [activeAreas, setActiveAreasArray]);

  const handleAreaClick = useCallback(({ area }: { area: AreaType }) => {
    const activeCount = Object.keys(activeAreas).filter(x => activeAreas[x]).length;
    setActiveAreas(prev => {
      if (activeCount === AREAS.length) {
        return AREAS.reduce((acc, curr) => ({ ...acc, [curr]: area === curr }), {});
      }
      return { ...prev, [area]: !prev[area] };
    });
  }, [activeAreas]);

  const areas = useMemo(() => {
    const circles = AREAS.map(area => {
      const areaLen = AREAS_DICT[area].length;
      return (
        <Tooltip
          key={area}
          label={AREAS_DICT[area][areaLen - 1]}
          hasArrow
        >
          <Circle
            as="button"
            onClick={() => handleAreaClick({ area })}
            transition="background-color .2s linear"
            bgColor={activeAreas[area]
              ? `${colors.areas[area as keyof typeof color.areas]}.500`
              : 'gray.500'}
            size={8}
          >
            <Text
              color={colors.white.keep}
              fontSize="xs"
              fontWeight="bold"
            >
              {area}
            </Text>
          </Circle>
        </Tooltip>
      );
    });
    return circles;
  }, [activeAreas, colors.areas, colors.white.keep, handleAreaClick]);
  return (
    <HStack spacing={{ base: 5, md: 2 }}>
      {areas}
    </HStack>
  );
};
