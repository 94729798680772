import { Flex, FlexProps, Text } from '@chakra-ui/react';
import { FC } from 'react';
import { useDefaultStyles } from '../../../hooks/useDefaultStyles';
import { linearGradient } from '../../../utils/linearGradient';

interface DifficultyGradientBarType extends FlexProps {
  difficulty: number;
  hidePercent?: boolean;
  loading?: boolean;
}

export const DifficultyGradientBar: FC<DifficultyGradientBarType> = ({
  difficulty, hidePercent = false, loading = false, ...props
}) => {
  const { colors } = useDefaultStyles();
  return (
    <Flex
      flexFlow="column"
      h={25}
      // w="100px"
      w="100%"
      {...props}
      position="relative"
      bgColor={linearGradient(difficulty)}
      overflow="hidden"
      borderRadius={10}
    >
      <Flex
        w={`${(1 - difficulty) * 100}%`}
        h={25}
        className={loading ? 'loading-bar-animation' : ''}
        bgColor={loading ? '' : colors.darkGray.keep}
        right={0}
        position="absolute"
      />
      <Flex align="center" h={25} position="absolute" right={2}>
        {!hidePercent
          && (
            <Text fontWeight="bold" color="white" fontSize={14}>
              {`${Math.round(difficulty * 100)}%`}
            </Text>
          )}
      </Flex>
    </Flex>
  );
};
