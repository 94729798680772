import { Flex, Text } from '@chakra-ui/react';
import { FC } from 'react';

type DifficultyStatsType = {
  label: string;
  score: number;
}

type DifficultyThermometerInputType = {
  difficultyStats: DifficultyStatsType;
  hide?: boolean;
}

export const DifficultyThermometer: FC<DifficultyThermometerInputType> = ({
  difficultyStats,
  hide,
}) => {
  if (hide) {
    return null;
  }

  return (
    <Flex w="100%" fontSize="xs">
      <Text>
        {`Esta foi uma atividade considerada
                      ${difficultyStats.label
          } ("Termômetro" de dificuldade: ${difficultyStats.score})`}
      </Text>
    </Flex>
  );
};
