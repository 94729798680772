import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box, Button, Flex, HStack, Image, Modal,
  ModalBody, ModalContent, ModalOverlay, Text,
} from '@chakra-ui/react';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Loading } from '../../lib/components/Loading';
import { PrintButtonProps } from '../_components/Question/PrintButton.component';
import { QuestionBody } from '../_components/Question/QuestionBody';

const PrintQuestions: FC = () => {
  const {
    questions,
    printProps,
  }: PrintButtonProps = useMemo(() => {
    return (
      JSON.parse(localStorage.getItem('@Aristoclass:printData') || '{}')
    );
  }, []);

  const examBody = useRef<HTMLDivElement>(null);
  const [loading, setLoading] = useState(true);

  const IMAGE_COUNT = useMemo(() => {
    return questions.map(q => q.body).join('|').split('![](').length - 1;
  }, [questions]);

  const checkLoading = useCallback(() => {
    const questionString = examBody.current?.innerHTML;
    const loadedImgCount = (questionString?.match(/\.jpg|\.png/g) || []).length;

    if (loadedImgCount === IMAGE_COUNT) {
      setLoading(false);
    }
  }, [IMAGE_COUNT]);

  useEffect(() => {
    const interval = setInterval(checkLoading, 1000);
    if (!loading) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [checkLoading, loading]);

  return (
    <Box fontSize="12px">
      <Modal onClose={() => null} size="full" isOpen={loading}>
        <ModalOverlay />
        <ModalContent>
          <ModalBody justify="center" align="center" display="flex">
            <Loading fontSize="9xl" w="100%" />
          </ModalBody>
        </ModalContent>
      </Modal>
      <HStack
        align="center"
        justify="center"
        w="100%"
        my={1}
        className="hideOnPrint"
      >
        <Button
          color="aristo.500"
          leftIcon={<FontAwesomeIcon icon={faPrint} />}
          onClick={() => window.print()}
          my={2}
          bgColor="secondary.50"
          borderStyle="solid"
          borderWidth={2}
          borderColor="aristo.500"
        >
          Imprimir
        </Button>
        <Alert status="info" textAlign="center" borderRadius={5}>
          <AlertIcon />
          <AlertDescription>
            Recomenda-se aguardar alguns segundos antes de clicar em imprimir,
            para garantir que todas as imagens foram carregadas.
          </AlertDescription>
        </Alert>
      </HStack>
      <Flex
        borderWidth={2}
        borderColor="aristo.500"
        p={5}
        justify="space-between"
        align="center"
        mt={2}
        mb={4}
      >
        <Image
          src="/images/aristo-logo-slogan.png"
          w={200}
          alt="logo"
        />
        {
          printProps && (
            <Flex direction="column">
              {printProps.name && (
                <HStack>
                  <Text fontWeight="bold">
                    Atividade:
                  </Text>
                  <Text>
                    {printProps.name}
                  </Text>
                </HStack>
              )}
              {printProps.date && (
                <HStack>
                  <Text fontWeight="bold">
                    Impresso em:
                  </Text>
                  <Text>
                    {printProps.date}
                  </Text>
                </HStack>
              )}
              {printProps.institution && printProps.uf && (
                <HStack>
                  <Text fontWeight="bold">
                    Instituição:
                  </Text>
                  <Text>
                    {`${printProps.institution} - ${printProps.uf}`}
                  </Text>
                </HStack>
              )}
              {printProps.title && (
                <HStack>
                  <Text fontWeight="bold">
                    Título:
                  </Text>
                  <Text>
                    {printProps.title}
                  </Text>
                </HStack>
              )}
              {printProps.subtitle && (
                <HStack>
                  <Text fontWeight="bold">
                    Subtítulo:
                  </Text>
                  <Text>
                    {printProps.subtitle}
                  </Text>
                </HStack>
              )}
              {Boolean(printProps.year) && (
                <HStack>
                  <Text fontWeight="bold">
                    Ano:
                  </Text>
                  <Text>
                    {printProps.year}
                  </Text>
                </HStack>
              )}
            </Flex>
          )
        }
      </Flex>
      <Box style={{ columnCount: 2 }} ref={examBody}>
        {questions.map((q, index) => {
          const keyQ = index;
          return (
            <Box textAlign="justify" key={keyQ} style={{ pageBreakInside: 'avoid' }}>
              <QuestionBody
                body={q.body}
                disableHighlight
                id={q._id}
                index={index}
                info={`**${index + 1}.${printProps?.name !== 'Prova na íntegra'
                  ? ` [${q.institution} - ${q.uf} - ${q.year}]`
                  : ''}**`}
              />
              <Flex
                flexFlow="column"
                borderBottomWidth={1}
                marginBottom={5}
                borderBottomColor="dark.500"
              >
                {q.choices.length > 1 && q.choices.map((c, i) => {
                  const key = `c${i}`;
                  return (
                    <Flex
                      mb={2}
                      key={key}
                      alignItems="flex-start"
                      style={{
                        pageBreakInside: 'avoid',
                      }}
                    >
                      <Text
                        fontWeight="bold"
                        mr={2}
                        textAlign="center"
                        flexFlow="column"
                        // padding={2}
                        borderColor="black"
                        borderWidth={1}
                        borderRadius="50%"
                        minWidth={5}
                        height={5}
                        width={5}
                      >
                        {String.fromCharCode(97 + i).toUpperCase()}
                      </Text>
                      <Text>
                        {c}
                      </Text>
                    </Flex>
                  );
                })}
                {q.choices.length === 1 && (
                  <Box
                    w="100%"
                    h={200}
                    borderWidth="2px"
                    borderColor="black"
                  />
                )}
              </Flex>
              {/* <Hr /> */}
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default PrintQuestions;
