import moment from 'moment';

export const getDateParam = (): string => {
  const { search } = window.location;
  const params = new URLSearchParams(search);
  const date = params.get('date') || moment().format('YYYYMMDD');
  const today = moment().format('YYYYMMDD');
  if (date > today) {
    return today;
  }
  return date;
};
