import {
  FC, useCallback, useContext, useEffect, useState,
} from 'react';
import {
  Box, Button, Circle, Flex, HStack, Input, Text,
  Tooltip,
  useBreakpointValue, useDisclosure,
} from '@chakra-ui/react';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faCalendarAlt, faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { getDateParam } from '../../../hooks/getDateParam';
import Slider from '../../../lib/components/Slider';
import { PrivateContext } from '../../../Private.context';
import ConfirmModal from '../../../lib/components/ConfirmModal';
import { useStudentPresences } from '../../../api/student-report';
import { stringToDate } from '../../../utils/formatDate';
import { Loading } from '../../../lib/components/Loading';
import { useDefaultStyles } from '../../../hooks/useDefaultStyles';
import { useLocalStorage } from '../../../hooks/useLocalStorage';
import { HOURS_TO_EXTEND } from '../../../utils/isTodayExtended';

const WeekMenu: FC = () => {
  const { onPrevDays, onNextDays, showDays, handleDaysSlice, profile } = useContext(PrivateContext);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [lastReloadTry, setLastReloadTry] = useLocalStorage('@Aristoclass:lastReloadTry');
  const history = useHistory();
  const device = useBreakpointValue({ base: 'mobile', lg: 'web' });
  const daySelected = getDateParam();
  const { colors } = useDefaultStyles();
  const [
    dateToSwap,
    setDateToSwap,
  ] = useState(`${daySelected.slice(0, 4)}-${daySelected.slice(4, 6)}-${daySelected.slice(6)}`);

  const daysSlice = useBreakpointValue({ base: 1, lg: 7 });
  const { frequencyArray, frequencyLoading } = useStudentPresences();

  useEffect(() => {
    if (handleDaysSlice) {
      handleDaysSlice(daysSlice ?? 7);
    }
  }, [daysSlice, handleDaysSlice]);

  useEffect(() => {
    const timer = setInterval(() => {
      const date = getDateParam();
      const today = moment().format('YYYYMMDD');
      const hasTodayFrequency = frequencyArray.includes(today);
      const todayHours = new Date().getHours();
      const yesterday = moment().subtract(1, 'days').format('YYYYMMDD');
      if (
        ((date === yesterday && todayHours >= HOURS_TO_EXTEND) || (date === today))
        && !hasTodayFrequency
        && !frequencyLoading
        && lastReloadTry !== today
      ) {
        setLastReloadTry(today);
        history.push('/agenda');
        window.location.reload();
      }
    }, 2000);

    return () => clearInterval(timer);
  }, [lastReloadTry, frequencyArray, frequencyLoading, setLastReloadTry, showDays, history]);

  const handleLeftIcon = useCallback((day: string) => {
    const hasPresence = frequencyArray.includes(day);
    if (stringToDate(day) > new Date()) {
      return <></>;
    }
    if (frequencyLoading) {
      return <Loading />;
    }
    return (
      <Tooltip label={`Você ${hasPresence ? '' : 'não '}entrou na plataforma neste dia.`}>
        <Circle
          color={hasPresence ? colors.green.goDarker : colors.red.goDarker}
          bgColor={colors.lighter.fromWhite}
          size={5}
        >
          <FontAwesomeIcon
            icon={hasPresence ? faCheckCircle : faTimesCircle}
          />
        </Circle>
      </Tooltip>
    );
  }, [colors.green.goDarker, colors.lighter.fromWhite, colors.red.goDarker, frequencyArray, frequencyLoading]);

  if (device === 'mobile') {
    return showDays?.[0] ? (
      <HStack>
        <Button
          onClick={onOpen}
          color={colors.lighter.fromWhite}
          variant="ghost"
          bgColor="transparent"
          _hover={{
            bgColor: 'transparent',
          }}
          leftIcon={handleLeftIcon(showDays[0].value)}
          rightIcon={<FontAwesomeIcon icon={faCalendarAlt} />}
        >
          <Text fontWeight="bold">
            {`${showDays[0].label.charAt(0).toUpperCase()}${showDays[0].label.split('-')[0].slice(1)}`}
          </Text>
        </Button>
        <ConfirmModal
          isOpen={isOpen}
          onDecline={onClose}
          onConfirm={() => {
            history.push({
              pathname: '/agenda',
              search: `?date=${dateToSwap.replaceAll('-', '')}`,
            });
          }}
          title="Mudar dia"
          subtitle="Selecione a data para a qual deseja navegar"
          confirmText="Trocar"
          icon={<FontAwesomeIcon icon={faCalendar} />}
          declineText="Cancelar"
        >
          <Input
            mt={2}
            value={dateToSwap}
            type="date"
            min={moment(profile.createdAt).format('YYYY-MM-DD')}
            max={moment().format('YYYY-MM-DD')}
            onChange={e => setDateToSwap(e.target.value)}
          />
        </ConfirmModal>
      </HStack>
    ) : null;
  }

  return (
    <Slider
      onPrev={() => {
        if (onPrevDays) {
          onPrevDays();
        }
      }}
      onNext={() => {
        if (onNextDays) {
          onNextDays();
        }
      }}
      noNext={showDays
        && (showDays[showDays.length - 1].label === 'domingo'
          || showDays[showDays.length - 1].value === moment().format('YYYYMMDD'))}
      color={colors.lighter.fromWhite}
      widthOnMobile="55%"
    >
      <Box className={clsx('week-menu')}>
        <Box
          className="week-menu__container"
        >
          {
            showDays?.map(item => (
              <HStack key={item.value}>
                {handleLeftIcon(item.value)}
                <Flex
                  justify="center"
                  align="center"
                  direction="column"
                  flex={1}
                  _after={{
                    ...(item.value === daySelected && {
                      width: '64px', height: '3px', background: 'white', content: '""', borderRadius: '2px',
                    }),
                  }}
                  key={item.value}
                >
                  <Button
                    type="button"
                    color="light.50"
                    variant="unstyled"
                    disabled={item.time === 'future'}
                    onClick={() => {
                      history.push({
                        pathname: '/agenda',
                        search: `?date=${item.value}`,
                      });
                    }}
                  >
                    {`${item.label.charAt(0).toUpperCase()}${item.label.split('-')[0].slice(1)}`}
                  </Button>
                </Flex>
              </HStack>
            ))
          }
        </Box>
      </Box>
    </Slider>
  );
};

export default WeekMenu;
