import { Flex, Image, Text } from '@chakra-ui/react';
import { FC, useCallback, useMemo, useState } from 'react';
import { usePremiumCoursesForUser } from '../../api/aristo-premium';
import { useProfile } from '../../api/profile';
import { useDefaultStyles } from '../../hooks/useDefaultStyles';
import { useDevice } from '../../hooks/useDevice';
import { PrimaryButton } from '../../lib/components/PrimaryButton';

export const AristoPremiumPage: FC = () => {
  const { data, loading: loadingPremiumCourses } = usePremiumCoursesForUser();
  const { cardBorder } = useDefaultStyles();
  const [isLoadingSwapCourse, setIsLoadingSwapCourse] = useState(false);
  const { onSwapCourse } = useProfile();
  const device = useDevice();

  const courses = useMemo(() => {
    if (data) {
      return data.getPremiumCoursesForUser;
    } return [];
  }, [data]);

  const handleSwapCourse = useCallback(async (id: string) => {
    try {
      setIsLoadingSwapCourse(true);
      await onSwapCourse(id);
      window.location.replace('/');
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingSwapCourse(false);
    }
  }, [onSwapCourse]);

  return (
    <Flex p={{ base: '0.5rem', md: '2rem' }} justify="center">
      <Flex w="100%" flexFlow="column" maxW={1100}>
        <Text
          fontSize={{ base: '1.5rem', md: '2rem' }}
          fontWeight="bold"
          mb={2}
        >
          Aproveite seus benefícios exclusivos
        </Text>
        <Text mb={{ base: 4, md: 8 }}>
          O &quot;Aristo Premium&quot; é muito mais
          do que apenas um programa de benefícios - é a chave
          para desbloquear um mundo de conveniência, entretenimento
          e exclusividade. Desenvolvido para atender às necessidades
          daqueles que buscam uma experiência de estilo de vida
          verdadeiramente excepcional, o &quot;Aristo Premium&quot;
          redefine o significado de ser um membro VIP.
        </Text>
        <Text
          fontSize={{ base: '1.5rem', md: '2rem' }}
          fontWeight="bold"
          mb={2}
        >
          Seus cursos Aristo Premium
        </Text>
        {
          loadingPremiumCourses ? (<Text>Carregando cursos...</Text>) : courses.map(e => (
            <Flex
              // p={6}
              mb={5}
              flexFlow="column"
              {...cardBorder}
              overflow="clip"
            >
              {
                e.image ? (
                  <Image src={e.image} alt="icon" height={200} objectFit="cover" objectPosition="0 0" />
                ) : null
              }
              <Flex
                p={{ base: 5, md: 10 }}
                justify="space-between"
                align="center"
                flexFlow={{ base: 'column', md: 'row' }}
              >
                <Flex flexFlow="column" mb={5}>
                  <Text
                    fontSize={{ base: '1rem', md: '1.2rem' }}
                    fontWeight="bold"
                  >
                    {e.title}
                  </Text>
                  <Text>{e.description}</Text>
                </Flex>
                <Flex
                  w={device === 'mobile' ? '100%' : ''}
                  ml={device === 'mobile' ? 0 : 15}
                  justify="flex-end"
                >
                  <PrimaryButton
                    onClick={() => handleSwapCourse(e.courseId)}
                    isLoading={isLoadingSwapCourse}
                    w="100%"
                    disabled={isLoadingSwapCourse}
                  >
                    Ir para o curso
                  </PrimaryButton>
                </Flex>
              </Flex>
            </Flex>
          ))
        }
      </Flex>
    </Flex>
  );
};
