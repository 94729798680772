import { Box, Button, HStack, Text } from '@chakra-ui/react';
import { faCaretLeft, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback, useContext, useMemo, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useAgenda } from '../../../api/agenda/agenda';
import { getDateParam } from '../../../hooks/getDateParam';
import { useDefaultStyles } from '../../../hooks/useDefaultStyles';
import { PrivateContext } from '../../../Private.context';
import { isTodayExtended } from '../../../utils/isTodayExtended';
import { AgendaContext } from '../Agenda.context';
import { FinishLessonButton } from './FinishLessonButton';

interface TheoreticalLessonNavigatorProps {
  lessons?: {
    lessonId: string;
    finished: boolean;
    lessonTitle: string;
    classification: string[];
  }[];
  type: 'study' | 'review';
}

export function TheoreticalLessonNavigator({
  lessons, type,
}: TheoreticalLessonNavigatorProps) {
  const { onFinishAgendaActivityContent } = useAgenda();
  const params = useParams<{ lessonId: string }>();
  const { refetchAgenda } = useContext(PrivateContext);
  const { percentOfQuestionsAnswered } = useContext(AgendaContext);
  const history = useHistory();
  const { colors: defaultColors } = useDefaultStyles();

  const handleQuery = useCallback(async () => {
    try {
      await onFinishAgendaActivityContent({
        calendarDate: getDateParam(),
        lessonId: params.lessonId,
        theoreticalType: type === 'study' ? 'theoreticalStudy' : 'theoreticalReview',
      });
      await refetchAgenda();
    } catch (error) {
      console.error(error);
    }
  }, [onFinishAgendaActivityContent, params.lessonId, refetchAgenda, type]);

  const lesson = useMemo(() => {
    if (lessons) {
      return lessons.find(item => item.lessonId === params.lessonId);
    }
    return undefined;
  }, [lessons, params.lessonId]);

  const lessonIdsMap = useMemo(() => {
    if (lessons) {
      return lessons.map(ls => ls.lessonId);
    }
    return [];
  }, [lessons]);

  const [currentClass, setCurrentClass] = useState(0);

  const handleSlideLesson = useCallback((step = 1) => {
    history.push(`/agenda/estudo-teorico/${lessonIdsMap[currentClass + step]}${window.location.search}`);
    setCurrentClass(prev => prev + step);
  }, [currentClass, history, lessonIdsMap]);

  return (
    <Box
      m="0 auto"
      py={4}
      px={{ base: 3, md: 0 }}
      mx={{ base: -3, md: 0 }}
    >
      <HStack justify="space-between">
        <HStack>
          <Button
            disabled={currentClass === 0}
            variant="ghost"
            onClick={() => handleSlideLesson(-1)}
          >
            <FontAwesomeIcon icon={faCaretLeft} />
          </Button>
          <Text noOfLines={2} fontSize={{ base: 'sm', md: 'xl' }}>
            {lesson?.lessonTitle || lesson?.classification[1]}
          </Text>
          <Button
            variant="ghost"
            // color="primary.600"
            disabled={currentClass === lessonIdsMap.length - 1}
            onClick={() => handleSlideLesson()}
          >
            <FontAwesomeIcon icon={faCaretRight} />
          </Button>
        </HStack>
        {
          isTodayExtended() && type === 'review' && (
            <FinishLessonButton
              isTheoreticalReview
              modalTitle="Concluir revisão"
              finishedText="Revisão concluída"
              unfinishedText="Concluir revisão"
              isFinished={lesson?.finished}
              isConfirmDisabled={percentOfQuestionsAnswered !== 1}
              onConfirm={async () => {
                await handleQuery();
                if (currentClass !== lessonIdsMap.length - 1) {
                  history.push(`/agenda/estudo-teorico/${lessonIdsMap[currentClass + 1]}${window.location.search}`);
                  setCurrentClass(prev => prev + 1);
                }
              }}
            >
              {
                percentOfQuestionsAnswered !== 1 ? (
                  <>
                    <Text color={defaultColors.red.keep} textAlign="center">
                      Faça no mínimo 10 questões para concluir.
                    </Text>
                    <Text textAlign="center">
                      Assim, poderemos atestar que você está bem no tema, evitindo repetições nas RTs.
                    </Text>
                  </>
                ) : (
                  <Text>
                    {
                      `Tem certeza que deseja concluir a revisão
                      do tema ${lesson?.lessonTitle}? Esse processo é irreversível.`
                    }
                  </Text>

                )
              }
            </FinishLessonButton>
          )
        }
        {
          type === 'study' && isTodayExtended() && (
            <FinishLessonButton
              modalTitle="Concluir tema"
              finishedText="Tema concluído"
              unfinishedText="Concluir tema"
              isFinished={lesson?.finished}
              onConfirm={async () => {
                await handleQuery();
                if (currentClass !== lessonIdsMap.length - 1) {
                  history.push(`/agenda/estudo-teorico/${lessonIdsMap[currentClass + 1]}${window.location.search}`);
                  setCurrentClass(prev => prev + 1);
                }
              }}
            >
              <Box w="100%" textAlign="center">
                {`Tem certeza que deseja concluir ${lesson ? 'o tema' : 'esse tema'}`}
                {lesson && (
                  <Box
                    fontWeight="bold"
                    display="inline-block"
                    color={defaultColors.secondary.goALittleDarker}
                    ml={1}
                  >
                    {lesson.lessonTitle}
                  </Box>
                )}
                ? Essa ação é irreversível.
              </Box>
            </FinishLessonButton>
          )
        }
      </HStack>
      <HStack mt={2}>
        {type === 'study' && lessons && lessons.map((item, index) => {
          const colors = () => {
            if (item?.finished) {
              return {
                bgColor: 'green.400',
                borderColor: 'green.400',
              };
            }
            if (params.lessonId === item.lessonId) {
              return {
                bgColor: 'transparent',
                borderColor: 'green.400',
              };
            }
            return {
              bgColor: 'gray.400',
              borderColor: 'gray.400',
            };
          };
          return (
            <Box
              key={item.lessonId}
              as="button"
              h="5px"
              flex={1}
              bgColor={colors().bgColor}
              borderColor={colors().borderColor}
              borderWidth="1px"
              borderStyle="solid"
              onClick={() => {
                setCurrentClass(index);
                history.push(`/agenda/estudo-teorico/${item.lessonId}${window.location.search}`);
              }}
            />
          );
        })}
        {type === 'review' && lessons && isTodayExtended() && lessons.map(item => {
          const colors = () => {
            if (item.finished) {
              return {
                bgColor: 'green.400',
                borderColor: 'green.400',
              };
            }
            if (params.lessonId === item.lessonId) {
              return {
                bgColor: 'transparent',
                borderColor: 'green.400',
              };
            }
            return {
              bgColor: 'gray.400',
              borderColor: 'gray.400',
            };
          };
          return (
            <Box
              key={item.lessonId}
              as="button"
              h="5px"
              flex={1}
              bgColor={colors().bgColor}
              borderColor={colors().borderColor}
              borderWidth="1px"
              borderStyle="solid"
              onClick={() => history.push(`/agenda/revisao-teorica/${item.lessonId}${window.location.search}`)}
            />
          );
        })}
      </HStack>
    </Box>
  );
}
