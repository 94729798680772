import {
  FC, useEffect, useState,
} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-regular-svg-icons';
import { Box, Modal, ModalBody, ModalContent, ModalHeader, ModalOverlay, Text } from '@chakra-ui/react';
import QuestionModal from './Question.modal';
import FlashcardModal from './Flashcard.modal';
import TheoricContentModal from './TheoricContent.modal';
import ExamModal from './Exam.modal';
import MockExamModal from './MockExam.modal';
import SmartMockExamModal from './SmartMockExam.modal';
import TheoreticalReviewModal from './TheoreticalReview.modal';
import SmartReviewModal from './SmartReview.modal';
import { useDefaultStyles } from '../../hooks/useDefaultStyles';

export type CreateActivityStates = '' | 'choose' | 'question' | 'flashcard'
  | 'theoricContent' | 'exam' | 'mockExam' | 'smartMockExam' | 'extra'
  | 'theoreticalReview' | 'smartReview';

interface CreateActivityProps {
  onClose?: () => void;
  onAfterCreate?: () => void;
  isOpen?: boolean;
  selected?: CreateActivityStates;
}

export interface ExtraActivityModalProps {
  onClose: () => void;
  onAfterCreate?: () => void;
}

const CreateActivity: FC<CreateActivityProps> = ({
  onClose = () => ({}), onAfterCreate = () => ({}), isOpen = false, selected = '',
}) => {
  const [state, setState] = useState<CreateActivityStates>(selected);
  const { modal } = useDefaultStyles();

  useEffect(() => setState(selected), [selected]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent mx={4} borderRadius={10} overflow="hidden">
        <ModalHeader
          py={2}
          display="flex"
          alignItems="center"
          bgColor={modal.header.bgColor}
          color={modal.header.color}
        >
          <Box position="absolute" top={2} left={3}>
            <FontAwesomeIcon icon={faEdit} />
          </Box>
          <Text flex={1} textAlign="center">Atividade extra</Text>
        </ModalHeader>
        <ModalBody bgColor={modal.body.bgColor}>
          {
            state === 'theoricContent' && <TheoricContentModal onClose={onClose} onAfterCreate={onAfterCreate} />
          }
          {
            state === 'exam' && <ExamModal onClose={onClose} onAfterCreate={onAfterCreate} />
          }
          {
            state === 'question' && <QuestionModal onClose={onClose} onAfterCreate={onAfterCreate} />
          }
          {
            state === 'flashcard' && <FlashcardModal onClose={onClose} onAfterCreate={onAfterCreate} />
          }
          {
            state === 'mockExam' && <MockExamModal onClose={onClose} onAfterCreate={onAfterCreate} />
          }
          {
            state === 'smartMockExam' && <SmartMockExamModal onClose={onClose} onAfterCreate={onAfterCreate} />
          }
          {
            state === 'theoreticalReview' && <TheoreticalReviewModal onClose={onClose} onAfterCreate={onAfterCreate} />
          }
          {
            state === 'smartReview' && <SmartReviewModal onClose={onClose} onAfterCreate={onAfterCreate} />
          }
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default CreateActivity;
