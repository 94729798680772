import { Text, Flex, Button, VStack, HStack, Stack, useColorMode, Box, Center } from '@chakra-ui/react';
import { FC, useCallback, useMemo, useState } from 'react';
import { useDevice } from '../../../../hooks/useDevice';
import { useDefaultStyles } from '../../../../hooks/useDefaultStyles';
import { PrimaryButton } from '../../../../lib/components/PrimaryButton';

interface ModalStep1Props {
  onSetScore: (score: number) => void,
  onReplyLater: () => void,
  onRefuse: () => void,
  onNext: () => void;
  disabled: boolean;
}

export const ModalStep1: FC<ModalStep1Props> = ({
  onSetScore,
  onReplyLater,
  onRefuse,
  onNext,
  disabled,
}) => {
  const device = useDevice();
  const { colors } = useDefaultStyles();
  const { colorMode } = useColorMode();
  const [selectedScore, setSelectedScore] = useState<number>();

  const scores = useMemo(() => {
    return Array.from({ length: 11 }, (_, index) => index);
  }, []);

  const isLight = useMemo(() => colorMode === 'light', [colorMode]);

  const handleSetScore = useCallback((value: number) => {
    setSelectedScore(value);
    onSetScore(value);
  }, [onSetScore]);

  return (
    <VStack align="left">
      <Box p="8px 16px" backgroundColor="#041223">
        <Text fontSize="20px" fontWeight="bold" color="#89B2F9">O que você acha da Aristo?</Text>
      </Box>
      <Box p="8px 16px">
        <Text>Em uma escala de 0 a 10, qual é a probabilidade de você recomendar a Aristo a um amigo ou colega?</Text>
      </Box>
      <VStack
        width="100%"
        padding="16px"
        borderTop={`1px solid ${colors.darkGray.keep}`}
        borderBottom={`1px solid ${colors.darkGray.keep}`}
        backgroundColor={isLight ? '#E7E8EE' : '#282A2F'}
      >
        <Flex flexWrap="wrap" align="center" justifyContent="center" gridGap="4px">
          {scores.map((it, index) => {
            return (
              <Center
                key={it}
                height="40px"
                width="40px"
                onClick={() => handleSetScore(index)}
                rounded="full"
                border={`1px solid ${isLight ? '#C4C6CF' : '#45474E'}`}
                backgroundColor={selectedScore === index ? colors.customColors.lightBlue : undefined}
                sx={{ cursor: 'pointer' }}
                color={selectedScore === index ? 'white' : undefined}
              >
                {it}
              </Center>
            );
          })}
        </Flex>
        <HStack width="100%" justifyContent="space-between">
          <Text fontSize="11px">Improvável</Text>
          <Text fontSize="11px">Muito provável</Text>
        </HStack>
      </VStack>
      <Stack
        p="16px"
        direction={device === 'mobile' ? 'column-reverse' : 'row'}
        justifyContent="space-between"
        align="center"
      >
        <Stack
          direction={device === 'mobile' ? 'column-reverse' : 'row'}
          spacing="8px"
          align="center"
          w={device === 'mobile' ? '100%' : undefined}
        >
          <Button
            variant="ghost"
            fontSize="14px"
            w={device === 'mobile' ? '100%' : undefined}
            onClick={onRefuse}
            color="#A3AEBF"
          >
            Não responder
          </Button>
          <Button
            variant="outline"
            fontSize="14px"
            w={device === 'mobile' ? '100%' : undefined}
            onClick={onReplyLater}
            borderColor={isLight ? undefined : colors.customColors.lightBlue}
            color={isLight ? colors.customColors.lightBlue : undefined}
          >
            Responder depois
          </Button>
        </Stack>
        <PrimaryButton
          disabled={disabled}
          fontSize="14px"
          colorScheme=""
          w={device === 'mobile' ? '100%' : undefined}
          onClick={onNext}
          backgroundColor={colors.customColors.lightBlue}
        >
          Próximo
        </PrimaryButton>
      </Stack>
    </VStack>
  );
};
