import { useCallback, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { Flashcard } from '../api/agenda/flashcards';
import { Question } from '../api/agenda/questions';
import { getId, setId } from './getId';

interface UseQuestionControlInterface {
  index: number;
  onPrev: () => void;
  onNext: () => void;
  onIndex: (value: number) => () => void;
}

export default function useQuestionControls(
  data: Question[] | Flashcard[],
  hiddenIndexes: number[] = [],
): UseQuestionControlInterface {
  const history = useHistory();
  const id = getId();

  const index = useMemo(() => {
    const x = data.findIndex((i: { _id: string }) => i._id === id);
    if (x === -1) {
      return 0;
    }
    return x;
  }, [data, id]);

  const visibleIndexes = useMemo(() => {
    const allIndexes = data.map((_, i) => i);
    return allIndexes.filter(x => !hiddenIndexes.includes(x));
  }, [data, hiddenIndexes]);

  const getNextIndex = useCallback((currIndex: number) => {
    const _visibleIndexes = visibleIndexes || [];
    return _visibleIndexes.filter(i => i > currIndex)[0] || _visibleIndexes[_visibleIndexes.length - 1];
  }, [visibleIndexes]);

  const getPrevIndex = useCallback((currIndex: number) => {
    const _visibleIndexes = visibleIndexes || [];
    const lowerIndexes = _visibleIndexes.filter(i => i < currIndex);
    return lowerIndexes[lowerIndexes.length - 1] || _visibleIndexes[0];
  }, [visibleIndexes]);

  /** Callbacks */
  const handleNext = useCallback(() => {
    if (visibleIndexes.length !== 0) {
      history.replace(setId(data[getNextIndex(index)]._id));
    }
  }, [data, getNextIndex, history, index, visibleIndexes.length]);

  const handlePrev = useCallback(() => {
    if (visibleIndexes.length !== 0) {
      history.replace(setId(data[getPrevIndex(index)]._id));
    }
  }, [data, getPrevIndex, history, index, visibleIndexes.length]);

  const handleIndex = useCallback((val: number) => () => {
    history.replace(setId(data[val]._id));
  }, [data, history]);

  /** Effect */
  useEffect(() => {
    if (!data[index] || !id) {
      history.replace(setId(data[0]._id));
    }
  }, [data, history, id, index]);

  return {
    index,
    onNext: handleNext,
    onPrev: handlePrev,
    onIndex: handleIndex,
  };
}
