import { FC, useContext } from 'react';
import { Redirect } from 'react-router-dom';
import { GlobalContext } from '../../Global.context';
import { PrivateContext } from '../../Private.context';
import { EmptyPage } from './_components/EmptyPage';

const MainAgenda: FC = () => {
  const { hasAgenda, agenda } = useContext(PrivateContext);
  const { isSprintCourse } = useContext(GlobalContext);

  if (isSprintCourse) {
    return (
      <Redirect
        to="/sprint"
      />
    );
  }

  if (hasAgenda.theoreticalStudy && agenda) {
    return (
      <Redirect
        to={`/agenda/estudo-teorico/${agenda.theoreticalStudy[0].lessonId}${window.location.search}`}
      />
    );
  }

  if (hasAgenda.theoreticalReview && agenda) {
    return (
      <Redirect
        to={`/agenda/revisao-teorica/${agenda.theoreticalReview[0].lessonId}${window.location.search}`}
      />
    );
  }

  if (hasAgenda.smartReview) {
    return <Redirect to={`/agenda/revisao-inteligente${window.location.search}`} />;
  }

  if (hasAgenda.examType === 'exams') {
    return <Redirect to={`/agenda/prova${window.location.search}`} />;
  }

  if (hasAgenda.examType === 'mocks') {
    return <Redirect to={`/agenda/simulado${window.location.search}`} />;
  }

  if (hasAgenda.examType === 'smart-exams') {
    return <Redirect to={`/agenda/simulado-inteligente${window.location.search}`} />;
  }

  return (
    <EmptyPage />
  );
};

export default MainAgenda;
