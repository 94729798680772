import { Box, Text, VStack } from '@chakra-ui/react';
import { FC, useMemo, useState } from 'react';
import { CompassInstitutionInfoType } from '../../../api/aristo-compass/exam-statistics';
import { useDefaultStyles } from '../../../hooks/useDefaultStyles';
import { useDevice } from '../../../hooks/useDevice';
import useTableFilter from '../../../hooks/useTableFilter';
import TableHeaderComponent, { HeaderProps } from '../../../lib/components/TableHeader.component';
import TableRowComponent from '../../../lib/components/TableRow.component';
import { DiagnosticTableMobileNav } from '../../diagnosis/_components/DiagnosticTableMobileNav';
import { DifficultyGradientBar } from './DifficultyGradientBar';
import { TableVisibleLinesHandler } from './TableVisibleLinesHandler';

type CompassInstitutionTableType = {
  institutionsInfo?: CompassInstitutionInfoType;
}

type TrendTextType = {
  percent?: number,
}

const TrendText: FC<TrendTextType> = ({ percent }) => {
  const { colors } = useDefaultStyles();

  const config = useMemo(() => {
    if (percent !== undefined) {
      if (percent <= 0.4) {
        return {
          text: 'Raramente',
          color: colors.green.keep,
        };
      }
      if (percent <= 0.6) {
        return {
          text: 'Eventualmente',
          color: colors.yellow.keep,
        };
      }
      if (percent < 1) {
        return {
          text: 'Quase sempre',
          color: colors.orange.keep,
        };
      }
      if (percent === 1) {
        return {
          text: 'Sempre 🔥',
          color: colors.red.keep,
        };
      }
    }
    return {
      text: '-',
      color: undefined,
    };
  }, [colors.green.keep, colors.orange.keep, colors.red.keep, colors.yellow.keep, percent]);

  return (
    <Text fontSize={12} color={config.color}>{config.text}</Text>
  );
};

export const CompassInstitutionTable: FC<CompassInstitutionTableType> = ({
  institutionsInfo,
}) => {
  const [noOfVisibleLines, setNoOfVisibleLines] = useState(5);
  const device = useDevice();
  const [currColumnOnMobile, setCurrColumnOnMobile] = useState(0);

  const { dataFiltered: crFiltered, filter, handleFilter } = useTableFilter(
    [...(institutionsInfo || [])]
      .sort((a, b) => {
        return (a.numberOfQuestions > b.numberOfQuestions) ? -1 : 1;
      }),
  );
  const crFilteredSlice = useMemo(() => {
    return crFiltered.slice(0, noOfVisibleLines);
  }, [crFiltered, noOfVisibleLines]);

  const tableHeaders = useMemo((): HeaderProps[] => {
    return (
      [
        {
          key: 'leaf',
          value: 'Tema',
          flex: { base: 5, md: 10 },
          alignItems: 'left',
          title: 'Tema',
        },
        {
          key: 'numberOfQuestions',
          value: (
            <VStack spacing={0}>
              <Text noOfLines={1}>N° questões</Text>
              <Text noOfLines={1}>(≤ 5 anos)</Text>
            </VStack>
          ),
          flex: { base: 2, md: 3 },
          textAlign: 'center',
          alignItems: 'center',
          title: 'Nível de incidência do tema nas instituições escolhidas por você.',
          showOnMobile: currColumnOnMobile === 0,
        },
        {
          key: 'difficulty',
          value: 'Dificuldade',
          flex: { base: 2, md: 3 },
          textAlign: 'center',
          alignItems: 'center',
          title: 'Nível de dificuldade do tema nas instituições escolhidas por você.',
          showOnMobile: currColumnOnMobile === 1,
        },
        {
          key: 'constancy',
          value: (
            <VStack spacing={0}>
              <Text noOfLines={1}>Constância de</Text>
              <Text noOfLines={1}>aparição</Text>
            </VStack>
          ),
          flex: { base: 2, md: 3 },
          alignItems: 'center',
          textAlign: 'center',
          title: 'Constância de aparição do tema no decorrer dos anos.',
          showOnMobile: currColumnOnMobile === 2,
        },
      ]
    );
  }, [currColumnOnMobile]);

  return (
    <Box
      maxWidth={1120}
      width="100%"
      margin="0 auto"
      borderRadius="1rem"
      marginBottom="2rem"
    >
      <DiagnosticTableMobileNav
        noOfSteps={tableHeaders.length - 2}
        tableStep={currColumnOnMobile}
        setTableStep={setCurrColumnOnMobile}
      />
      <TableHeaderComponent
        paddingLeft=".5rem"
        fontSize={13}
        headers={tableHeaders}
        handleFilter={handleFilter}
        filter={filter}
      />
      {
        crFilteredSlice.map((item, i) => {
          const subthemes = item.leaf;
          const len = subthemes.length;
          const lastTwoST = `${subthemes[len - 2]} - ${subthemes[len - 1]}`;
          return (
            <TableRowComponent
              key={Math.random().toString(36).slice(2)}
              tableStyle="compass"
              paddingLeft={device === 'mobile' ? '1rem' : '.8rem'}
              rowBackgroundColorWhite={i % 2}
              rowData={[
                {
                  key: 'leaf',
                  value: (
                    <Text
                      title={item.leaf.join(' - ')}
                    >
                      {lastTwoST}
                    </Text>
                  ),
                  flexSpan: device === 'web' ? 3 : 2,
                  align: 'left',
                },
                {
                  key: 'numberOfQuestions',
                  value: item.numberOfQuestions ? <Text>{item.numberOfQuestions}</Text> : '-',
                  align: 'center',
                  flexSpan: 1,
                  showOnMobile: currColumnOnMobile === 0,
                },
                {
                  key: 'difficulty',
                  value: item.difficulty ? <DifficultyGradientBar hidePercent difficulty={item.difficulty} /> : '-',
                  alignItems: 'center',
                  justifyContent: 'center',
                  margin: 'auto',
                  showOnMobile: currColumnOnMobile === 1,
                  flexSpan: 1,
                },
                {
                  key: 'constancy',
                  align: 'center',
                  value: <TrendText percent={item.constancy} />,
                  showOnMobile: currColumnOnMobile === 2,
                  flexSpan: 1,
                },
              ]}
            />
          );
        })
      }
      <TableVisibleLinesHandler
        maxLen={crFiltered.length}
        noOfVisibleLines={noOfVisibleLines}
        setNoOfVisibleLines={setNoOfVisibleLines}
      />
    </Box>
  );
};
