import { useColorModeValue } from '@chakra-ui/react';
import {
  Dispatch, SetStateAction, useCallback, useMemo, useState,
} from 'react';

interface UseHighlightHook {
  currentColor: number;
  onChangeColor: () => void;
  highlightRange: (range: Range) => void;
  highlightSelection: () => void;
  highlightIsActive: boolean;
  setHighlightIsActive: Dispatch<SetStateAction<boolean>>;
}

export function useHighlight(): UseHighlightHook {
  const [currentColor, setCurrentColor] = useState(0);
  const [highlightIsActive, setHighlightIsActive] = useState(false);
  const colorMode = useColorModeValue('hl-light', 'hl-dark');

  const listOfColors = useMemo(() => ([
    `highlight-bg-blue ${colorMode}`,
    `highlight-bg-red ${colorMode}`,
    `highlight-bg-green ${colorMode}`,
    `highlight-bg-yellow ${colorMode}`,
  ]
  ), [colorMode]);

  const onChangeColor = useCallback(() => {
    setCurrentColor(prev => (prev + 1) % listOfColors.length);
  }, [listOfColors]);

  const highlightRange = useCallback((range: Range) => {
    const newNode = document.createElement('mark');
    newNode.setAttribute(
      'class',
      listOfColors[currentColor],
    );
    range.surroundContents(newNode);
  }, [currentColor, listOfColors]);

  const highlightSelection = useCallback(() => {
    if (document) {
      const userSelection = document.getSelection();
      if (userSelection) {
        try {
          const range = userSelection.getRangeAt(0);
          highlightRange(range);
        } catch {
          console.log('error on selection');
        }
      }
    }
  }, [highlightRange]);

  return {
    currentColor,
    onChangeColor,
    highlightRange,
    highlightSelection,
    highlightIsActive,
    setHighlightIsActive,
  };
}
