import React, { FC, HTMLAttributes } from 'react';
import clsx from 'clsx';

export interface FlexProps {
  align?: 'start' | 'end' | 'center' | 'stretch';
  justify?: 'start' | 'end' | 'center' | 'space-between' | 'space-around' | 'space-evenly' | 'stretch';
  direction?: 'row' | 'column';
  wrap?: boolean;
  inline?: boolean;
  className?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [x: string]: any;
}

const Flex: FC<FlexProps> = ({
  align,
  justify,
  direction,
  wrap,
  inline,
  className,
  children,
  ...rest
}) => {
  const cls = clsx(
    'd-flex',
    align && `align-items-${align}`,
    justify && `justify-content-${justify}`,
    direction && `flex-${direction}`,
    {
      'flex-wrap': wrap,
      'flex-inline': inline,
    },
    className,
  );

  return (
    <div {...rest} className={cls}>
      {children}
    </div>
  );
};

interface FlexItemProps extends HTMLAttributes<HTMLDivElement> {
  flex?: number;
}

export const FlexItem: FC<FlexItemProps> = ({
  children, flex = 1, style, ...rest
}) => {
  const stl = {
    ...style,
    minWidth: 0,
    flex,
  };
  return (
    <div style={stl} {...rest}>
      {children}
    </div>
  );
};

export default Flex;
