import { Button, Flex, HStack, Input, Tooltip } from '@chakra-ui/react';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC, useCallback, useContext } from 'react';
import { useDefaultStyles } from '../../../../hooks/useDefaultStyles';
import { useLocalStorage } from '../../../../hooks/useLocalStorage';
import { toast } from '../../../../utils/toast';
import { FlashcardsCreationContext } from '../../contexts/FlashcardsCreation.context';
import { OrderOptionType } from '../../types/types';
import { OrderSelector } from '../Navigators/OrderSelector';

export const ContentSearchBar: FC = () => {
  const {
    refetch,
    setSkip,
    currLessonId,
    cardsSearchInput,
    setCardsSearchInput,
    tab,
    selectedOrderOption,
    setSelectedOrderOption,
  } = useContext(FlashcardsCreationContext);
  const [storageSearchInput, setStorageSearchInput] = useLocalStorage('@Aristoclass:contentSearchValues');

  const changeSortBy = useCallback(async (sortBy: OrderOptionType) => {
    try {
      if (refetch) {
        await refetch({
          targets: ['community-flashcards'],
          params: {
            sortBy,
            includes: cardsSearchInput,
          },
        });
      }
      setStorageSearchInput({
        ...storageSearchInput,
        [currLessonId]: {
          searchBy: cardsSearchInput,
          sortBy,
        },
      });
      setSelectedOrderOption(sortBy);
    } catch {
      toast.error({
        title: 'Não foi possível ordenar os flashcards.',
        description: 'Verifique sua conexão e tente novamente',
      });
    }
    if (setSkip) {
      setSkip(0);
    }
  }, [
    cardsSearchInput, currLessonId, refetch, setSelectedOrderOption,
    setSkip, setStorageSearchInput, storageSearchInput,
  ]);

  const { colors } = useDefaultStyles();

  if (tab === 'meus-baralhos') {
    return null;
  }

  return (
    <Flex
      style={{ gap: 20 }}
      w="100%"
      justify="space-between"
      flexFlow={{ base: 'column', md: 'row' }}
      mx={5}
    >
      <HStack
        w={{ md: '85%' }}
        border="1px solid"
        borderColor={colors.secondary.keep}
        borderX="none"
        borderTop="none"
        overflow="hidden"
      >
        <Input
          border="none"
          _focus={{ border: 'none' }}
          fontSize="sm"
          _placeholder={{ color: colors.secondary.keep }}
          placeholder="Pesquisar por conteúdo..."
          value={cardsSearchInput}
          onChange={e => setCardsSearchInput(e.target.value)}
          onKeyUp={e => {
            if (e.code === 'Enter') {
              changeSortBy(selectedOrderOption);
            }
          }}
        />
        <OrderSelector
          onOptionClick={changeSortBy}
          selectedOrderOption={selectedOrderOption}
        />
        <Tooltip label="Pesquisar" hasArrow>
          <Button
            variant="ghost"
            color={colors.secondary.keep}
            _focus={{ outline: 'none' }}
            onClick={() => changeSortBy(selectedOrderOption)}
          >
            <FontAwesomeIcon icon={faArrowRight} />
          </Button>
        </Tooltip>
      </HStack>
    </Flex>
  );
};
