import { Box } from '@chakra-ui/react';
import { faFacebook, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC, useMemo } from 'react';
import { useDefaultStyles } from '../../../hooks/useDefaultStyles';

type SocialMedia = 'facebook' | 'instagram' | 'youtube'
type SocialMediaIconType = {
  type: SocialMedia,
}

export const SocialMediaIcon: FC<SocialMediaIconType> = ({
  type,
}) => {
  const { colors } = useDefaultStyles();
  const links: Record<SocialMedia, string> = {
    facebook: 'https://www.facebook.com/aristoresidenciamedica/',
    instagram: 'https://www.instagram.com/aristoresidenciamedica/',
    youtube: 'https://www.youtube.com/c/AristoResidenciaMedica',
  };
  const icon = useMemo(() => {
    if (type === 'facebook') {
      return faFacebook;
    }
    if (type === 'instagram') {
      return faInstagram;
    }
    return faYoutube;
  }, [type]);

  return (
    <Box
      as="button"
      onClick={() => window.open(links[type], '_blank')}
      fontSize={25}
      _hover={{ color: colors.white.keep }}
      transition="color 0.5s"
    >
      <FontAwesomeIcon icon={icon} />
    </Box>
  );
};
