import { Box, Flex, HStack, ScaleFade } from '@chakra-ui/react';
import { FC, ReactNode } from 'react';
import { PrimaryButton } from '../../../lib/components/PrimaryButton';
import { SecondaryButton } from '../../../lib/components/SecondaryButton';
import { ReturnButton, ReturnButtonProps } from './ReturnButton.component';

interface FirstModalButton {
  label: string;
  onClick: () => void;
}

interface FirstModalBodyProps {
  returnButton?: ReturnButtonProps;
  children?: ReactNode;
  primaryButton?: FirstModalButton;
  secondaryButton?: FirstModalButton;
  buttonsPosition?: string;
  show?: boolean;
}

export const FirstModalBody: FC<FirstModalBodyProps> = ({
  returnButton,
  children,
  primaryButton,
  secondaryButton,
  buttonsPosition = 'flex-end',
  show = true,
}: FirstModalBodyProps) => {
  return show ? (
    <ScaleFade
      initialScale={0}
      in
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'auto',
        padding: '1.5rem',
      }}
    >
      <Box
        margin="auto"
        width={{ base: '20rem', md: '45rem' }}
        fontSize="1rem"
      >
        {returnButton && (
          <ReturnButton
            label={returnButton.label}
            onClick={returnButton.onClick}
          />
        )}
        {children}
        {(primaryButton || secondaryButton) && (
          <Flex marginTop="1rem" justifyContent={{ base: 'center', md: buttonsPosition }} width="100%">
            <HStack gridGap={2} minWidth={{ base: '75%', md: '35%' }}>
              {secondaryButton && (
                <SecondaryButton
                  width="100%"
                  marginX="auto"
                  onClick={secondaryButton.onClick}
                >
                  {secondaryButton.label}
                </SecondaryButton>
              )}
              {primaryButton && (
                <PrimaryButton
                  width="100%"
                  marginX="auto"
                  onClick={primaryButton.onClick}
                >
                  {primaryButton.label}
                </PrimaryButton>
              )}
            </HStack>
          </Flex>
        )}
      </Box>
    </ScaleFade>
  ) : null;
};
