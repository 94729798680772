import React, {
  FC, useContext, useMemo,
} from 'react';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from '@chakra-ui/react';
import { getActivityIndex } from '../../../hooks/getActivityIndex';
import Flex from '../../../lib/components/Flex';
import Text from '../../../lib/components/Text';
import { AgendaContext } from '../Agenda.context';
import { useGetExtraSmartExams } from '../../../api/extra-activities/extra-smart-exams';
import { ExtraHeader } from './_components/ExtraHeader';
import { Questions } from '../types/Questions.component';

const SmartExamExtraContainer: FC = () => {
  const agendaCtx = useContext(AgendaContext);

  const id = getActivityIndex();

  const actualId = useMemo(() => {
    const qs = agendaCtx.extras.activitySmartExamExtra;

    const index = qs?.findIndex(x => x._id === id) || 0;

    if (qs && qs.length > 0 && index !== -1) {
      return qs[index]._id;
    }

    if (qs && qs.length > 0) {
      return qs[0]._id;
    }

    return '';
  }, [agendaCtx.extras.activitySmartExamExtra, id]);

  if (agendaCtx.extrasLoading) {
    return (
      <Flex align="center" justify="center" style={{ minHeight: 400 }}>
        <FontAwesomeIcon icon={faSpinner} spin size="6x" className="color-dark-gray" />
      </Flex>
    );
  }

  return (
    <Box maxW={900} m="0 auto">
      <ExtraHeader
        title="Simulado inteligente [Extra]"
      />
      <SmartExamExtra id={actualId} />
    </Box>
  );
};

interface SmartExamExtraProps {
  id: string;
}

const SmartExamExtra: FC<SmartExamExtraProps> = ({ id }) => {
  const query = useGetExtraSmartExams({
    id,
  });

  const obj = useMemo(() => {
    if (query.data) {
      return ({
        loading: query.loading,
        data: query.data.getSmartExamExtraActivityDetails.questions,
        length: query.data.getSmartExamExtraActivityDetails.questions.length,
        finished: query.data.getSmartExamExtraActivityDetails.finished,
        preChoice: query.data.getSmartExamExtraActivityDetails.preChoice,
      });
    }
    return ({
      loading: query.loading,
      data: [],
      length: 0,
      finished: false,
      preChoice: [],
    });
  }, [query]);

  if (obj.loading) {
    return (
      <Flex align="center" justify="center" style={{ minHeight: 400 }}>
        <FontAwesomeIcon icon={faSpinner} spin size="5x" className="color-dark-gray" />
      </Flex>
    );
  }

  if (!query.error && !obj.loading && obj.data && obj.length > 0) {
    return (
      <Questions
        model="exams"
        activityId={id}
        data={obj.data}
        finished={obj.finished}
        serverPreChoices={obj.preChoice}
      />
    );
  }

  return (
    <Text>Nenhuma atividade disponível para hoje!</Text>
  );
};

export default SmartExamExtraContainer;
