import { gql, useQuery } from '@apollo/client';
import { useMemo } from 'react';
import { ExamsDataType } from '../views/performance/_components/ExamsPerformanceCard';

type DateIntervalType = {
  dateStart: string,
  dateEnd: string,
}

const STUDENT_PRESENCES_QUERY = gql`
  query getPresenceDates {
    getPresenceDates
  }
`;

export function useStudentPresences() {
  const query = useQuery(STUDENT_PRESENCES_QUERY);
  return {
    frequencyArray: query.data?.getPresenceDates || [],
    frequencyLoading: query.loading,
    refetch: query.refetch,
  };
}

const GET_TROPHIES_BY_DATE_RANGE = gql`
  query GetTrophiesByDateRange(
    $calendarDateStart: CalendarDate!
    $calendarDateEnd: CalendarDate!
    ) {
    getTrophiesByDateRange (
      calendarDateStart: $calendarDateStart,
      calendarDateEnd: $calendarDateEnd) {
      bronze
      silver
      gold
      trophyPoints
      maxTrophyPoints
    }
  }
`;

export type TrophiesStats = {
  bronze: number,
  silver: number,
  gold: number,
  trophyPoints: number,
  maxTrophyPoints: number,
}

type GetTrophiesByDateRangeInput = {
  calendarDateStart: string,
  calendarDateEnd: string,
}

type GetTrophiesByDateRangeOutput = {
  getTrophiesByDateRange: TrophiesStats,
}

export function useGetTrophiesByDateRange({ dateStart, dateEnd }: DateIntervalType) {
  const trophies = useQuery<
    GetTrophiesByDateRangeOutput, GetTrophiesByDateRangeInput
  >(GET_TROPHIES_BY_DATE_RANGE, {
    variables: {
      calendarDateStart: dateStart,
      calendarDateEnd: dateEnd,
    },
  });
  return {
    loading: trophies.loading,
    data: trophies.data?.getTrophiesByDateRange,
    refetch: trophies.refetch,
  };
}

const GET_EXAM_PERFORMANCE = gql`
  query ExamPerformance(
    $dateStart: CalendarDate!
    $dateEnd: CalendarDate!
    ) {
    examPerformance(dateStart: $dateStart, dateEnd: $dateEnd) {
      countExam
      answers {
          difficulty
          countAnswer
          percentage
      }
    }
  }
`;

type GetExamPerformanceInput = {
  dateStart: string,
  dateEnd: string,
}

type GetExamPerformanceOutput = {
  examPerformance: ExamsDataType,
}

export function useGetExamPerformance({ dateStart, dateEnd }: DateIntervalType) {
  const performanceData = useQuery<
    GetExamPerformanceOutput, GetExamPerformanceInput
  >(GET_EXAM_PERFORMANCE, { variables: { dateStart, dateEnd } });
  return {
    loading: performanceData.loading,
    data: performanceData.data?.examPerformance,
    refetch: performanceData.refetch,
  };
}

const GET_FLASHCARDS = gql`
  query CountFlashcards(
    $dateStart: CalendarDate!,
    $dateEnd: CalendarDate!,
  ) {
    countFlashcards (
      dateStart: $dateStart,
      dateEnd: $dateEnd,
    ) {
      hits
      misses
    }
  }
`;

export type FCStatsType = {
  hits: number,
  misses: number,
}

type GetFlashcardsInputType = {
  dateStart: string,
  dateEnd: string,
};

type GetFlashcardsOutputType = {
  countFlashcards: FCStatsType
};

export function useGetFlashcardsCount({ dateStart, dateEnd }: DateIntervalType) {
  const getFlashcards = useQuery<
    GetFlashcardsOutputType, GetFlashcardsInputType
  >(GET_FLASHCARDS, { variables: { dateStart, dateEnd } });
  return {
    data: getFlashcards.data?.countFlashcards,
    loading: getFlashcards.loading,
  };
}

const GET_PERFORMANCE_BY_AREA = gql`
  query PerformanceByClassificationInTestsAndSimulated(
    $dateStart: CalendarDate,
    $dateEnd: CalendarDate,
    $onlyExams: Boolean,
  ) {
    performanceByClassificationInTestsAndSimulated (
      dateStart: $dateStart,
      dateEnd: $dateEnd,
      onlyExams: $onlyExams
    ) {
      classification
      percentage
    }
  }
`;

export type AreasStatsType = {
  classification: string,
  percentage: number,
}

type GetPerformanceByAreaOutputType = {
  performanceByClassificationInTestsAndSimulated: AreasStatsType[]
};

type GetPerformanceByAreaInputType = {
  dateStart: string,
  dateEnd: string,
  onlyExams?: boolean,
};

export function useGetPerformanceByArea({ dateStart, dateEnd, onlyExams = true }: GetPerformanceByAreaInputType) {
  const getPerformance = useQuery<GetPerformanceByAreaOutputType, GetPerformanceByAreaInputType>(
    GET_PERFORMANCE_BY_AREA, { variables: { dateEnd, dateStart, onlyExams } },
  );
  return {
    data: getPerformance.data?.performanceByClassificationInTestsAndSimulated,
    loading: getPerformance.loading,
  };
}

const PERFORMANCE_AND_PRIORITY_BY_THEMA = gql`
  query PerformanceAndPriorityByThema (
    $dateStart: CalendarDate!,
    $dateEnd: CalendarDate!,
    $onlyCompletedThems: Boolean,
  ) {
    performanceAndPriorityByThema (
      dateStart: $dateStart,
      dateEnd: $dateEnd,
      onlyCompletedThems: $onlyCompletedThems,
    ) {
      totalPerformance {
        currMonth
        lastMonth
      }
      performance {
        leaf
        priorityIndex
        lastMonthStats {
          hits
          total
        }
        stats {
          hits
          total
        }
        relevance
        isPriority
        totalPerformance
        performanceLastMonth
      }
    }
  }
`;

export type PerformanceObjectType = {
  totalPerformance: {
    currMonth: number,
    lastMonth: number,
  },
  performance: {
    leaf: string,
    priorityIndex: number,
    stats: {
      hits: number,
      total: number,
    },
    lastMonthStats: {
      hits: number,
      total: number,
    }
    relevance: number,
    isPriority: boolean,
    totalPerformance: number,
    performanceLastMonth: number
  }[]
}

export type PerformanceAndPriorityByThemaInputType = {
  dateStart: string,
  dateEnd: string,
  onlyLastTwoSubThemes?: boolean,
  onlyCompletedThems?: boolean,
};

export type PerformanceAndPriorityByThemaOutputType = {
  performanceAndPriorityByThema: PerformanceObjectType;
};

export function usePerformanceAndPriorityByThema({
  dateStart, dateEnd, onlyLastTwoSubThemes = true,
}: PerformanceAndPriorityByThemaInputType) {
  const performanceObject = useQuery<
    PerformanceAndPriorityByThemaOutputType, PerformanceAndPriorityByThemaInputType
  >(PERFORMANCE_AND_PRIORITY_BY_THEMA, {
    variables: {
      dateStart,
      dateEnd,
    },
  });
  const currData = performanceObject.data?.performanceAndPriorityByThema;

  const processedData = useMemo(() => {
    if (onlyLastTwoSubThemes && currData) {
      const newPerformance = currData.performance
        .map(item => {
          const splittedLeaf = item.leaf.split('|');
          const leafLen = splittedLeaf.length;
          return { ...item, leaf: `${splittedLeaf[leafLen - 2]} - ${splittedLeaf[leafLen - 1]}` };
        });
      return { ...currData, performance: newPerformance };
    } return performanceObject.data?.performanceAndPriorityByThema;
  }, [currData, onlyLastTwoSubThemes, performanceObject.data?.performanceAndPriorityByThema]);

  const priorityLeaves = useMemo(() => {
    if (currData && currData.performance.length > 5) {
      return [...currData.performance]
        .sort((a, b) => (a.priorityIndex > b.priorityIndex ? -1 : 1))
        .map(x => x.leaf);
    } return currData?.performance.map(x => x.leaf);
  }, [currData]);

  const totalHits = useMemo(() => {
    return currData?.performance.reduce((acc, curr) => acc + curr.stats.hits, 0);
  }, [currData?.performance]);

  const totalQuestions = useMemo(() => {
    return currData?.performance.reduce((acc, curr) => acc + curr.stats.total, 0);
  }, [currData?.performance]);

  return {
    data: processedData,
    loading: performanceObject.loading,
    refetch: performanceObject.refetch,
    priorityLeaves,
    totalHits,
    totalQuestions,
  };
}
