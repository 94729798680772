import { Alert, AspectRatio, Box, Text } from '@chakra-ui/react';
import { FC } from 'react';
import { useDefaultStyles } from '../../../hooks/useDefaultStyles';
import { useDevice } from '../../../hooks/useDevice';

export const StonlyMotivational: FC = () => {
  const device = useDevice();
  const { colors } = useDefaultStyles();
  return (
    <Box
      fontFamily="-apple-system, BlinkMacSystemFont, San Francisco, Roboto, Segoe UI, Helvetica Neue, sans-serif"
    // color="#26282e"
    >
      <AspectRatio
        ratio={16 / 9}
      >
        <iframe
          width="100%"
          height={device === 'web' ? '405' : '180'}
          src="https://www.youtube.com/embed/nBnrEOr37EA?vq=hd1080"
          title="Boas-vindas"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </AspectRatio>
      <Box
        padding="2rem 1rem"
        letterSpacing="0.05px"
      >
        <Text fontSize="28px" marginBottom={4}>
          É aqui onde tudo começa!
        </Text>
        <Text fontSize="18px" marginBottom={4}>
          Do que é feito um sonho?
        </Text>
        <Text fontSize="18px" marginBottom={4}>
          Você está preparado para tomar as rédeas da sua preparação e determinar onde você quer chegar?
        </Text>
        <Alert
          variant="left-accent"
          bgColor={colors.lighter.goAlmostFullDarker}
          borderColor={colors.secondary.goALittleDarker}
        >
          Faremos o nosso melhor pra te ajudar nessa jornada.
        </Alert>
      </Box>
    </Box>
  );
};
