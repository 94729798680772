import {
  FC, useCallback, useMemo, useState,
} from 'react';
import { faBookmark as faBookmarkSolid, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { faBookmark } from '@fortawesome/free-regular-svg-icons';
import { Box, Tooltip } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import { DocTypes } from '../../../api/agenda/agenda';
import {
  useFavoriteOne, useFavorites,
} from '../../../api/favorites';
import { useDefaultStyles } from '../../../hooks/useDefaultStyles';

interface FavoriteBtnProps {
  document: string;
  type: DocTypes;
}

const FavoriteBtn: FC<FavoriteBtnProps> = ({
  document, type,
}) => {
  const [loading, setLoading] = useState(false);
  const [hover, setHover] = useState(false);
  const { colors } = useDefaultStyles();

  const query = useFavoriteOne({
    document,
    type,
  });

  const { onFavoriteCreateOrDelete } = useFavorites();

  const active = useMemo(() => {
    const { data } = query;

    if (data) {
      return data.favoriteOne;
    }

    return false;
  }, [query]);

  const handleClick = useCallback(async () => {
    setLoading(true);
    try {
      await onFavoriteCreateOrDelete({
        document,
        type,
        enabled: !active,
      });
      await query.refetch();
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
    }
  }, [active, document, onFavoriteCreateOrDelete, query, type]);

  return (
    <Box
      as="button"
      fontSize="1.2rem"
      onClick={handleClick}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      color={(active || hover) ? colors.secondary.keep : 'light.200'}
      px={2}
    >
      {
        loading ? (
          <Box color="light.200">
            <FontAwesomeIcon icon={faSpinner} spin />
          </Box>
        ) : (
          <Tooltip label="Salvar nos favoritos">
            <Box>
              <FontAwesomeIcon
                icon={(active || hover) ? faBookmarkSolid : faBookmark}
              />
            </Box>
          </Tooltip>
        )
      }
    </Box>
  );
};

export default FavoriteBtn;
