import {
  gql, QueryResult, useMutation, useQuery,
} from '@apollo/client';
import { useCallback } from 'react';
import { toast } from '../../utils/toast';
import { Question } from '../agenda/questions';
import errorHandler from '../error/errorHandler';
import { Activity } from './extra-activities';

interface ExtraQuestions {
  _id: string;
  authorId: string;
  finished: boolean;
  createdAt: Date;
  updatedAt: Date;
  calendarDate: string;
  classification: string[];
  questions: Question[];
}

interface GetExtraQuestionsInput {
  id: string;
}

interface GetExtraQuestionsOutput {
  getQuestionActivityDetails: ExtraQuestions;
}

const GET_EXTRA_QUESTIONS = gql`
  query GetQuestionActivityDetails ($id: ObjectId!) {
    getQuestionActivityDetails (_id: $id) {
      _id
      profile
      finished
      createdAt
      updatedAt
      calendarDate
      classification
      questions {
        _id
        body
        choices
        answersProportion
        correctChoice
        institution
        uf
        year
        examOrder
        examType
        complement
        classification
        difficulty
        createdAt
        updatedAt
        comment {
          author
          profile
          title
          body
          createdAt
          updatedAt
        }
        isEssay
        essay
        answer
      }
    }
  }
`;

export function useGetExtraQuestions({
  id,
}: GetExtraQuestionsInput): QueryResult<GetExtraQuestionsOutput, GetExtraQuestionsInput> {
  return useQuery<GetExtraQuestionsOutput, GetExtraQuestionsInput>(GET_EXTRA_QUESTIONS, {
    variables: {
      id,
    },
    fetchPolicy: 'network-only',
    skip: id === '',
  });
}

interface ActivityInput {
  classification?: string[];
  count?: number;
  difficulty?: string;
  uf?: string;
  institution?: string;
  onlyWithComments?: boolean;
  minYear: number;
  maxYear: number;
}

interface CreateQuestionActivityResponse extends Activity {
  questions: string[];
}

interface CreateQuestionActivityInput {
  input: ActivityInput;
}

interface CreateQuestionActivityOutput {
  createQuestionActivity: CreateQuestionActivityResponse;
}

const CREATE_QUESTION_ACTIVITY = gql`
  mutation CreateQuestionActivity ($input: ActivityQuestionInputType) {
    createQuestionActivity (input: $input) {
      _id
    }
  }
`;

interface UseExtraQuestionsInterface {
  onCreateQuestionActivity: (props: ActivityInput) => Promise<void>;
}

export function useExtraQuestions(): UseExtraQuestionsInterface {
  const [createActivity] = useMutation<
    CreateQuestionActivityOutput, CreateQuestionActivityInput
  >(CREATE_QUESTION_ACTIVITY, {
    onError: errorHandler,
    onCompleted: data => {
      if (data !== null) {
        toast.success({
          title: 'Tudo certo!',
          description: 'Atividade de questão criada com sucesso!',
        });
      }
    },
  });

  const handleCreate = useCallback(async ({
    classification,
    count,
    difficulty,
    institution,
    onlyWithComments,
    uf,
    minYear,
    maxYear,
  }: ActivityInput) => {
    await createActivity({
      variables: {
        input: {
          classification,
          count,
          difficulty,
          institution,
          onlyWithComments,
          uf,
          minYear,
          maxYear,
        },
      },
    });
  }, [createActivity]);

  return {
    onCreateQuestionActivity: handleCreate,
  };
}
