import React, {
  FC, useContext, useMemo,
} from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useGetExtraQuestions } from '../../../api/extra-activities/extra-questions';
import { getActivityIndex } from '../../../hooks/getActivityIndex';
import Text from '../../../lib/components/Text';
import { AgendaContext } from '../Agenda.context';
import { ExtraHeader } from './_components/ExtraHeader';
import { Questions } from '../types/Questions.component';

const ExtraQuestionsContainer: FC = () => {
  const agendaCtx = useContext(AgendaContext);

  const id = getActivityIndex();

  const actualId = useMemo(() => {
    const qs = agendaCtx.extras.activityQuestion;

    const index = qs?.findIndex(x => x._id === id) || 0;

    if (qs && qs.length > 0 && index !== -1) {
      return qs[index]._id;
    }

    if (qs && qs.length > 0) {
      return qs[0]._id;
    }

    return '';
  }, [agendaCtx.extras.activityQuestion, id]);

  if (agendaCtx.extrasLoading) {
    return (
      <Flex align="center" justify="center" style={{ minHeight: 400 }}>
        <FontAwesomeIcon icon={faSpinner} spin size="6x" className="color-dark-gray" />
      </Flex>
    );
  }

  return (
    <Box maxW={900} m="0 auto">
      <ExtraHeader
        title="Questões [Extra]"
      />
      <ExtraQuestions id={actualId} />
    </Box>
  );
};

interface ExtraQuestionsProps {
  id: string;
}

const ExtraQuestions: FC<ExtraQuestionsProps> = ({ id }) => {
  const query = useGetExtraQuestions({
    id,
  });

  const { extras } = useContext(AgendaContext);

  const serverPreChoices = useMemo(() => {
    return extras.activityQuestion
      ? extras.activityQuestion?.filter(x => x._id === id).map(x => x.preChoice)[0]
      : undefined;
  }, [extras.activityQuestion, id]);

  if (query.loading) {
    return (
      <Flex align="center" justify="center" style={{ minHeight: 400 }}>
        <FontAwesomeIcon icon={faSpinner} spin size="5x" className="color-dark-gray" />
      </Flex>
    );
  }

  if (
    !query.error
    && query.data
    && query.data.getQuestionActivityDetails.questions
    && query.data.getQuestionActivityDetails.questions.length > 0
  ) {
    return (
      <>
        <Questions
          model="questions"
          activityId={id}
          data={query.data.getQuestionActivityDetails.questions}
          finished={query.data.getQuestionActivityDetails.finished}
          serverPreChoices={serverPreChoices}
        />
      </>
    );
  }

  return (
    <Text>Nenhuma atividade disponível para hoje!</Text>
  );
};

export default ExtraQuestionsContainer;
