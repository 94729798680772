import { Button, ButtonProps, HStack, Text } from '@chakra-ui/react';
import { faClock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC, useState } from 'react';
import { useDefaultStyles } from '../../../hooks/useDefaultStyles';

interface FocusTimeButtonInterface extends ButtonProps {
  focusTime: string,
  show?: boolean,
}

export const FocusTimeButton: FC<FocusTimeButtonInterface> = ({
  show = true,
  focusTime,
  ...props
}) => {
  const { colors } = useDefaultStyles();
  const [showFocusTime, setShowFocusTime] = useState(false);

  if (!show) {
    return null;
  }

  if (showFocusTime) {
    return (
      <HStack
        as="button"
        onClick={() => setShowFocusTime(false)}
        _hover={{
          textDecoration: showFocusTime ? 'line-through' : 'underline',
          textDecorationColor: showFocusTime ? colors.darkGray.keep : colors.secondary.keep,
        }}
        spacing={0}
      >
        <Text>
          Tempo de foco:
        </Text>
        <Text minW={100}>{focusTime}</Text>
      </HStack>
    );
  }

  return (
    <Button
      onClick={() => setShowFocusTime(true)}
      color={colors.secondary.keep}
      {...props}
    >
      <FontAwesomeIcon icon={faClock} />
    </Button>
  );
};
