import { gql, QueryResult, useQuery } from '@apollo/client';
import { useCallback, useMemo } from 'react';

export type SortType = 'relevance' | 'date';

export interface ProfileInfo {
  _id: string;
  name: string;
}

export interface FlashcardType {
  _id: string;
  front: string;
  back: string;
  createdAt: string;
  createdBy?: ProfileInfo;
  prefix?: string;
  timesAdded?: number;
  ownFlashcard?: boolean;
  alreadyAdded?: boolean;
  index?: number;
}

interface GetCommunityFlashcardsInput {
  lessonId?: string | null;
  sortBy?: SortType;
  skip?: number;
  limit?: number;
  includes?: string;
}

interface GetCommunityFlashcardsOutput {
  getCommunityFlashcards: {
    flashcards: FlashcardType[],
    totalAmount: number,
  };
}

const GET_COMMUNITY_FLASHCARDS = gql`
  query GetCommunityFlashcards (
    $lessonId: ObjectId
    $sortBy: ESort
    $skip: Int
    $limit: Int
    $includes: String
  ) {
    getCommunityFlashcards (
      lessonId: $lessonId
      sortBy: $sortBy
      skip: $skip
      limit: $limit
      includes: $includes
    ) {
      flashcards {
        _id
        front
        back
        createdAt
        createdBy {
         _id
         name
        }
        prefix
        timesAdded
        ownFlashcard
        alreadyAdded
      }
      totalAmount
    }
  }
`;

export function useGetCommunityFlashcards({
  lessonId, sortBy, skip, limit, includes,
}: GetCommunityFlashcardsInput) {
  const { pathname } = window.location;
  return useQuery<GetCommunityFlashcardsOutput, GetCommunityFlashcardsInput>(GET_COMMUNITY_FLASHCARDS, {
    variables: {
      lessonId,
      sortBy,
      skip,
      limit,
      includes,
    },
    skip: (!lessonId && lessonId !== null) || pathname.includes('/meus-baralhos'),
  });
}

interface GetUserFlashcardsInput {
  lessonId: string;
  skip?: number;
  limit?: number;
}

interface GetUserFlashcardsOutput {
  getUserFlashcards: FlashcardType[];
}

const GET_USER_FLASHCARDS = gql`
  query GetUserFlashcards (
    $lessonId: ObjectId!
    $skip: Int
    $limit: Int
  ) {
    getUserFlashcards (
      lessonId: $lessonId
      skip: $skip
      limit: $limit
    ) {
      _id
      front
      back
      createdAt
      createdBy {
        _id
        name
      }
      prefix
      timesAdded
      ownFlashcard
    }
  }
`;

export function useGetUserFlashcards({
  lessonId, skip, limit,
}: GetUserFlashcardsInput): QueryResult<GetUserFlashcardsOutput, GetUserFlashcardsInput> {
  const { pathname } = window.location;
  return useQuery<GetUserFlashcardsOutput, GetUserFlashcardsInput>(GET_USER_FLASHCARDS, {
    skip: !lessonId || pathname.includes('/comunidade'),
    variables: {
      lessonId,
      skip,
      limit,
    },
  });
}

const GET_USER_CLASSES = gql`
  query GetUserClasses {
    customRelevances {
      leaf
      lessonId
    }
  }
`;

type ClassType = {
  leaf: string[],
  lessonId: string,
}

type GetUserClassesOutputType = {
  customRelevances: ClassType[],
};

export type GetAllClassesOutputType = {
  lessonTitle: string,
  area: string,
  lessonId: string,
}

export function useUserClasses() {
  const query = useQuery<GetUserClassesOutputType>(GET_USER_CLASSES);

  const loading = query?.loading;

  const getLessonIdByName = useCallback(({ title }: { title: string }) => {
    const lessons = query.data?.customRelevances;
    if (lessons) {
      return lessons.filter(x => x.leaf.includes(title))[0]?.lessonId;
    } return undefined;
  }, [query.data?.customRelevances]);

  const getLessonByName = useCallback(({ title }: { title: string }) => {
    const lessons = query.data?.customRelevances;
    if (lessons) {
      return lessons.filter(x => x.leaf.includes(title))[0];
    } return undefined;
  }, [query.data?.customRelevances]);

  const getLessonNameById = useCallback(({ lessonId }: { lessonId: string }) => {
    const lessons = query.data?.customRelevances;
    if (lessons) {
      const targetLesson = lessons.filter(x => x.lessonId === lessonId)[0];
      const lessonLeafLastItem = targetLesson?.leaf?.slice(-1)[0];
      if (lessonLeafLastItem) {
        return lessonLeafLastItem;
      }
    } return 'Aula não encontrada';
  }, [query.data?.customRelevances]);

  const listOfAllLessons = useMemo(() => {
    const data = query.data?.customRelevances;
    if (data) {
      const onlyLastLeafName = data?.map(x => {
        const len = x.leaf.length;
        return {
          lessonTitle: x.leaf[len - 1],
          area: x.leaf[0],
          lessonId: x.lessonId,
        } as GetAllClassesOutputType;
      });
      return onlyLastLeafName;
    }
    return [];
  }, [query.data?.customRelevances]);

  const getAreaByLessonId = useCallback(({ lessonId }: { lessonId?: string }) => {
    if (lessonId) {
      const area = listOfAllLessons.filter(x => x.lessonId === lessonId)[0]?.area;
      if (['Epidemiologia', 'Saúde Coletiva'].includes(area)) {
        return 'SC';
      }
      if (['Pediatria'].includes(area)) {
        return 'PED';
      }
      if (['Cirurgia', 'Cirurgia Geral'].includes(area)) {
        return 'CIR';
      }
      if (['Ginecologia e obstetrícia', 'Ginecologia e Obstetrícia'].includes(area)) {
        return 'GO';
      }
      if (['Clínica médica', 'Clínica Médica'].includes(area)) {
        return 'CM';
      }
    } return 'EXT';
  }, [listOfAllLessons]);

  return {
    getLessonIdByName,
    getLessonByName,
    listOfAllLessons,
    getAreaByLessonId,
    getLessonNameById,
    loading,
  };
}
