import {
  gql, QueryResult, useMutation, useQuery,
} from '@apollo/client';
import { useCallback } from 'react';
import errorHandler from '../error/errorHandler';

export type TheoreticalTypes = 'theoreticalReview' | 'theoreticalStudy'
  | 'smartReview' | 'exams' | 'smart-exams' | 'mocks' | 'smartExams'
  | 'favorites' | 'extras' | 'extras-theoreticalStudy' | 'extras-theoreticalReview'
  | 'extras-smartReview' | 'extras-smartExam' | 'extras-exam';

export type ActivityTypes = 'exams' | 'smart_exams' | 'mock';
export type DocTypes = 'question' | 'flashcard' | 'video' | 'mindmap' | 'text' | 'jjvideo';

export interface Comment {
  author?: string;
  profile: string;
  title?: string;
  body?: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface OfficialComments {
  authorName: string;
  content: string;
  officialCommentEval: number;
}

interface ObjFinish {
  id: string;
  finished: boolean;
}

export interface TheoreticalLesson {
  finished: boolean;
  lessonId: string;
  lessonTitle: string;
  classification: string[];
  questions: string[];
  videos: string[];
  texts: string[];
  mindmaps: string[];
  flashcards: string[];
}

export interface TheoreticalReviewIds {
  videos: ObjFinish[];
  texts: ObjFinish[];
  mindmaps: ObjFinish[];
  flashcards: string[];
  questions: string[];
}

export interface SmartReviewIds {
  questions: string[];
  flashcards: string[];
}

export interface Agenda {
  _id: string;
  calendarDate: string;
  theoreticalStudy: TheoreticalLesson[];
  theoreticalReview: TheoreticalLesson[];
  smartReview: SmartReviewIds;
  mocks: string[];
  exams: string[];
  smartExamActivity?: string;
  smartReviewPreChoice?: {
    questionId: string;
    selected: number;
    essay?: string;
  }[];
}

interface AgendaInput {
  calendarDate: string;
}

interface AgendaOutput {
  agenda: Agenda;
}

const AGENDA = gql`
  query Agenda ($calendarDate: CalendarDate!) {
    agenda (calendarDate: $calendarDate) {
      _id
      calendarDate
      theoreticalStudy {
        lessonId
        finished
        lessonTitle
        classification
        videos
        texts
        mindmaps
        flashcards
        questions
      }
      theoreticalReview {
        lessonId
        finished
        lessonTitle
        classification
        videos
        texts
        mindmaps
        flashcards
        questions
      }
      smartReview {
        flashcards
        questions
      }
      mocks
      exams
      smartExamActivity
      smartReviewPreChoice {
        questionId
        selected
        essay
      }
    }
  }
`;

interface FinishAgendaActivityContentInput {
  theoreticalType: TheoreticalTypes;
  lessonId: string;
  calendarDate: string;
}

interface FinishAgendaActivityContentOutput {
  finishAgendaActivityContent: boolean;
}

const FINISH_AGENDA_ACTIVITY_CONTENT = gql`
  mutation FinishAgendaActivityContent (
    $theoreticalType: TheoreticalType!
    $lessonId: ObjectId!
    $calendarDate: CalendarDate!
  ) {
    finishAgendaActivityContent (
      theoreticalType: $theoreticalType
      lessonId: $lessonId
      calendarDate: $calendarDate
    )
  }
`;

interface UseAgendaInterface {
  onFinishAgendaActivityContent: (props: FinishAgendaActivityContentInput) => Promise<void>;
}

export function useAgenda(): UseAgendaInterface {
  const [finishAgenda] = useMutation<
    FinishAgendaActivityContentOutput, FinishAgendaActivityContentInput
  >(FINISH_AGENDA_ACTIVITY_CONTENT, {
    onError: errorHandler,
  });

  const handleFinishAgenda = useCallback(async ({
    calendarDate,
    lessonId,
    theoreticalType,
  }: FinishAgendaActivityContentInput) => {
    await finishAgenda({
      variables: {
        calendarDate,
        lessonId,
        theoreticalType,
      },
    });
  }, [finishAgenda]);

  return {
    onFinishAgendaActivityContent: handleFinishAgenda,
  };
}

export function useAgendaQuery({ calendarDate }: AgendaInput): QueryResult<AgendaOutput, AgendaInput> {
  return useQuery<AgendaOutput, AgendaInput>(AGENDA, {
    variables: {
      calendarDate,
    },
    fetchPolicy: 'network-only',
    onError: errorHandler,
  });
}
