/* eslint-disable no-undef */
import {
  FC, useState, useEffect, useCallback, useMemo,
} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Button,
  Flex,
  HStack,
  IconButton,
  Modal,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea,
  useDisclosure,
  useTheme,
  VStack,
} from '@chakra-ui/react';
import {
  faArrowLeft,
  faCheck,
  faGraduationCap,
} from '@fortawesome/free-solid-svg-icons';
import ReactMarkdown from 'react-markdown';
import { useHistory, useLocation } from 'react-router-dom';
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import DifficultyBlocks from '../DifficultyBlocks';
import { PreChoiceInterface } from '../../../api/agenda/mock-exam';
import TagQuestionCanceled from '../TagQuestionCanceled';
import { PrimaryButton } from '../../../lib/components/PrimaryButton';
import FavoriteBtn from '../../agenda/_components/FavoriteBtn.component';
import { MoreOptions } from '../MoreOptions';
import { Question } from '../../../api/agenda/questions';
import { Comment } from '../Comment';
import { useDevice } from '../../../hooks/useDevice';
import { SecondaryButton } from '../../../lib/components/SecondaryButton';
import { QuestionBody } from './QuestionBody';
import { QuestionWrapper } from './QuestionWrapper';
import { Divider } from '../../../lib/components/Divider';
import { LikeDislike } from '../LikeDislike';
import { useDefaultStyles } from '../../../hooks/useDefaultStyles';
import { CardsButton } from '../../flashcards-creation/_components/Buttons/CardsButton';
import { isTodayExtended } from '../../../utils/isTodayExtended';

interface QuestionCardProps {
  activityId?: string;
  data: Question;
  answer: number;
  essay: string;
  disabled?: boolean;
  index: number;
  onSubmitAnswer?: (essay: string, val: 0 | 1) => Promise<void>;
  onSaveEssay?: (value: string) => Promise<void>;
  preEssay?: PreChoiceInterface;
  showResults?: boolean;
  showComment?: boolean;
  showCommentElements?: boolean;
  showForum?: boolean;
  showMoreOptions?: boolean;
  isExam?: boolean;
  bgColorProp?: string;
  isFavoritesModel?: boolean;
  colorProp?: string;
}

const EssayQuestion: FC<QuestionCardProps> = ({
  activityId,
  answer,
  essay,
  isFavoritesModel,
  data: {
    _id: id,
    answersProportion,
    choices,
    correctChoice,
    difficulty,
    institution,
    uf: state,
    year,
    body,
    classification,
    comment,
  },
  disabled = false,
  index,
  isExam = false,
  onSubmitAnswer,
  onSaveEssay,
  preEssay,
  showResults = true,
  showComment = false,
  showCommentElements = true,
  showForum = false,
  showMoreOptions = true,
  bgColorProp,
  colorProp,
}) => {
  /**
   * States
   */
  const { isOpen: isCommentOpen, onOpen, onClose, onToggle } = useDisclosure();
  const device = useDevice();
  const theme = useTheme();
  const [essaySavedFeedback, setEssaySavedFeedback] = useState(false);
  const [essayState, setEssayState] = useState(essay ?? preEssay);
  const [isSavingEssay, setIsSavingEssay] = useState(false);
  const [value, setValue] = useState(answer);
  const [showCorrectAnswer, setShowCorrectAnswer] = useState(false);
  const [answerLoading, setAnswerLoading] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const MAX_TEXT_AREA_LENGTH = 750;
  const { colors } = useDefaultStyles();

  const shouldShowCorrectAnswerBtn = useMemo(() => {
    if (isExam) {
      return showResults && !showCorrectAnswer;
    }
    return !(showResults || disabled) && !showCorrectAnswer;
  }, [disabled, isExam, showCorrectAnswer, showResults]);

  const isAnswerDisabled = useMemo(() => {
    return (disabled || essayState.length < 3) && isTodayExtended();
  }, [disabled, essayState.length]);

  /**
   * Callbacks
   */
  const onCommentOpenMobile = useCallback(() => {
    const search = new URLSearchParams(location.search);
    search.set('showComment', 'true');
    history.push({
      pathname: history.location.pathname,
      search: String(search),
    });
  }, [history, location.search]);

  const onCommentCloseMobile = useCallback(() => {
    const search = new URLSearchParams(location.search);
    search.delete('showComment');
    history.push({
      pathname: history.location.pathname,
      search: String(search),
    });
  }, [history, location.search]);

  const handleSaveEssay = useCallback(async () => {
    if (onSaveEssay) {
      setIsSavingEssay(true);
      try {
        await onSaveEssay(essayState);
        setEssaySavedFeedback(true);
        setTimeout(() => setEssaySavedFeedback(false), 2300);
      } catch (err) {
        console.log(err);
      } finally {
        setIsSavingEssay(false);
      }
    }
  }, [essayState, onSaveEssay]);

  const getColor = useCallback((ind: number, tint?: number) => {
    if (showResults) {
      if ((ind === 0) || (correctChoice === -1)) {
        return `success.${tint ?? 100}`;
      }
      if (ind === 1) {
        return `error.${tint ?? 100}`;
      }
    }
    return 'transparent';
  }, [correctChoice, showResults]);

  const handleAnswer = useCallback((val: 0 | 1) => async () => {
    if (onSubmitAnswer) {
      setAnswerLoading(true);
      try {
        await onSubmitAnswer(essayState, val);
      } catch (err) {
        console.log(err);
      } finally {
        setAnswerLoading(false);
      }
    }
  }, [essayState, onSubmitAnswer]);

  useEffect(() => {
    if (preEssay && answer === -1) {
      setEssayState(preEssay.essay ?? '');
      return;
    }
    setEssayState(essay);
  }, [answer, essay, id, index, preEssay]);

  useEffect(() => {
    setShowCorrectAnswer((answer !== -1) || !isTodayExtended());
    setValue(answer);
  }, [answer]);

  useEffect(() => {
    if (!showComment) {
      onClose();
    }
  }, [onClose, showComment]);

  useEffect(() => {
    const search = new URLSearchParams(location.search);
    if (search.get('showComment') === 'true') {
      onOpen();
      return;
    }
    onClose();
  }, [location.search, onClose, onOpen]);

  return (
    <>
      <QuestionWrapper
        borderColor={getColor(value, 500)}
        bgColor={bgColorProp}
        color={colorProp}
      >
        <Flex flexDir="column">
          {
            showResults && (
              <>
                <Flex align="center" justify="space-between" mb={2}>
                  <Flex flexFlow={{ base: 'column', md: 'row' }} align="center">
                    <Text fontWeight="bold" fontSize="sm" mr={2}>Dificuldade</Text>
                    <DifficultyBlocks difficulty={difficulty} />
                  </Flex>
                  {showMoreOptions && (
                    <HStack>
                      <CardsButton />
                      <LikeDislike
                        document={id}
                        type="question"
                      />
                      <FavoriteBtn
                        document={id}
                        type="question"
                      />
                      <MoreOptions
                        contentId={id}
                        type="question"
                      />
                    </HStack>
                  )}
                </Flex>
                <HStack color={getColor(value, 500)}>
                  {value === 0 && (
                    <>
                      <FontAwesomeIcon icon={faCheckCircle} />
                      <Text>Você acertou essa questão</Text>
                    </>
                  )}
                  {value === 1 && (
                    <>
                      <FontAwesomeIcon icon={faTimesCircle} />
                      <Text>Você errou essa questão</Text>
                    </>
                  )}
                </HStack>
                <Divider borderColor={getColor(value, 500)} />
              </>
            )
          }
          <QuestionBody
            body={body}
            id={id}
            index={index}
            info={`**${index + 1}. [${institution} - ${state} - ${year}]**`}
          />
          {correctChoice === -1 && showResults ? <TagQuestionCanceled /> : ''}
        </Flex>
        {
          choices[0] && (
            <VStack align="start">
              <Text color={colors.secondary.goALittleDarker} fontWeight="bold">Sua resposta</Text>
              <Textarea
                variant="filled"
                borderColor={colors.secondary.goALittleDarker}
                placeholder="Escreva aqui sua resposta"
                size="lg"
                fontSize="md"
                disabled={showCorrectAnswer || value !== -1 || (isExam && showResults)}
                minH={200}
                value={essayState}
                onChange={e => {
                  if (e.target.value.length <= MAX_TEXT_AREA_LENGTH) {
                    setEssayState(e.target.value);
                  }
                }}
                _disabled={{
                  color: colors.darkGray.keep,
                  cursor: 'not-allowed',
                }}
              />
              <Flex flex={1} alignSelf="flex-end">
                <Text fontSize="xs">
                  {`${MAX_TEXT_AREA_LENGTH - essayState.length} caracteres restantes.`}
                </Text>
              </Flex>
            </VStack>
          )
        }
        {choices[0] && (showCorrectAnswer || value !== -1) && (
          <VStack align="start" mt={4}>
            <Text color={colors.secondary.keep} fontWeight="bold">Gabarito oficial</Text>
            <ReactMarkdown>{choices[0]}</ReactMarkdown>
          </VStack>
        )}
        <Flex mt={4}>
          {(showComment || (isFavoritesModel && essayState.length > 0)) && (
            <SecondaryButton
              leftIcon={<FontAwesomeIcon color={theme.colors.primary[500]} icon={faGraduationCap} />}
              onClick={device === 'mobile' ? onCommentOpenMobile : onToggle}
              disabled={!comment?.body}
              borderColor={colors.secondary.keep}
              color={colors.secondary.keep}
              borderRadius={30}
              fontWeight="normal"
            >
              {!comment?.body ? 'Sem comentário' : 'Comentário Aristo'}
            </SecondaryButton>
          )}
          <HStack>
            {
              shouldShowCorrectAnswerBtn && !isFavoritesModel && (
                <PrimaryButton
                  ml="auto"
                  disabled={isAnswerDisabled}
                  onClick={() => setShowCorrectAnswer(true)}
                  isLoading={answerLoading}
                  justifySelf="end"
                >
                  Ver gabarito
                </PrimaryButton>
              )
            }
            {isExam && !showResults && !isFavoritesModel && (
              <PrimaryButton
                ml="auto"
                disabled={isAnswerDisabled}
                onClick={essaySavedFeedback ? () => undefined : handleSaveEssay}
                isLoading={isSavingEssay}
                leftIcon={essaySavedFeedback ? (
                  <Box color={colors.green.goDarker}>
                    <FontAwesomeIcon icon={faCheck} />
                  </Box>
                ) : undefined}
                justifySelf="end"
              >
                {essaySavedFeedback ? 'Resposta salva' : 'Salvar resposta'}
              </PrimaryButton>
            )}
          </HStack>
          {showCorrectAnswer && (value === -1) && !isFavoritesModel && (
            <HStack w="100%" maxW={300} m="0 auto">
              <Button
                flex={1}
                bgColor={colors.red.goDarker}
                colorScheme="red"
                color={colors.lighter.keep}
                onClick={handleAnswer(1)}
                isLoading={answerLoading}
                disabled={disabled || answerLoading}
                _hover={{
                  filter: 'brightness(0.9)',
                }}
                transition="all 0.3s"
              >
                Errei
              </Button>
              <Button
                flex={1}
                bgColor={colors.green.goDarker}
                colorScheme="green"
                color={colors.lighter.keep}
                disabled={disabled || answerLoading}
                onClick={handleAnswer(0)}
                isLoading={answerLoading}
                _hover={{
                  filter: 'brightness(0.9)',
                }}
                transition="all 0.2s"
              >
                Acertei
              </Button>
            </HStack>
          )}
        </Flex>
      </QuestionWrapper>
      {device === 'mobile' ? (
        <Modal isOpen={isCommentOpen} onClose={onCommentCloseMobile} size="full">
          <ModalOverlay />
          <ModalContent bgColor={colors.lighter.goFullDarker}>
            <ModalHeader>
              <IconButton
                aria-label="voltar"
                icon={<FontAwesomeIcon icon={faArrowLeft} />}
                onClick={onCommentCloseMobile}
                variant="ghost"
              />
            </ModalHeader>
            <Box p={2}>
              {comment && <Comment data={comment} />}
            </Box>
          </ModalContent>
        </Modal>
      ) : (
        <>
          {comment && isCommentOpen && (
            <Box mt={4}>
              <Comment
                data={comment}
                id={id}
                classification={classification}
                showCommentElements={showCommentElements}
                colorProp={colorProp}
                bgColorProp={bgColorProp}
              />
            </Box>
          )}
        </>
      )}
    </>
  );
};

export default EssayQuestion;
