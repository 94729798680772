import { gql, useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { toast } from '../utils/toast';
import { TheoreticalTypes } from './agenda/agenda';
import { PreChoiceInterface } from './agenda/mock-exam';

export type AnswerType = 'question' | 'flashcard' | 'mocks' | 'exams'
  | 'smart_exams' | 'extra-smart-review-question' | 'extra-smart-review-flashcard';
export type DifficultyType = 'hard' | 'medium' | 'easy' | undefined;

interface AnswerInput {
  choosen: number;
  essay?: string;
  document: string;
  calendarDate: string;
  type: AnswerType;
  classification: string[];
  theoreticalType?: TheoreticalTypes;
  activity?: string;
  difficulty?: string;
  correctChoice?: number;
}

interface CreateOneAnswerInput {
  input: AnswerInput;
}

interface AnswerOutput {
  activity: string;
  difficulty: DifficultyType;
}

interface CreateOneAnswerOutput {
  createOneAnswer: AnswerOutput;
}

const CREATE_ONE_ANSWER = gql`
  mutation CreateOneAnswer (
    $input: CreateOneAnswerInputType!
  ) {
    createOneAnswer (
      input: $input
    ) {
      activity
      difficulty
    }
  }
`;

const CREATE_MANY_PRECHOICES = gql`
  mutation CreateManyPreChoices (
    $preChoices: [PreChoiceInputType!]!
    $activity: ObjectId
    $agenda: ObjectId
  ) {
    createManyPreChoices (
      preChoices: $preChoices
      activity: $activity
      agenda: $agenda
    )
  }
`;

interface CreateManyPreChoicesInput {
  preChoices: PreChoiceInterface[];
  activity?: string;
  agenda?: string;
}

interface CreateManyPreChoicesOutput {
  createManyPreChoices: boolean;
}

interface CreateOrUpdateOnePreChoiceInput {
  selected: number;
  question: string;
  essay?: string;
  activity?: string;
  agenda?: string;
}

interface CreateOrUpdateOnePreChoiceOutput {
  createOrUpdateOnePreChoice?: {
    questionId: string;
    selected: number;
    essay?: string;
  };
}

const CREATE_OR_UPDATE_ONE_PRE_CHOICE = gql`
  mutation CreateOrUpdateOnePreChoice (
    $selected: Float!
    $question: ObjectId!
    $essay: String
    $activity: ObjectId
    $agenda: ObjectId
  ) {
    createOrUpdateOnePreChoice (
      selected: $selected
      question: $question
      essay: $essay
      activity: $activity
      agenda: $agenda
    ) {
      questionId
      selected
      essay
    }
  }
`;

export interface ManyAnswerInterface {
  choosen: number;
  document: string;
  classification: string[];
  difficulty?: string;
  correctChoice?: number;
}

export interface ManyAnswerInput {
  answers: ManyAnswerInterface[];
  calendarDate: string;
  type: string;
  theoreticalType?: TheoreticalTypes;
  activity?: string;
}

interface CreateManyAnswerInput {
  input: ManyAnswerInput;
}

interface CreateManyAnswerOutput {
  answers: AnswerOutput[];
}

const CREATE_MANY_ANSWER = gql`
  mutation CreateManyAnswer (
    $input: CreateManyAnswerInputType!
  ) {
    answers: createManyAnswer (
      input: $input
    ) {
      choosen
      document
      createdBy
      calendarDate
      createdAt
      updatedAt
      theoreticalType
      activity
      difficulty
    }
  }
`;

export function useAnswer() {
  const [createOneAnswer] = useMutation<CreateOneAnswerOutput, CreateOneAnswerInput>(CREATE_ONE_ANSWER);
  const [createManyAnswer] = useMutation<CreateManyAnswerOutput, CreateManyAnswerInput>(CREATE_MANY_ANSWER);
  const [createManyPreChoices] = useMutation<
    CreateManyPreChoicesOutput, CreateManyPreChoicesInput
  >(CREATE_MANY_PRECHOICES);
  const [createOrUpdateOnPreChoice] = useMutation<
    CreateOrUpdateOnePreChoiceOutput, CreateOrUpdateOnePreChoiceInput
  >(CREATE_OR_UPDATE_ONE_PRE_CHOICE);

  const handleCreateOneAnswer = useCallback(async ({
    choosen,
    essay,
    document,
    calendarDate,
    type,
    classification,
    theoreticalType,
    activity,
    correctChoice,
    difficulty,
  }: AnswerInput) => {
    try {
      const res = await createOneAnswer({
        variables: {
          input: {
            choosen,
            document,
            essay,
            calendarDate,
            type,
            classification,
            theoreticalType,
            activity,
            correctChoice,
            difficulty,
          },
        },
      });
      if (res.data) {
        return res.data?.createOneAnswer.difficulty;
      }
      throw new Error('Received a null response.');
    } catch (error) {
      console.log(error);
      toast.error({
        title: 'Houve um problema ao salvar sua escolha.',
        description: 'Verifique sua conexão e tente novamente.',
      });
      return undefined;
    }
  }, [createOneAnswer]);

  const handleCreateManyAnswer = useCallback(async ({
    answers,
    calendarDate,
    type,
    theoreticalType,
    activity,
  }: ManyAnswerInput) => {
    try {
      const createMany = await createManyAnswer({
        variables: {
          input: {
            answers,
            calendarDate,
            type,
            theoreticalType,
            activity,
          },
        },
      });
      if (createMany) {
        return createMany;
      }
      throw new Error('createManyAnswer failed');
    } catch (error) {
      console.log(error);
      toast.error({
        title: 'Houve um problema ao salvar suas respostas.',
        description: 'Verifique sua conexão e tente novamente.',
      });
      return false;
    }
  }, [createManyAnswer]);

  const handleCreateManyPreChoices = useCallback(async ({
    preChoices, activity, agenda,
  }: CreateManyPreChoicesInput) => {
    try {
      await createManyPreChoices({
        variables: {
          preChoices,
          activity,
          agenda,
        },
      });
      return true;
    } catch (error) {
      toast.error({
        title: 'Houve um problema ao salvar sua escolha.',
        description: 'Verifique sua conexão e tente novamente.',
      });
      return false;
    }
  }, [createManyPreChoices]);

  const handleCreateOrUpdateOnePreChoice = useCallback(async ({
    selected,
    question,
    activity,
    agenda,
    essay,
  }: CreateOrUpdateOnePreChoiceInput) => {
    try {
      const createOrUpdate = await createOrUpdateOnPreChoice({
        variables: {
          selected,
          question,
          essay,
          activity,
          agenda,
        },
      });
      if (createOrUpdate.data?.createOrUpdateOnePreChoice) {
        return createOrUpdate.data.createOrUpdateOnePreChoice;
      }
      throw new Error('Received null response.');
    } catch (error) {
      console.log(error);
      toast.error({
        title: 'Houve um problema ao salvar sua escolha.',
        description: 'Verifique sua conexão e tente novamente.',
      });
      return undefined;
    }
  }, [createOrUpdateOnPreChoice]);

  return {
    onCreateOneAnswer: handleCreateOneAnswer,
    onCreateManyAnswer: handleCreateManyAnswer,
    onCreateOrUpdateOnePreChoice: handleCreateOrUpdateOnePreChoice,
    onCreateManyPreChoices: handleCreateManyPreChoices,
  };
}
