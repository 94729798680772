import { FC, useCallback } from 'react';
import { Button, HStack, Tooltip, Text, Flex, VStack, Box } from '@chakra-ui/react';
import { faList, faPlay } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { useDefaultStyles } from '../../../hooks/useDefaultStyles';
import { Loading } from '../../../lib/components/Loading';
import { PrintButton } from '../../_components/Question/PrintButton.component';
import { GetOrCreateMockExamActivityOutput } from '../../../api/agenda/mock-exam';
import { Question } from '../../../api/agenda/questions';
import { PrimaryButton } from '../../../lib/components/PrimaryButton';

interface AgendaMockExamStartMessageProps {
  printLoading: boolean;
  activityDetails: {
      title: string;
      subtitle: string;
      year: string;
      institution: string;
      uf: string;
      questions: Question[];
  } | undefined;
  handleStart: () => Promise<void>;
  setFeedbackModel: () => void;
  loading: boolean;
  data: GetOrCreateMockExamActivityOutput;
  blockStart: boolean;
}

export const AgendaMockExamStartMessage: FC<AgendaMockExamStartMessageProps> = ({
  printLoading,
  activityDetails,
  handleStart,
  setFeedbackModel,
  loading,
  data,
  blockStart,
}) => {
  const { cardBorder, colors } = useDefaultStyles();

  const handleTimeFormatter = useCallback((milisseconds: number) => {
    const hours = moment.duration(milisseconds).asHours();
    const minutes = moment.duration(milisseconds).minutes();
    const hoursHumanized = moment.duration(Math.floor(hours), 'hours').humanize();
    const minutesHumanized = moment.duration(minutes, 'minutes').humanize({ m: 60 });

    if (hours < 1) {
      return minutesHumanized;
    }

    if (minutes === 0) {
      return hoursHumanized;
    }

    return `${hoursHumanized} e ${minutesHumanized}`;
  }, []);

  const amountOfTime = handleTimeFormatter(
    Math.min(data.mockExam.timeToDo, moment(data.mockExam.finishesAt).diff(moment())),
  );

  function formatTime(dateString?: string) {
    if (!dateString) {
      return '--:--';
    }

    const date = new Date(dateString);

    let hours: number | string = date.getHours();
    let minutes: number | string = date.getMinutes();

    if (hours < 10) {
      hours = `0${hours}`;
    }
    if (minutes < 10) {
      minutes = `0${minutes}`;
    }

    return `${hours}:${minutes}`;
  }

  const finshTime = formatTime(data.mockExam.limitDateToStart);

  const finishDate = moment(data.mockExam.limitDateToStart).format('DD/MM/YYYY');

  const rankDate = moment(data.mockExam.rankingAt).format('DD/MM/YYYY');

  return (
    <Flex
      direction="column"
      p={4}
      maxW={600}
      m="1rem auto"
      {...cardBorder}
    >
      <HStack justifyContent="center" marginBottom="16px">
        {!data.mockExam.printingIsBlocked && (
        <Tooltip shouldWrapChildren hasArrow label="Imprimir prova">
          {
              !printLoading && activityDetails
                ? (
                  <PrintButton
                    questions={activityDetails.questions}
                    printProps={{
                      ...{
                        institution: activityDetails?.institution,
                        uf: activityDetails?.uf,
                        title: activityDetails?.title,
                        subtitle: activityDetails?.subtitle,
                        year: Number(activityDetails?.year),
                      },
                    }}
                  />
                )
                : (
                  <Loading mx={3} />
                )
            }
        </Tooltip>
        )}
        <Tooltip hasArrow label="Imprimir modelo de gabarito">
          <Button
            color={colors.secondary.goALittleDarker}
            variant="ghost"
            onClick={setFeedbackModel}
          >
            <FontAwesomeIcon icon={faList} />
          </Button>
        </Tooltip>
      </HStack>
      {
         data.mockExam.title && (
         <Text fontSize="22">{data.mockExam.title}</Text>
         )
        }
      {
         data.mockExam.subtitle && (
         <Text fontSize="md">{`Instituição: ${data.mockExam.subtitle}`}</Text>
         )
        }

      <VStack marginTop="16px" spacing="16px" alignItems="flex-start">
        <p>
          Você terá
          <strong>
            {' '}
            {amountOfTime}
          </strong>
          {' '}
          para finalizar este simulado assim que clicar em ”iniciar simulado”.
          <br />
          Após iniciado, não feche a janela ou desligue seu dispositivo.
        </p>

        <p>
          Você pode iniciar este simulado até as
          <strong>
            {' '}
            {finshTime}
          </strong>
          {' '}
          (Horário de Brasília) do dia
          {' '}
          <strong>
            {finishDate}
          </strong>
          .
        </p>

        <p>
          Seu ranking ficará disponível em
          {' '}
          <strong>{rankDate}</strong>
          , você receberá um e-mail confirmando que ele está disponível para acesso na página de ”Desempenho”.
        </p>
        <p>
          Caso queira encerrar seu simulado, basta clicar no botão de ”parar” dentro do cronômetro na tela da prova.
          <strong> As questões não-respondidas serão consideradas como inválidas.</strong>
        </p>

        <Box backgroundColor={colors.lighter.goMuchDarker} padding="16px" borderRadius="8px">
          <Text>
            O simulado registra todas as respostas com data e horário para auditoria futura e no
            caso de simulados com ranking competitivo poderá ser usado para iniciativas de prevenção à fraude.
          </Text>
        </Box>

        <PrimaryButton
          backgroundColor="#415F91"
          borderRadius="50px"
          onClick={handleStart}
          isLoading={loading}
          disabled={blockStart}
        >
          <FontAwesomeIcon color="white" icon={faPlay} size="xs" />
          <Text marginLeft="8px" color="white">iniciar simulado</Text>
        </PrimaryButton>
      </VStack>
    </Flex>
  );
};
