import moment from 'moment';
import { getDateParam } from '../hooks/getDateParam';

export const HOURS_TO_EXTEND = 4;

export const isTodayExtended = ({ hoursToExtend } = { hoursToExtend: HOURS_TO_EXTEND }) => {
  const date = getDateParam();
  const today = moment().format('YYYYMMDD');
  const todayHours = new Date().getHours();
  const yesterdayDate = new Date();
  yesterdayDate.setDate(yesterdayDate.getDate() - 1);
  const yesterday = moment(yesterdayDate).format('YYYYMMDD');
  return date === today || ((date === yesterday) && todayHours < hoursToExtend);
};
