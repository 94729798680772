import {
  Alert,
  Box,
  Image, Text,
} from '@chakra-ui/react';
import React, { FC, useMemo } from 'react';
import { useDefaultStyles } from '../../../hooks/useDefaultStyles';
import { useDevice } from '../../../hooks/useDevice';
import { FirstModalBody } from '../../agenda/_components/FirstModalBody.component';
import TypicalWeek from '../../profile/_components/TypicalWeek.component';

type OnboardingEMCType = {
  nextStep: () => void;
  previousStep: () => void;
  quitModal: () => void;
  welcomeStep: number;
  setWelcomeStep: React.Dispatch<React.SetStateAction<number>>;
  courseId: string;
}

export const OnboardingEMC: FC<OnboardingEMCType> = ({
  nextStep,
  previousStep,
  quitModal,
  welcomeStep,
  setWelcomeStep,
  courseId,
}) => {
  const device = useDevice();
  const { colors } = useDefaultStyles();

  const contents = useMemo(() => {
    if (courseId === '63650f68f5d699409ae09289') {
      return {
        title: 'Ortopedia',
      };
    }
    return {
      title: 'Clínica Médica',
    };
  }, [courseId]);

  return (
    <>
      <FirstModalBody
        show={welcomeStep === 0}
        buttonsPosition="center"
        primaryButton={{ label: 'Começar', onClick: nextStep }}
      >
        <Image width="12rem" marginBottom="3rem" marginX="auto" src="/images/aristo-design/c1-1.png" />
        <Text fontSize="1.5rem" marginBottom={1} fontWeight="bold">
          Bem-vindo ao curso de
          <Box color={colors.secondary.goALittleDarker} display="inline" as="span" mx={1}>
            Educação Médica Continuada
          </Box>
          em
          <Box color={colors.secondary.goALittleDarker} display="inline" as="span" ml={1}>
            {` ${contents.title}`}
          </Box>
          !
        </Text>

        <Text marginBottom={2}>
          Na nossa plataforma, AristoClass, deixaremos você sempre alinhado com as principais novidades
          da sua especialidade, guiando seus estudos, realizando revisões e sedimentando conhecimentos adquiridos.
        </Text>
        <Text marginBottom={2}>
          Para isso, utilizaremos diversos recursos, como casos clínicos, questões comentadas e flashcards,
          tudo para facilitar esse processo.
        </Text>
        <Alert
          variant="left-accent"
          bgColor={colors.lighter.goAlmostFullDarker}
          borderColor={colors.secondary.goALittleDarker}
          margin="1rem 0 1.5rem 0"
        >
          {`Esteja sempre atualizado com a EMC ${contents.title}. Vamos lá?`}
        </Alert>
      </FirstModalBody>

      <FirstModalBody
        show={welcomeStep === 1}
        returnButton={{ label: 'Semana típica', onClick: previousStep }}
      >
        <Text marginBottom={2} fontWeight="bold" fontSize="1.2rem">
          Organize aqui a sua semana para cumprir as metas semanais do seu curso.
        </Text>
        <Text marginBottom={2}>
          A configuração deve ser feita individualmente
          para cada um dos cursos que possuir. Ela pode ser alterada a qualquer
          momento na página de perfil.
        </Text>
        <Text marginBottom={4}>
          As
          <Box color={colors.secondary.goALittleDarker} display="inline" as="span">{' metas mínimas '}</Box>
          indicam o número de atividades que você deve colocar para um estudo de alta produtividade. Escolha:
        </Text>
        <TypicalWeek
          isOnboarding
          hideSuccessToast
          primaryButtonText="Finalizar"
          justifyButtons={device === 'mobile' ? 'center' : 'end'}
          primaryButtonStyle={{
            marginTop: '1rem',
            width: '90%',
            maxWidth: '15rem',
          }}
          disableUndo
          onSave={quitModal}
        />
      </FirstModalBody>
    </>
  );
};
