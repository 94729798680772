import React, {
  CSSProperties, forwardRef, RefObject, FC,
} from 'react';
import clsx from 'clsx';
import { colorsType } from '../types/types';
import { FlexProps } from './Flex';

interface Border {
  color: colorsType;
  style: 'solid';
  width: number;
}

interface SizedBoxProps extends FlexProps {
  backgroundColor?: colorsType;
  border?: Border;
  borderRadius?: number;
  bottom?: number;
  color?: colorsType;
  flex?: boolean;
  height?: number | string;
  left?: number;
  maxHeight?: number;
  maxWidth?: number;
  minHeight?: number;
  minWidth?: number;
  opacity?: number;
  overflow?: 'hidden' | 'auto' | 'scroll' | 'visible';
  overflowX?: 'hidden' | 'auto' | 'scroll' | 'visible';
  overflowY?: 'hidden' | 'auto' | 'scroll' | 'visible';
  position?: 'relative' | 'absolute' | 'fixed' | 'static' | 'sticky' | 'inherit';
  right?: number;
  style?: CSSProperties;
  top?: number;
  width?: number | string;
  zIndex?: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [x: string]: any;
}

const SizedBox: FC<SizedBoxProps> = forwardRef((
  props,
  ref: string | ((instance: HTMLDivElement | null) => void) | RefObject<HTMLDivElement> | null | undefined,
) => {
  const {
    align,
    justify,
    direction,
    backgroundColor,
    border,
    borderRadius,
    bottom,
    className,
    color,
    flex,
    height,
    left,
    maxHeight,
    maxWidth,
    minHeight,
    minWidth,
    opacity,
    overflow,
    overflowX,
    overflowY,
    position,
    right,
    style,
    top,
    width,
    zIndex,
    ...rest
  } = props;

  const stl = {
    ...style,
    borderRadius,
    borderStyle: border && border.style,
    borderWidth: border && border.width,
    bottom,
    height,
    left,
    maxHeight,
    maxWidth,
    minHeight,
    minWidth,
    opacity,
    ...(overflow && { overflow }),
    ...(overflowX && { overflowX }),
    ...(overflowY && { overflowY }),
    position,
    right,
    top,
    width,
  };

  return (
    <div
      ref={ref}
      style={stl}
      {...rest}
      className={clsx(
        className,
        backgroundColor && `bg-${backgroundColor}`,
        color && `color-${color}`,
        border && `border-${border.color}`,
        align && `align-items-${align}`,
        justify && `justify-content-${justify}`,
        direction && `flex-${direction}`,
        {
          'd-flex': flex,
        },
      )}
    />
  );
});

export default SizedBox;
