import { Flex, Heading } from '@chakra-ui/react';
import { FC } from 'react';
import { useDefaultStyles } from '../../../hooks/useDefaultStyles';

type CompassTableWrapperType = {
  title: string,
}

export const CompassTableWrapper: FC<CompassTableWrapperType> = ({
  title,
  children,
}) => {
  const { colors, cardBorder } = useDefaultStyles();
  return (
    <Flex
      bgColor={colors.background}
      align="center"
      // w={{ base: '100%', md: '60%' }}
      flexFlow="column"
      // my={5}
      p={4}
      justify="center"
      style={{ gap: 10 }}
      {...cardBorder}
    >
      <Heading alignSelf="flex-start" size="md">{title}</Heading>
      {children}
    </Flex>
  );
};
