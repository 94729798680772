import React, {
  useMemo, useContext, FC,
} from 'react';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from '@chakra-ui/react';
import { getActivityIndex } from '../../../hooks/getActivityIndex';
import Flex from '../../../lib/components/Flex';
import Text from '../../../lib/components/Text';
import { AgendaContext } from '../Agenda.context';
import { useGetExtraMockExams } from '../../../api/extra-activities/extra-mock-exams';
import { ExtraHeader } from './_components/ExtraHeader';
import { Questions } from '../types/Questions.component';

const ExtraMockExamsContainer: FC = () => {
  const agendaCtx = useContext(AgendaContext);

  const id = getActivityIndex();

  const actualId = useMemo(() => {
    const qs = agendaCtx.extras.activityMockExamExtra;

    const index = qs?.findIndex(x => x._id === id) || 0;

    if (qs && qs.length > 0 && index !== -1) {
      return qs[index]._id;
    }

    if (qs && qs.length > 0) {
      return qs[0]._id;
    }

    return '';
  }, [agendaCtx.extras.activityMockExamExtra, id]);

  if (agendaCtx.extrasLoading) {
    return (
      <Flex align="center" justify="center" style={{ minHeight: 400 }}>
        <FontAwesomeIcon icon={faSpinner} spin size="6x" className="color-dark-gray" />
      </Flex>
    );
  }

  return (
    <Box maxW={900} m="0 auto">
      <ExtraHeader
        title="Simulado Aristo [Extra]"
      />
      <ExtraMockExams id={actualId} />
    </Box>
  );
};

interface ExtraMockExamsProps {
  id: string;
}

const ExtraMockExams: FC<ExtraMockExamsProps> = ({ id }) => {
  const query = useGetExtraMockExams({
    id,
  });

  if (query.loading) {
    return (
      <Flex align="center" justify="center" style={{ minHeight: 400 }}>
        <FontAwesomeIcon icon={faSpinner} spin size="5x" className="color-dark-gray" />
      </Flex>
    );
  }

  if (!query.error && !query.loading && query.data && query.data.getMockExamExtraActivityDetails.questions.length > 0) {
    return (
      <Questions
        model="mocks"
        activityId={id}
        data={query.data.getMockExamExtraActivityDetails.questions}
        finished={query.data.getMockExamExtraActivityDetails.finished}
        mockExamTemplate={query.data.getMockExamExtraActivityDetails.mockExamTemplate}
        startedAt={query.data.getMockExamExtraActivityDetails.startedAt
          ? new Date(query.data.getMockExamExtraActivityDetails.startedAt) : undefined}
        timeToDo={query.data.getMockExamExtraActivityDetails.timeToDo}
        serverPreChoices={query.data.getMockExamExtraActivityDetails.preChoice}
        examBlockPrinting={query.data.getMockExamExtraActivityDetails.printingIsBlocked}
      />
    );
  }

  return (
    <Text>Nenhuma atividade disponível para hoje!</Text>
  );
};

export default ExtraMockExamsContainer;
