import { gql, QueryResult, useQuery } from '@apollo/client';

interface GetGeneralRankingInput {
  mockExamTemplate: string;
}

interface GetGeneralRankingOutput {
  getUserRankingStatus: {
    userId: string;
    name: string;
    grade: number;
    ranking: number;
    total: string;
  }[];
}

const GET_GENERAL_RANKING = gql`
  query GET_GENERAL_RANKING($mockExamTemplate: ObjectId!){
    getUserRankingStatus(mockExamTemplate: $mockExamTemplate){
      userId
      name
      ranking
      total
      grade
    }
  }
`;

export function useGetGeneralRanking({
  mockExamTemplate,
}: GetGeneralRankingInput): QueryResult<GetGeneralRankingOutput, GetGeneralRankingInput> {
  return useQuery<GetGeneralRankingOutput, GetGeneralRankingInput>(GET_GENERAL_RANKING, {
    variables: {
      mockExamTemplate,
    },
    skip: mockExamTemplate === '',
  });
}

interface GetUserPercentileInput {
  mockExamTemplate: string;
}

interface GetUserPercentileOutput {
  getRankingPercentiles: {
    userRanking: {
      total: number;
      grade: number;
    };
    userPercentile: number;
  };
}

const GET_USER_PERCENTILE = gql`
  query GET_USER_PERCENTILE($mockExamTemplate: ObjectId!){
    getRankingPercentiles(mockExamTemplate: $mockExamTemplate){
      userRanking {
        grade
        total
      }
      userPercentile
    }
  }
`;

export function useGetUserPercentile({
  mockExamTemplate,
}: GetUserPercentileInput) {
  return useQuery<GetUserPercentileOutput, GetUserPercentileInput>(GET_USER_PERCENTILE, {
    variables: {
      mockExamTemplate,
    },
    skip: !mockExamTemplate,
  });
}
