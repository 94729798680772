import { FC } from 'react';
import moment from 'moment';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import 'moment/locale/pt-br';
import { BrowserRouter } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import Routes from './Routes';
import client from './client/apollo';
import IsLoaded from './IsLoaded';
import { GlobalStorage } from './Global.context';

library.add(fas);

moment.locale('pt-BR');

const App: FC = () => {
  return (
    <BrowserRouter>
      <ApolloProvider client={client}>
        <GlobalStorage>
          <IsLoaded>
            <Routes />
          </IsLoaded>
        </GlobalStorage>
      </ApolloProvider>
    </BrowserRouter>
  );
};

export default App;
