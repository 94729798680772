import React, {
  FC,
  useState,
  useMemo,
  useContext,
  useCallback,
  useEffect,
  Dispatch,
  SetStateAction,
} from 'react';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Box, HStack, Text } from '@chakra-ui/react';
import {
  StudyWeek, StudyWeekSchema, useProfile,
} from '../../../api/profile';
import Flex from '../../../lib/components/Flex';
import { PrivateContext } from '../../../Private.context';
import WeekToggler, { WeekTogglerValue, weekTogglerValueInitialValue } from './WeekToggler';
import { cleanTypename } from '../../../utils/cleanTypename';
import { useCoursesManyInfo } from '../../../api/courses';
import { SecondaryButton } from '../../../lib/components/SecondaryButton';
import { PrimaryButton } from '../../../lib/components/PrimaryButton';
import { useDevice } from '../../../hooks/useDevice';
import { useDefaultStyles } from '../../../hooks/useDefaultStyles';

export interface DeserializedStudyWeek {
  theoreticalStudy: WeekTogglerValue;
  smartReview: WeekTogglerValue;
  theoreticalReview: WeekTogglerValue;
  examAndMocks: WeekTogglerValue;
}

type StudyWeekEntrie = [keyof StudyWeek, StudyWeekSchema]
type DeserializedStudyWeekEntrie = [keyof DeserializedStudyWeek, WeekTogglerValue]

function weekTogglerValueWithMinActive(n = 0): WeekTogglerValue {
  const value = { ...weekTogglerValueInitialValue };
  Object.keys(value).forEach((k, i) => {
    if (n < i) {
      value[k as keyof WeekTogglerValue] = true;
    }
  });
  return value;
}

function deserializeStudyWeek(sw: StudyWeek, minValues = {
  exam: 5,
  smartReview: 5,
  theoreticalReview: 5,
  theoreticalStudy: 5,
}): DeserializedStudyWeek {
  const deserialized: DeserializedStudyWeek = {
    examAndMocks: weekTogglerValueWithMinActive(minValues.exam),
    smartReview: weekTogglerValueWithMinActive(minValues.smartReview),
    theoreticalReview: weekTogglerValueWithMinActive(minValues.theoreticalReview),
    theoreticalStudy: weekTogglerValueWithMinActive(minValues.theoreticalStudy),
  };
  (Object.entries(sw) as StudyWeekEntrie[]).forEach(([day, week]) => {
    deserialized.examAndMocks[day] = week.examAndMocks;
    deserialized.smartReview[day] = week.smartReview;
    deserialized.theoreticalReview[day] = week.theoreticalReview;
    deserialized.theoreticalStudy[day] = week.theoreticalStudy;
  });
  return deserialized;
}

const defaultStudyWeekSchema = {
  examAndMocks: false,
  smartReview: false,
  theoreticalReview: false,
  theoreticalStudy: false,
};

function serializeStudyWeek(dsw: DeserializedStudyWeek): StudyWeek {
  const serialized: StudyWeek = {
    monday: { ...defaultStudyWeekSchema },
    tuesday: { ...defaultStudyWeekSchema },
    wednesday: { ...defaultStudyWeekSchema },
    thursday: { ...defaultStudyWeekSchema },
    friday: { ...defaultStudyWeekSchema },
    saturday: { ...defaultStudyWeekSchema },
    sunday: { ...defaultStudyWeekSchema },
  };
  (Object.entries(dsw) as DeserializedStudyWeekEntrie[]).forEach(([activityType, week]) => {
    Object.entries(week).forEach(([day, val]) => {
      serialized[day as keyof StudyWeek][activityType] = val;
    });
  });
  return serialized;
}

export interface TypicalWeekProps {
  disableUndo?: boolean;
  onSave?: () => void;
  isOnboarding?: boolean;
  primaryButtonText?: string;
  primaryButtonStyle?: React.CSSProperties;
  justifyButtons?: 'end' | 'center';
  hideSuccessToast?: boolean;
}

const TypicalWeek: FC<TypicalWeekProps> = ({
  disableUndo, onSave, isOnboarding, primaryButtonText = 'Salvar alterações',
  primaryButtonStyle, justifyButtons = 'end', hideSuccessToast,
}: TypicalWeekProps) => {
  const WEEKDAYS = [
    { mobile: 'Seg', web: 'Segunda' },
    { mobile: 'Ter', web: 'Terça' },
    { mobile: 'Qua', web: 'Quarta' },
    { mobile: 'Qui', web: 'Quinta' },
    { mobile: 'Sex', web: 'Sexta' },
    { mobile: 'Sab', web: 'Sábado' },
    { mobile: 'Dom', web: 'Domingo' },
  ];

  const device = useDevice();
  const { profile: { courses } } = useContext(PrivateContext);
  const coursesInfoQuery = useCoursesManyInfo(courses.map(course => course.course));
  const { colors } = useDefaultStyles();

  const [examAndMocks, setExamAndMocks] = useState(weekTogglerValueInitialValue);
  const [smartReview, setSmartReview] = useState(weekTogglerValueInitialValue);
  const [theoreticalReview, setTheoreticalReview] = useState(weekTogglerValueInitialValue);
  const [theoreticalStudy, setTheoreticalStudy] = useState(weekTogglerValueInitialValue);

  const [examAndMocksErr, setExamAndMocksErr] = useState(false);
  const [smartReviewErr, setSmartReviewErr] = useState(false);
  const [theoreticalReviewErr, setTheoreticalReviewErr] = useState(false);
  const [theoreticalStudyErr, setTheoreticalStudyErr] = useState(false);

  const [dirtyInput, setDirtyInput] = useState(false);
  const [loading, setLoading] = useState(false);

  const { onUpdateProfile } = useProfile();

  const courseInfo = useMemo(() => {
    const { data } = coursesInfoQuery;
    if (!data) {
      return undefined;
    }
    return [...data.coursesManyInfo].find(course => course._id === courses[0].course);
  }, [courses, coursesInfoQuery]);

  const handleUpdate = useCallback(async () => {
    setLoading(true);
    try {
      const data = await onUpdateProfile({
        studyWeek: serializeStudyWeek({
          examAndMocks,
          smartReview,
          theoreticalReview,
          theoreticalStudy,
        }),
      });
      if (data) {
        if (!hideSuccessToast) {
          toast.success('Atualizado com sucesso!');
        }
        setDirtyInput(false);
      } else {
        toast.error('Erro ao atualizar!');
      }
    } catch (error) {
      toast.error(error);
    } finally {
      if (onSave) {
        onSave();
      }
      setLoading(false);
    }
  }, [examAndMocks, hideSuccessToast, onSave, onUpdateProfile, smartReview, theoreticalReview, theoreticalStudy]);

  const saveDisabled = useMemo(() => {
    if (!courseInfo) {
      return true;
    }
    const {
      exam: examMax,
      smartReview: sReviewMax,
      theoreticalReview: tReviewMax,
      theoreticalStudy: tStudyMax,
    } = courseInfo.courseWeekGoals.max;
    return (!dirtyInput && !isOnboarding)
      || (examAndMocksErr && examMax !== 0)
      || (smartReviewErr && sReviewMax !== 0)
      || (theoreticalReviewErr && tReviewMax !== 0)
      || (theoreticalStudyErr && tStudyMax !== 0);
  }, [
    courseInfo, dirtyInput, examAndMocksErr, isOnboarding, smartReviewErr, theoreticalReviewErr, theoreticalStudyErr,
  ]);

  const handleChange = useCallback((setField: Dispatch<SetStateAction<WeekTogglerValue>>) => (v: WeekTogglerValue) => {
    setField(v);
    setDirtyInput(true);
  }, []);

  const setAllTypicalWeeks = useCallback(() => {
    const vals = deserializeStudyWeek(cleanTypename(courses[0].studyWeek), courseInfo?.courseWeekGoals.min);
    // console.log('vals', vals);
    setExamAndMocks(vals.examAndMocks);
    setSmartReview(vals.smartReview);
    setTheoreticalReview(vals.theoreticalReview);
    setTheoreticalStudy(vals.theoreticalStudy);
  }, [courseInfo?.courseWeekGoals.min, courses]);

  const handleUndo = useCallback(() => {
    setAllTypicalWeeks();
    setDirtyInput(false);
  }, [setAllTypicalWeeks]);

  useEffect(() => {
    setAllTypicalWeeks();
  }, [setAllTypicalWeeks]);

  // console.log(courses[0].studyWeek);

  if (!courseInfo) {
    return (
      <Flex justify="center">
        <FontAwesomeIcon icon={faSpinner} spin size="4x" className="color-dark-gray mt-4" />
      </Flex>
    );
  }

  return (
    <Box>
      <Box
        align="center"
        display="flex"
        flexFlow="row"
        padding="0 1em"
        height="3em"
        alignItems="center"
        backgroundColor={colors.secondary.goDarker}
        borderRadius={8}
        color={colors.lighter.keep}
        mb={1}
        mt={2}
      >
        <Box display={{ base: 'none', md: 'block' }} flex={2} />
        {WEEKDAYS.map(day => (
          <Box
            flex={1}
            key={day.web}
          >
            <Text fontWeight="bold">{day[device]}</Text>
          </Box>
        ))}
      </Box>

      <WeekToggler
        className="mb-1"
        label="Estudo Teórico"
        min={courseInfo.courseWeekGoals.min.theoreticalStudy}
        max={courseInfo.courseWeekGoals.max.theoreticalStudy}
        value={theoreticalStudy}
        onChange={handleChange(setTheoreticalStudy)}
        onError={e => setTheoreticalStudyErr(!!e)}
      />
      <WeekToggler
        className="mb-1"
        label="Revisão Inteligente"
        min={courseInfo.courseWeekGoals.min.smartReview}
        max={courseInfo.courseWeekGoals.max.smartReview}
        value={smartReview}
        onChange={handleChange(setSmartReview)}
        onError={e => setSmartReviewErr(!!e)}
      />
      <WeekToggler
        className="mb-1"
        label="Revisão Teórica"
        min={courseInfo.courseWeekGoals.min.theoreticalReview}
        max={courseInfo.courseWeekGoals.max.theoreticalReview}
        value={theoreticalReview}
        onChange={handleChange(setTheoreticalReview)}
        onError={e => setTheoreticalReviewErr(!!e)}
      />
      <WeekToggler
        className="mb-4"
        label="Provas e simulados"
        min={courseInfo.courseWeekGoals.min.exam}
        max={courseInfo.courseWeekGoals.max.exam}
        value={examAndMocks}
        onChange={handleChange(setExamAndMocks)}
        onError={e => setExamAndMocksErr(!!e)}
      />

      <Flex>
        <HStack justify={justifyButtons} width="100%">
          {!disableUndo && (
            <SecondaryButton
              disabled={!dirtyInput || loading}
              onClick={handleUndo}
              color={colors.secondary.goLighter}
            >
              <Text fontSize={{ base: 'xs', md: 'md' }}>
                Desfazer alterações
              </Text>
            </SecondaryButton>
          )}
          <PrimaryButton
            onClick={handleUpdate}
            disabled={saveDisabled}
            isLoading={loading}
            style={primaryButtonStyle}
          >
            <Text fontSize={!primaryButtonStyle ? { base: 'xs', md: 'md' } : ''}>
              {primaryButtonText}
            </Text>
          </PrimaryButton>
        </HStack>
      </Flex>
    </Box>
  );
};

export default TypicalWeek;
