import { Box, Button, Flex, Text } from '@chakra-ui/react';
import { useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useQuestionMany } from '../../api/agenda/questions';
import { Loading } from '../../lib/components/Loading';
import { Forum2 } from '../_components/Forum/Forum2';
import QuestionCard from '../_components/Question/QuestionCard.component';

export function QuestionViewer() {
  const [answer, setAnswer] = useState(-1);
  const [selected, setSelected] = useState(-1);
  const { search } = useLocation();
  const [showForum, setShowForum] = useState(false);
  const [selectedChoice, setSelectedChoice] = useState(answer);

  const questionId = useMemo(() => {
    return new URLSearchParams(search).get('id');
  }, [search]);

  const courseId = useMemo(() => {
    return new URLSearchParams(search).get('courseId') ?? undefined;
  }, [search]);

  const { data, loading } = useQuestionMany({
    ids: questionId ? [questionId] : [],
    calendarDate: '20210101',
    type: 'theoreticalStudy',
  });

  const questionData = useMemo(() => {
    if (!data || loading) {
      return undefined;
    }
    return data.questionMany[0];
  }, [data, loading]);

  return (
    <Box m="1rem auto" maxW={980}>
      {loading && <Loading />}
      {!loading && questionData && (
        <Box>
          <QuestionCard
            data={questionData}
            answer={answer}
            selectedChoice={selectedChoice}
            setSelectedChoice={setSelectedChoice}
            index={0}
            onAnswerClick={async choice => setAnswer(choice)}
            onSelectChoice={async choice => setSelected(choice)}
            preChoice={{ questionId: '', selected }}
            showComment={answer !== -1}
            showMoreOptions
            showResults={answer !== -1}
          />
          <Flex w="100%" justify="center">
            <Button mt={8} onClick={() => setShowForum(prev => !prev)}>
              {`${showForum ? 'Esconder' : 'Ver'} fórum`}
            </Button>
          </Flex>
          <Forum2
            id={questionData._id}
            show={showForum}
            tag={['Questão', questionData.classification.join('|') || 'Sem classificação'].join('|')}
            classification={questionData.classification}
            contentType="Question"
            courseIdFromParam={courseId}
          />
        </Box>
      )}
      {!loading && !questionData && <Text>Questão não encontrada!</Text>}
    </Box>
  );
}
