import { Box, Square, Text } from '@chakra-ui/react';
import { FC, ReactNode } from 'react';
import { useDefaultStyles, useRGBColor } from '../../../hooks/useDefaultStyles';

type KeyboardKeyType = {
  icon?: ReactNode,
  text?: string,
  iconColor?: string,
  bgColor?: string,
  fontSize?: number,
  mr?: number,
  ml?: number,
}

export const KeyboardKey: FC<KeyboardKeyType> = ({
  icon,
  iconColor,
  text,
  bgColor,
  fontSize,
  mr,
  ml,
}) => {
  const { colors } = useDefaultStyles();
  const { toRGB } = useRGBColor();
  return (
    <Square
      color="#555"
      mr={mr}
      ml={ml}
      fontWeight="bold"
      bgColor={bgColor || colors.lightGray.keep}
      size={14}
      position="relative"
      borderRadius={10}
      boxShadow={`2px 2px 2px 2px ${toRGB(colors.alpha[400])}`}
    >
      {
        icon && (
          <Box position="absolute" top={0} right={1} color={iconColor}>
            {icon}
          </Box>
        )
      }
      {
        text && (
          <Text fontSize={fontSize}>
            {text}
          </Text>
        )
      }
    </Square>
  );
};
