import { FC } from 'react';
import { Button, ButtonProps } from '@chakra-ui/react';
import { useDefaultStyles } from '../../hooks/useDefaultStyles';

// interface PrimaryButtonProps extends ButtonProps { }

export const SecondaryButton: FC<ButtonProps> = ({
  children, ...rest
}) => {
  const { colors, colorPalette } = useDefaultStyles();
  return (
    <Button
      borderColor={colors.secondary.goDarker}
      color={colors.secondary.goFullLighter}
      colorScheme={colorPalette}
      variant="outline"
      {...rest}
    >
      {children}
    </Button>
  );
};
