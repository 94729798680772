import React, { FC, TextareaHTMLAttributes } from 'react';
import clsx from 'clsx';

const Textarea: FC<TextareaHTMLAttributes<HTMLTextAreaElement>> = ({ className, ...rest }) => {
  return (
    <textarea {...rest} className={clsx('jj-textarea', className)} />
  );
};

export default Textarea;
