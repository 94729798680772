import { FC } from 'react';
import { Box, HStack, Select as ChakraSelect, SelectProps as ChakraSelectProps, Text, Tooltip } from '@chakra-ui/react';
import { faInfoCircle, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDefaultStyles, useRGBColor } from '../../hooks/useDefaultStyles';

interface SelectProps extends ChakraSelectProps {
  loading?: boolean;
  label?: string;
  info?: string;
  options?: { value: string; label: string; }[];
  required?: boolean;
  labelColor?: string;
  bgColor?: string;
  optionBgColor?: string;
}

const Select: FC<SelectProps> = ({
  loading, label, options, required = false, info,
  placeholder, optionBgColor, labelColor, width, w, ...rest
}) => {
  const { toRGB } = useRGBColor();
  const { colors } = useDefaultStyles();
  if (loading) {
    return (
      <Box display="flex" align="center" justify="center">
        <FontAwesomeIcon icon={faSpinner} spin />
      </Box>
    );
  }

  return (
    <Box w={width || w}>
      {
        label && (
          <HStack>
            <Text fontSize="sm" color={labelColor || colors.secondary.goALittleDarker}>{label}</Text>
            {
              info && (
                <Tooltip label={info}>
                  <Box color={colors.secondary.keep}>
                    <FontAwesomeIcon icon={faInfoCircle} />
                  </Box>
                </Tooltip>
              )
            }
          </HStack>
        )
      }
      <ChakraSelect
        className="chakra-select"
        placeholder={placeholder || (!required ? 'Escolha' : undefined)}
        borderColor="light.100"
        {...rest}
        _placeholder={{ color: 'light.100' }}
        _disabled={{
          bgColor: colors.lightGray.goAlmostFullDarker,
          cursor: 'not-allowed',
        }}
      >
        {options && options.map(item => (
          <option
            key={item.value}
            style={{ backgroundColor: toRGB(optionBgColor || colors.background) }}
            value={item.value}
          >
            {item.label}
          </option>
        ))}
      </ChakraSelect>
    </Box>
  );
};

export default Select;
