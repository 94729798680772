import { Button, useColorMode } from '@chakra-ui/react';
import { FC, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBrain } from '@fortawesome/free-solid-svg-icons';

interface HarrisonButtonProps {
  askHarrison: () => Promise<void>,
}

export const HarrisonButton: FC<HarrisonButtonProps> = ({
  askHarrison,
}) => {
  const { colorMode } = useColorMode();
  const bgColor = useMemo(() => (colorMode === 'light' ? '#B1F1C1' : '#12512E'), [colorMode]);
  const textColor = useMemo(() => (colorMode === 'light' ? '#00210E' : '#B1F1C1'), [colorMode]);

  return (
    <Button
      onClick={askHarrison}
      leftIcon={<FontAwesomeIcon icon={faBrain} />}
      bg={bgColor}
      color={textColor}
      rounded="full"
      disabled={false}
      fontSize="14px"
      maxWidth="240px"
    >
      Obter resposta do Harrison
    </Button>
  );
};
