import { Flex, Heading, Text } from '@chakra-ui/react';
import { FC } from 'react';
import { useDefaultStyles, useRGBColor } from '../../../hooks/useDefaultStyles';
import { TrophyVariantType } from './TrophyFragments';
import { TrophyTableRow } from './TrophyTableRow';

type TrophyFragmentsExplanationCardType = {
  numberOfActivities?: number,
}

export const TrophyFragmentsExplanationCard: FC<TrophyFragmentsExplanationCardType> = ({
  numberOfActivities = 0,
}) => {
  const { cardBorder, colors } = useDefaultStyles();
  const { toRGB } = useRGBColor();
  const TROPHY_TYPES: TrophyVariantType[] = ['bronze', 'silver', 'gold'];
  return (
    <Flex
      p={{ base: 4, md: 8 }}
      w="100%"
      {...cardBorder}
      flexFlow="column"
      gridGap={4}
    >
      <Heading size="md">Fragmentos de troféu</Heading>
      <Text>Para coletar seu troféu semanal, você deve coletar os fragmentos.</Text>
      <Flex w="100%" justify="center">
        <Flex
          flexFlow="column"
          w="100%"
          borderRadius={8}
          overflow="hidden"
          border={`2px solid ${toRGB(colors.lightGray.goAlmostFullDarker)}`}
        >
          <Flex
            w="100%"
            bgColor={colors.lighter.goAlmostFullDarker}
            p={3}
            px={37}
            justify="space-between"
            borderBottom={`2px solid ${toRGB(colors.lightGray.goAlmostFullDarker)}`}
          >
            <Text>Fragmento</Text>
            <Text>Troféu</Text>
          </Flex>
          {
            TROPHY_TYPES.map((type, i) => (
              <TrophyTableRow
                type={type}
                borderBottom={i !== TROPHY_TYPES.length - 1}
                numberOfActivities={numberOfActivities}
              />
            ))
          }
        </Flex>

      </Flex>
      <Text>
        Você só pode coletar 1 troféu por semana. Por isso, quando coletar os mais valiosos,
        eles substituirão os menos valiosos já adquiridos.
      </Text>
    </Flex>
  );
};
