import { Box, HStack, Text, useDisclosure } from '@chakra-ui/react';
import { faCircle } from '@fortawesome/free-regular-svg-icons';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactNode, useCallback, useContext, useMemo, useState } from 'react';
import { useDefaultStyles } from '../../../hooks/useDefaultStyles';
import ConfirmModal from '../../../lib/components/ConfirmModal';
import { AgendaContext } from '../Agenda.context';

interface FinishLessonButtonProps {
  isFinished?: boolean;
  finishedText?: string;
  unfinishedText?: string;
  children?: ReactNode;
  modalTitle?: string;
  modalSubtitle?: string;
  onConfirm?: () => void | Promise<void>;
  isConfirmDisabled?: boolean;
  isTheoreticalReview?: boolean;
}

export function FinishLessonButton({
  isFinished = false, finishedText, unfinishedText, modalTitle,
  modalSubtitle, onConfirm, children, isConfirmDisabled = false,
  isTheoreticalReview = false,
}: FinishLessonButtonProps) {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { colors } = useDefaultStyles();
  const { percentOfQuestionsAnswered } = useContext(AgendaContext);

  const [isConfirming, setIsConfirming] = useState(false);

  const handleConfirm = useCallback(async () => {
    setIsConfirming(true);
    if (onConfirm) {
      await onConfirm();
    }
    onClose();
    setIsConfirming(false);
  }, [onClose, onConfirm]);

  const buttonColors = useMemo(() => {
    if (isFinished) {
      return {
        borderColor: colors.green.keep,
        color: colors.lighter.fromWhite,
        bgColor: colors.green.keep,
        bgColorHover: undefined,
      };
    }
    if (percentOfQuestionsAnswered !== 1 && isTheoreticalReview) {
      return {
        borderColor: colors.darkGray.keep,
        color: colors.darkGray.keep,
        bgColor: undefined,
        bgColorHover: colors.lightGray.goAlmostFullDarker,
      };
    }
    return {
      borderColor: colors.green.keep,
      color: colors.green.keep,
      bgColor: undefined,
      bgColorHover: 'green.100',
    };
  }, [
    colors.darkGray.keep, colors.green.keep, colors.lightGray.goAlmostFullDarker,
    colors.lighter.fromWhite, isFinished, isTheoreticalReview, percentOfQuestionsAnswered]);

  return (
    <>
      <Box
        as="button"
        bgColor={buttonColors.bgColor}
        borderColor={buttonColors.borderColor}
        borderWidth="1px"
        borderStyle="solid"
        borderRadius={4}
        color={buttonColors.color}
        px={1}
        py="0.2rem"
        onClick={isFinished ? undefined : onOpen}
        cursor={isFinished ? 'default' : 'pointer'}
        transition="all 0.2s"
        {...(!isFinished && {
          _hover: {
            bgColor: buttonColors.bgColorHover,
          },
        })}
        whiteSpace="nowrap"
      >
        <HStack align="center">
          <FontAwesomeIcon icon={isFinished ? faCheckCircle : faCircle} />
          <Text>{isFinished ? finishedText : unfinishedText}</Text>
        </HStack>
      </Box>
      <ConfirmModal
        isOpen={isOpen}
        onDecline={onClose}
        onConfirm={handleConfirm}
        icon={<FontAwesomeIcon icon={faCheckCircle} />}
        title={modalTitle ?? 'Concluir'}
        subtitle={modalSubtitle}
        confirmText="Confirmar"
        declineText="Cancelar"
        loading={isConfirming}
        loadingText="Concluindo"
        isConfirmDisabled={isConfirmDisabled}
      >
        {children}
      </ConfirmModal>
    </>
  );
}
