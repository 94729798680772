import { Button, Flex, HStack, Text } from '@chakra-ui/react';
import { faCaretLeft, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC, useContext } from 'react';
import { useDefaultStyles } from '../../../../hooks/useDefaultStyles';
import { FlashcardsCreationContext } from '../../contexts/FlashcardsCreation.context';

export const CardsNavigator: FC = () => {
  const {
    skip,
    CARDS_LIMIT,
    currPage,
    loadingFetchMore,
    masterLoading,
    noOfPages,
    handleNextPageClick,
    handlePreviousPageClick,
  } = useContext(FlashcardsCreationContext);
  const { colors } = useDefaultStyles();
  const isNextButtonDisabled = currPage >= noOfPages;
  const isPreviousButtonDisabled = skip < CARDS_LIMIT;

  if (Object.values(masterLoading).some(x => x)) {
    return null;
  }

  return (
    <Flex
      align="center"
      justify="flex-end"
      flexFlow={{ base: 'column', md: 'row' }}
      w="100%"
      p={4}
    >
      <HStack>
        {
          (!isNextButtonDisabled
            || !isPreviousButtonDisabled)
          && (
            <>
              <Button
                onClick={handlePreviousPageClick}
                color={colors.secondary.keep}
                variant="ghost"
                disabled={isPreviousButtonDisabled || loadingFetchMore}
                leftIcon={<FontAwesomeIcon icon={faCaretLeft} />}
                isLoading={loadingFetchMore}
              >
                Anterior
              </Button>
              <Text color={colors.secondary.keep}>
                {`${currPage} / ${noOfPages}`}
              </Text>
              <Button
                onClick={handleNextPageClick}
                color={colors.secondary.keep}
                variant="ghost"
                disabled={isNextButtonDisabled || loadingFetchMore}
                rightIcon={<FontAwesomeIcon icon={faCaretRight} />}
                isLoading={loadingFetchMore}
              >
                Próximo
              </Button>
            </>
          )
        }
      </HStack>
    </Flex>
  );
};
