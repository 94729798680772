import React, { FC, useMemo } from 'react';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFlashcardMany } from '../../../../api/agenda/flashcards';
import { getDateParam } from '../../../../hooks/getDateParam';
import Flex from '../../../../lib/components/Flex';
import AgendaFlashcards from '../../types/AgendaFlashcards';
import FlashCardEmpty from '../../types/FlashCardEmpty';

interface ExtraSmartReviewFlashcardProps {
  ids?: string[];
  id: string;
  finished: boolean;
}

const ExtraSmartReviewFlashcard: FC<ExtraSmartReviewFlashcardProps> = ({ id, ids, finished }) => {
  const query = useFlashcardMany({
    ids: ids || [],
    calendarDate: getDateParam(),
    activity: id,
  });

  const dataQuery = useMemo(() => {
    const { data } = query;
    if (data) {
      return data.flashcardMany;
    }

    return [];
  }, [query]);

  if (query.loading) {
    return (
      <Flex align="center" justify="center" style={{ minHeight: 400 }}>
        <FontAwesomeIcon icon={faSpinner} spin size="5x" className="color-dark-gray" />
      </Flex>
    );
  }

  if (dataQuery && dataQuery.length > 0) {
    return <AgendaFlashcards data={dataQuery} activityId={id} finished={finished} />;
  }

  return <FlashCardEmpty />;
};

export default ExtraSmartReviewFlashcard;
