import { gql, useMutation } from '@apollo/client';
import { useCallback } from 'react';
import notificationClient from '../../client/notifications-apollo';
import { Profile } from '../profile';

interface RegisterUserTokenInput {
  firebaseId: string;
  token: string;
  operation?: 'add' | 'remove';
}

interface RegisterUserTokenOutput {
  updateUserTokenRegistration: boolean;
}

const REGISTER_USER_TOKEN = gql`
  mutation UpdateUserTokenRegistration(
    $firebaseId: String!,
    $token: String!,
    $operation: EOperation,
  ) {
    updateUserTokenRegistration(
      firebaseId: $firebaseId,
      token: $token,
      operation: $operation,
    )
  }
`;

const REGISTER_USER_EXPO_TOKEN = gql`
  mutation UpdateUserTokenRegistration(
    $firebaseId: String!,
    $token: String!,
    $operation: EOperation,
  ) {
    updateUserExpoTokenRegistration(
      firebaseId: $firebaseId,
      token: $token,
      operation: $operation,
    )
  }
`;

interface CreateUserInput {
  firebaseId: string;
}

interface CreateUserOutput {
  createUser: string;
}

const CREATE_USER = gql`
  mutation CreateUser($firebaseId: String!) {
    createUser(firebaseId: $firebaseId)
  }
`;

export function useRegisterUserNotifications() {
  const [registerMutation] = useMutation<RegisterUserTokenOutput, RegisterUserTokenInput>(REGISTER_USER_TOKEN, {
    client: notificationClient,
  });

  const [registerExpoMutation] = useMutation<
    RegisterUserTokenOutput,
    RegisterUserTokenInput
  >(REGISTER_USER_EXPO_TOKEN, {
    client: notificationClient,
  });

  const [createUserMutation] = useMutation<CreateUserOutput, CreateUserInput>(CREATE_USER, {
    client: notificationClient,
  });

  const handleRegisterUser = useCallback(async (token: string, profile: Profile, operation?: 'add' | 'remove') => {
    try {
      await registerMutation({
        variables: {
          token,
          firebaseId: profile.uid,
          operation,
        },
      });
    } catch (error) {
      console.error(error);
    }
  }, [registerMutation]);

  const handleRegisterExpoUser = useCallback(async (token: string, uid: string, operation?: 'add' | 'remove') => {
    try {
      await registerExpoMutation({
        variables: {
          token,
          firebaseId: uid,
          operation,
        },
      });
    } catch (error) {
      console.error(error);
    }
  }, [registerExpoMutation]);

  const handleCreateUser = useCallback(async (firebaseId: string) => {
    try {
      await createUserMutation({
        variables: {
          firebaseId,
        },
      });
    } catch (error) {
      console.error(error);
    }
  }, [createUserMutation]);

  return { handleRegisterUser, handleRegisterExpoUser, handleCreateUser };
}
