import { FC, useContext, useMemo } from 'react';
import { Box, Text, Flex } from '@chakra-ui/react';
import TypicalWeek from '../_components/TypicalWeek.component';
import MyGoals from '../_components/MyGoals.component';
import { Divider } from '../../../lib/components/Divider';
import { useDefaultStyles } from '../../../hooks/useDefaultStyles';
import { PrivateContext } from '../../../Private.context';
import { CourseContext } from '../../../CourseInfoContext';
import { EMCCourses } from '../../_components/Header/SideMenu';

const StudyProfile: FC = () => {
  const { colors } = useDefaultStyles();
  const { profile } = useContext(PrivateContext);
  const { course, loading } = useContext(CourseContext);

  const userCourse = useMemo(() => {
    if (profile.courses && profile.courses.length > 0 && course && !loading) {
      return course.find(c => c._id === profile.courses[0].course);
    }
    return undefined;
  }, [course, loading, profile.courses]);

  return (
    <Flex justify="center" pb={6}>
      <Flex
        flexFlow="column"
        maxWidth={1000}
        width="100%"
      >
        {/* MEUS OBJETIVOS */}
        {
          !EMCCourses.includes(userCourse?._id || '') && (
            <>
              <Text
                color={colors.secondary.goALittleDarker}
                fontWeight="extrabold"
                marginBottom={4}
              >
                Meus objetivos
              </Text>
              <Text marginBottom={4}>
                Marque abaixo quais provas de residência médica ou de revalidação você pretende prestar.
                Essas informações podem ser alteradas a qualquer momento, mas são importantes para a
                individualização do seu cronograma. Caso ainda não saiba, deixe em branco e
                volte a esta página assim que definir as provas.
              </Text>
              <Text fontWeight="bold" marginBottom={4}>
                Atenção: A ordem escolhida implicará no peso que daremos a cada instituição.
              </Text>
              <MyGoals />

              <Box height={2} marginY={4}>
                <Divider orientation="horizontal" />
              </Box>
            </>
          )
        }

        {/* SEMANA TÍPICA */}
        <Text
          color={colors.secondary.goALittleDarker}
          fontWeight="extrabold"
          marginBottom={4}
        >
          Semana Típica
        </Text>
        <Text marginBottom={2}>
          Organize aqui a sua semana para cumprir as metas semanais do seu curso.
          A configuração deve ser feita individualmente
          para cada um dos cursos que possuir. Escolha:
        </Text>
        <Text marginBottom={4}>
          As
          <Box color={colors.secondary.goLighter} display="inline" as="span">{' metas mínimas '}</Box>
          indicam o número de atividades que você deve colocar para um estudo de alta produtividade.
          Atingindo as metas mínimas nos quatro tipos de atividades da semana, você recebe um troféu semanal. Escolha:
        </Text>
        <TypicalWeek />
      </Flex>
    </Flex>
  );
};

export default StudyProfile;
