import { Flex, useTheme, VStack, Text } from '@chakra-ui/react';
import { faBookmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC } from 'react';
import { PrimaryButton } from '../../../lib/components/PrimaryButton';

const FavoritesSprintEmpty: FC = () => {
  const theme = useTheme();
  return (
    <Flex
      textAlign="center"
      alignItems="center"
      justifyContent="center"
      flexFlow="column"
      height="30rem"
    >
      <VStack
        align="center"
        justify="center"
        gridGap=".5rem"
        width="15rem"
        mb="2rem"
      >
        <FontAwesomeIcon
          style={{
            fontSize: '4rem',
            color: theme.colors.primary[500],
          }}
          icon={faBookmark}
        />
        <Text>
          Recurso não disponível para esse curso!
        </Text>
      </VStack>
      <a href="https://aristo.com.br/" target="_blank" rel="noopener noreferrer">
        <PrimaryButton backgroundColor="#475f8d" height="50px" borderRadius="100px">
          Conheça os cursos disponíveis
        </PrimaryButton>
      </a>
    </Flex>

  );
};

export default FavoritesSprintEmpty;
