import { HStack } from '@chakra-ui/react';
import React, { FC } from 'react';
import { useDefaultStyles } from '../../../hooks/useDefaultStyles';
import { useDevice } from '../../../hooks/useDevice';
import { PrimaryButton } from '../../../lib/components/PrimaryButton';
import { SecondaryButton } from '../../../lib/components/SecondaryButton';

type DiagnosticTableMobileNavType = {
  tableStep: number,
  setTableStep: React.Dispatch<React.SetStateAction<number>>,
  noOfSteps: number,
}

export const DiagnosticTableMobileNav: FC<DiagnosticTableMobileNavType> = ({
  tableStep,
  setTableStep,
  noOfSteps,
}) => {
  const device = useDevice();
  const { colors } = useDefaultStyles();

  if (device === 'web') {
    return null;
  }

  return (
    <HStack
      paddingY="1rem"
      gridGap="1.5rem"
      justify="center"
    >
      <SecondaryButton
        bgColor={colors.white.keep}
        disabled={tableStep === 0}
        onClick={() => {
          setTableStep(prev => (
            prev !== 0 ? prev - 1 : prev
          ));
        }}
      >
        Anterior
      </SecondaryButton>
      <PrimaryButton
        disabled={tableStep === noOfSteps}
        onClick={() => {
          setTableStep(prev => (
            prev !== noOfSteps ? prev + 1 : prev
          ));
        }}
      >
        Próximo
      </PrimaryButton>
    </HStack>
  );
};
