import React, { FC, useMemo } from 'react';

type MeteorRainProps = {
}

export const MeteorRain: FC<MeteorRainProps> = () => {
  const listOfStars = useMemo(() => ([
    {
      top: '-20vh', right: '90vw', delay: '0s', duration: '2s',
    },
    {
      top: '-20vh', right: '50vw', delay: '.2s', duration: '6s',
    },
    {
      top: '-20vh', right: '150vw', delay: '.4s', duration: '4s',
    },
    {
      top: '-20vh', right: '130vw', delay: '.6s', duration: '3s',
    },
    {
      top: '-20vh', right: '40vw', delay: '.8s', duration: '10s',
    },
    {
      top: '-20vh', right: '120vw', delay: '1s', duration: '15s',
    },
    {
      top: '-20vh', right: '80vw', delay: '1.2s', duration: '1.75s',
    },
    {
      top: '-20vh', right: '25vw', delay: '1.4s', duration: '1.25s',
    },
    {
      top: '-20vh', right: '70vw', delay: '.75s', duration: '2.25s',
    },
    {
      top: '-20vh', right: '60vw', delay: '2.75s', duration: '2.25s',
    },
  ]), []);

  return (
    <div
      style={{
        width: '100%',
        height: '100vh',
        position: 'fixed',
        zIndex: 3,
        pointerEvents: 'none',
      }}
    >
      {listOfStars.map(star => {
        const key = Math.random().toString(36).slice(2);
        return (
          <span
            key={key}
            className="shootingStar"
            style={{
              top: star.top,
              right: star.right,
              animationDelay: star.delay,
              animationDuration: star.duration,
              left: 'initial',
            }}
          />
        );
      })}
    </div>
  );
};
