import { Box, SkeletonCircle, SkeletonText } from '@chakra-ui/react';
import { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDiagnosticExam } from '../../../api/diagnosis';
import { useLocalStorage } from '../../../hooks/useLocalStorage';
import { Questions } from '../../agenda/types/Questions.component';

export const DiagnosticMockPage: FC = () => {
  const [activityId, setActivityId] = useState('');
  const history = useHistory();
  const diagnosticExamCallback = useDiagnosticExam({ _id: activityId });
  const { findDiagnosticExamQuery: query } = diagnosticExamCallback;
  const [isCompass] = useLocalStorage('@Aristoclass:isCompass');

  useEffect(() => {
    const localActivityId = localStorage.getItem('@plataformaJJMentoria:id');
    if (localActivityId) {
      setActivityId(localActivityId);
    } else {
      history.push('/diagnostico');
    }
  }, [history, isCompass]);

  if (query.data) {
    return (
      <Questions
        model="diagnostic"
        data={query.data.findDiagnosticExam.questions}
        activityId={query.data.findDiagnosticExam._id}
        serverPreChoices={query.data.findDiagnosticExam.preChoice}
        finished={query.data.findDiagnosticExam.finished}
        diagnosticExamCallback={diagnosticExamCallback}
      />
    );
  }
  return (
    <Box px="20%" py="2rem" w="100%">
      <SkeletonCircle size="10" />
      <SkeletonText mt="4" noOfLines={4} spacing="4" />
    </Box>
  );
};
