import { Button, VStack, Text, Stack, useColorMode } from '@chakra-ui/react';
import { FC, useCallback, useState } from 'react';
import { useDevice } from '../../../../../hooks/useDevice';

interface ErrorWarningProps {
  askAgain: () => Promise<void>,
  requestMonitor: () => Promise<void>,
}

export const ErrorWarning: FC<ErrorWarningProps> = ({
  askAgain,
  requestMonitor,
}) => {
  const { colorMode } = useColorMode();
  const device = useDevice();
  const [loading, setLoading] = useState(false);

  const toggleLoading = useCallback(() => {
    setLoading(!loading);
  }, [loading]);

  const handleAsk = useCallback(async () => {
    toggleLoading();
    await askAgain();
    toggleLoading();
  }, [askAgain, toggleLoading]);

  const handleMonitor = useCallback(async () => {
    toggleLoading();
    await requestMonitor();
    toggleLoading();
  }, [requestMonitor, toggleLoading]);

  return (
    <VStack
      align={device === 'mobile' ? 'center' : 'flex-end'}
      borderRadius="12px"
      padding="16px"
      backgroundColor={colorMode === 'light' ? '#F3F3FA' : '#151C2A'}
      color={colorMode === 'light' ? 'black' : 'white'}
      spacing="16px"
    >
      <VStack align="left">
        <Text color="#D13221">Ocorreu um erro</Text>
        <Text>Harrison está estudando para responder melhor. Tente novamente em alguns instantes!</Text>
      </VStack>
      <Stack
        direction={device === 'mobile' ? 'column-reverse' : 'row'}
        spacing="16px"
      >
        <Button
          onClick={handleMonitor}
          rounded="full"
          backgroundColor="transparent"
          fontSize="14px"
        >
          Solicitar monitor
        </Button>
        <Button
          onClick={handleAsk}
          rounded="full"
          fontSize="14px"
          bg="#0673E2"
          color="white"
        >
          Tentar novamente
        </Button>
      </Stack>
    </VStack>
  );
};
