import { Flex, Text } from '@chakra-ui/react';
import React, { FC } from 'react';
import { useDefaultStyles, useRGBColor } from '../../../hooks/useDefaultStyles';
import { PrimaryButton } from '../../../lib/components/PrimaryButton';

type AristoCompassBannerType = {
  setCurrPage: React.Dispatch<React.SetStateAction<number>>;
}

export const AristoCompassBanner: FC<AristoCompassBannerType> = ({
  setCurrPage,
}) => {
  const { toRGB } = useRGBColor();
  const { colors } = useDefaultStyles();
  const bannerHeight = { base: '33rem', md: '45rem' };
  return (
    <Flex
      w="100%"
      flexFlow="column"
      h={bannerHeight}
      position="relative"
      boxShadow={`0px 2px 2px 2px ${toRGB(colors.darkGray.keep)}`}
    >
      <Flex
        w="100%"
        transition="opacity 0.4s"
        position="absolute"
        flexFlow="column"
        backgroundImage="/images/aristo-compass/physician.png"
        backgroundSize={{ base: 'cover', md: 'cover' }}
        backgroundRepeat="no-repeat"
        backgroundPosition={{ base: 'top right', md: 'center' }}
        h={bannerHeight}
        opacity={{ base: 0.3, md: 1 }}
      />
      <Flex
        w={{ base: '100%', md: undefined }}
        maxW={{ md: '55%' }}
        justify="center"
        align={{ base: 'center', md: 'flex-start' }}
        textAlign={{ base: 'center', md: 'left' }}
        position="absolute"
        h={bannerHeight}
        px={{ base: '2rem', md: '5vw' }}
        py={{ md: '5vh' }}
        pt={{ base: '5vh', md: undefined }}
        flexFlow="column"
        style={{ gap: '2rem' }}
      >
        <Text
          color={{ base: colors.primary.keep, md: colors.white.keep }}
          fontSize={{ base: 30, md: 40, lg: 50 }}
          fontWeight={700}
          lineHeight={{ base: '52px', md: '61px' }}
        >
          Aristo Compass
        </Text>
        <Text
          color={{ base: colors.primary.keep, md: colors.white.keep }}
          fontSize={{ base: 18, md: 22, lg: 27 }}
          fontWeight={500}
          lineHeight={{ base: '32px', md: '42px' }}
        >
          Saiba quais são os temas que mais caem nas suas provas prioritárias, o grau de dificuldade e muito mais!
        </Text>
        <PrimaryButton
          w={200}
          bgColor={colors.secondary.keep}
          h={50}
          borderRadius={50}
          fontSize={20}
          onClick={() => setCurrPage(1)}
        >
          Começar
        </PrimaryButton>
      </Flex>
    </Flex>
  );
};
