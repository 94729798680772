import { Box, Flex, Text } from '@chakra-ui/react';
import React, { FC, ReactNode } from 'react';

type FormItemProps = {
  label?: string;
  inputStyle?: string;
  children?: ReactNode;
  flexFlow?: string;
}

export const FormItem: FC<FormItemProps> = ({
  label,
  children,
  inputStyle = 'formItemStyle',
  flexFlow = 'row',
}: FormItemProps) => {
  return (
    <Box marginBottom="1.5rem">
      <Text
        marginBottom="1rem"
        fontSize="1.3rem"
      >
        {label}
      </Text>
      <Flex
        flexFlow={flexFlow}
        gridGap="2rem"
        className={inputStyle}
      >
        {children}
      </Flex>
    </Box>
  );
};
