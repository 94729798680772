type AgendaTheoreticalType = 'theoreticalReview'
  | 'theoreticalStudy' | 'smartReview' | 'exams' | 'mocks' | 'smartExams'

export const getAgendaTheoreticalType = (): AgendaTheoreticalType | undefined => {
  const { pathname } = window.location;
  if (pathname.includes('agenda/estudo-teorico')) {
    return 'theoreticalStudy';
  }
  if (pathname.includes('agenda/revisao-inteligente')) {
    return 'smartReview';
  }
  if (pathname.includes('agenda/revisao-teorica')) {
    return 'theoreticalReview';
  }
  if (pathname.includes('agenda/simulado-inteligente')) {
    return 'smartExams';
  }
  if (pathname.includes('agenda/simulado')) {
    return 'mocks';
  }
  if (pathname.includes('agenda/prova')) {
    return 'exams';
  }
  return undefined;
};
