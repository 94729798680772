import { Button, Center, Tooltip, useColorModeValue } from '@chakra-ui/react';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC } from 'react';
import { useDefaultStyles } from '../../hooks/useDefaultStyles';

interface CircleIconButtonProps {
  onClick: () => void;
  faIcon: IconDefinition;
  tooltipLabel?: string;
  color?: string;
  bgColor?: string;
}

export const CircleIconButton: FC<CircleIconButtonProps> = (
  {
    onClick, color, faIcon, tooltipLabel, bgColor,
  }: CircleIconButtonProps,
) => {
  const { colors } = useDefaultStyles();
  const btnBg = useColorModeValue('white', 'transparent');
  return (
    <Tooltip
      isDisabled={!tooltipLabel}
      label={tooltipLabel}
      hasArrow
    >
      <Button
        transition="all 0.5s"
        _focus={{ borderColor: 'none' }}
        fontSize={20}
        onClick={onClick}
        bgColor={bgColor || btnBg}
        color={color || colors.secondary.goALittleDarker}
        borderRadius={20}
        width={10}
        height={10}
      >
        <Center>
          <FontAwesomeIcon icon={faIcon} />
        </Center>
      </Button>
    </Tooltip>
  );
};
