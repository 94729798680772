import { Button, Flex, Text, theme } from '@chakra-ui/react';
import { FC, ReactNode } from 'react';
import { useHistory } from 'react-router-dom';
import { useDefaultStyles } from '../../../hooks/useDefaultStyles';
import { TextComponentProps } from '../../../lib/components/Text';

interface RowDataProps extends TextComponentProps {
  key: string;
  value: (string | ReactNode);
  cellTooltip?: string;
  flexSpan?: number;
}

interface HistoryCardComponentProps {
  lineTooltip?: string;
  rowData: RowDataProps[];
  iconProps: { icon: ReactNode, destiny?: string, label?: string };
  title: string;
}

const HistoryCardComponent: FC<HistoryCardComponentProps> = ({
  lineTooltip, rowData, title, iconProps,
}) => {
  const history = useHistory();
  const { colors } = useDefaultStyles();
  return (
    <Flex
      flexFlow="column"
      boxShadow="0 1px 4px 2px rgba(0, 0, 0, .25)"
      title={lineTooltip}
      marginBottom="1.6rem"
      borderRadius="1rem"
    >
      <Flex
        backgroundColor={colors.secondary.goDarker}
        borderTopLeftRadius="1rem"
        borderTopRightRadius="1rem"
        align="center"
        padding={{ base: '0.4rem 1.5rem', md: '0.8rem 1.5rem' }}
        fontSize="1.2rem"
        color={colors.lighter.fromWhite}
        maxHeight={20}
      >
        <Text
          flex={8}
          fontWeight="bold"
          noOfLines={1}
        >
          {title}
        </Text>
        <Button
          width="3rem"
          height="3rem"
          title={iconProps.label ? iconProps.label : 'Ir para a atividade'}
          bgColor={colors.lighter.goALittleDarker}
          borderRadius="50%"
          align="center"
          onClick={() => {
            if (iconProps.destiny) {
              history.push(iconProps.destiny);
            }
          }}
          justify="center"
          fontSize="1.5rem"
          boxShadow="2px 2px 10px .2px rgba(0,0,0,.45)"
          _hover={{
            boxShadow: 'inset 2px 2px 10px .2px rgba(0,0,0,.45)',
          }}
        >
          <Text
            tooltip={iconProps.label ? iconProps.label : ''}
            color={colors.secondary.keep}
            align="center"
            justify="center"
          >
            {iconProps.icon}
          </Text>
        </Button>
      </Flex>

      <Flex
        align="center"
        paddingX="1rem"
        paddingY="1rem"
        backgroundColor={theme.colors.whiteAlpha[100]}
        borderRadius="0 0 1rem 1rem"
      >
        {rowData.map(({
          key, value, cellTooltip, label, finished,
        }) => (
          <Flex
            key={key}
            flex={1}
            justify="center"
            title={cellTooltip}
          >
            <Flex
              flexFlow="column"
              textAlign="center"
            >
              <Text
                fontWeight="bold"
                color={colors.secondary.keep}
              >
                {label}
              </Text>
              {typeof value === 'string' ? (
                <Text>
                  {value}
                </Text>
              ) : (
                <Text>
                  {value}
                </Text>
              )}
            </Flex>
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
};

export default HistoryCardComponent;
