import { gql, QueryResult, useQuery } from '@apollo/client';

interface DailyAchievement {
  calendarDate: string;
  status: number;
}

export interface AchievementInterface {
  dailyAchievements: DailyAchievement[];
  calendarDateStart: string;
  calendarDateEnd: string;
  hasTrophy: boolean;
}

interface GetAchievementsInput {
  calendarDate: string;
  phase: string;
}

interface GetAchievementsOutput {
  getAchievements: AchievementInterface[];
}

const GET_ACHIEVEMENTS = gql`
  query GetAchievements (
    $calendarDate: CalendarDate!
    $phase: String!
  ) {
    getAchievements (
      calendarDate: $calendarDate
      phase: $phase
    ) {
      dailyAchievements {
        calendarDate
        status
      }
      calendarDateStart
      calendarDateEnd
      hasTrophy
    }
  }
`;

type GetWeeklyAchievementsProgressType = {
    progress: number;
    total: number;
    bronze: number;
    silver: number;
    gold: number;
    title: string;
}

interface GetWeeklyAchievementsProgressV2Output {
  getWeeklyAchievementsProgressV2: {
    course: GetWeeklyAchievementsProgressType;
    general: GetWeeklyAchievementsProgressType;
    preCourse: GetWeeklyAchievementsProgressType;
  }
}

const GET_WEEKLY_ACHIEVEMENTS_PROGRESS_V2 = gql`
 query GetWeeklyAchievementsProgressV2 {
    getWeeklyAchievementsProgressV2 {
      course {
        progress
        title
        total
        bronze
        silver
        gold
      }
      general {
        progress
        title
        total
        bronze
        silver
        gold
      }
      preCourse {
        progress
        title
        total
        bronze
        silver
        gold
      }
    }
  }
`;

export function useGetAchievements({
  calendarDate,
  phase,
}: GetAchievementsInput): QueryResult<GetAchievementsOutput, GetAchievementsInput> {
  return useQuery<GetAchievementsOutput, GetAchievementsInput>(GET_ACHIEVEMENTS, {
    variables: {
      calendarDate,
      phase,
    },
    skip: phase === '',
    fetchPolicy: 'cache-and-network',
  });
}

export function useGetWeeklyAchievementsProgressV2(): QueryResult<GetWeeklyAchievementsProgressV2Output> {
  return useQuery<GetWeeklyAchievementsProgressV2Output>(GET_WEEKLY_ACHIEVEMENTS_PROGRESS_V2);
}

interface GetWeekAchievementDocInput {
  calendarDate: string;
}

type GoalsType = {
  bronze: number;
  silver: number;
  gold: number;
}

interface GetWeekAchievementDocOutput {
  getWeeklyAchievement: {
    _id: string;
    profile: string;
    theoreticalStudy: {
      done: number;
      total: number;
    };
    theoreticalReview: {
      done: number;
      total: number;
    };
    exams: {
      done: number;
      total: number;
    };
    phase: string;
    trophy?: 'bronze' | 'silver' | 'gold';
    goals: {
      theoreticalStudy: GoalsType;
      theoreticalReview: GoalsType;
      exam: GoalsType;
      smartReview: GoalsType;
    }
    courseId: string;
    calendarDateStart: string;
    calendarDateEnd: string;
    hasTrophy: boolean;
    extraTheoreticalReview?: number;
    smartReview: {
      questions: number;
      flashcards: number;
      total: number;
    };
  };
}

export const GET_WEEK_ACHIEVEMENT_DOC = gql`
  query GetWeeklyAchievement($calendarDate: CalendarDate!) {
    getWeeklyAchievement(calendarDate: $calendarDate) {
      _id
      profile
      theoreticalStudy {
        done
        total
      }
      theoreticalReview {
        done
        total
      }
      exams {
        done
        total
      }
      phase
      courseId
      calendarDateStart
      calendarDateEnd
      hasTrophy
      goals {
        theoreticalStudy {
          bronze
          silver
          gold
        }
        theoreticalReview {
          bronze
          silver
          gold
        }
        exam {
          bronze
          silver
          gold
        }
        smartReview {
          bronze
          silver
          gold
        }
      }
      trophy
      extraTheoreticalReview
      smartReview {
        questions
        flashcards
        total
      }
    }
  }
`;

export function useGetCurrentWeekAchievementDoc(calendarDate: string) {
  return useQuery<GetWeekAchievementDocOutput, GetWeekAchievementDocInput>(GET_WEEK_ACHIEVEMENT_DOC, {
    variables: {
      calendarDate,
    },
    fetchPolicy: 'network-only',
  });
}
