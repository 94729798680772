import { Box, Flex } from '@chakra-ui/react';
import React, { FC, ReactNode } from 'react';

type DiagnosisCardProps = {
  title: string;
  bgColor?: string;
  children: ReactNode;
}

export const DiagnosisCard: FC<DiagnosisCardProps> = ({
  title, children, bgColor = 'aristo.500',
}: DiagnosisCardProps) => {
  return (
    <Flex
      bgColor={bgColor}
      flexFlow="column"
      color="white"
      width="14rem"
      height={{ base: '19rem', md: '22rem' }}
      minWidth="14rem"
      borderRadius="1rem"
      padding="1rem"
      textAlign="center"
      margin="1rem"
      boxShadow="5px 5px 5px rgba(92, 91, 91, 0.486)"
      transition="transform .2s linear"
      _hover={{ transform: 'translateY(-.8rem)' }}

    >
      <Box
        marginBottom="1rem"
        fontSize="1.5rem"
        fontWeight="bold"
      >
        {title}
      </Box>
      <Box>
        {children}
      </Box>
    </Flex>
  );
};
