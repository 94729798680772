import { Collapse, Flex, Text } from '@chakra-ui/react';
import { FC, useMemo } from 'react';
import { InstitutionDataType } from '../../../api/aristo-compass/exam-statistics';
import { useDefaultStyles } from '../../../hooks/useDefaultStyles';
import { useLocalStorage } from '../../../hooks/useLocalStorage';
import { DifficultyGradientBar } from './DifficultyGradientBar';

type InstitutionsDifficultiesPanelType = {
  isOpen?: boolean;
  institutionData?: InstitutionDataType[];
  loading?: boolean;
}

export const InstitutionsDifficultiesPanel: FC<InstitutionsDifficultiesPanelType> = ({
  institutionData = [],
  isOpen = true,
  loading = false,
}) => {
  const { colors } = useDefaultStyles();
  const [examVariant] = useLocalStorage('@Aristoclass:examVariant') || 'R1';

  // const examVariant = localStorage.getItem('@Aristoclass:examVariant') || 'R1';
  const currVariantDifficulty = useMemo(() => {
    if (examVariant === 'R1') {
      return 0.49;
    }
    if (examVariant === 'REVALIDA') {
      return 0.68;
    }
    return 0;
  }, [examVariant]);

  const processedInstitutionData = useMemo(() => {
    const examVariantFixCase = examVariant.slice(0, 1).toUpperCase() + examVariant.slice(1).toLowerCase();
    const _processedData = institutionData
      .filter(institution => institution.institution !== 'Geral')
      .map(institution => ({
        institution: institution.institution,
        difficulty: institution.difficulty,
        highlight: false,
      }));
    _processedData.unshift({
      institution: `Dificuldade média das instituições de ${examVariantFixCase} no Brasil`,
      difficulty: currVariantDifficulty,
      highlight: true,
    });
    return _processedData;
  }, [currVariantDifficulty, examVariant, institutionData]);

  return (
    <Flex
      w="95%"
      flexFlow="column"
      transition="height 0.4s"
      overflow="hidden"
      bgColor="white"
      borderRadius="0px 0px 12px 12px"
    >
      <Collapse
        in={isOpen}
        animateOpacity
      >
        {
          processedInstitutionData
            .sort((a, b) => (a.difficulty > b.difficulty ? 1 : -1))
            .map((institution, index) => {
              const key = Math.random().toString(36).slice(2);
              return (
                <Flex
                  key={key}
                  bgColor={index % 2 === 1 ? 'gray.50' : undefined}
                  px={4}
                  py={2}
                  pr="6%"
                  w="100%"
                  align="center"
                  justifyContent="space-between"
                >
                  <Text
                    fontSize={12}
                    maxW="55%"
                    color={institution.highlight ? colors.secondary.keep : undefined}
                  >
                    {institution.institution}
                  </Text>
                  {
                    institution.difficulty ? (
                      <DifficultyGradientBar
                        w={{ base: '100px', md: '150px' }}
                        loading={loading}
                        difficulty={institution.difficulty}
                      />
                    ) : '-'
                  }
                </Flex>
              );
            })
        }
      </Collapse>
    </Flex>
  );
};
