export const reportOptions = {
  flashcard: [
    'Pergunta errada',
    'Resposta errada',
    'Problema com imagem',
    'Erro ortográfico',
    'Outro problema',
  ],
  comment: [
    'CCQ inespecífico',
    'Comentário desatualizado',
    'Erro de conteúdo',
    'Erro ortográfico',
    'Formatação inadequada',
    'Não respondeu à pergunta central',
    'Não respondeu minha dúvida',
    'Não comentou todas as alternativas',
    'Problema com imagem',
    'Outro problema',
  ],
};

export const defaultOptions = [
  'Erro no enunciado/alternativa',
  'Gabarito errado',
  'Classificação errada',
  'Questão anulada',
  'Questão desatualizada',
  'Outro problema',
];
