import { Circle, Flex, HStack, Image, Text } from '@chakra-ui/react';
import { FC } from 'react';
import { useDefaultStyles, useRGBColor } from '../../../hooks/useDefaultStyles';

type TrophyTableRowType = {
  type: 'bronze' | 'silver' | 'gold',
  borderBottom?: boolean,
  numberOfActivities?: number,
};

export const TrophyTableRow: FC<TrophyTableRowType> = ({
  type, borderBottom = true, numberOfActivities = 0,
}) => {
  const { colors } = useDefaultStyles();
  const { toRGB } = useRGBColor();
  return (
    <Flex
      w="100%"
      p={3}
      px={47}
      justify="space-between"
      borderBottom={borderBottom ? `2px solid ${toRGB(colors.lightGray.goAlmostFullDarker)}` : undefined}
    >
      <HStack>
        <Text>{`${numberOfActivities}x`}</Text>
        <Circle size={4} bgColor={colors.prizes[type]} />
      </HStack>
      <Image w={5} h="100%" src={`/images/trophies/${type}.svg`} />
    </Flex>
  );
};
