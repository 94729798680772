import { useEffect, useState } from 'react';

export function useKeyPressed(targetKeys?: string[] | string) {
  const [keyPressed, setKeyPressed] = useState(false);

  function downHandler({ code }: KeyboardEvent) {
    if ((targetKeys && targetKeys.includes(code)) || targetKeys === undefined || targetKeys === code) {
      setKeyPressed(true);
    }
  }

  function upHandler({ code }: KeyboardEvent) {
    if ((targetKeys && targetKeys.includes(code)) || targetKeys === undefined || targetKeys === code) {
      setKeyPressed(false);
    }
  }

  useEffect(() => {
    document.body.addEventListener('keydown', downHandler);
    window.addEventListener('keyup', upHandler);
    return () => {
      window.removeEventListener('keydown', downHandler);
      window.removeEventListener('keyup', upHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return keyPressed;
}

type KeyEventType = {
  code: string | null,
  key: string | null,
  pressed: boolean | null,
}

export function useGetKeyEvents() {
  const [keyEvent, setKeyEvent] = useState<KeyEventType>({ code: null, key: null, pressed: null });

  function downHandler({ code, key }: KeyboardEvent) {
    setKeyEvent({ code, key, pressed: true });
  }

  function upHandler({ code, key }: KeyboardEvent) {
    setKeyEvent({ code, key, pressed: false });
  }

  useEffect(() => {
    document.body.addEventListener('keydown', downHandler);
    window.addEventListener('keyup', upHandler);
    return () => {
      window.removeEventListener('keydown', downHandler);
      window.removeEventListener('keyup', upHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return keyEvent;
}
