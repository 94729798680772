import { gql, useMutation } from '@apollo/client';
import { useCallback } from 'react';

interface ReportInput {
  title: string;
  contentId: string;
  contentType: string;
  body: string;
}

interface ReportCreateOneInput {
  input: ReportInput;
}

interface ReportCreateOneOutput {
  report: {
    _id: string;
  }
}

const REPORT_CREATE_ONE = gql`
  mutation ReportCreateOne($input: ReportInputType!) {
    report: reportCreateOne (input: $input) {
      _id
    }
  }
`;

interface UseReportInterface {
  onReportCreateOne: (props: ReportInput) => Promise<void>;
}

export function useReport(): UseReportInterface {
  const [report] = useMutation<ReportCreateOneOutput, ReportCreateOneInput>(REPORT_CREATE_ONE);

  const handleReport = useCallback(async ({
    contentType, contentId, body, title,
  }: ReportInput) => {
    await report({
      variables: {
        input: {
          body,
          contentId,
          contentType,
          title,
        },
      },
    });
  }, [report]);

  return {
    onReportCreateOne: handleReport,
  };
}
