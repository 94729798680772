import React, { FC } from 'react';
import Text from '../../lib/components/Text';

const NotFoundPage: FC = () => {
  return (
    <Text tag="h1">Página não encontrada!</Text>
  );
};

export default NotFoundPage;
