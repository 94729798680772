function fixJavascriptRoundings(n: number) {
  return Math.round(n * 100) / 100;
}

export function linearGradient(
  weight = 0,
  colors = [[72, 187, 120], [236, 201, 75], [245, 101, 101]],
) {
  const weightInput = fixJavascriptRoundings(weight);
  const noOfSections = colors.length - 1;
  const sectionWidth = 1 / noOfSections;
  const w1 = (weightInput % sectionWidth) / sectionWidth;
  const w2 = 1 - w1;
  const isLastColor = weightInput / sectionWidth === noOfSections;
  const index1 = isLastColor ? noOfSections - 1 : Math.floor(weightInput / sectionWidth);
  const index2 = index1 + 1;
  const rgb = [
    Math.round(colors[isLastColor ? index2 : index1][0] * w2 + colors[index2][0] * w1),
    Math.round(colors[isLastColor ? index2 : index1][1] * w2 + colors[index2][1] * w1),
    Math.round(colors[isLastColor ? index2 : index1][2] * w2 + colors[index2][2] * w1),
  ];
  return `rgb(${rgb[0]}, ${rgb[1]}, ${rgb[2]})`;
}
