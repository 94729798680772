import { FC } from 'react';
import { Redirect } from 'react-router-dom';
import { MenuTabsItems } from '../../../../lib/components/MenuTabs';
import { EmptyPage } from '../../_components/EmptyPage';

interface ExtraTheoreticalMainProps {
  menuItems: MenuTabsItems;
  context: 'estudo-teorico' | 'revisao-teorica';
}

const ExtraTheoreticalStudyMain: FC<ExtraTheoreticalMainProps> = ({ menuItems, context }) => {
  if (menuItems.questao && context === 'revisao-teorica') {
    return <Redirect to={`/atividades-extras/${context}/questao${window.location.search}`} />;
  }

  if (menuItems.video) {
    return <Redirect to={`/atividades-extras/${context}/video${window.location.search}`} />;
  }

  if (menuItems.apostila) {
    return <Redirect to={`/atividades-extras/${context}/apostila${window.location.search}`} />;
  }

  if (menuItems['mapa-mental']) {
    return <Redirect to={`/atividades-extras/${context}/mapa-mental${window.location.search}`} />;
  }

  if (menuItems.flashcard) {
    return <Redirect to={`/atividades-extras/${context}/flashcard${window.location.search}`} />;
  }

  return (
    <EmptyPage />
  );
};

export default ExtraTheoreticalStudyMain;
