import { useCallback } from 'react';
import {
  gql, QueryResult, useMutation, useQuery,
} from '@apollo/client';

export interface Course {
  _id: string;
  title: string;
}

export interface CourseManyInfo {
  _id: string;
  title: string;
  className: string;
  phases: {
    phase: string;
    startsAt: string;
  }[];
  courseWeekGoals: {
    min: {
      theoreticalStudy: number;
      theoreticalReview: number;
      smartReview: number;
      exam: number;
    };
    max: {
      theoreticalStudy: number;
      theoreticalReview: number;
      smartReview: number;
      exam: number;
    };
  };
  colorPalette: string;
  logo: string;
  homePage: string;
}

interface CoursesManyOutput {
  coursesMany: Course[];
}

interface CoursesManyInfoOutput {
  coursesManyInfo: CourseManyInfo[];
}

const COURSES_MANY = gql`
  query CoursesMany {
    coursesMany {
      _id
      title
    }
  }
`;

const COURSES_MANY_INFO = gql`
  query CoursesManyInfo($ids: [ObjectId!]!) {
    coursesManyInfo(ids: $ids) {
      _id
      title
      className
      phases {
        phase
        startsAt
      }
      courseWeekGoals {
        min {
          theoreticalStudy
          theoreticalReview
          smartReview
          exam
        }
        max {
          theoreticalStudy
          theoreticalReview
          smartReview
          exam
        }
      }
    }
  }
`;

const GET_LAYOUT_INFO = gql`
  query GetLayoutInfo($ids: [ObjectId!]!) {
    coursesManyInfo(ids: $ids) {
      colorPalette
      logo
      homePage
    }
  }
`;

type GetLayoutInfoOutput = {
  coursesManyInfo: {
    colorPalette: string;
    logo: string;
    homePage: string;
  }[]
}

export function useGetLayoutInfo(ids: string[]): QueryResult<GetLayoutInfoOutput> {
  return useQuery<GetLayoutInfoOutput>(GET_LAYOUT_INFO, {
    variables: {
      ids,
    },
    skip: ids[0] === '',
  });
}

export function useCoursesMany(): QueryResult<CoursesManyOutput> {
  return useQuery<CoursesManyOutput>(COURSES_MANY);
}

export function useCoursesManyInfo(ids: string[]): QueryResult<CoursesManyInfoOutput> {
  return useQuery<CoursesManyInfoOutput>(COURSES_MANY_INFO, {
    variables: {
      ids,
    },
  });
}

interface CreateCourseOnTrialInput {
  id: string;
}

interface CreateCourseOnTrialOutput {
  createCourseOnTrial: boolean;
}

const CREATE_COURSE_ON_TRIAL = gql`
  mutation CreateCourseOnTrial ($id: ObjectId!) {
    createCourseOnTrial (id: $id)
  }
`;

interface UseCoursesInterface {
  onCreateCourseOnTrial: (props: CreateCourseOnTrialInput) => Promise<void>;
}

export function useCourses(): UseCoursesInterface {
  const [createCourseOnTrial] = useMutation<
    CreateCourseOnTrialOutput, CreateCourseOnTrialInput
  >(CREATE_COURSE_ON_TRIAL);

  const handleCreateCourseOnTrial = useCallback(async ({
    id,
  }: CreateCourseOnTrialInput) => {
    await createCourseOnTrial({
      variables: {
        id,
      },
    });
  }, [createCourseOnTrial]);

  return {
    onCreateCourseOnTrial: handleCreateCourseOnTrial,
  };
}
