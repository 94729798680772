import { FC } from 'react';
import { Forum2 } from '../../_components/Forum/Forum2';
import { ContentType } from '../../_components/Forum/types/forum2.types';

type ForumWrapperInputType = {
  forumId: string,
  forumTag: string,
  contentType: ContentType,
  classification: string[],
}

export const ForumWrapper: FC<ForumWrapperInputType> = (
  { children, forumId, forumTag, contentType, classification },
) => {
  return (
    <>
      {children}
      <Forum2
        id={forumId}
        tag={forumTag}
        classification={classification}
        contentType={contentType}
      />
    </>
  );
};
