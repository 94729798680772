import { ButtonProps } from '@chakra-ui/react';
import { faEdit, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  FC, useCallback, useContext, useState,
} from 'react';
import ConfirmModal from '../../../lib/components/ConfirmModal';
import { AgendaContext } from '../../agenda/Agenda.context';
import { PrimaryButton } from '../../../lib/components/PrimaryButton';
import { useExtraQuestions } from '../../../api/extra-activities/extra-questions';
import { toast } from '../../../utils/toast';
import { WholeAppContext } from '../../agenda/WholeApp.context';

interface AddExtraQuestionsProps extends ButtonProps {
  classification: string[];
  confirmModalTitle?: string;
  confirmModalSubtitle?: string;
  label?: string;
}

const AddExtraQuestions: FC<AddExtraQuestionsProps> = ({
  classification,
  confirmModalSubtitle = `5 questões comentadas dos últimos 6 anos do
  tema "${classification[classification.length - 1]}" serão adicionadas
  como atividade extra na sua agenda de hoje.`,
  confirmModalTitle = 'Adicionar como atividade extra?',
  label = 'Mais questões',
  ...props
}) => {
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const { hideElementsThatTriggerModals } = useContext(WholeAppContext);
  const { refetchExtras } = useContext(AgendaContext);
  const CURRENT_YEAR = new Date().getFullYear();

  const { onCreateQuestionActivity } = useExtraQuestions();

  const handleCreate = useCallback(async () => {
    setLoading(true);
    try {
      await onCreateQuestionActivity({
        classification: classification.length ? [classification.join('|')] : undefined,
        uf: undefined,
        institution: undefined,
        count: 5,
        difficulty: undefined,
        onlyWithComments: true,
        minYear: CURRENT_YEAR - 5,
        maxYear: CURRENT_YEAR,
      });
      await refetchExtras();
      setModal(false);
    } catch (err) {
      const msg = err.graphQLErrors && err.graphQLErrors[0].message as string;
      if (msg === 'No question found.') {
        toast.error({
          title: 'Nenhuma questão recomendada encontrada...',
          description: 'Caso queira fazer questões não recomendadas, adicione-as como extra.',
        });
      } else {
        console.log(msg);
      }
    } finally {
      setLoading(false);
    }
  }, [CURRENT_YEAR, classification, onCreateQuestionActivity, refetchExtras]);

  if (hideElementsThatTriggerModals) {
    return null;
  }

  return (
    <>
      {!loading ? (
        <>
          {
            classification && classification.length > 0 && (
              <PrimaryButton
                leftIcon={<FontAwesomeIcon icon={faEdit} />}
                onClick={() => setModal(true)}
                disabled={!classification || classification.length === 0}
                {...props}
              >
                {label}
              </PrimaryButton>
            )
          }
        </>
      ) : (
        <FontAwesomeIcon icon={faSpinner} spin />
      )}
      <ConfirmModal
        isOpen={modal}
        onConfirm={handleCreate}
        onDecline={() => setModal(false)}
        title={confirmModalTitle}
        subtitle={confirmModalSubtitle}
        confirmText="Adicionar"
        declineText="Cancelar"
        loading={loading}
      />
    </>
  );
};

export default AddExtraQuestions;
