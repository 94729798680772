import { Box, Flex, HStack, Text, useBreakpointValue } from '@chakra-ui/react';
import { faArrowLeft, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC, useEffect, useMemo } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { useVideosJJPlusMany } from '../../api/aristoplus';
import Video from '../../lib/components/Video';
import { Forum } from '../_components/Forum/Forum';
import { AristoPlusPlaylistNavigator } from './AristoPlusPlaylistNavigator';
import { AristoPlusTitleBar } from './AristoPlusTitleBar';

export const VideosPlaylist: FC = () => {
  const params = useParams<{ playlist: string, category: string }>();
  const { search } = useLocation();
  const history = useHistory();
  const showPlaylistNavBarWeb = useBreakpointValue({ sm: false, lg: true });
  const videosJJPlusQuery = useVideosJJPlusMany({ category: params.category, playlist: params.playlist, limit: 1000 });
  const playlists = useMemo(() => {
    if (!videosJJPlusQuery.data || videosJJPlusQuery.loading) {
      return undefined;
    }

    return videosJJPlusQuery.data.playlists;
  }, [videosJJPlusQuery.data, videosJJPlusQuery.loading]);

  const query = useMemo(() => new URLSearchParams(search), [search]);

  const currentVideo = useMemo(() => {
    return playlists?.[0].videos.find(v => v._id === query.get('id'));
  }, [playlists, query]);

  useEffect(() => {
    if (playlists && !query.get('id')) {
      query.set('id', playlists?.[0].videos[0]._id);
      history.replace({ search: String(query) });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playlists]);

  const selectedVideoIndex = useMemo(() => {
    return (playlists?.[0].videos.findIndex(v => v._id === query.get('id')) ?? -1) + 1;
  }, [playlists, query]);

  if (videosJJPlusQuery.loading) {
    return (
      <Flex justify="center">
        <FontAwesomeIcon icon={faSpinner} size="3x" spin />
      </Flex>
    );
  }

  return (
    <Box m="0 auto" w="100%" maxW={1400}>
      <HStack
        as="button"
        onClick={() => history.push('/mais-aristo/videos')}
        align="center"
        mb={4}
      >
        <Text>
          <FontAwesomeIcon icon={faArrowLeft} />
        </Text>
        <Text ml={1}>Voltar</Text>
      </HStack>
      <Flex w="100%">
        <Flex w="100%" flexFlow="column">
          {currentVideo && (
            <Video
              w="100%"
              id={currentVideo._id}
              url={currentVideo.url}
            />
          )}
          <AristoPlusTitleBar
            show={!showPlaylistNavBarWeb}
            playlistName={playlists?.[0].playlistName || ''}
            selectedVideoIndex={selectedVideoIndex}
            videoListLen={playlists?.[0].videos.length}
          >
            <AristoPlusPlaylistNavigator
              videos={playlists?.[0].videos || []}
            />
          </AristoPlusTitleBar>
          {
            playlists && (
              <Forum
                id={query.get('id') || ''}
                tag={[
                  'Video',
                  currentVideo?.title,
                  currentVideo?.subtitle].join('|')}
              />
            )
          }
        </Flex>
        <AristoPlusTitleBar
          show={showPlaylistNavBarWeb}
          playlistName={playlists?.[0].playlistName || ''}
          selectedVideoIndex={selectedVideoIndex}
          videoListLen={playlists?.[0].videos.length}
        >
          <AristoPlusPlaylistNavigator
            videos={playlists?.[0].videos || []}
          />
        </AristoPlusTitleBar>
      </Flex>
      <Box>
        {/* <Divider /> */}
      </Box>
    </Box>
  );
};
