import { VStack, Text } from '@chakra-ui/react';
import { useDefaultStyles } from '../../../hooks/useDefaultStyles';

export const AgendaMockExamEmptyMessage = () => {
  const { cardBorder } = useDefaultStyles();

  return (
    <VStack
      spacing="16px"
      {...cardBorder}
      padding="16px"
      alignItems="flex-start"
      maxWidth="600px"
      margin="0px auto"
    >
      <Text fontSize="32px">Não há simulado disponível para esse dia 🙁</Text>
    </VStack>
  );
};
