import { Flex, Text } from '@chakra-ui/react';
import { faLaptop } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { useDefaultStyles } from '../../../hooks/useDefaultStyles';
import { DiagnosticAreaTable } from '../../diagnosis/_components/DiagnosticAreaTable';
import { DiagnosticThemesTable } from '../../diagnosis/_components/DiagnosticThemesTable';
import { FlashcardIcon } from '../../flashcards-creation/icons/FlashcardIcon';
import { AristoCompassTemplate } from '../template';
import { CompassActivityCard } from '../_components/CompassActivityCard';
import { CompassTableWrapper } from '../_components/CompassTableWrapper';
import { testThePlatformFormLink } from '../_components/Header';
import { SideCardsContainer } from '../_components/SideCardsContainer';
import diagnosticResult from '../_mocks/DiagnosticMock.json';

export const CompassQuestionResultsPage: FC = () => {
  const { colors } = useDefaultStyles();
  const { push } = useHistory();
  const themesBoardWidth = 60;
  return (
    <AristoCompassTemplate
      p={10}
      px={{ base: 2, md: 10 }}
    >
      <Text
        fontWeight="bold"
        fontSize="2rem"
        marginBottom="1.5rem"
        color={colors.primary.keep}
      >
        Seu diagnóstico parcial
      </Text>
      <Flex
        w="100%"
        style={{ gap: 40 }}
        flexFlow={{ base: 'column', md: 'row' }}
      >
        <Flex
          w={{ base: '100%', lg: `${themesBoardWidth}%` }}
          flexFlow="column"
          style={{ gap: 40 }}
          justify="flex-start"
          align="space-around"
        >
          <CompassTableWrapper title="Desempenho por grande área">
            <DiagnosticAreaTable
              areas={diagnosticResult.fetchDiagnosticExamResults.areas}
              tableStyle="compass"
              showThemesToReview={false}
            />
          </CompassTableWrapper>
          <CompassTableWrapper title="Desempenho por tema">
            <DiagnosticThemesTable
              themes={diagnosticResult.fetchDiagnosticExamResults.themes}
              tableStyle="compass"
            />
          </CompassTableWrapper>
        </Flex>
        <SideCardsContainer
          title="Quer mais?"
          description={[
            'Você poderá fazer flashcards para treinar suas prioridades e fazer um teste grátis da nossa plataforma!',
            'Qual deles você gostaria de fazer primeiro?',
          ]}
          width={{ base: '100%', lg: `${98 - themesBoardWidth}%` }}
        >
          <CompassActivityCard
            title="Treine com flashcards"
            description="Faça flashcards das suas principais prioridades."
            estimatedTime="15 - 20 min"
            onClick={() => push('/aristo-compass/diagnostico-parcial/flashcards')}
            icon={<FlashcardIcon p={8} color={colors.secondary.keep} size={30} />}
          // disabled={loadingCreatePartialDiagnosis}
          // isLoading={loadingCreatePartialDiagnosis}
          />
          <CompassActivityCard
            title="Teste a plataforma"
            description="Faça um teste grátis da nossa plataforma!"
            onClick={() => window.open(testThePlatformFormLink)}
            icon={<FontAwesomeIcon icon={faLaptop} />}
            btnLabel="Testar"
          />
        </SideCardsContainer>
      </Flex>
    </AristoCompassTemplate>
  );
};
