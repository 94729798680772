import {
  FC,
} from 'react';
import { Box, Flex, HStack, Image } from '@chakra-ui/react';
import SizedBox from '../../../lib/components/SizedBox';
import useFirebaseStorage from '../../../hooks/useFirebaseStorage';
import { Mindmap } from '../../../api/agenda/mindmaps';
import { PdfItem } from '../../../lib/components/PdfItem';
import Stars from '../../_components/Stars';
import FavoriteBtn from '../_components/FavoriteBtn.component';
import { MoreOptions } from '../../_components/MoreOptions';

interface AgendaMindmapsProps {
  data: Mindmap[];
}

const AgendaMindMaps: FC<AgendaMindmapsProps> = ({
  data,
}) => {
  const storage = useFirebaseStorage();

  return (
    <Box mt={8}>
      {data && data.map(item => {
        const storageUrl = storage.getURL(item.url);
        return (
          <Box key={item._id} maxW={700} m="1rem auto">
            <PdfItem
              imageSrc="/images/aristo-icons/mindmap.png"
              href={storageUrl}
              title={item.title}
              subtitle={item.subtitle}
            />
            <Flex mt={2} px={2} align="center" justify="space-between">
              <Stars
                document={item._id}
                type="mindmap"
              />
              <HStack>
                <FavoriteBtn
                  document={item._id}
                  type="mindmap"
                />
                <MoreOptions
                  contentId={item._id}
                  type="mindmap"
                />
              </HStack>
            </Flex>
          </Box>
        );
      })}
    </Box>
  );
};

interface PreviewMindmapProps {
  index: number;
  onClick: (i: number) => () => void;
  active?: boolean;
  src: string;
}

export const PreviewMindmap: FC<PreviewMindmapProps> = ({
  index, onClick, active, src,
}) => {
  return (
    <button type="button" onClick={onClick(index)}>
      <SizedBox
        width={100}
        height={80}
        opacity={active ? 1 : 0.5}
        className="mx-1"
        style={{
          transform: active ? 'scale(1.2)' : '',
        }}
      >
        <Image src={src} alt="img" />
      </SizedBox>
    </button>
  );
};

export default AgendaMindMaps;
