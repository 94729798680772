import { Text } from '@chakra-ui/react';
import { FC } from 'react';
import { useDefaultStyles } from '../../../hooks/useDefaultStyles';

type FooterListItemType = {
  label: string;
  link: string;
}

export const FooterListItem: FC<FooterListItemType> = ({
  label,
  link,
}) => {
  const { colors } = useDefaultStyles();
  return (
    <Text
      transition="color 0.4s"
      _hover={{ color: colors.white.keep }}
      as="button"
      color={colors.darkGray.keep}
      onClick={() => window.open(link, '_blank')}
    >
      {label}
    </Text>
  );
};
