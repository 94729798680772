import {
  ApolloClient, ApolloLink, HttpLink, InMemoryCache,
} from '@apollo/client';

import { setContext } from '@apollo/client/link/context';
import * as moment from 'moment-timezone';
import { FlashcardType } from '../api/flashcards';
import { auth } from './firebase';

const uri = String(process.env.REACT_APP_API_URL);

const httpLink = new HttpLink({
  uri,
});

const authLink = setContext(async (_, { headers }) => {
  const { currentUser } = auth;

  if (!currentUser) {
    return {
      headers,
    };
  }

  let token = '';
  try {
    token = await currentUser.getIdToken();
  } catch (error) {
    console.error(error);
  }

  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : '',
      'X-Timezone': moment.tz.guess(),
    },
  };
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ApolloArgsType = Record<string, any>;

const client = new ApolloClient({
  link: ApolloLink.from([authLink, httpLink]),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          getUserFlashcards: {
            keyArgs: ['lessonId'],
            merge(existing, incoming, { args: { skip = 0 } }: ApolloArgsType) {
              const merged: FlashcardType[] = existing ? existing.slice(0) : [];
              for (let i = 0; i < incoming.length; i += 1) {
                merged[skip + i] = incoming[i];
              }
              return merged;
            },
          },
          getCommunityFlashcards: {
            keyArgs: ['lessonId'],
            merge(existing, incoming, { args: { skip = 0 } }: ApolloArgsType) {
              const newFlashcards = existing ? [...existing.flashcards] : [];
              for (let i = 0; i < incoming.flashcards.length; i += 1) {
                newFlashcards[skip + i] = incoming.flashcards[i];
              }
              return { flashcards: newFlashcards, totalAmount: incoming.totalAmount || 0 };
            },
          },
        },
      },
    },
  }),
});

export default client;
