import {
  gql, QueryResult, useMutation, useQuery,
} from '@apollo/client';
import { useCallback } from 'react';
import { Question } from './questions';

export interface MockExamTemplate {
  template: string;
  title: string;
}

interface GetMockExamTemplatesOutput {
  getMockExamTemplates: MockExamTemplate[];
}

const GET_MOCK_EXAM_TEMPLATES = gql`
  query GetMockExamTemplates {
    getMockExamTemplates {
      template
      title
    }
  }
`;

export function useGetMockExamTemplates(): QueryResult<GetMockExamTemplatesOutput> {
  return useQuery<GetMockExamTemplatesOutput>(GET_MOCK_EXAM_TEMPLATES);
}

export interface RankMockExamInterface {
  position: number;
  total: number;
  grade: number;
}

export interface PreChoiceInterface {
  questionId: string;
  selected: number;
  essay?: string;
}

export interface MockExamInterface {
  _id: string;
  profile: string;
  finished: boolean;
  preChoice?: PreChoiceInterface[];
  createdAt: string;
  updatedAt: string;
  calendarDate: string;
  mockExamTemplate: string;
  questions: Question[];
  title: string;
  subtitle: string;
  startsAt: string;
  finishesAt: string;
  timeToDo: number;
  answersAt: string;
  startedAt?: string;
  rankingAt: string;
  ranking?: RankMockExamInterface;
  limitDateToStart?: string;
  printingIsBlocked?: boolean
}

interface GetOrCreateMockExamActivityInput {
  mockExamTemplate: string;
}

export interface GetOrCreateMockExamActivityOutput {
  mockExam: MockExamInterface;
}

const GET_OR_CREATE_MOCK_EXAM_ACTIVITY = gql`
  query GetOrCreateMockExamActivity (
    $mockExamTemplate: ObjectId!
  ) {
    mockExam: getOrCreateMockExamActivity (
      mockExamTemplate: $mockExamTemplate
    ) {
      _id
      profile
      finished
      preChoice {
        questionId
        selected
        essay
      }
      createdAt
      updatedAt
      calendarDate
      mockExamTemplate
      questions {
        _id
        body
        choices
        answersProportion
        correctChoice
        institution
        uf
        year
        examOrder
        examType
        complement
        classification
        difficulty
        createdAt
        updatedAt
        comment {
          author
          profile
          title
          body
          createdAt
          updatedAt
        }
        answer
        essay
        isEssay
      }
      title
      subtitle
      startsAt
      finishesAt
      timeToDo
      answersAt
      startedAt
      rankingAt
      ranking {
        position
        total
        grade
      }
      limitDateToStart
      printingIsBlocked
    }
  }
`;

interface StartExamInput {
  activityId: string;
}

interface StartExamOutput {
  startExam: string;
}

const START_EXAM = gql`
  mutation StartExam (
    $activityId: ObjectId!
  ) {
    startExam (
      activityId: $activityId
    )
  }
`;

interface RankMockExamInput {
  mockExamTemplate: string;
  activity: string;
}

interface RankMockExamOutput {
  rankMockExam: RankMockExamInterface;
}

const RANK_MOCK_EXAM = gql`
  mutation RankMockExam (
    $mockExamTemplate: ObjectId!
    $activity: ObjectId!
  ) {
    rankMockExam (
      mockExamTemplate: $mockExamTemplate
      activity: $activity
    ) {
      position
      total
      grade
    }
  }
`;

interface UseMockExamInterface {
  onRankMockExam: (props: RankMockExamInput) => Promise<void>;
  onStartExam: (props: StartExamInput) => Promise<void>;
}

export function useMockExam(): UseMockExamInterface {
  const [rankMockExam] = useMutation<RankMockExamOutput, RankMockExamInput>(RANK_MOCK_EXAM);
  const [startExam] = useMutation<StartExamOutput, StartExamInput>(START_EXAM);

  const handleRankMockExam = useCallback(async ({
    mockExamTemplate,
    activity,
  }: RankMockExamInput) => {
    await rankMockExam({
      variables: {
        mockExamTemplate,
        activity,
      },
    });
  }, [rankMockExam]);

  const handleStartExam = useCallback(async ({
    activityId,
  }: StartExamInput) => {
    await startExam({
      variables: {
        activityId,
      },
    });
  }, [startExam]);

  return {
    onRankMockExam: handleRankMockExam,
    onStartExam: handleStartExam,
  };
}

export function useGetOrCreateMockExam({
  mockExamTemplate,
}: GetOrCreateMockExamActivityInput): QueryResult<GetOrCreateMockExamActivityOutput, GetOrCreateMockExamActivityInput> {
  return useQuery<
    GetOrCreateMockExamActivityOutput, GetOrCreateMockExamActivityInput
  >(GET_OR_CREATE_MOCK_EXAM_ACTIVITY, {
    variables: {
      mockExamTemplate,
    },
    fetchPolicy: 'network-only',
    skip: mockExamTemplate === '',
  });
}
