/* eslint-disable no-unused-vars */
import React, {
  createContext, FC, useCallback, useState,
} from 'react';

interface IExtraSmartReviewContext {
  questionsAndFlashcardsFinished: {
    _id: string;
    questions: boolean;
    flashcards: boolean;
  }[];
  finishFlashcards: (_id: string) => number | void;
  finishQuestions: (_id: string) => number | void;
}

// TODO: possível melhoria - trocar questions and flashcards finished para um hashmap

export const ExtraSmartReviewContext = createContext<IExtraSmartReviewContext>({
  questionsAndFlashcardsFinished: [],
  finishFlashcards: (_id: string) => undefined,
  finishQuestions: (_id: string) => undefined,
});

export const ExtraSmartReviewStorage: FC = ({ children }) => {
  const [
    questionsAndFlashcardsFinished,
    setQuestionsAndFlashcardsFinished,
  ] = useState<{ _id: string, questions: boolean, flashcards: boolean }[]>([]);

  const handleSetFlashcards = useCallback((_id: string) => {
    const objectIndex = questionsAndFlashcardsFinished.findIndex(obj => obj._id === _id);
    setQuestionsAndFlashcardsFinished(prev => {
      if (objectIndex === -1) {
        return [...prev, {
          _id,
          questions: false,
          flashcards: true,
        }];
      }
      const updatedArray = prev;
      updatedArray[objectIndex] = {
        ...questionsAndFlashcardsFinished[objectIndex],
        flashcards: true,
      };
      return updatedArray;
    });
    return objectIndex;
  }, [questionsAndFlashcardsFinished]);

  const handleSetQuestions = useCallback((_id: string) => {
    const objectIndex = questionsAndFlashcardsFinished.findIndex(obj => obj._id === _id);
    setQuestionsAndFlashcardsFinished(prev => {
      if (objectIndex === -1) {
        const updatedArray = [...prev, {
          _id,
          questions: true,
          flashcards: false,
        }];
        return updatedArray;
      }
      const updatedArray = prev;
      updatedArray[objectIndex] = {
        ...questionsAndFlashcardsFinished[objectIndex],
        questions: true,
      };
      return updatedArray;
    });
    return objectIndex;
  }, [questionsAndFlashcardsFinished]);
  return (
    <ExtraSmartReviewContext.Provider
      value={{
        questionsAndFlashcardsFinished,
        finishFlashcards: handleSetFlashcards,
        finishQuestions: handleSetQuestions,
      }}
    >
      {children}
    </ExtraSmartReviewContext.Provider>
  );
};
