import { Box, Button, HStack, Select, Text } from '@chakra-ui/react';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Dispatch, FC, useState } from 'react';
import { useDefaultStyles } from '../../../hooks/useDefaultStyles';

interface TableFilterComponentProps {
  setFilter: Dispatch<React.SetStateAction<{
    name: string;
    dir: string;
  }>>,
  options: { value: string, content: string }[],
}

const TableFilterComponent: FC<TableFilterComponentProps> = ({
  setFilter, options,
}) => {
  const [direction, setDirection] = useState<'asc' | 'desc'>('asc');
  const { colors } = useDefaultStyles();
  return (
    <HStack
      flex={1}
      justify="center"
      align="center"
      marginY=".5rem"
      mb="1.5rem"
    >
      <HStack w={180}>
        <Text
          color={colors.secondary.goALittleDarker}
          fontWeight="bold"
        >
          Ordenar
        </Text>
        <Button
          marginLeft=".5rem"
          onClick={() => {
            setFilter(prev => {
              return ({
                name: prev.name,
                dir: direction === 'desc' ? 'asc' : 'desc',
              });
            });
            setDirection(prev => (
              prev === 'asc' ? 'desc' : 'asc'
            ));
          }}
        >
          {direction === 'desc' ? (
            <Box color={colors.red.keep}>
              <FontAwesomeIcon icon={faCaretUp} />
            </Box>
          ) : (
            <Box color={colors.green.keep}>
              <FontAwesomeIcon icon={faCaretDown} />
            </Box>
          )}
        </Button>

      </HStack>

      <Select
        placeholder="Selecione"
        style={{ backgroundColor: 'transparent' }}
        borderRadius={{ base: 100, md: 8 }}
        borderColor={colors.secondary.goALittleDarker}
        onChange={e => setFilter({ name: e.target.value, dir: direction })}
      >
        {options.map(opt => (
          <option
            key={opt.value}
            value={opt.value}
          >
            {opt.content}
          </option>
        ))}
      </Select>
    </HStack>

  );
};

export default TableFilterComponent;
