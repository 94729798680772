import { Box, SkeletonCircle, SkeletonText } from '@chakra-ui/react';
import { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDiagnosticExam } from '../../../api/diagnosis';
import { useLocalStorage } from '../../../hooks/useLocalStorage';
import { Questions } from '../../agenda/types/Questions.component';
import { AristoCompassTemplate } from '../template';

export const CompassPartialMockPage: FC = () => {
  const [activityId, setActivityId] = useState('');
  const history = useHistory();
  const diagnosticExamCallback = useDiagnosticExam({ _id: activityId });
  const { findDiagnosticExamQuery: query } = diagnosticExamCallback;
  const [isCompass] = useLocalStorage('@Aristoclass:isCompass');

  useEffect(() => {
    const localActivityId = localStorage.getItem('@plataformaJJMentoria:partialDiagnosticId');
    if (localActivityId) {
      setActivityId(localActivityId);
    }
    history.push('/aristo-compass/diagnostico-parcial/questoes');
    // history.push(`/diagnostico${IS_COMPASS ? '?compass=true' : ''}`);
  }, [history, isCompass]);

  if (query.data) {
    return (
      <AristoCompassTemplate active={isCompass}>
        <Questions
          model="diagnostic"
          data={query.data.findDiagnosticExam.questions.slice(0, 2)}
          activityId={query.data.findDiagnosticExam._id}
          serverPreChoices={query.data.findDiagnosticExam.preChoice}
          finished={query.data.findDiagnosticExam.finished}
          diagnosticExamCallback={diagnosticExamCallback}
        />
      </AristoCompassTemplate>
    );
  }
  return (
    <AristoCompassTemplate active={isCompass}>
      <Box px="20%" py="2rem" w="100%">
        <SkeletonCircle size="10" />
        <SkeletonText mt="4" noOfLines={4} spacing="4" />
      </Box>
    </AristoCompassTemplate>
  );
};
