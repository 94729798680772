import { useHistory } from 'react-router-dom';
import { Center, Flex, Image } from '@chakra-ui/react';
import { FC, useCallback, useContext, useEffect } from 'react';
import { FlashcardType } from '../../../../api/flashcards';
import { EmptyPage } from '../../../_components/Forum/components/EmptyPage';
import { MiniFlashcard } from '../Miniatures/MiniFlashcard';
import { PrimaryButton } from '../../../../lib/components/PrimaryButton';
import { useDevice } from '../../../../hooks/useDevice';
import { FlashcardsCreationContext } from '../../contexts/FlashcardsCreation.context';
import { DELAY_INDEX } from '../Miniatures/MiniDeck';
import { LoadingCards } from '../Loadings/LoadingCards';
import { GoBack } from '../Navigators/GoBack';
import { AddCardButton } from '../Buttons/AddCardButton';
import { ContentSearchBar } from '../SearchBars/ContentSearchBar';
import { CardsNavigator } from '../Navigators/CardsNavigator';

export const ListDeckCards: FC = () => {
  const device = useDevice();
  const history = useHistory();
  const {
    onCreateFlashcardOpen,
    masterLoading,
    flashcards,
    setCurrData,
    onFlashcardViewerOpen,
    lessonName,
    currLessonId,
    currPage,
    CARDS_LIMIT,
    tab,
    cardsSearchInput,
    currPageLen,
    handleFCNavigatorClick,
    setCurrFlashcardIndex,
    currFlashcardIndex,
    isFlashcardNavDisabled,
    noOfPages,
    setFlashcardViewerModalFlip,
    handleNextPageClick,
    handlePreviousPageClick,
    skip,
    isOnTheLastFlashcardOfThePage,
    isOnTheFirstFlashcardOfThePage,
  } = useContext(FlashcardsCreationContext);

  const handleMiniatureClick = useCallback((currFCData: FlashcardType) => {
    onFlashcardViewerOpen();
    if (setCurrData) {
      setCurrData(currFCData);
    }
    setCurrFlashcardIndex(currFCData.index || 0);
  }, [onFlashcardViewerOpen, setCurrData, setCurrFlashcardIndex]);

  useEffect(() => {
    document.onkeydown = async e => {
      if (e.code === 'ArrowRight') {
        setFlashcardViewerModalFlip(false);
        if (!isFlashcardNavDisabled.next) {
          handleFCNavigatorClick('next');
        }
      }
      if (e.code === 'ArrowLeft') {
        setFlashcardViewerModalFlip(false);
        if (!isFlashcardNavDisabled.prev) {
          handleFCNavigatorClick('prev');
        }
      }
    };
    return () => {
      document.onkeyup = null;
    };
  }, [
    CARDS_LIMIT, currFlashcardIndex, currPage, currPageLen,
    handleFCNavigatorClick, handleNextPageClick, handlePreviousPageClick, isFlashcardNavDisabled.next,
    isFlashcardNavDisabled.prev, isOnTheFirstFlashcardOfThePage, isOnTheLastFlashcardOfThePage, noOfPages,
    setFlashcardViewerModalFlip, skip]);

  if (masterLoading.communityFlashcards || masterLoading.userFlashcards) {
    return (
      <>
        <GoBack />
        <ContentSearchBar />
        <LoadingCards />
      </>
    );
  }

  if ((!flashcards || flashcards.length === 0)) {
    if (tab === 'meus-baralhos') {
      history.push();
    }
    if ((cardsSearchInput !== undefined) || currLessonId === 'todos') {
      return (
        <>
          <GoBack />
          <ContentSearchBar />
          <EmptyPage
            variant="colorful"
            label="Nenhum flashcard encontrado..."
            fontSize={device === 'mobile' ? 'sm' : 'md'}
          />
        </>
      );
    }
    return (
      <Center w="100%" flexFlow="column">
        <GoBack />
        <EmptyPage
          variant="colorful"
          label="Nenhum flashcard criado ainda..."
          fontSize={device === 'mobile' ? 'sm' : 'md'}
        />
        {
          lessonName && lessonName !== 'Aula não encontrada' && (
            <>
              <PrimaryButton
                leftIcon={<Image src="/images/flashcard-creation/flashcard.svg" />}
                mt={5}
                onClick={onCreateFlashcardOpen}
                fontSize="xl"
              >
                Criar o primeiro flashcard!
              </PrimaryButton>
            </>
          )
        }
      </Center>
    );
  }

  return (
    <>
      <GoBack />
      <ContentSearchBar />
      <Flex w="100%" flexWrap="wrap">
        {
          flashcards.map((currFCData, index) => {
            const currMiniatureIndex = (currPage - 1) * CARDS_LIMIT + (index + 1);
            return (
              <MiniFlashcard
                key={currFCData._id}
                index={currMiniatureIndex}
                onClick={() => handleMiniatureClick({
                  ...currFCData,
                  index: currMiniatureIndex - 1,
                })}
                flashcardData={currFCData}
              />
            );
          })
        }
        <AddCardButton
          onClick={onCreateFlashcardOpen}
          delay={Math.min(flashcards.length, CARDS_LIMIT) * DELAY_INDEX}
          mt={4}
          ml={4}
          show={currLessonId !== 'todos' && tab === 'comunidade'}
        />
      </Flex>
      <CardsNavigator />
    </>
  );
};
