import React, { FC, useMemo } from 'react';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTextMany } from '../../../../api/agenda/texts';
import Flex from '../../../../lib/components/Flex';
import AgendaTexts from '../../types/AgendaTexts';
import TextEmpty from '../../types/TextEmpty';

interface ExtraTheoreticalTextProps {
  ids?: string[];
  onFinish: () => Promise<void>;
  loading: boolean;
  finished: boolean;
}

const ExtraTheoreticalText: FC<ExtraTheoreticalTextProps> = ({
  ids, onFinish, loading, finished,
}) => {
  const query = useTextMany({
    ids: ids || [],
  });

  const dataQuery = useMemo(() => {
    const { data } = query;
    if (data) {
      return data.textMany;
    }

    return [];
  }, [query]);

  if (query.loading) {
    return (
      <Flex align="center" justify="center" style={{ minHeight: 400 }}>
        <FontAwesomeIcon icon={faSpinner} spin size="5x" className="color-dark-gray" />
      </Flex>
    );
  }

  if (dataQuery && dataQuery.length > 0) {
    return (
      <AgendaTexts
        data={dataQuery}
      />
    );
  }

  return <TextEmpty />;
};

export default ExtraTheoreticalText;
