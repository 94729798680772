import React, { FC } from 'react';
import SizedBox from '../../../lib/components/SizedBox';
import Text from '../../../lib/components/Text';

const QuestionEmpty: FC = () => {
  return (
    <SizedBox className="py-4">
      <Text tag="h4" center>
        Questões para esse dia ainda não estão disponíveis
      </Text>
    </SizedBox>
  );
};

export default QuestionEmpty;
