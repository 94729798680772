import { Box, Center, Text, VStack } from '@chakra-ui/react';
import { useMemo } from 'react';
import { usePdfPlusMany } from '../../api/aristoplus';
import { Loading } from '../../lib/components/Loading';
import { PdfItem } from '../../lib/components/PdfItem';

export function AristoPlusDocs() {
  const { data, loading } = usePdfPlusMany({});

  const pdfs = useMemo(() => {
    if (!data || loading) {
      return undefined;
    }
    return data.pdfs;
  }, [data, loading]);

  const recentPdfs = useMemo(() => {
    if (pdfs) {
      return [...pdfs].sort((a, b) => new Date(b.createdAt ?? 0).getTime() - new Date(a.createdAt ?? 0).getTime())
        .slice(0, 5);
    }
    return undefined;
  }, [pdfs]);

  if (loading) {
    return <Loading fontSize="5xl" mt={8} />;
  }

  return (pdfs && pdfs.length) ? (
    <VStack maxW={700} m="0 auto" spacing={6}>
      {recentPdfs && (
        <Box w="100%">
          <Text mt={4} mb={2} fontSize="xl" fontWeight="bold">Novidades</Text>
          <VStack align="stretch">
            {recentPdfs.map(p => (
              <PdfItem
                key={p._id}
                href={p.url}
                title={p.title}
                subtitle={p.subtitle}
              />
            ))}
          </VStack>
        </Box>
      )}
      <Box w="100%">
        <Text mt={4} mb={2} fontSize="xl" fontWeight="bold">Todos os documentos</Text>
        <VStack align="stretch">
          {pdfs.map(p => (
            <PdfItem
              key={p._id}
              href={p.url}
              title={p.title}
              subtitle={p.subtitle}
            />
          ))}
        </VStack>
      </Box>
    </VStack>
  ) : (
    <Center mt={8}>Nenhum documento encontrado</Center>
  );
}
