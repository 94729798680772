import { Button, HStack, Tooltip } from '@chakra-ui/react';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC } from 'react';
import { useDefaultStyles } from '../../../hooks/useDefaultStyles';
import { FocusTimeButton } from '../../agenda/_components/FocusTimeButton';

type FullscreenQuestionMenuType = {
  show?: boolean,
  focusTimeSpent: string,
  isNavigatorVisible: boolean,
  setIsNavigatorVisible: React.Dispatch<React.SetStateAction<boolean>>,
}
export const FullscreenQuestionMenu: FC<FullscreenQuestionMenuType> = ({
  show,
  focusTimeSpent,
  isNavigatorVisible,
  setIsNavigatorVisible,
}) => {
  const { colors } = useDefaultStyles();
  if (!show) {
    return null;
  }

  return (
    <HStack>
      <Tooltip label={isNavigatorVisible ? 'Esconder desempenho' : 'Mostrar desempenho'}>
        <Button
          color={colors.secondary.keep}
          onClick={() => setIsNavigatorVisible(prev => !prev)}
        >
          <FontAwesomeIcon icon={isNavigatorVisible ? faEyeSlash : faEye} />
        </Button>
      </Tooltip>
      <FocusTimeButton focusTime={focusTimeSpent} />
    </HStack>
  );
};
