import {
  useState, useCallback, useEffect, useContext,
} from 'react';
import {
  signInWithEmailAndPassword,
  sendEmailVerification,
  EmailAuthProvider,
  sendPasswordResetEmail,
  reauthenticateWithCredential,
  updatePassword,
} from 'firebase/auth';
import { useHistory } from 'react-router-dom';
import { useApolloClient } from '@apollo/client';
import { auth } from '../client/firebase';
import { GlobalContext } from '../Global.context';

export interface AccountHook {
  uid: string;
  loadingAuth: boolean;
  loginWithEmail: (email: string, password: string) => Promise<string>;
  logout: () => Promise<void>;
  isAuthenticated: () => boolean;
  isEmailVerified: () => boolean;
  sendPasswordResetEmail: (email: string) => Promise<string>;
  sendEmailVerification: () => Promise<void>;
  updatePassword: (oldPwd: string, newPwd: string, newPwdRepeat: string) => Promise<void>;
}

export default function useAccount(): AccountHook {
  const [uid, setUid] = useState<string>(auth.currentUser?.uid || '');
  const history = useHistory();
  const apollo = useApolloClient();
  const [loadingAuth, setLoadingAuth] = useState(true);
  const {
    getGlobalProfile,
    profile,
    refetchProfile,
    block,
  } = useContext(GlobalContext);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(next => {
      setLoadingAuth(false);
      if (next && next.uid) {
        setUid(next.uid);
      } else {
        setUid('');
      }
    });

    return () => unsubscribe();
  }, []);
  const loginWithEmail = useCallback(async (email: string, password: string) => {
    try {
      const credentials = await signInWithEmailAndPassword(auth, email, password);
      if (credentials.user) {
        await auth.updateCurrentUser(credentials.user);
        setUid(credentials.user.uid);
        getGlobalProfile();
        return 'success';
      }
      return 'failed';
    } catch (error) {
      console.log(error);
      if (error.code.startsWith('auth/')) {
        return error.code.replace('auth/', '');
      } return error.code;
    }
  }, [getGlobalProfile]);

  const logout = useCallback(async () => {
    await auth.signOut();
    await apollo.clearStore();
    setUid('');
    history.replace('/');
  }, [apollo, history]);

  const isAuthenticated = useCallback(() => {
    return Boolean(uid);
  }, [uid]);

  // Carregar perfil se usuário já estiver autenticado.
  useEffect(() => {
    if (
      isAuthenticated()
      && !profile.called
      && !profile.loading
      && !profile.data
      && !block
    ) {
      refetchProfile();
    }
  }, [block, isAuthenticated, profile.called, profile.data, profile.loading, refetchProfile]);

  const isEmailVerified = useCallback(() => Boolean(auth.currentUser?.emailVerified), []);

  const sendEmailVerificationFn = useCallback(() => {
    if (auth.currentUser) {
      return sendEmailVerification(auth.currentUser, {
        url: `${window.location.origin}/confirmEmail`,
      });
    }
    return Promise.reject();
  }, []);

  // Alterar senha de usuário
  const updatePasswordFn = useCallback(async (oldPwd: string, newPwd: string, newPwdRepeat: string) => {
    if (!auth.currentUser || !auth.currentUser.email) {
      return;
    }
    if (auth.currentUser.providerId === 'password') {
      throw new Error('Esse usuário não faz autenticação por senha.');
    }
    if (newPwd !== newPwdRepeat) {
      throw new Error('Novas senhas não são identicas');
    }

    const credential = EmailAuthProvider.credential(
      auth.currentUser.email,
      oldPwd,
    );
    await reauthenticateWithCredential(auth.currentUser, credential);
    await updatePassword(auth.currentUser, newPwd.trim());
  }, []);

  const sendPasswordResetEmailFn = useCallback(async (email: string) => {
    try {
      await sendPasswordResetEmail(auth, email);
      return 'success';
    } catch (error) {
      return error.message;
    }
  }, []);

  return {
    uid,
    isEmailVerified,
    sendEmailVerification: sendEmailVerificationFn,
    loginWithEmail,
    logout,
    isAuthenticated,
    updatePassword: updatePasswordFn,
    loadingAuth,
    sendPasswordResetEmail: sendPasswordResetEmailFn,
  };
}
