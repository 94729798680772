import { FC, useMemo } from 'react';
import { HStack, Square, Tooltip } from '@chakra-ui/react';
import { DifficultyType } from '../../api/answer';
import { useDefaultStyles } from '../../hooks/useDefaultStyles';

interface DifficultyBlocksProps {
  difficulty: DifficultyType;
  className?: string;
}

const title = {
  hard: 'Difícil',
  medium: 'Médio',
  easy: 'Fácil',
};

const DifficultyBlocks: FC<DifficultyBlocksProps> = ({
  difficulty, className,
}) => {
  const { colors } = useDefaultStyles();
  const isEasy = useMemo(() => {
    return Boolean(difficulty);
  }, [difficulty]);

  const isMedium = useMemo(() => {
    return difficulty === 'medium';
  }, [difficulty]);

  const isHard = useMemo(() => {
    return difficulty === 'hard';
  }, [difficulty]);

  return (
    <Tooltip label={difficulty ? title[difficulty] : 'Desconhecida'}>
      <HStack align="center" spacing={1}>
        <Square size="15px" bgColor={(isEasy || isMedium || isHard) ? colors.green.goDarker : 'dark.400'} />
        <Square size="15px" bgColor={(isEasy || isMedium || isHard) ? colors.green.goDarker : 'dark.400'} />
        <Square size="15px" bgColor={(isMedium || isHard) ? colors.yellow.goDarker : 'dark.400'} />
        <Square size="15px" bgColor={(isMedium || isHard) ? colors.yellow.goDarker : 'dark.400'} />
        <Square size="15px" bgColor={isHard ? colors.red.goDarker : 'dark.400'} />
        <Square size="15px" bgColor={isHard ? colors.red.goDarker : 'dark.400'} />
      </HStack>
    </Tooltip>
  );
};

export default DifficultyBlocks;
