import { Avatar, Box, Flex, Heading, HStack, Text } from '@chakra-ui/react';
import { faSpinner, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC, useContext, useMemo, useState } from 'react';
import { useGetWeeklyAchievementsProgressV2 } from '../../../api/achievements';
import { useDefaultStyles } from '../../../hooks/useDefaultStyles';
import { useProfileImg } from '../../../hooks/useProfileImg';
import Select from '../../../lib/components/Select';
import { PrivateContext } from '../../../Private.context';
import { ProgressBarByGoal } from './ProgressBarByGoal';
import { TrophiesSubtitle } from './TrophiesSubtitle';
import { TrophyPodium } from './TrophyPodium';

export const MyTrophies: FC = () => {
  const { profile } = useContext(PrivateContext);
  const { colors, cardBorder } = useDefaultStyles();
  const { data, loading } = useGetWeeklyAchievementsProgressV2();
  const [coursePeriod, setCoursePeriod] = useState<'course' | 'preCourse' | 'general'>('course');

  const { imageSrc } = useProfileImg(profile._id);

  const { bronze, silver, gold, total, progress } = useMemo(() => {
    return {
      bronze: data?.getWeeklyAchievementsProgressV2[coursePeriod].bronze || 0,
      silver: data?.getWeeklyAchievementsProgressV2[coursePeriod].silver || 0,
      gold: data?.getWeeklyAchievementsProgressV2[coursePeriod].gold || 0,
      total: data?.getWeeklyAchievementsProgressV2[coursePeriod].total || 0,
      progress: data?.getWeeklyAchievementsProgressV2[coursePeriod].progress || 0,
    };
  }, [coursePeriod, data?.getWeeklyAchievementsProgressV2]);

  if (loading) {
    return (
      <Box color={colors.lighterSecondary.goDarker} mt={10} align="center">
        <FontAwesomeIcon icon={faSpinner} spin size="5x" />
      </Box>
    );
  }

  return (
    <Box
      p={{ base: 4, md: 8 }}
      w="100%"
      {...cardBorder}
    >
      <Flex
        gridGap={4}
        w="100%"
        alignItems={{ base: 'space-between', md: 'flex-start' }}
        justifyContent={{ base: 'center', md: 'space-between' }}
        flexFlow={{ base: 'column', md: 'row' }}
      >
        <Heading size="md">Meus troféus</Heading>
        <Select
          required
          value={coursePeriod}
          onChange={e => setCoursePeriod(e.target.value as typeof coursePeriod)}
          options={[
            { label: data?.getWeeklyAchievementsProgressV2.general.title || 'Geral', value: 'general' },
            { label: data?.getWeeklyAchievementsProgressV2.preCourse.title || 'Pré-curso', value: 'preCourse' },
            { label: data?.getWeeklyAchievementsProgressV2.course.title || 'Curso', value: 'course' },
          ]}
        />
      </Flex>
      <Flex align="stretch" justify="center" direction="column" mt={10}>
        <TrophyPodium
          variant="student-goals"
          bronze={bronze}
          silver={silver}
          gold={gold}
        />
        <HStack spacing={4} mt={4}>
          <Avatar
            src={imageSrc}
            alt="user-img"
            bgColor={colors.alpha[200]}
            color={colors.secondary.keep}
            w={{ base: 12, md: 16 }}
            h={{ base: 12, md: 16 }}
            fontSize="2xl"
            name={profile.name}
            icon={<FontAwesomeIcon icon={faUser} />}
          />
          <ProgressBarByGoal
            h={4}
            borderRadius={8}
            bronze={bronze}
            silver={silver}
            gold={gold}
            total={total}
          />
          <Text>{`${progress}/${total}`}</Text>
        </HStack>
        <TrophiesSubtitle />
      </Flex>
    </Box>
  );
};
