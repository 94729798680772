import { Flex, Box } from '@chakra-ui/react';
import { faCircle, IconName, IconLookup } from '@fortawesome/free-solid-svg-icons';
import { findIconDefinition } from '@fortawesome/fontawesome-svg-core';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BurgerMenuItemType } from '../../../api/burger-menu';
import { useDefaultStyles } from '../../../hooks/useDefaultStyles';

interface BottomMenuProps {
  items?: BurgerMenuItemType[];
  itemsCount?: number;
}

interface BottomMenuItemProps {
  item: BurgerMenuItemType;
  active?: boolean;
}

function BottomMenuItem({
  item, active = false,
}: BottomMenuItemProps) {
  const { colors } = useDefaultStyles();
  const history = useHistory();
  const iconLookup: IconLookup = { prefix: 'fas', iconName: item.icon as IconName };
  const iconDefinition = findIconDefinition(iconLookup) || faCircle;
  return (
    <Box
      p={4}
      display="flex"
      flexDir="column"
      alignItems="center"
      as="button"
      color={colors.lighter.fromWhite}
      fontSize="1.5rem"
      onClick={() => history.push(item.url)}
      _after={active ? {
        content: '""',
        padding: '1px',
        marginTop: '4px',
        width: '100%',
        height: '5px',
        borderRadius: '5px',
        bgColor: colors.lighter.fromWhite,
      } : {}}
    >
      <FontAwesomeIcon icon={iconDefinition} />
    </Box>
  );
}

export function BottomMenu({
  items, itemsCount = 4,
}: BottomMenuProps) {
  const history = useHistory();
  const { colors } = useDefaultStyles();
  return (
    <Flex
      position="fixed"
      zIndex={2}
      bottom={0}
      justify="space-between"
      align="center"
      bg={colors.primary.goALittleLighter}
      as="footer"
      w="100%"
      h="3rem"
      px="2rem"
      display={{ base: 'flex', md: 'none' }}
    >
      {items && items.slice(0, itemsCount).map((item, index) => (
        <BottomMenuItem
          key={item.url}
          item={item}
          active={history.location.pathname.startsWith(item.url)}
        />
      ))}
    </Flex>
  );
}
