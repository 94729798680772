import { useCallback } from 'react';
import {
  gql, QueryResult, useMutation, useQuery,
} from '@apollo/client';
import { toast } from 'react-toastify';
import { PreChoiceInterface } from '../agenda/mock-exam';
import { Question } from '../agenda/questions';
import { Activity } from './extra-activities';
import errorHandler from '../error/errorHandler';

interface SmartExamExtra {
  _id: string;
  profile: string;
  finished: boolean;
  preChoice: PreChoiceInterface[];
  createdAt: Date;
  updatedAt: Date;
  institution: string;
  uf: string;
  questions: Question[];
}

interface GetSmartExamExtraInput {
  id: string;
}

interface GetSmartExamExtraOutput {
  getSmartExamExtraActivityDetails: SmartExamExtra;
}

const GET_SMART_EXAM_EXTRA = gql`
  query getSmartExamExtraActivityDetails ($id: ObjectId!) {
    getSmartExamExtraActivityDetails (_id: $id) {
      _id
      profile
      finished
      preChoice {
        questionId
        selected
        essay
      }
      createdAt
      updatedAt
      institution
      uf
      questions {
        _id
        body
        choices
        answersProportion
        correctChoice
        institution
        uf
        year
        examOrder
        examType
        complement
        classification
        difficulty
        createdAt
        updatedAt
        isEssay
        essay
        comment {
          author
          profile
          title
          body
          createdAt
          updatedAt
        }
        answer
      }
    }
  }
`;

export function useGetExtraSmartExams({
  id,
}: GetSmartExamExtraInput): QueryResult<GetSmartExamExtraOutput, GetSmartExamExtraInput> {
  return useQuery<GetSmartExamExtraOutput, GetSmartExamExtraInput>(GET_SMART_EXAM_EXTRA, {
    variables: {
      id,
    },
    fetchPolicy: 'network-only',
    skip: id === '',
  });
}

interface ActivitySmartExam extends Activity {
  institution: string;
  uf: string;
  questions: Question[];
}

interface CreateSmartExamExtraActivityOutput {
  createSmartExamExtraActivity: ActivitySmartExam;
}

const CREATE_SMART_EXAM_ACTIVITY = gql`
  mutation CreateSmartExamExtraExamActivity {
    createSmartExamExtraActivity {
      _id
    }
  }
`;

interface UseExtraSmartExamsInterface {
  onCreateSmartExamActivity: () => Promise<void>;
}

export function useExtraSmartExams(): UseExtraSmartExamsInterface {
  const [createActivity] = useMutation<CreateSmartExamExtraActivityOutput>(CREATE_SMART_EXAM_ACTIVITY, {
    onError: errorHandler,
    onCompleted: data => {
      if (data !== null) {
        toast.success('Atividade de simulado inteligente criada com sucesso!');
      }
    },
  });

  const handleCreate = useCallback(async () => {
    await createActivity();
  }, [createActivity]);

  return {
    onCreateSmartExamActivity: handleCreate,
  };
}
