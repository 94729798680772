import { Flex, Text } from '@chakra-ui/react';
import { FC } from 'react';
import { useDefaultStyles } from '../../../hooks/useDefaultStyles';
import { useLocalStorage } from '../../../hooks/useLocalStorage';
import { Loading } from '../../../lib/components/Loading';

export const LoadingDiagnosticResults: FC = () => {
  const [isCompass] = useLocalStorage('@Aristoclass:isCompass');
  const { colors } = useDefaultStyles();
  return (
    <Flex
      padding={{ base: '15vh', md: '25vh' }}
      alignItems="center"
      justifyContent="center"
      flexFlow="column"
      color={isCompass ? colors.secondary.keep : colors.white.keep}
    >
      <Text
        marginBottom="2rem"
        textAlign="center"
        fontSize="1.5rem"
      >
        Estamos gerando seu relatório! Aguarde...
      </Text>
      <Loading
        color={isCompass ? colors.secondary.keep : colors.white.keep}
        fontSize="7rem"
      />
    </Flex>
  );
};
