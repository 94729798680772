import { Box, Heading, HStack, Text, VStack } from '@chakra-ui/react';
import { FC, useCallback, useMemo } from 'react';
import {
  Legend, PolarAngleAxis, PolarGrid, PolarRadiusAxis,
  Radar, RadarChart, ResponsiveContainer, Tooltip,
} from 'recharts';
import { AreasStatsType } from '../../../api/student-report';
import { getComplementaryColor, useDefaultStyles, useRGBColor } from '../../../hooks/useDefaultStyles';
import { useDevice } from '../../../hooks/useDevice';
import { PrizeStar } from './PrizeStar';
import { ReportLoading } from './ReportLoading';

export type CustomTooltipType = {
  payload?: {
    value: number,
    stroke: string,
    name: string,
  }[],
  label?: string,
}

const CustomTooltip: FC<CustomTooltipType> = ({ label, payload }: CustomTooltipType) => {
  const { cardBorder, colors } = useDefaultStyles();
  if (!payload || !payload[0] || !payload[1]) {
    return null;
  }
  const { stroke: currStroke, name: currName, value: currValue } = payload[1];
  const { stroke: prevStroke, name: prevName, value: prevValue } = payload[0];
  const delta = currValue - prevValue;

  return (
    <Box
      bgColor={colors.background}
      padding={2}
      align="center"
      {...cardBorder}
    >
      <Text mb={1} fontWeight="bold" fontSize="lg">{label}</Text>
      <VStack align="flex-start">
        <HStack>
          <Text color={prevStroke}>{`${prevName}:`}</Text>
          <Text>{`${prevValue}%`}</Text>
        </HStack>
        <HStack>
          <Text color={currStroke}>{`${currName}:`}</Text>
          <Text>{`${currValue}%`}</Text>
        </HStack>
        <HStack color={delta < 0 ? colors.red.keep : colors.green.keep}>
          <Text>Diferença:</Text>
          <Text>{`${delta >= 0 ? '+' : ''}${delta}%`}</Text>
        </HStack>
      </VStack>
    </Box>
  );
};

type AreasPerformancesType = {
  currAreasData?: AreasStatsType[],
  prevAreasData?: AreasStatsType[],
}

export const AreasPerformances: FC<AreasPerformancesType> = ({
  currAreasData,
  prevAreasData,
}: AreasPerformancesType) => {
  const { cardBorder, colors } = useDefaultStyles();
  const { toRGB } = useRGBColor();
  const device = useDevice();

  const getDataObject = useCallback((data?: AreasStatsType[]) => {
    if (data) {
      return {
        pediatrics: Math.round(data.filter(x => ['Pediatria']
          .includes(x.classification))[0]?.percentage || 0),
        clinic: Math.round(data.filter(x => ['Clínica médica', 'Clínica Médica']
          .includes(x.classification))[0]?.percentage || 0),
        preventive: Math.round(data.filter(x => ['Epidemiologia', 'Saúde Coletiva']
          .includes(x.classification))[0]?.percentage || 0),
        surgery: Math.round(data.filter(x => ['Cirurgia', 'Cirurgia Geral']
          .includes(x.classification))[0]?.percentage || 0),
        go: Math.round(data.filter(x => ['Ginecologia e obstetrícia', 'Ginecologia e Obstetrícia']
          .includes(x.classification))[0]?.percentage || 0),
      };
    }
    return undefined;
  }, []);

  const currData = getDataObject(currAreasData);
  const prevData = getDataObject(prevAreasData);

  const starAchivement = useMemo(() => {
    const MINIMUM_SCORE_TO_ACHIVE = 80;
    if (currData) {
      return Object.values(currData).reduce((acc, curr) => (curr >= MINIMUM_SCORE_TO_ACHIVE ? acc + 1 : acc), 0);
    } return 0;
  }, [currData]);

  if (!currData || !prevData || !prevAreasData || !currAreasData) {
    return <ReportLoading />;
  }

  const data = [
    {
      subject: 'Clínica médica', previous: prevData.clinic, current: currData.clinic, fullMark: 100,
    },
    {
      subject: 'Pediatria', previous: prevData.pediatrics, current: currData.pediatrics, fullMark: 100,
    },
    {
      subject: 'Preventiva', previous: prevData.preventive, current: currData.preventive, fullMark: 100,
    },
    {
      subject: 'GO', previous: prevData.go, current: currData.go, fullMark: 100,
    },
    {
      subject: 'Cirurgia', previous: prevData.surgery, current: currData.surgery, fullMark: 100,
    },
  ];

  return (
    <Box
      mb={{ base: 4, md: 0 }}
      p={4}
      w={device === 'mobile' ? '100%' : '50%'}
      {...cardBorder}
    >
      <HStack flex={3}>
        <PrizeStar
          label="Parabéns! Você fez no mínimo 80% em todas as grandes áreas!"
          goal={5}
          achieved={starAchivement}
        />
        <Heading size="md">Desempenho por grande área em provas e simulados</Heading>
      </HStack>
      <ResponsiveContainer
        width="100%"
        height={device === 'mobile' ? 300 : 400}
      >
        <RadarChart data={data}>
          <PolarGrid
            gridType="circle"
            strokeOpacity={0.4}
            stroke={toRGB(colors.secondary.keep)}
          />
          <Tooltip
            content={<CustomTooltip />}
          />
          <PolarAngleAxis
            axisLine={false}
            tickLine={false}
            dataKey="subject"
            fontSize={12}
            stroke={toRGB(colors.primary.goFullLighter)}
          />
          <PolarRadiusAxis
            angle={18}
            domain={[0, 100]}
            stroke={toRGB(colors.secondary.keep)}
            fontSize={10}
            tickCount={6}
            orientation="right"
          />
          <Radar
            name="Mês passado"
            dataKey="previous"
            stroke={getComplementaryColor(toRGB(colors.secondary.keep))}
            fill={getComplementaryColor(toRGB(colors.secondary.keep))}
            fillOpacity={0.2}
          />
          <Radar
            name="Este mês"
            dataKey="current"
            stroke={toRGB(colors.secondary.keep)}
            fill={toRGB(colors.secondary.keep)}
            fillOpacity={0.5}
          />
          <Legend
            iconSize={10}
            iconType="circle"
            layout="horizontal"
            verticalAlign="bottom"
          />
        </RadarChart>
      </ResponsiveContainer>
    </Box>
  );
};
