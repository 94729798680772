import { Box, Heading, Square, HStack, VStack, Circle, Text } from '@chakra-ui/react';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContext, useMemo, useState } from 'react';
import { useGetPerformanceChart } from '../../../../api/performance-chart';
import { useDefaultStyles } from '../../../../hooks/useDefaultStyles';
import Select from '../../../../lib/components/Select';
import { PrivateContext } from '../../../../Private.context';
import ChartComponent from '../../_components/chart/ChartComponent';

export function QuestionPerformanceChart() {
  const [chartFilter, setChartFilter] = useState('all');
  const { profile } = useContext(PrivateContext);
  const startsAt = useMemo(() => new Date(profile.createdAt), [profile.createdAt]);
  const { cardBorder, colors } = useDefaultStyles();

  const { data, called, loading } = useGetPerformanceChart({
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    tag: chartFilter as any,
    startsAt,
  });

  const chartData = useMemo(() => {
    if (called && !loading && data) {
      const pointData: { xLabel: string, user: number, global: number }[] = [];
      data.getPerformanceChart.forEach(point => {
        const globalY = point.globalCorrect / ((point.globalWrong + point.globalCorrect) || 1);
        const userY = point.correct / ((point.correct + point.wrong) || 1);
        pointData.push({
          xLabel: point.weekSpan,
          user: userY,
          global: globalY,
        });
      });
      return pointData;
    }
    return [];
  }, [called, data, loading]);

  return (
    <Box
      maxH={500}
      {...cardBorder}
      p={4}
    >
      <Heading size="md">Desempenho por questões</Heading>
      <Text fontSize="xs" color={colors.secondary.keep}>Atualiza a cada 24 horas</Text>
      <Box my={4}>
        <Select
          ml="auto"
          borderRadius={20}
          fontSize="sm"
          maxW={250}
          value={chartFilter}
          onChange={e => setChartFilter(e.target.value)}
          options={[
            { value: 'all', label: 'Todas' },
            { value: 'exam_and_mocks', label: 'Simulados e provas na íntegra' },
            { value: 'easy_questions', label: 'Questões de nível fácil' },
            { value: 'medium_questions', label: 'Questões de nível médio' },
            { value: 'hard_questions', label: 'Questões de nível difícil' },
          ]}
          required
        />
      </Box>
      <Box
        h={300}
        my={4}
      >
        {loading ? (
          <Square color="light.200" size="100%">
            <FontAwesomeIcon icon={faSpinner} spin size="5x" />
          </Square>
        ) : (
          <ChartComponent chartData={chartData} />
        )}
      </Box>
      <HStack spacing={8} justify="center">
        <VStack>
          <Circle size="20px" bgColor={colors.secondary.keep} position="relative">
            <Box
              w="40px"
              h="1px"
              position="absolute"
              top="50%"
              left="50%"
              transform="translateX(-50%)"
              bgColor={colors.secondary.keep}
            />
          </Circle>
          <Text textAlign="center" fontSize="sm">Seu desempenho</Text>
        </VStack>
        <VStack>
          <Circle size="20px" bgColor="gray.300" position="relative">
            <Box
              w="40px"
              h="1px"
              position="absolute"
              top="50%"
              left="50%"
              transform="translateX(-50%)"
              bgColor="gray.300"
            />
          </Circle>
          <Text textAlign="center" fontSize="sm">Média geral dos alunos</Text>
        </VStack>
      </HStack>
    </Box>
  );
}
